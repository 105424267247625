import React from 'react';

import { Loader } from './Loader';

export const Page = ({ children, loading, className }) => {
  return (
    <div className={`content ${className || ''}`}>
      <Loader loading={loading}></Loader>
      {children}
    </div>
  );
};
