import _ from 'lodash';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import React, { Component } from 'react';

import 'react-datepicker/dist/react-datepicker.css';

import { CheckBox } from '../../components/Input/CheckBox';
import { CONST_FILTER_LIST } from '../../stores/ReconcileStore';
import CustomInputDatePicker from '../../components/Input/DatePicker';
import MultiSelect from '../../components/Forms/MultiSelect';
import NexterButton from '../../components/Button/NexterButton';

const rowStyles = classNames('pb-3 mx-0 my-0');
const colStyles = classNames('pr-3 pl-0');
const rowModalStyles = classNames('mx-0 my-0pb-3');
const colModalStyles = classNames('px-0');
const colModalDefaultProps = {
  xs: { size: 12 },
  lg: { size: 4 },
};
const initialState = {
  modalFilter: false,
  filters: {
    payment_gateway: [
      { value: '2c2p', label: '2C2P' },
      { value: 't2p', label: 'T2P' },
      { value: '123', label: '123' },
      { value: 'bbl', label: 'BBL' },
      { value: 'opn', label: 'OPN' },
    ],
  },
  isSuperAdmin: false,
  isChannelAdmin: false,
};
class ReconcileSearch extends Component {
  constructor(props) {
    super(props);
    this.state = _.cloneDeep(initialState);
  }
  async componentDidMount() {
    let { loggedinUser } = this.props.user.getData();
    switch (loggedinUser.type) {
      case 'super_admin':
      case 'ae_marketing':
      case 'biz_system':
      case 'manager':
        await this.props.channel.fetchChannelAll();
        this.setState({ isSuperAdmin: true });
        break;
      case 'channel_admin':
      case 'channel_user':
      case 'as_channel_admin':
        this.setState({ isChannelAdmin: true });
        break;
      default:
        break;
    }
  }
  channelOptionList = () => {
    const { channels } = this.props.channel.getData();
    return channels.all.filter(channel => channel.status === 'approved').map(c => ({ value: c.uid, label: c.name }));
  };
  toggleFilter = () => {
    this.setState({ modalFilter: !this.state.modalFilter });
  };
  render() {
    const { modalFilter, filters, isSuperAdmin, isChannelAdmin } = this.state;
    const { reconcile } = this.props;
    const {
      filter: { list: filter },
    } = reconcile.getData();
    return (
      <>
        <Row className={classNames(rowStyles)}>
          {isSuperAdmin && (
            <Col lg="3" xs="12" className={classNames(colStyles, 'pl-0')}>
              <MultiSelect
                placeholder="เลือก CHANNEL"
                options={this.channelOptionList()}
                value={filter[CONST_FILTER_LIST.CHANNEL]}
                onChange={e => {
                  reconcile.filterList([CONST_FILTER_LIST.CHANNEL], e);
                }}
              />
            </Col>
          )}
          <Col lg={isSuperAdmin ? '3' : '6'} xs="12" className={colStyles}>
            <Row className="mx-0 group-form">
              <Col xs={6} className="px-0">
                <DatePicker
                  customInput={
                    <CustomInputDatePicker size="sm" iconCalendarStyle={{ position: 'absolute', right: 5 }}>
                      {filter[CONST_FILTER_LIST.START_DATE] ? (
                        <span className="datepicker-custom-text">{moment(filter[CONST_FILTER_LIST.START_DATE]).format('DD/MM/YYYY')}</span>
                      ) : (
                        <span className="datepicker-custom-text">วันที่เริ่ม</span>
                      )}
                    </CustomInputDatePicker>
                  }
                  selected={filter[CONST_FILTER_LIST.START_DATE]}
                  onChange={date => reconcile.filterList(CONST_FILTER_LIST.START_DATE, date)}
                  locale="th"
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date('07-01-2021')}
                  maxDate={filter[CONST_FILTER_LIST.END_DATE]}
                />
              </Col>
              <Col xs={6} className="px-0">
                <DatePicker
                  customInput={
                    <CustomInputDatePicker size="sm" iconCalendarStyle={{ position: 'absolute', right: 5 }}>
                      {filter[CONST_FILTER_LIST.END_DATE] ? (
                        <span className="datepicker-custom-text">{moment(filter[CONST_FILTER_LIST.END_DATE]).format('DD/MM/YYYY')}</span>
                      ) : (
                        <span className="datepicker-custom-text">วันที่สิ้นสุด</span>
                      )}
                    </CustomInputDatePicker>
                  }
                  selected={filter[CONST_FILTER_LIST.END_DATE]}
                  onChange={date => reconcile.filterList(CONST_FILTER_LIST.END_DATE, date)}
                  locale="th"
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date('07-01-2021')}
                />
              </Col>
            </Row>
          </Col>
          <Col lg="6" xs="12" className={classNames(colStyles, 'd-flex justify-content-between')}>
            <div>
              <CheckBox
                label="ไม่ระบุวัน"
                checked={filter[CONST_FILTER_LIST.NO_DATE]}
                onChange={() => reconcile.filterList(CONST_FILTER_LIST.NO_DATE, !filter[CONST_FILTER_LIST.NO_DATE])}
                defaultClassName=""
              />
              <NexterButton
                size="sm"
                color="info"
                outline
                onClick={() => {
                  reconcile.clearFilterList();
                  reconcile.getList(isSuperAdmin);
                }}
              >
                clear
              </NexterButton>
              <NexterButton size="sm" color="dark" className="mx-1" icon="fas fa-search" onClick={() => reconcile.getList(isSuperAdmin)}>
                search
              </NexterButton>
            </div>
            <div>
              <NexterButton size="sm" color="dark" icon="fas fa-filter" onClick={this.toggleFilter}>
                Filter
              </NexterButton>
              <Modal isOpen={modalFilter} toggle={this.toggleFilter} centered>
                <ModalBody>
                  <h6 className="text-capitalize">filters</h6>
                  <hr />
                  <h6>Payment Gateway</h6>
                  <Row className={rowModalStyles}>
                    {filters.payment_gateway
                      .filter(gateway => (isSuperAdmin ? true : gateway.value !== 'bbl'))
                      .map((props, i) => (
                        <Col key={i} {...colModalDefaultProps} className={colModalStyles}>
                          <CheckBox
                            {...props}
                            checked={filter[CONST_FILTER_LIST.PAYMENT_GATEWAY].includes(props.value)}
                            onChange={() => {
                              let values = [...filter[CONST_FILTER_LIST.PAYMENT_GATEWAY]];
                              const isNew = !values.find(v => v === props.value);
                              if (isNew) values.push(props.value);
                              else values = values.filter(v => v !== props.value);
                              reconcile.filterList([CONST_FILTER_LIST.PAYMENT_GATEWAY], values);
                            }}
                            defaultClassName="mx-0"
                          />
                        </Col>
                      ))}
                  </Row>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <NexterButton size="sm" className="mx-2 btn-light" onClick={() => reconcile.filterList(CONST_FILTER_LIST.PAYMENT_GATEWAY, [])}>
                      Clear all filters
                    </NexterButton>
                    <NexterButton size="sm" onClick={this.toggleFilter}>
                      Done
                    </NexterButton>
                  </div>
                </ModalBody>
              </Modal>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default inject('channel', 'seller', 'user', 'reconcile')(observer(ReconcileSearch));
