import React, { Fragment } from 'react';
import { Table } from 'reactstrap';

import { Amount } from '../../components/Field/Amount';

export const SummaryTable = ({ total, fee, channelFee, sellerFee }) => {
  if (typeof total === 'string') total = parseFloat(total)
  if(Number.isNaN(fee) || !fee) fee=0
  const net = total - fee;
  return (
    <Fragment>
      <h5 className="title">สรุปยอดโอน</h5>
      <hr />
      <div className="table-reponsive table-scroll">
        <Table className="table-report">
          <thead>
            <tr>
              <th className="text-left">รายการสินค้า</th>
              <th className="text-right">ยอดชำระ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-left">ยอดรวม</td>
              <td className="text-right"><Amount value={total} currencyUnit={true} /></td>
            </tr>
            { channelFee ?
              <>
                <tr>
                  <td className="text-left">ค่าโอนของ Channel</td>
                  <td className="text-right"><Amount value={channelFee} currencyUnit={true} /></td>
                </tr>
                <tr>
                  <td className="text-left">ค่าโอนของ Seller</td>
                  <td className="text-right"><Amount value={sellerFee} currencyUnit={true} /></td>
                </tr>
              </> 
              :
              <tr>
                <td className="text-left">ค่าโอน</td>
                <td className="text-right"><Amount value={fee} currencyUnit={true} /></td>
              </tr>
            }
            <tr className="bold border-top">
              <td className="text-left">ยอดโอนสุทธิ</td>
              <td className="text-right red"><Amount value={net} currencyUnit={true} /></td>
            </tr>
            <tr className="bold">
              <td className="text-left"></td>
              {net < 0 ? <td className="text-right red">ไม่สามารถทำรายการโอนได้เนื่องจากยอดเงินติดลบ</td> : null}
            </tr>
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};
