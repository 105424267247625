import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Page } from '../../components/Page';
import PermissionSetting from './PermissionSetting';
import MenuSetting from './MenuSetting';
import UserManageAdmin from './UserManageAdmin';
import { Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { NexterLabelHeader } from '../../components/Field/Label';
import AccessUserProfilePage from '../user/AccessUserProfilePage';
import CreateUserPage from './CreateUserPage';
import UserHistoryLogPage from '../user/UserHistoryLogPage';

class RolePermissionPage extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.changeActiveTab = this.changeActiveTab.bind(this);
  }

  componentDidMount() {
    this.props.user.setSelectedUser(null);
    this.props.user.changeUserMgmtView('list');
  }

  getInitialState() {
    return {
      activeTab: 'user-management',
    };
  }

  changeActiveTab(e) {
    const tabId = e.target.id;
    this.setState({ activeTab: tabId });
  }

  render() {
    const { activeTab } = this.state;
    let { userMgmtView, loading: userLoading } = this.props.user.getData();
    let { loading: roleLoading } = this.props.role.getData();
    let loading = userLoading || roleLoading;
    return (
      <Page loading={loading}>
        <Row>
          <Col md="12">
            <NexterLabelHeader>USER MANAGEMENT</NexterLabelHeader>
          </Col>
        </Row>
        <Row>
          <Col>
            <Nav tabs className="float-left">
              <NavItem>
                <NavLink href="#" active={activeTab === 'user-management'} onClick={this.changeActiveTab} id="user-management">
                  User Management
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" active={activeTab === 'role-permission'} onClick={this.changeActiveTab} id="role-permission">
                  Role Permission
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" active={activeTab === 'menu-setting'} onClick={this.changeActiveTab} id="menu-setting">
                  Menu Setting
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="user-management">
            {activeTab === 'user-management' && (
              <Card className="card-usermanagement">
                <CardBody>
                  {userMgmtView == 'list' && <UserManageAdmin />}
                  {userMgmtView == 'detail' && <AccessUserProfilePage />}
                  {userMgmtView == 'create' && <CreateUserPage />}
                  {userMgmtView == 'history' && <UserHistoryLogPage />}
                </CardBody>
              </Card>
            )}
          </TabPane>
          <TabPane tabId="role-permission">{activeTab === 'role-permission' && <PermissionSetting />}</TabPane>
          <TabPane tabId="menu-setting">{activeTab === 'menu-setting' && <MenuSetting />}</TabPane>
        </TabContent>
      </Page>
    );
  }
}
export default inject('role', 'user')(observer(RolePermissionPage));
