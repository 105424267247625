import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from "prop-types";
import FilePicker from './FilePicker';
import NexterButton from '../Button/NexterButton';

class ApplicationFile extends Component {
  render() {
    const {
      disabled,
      onDrop,
      onView,
      previewName,
      inputProps,
      dropZoneProps,
    } = this.props;
    return (
      <FilePicker
        onDrop={onDrop}
        inputProps={{
          multiple: false,
          disabled,
          ...inputProps,
        }}
        dropZoneProps={{
          disabled,
          ...dropZoneProps,
          ...(inputProps.accept && { accept: inputProps.accept }),
        }}>
        <div className="d-flex">
          <div className="file-application-form w-100 pl-2" onClick={onView}>
            <span>{previewName || 'click browse files'}</span>
          </div>
          <div>
            <NexterButton color="dark" size="sm"
              disabled={disabled}
              className="folder-before ml-2 my-0 btn-application-form">
              <span> Browse files</span>
            </NexterButton>
          </div>
        </div>
      </FilePicker>
    )
  }
}

ApplicationFile.propTypes = {
  disabled: PropTypes.bool,
  onDrop: PropTypes.func,
  onView: PropTypes.func,
  previewName: PropTypes.string,
};

export default ApplicationFile;