import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Card, CardBody } from 'reactstrap';
import { Page } from '../../components/Page';
import SystemConfigHistoryLog from './SystemConfigHistoryLog';

class SystemConfigHistoryLogPage extends Component {
  backToSystemConfig() {
    this.props.history.goBack();
  }

  render() {
    return (
      <Fragment>
        <Page>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="text-uppercase" style={{ marginBottom: 0 }}>
              <a onClick={this.backToSystemConfig.bind(this)}>
                <i className="fas fa-chevron-left"></i> <b>SYSTEM CONFIG</b>
              </a>
            </h5>
          </div>
          <Card className="historylog-wrapper">
            <CardBody>
              <SystemConfigHistoryLog />
            </CardBody>
          </Card>
        </Page>
      </Fragment>
    );
  }
}

export default SystemConfigHistoryLogPage;
