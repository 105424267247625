import format from 'date-fns/format';
import { th } from 'date-fns/locale';
import * as _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import Swal from 'sweetalert2';
import NexterButton from '../../components/Button/NexterButton';
import { NexterLabelHeader, NexterLabelSpan } from '../../components/Field/Label';
import CustomInputDatePicker from '../../components/Input/DatePicker';
import NexterInput from '../../components/Input/NexterInput';
import { Page } from '../../components/Page';
import PaymentGatewaySelect from '../../components/PaymentGateway/PaymentGatewaySelect';
import Visibility from '../../components/Visibility/Visibility';
import PreTransferManagement from './component/PreTransferManagement';
import TransferManagement from './component/TransferManagement';

registerLocale('th', th);
class TransferPage extends Component {
  constructor(props) {
    super(props);
    this.transferRef = React.createRef();
    this.changeActiveTab = this.changeActiveTab.bind(this);
    this.onSelectGateway = this.onSelectGateway.bind(this);
    this.getTitlePage = this.getTitlePage.bind(this);
    this.selectChannel = this.selectChannel.bind(this);
    this.state = {
      data: {
        sellerDataList: [],
        channelDataList: [],
      },
    };
  }
  async checkDefaultPage() {
    const {
      match: { params },
    } = this.props;
    if (params.gateway) {
      this.props.transfer.changeGateway(params.gateway);
    }
    if (params.report_no) {
      await this.props.transfer.getTransferReport(params.report_no);
      this.props.transfer.setDataByTransferReport();
    }
  }
  async componentDidMount() {
    const { channels } = this.props.channel.getData();
    let channelOpt = [];
    if (channels.all.length === 0) {
      await this.props.channel.fetchChannelAll();
    }
    await this.checkDefaultPage();
    await this.props.seller.fetchSellersByStatus('approved');
    channelOpt = await this.getChannelOptionList();
    const sellerOpt = this.getSellerOptionList();
    this.setState({
      data: { sellerDataList: sellerOpt, channelDataList: channelOpt },
    });
  }
  async getChannelOptionList() {
    const { channels, channel } = this.props.channel.getData();
    const { selectedChannel, loggedinUser } = this.props.user.getData();
    const listData = channels.all.map(c => ({ value: c.uid, id: c.id, label: c.name }));
    let currentChannel = _.find(listData, { value: channel.uid });
    if (loggedinUser.channel && loggedinUser.channel.uid) {
      const c = loggedinUser.channel;
      currentChannel = { value: c.uid, id: c.cid, label: c.name };
    } else if (selectedChannel) {
      currentChannel = _.find(listData, { value: selectedChannel.uid });
    }
    if (currentChannel) {
      await this.selectChannel(currentChannel);
    }
    return listData;
  }

  getSellerOptionList() {
    let { channelSellers } = this.props.seller.getData();
    let sellerArray = channelSellers.reduce((pre, sellers) => {
      if (sellers.seller.type === 'channel') {
        pre.unshift({
          value: sellers.seller.id,
          label: '** ' + channelSellers.filter(s => s.seller.name === sellers.seller.name).length > 1 ? `${sellers.seller.name} (${sellers.seller_code})` : sellers.seller.name,
          channelSeller: sellers,
        });
      } else if (sellers.seller.type === 'partner') {
        pre.push({
          value: sellers.seller.id,
          label: channelSellers.filter(s => s.seller.name === sellers.seller.name).length > 1 ? `${sellers.seller.name} (${sellers.seller_code})` : sellers.seller.name,
          channelSeller: sellers,
        });
      }
      return pre;
    }, []);
    return sellerArray;
  }
  componentWillUnmount() {
    this.props.transfer.clearData();
    this.props.transfer.changeGateway(null);
    this.props.transfer.changeTransferType('pre-transfer');
    this.props.transfer.changeActiveDefaultTransferStatus(null);
    this.props.transfer.changeChannel(null);
  }
  selectSeller(seller) {
    this.props.transfer.changeSeller(seller);
    const { transfer_type } = this.props.transfer.getData();
    if (transfer_type === 'pre-transfer') {
      this.onClickSearch();
    }
  }

  async clearSeller() {
    this.props.transfer.changeSeller('');
    this.props.transfer.clearData();
    this.props.transfer.getTransfer();
    await this.onClickSearch();
  }

  async selectChannel(channel) {
    this.props.transfer.setLoading(true);
    this.props.transfer.changeChannel(channel);
    await this.props.seller.fetchSellersByStatus('approved', channel.value);
    const sellerOpt = this.getSellerOptionList();
    this.defaultSellerOpt = sellerOpt;
    let state = { ...this.state.data, sellerDataList: sellerOpt };
    this.setState(
      {
        data: state,
      },
      () => this.props.transfer.setLoading(false)
    );
  }

  async createTransfer() {
    let result = await Swal.fire({
      customClass: 'nexter-alert',
      text: 'ยืนยันที่จะสร้างการโอนเงินนี้?',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'สร้างการโอนเงิน',
      confirmButtonClass: 'p0',
      reverseButtons: true,
    });

    if (result.value) {
      const { gateway, transfer } = this.props.transfer.getData();
      if (transfer.items.length > 0 || partner_transfers.length > 0) {
        await this.props.transfer.createTransferReport(gateway);
        await this.props.transfer.exportFile({ createTransferItemDetail: true, source: 'Payment gateway (Pre-Transfer)' });
        await this.onClickSearch();
      }
    }
  }
  async exportFile() {
    const { transfer_type } = this.props.transfer.getData();
    if (transfer_type === 'transfer') {
      this.createT2pPayOut();
    } else if (transfer_type === 'pre-transfer') {
      await this.props.transfer.exportFile({ source: 'Payment gateway (Pre-Transfer)' });
      await this.onClickSearch();
    }
  }

  createT2pPayOut() {
    const { gateway } = this.props.transfer.getData();
    this.props.transfer.getTransferExcelFile(gateway, 'Payment gateway (Transfer)');
  }
  async onClickSearch() {
    const { transfer_type, gateway } = this.props.transfer.getData();
    if (gateway) {
      if (transfer_type === 'transfer') {
        await this.props.transfer.getTransfer();
      } else if (transfer_type === 'pre-transfer') {
        await this.props.transfer.getTransfer('created');
      }
    }
  }
  onSelectGateway(gateway) {
    this.props.transfer.changeGateway(gateway);
    this.props.transfer.changeTransferReport(null);
    this.props.payment.changeShowAllPayment(true);
    this.onClickSearch();
  }
  changeDate(name, date) {
    if (name === 'start_date') {
      this.props.transfer.changeStartDate(date);
    }
    if (name === 'end_date') {
      this.props.transfer.changeEndDate(date);
    }
  }
  changeActiveTab(e) {
    const transferType = e.target.id;
    const { gateway } = this.props.transfer.getData();
    this.props.transfer.changeTransferType(transferType);
    this.props.transfer.changeTransferReport(null);
    this.props.payment.changeShowAllPayment(true);
    if (transferType === 'pre-transfer') {
      this.props.transfer.getTransfer('created');
      this.props.transfer.changeActiveDefaultTransferStatus(gateway);
    }
  }
  getTitlePage() {
    const { transfer_type, gateway, transfer } = this.props.transfer.getData();
    let gatewayName = '';
    let TitlePage = '';
    switch (gateway) {
      case 'linepay':
        gatewayName = 'Line Pay';
        break;
      case '2c2p':
        gatewayName = '2C2P';
        break;
      case '123':
        gatewayName = '123 Service';
        break;
      case 't2p':
        gatewayName = 'T2P';
        break;
      case 'opn':
        gatewayName = 'OPN';
        break;
      default:
        break;
    }
    if (transfer_type === 'pre-transfer') {
      TitlePage = `เตรียมการโอนเงิน - ${gatewayName}`;
    } else if (transfer_type === 'transfer') {
      if (transfer.to_seller.type === 'channel') {
        TitlePage = 'รายการโอนเงินให้ Channel';
      } else if (transfer.to_seller.type === 'partner') {
        TitlePage = 'รายการโอนเงินให้ Seller';
      }
    }
    return TitlePage;
  }

  printPDF() {
    window.print();
  }

  filterSellerOpt = (filterOnlySellerActive = true) => {
    const sellerOpt = this.defaultSellerOpt.filter(list => (filterOnlySellerActive ? list.channelSeller.seller.is_active : true));
    let state = { ...this.state.data, sellerDataList: sellerOpt };
    this.setState({ data: state });
  };

  render() {
    const { loading, transfer_type, transfer_condition, gateway, transfer, channel_selected } = this.props.transfer.getData();
    const { loggedinUser } = this.props.user.getData();
    return (
      <Page loading={loading}>
        <Row>
          <Col md="12" className="no-print">
            <NexterLabelHeader>PAYMENT GATEWAY</NexterLabelHeader>
          </Col>
        </Row>
        <Visibility permission_code="transfer:view">
          <Visibility isVisibility={!(loggedinUser.channel && loggedinUser.channel.uid)}>
            <Row>
              <Col md="4" className="no-print my-3">
                <NexterInput
                  type="select"
                  className={'nexter-select-sm payment-channel-select'}
                  options={this.state.data.channelDataList}
                  onChange={this.selectChannel.bind(this)}
                  value={channel_selected}
                  placeholder="เลือก CHANNEL"
                />
              </Col>
            </Row>
          </Visibility>
          <Visibility isVisibility={!_.isEmpty(channel_selected)}>
            <Row>
              <Col md="12">
                <PaymentGatewaySelect
                  active={gateway}
                  paymentGateway={[{ gateway: '2c2p' }, { gateway: '123' }, { gateway: 't2p' }, { gateway: 'linepay' }, { gateway: 'opn' }]}
                  onSelect={this.onSelectGateway.bind(this)}
                />
              </Col>
            </Row>
          </Visibility>

          <Visibility isVisibility={!_.isEmpty(gateway)}>
            <Row>
              <Col md={transfer_type === 'transfer' ? '3' : '4'}>
                <NexterLabelSpan>{this.getTitlePage()}</NexterLabelSpan>
              </Col>
              <Col md={transfer_type === 'transfer' ? '10' : '8'} className="filer-section no-print">
                <Visibility isVisibility={transfer_type === 'transfer' && transfer_condition.report_no === null}>
                  <NexterInput
                    type="select"
                    placeholder="เลือกผู้ขาย/ผู้รับเงิน..."
                    size="sm"
                    className="seller no-print"
                    value={transfer_condition.seller_selected}
                    options={this.state.data.sellerDataList}
                    onChange={this.selectSeller.bind(this)}
                  />
                  <NexterButton disabled={transfer_condition.seller_selected === ''} href="#" className="no-print mr-1" size="sm" color="primary" onClick={this.clearSeller.bind(this)}>
                    CLEAR
                  </NexterButton>
                  <DatePicker
                    customInput={
                      <CustomInputDatePicker size="sm">
                        <NexterInput
                          placeholder="วันที่เริ่ม"
                          readOnly
                          className="chai"
                          value={transfer_condition.start_date ? format(transfer_condition.start_date, 'dd/MM/yyyy HH:mm') : transfer_condition.start_date}
                        />
                      </CustomInputDatePicker>
                    }
                    selected={transfer_condition.start_date}
                    onChange={this.changeDate.bind(this, 'start_date')}
                    locale="th"
                    dateFormat="dd/MM/yyyy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                  />
                  <DatePicker
                    customInput={
                      <CustomInputDatePicker size="sm">
                        <NexterInput
                          placeholder="วันที่สิ้นสุด"
                          readOnly
                          size="sm"
                          value={transfer_condition.end_date ? format(transfer_condition.end_date, 'dd/MM/yyyy HH:mm') : transfer_condition.end_date}
                        />
                      </CustomInputDatePicker>
                    }
                    selected={transfer_condition.end_date}
                    onChange={this.changeDate.bind(this, 'end_date')}
                    locale="th"
                    dateFormat="dd/MM/yyyy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                  />
                  <NexterButton href="#" size="sm" color="default" icon="fas fa-search no-content" onClick={this.onClickSearch.bind(this)} />
                  <Visibility isVisibility={transfer_condition.status !== 'exported'}>
                    <NexterButton href="#" className="no-print mr-1" size="sm" color="primary" icon="fas fa-download" onClick={this.exportFile.bind(this)}>
                      Excel
                    </NexterButton>
                  </Visibility>
                </Visibility>
                <Visibility isVisibility={transfer_type === 'pre-transfer'}>
                  <NexterInput
                    type="select"
                    placeholder="เลือกผู้ขาย/ผู้รับเงิน..."
                    size="sm"
                    className="no-print seller"
                    value={transfer_condition.seller_selected}
                    options={this.state.data.sellerDataList}
                    onChange={this.selectSeller.bind(this)}
                  />
                  <NexterButton disabled={transfer_condition.seller_selected === ''} href="#" className="no-print mr-1" size="sm" color="primary" onClick={this.clearSeller.bind(this)}>
                    CLEAR
                  </NexterButton>
                  <Visibility permission_code="transfer:request">
                    <NexterButton
                      href="#"
                      className="no-print mr-1"
                      size="sm"
                      color="primary"
                      icon="fas fa-edit"
                      disabled={transfer.summary && transfer.summary.transfer_net < 0}
                      onClick={this.createTransfer.bind(this)}
                    >
                      สร้างการโอนเงิน
                    </NexterButton>
                  </Visibility>
                  <NexterButton href="#" className="no-print mr-1" size="sm" color="primary" icon="fas fa-download" onClick={this.exportFile.bind(this)}>
                    EXCEL
                  </NexterButton>
                  <NexterButton href="#" className="no-print mr-1" size="sm" color="info" outline icon="fas fa-print" onClick={this.printPDF.bind(this)}>
                    PRINT
                  </NexterButton>
                </Visibility>
              </Col>
            </Row>
            <Row style={{ marginTop: '1em' }}>
              <Col>
                <Nav tabs className="no-print">
                  <NavItem>
                    <NavLink href="#" active={transfer_type === 'pre-transfer'} onClick={this.changeActiveTab} id="pre-transfer">
                      Pre-Transfer
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#" active={transfer_type === 'transfer'} onClick={this.changeActiveTab} id="transfer">
                      Transfer
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={transfer_type}>
                  <TabPane tabId="pre-transfer">
                    <PreTransferManagement />
                  </TabPane>
                  <TabPane tabId="transfer">
                    <TransferManagement filterSellerOpt={this.filterSellerOpt} />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Visibility>
        </Visibility>
        <Visibility permission_code="transfer:view" invisibility_msg>
          <h5>ท่านไม่มีสิทธิ์ดูรายการ Transfer</h5>
        </Visibility>
      </Page>
    );
  }
}

export default inject('transfer', 'seller', 'channel', 'user', 'payment')(observer(TransferPage));
