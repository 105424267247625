import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// react plugin used to create charts
import { Line, Doughnut, Bar, HorizontalBar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import SelectInterval from './components/SelectInterval'
import moment from 'moment'
import * as _ from 'lodash'
import numeral from 'numeral';
import {
  // UncontrolledTooltip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  ButtonGroup,
  Button
} from 'reactstrap';

class PaymentMethodChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_interval: 'month'
    }
  }
  componentWillMount() {
    this.fetchData()
  }
  selectInterval(val) {
    this.setState({ selected_interval: val }, () => {
      this.fetchData()
    })
  }
  async fetchData() {
    await this.props.dashboard.fetchDashboardByPaymentMethod(this.state.selected_interval)

  }
  getColorOption(dataState) {
    let { datasets } = dataState
    var canvas = document.createElement('canvas');
    const ctx = canvas.getContext("2d")

    const gradient = ctx.createLinearGradient(0, 0, 0, 350);
    gradient.addColorStop(0, 'rgba(87, 184, 255, 0.4)');
    gradient.addColorStop(0.5, 'rgba(87, 184, 255, 0.1)');
    gradient.addColorStop(1, 'rgba(87, 184, 255, 0)');

    const gradient2 = ctx.createLinearGradient(0, 0, 0, 350);
    gradient2.addColorStop(0, 'rgba(126, 132, 163, 0.4)');
    gradient2.addColorStop(0.5, 'rgba(126, 132, 163, 0.1)');
    gradient2.addColorStop(1, 'rgba(126, 132, 163, 0)');

    const gradient3 = ctx.createLinearGradient(0, 0, 0, 350);
    gradient3.addColorStop(0, 'rgba(33, 213, 155, 0.4)');
    gradient3.addColorStop(0.5, 'rgba(33, 213, 155, 0.1)');
    gradient3.addColorStop(1, 'rgba(33, 213, 155, 0)');

    const gradient4 = ctx.createLinearGradient(0, 0, 0, 350);
    gradient4.addColorStop(0, 'rgba(255, 199, 0, 0.4)');
    gradient4.addColorStop(0.5, 'rgba(255, 199, 0, 0.1)');
    gradient4.addColorStop(1, 'rgba(255, 199, 0, 0)');

    const gradient5 = ctx.createLinearGradient(0, 0, 0, 350);
    gradient5.addColorStop(0, 'rgba(240, 20, 47, 0.4)');
    gradient5.addColorStop(0.5, 'rgba(240, 20, 47, 0.1)');
    gradient5.addColorStop(1, 'rgba(240, 20, 47, 0)');

    const gradient6 = ctx.createLinearGradient(0, 0, 0, 350);
    gradient6.addColorStop(0, 'rgba(17, 85, 204, 0.4)');
    gradient6.addColorStop(0.5, 'rgba(17, 85, 204, 0.1)');
    gradient6.addColorStop(1, 'rgba(17, 85, 204, 0)');

    const gradient7 = ctx.createLinearGradient(0, 0, 0, 350);
    gradient7.addColorStop(0, 'rgba(255, 141, 198, 0.4)');
    gradient7.addColorStop(0.5, 'rgba(255, 141, 198, 0.1)');
    gradient7.addColorStop(1, 'rgba(255, 141, 198, 0)');

    const gradient8 = ctx.createLinearGradient(0, 0, 0, 350);
    gradient8.addColorStop(0, 'rgba(250, 130, 49, 0.4)');
    gradient8.addColorStop(0.5, 'rgba(250, 130, 49, 0.1)');
    gradient8.addColorStop(1, 'rgba(250, 130, 49, 0)');
    
    const gradient9 = ctx.createLinearGradient(0, 0, 0, 350);
    gradient9.addColorStop(0, 'rgba(123, 33, 159, 0.4)');
    gradient9.addColorStop(0.5, 'rgba(123, 33, 159, 0.1)');
    gradient9.addColorStop(1, 'rgba(123, 33, 159, 0)');

    const gradient10 = ctx.createLinearGradient(0, 0, 0, 350);
    gradient10.addColorStop(0, 'rgba(0, 158, 53, 0.4)');
    gradient10.addColorStop(0.5, 'rgba(0, 158, 53, 0.1)');
    gradient10.addColorStop(1, 'rgba(0, 158, 53, 0)');

    const gradient11 = ctx.createLinearGradient(0, 0, 0, 350);
    gradient9.addColorStop(0, 'rgba(102, 0, 0, 0.4)');
    gradient9.addColorStop(0.5, 'rgba(102, 0, 0, 0.1)');
    gradient9.addColorStop(1, 'rgba(102, 0, 0, 0)');

    const gradient12 = ctx.createLinearGradient(0, 0, 0, 350);
    gradient9.addColorStop(0, 'rgba(0, 0, 0, 0.4)');
    gradient9.addColorStop(0.5, 'rgba(0, 0, 0, 0.1)');
    gradient9.addColorStop(1, 'rgba(0, 0, 0, 0)');

    let option = {
      credit_card: {
        label: "Credit Card",
        fill: true,
        backgroundColor: gradient,
        borderColor: "#57b8ff",
        pointBackgroundColor: '#57b8ff',
        lineTension: 0
      },
      qr_box: {
        label: "QR / Bill Payment (BBL)",
        fill: true,
        borderColor: '#1155cc' ,
        pointBackgroundColor: '#1155cc' ,
        backgroundColor: gradient6,
        lineTension: 0
      },
      line: {
        label: "Line Pay",
        fill: true,
        borderColor: '#21d59b',
        pointBackgroundColor: '#21d59b',
        backgroundColor: gradient3,
        lineTension: 0
      },
      bill_payment_mobile: {
        label: "Mobile Banking",
        fill: true,
        borderColor: "#ffc700",
        pointBackgroundColor: '#ffc700',
        backgroundColor: gradient4,
        lineTension: 0
      },
      scg_wallet: {
        label: "SCG Cash Card / SCG Wallet",
        fill: true,
        borderColor: '#f0142f',
        pointBackgroundColor: '#f0142f',
        backgroundColor: gradient5,
        lineTension: 0
      },
      bill_payment: {
        label: "Counter Service",
        fill: true,
        borderColor: '#7e84a3',
        pointBackgroundColor: '#7e84a3',
        backgroundColor: gradient2,
        lineTension: 0
      },
      promptpay: {
        label: "Thai QR Payment",
        fill: true,
        borderColor: '#7b219f',
        pointBackgroundColor: '#7b219f',
        backgroundColor: gradient9,
        lineTension: 0
      },
      installment: {
        label: "Installment (แบ่งชำระ)",
        fill: true,
        borderColor: '#ff8dc6',
        pointBackgroundColor: '#ff8dc6',
        backgroundColor: gradient7,
        lineTension: 0
      },
      chang_family_wallet: {
        label: "Chang Family Wallet",
        fill: true,
        borderColor: '#fa8231',
        pointBackgroundColor: '#fa8231',
        backgroundColor: gradient8,
        lineTension: 0
      },
      grabpay: {
        label: "GrabPay",
        fill: true,
        borderColor: '#009E35',
        pointBackgroundColor: '#009E35',
        backgroundColor: gradient10,
        lineTension: 0
      },
      cbdc: {
        label: "CBDC Payment",
        fill: true,
        borderColor: '#660000',
        pointBackgroundColor: '#660000',
        backgroundColor: gradient11,
        lineTension: 0
      },
      mobile_banking_opn: {
        label: "Mobile Banking (App Switch)",
        fill: true,
        borderColor: '#000000',
        pointBackgroundColor: '#000000',
        backgroundColor: gradient12,
        lineTension: 0
      },
    }
    let dataWithOption = datasets.map(item => {
      if (option[item.label]) {
        return { ...option[item.label], data: item.data }
      } else {
        return item
      }
    })
    dataState.datasets = dataWithOption
    return dataState
  }
  render() {
    let { payment_method: { dataset } } = this.props.dashboard.getData();
    dataset = this.getColorOption(dataset)

    const chartOption = {
      options: {
      tooltips: {
        bodyFontColor: '#161616',
        borderWidth:1,
       
        
     
        custom: function(tooltip) {
          
          tooltip.caretSize = 10,
          tooltip.backgroundColor = "#ffff",
          tooltip.titleFontColor = "#161616",
          tooltip.titleFontStyle = "bold",
          tooltip.bodyFontColor = "#161616",
          tooltip.borderColor='#7e84a3'
      },
    callbacks: {
      title: function(tooltipItem, data) {
        return tooltipItem[0].xLabel;
      },
      label: function(tooltipItem, data) {
        return ' ' + data.datasets[tooltipItem.datasetIndex].label + ':' +'฿' + numeral(tooltipItem.yLabel || 0).format('0,0.00').toLocaleString();
      }
    }
  },
        
      
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              userCallback: function(label, index, labels) {
                return numeral(label).format('0,0.00')
              },
            },
            scaleLabel: {
              display: true,
              labelString: 'จำนวน (บาท)'
            }
          }],
          xAxes: [{
            offset: true
         
          }],
          
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true,
          },
        },
      }
    };
    return (

      <Card className="card-chart">

        <CardHeader>
          <div className="chart-header-row">
            <CardTitle className="chart-title " tag="h5">ยอดรวมแยกตามประเภทการชำระเงิน</CardTitle>
            <SelectInterval selectInterval={(val) => this.selectInterval(val)} />
          </div>
        </CardHeader>
        <CardBody>
          <div className="no-print">
            <Line data={dataset} options={chartOption.options} width={320} height={185} />
          </div>
        </CardBody>
      </Card>


    )
  }
}
export default inject('dashboard')(observer(PaymentMethodChart));
