import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row, Nav, NavItem, NavLink } from 'reactstrap';
import { config } from '../../../config';
import { PAYMENT_GATEWAYS } from '../../../constants/paymentConstants';
import { Page } from '../../../components/Page';
import CreateOrUpdate from './components/CreateOrUpdate';
import ListMidChannels from './components/ListMidChannels';
import NexterButton from '../../../components/Button/NexterButton';

const TAB_KEYS = {
  CCPP: PAYMENT_GATEWAYS.CCPP,
  OPN: PAYMENT_GATEWAYS.OPN,
};

export class Mid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreated: true,
      isEdited: false,
      isShowList: true,
      midChannel: null,
      seletedTab: TAB_KEYS.CCPP,
      currentPage: 1,
    };
    this.setShowList = this.setShowList.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onCreated = this.onCreated.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  async componentDidMount() {
    await this.reFetch();
  }

  async reFetch() {
    const { seletedTab } = this.state;
    if (seletedTab === TAB_KEYS.CCPP) {
      await this.props.channel.fetchMIDChannel({ orderBy: 'updated_at' });
    } else if (seletedTab === TAB_KEYS.OPN) {
      await this.props.channel.fetchMIDOpnChannel({ orderBy: 'updated_at' });
    }
  }

  onChangeTab(key) {
    this.setState({ seletedTab: key, currentPage: 1 }, () => {
      this.reFetch();
    });
  }

  onEdit(payload) {
    this.setState({ isShowList: false, isEdited: true, isCreated: false, midChannel: payload });
  }

  onCreated() {
    this.setState({ isCreated: true, isEdited: false, isShowList: false, midChannel: null });
  }

  async setShowList(value) {
    this.setState({ isShowList: value, currentPage: 1 });
    await this.reFetch();
  }

  async onBack() {
    this.setState({ isCreated: true, isEdited: false, isShowList: true, midChannel: null });
    await this.reFetch();
  }

  componentWillUnmount() {
    this.props.channel.clearChannel();
  }

  onChangePage(page) {
    this.setState({ currentPage: page });
  }

  getChannelMidList(seletedTab) {
    const { channels_mid, channels_mid_opn } = this.props.channel.getData();
    if (seletedTab === TAB_KEYS.CCPP) {
      return channels_mid;
    } else if (seletedTab === TAB_KEYS.OPN) {
      return channels_mid_opn;
    }
    return [];
  }

  getListByPage(list = [], page = 1, size = 1) {
    return list.slice((page - 1) * size, page * size);
  }

  render() {
    const { isCreated, isShowList, isEdited, midChannel, seletedTab, currentPage } = this.state;
    const allChannelMids = this.getChannelMidList(seletedTab);
    const channelMids = this.getListByPage(allChannelMids, currentPage, 10);

    return (
      <Page loading={this.props.channel.loading}>
        <Row>
          <Col>
            <Card className="no-r-tl" style={{ minHeight: '320px' }}>
              <CardBody className="pt-0">
                {isShowList && (
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="text-uppercase my-4 system-config-title">MID Channel List</h6>
                    <div>
                      <Button icon="fa fa-plus" size="sm" color="primary" className="nexter-btn text-uppercase" onClick={this.onCreated}>
                        <i className="fa fa-plus mr-2" /> CREATE
                      </Button>
                      <Link to={`${config.web.rootpath}/system-config/history?tab=mid_${seletedTab}`}>
                        <NexterButton className="ml-3 my-0" size="sm" outline icon="fas fa-history">
                          History Log
                        </NexterButton>
                      </Link>
                    </div>
                  </div>
                )}

                {isShowList && (
                  <div className="nav-bar-bottom-line no-print" style={{ borderColor: 'transparent' }}>
                    <Nav tabs>
                      <NavItem>
                        <NavLink active={seletedTab === TAB_KEYS.CCPP} href="#" id="mid-2c2p-list" className="px-5" onClick={() => this.onChangeTab(TAB_KEYS.CCPP)}>
                          2C2P
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink active={seletedTab === TAB_KEYS.OPN} href="#" id="mid-opn-list" className="px-5" onClick={() => this.onChangeTab(TAB_KEYS.OPN)}>
                          OPN
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                )}

                <div className="container-fluid px-0">
                  {isShowList ? (
                    <ListMidChannels
                      gateway={seletedTab}
                      channels={channelMids}
                      pagination={{ activePage: currentPage, total: allChannelMids.length, size: 10, onSelect: this.onChangePage.bind(this) }}
                      onEdit={this.onEdit}
                    />
                  ) : (
                    <CreateOrUpdate
                      gateway={seletedTab}
                      isCreated={isCreated}
                      isEdited={isEdited}
                      channel={this.props.channel}
                      editChannel={midChannel}
                      showList={this.setShowList}
                      onBack={this.onBack}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}

export default inject('channel')(observer(Mid));
