import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Card, CardHeader, CardBody, CardFooter, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { config } from '../../../config';
import { Page } from '../../../components/Page';
import { Pagination } from '../../../components/Pagination/Pagination';
import AutoTransferFilterTab from './AutoTransferFilterTab';
import AutoTransferTable from './AutoTransferTable';
import NexterButton from '../../../components/Button/NexterButton';
import Visibility from '../../../components/Visibility/Visibility';

const P8_ROLES = ['super_admin', 'biz_system', 'ae_marketing', 'manager'];

class AutoTransferListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalFilter: {},
    };
  }

  componentDidMount() {
    this.props.autoTransfer.getAutoTransfers();
    this.props.channel.fetchChannelAll();
    this.tempOriginalFilter();
  }

  componentWillUnmount() {
    const { originalFilter } = this.state;
    this.props.autoTransfer.changeAllFilters(originalFilter);
  }

  tempOriginalFilter() {
    const { filter } = this.props.autoTransfer.getData();
    this.setState({ originalFilter: filter });
  }

  selectPage(page) {
    this.props.autoTransfer.selectPage(page);
  }

  onChangeFilter(type, value) {
    const { autoTransfer } = this.props;
    switch (type) {
      case 'channelUid':
        autoTransfer.changeFilterChannel(value);
        break;
      case 'updatedBy':
        autoTransfer.changeFilterUpdatedBy(value);
        break;
      case 'updatedStartDate':
        autoTransfer.changeFilterUpdatedStartDate(value);
        break;
      case 'updatedEndDate':
        autoTransfer.changeFilterUpdatedEndDate(value);
        break;
      case 'gateways':
        autoTransfer.toggleFilterGateways(value);
        break;
      case 'scheduleTypes':
        autoTransfer.toggleFilterScheduleTypes(value);
        break;
      case 'statuses':
        autoTransfer.toggleFilterStatuses(value);
        break;
      default:
        break;
    }
  }

  onClearAllFilters() {
    this.props.autoTransfer.clearAllFilters();
    this.tempOriginalFilter();
  }

  onClearSomeFilters(keys = []) {
    this.props.autoTransfer.clearSomeFilters(keys);
    this.tempOriginalFilter();
  }

  onSubmitFilter() {
    this.selectPage(1);
    this.tempOriginalFilter();
  }

  render() {
    const { loading, autoTransfers, filter, pagination } = this.props.autoTransfer.getData();
    const { channels } = this.props.channel.getData();
    const { user_rp } = this.props.user.getData();
    const { page, size, totalPage } = pagination;

    const isPermissionEdit = ['auto_transfer:edit'].some(role => user_rp.includes(role));

    return (
      <Page loading={loading}>
        <div className="container-fluid p-2">
          <Row>
            <Col xs={12} md={6} className="label-header text-uppercase mb-4">
              Auto Transfer
            </Col>
            <Col xs={12} md={6} className="d-flex justify-content-end mb-4">
              <Visibility permission_code="auto_transfer:view">
                <Link to={`${config.web.rootpath}/auto-transfers/history`}>
                  <NexterButton className="mr-2 my-0" size="sm" outline icon="fas fa-history">
                    History Log
                  </NexterButton>
                </Link>
              </Visibility>
            </Col>
          </Row>
          <Visibility permission_code="auto_transfer:view">
            <Card className="p-0">
              <Visibility role_list={P8_ROLES}>
                <CardHeader>
                  <AutoTransferFilterTab
                    channels={channels}
                    filter={filter}
                    onChange={this.onChangeFilter.bind(this)}
                    onClearAll={this.onClearAllFilters.bind(this)}
                    onClearSome={this.onClearSomeFilters.bind(this)}
                    onSubmit={this.onSubmitFilter.bind(this)}
                  />
                </CardHeader>
                <hr className="mx-3 border-secondary" />
              </Visibility>

              <CardBody>
                <div className="table-reponsive table-scroll">
                  <AutoTransferTable items={autoTransfers} page={page} size={size} canViewDetail={isPermissionEdit} />
                </div>
              </CardBody>

              <hr className="mx-3 mt-0" />

              <CardFooter>
                <Row>
                  <Col />
                  <Col>
                    <Pagination className="pull-right" activePage={page} displayPageNumber={5} maximumPageNumber={totalPage} onSelect={this.selectPage.bind(this)} />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Visibility>
          <Visibility permission_code="auto_transfer:view" invisibility_msg>
            <h5>ท่านไม่มีสิทธิ์ดูรายการ Auto Transfer</h5>
          </Visibility>
        </div>
      </Page>
    );
  }
}

export default inject('autoTransfer', 'channel', 'user')(observer(AutoTransferListPage));
