import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import swal from 'sweetalert2';
import _ from 'lodash';
import classNames from 'classnames';
import { config } from '../../config';
import { http } from '../../utils/http';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Row, Col, Label, FormGroup, Card, CardHeader, CardBody, CardFooter, Table, CustomInput, Modal, ModalBody } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';
import { EmptyRow } from '../../components/Table/EmptyRow';
import { Pagination } from '../../components/Pagination/Pagination';
import { Page } from '../../components/Page';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-table/react-table.css';

import { CheckBox } from '../../components/Input/CheckBox';
import CustomInputDatePicker from '../../components/Input/DatePicker';
import store from '../../stores';
import { th } from 'date-fns/locale';
// import addDays from 'date-fns/addDays';
registerLocale('th', th);

const viewNewInstallmentDetail = () => {
  store.channel.viewNewInstallmentDetail();
};

const rowModalStyles = classNames('mx-0 pb-3');
const colModalStyles = classNames('px-0');
const colModalDefaultProps = {
  xs: { size: 12 },
  lg: { size: 4 },
};

class InstalmentSetting extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.handleCondition = this.handleCondition.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.executeFilter = this.executeFilter.bind(this);
    this.onFilterChecked = this.onFilterChecked.bind(this);
    this.clearFilterState = this.clearFilterState.bind(this);
    this.selectPage = this.selectPage.bind(this);
    this.hasPermissionCode = this.hasPermissionCode.bind(this);
  }

  getInitialState() {
    return {
      loading: false,
      admin_channel_uid: '',
      user_type: '',
      modal: false,
      card: {
        absorb_interest_rate: 100,
        terms: [],
      },

      // NEW
      now_active_campaign: null,
      installments: [],
      page: 1,
      size: 10,
      total: 1,
      condition: {
        name: '',
        created_at: '',
        start_date: '',
        end_date: '',
      },
      modal_filter: false,
      default_filters: {},
      filters: {
        banks: [
          { value: 'SCB', label: 'SCB', checked: false },
          { value: 'BBL', label: 'BBL', checked: false },
          { value: 'KBANK', label: 'KBANK', checked: false },
          { value: 'UOB', label: 'UOB', checked: false },
          { value: 'BAY', label: 'BAY', checked: false },
          { value: 'KTC', label: 'KTC', checked: false },
          { value: 'TTB', label: 'TTB', checked: false },
          { value: 'TBANK', label: 'TBANK', checked: false },
          { value: 'FCC', label: 'FCC', checked: false },
        ],
        statuses: [
          { value: 'active', label: 'Active', checked: false },
          { value: 'inactive', label: 'Inactive', checked: false },
        ],
      },
    };
  }

  componentDidMount() {
    let { admin_channel_uid } = this.props.channel.getData();
    let { loggedinUser } = this.props.user.getData();
    let user_type = loggedinUser.type;
    this.setState({ admin_channel_uid, user_type }, async () => {
      await this.fetchChannelInstallments();
    });
  }
  getSearchQueryString() {
    const { admin_channel_uid, page, size, condition, filters } = this.state;

    const params = { page, size };
    const banks = filters.banks.filter(s => s.checked);
    const statuses = filters.statuses.filter(s => s.checked);
    if (admin_channel_uid) params['channel_uid'] = admin_channel_uid;
    if (condition.name) params['name'] = condition.name;
    if (!_.isEmpty(banks)) params['abbr'] = _.map(banks, 'value').join(',');
    if (!_.isEmpty(statuses)) params['statuses'] = _.map(statuses, 'value').join(',');
    if (condition.created_at && condition.created_at instanceof Date) params['created_at'] = condition.created_at.getTime();
    if (condition.start_date && condition.start_date instanceof Date) params['start_date'] = condition.start_date.getTime();
    if (condition.end_date && condition.end_date instanceof Date) params['end_date'] = condition.end_date.getTime();
    params['source'] = 'Channel setting (Installment Campaign)';
    let query = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');
    return query;
  }
  async fetchChannelInstallments() {
    try {
      this.setState({ loading: true });
      let query = this.getSearchQueryString();
      let url = `${config.npay.apiUrl}/channels/settings/installment?${query}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        let data = body.data;
        const installments = data.installments;
        this.setState({
          installments,
          total: data.total ? data.total : 1,
          now_active_campaign: data.now_active_campaign ? data.now_active_campaign : null,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ loading: false });
    }
  }
  async exportExcel() {
    try {
      let query = this.getSearchQueryString();
      let url = `${config.npay.apiUrl}/excel/campaign?${query}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        window.open(body.data.url);
      }
    } catch (err) {
      console.error(err);
    }
  }
  async updateCampaignStatus(installment_id) {
    try {
      let body = { id: installment_id };
      let url = `${config.npay.apiUrl}/channels/settings/campaign/status`;
      const response = await http.put(url, { body });
      const [response_1, json] = await Promise.all([response, response.json()]);
      if (!response_1.ok) throw new Error(json.message);
      const data = json.data;
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  changeCampaignStatus(installment_id) {
    swal
      .fire({
        customClass: 'nexter-alert',
        type: 'warning',
        text: 'ยืนยันเปลี่ยนสถานะ Campaign นี้ ?',
        showCancelButton: true,
        cancelButtonText: 'CANCEL',
        confirmButtonText: 'CONFIRM',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: async () => {
          return await this.updateCampaignStatus(installment_id);
        },
        allowOutsideClick: () => !swal.isLoading(),
      })
      .then(result => {
        if (result?.value?.status) {
          this.selectPage(1);
        } else if (!result?.value?.status && result?.value?.message) {
          swal.fire({
            customClass: 'nexter-alert',
            title: result?.value?.message || '',
            type: 'warning',
            html: 'กำลัง Active ในช่วงเวลาเดียวกัน<br />ไม่สามารถเปลี่ยนสถานะได้',
            showCancelButton: false,
            confirmButtonText: 'CONTINUE',
          });
        }
      });
  }
  selectPage(page) {
    this.setState({ page }, async () => {
      await this.fetchChannelInstallments();
    });
  }
  onFind() {
    this.selectPage(1);
  }
  onClear() {
    this.setState({
      condition: {
        name: '',
        created_at: '',
        start_date: '',
        end_date: '',
      },
      filters: _.cloneDeep(this.getInitialState().filters),
    });
  }
  toggleFilter() {
    const toggle = !this.state.modal_filter;
    // set filters back when closing (not save).
    const filters = _.cloneDeep(!toggle ? this.state.default_filters : this.state.filters);
    // set default filters when opening.
    const default_filters = _.cloneDeep(toggle ? this.state.filters : {});
    this.setState({ modal_filter: toggle, filters, default_filters });
  }
  onFilterChecked(index, type) {
    const filters = _.cloneDeep(this.state.filters);
    // select only one
    if (type === 'date_selected') {
      filters[type] = filters[type].map(f => ({ ...f, checked: false }));
    }
    filters[type][index].checked = !filters[type][index].checked;
    this.setState({ filters });
  }
  executeFilter() {
    this.setState({ modal_filter: !this.state.modal_filter, default_filters: {} });
  }
  clearFilterState() {
    this.setState({ filters: _.cloneDeep(this.getInitialState().filters) });
  }
  handleCondition(name, val) {
    const condition = this.state.condition;
    condition[name] = val;
    this.setState({ condition });
  }

  disabledEditByRole(type) {
    const { user_type } = this.state;
    switch (type) {
      case 'admin_select': // enable only 3 roles
        return !['as_channel_admin', 'channel_admin', 'channel_user'].some(r => r === user_type);
      default:
        return false;
    }
  }

  hasPermissionCode(permission_code) {
    const { user_rp } = this.props.user.getData();
    return user_rp.includes(permission_code);
  }

  render() {
    const { admin_channel_uid, loading, filters, condition, now_active_campaign, installments } = this.state;
    const historyLogUrl = this.state.admin_channel_uid
      ? `${config.web.rootpath}/channels/${this.state.admin_channel_uid}/setting/history?tab=installment-campaign`
      : `${config.web.rootpath}/channel-setting/campaign/history?tab=installment-campaign`;

    let rows =
      installments && installments.length ? (
        this.state.installments.map((item, i) => {
          const detail_url = this.state.admin_channel_uid
            ? `${config.web.rootpath}/channel-setting/campaign/${item.id}/edit/${this.state.admin_channel_uid}`
            : `${config.web.rootpath}/channel-setting/campaign/${item.id}/edit`;
          return (
            <tr key={i}>
              <td>{item.name ? <Link to={detail_url}>{item.name}</Link> : '-'}</td>
              <td>{item.created_by || '-'}</td>
              <td>{item.created_at ? moment(item.created_at).format('DD/MM/YYYY') : '-'}</td>
              <td>{item.start_date ? moment(item.start_date).format('DD/MM/YYYY') : '-'}</td>
              <td>{item.end_date ? moment(item.end_date).format('DD/MM/YYYY') : '-'}</td>
              <td style={{ maxWidth: 166 }}>{item.cards && item.cards.length > 0 ? [...new Set(_.map(item.cards, 'abbr').filter(s => !!s))].join(', ') : '-'}</td>
              <td>
                {item.status ? (
                  <>
                    <i className={classNames('channel-status far fa-dot-circle', item.status)}></i> {item.status === 'active' ? 'Enable' : 'Disable'}
                  </>
                ) : (
                  '-'
                )}
              </td>
              <td>
                <CustomInput
                  type="switch"
                  id={item.id}
                  disabled={!this.hasPermissionCode('channel:set_channel_installment_campaign')}
                  checked={item.status === 'active'}
                  onChange={this.changeCampaignStatus.bind(this, item.id)}
                />
              </td>
            </tr>
          );
        })
      ) : (
        <EmptyRow colSpan={8} displayText="No Data" />
      );

    return (
      <Page loading={loading}>
        <Card className="card-channelmanagement">
          <CardBody>
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="text-uppercase title-channel">{admin_channel_uid ? 'รายการ Campaign ผ่อนชำระ' : 'ตั้งค่า Campaign ผ่อนชำระ'}</h6>
              <Link to={historyLogUrl}>
                <NexterButton className="my-0" size="sm" outline icon="fas fa-history">
                  History Log
                </NexterButton>
              </Link>
            </div>
            <Row className="campaign-search-wrapper">
              <Col lg="3" className="pr-1">
                <FormGroup>
                  <Label className="control-label form-label">พิมพ์ค้นหา</Label>
                  <NexterInput
                    size="sm"
                    name="name"
                    value={condition.name}
                    onChange={e => this.handleCondition(e.target.name, e.target.value)}
                    placeholder="Campaign Name / Created by"
                    className="nexter-input-sm"
                  />
                </FormGroup>
              </Col>
              <Col lg={2} className="pl-0 pr-1" style={{ maxWidth: 135 }}>
                <FormGroup>
                  <Label className="control-label form-label">ค้นหาวันที่สร้าง</Label>
                  <DatePicker
                    customInput={
                      <CustomInputDatePicker size="sm" iconCalendarStyle={{ position: 'absolute', right: 5, top: 5 }}>
                        {condition.created_at ? (
                          <span className="datepicker-custom-text">{moment(condition.created_at).format('DD/MM')}</span>
                        ) : (
                          <span className="datepicker-custom-text">วันที่สร้าง</span>
                        )}
                      </CustomInputDatePicker>
                    }
                    selected={condition.created_at}
                    onChange={val => this.handleCondition('created_at', val)}
                    locale="th"
                    dateFormat="dd/MM/yyyy"
                  />
                </FormGroup>
              </Col>
              <Col lg={3} className="pl-0 pr-1">
                <FormGroup>
                  <Label className="control-label form-label">ค้นหาช่วงวันที่จัด Campaign</Label>
                  <Row>
                    <Col className="pr-0">
                      <DatePicker
                        customInput={
                          <CustomInputDatePicker size="sm" iconCalendarStyle={{ position: 'absolute', right: 5, top: 5 }}>
                            {condition.start_date ? (
                              <span className="datepicker-custom-text">{moment(condition.start_date).format('DD/MM')}</span>
                            ) : (
                              <span className="datepicker-custom-text">วันที่เริ่ม</span>
                            )}
                          </CustomInputDatePicker>
                        }
                        selected={condition.start_date}
                        onChange={val => this.handleCondition('start_date', val)}
                        locale="th"
                        dateFormat="dd/MM/yyyy"
                        maxDate={condition.end_date}
                      />
                    </Col>
                    <Col className="pl-0">
                      <DatePicker
                        customInput={
                          <CustomInputDatePicker size="sm" iconCalendarStyle={{ position: 'absolute', right: 5, top: 5 }}>
                            {condition.end_date ? (
                              <span className="datepicker-custom-text">{moment(condition.end_date).format('DD/MM')}</span>
                            ) : (
                              <span className="datepicker-custom-text">วันที่สิ้นสุด</span>
                            )}
                          </CustomInputDatePicker>
                        }
                        selected={condition.end_date}
                        onChange={val => this.handleCondition('end_date', val)}
                        locale="th"
                        dateFormat="dd/MM/yyyy"
                        minDate={condition.start_date}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col className="pl-0">
                <FormGroup>
                  <Label className="control-label form-label">&nbsp;</Label>
                  <br />
                  <div className="d-flex justify-content-between">
                    <div>
                      <NexterButton size="sm" color="info" onClick={this.onClear.bind(this)} outline>
                        clear
                      </NexterButton>
                      <NexterButton size="sm" color="dark" onClick={this.onFind.bind(this)} icon="fas fa-search" className="mx-1">
                        search
                      </NexterButton>
                    </div>
                    <NexterButton size="sm" color="dark" onClick={this.toggleFilter} icon="fas fa-filter">
                      Filter
                    </NexterButton>
                  </div>
                </FormGroup>
                <Modal isOpen={this.state.modal_filter} toggle={this.toggleFilter} className="modal-payment-filter">
                  <ModalBody>
                    <h6 className="text-capitalize">filters</h6>
                    <hr />
                    <h6>บัตรเครดิตธนาคาร</h6>
                    <Row className={rowModalStyles}>
                      {filters.banks.map((props, i) => (
                        <Col key={i} {...colModalDefaultProps} className={colModalStyles}>
                          <CheckBox {...props} onChange={() => this.onFilterChecked(i, 'banks')} defaultClassName="mx-0" />
                        </Col>
                      ))}
                    </Row>
                    <hr />
                    <h6>สถานะ Campaign</h6>
                    <Row className={rowModalStyles}>
                      {filters.statuses.map((props, i) => (
                        <Col key={i} {...colModalDefaultProps} className={colModalStyles}>
                          <CheckBox {...props} onChange={() => this.onFilterChecked(i, 'statuses')} defaultClassName="mx-0" />
                        </Col>
                      ))}
                    </Row>
                    <hr />
                    <div className="d-flex justify-content-end">
                      <NexterButton size="sm" className="mx-2" onClick={this.clearFilterState.bind(this)}>
                        Clear all filters
                      </NexterButton>
                      <NexterButton size="sm" onClick={this.executeFilter.bind(this)}>
                        Done
                      </NexterButton>
                    </div>
                  </ModalBody>
                </Modal>
              </Col>
            </Row>
            <hr />
            <div className="d-flex justify-content-between">
              <div>
                {now_active_campaign && (
                  <div className="approved-wrapper">
                    <div className="p-2 approved-container">
                      <span>
                        {now_active_campaign.name || ''} กำลัง Active, เหลือ {+moment(now_active_campaign.end_date).add(1, 'days').endOf('day').diff(moment(), 'days') || '-'} วัน
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <NexterButton size="sm" onClick={this.exportExcel.bind(this)} icon="fas fa-download" className="mx-1">
                  excel
                </NexterButton>
                {this.hasPermissionCode('channel:set_channel_installment_campaign') && (
                  <Link to={`${config.web.rootpath}/channel-setting/campaign/create`}>
                    <NexterButton size="sm" icon="fa fa-plus">
                      CREATE CAMPAIGN
                    </NexterButton>
                  </Link>
                )}
              </div>
            </div>
            <hr />
            <Table className="table-channel">
              <thead>
                <tr>
                  <th className="text-capitalize border-0">Campaign Name</th>
                  <th className="text-capitalize border-0">Created by</th>
                  <th className="text-capitalize border-0">วันที่สร้าง</th>
                  <th className="text-capitalize border-0">วันที่เริ่ม</th>
                  <th className="text-capitalize border-0">วันที่สิ้นสุด</th>
                  <th className="text-capitalize border-0">บัตรเครดิตธนาคาร</th>
                  <th className="text-capitalize border-0">สถานะ Campaign</th>
                  <th className="text-capitalize border-0">
                    ตั้งค่าสถานะ
                    <br />
                    Campaign
                  </th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
            <hr />
            <Row>
              <Col />
              <Col>
                <Pagination
                  className="pull-right"
                  activePage={this.state.page}
                  displayPageNumber={5}
                  maximumPageNumber={Math.ceil(this.state.total / this.state.size)}
                  onSelect={page => this.selectPage(page)}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Page>
    );
  }
}

export default inject('channel', 'user')(observer(InstalmentSetting));
