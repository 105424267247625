import React from 'react';
import iNoData from '../../assets/img/icon-no-data.svg';
export const EmptyRow = ({ colSpan, displayText }) => (
  <tr>
    <td colSpan={colSpan} className="text-center">
      <div>
        <img src={iNoData} alt="data not found" />
      </div>
      <span>{displayText || 'ไม่พบข้อมูล'}</span>
    </td>
  </tr>
);
