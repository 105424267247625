import React from 'react';
import { Row, Col, Alert } from 'reactstrap';
import NexterButton from '../../../components/Button/NexterButton';

const OpnAccountAlert = ({ status, rejectReason, canResubmit, onResubmit }) => {
  if (status === 'pending' && canResubmit) {
    return <RequestedStatusAlert onResubmit={onResubmit} />;
  } else if (status === 'rejected') {
    return <RejectedStatusAlert rejectReason={rejectReason} />;
  }
  return null;
};

const RequestedStatusAlert = ({ onResubmit }) => (
  <Alert className="alert-warning-light">
    <Row className="align-items-center">
      <Col className="text-dark">
        รายการนี้ยังไม่ได้รับการตรวจสอบจาก OPN ท่านสามารถกด <b>"ส่งข้อมูลอีกครั้ง"</b> เพื่ออัปเดตข้อมูลให้ตรวจสอบ
      </Col>
      <Col md={4} className="text-right">
        <NexterButton size="sm" onClick={() => onResubmit()}>
          <span style={{ textTransform: 'none' }}>ส่งข้อมูลอีกครั้ง</span>
        </NexterButton>
      </Col>
    </Row>
  </Alert>
);

const RejectedStatusAlert = ({ rejectReason }) => (
  <Alert className="alert-danger-light text-dark">
    <span className="font-weight-bold">เหตุผลในการปฏิเสธ : </span>
    {rejectReason || '-'}
  </Alert>
);

export default OpnAccountAlert;
