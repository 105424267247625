/* eslint-disable prefer-promise-reject-errors */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Table, UncontrolledTooltip, } from 'reactstrap';
import NexterButton from '../../components/Button/NexterButton';
import { CheckBox } from '../../components/Input/CheckBox';
import * as _ from 'lodash'
import Visibility from '../../components/Visibility/Visibility'
import { v4 as uuidv4 } from 'uuid';
class RolePermissionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role_group: {},
      role_list: [],
      rp_group: {},
      rp_list: [],
      sort_role_group: [],
      disabled: true,
      edit: []
    }
    this.onClickBtn = this.onClickBtn.bind(this)

  }
  componentWillReceiveProps(prevProps, prevState) {
    const { role } = prevProps.role.getData()
    if (role.length > 0) {
      const data = this.processData()
      this.setState({ ...data })
    }
  }
  onClickCheck(rowInfo) {
    const { rp_group, edit } = this.state
    let role_permission = rowInfo
    role_permission.group = this.props.groupName
    role_permission.is_active = !role_permission.is_active
    edit.push(role_permission)
    let permInRP = rp_group[role_permission.permission_code]
    if (permInRP) {
      const update = permInRP.map(item => {
        if (item.permission_code === role_permission.permission_code && item.role_id === role_permission.role_id) {
          item = role_permission
        }
        return item
      })
      this.setState({
        rp_group: { ...rp_group, [role_permission.permission_code]: update },
        edit
      })
    }
  }
  processData() {
    const { perm_list } = this.props
    const { role, rolepermission } = this.props.role.getData()
    const roleGroup = _.groupBy(role, 'role_group')
    let keysSorted = Object.keys(roleGroup).sort(function (a, b) { return roleGroup[b].length - roleGroup[a].length })
    const perm_code_list = perm_list.map(i => i.permission_code)
    const role_code_list = role.map(i => i.role_code)
    const mapRolePerms = rolepermission.reduce((pre, item) => {
      const role_code = Object.keys(item)[0]
      let role_perms = []
      const role_info = _.find(role, { role_code: role_code })
      if (item[role_code].length === 0) {
        // not have any permission_code
        role_perms = perm_code_list.map(perms_code => {
          return {
            checked: false,
            id: null,
            is_active: false,
            permission_code: perms_code,
            role_id: role_info?.id,
            role_code: role_code
          }
        })
      } else {
        const pem_in_role = _.groupBy(item[role_code], 'permission_code')
        role_perms = perm_code_list.map(perms_code => {
          let data = _.first(pem_in_role[perms_code])
          if (data) {
            data.role_code = role_code
          } else {
            data = {
              id: null,
              is_active: false,
              permission_code: perms_code,
              role_id: role_info?.id,
              role_code: role_code
            }
          }
          return data
        })
      }
      pre.push(...role_perms)
      return pre
    }, [])
    const groupRolePerm = _.groupBy(mapRolePerms, 'permission_code')
    let updateState = {
      role_list: role_code_list,
      role_group: roleGroup,
      sort_role_group: keysSorted,
      rp_list: mapRolePerms,
      rp_group: groupRolePerm
    }
    return updateState
  }
  onClickBtn(type, e) {
    let { disabled, edit, ...data } = this.state
    if (type === 'edit') {
      disabled = false
    }
    if (type === 'cancel') {
      disabled = true
      data = this.processData()
    }
    if (type === 'save') {
      if (this.props.onSave) {
        this.props.onSave(edit)
      }
      disabled = true
    }
    this.setState({ disabled: disabled, ...data })
  }
  render() {
    const { groupName, perm_list, id } = this.props
    const { role_group, sort_role_group, rp_group, disabled } = this.state
    return (
      <div className="table-margin">
        <div className="table-header">
          <p className="table-title">{groupName}</p>
          <Visibility permission_code="role_permission:edit">
            <div>
              <Visibility isVisibility={disabled}>
                <NexterButton className="btn-sm" icon="fa fa-edit" onClick={this.onClickBtn.bind(this, 'edit')}>Edit</NexterButton>
              </Visibility>
              <Visibility isVisibility={!disabled}>
                <NexterButton className="btn-sm mx-2" icon="fa fa-edit" color="default" outline onClick={this.onClickBtn.bind(this, 'cancel')} id="cancel" >Cancel</NexterButton>
                <NexterButton className="btn-sm" icon="fa fa-edit" color="success" outline onClick={this.onClickBtn.bind(this, 'save')} id="save">Save</NexterButton>
              </Visibility>
            </div>
          </Visibility>
        </div>

        <Table style={{ marginBottom: '0' }}>
          <thead className="text-center grey border">
            <tr>
              <th rowSpan={3} className="grey-white" >
                Responsibilities /Activities
            </th>
            </tr>
            <tr>
              {sort_role_group.map((groupKey, index) => {
                const newGroup = index % 2 === 0
                const colSpan = role_group[groupKey].length
                return (<th className={newGroup ? "grey-black" : "light-grey-black"} colSpan={colSpan} key={groupKey} style={{ width: `${colSpan * 100}px` }}>{groupKey}</th>)
              })}
            </tr>
            <tr className="smoke-white-black" >
              {sort_role_group.map(groupKey => {
                const row = role_group[groupKey].map((role, index, arr) => {
                  return (<th className="light-grey-border" key={role.role_name}>{role.role_name}
                    <br />
                    <i className="fas fa-question-circle gray-font" id={`${id}-${role.id}-${role.role_code}_tool-trip`}>
                      <UncontrolledTooltip placement="top" target={`${id}-${role.id}-${role.role_code}_tool-trip`} >
                        {role.role_description}
                      </UncontrolledTooltip>
                    </i>
                  </th>)
                })
                return row
              })}
            </tr>
          </thead>
          <tbody className="text-center">
            {perm_list && perm_list.map(item => {
              const uuid = uuidv4()
              return (<tr key={`${item.permission_code}-${uuid}`}>
                <td className="grey-border-right" scope="row" >
                  <span id={`${id}-${uuid}_tool-trip`}>{item.permission_name}</span>
                  <UncontrolledTooltip placement="top" target={`${id}-${uuid}_tool-trip`} >
                    {item.permission_code}
                  </UncontrolledTooltip>
                </td>
                {rp_group[item.permission_code] &&
                  rp_group[item.permission_code].map(r => {
                    return (
                      <td className="grey-border-right" scope="row" key={`${r.role_id}-${r.is_active}-${r.permission_code}`}>
                        <CheckBox
                          checked={r.is_active}
                          defaultClassName="display-content"
                          onChange={() => !disabled ? this.onClickCheck(r) : {}}
                          id={`${r.role_id}:${r.permission_code}=${r.is_active}`}
                          disabled={r.id === null}
                        />
                      </td>
                    );
                  })}
              </tr>)
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}
RolePermissionTable.defaultProps = {
  perm_list: []
}
export default inject('role')(observer(RolePermissionTable));
