import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import NexterInput from '../../components/Input/NexterInput';
import FilePicker from '../../components/Forms/FilePicker';
import FileUpload from '../../components/File/FileUpload';
import Number from '../../components/Forms/Number';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-table/react-table.css';
import CustomInputDatePicker from '../../components/Input/DatePicker';
import { CheckBox } from '../../components/Input/CheckBox';
import SellerAddress from './SellerAddress';
import { basename } from '../../utils/string';


class SellerRegisterKYCPersonal extends Component {

  changeKYCPersonalFile(key, files) {
    if (files && files.length) {
      if (files.length > 1) files.shift();
      this.props.sellerRegister.changeKYCPersonalFile(files[0], key);
    }
  }

  handleCurrentAddressChange(data) {
    this.handleChange('current_address', data.address);
    this.handleChange('current_province', data.province);
    this.handleChange('current_district', data.district);
    this.handleChange('current_subdistrict', data.sub_district);
    this.handleChange('current_postcode', data.zipcode);
  }

  handleIdAddressChange(data) {
    this.handleChange('id_address', data.address);
    this.handleChange('id_province', data.province);
    this.handleChange('id_district', data.district);
    this.handleChange('id_subdistrict', data.sub_district);
    this.handleChange('id_zipcode', data.zipcode);
  }

  handleCompanyAddressChange(data) {
    this.handleChange('company_address', data.address);
    this.handleChange('company_province', data.province);
    this.handleChange('company_district', data.district);
    this.handleChange('company_subdistrict', data.sub_district);
    this.handleChange('company_zipcode', data.zipcode);
  }

  handleChange(name, value) {
    this.props.handleChange
      ? this.props.handleChange('kycPersonal', name, value)
      : this.props.sellerRegister.changeKYCPersonalInput(value, name);
  }

  handleKYCFile(name, files) {
    this.props.handleKYCFile
      ? this.props.handleKYCFile('kycPersonal', name, files)
      : this.changeKYCPersonalFile(name, files);
  }

  removeKYCFile(name) {
    if(this.props.removeKYCFile)
      this.props.removeKYCFile('kycPersonal', name)
  }

  getFileName(filename) {
    return filename?.name || basename(filename);
  }

  render() {
    const { seller, kycPersonalUpload, taxId: storeTaxId } = this.props.sellerRegister.getData();
    const colStyle = { size: 9, offset: 1 };
    const data = _.get(seller, 'kyc_personal', {});
    const form = this.props.form || data;
    const upload = this.props.form || kycPersonalUpload;
    const taxId = this.props.taxId || storeTaxId;
    const showInfo = this.props.showInfo !== false;

    return (
      <Fragment>
        {showInfo && (
          <div className="seller-notice-alert">
            <i className="fas fa-exclamation-circle" /> ข้อมูลส่วนนี้ สามารถเพิ่มได้ในภายหลัง
          </div>
        )}
        <Row className="content my-3">
          {/* <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">ชื่อ - นามสกุล</Label>
              <NexterInput
                size="sm"
                name="name"
                value={form.name}
                onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                className="nexter-input-sm"
              />
            </FormGroup>
          </Col> */}
          <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">เลขประจำตัวประชาชน</Label>
              <NexterInput
                size="sm"
                value={taxId}
                disabled={true}
                className="nexter-input-sm"
              />
            </FormGroup>
          </Col>
          {/* <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">เกิดวันที่</Label>
              <DatePicker
                customInput={<CustomInputDatePicker size="sm" iconCalendarStyle={{ position: 'absolute', right: 5, top: 5 }}>
                  {form.dob_at
                    ? <span className="datepicker-custom-text">{moment(form.dob_at).format('DD/MM/YYYY')}</span>
                    : <span className="datepicker-custom-text">DD/MM/YYYY</span>
                  }
                </CustomInputDatePicker>}
                selected={form.dob_at}
                onChange={(val) => this.handleChange('dob_at', val)}
                locale="th"
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </FormGroup>
          </Col> */}
          {/* <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">เบอร์โทรศัพท์</Label>
              <NexterInput
                allow="numeric"
                size="sm"
                name="phone"
                value={form.phone}
                onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                className="nexter-input-sm"
              />
            </FormGroup>
          </Col> */}
          {/* <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">อีเมล</Label>
              <NexterInput
                size="sm"
                name="email"
                value={form.email}
                onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                className="nexter-input-sm"
              />
            </FormGroup>
          </Col> */}
        </Row>
        {/* <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">แบบรายการแสดงตน KYC</Label>
              <FileUpload
                previewSrc={this.getFileName(upload.kyc_personal)}
                onRemove={() => this.removeKYCFile('kyc_personal')}
                onChange={(files) => this.handleKYCFile('kyc_personal', files)}
                inputProps={{
                  multiple: false,
                  accept: 'application/pdf, image/png, image/jpeg',
                }}
                dropZoneProps={{
                  maxSize: 5242880,
                }}
                titleButton="UPLOAD"
                titlePre="รองรับเฉพาะไฟล์ .pdf, .jpg, .png"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">สำเนาบัตรประชาชน พร้อมรับรอง</Label>
              <FileUpload
                previewSrc={this.getFileName(upload.idcard)}
                onRemove={() => this.removeKYCFile('idcard')}
                onChange={(files) => this.handleKYCFile('idcard', files)}
                inputProps={{
                  multiple: false,
                  accept: 'application/pdf, image/png, image/jpeg',
                }}
                dropZoneProps={{
                  maxSize: 5242880,
                }}
                titleButton="UPLOAD"
                titlePre="รองรับเฉพาะไฟล์ .pdf, .jpg, .png"
              />
            </FormGroup>
          </Col>
        </Row> */}
        {/* <hr />
        <h6 className="text-uppercase sub-title"><b>ข้อมูลที่อยู่</b></h6>
        <h6 className="sub-title-2"><b>ที่อยู่ตามบัตรประชาชน</b></h6>
        <SellerAddress
          address={form.id_address}
          province={form.id_province}
          district={form.id_district}
          subDistrict={form.id_subdistrict}
          zipcode={form.id_zipcode}
          onChange={this.handleIdAddressChange.bind(this)}
        />
        <hr />
        <h6 className="sub-title-2"><b>ที่อยู่ปัจจุบันที่ติดต่อได้</b></h6>
        <CheckBox
          id="contact_same"
          name="contact_same"
          checked={!!form.contact_same}
          // disabled={disabledInfo}
          onChange={(e) => this.handleChange(e.target.name, !form.contact_same)}
          defaultClassName="my-auto mr-1"
        />
        <Label className="form-label">ใช้ที่อยู่เดียวกับบัตรประชาชน</Label>
        <SellerAddress
          disabled={!!form.contact_same}
          address={form.current_address}
          province={form.current_province}
          district={form.current_district}
          subDistrict={form.current_subdistrict}
          zipcode={form.current_postcode}
          onChange={this.handleCurrentAddressChange.bind(this)}
        />
        <hr />
        <h6 className="sub-title-2"><b>ที่อยู่ที่ทำงาน</b></h6>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">ชื่อสถานที่ทำงาน</Label>
              <NexterInput
                size="sm"
                name="company_name"
                value={form.company_name}
                onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                className="nexter-input-sm"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">อาชีพ</Label>
              <NexterInput
                size="sm"
                name="occupation"
                value={form.occupation}
                onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                className="nexter-input-sm"
              />
            </FormGroup>
          </Col>
        </Row>
        <SellerAddress
          address={form.company_address}
          province={form.company_province}
          district={form.company_district}
          subDistrict={form.company_subdistrict}
          zipcode={form.company_zipcode}
          onChange={this.handleCompanyAddressChange.bind(this)}
        /> */}
      </Fragment>
    );
  }
}

export default inject('sellerRegister')(observer(SellerRegisterKYCPersonal));
