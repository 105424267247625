import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import NexterButton from '../../components/Button/NexterButton';
import { Row, Col } from 'reactstrap';
import addDays from 'date-fns/addDays';
import Visibility from '../../components/Visibility/Visibility';
import moment from 'moment'
class VoidAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: '',
    };
  }

  componentDidMount() {
    let { paymentInfo, userType } = this.props;
    this.setState({ userType });

    // console.log('PAYMENT INFO', paymentInfo);
    let id = paymentInfo.id;
    if (id) {
      this.props.transfer.getTransferItemById(id);
    }
  }

  void(payment) {
    this.props.payment.voidPayment(payment.uid);
  }

  disabledBtn(paymentInfo) {
    const { method, status, payment_at } = paymentInfo;
    const payment_method_can = ['credit_card', 'scg_wallet', 'installment', 'line', 'chang_family_wallet', 'grabpay'];
    if (payment_method_can.includes(method)) {
      const paidTime = new Date(payment_at).getTime();
      let expire = new Date(paidTime).setHours(20, 45, 0);
      let expireT1 = addDays(new Date(paidTime).setHours(20, 45, 0), 1).getTime();
      const now = new Date().getTime();
      const start_time = addDays(new Date(now).setHours(8, 0, 0), 1).getTime();
      if (status === 'success') {
        let disable = false;
        if (paidTime > expire) {
          disable = true;
        } else if (now > start_time && now < expireT1) {
          disable = false
        }

        if (now < expire) {
          disable = true;
          if (this.state.userType.includes('channel')) {
            if (paymentInfo.void_condition.channel_time) {
              let timeSetting = moment(paymentInfo.void_condition.channel_time, "HH:mm:ss");
              let channelTime = new Date(now).setHours(timeSetting.hour(), timeSetting.minutes(), timeSetting.seconds());
              if (now < channelTime) {
                disable = false;
              }
            }
          } else {
            if (paymentInfo.void_condition.admin_time) {
              let timeSetting = moment(paymentInfo.void_condition.admin_time, "HH:mm:ss");
              let adminTime = new Date(now).setHours(timeSetting.hour(), timeSetting.minutes(), timeSetting.seconds());
              if (now < adminTime) {
                disable = false;
              }
            }
          }
        } else {
          disable = true;
        }
        return disable;
      } else {
        return true;
      }

    } else {
      return true;
    }
  }

  render() {
    let { paymentInfo } = this.props;
    return (
      <Fragment>
        <Row>
          <Col>
            {' '}
            <h4 className="title" style={{ marginTop: '8px' }}>
              Void
            </h4>
          </Col>
          <Col>
            <Visibility permission_code="payment:transaction_void_api">
              <NexterButton className="no-print m-0 pull-right" size="sm" color="primary" outline onClick={this.void.bind(this, paymentInfo)} disabled={this.disabledBtn(paymentInfo)}>
                Void
              </NexterButton>
            </Visibility>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Visibility permission_code="payment:transaction_void_api">
              <b>เงื่อนไขการ Void</b>
              <br />
              <p className="mt-1">
                - ต้องชำระเงินด้วยบัตรเครดิต, Chang Family Wallet, SCG Cash Card, Line Pay หรือ GrabPay
                <br />
                - สถานะต้องเป็น Success เท่านั้น
                <br />
                - ยังไม่สร้างรายการโอนเงิน
                <br />- สามารถ Void ได้ในวันเดียวกันกับวันที่ชำระเงิน และไม่เกิน 20:45 น.
              </p>
            </Visibility>
            <Visibility permission_code="payment:transaction_void_api" invisibility_msg>
              <p>
                <b>ท่านไม่มีสิทธิ์ดำเนินการ Void</b>
              </p>
            </Visibility>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default inject('payment', 'transfer', 'user')(observer(VoidAction));
