import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { address as thai_address } from '../../utils/thai-address';
import NexterInput from '../../components/Input/NexterInput';

const INITIAL_STATE = {
  provinces: [],
  districts: [],
  sub_districts: [],

  address: '',
  province: null,
  district: null,
  sub_district: null,
  zipcode: '',
};

class SellerAddress extends Component {
  constructor(props) {
    super(props);
    this.state = _.cloneDeep(INITIAL_STATE);
  }

  componentDidMount() {
    const provinces = thai_address.getProvinces().map((p) => ({ value: p, label: p }));
    this.setState({ provinces });
  }

  convertToOptions(arr) {
    return arr.map((p) => ({ value: p, label: p }));
  }

  setAddress(province, district, sub_district) {
    const provinces = this.convertToOptions(thai_address.getProvinces());
    const districts = this.convertToOptions(thai_address.getDistricts(province));
    const sub_districts = this.convertToOptions(thai_address.getSubDistricts(province, district));
    const zipcode = thai_address.getZipCode(province, district, sub_district)[0] || '';
    this.setState({ provinces, districts, sub_districts, zipcode });
  }

  handleChange(e) {
    const { province, district, subDistrict, zipcode } = this.props;
    this.setState({ [e.target.name]: e.target.value, province, district, sub_district: subDistrict, zipcode }, () => this.toParent());
  }

  handleProvince(selected) {
    const province = selected.value;
    const districts = this.convertToOptions(thai_address.getDistricts(province));
    this.setState({
      province,
      districts,
      district: null,
      sub_district: null,
      sub_districts: [],
      zipcode: '',
    }, () => this.toParent());
  }

  handleDistrict(selected) {
    const { province } = this.state;
    const district = selected.value;
    const sub_districts = this.convertToOptions(thai_address.getSubDistricts(province, district));
    this.setState({
      district,
      sub_districts,
      sub_district: null,
      zipcode: '',
    }, () => this.toParent());
  }

  handleSubDistrict(selected) {
    const { province, district } = this.state;
    const sub_district = selected.value;
    const zipcode = thai_address.getZipCode(province, district, sub_district)[0] || '';
    this.setState({
      sub_district,
      zipcode,
    }, () => this.toParent());
  }

  toParent() {
    const { address, province, district, sub_district, zipcode } = this.state;
    this.props.onChange && this.props.onChange({
      address,
      province,
      district,
      sub_district,
      zipcode,
    });
  }

  render() {
    const { province, district, subDistrict, disabled } = this.props;
    const provinceSelect = this.state.provinces.find(s => s.value === province) || null;
    const districtSelect = this.state.districts.find(s => s.value === district) || null;
    const subDistrictSelect = this.state.sub_districts.find(s => s.value === subDistrict) || null;
    const zipcode = this.props.zipcode;
    const address = this.props.address;

    if (province && district && subDistrict && !districtSelect && !subDistrictSelect) {
      this.setAddress(province, district, subDistrict);
    }

    return (
      <Fragment>
        <Row>
          <Col>
            <FormGroup>
              <Label className="control-label form-label">ที่อยู่</Label>
              <NexterInput
                size="sm"
                name="address"
                value={address}
                disabled={disabled}
                maxLength={500}
                onChange={this.handleChange.bind(this)}
                className="nexter-input-sm"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label className="control-label form-label">จังหวัด</Label>
              <NexterInput
                type="select"
                size="sm"
                options={this.state.provinces}
                value={provinceSelect}
                disabled={disabled}
                onChange={this.handleProvince.bind(this)}
                className="nexter-select-sm"
                placeholder="เลือกจังหวัด..."
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="control-label form-label">อำเภอ/เขต</Label>
              <NexterInput
                type="select"
                size="sm"
                options={this.state.districts}
                value={districtSelect}
                disabled={disabled}
                onChange={this.handleDistrict.bind(this)}
                className="nexter-select-sm"
                placeholder="เลือกอำเภอ/เขต..."
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="control-label form-label">ตำบล/แขวง</Label>
              <NexterInput
                type="select"
                size="sm"
                options={this.state.sub_districts}
                value={subDistrictSelect}
                disabled={disabled}
                onChange={this.handleSubDistrict.bind(this)}
                className="nexter-select-sm"
                placeholder="เลือกตำบล/แขวง..."
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="control-label form-label">รหัสไปรษณีย์</Label>
              <NexterInput
                allow="numeric"
                size="sm"
                value={zipcode}
                disabled={disabled}
                onChange={() => ({})}
                maxLength={5}
                className="nexter-input-sm"
              />
            </FormGroup>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default SellerAddress;