/* global FormData */
import Swal from 'sweetalert2';
import BaseStore from './BaseStore';
import { config } from '../config';
import { http } from '../utils/http';
import { file as fileUtil } from '../utils/file';

export class ImportStore extends BaseStore {
  constructor() {
    super();
    this.observable(this.getInitialData());
  }

  getInitialData() {
    return {
      loading: false,
      files: {
        seller_excel: null,
        update_seller_excel: null,
        images: [],
        kyc_file_type: 'classify',
      },
    };
  }

  clearData() {
    let { files } = this.getInitialData();
    this.files = files;
  }

  changeFile(file, key) {
    this.files[key] = file;
  }

  changeKycFileType(value) {
    if (this.files && this.files.kyc_file_type) {
      this.files.kyc_file_type = value;
    }
  }

  selectImageFiles(files) {
    this.files.images = files;
  }

  async uploadSeller(key, options = { update: false }) {
    try {
      const file = this.files[key];
      if (!file) {
        console.warn('No file to upload');
        return;
      }

      this.loading = true;
      let formData = new FormData();
      formData.append('sellers', file, file.name);
      formData.append('options', JSON.stringify(options));

      const url = `${config.npay.apiUrl}/import/sellers`;
      const response = await http.post(url, { body: formData });
      if (response.status === 200) {
        const body = await response.json();
        if (body.data.successItems.length > 0 || body.data.existingItems.length > 0) {
          let successMsg = '';
          let errorMsg = '';
          if (body.data.existingItems.length) {
            successMsg += `${options.update ? 'แก้ไข' : 'สร้าง'} Seller สำหรับ channel สำเร็จ ${body.data.existingItems.length} ราย`;
          }
          if (!options.update) {
            successMsg += `<br/>มี Seller ใหม่เข้ามาในระบบ ${body.data.successItems.length} ราย`;
          }
          if (body.data.validateFail) {
            successMsg += `<br/>`;
            errorMsg += `<div">${body.data.validateFail}</div>`;
          }
          // if (body.data.validateFail.length) {
          //   successMsg = `<div style="padding-left: 50px">${successMsg}และมีรายการที่ไม่สำเร็จดังนี้</div>`;
          //   errorMsg = `<ul style="padding-left: 80px">${body.data.validateFail}</ul>`;
          // }
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'success',
            title: 'อัปโหลดข้อมูลขึ้นระบบสำเร็จ!',
            html: `${successMsg}${errorMsg}`,
            confirmButtonText: 'กลับไป',
          }).then(() => {
            setTimeout(() => {
              this.clearData();
            }, 500);
          });
        } else {
          let errorMsg = 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง';
          try {
            if (body.data.validateFail) {
              errorMsg = `<div">${body.data.validateFail}</div>`;
            }
          } catch (e) {}
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'เกิดข้อผิดพลาด!',
            html: errorMsg,
            confirmButtonText: 'ลองอีกครั้ง',
          });
        }
      } else if (response.status === 400) {
        const body = await response.json();

        Swal.fire({
          customClass: 'nexter-alert',
          type: 'warning',
          title: 'Warning',
          html: '<div align="center"> ' + (body.message || body.data?.validateFail) + '</div>',
          showCancelButton: false,
        });
      } else {
        let body = await response.json();
        let liElemSucess = '';
        let liElemExist = '';
        let liElemDup = '';
        body.data.successItems.forEach(el => {
          liElemSucess += '<li>' + el + '</li>';
        });
        body.data.existingItems.forEach(el => {
          liElemExist += '<li>' + el + '</li>';
        });

        if (liElemSucess.length > 0 || liElemExist > 0) {
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'success',
            title: 'อัปโหลดข้อมูลขึ้นระบบสำเร็จ!',
            html: `มี Seller ใหม่ เข้ามาในระบบ ${body.data.successItems.length + body.data.existingItems.length} ราย<br/>`,
            confirmButtonText: 'กลับไป',
          });
        } else {
          let errorMsg = 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง';
          try {
            if (body.data.validateFail) {
              const invalidList = `
                <ul style="padding-left: 80px">${body.data.validateFail}</ul>
              `;
              errorMsg = invalidList;
            }
          } catch (e) {
            console.error(e);
          }
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'เกิดข้อผิดพลาด!',
            html: errorMsg,
            confirmButtonText: 'ลองอีกครั้ง',
          });
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async uploadImageFiles() {
    try {
      let files = this.files.images;
      if (files.length <= 0) {
        console.warn('No files to upload');
        return;
      }

      this.loading = true;
      let formData = new FormData();

      for (const file of files) {
        let compressed = file;
        if (['image/png', 'image/jpeg'].includes(file.type)) compressed = await fileUtil.compressImage(file);
        formData.append(compressed.name, compressed, compressed.name);
      }

      let url = `${config.npay.apiUrl}/import/images`;
      let response = await http.post(url, { body: formData });
      if (response.status === 200) {
        let body = await response.json();
        // console.log("TCL: ImportStore -> uploadImageFiles -> body", body)
        let liElemUploaded = '';
        let liElemNoSeller = '';

        body.data.uploadData.forEach(el => {
          liElemUploaded += '<li>' + el + '</li>';
        });

        body.data.noSellers.forEach(el => {
          liElemNoSeller += '<li>' + el + '</li>';
        });

        let text = '';
        let isError = false;
        if (body.data.uploadData.length && body.data.noSellers.length) {
          text = `ไฟล์ที่อัปโหลด ${body.data.uploadData.length} ไฟล์<br/>และไฟล์ที่ไม่สามารถอัปโหลดได้ ${body.data.noSellers.length} ไฟล์`;
        } else if (body.data.uploadData.length) {
          text = `ไฟล์ที่อัปโหลด ${body.data.uploadData.length} ไฟล์`;
        } else if (body.data.noSellers.length) {
          isError = true;
          text = `ไฟล์ที่ไม่สามารถอัปโหลดได้ ${body.data.noSellers.length} ไฟล์`;
        }

        if (body.data.errors && body.data.errors.length) {
          text += '<br/><br/>'
          body.data.errors.forEach(err => {
            text += `<div><span style="margin-right:5px;">${err.name}</span> <span style="color:#E20B19;">${err.error}</span></div><br/>`
          });
        }

        if (!isError) {
          Swal.fire({
            customClass: 'nexter-alert w-auto',
            type: 'success',
            title: 'อัปโหลดข้อมูลขึ้นระบบสำเร็จ!',
            html: text,
            confirmButtonText: 'กลับไป',
          }).then(() => {
            setTimeout(() => {
              this.clearData();
            }, 500);
          });
        } else {
          Swal.fire({
            customClass: 'nexter-alert w-auto',
            type: 'error',
            title: 'เกิดข้อผิดพลาด!',
            html: text,
            confirmButtonText: 'ลองอีกครั้ง',
          });
        }

        // if (!body.data.uploadData.length) {
        // Swal.fire({
        //   customClass: 'nexter-alert',
        //   type: 'error',
        //   title: 'เกิดข้อผิดพลาด!',
        //   html:
        //     '<div class="w-75 mx-auto mt-2">' +
        //     '<p><strong>ไฟล์ที่ไม่สามารถอัปโหลดได้ กรุณาตรวจสอบรหัสผู้ขาย: ' +
        //     body.data.noSellers.length +
        //     '</strong></p>' +
        //     '<ul class="text-left">' +
        //     liElemNoSeller +
        //     '</ul>' +
        //     '</div>',
        //   confirmButtonText: 'OK',
        // }).then(() => {
        //   setTimeout(() => {
        //     this.clearData();
        //   }, 500);
        // });
        // } else if (!body.data.noSellers.length) {
        // Swal.fire({
        //   customClass: 'nexter-alert',
        //   type: 'success',
        //   title: 'สำเร็จ!',
        //   html: '<div class="w-75 mx-auto mt-2">' + '<p><strong>ไฟล์ที่อัปโหลด: ' + body.data.uploadData.length + '</strong></p>' + '<ul class="text-left">' + liElemUploaded + '</ul>' + '</div>',
        //   confirmButtonText: 'OK',
        // }).then(() => {
        //   setTimeout(() => {
        //     this.clearData();
        //   }, 500);
        // });
        // } else {
        //   Swal.fire({
        //     customClass: 'nexter-alert',
        //     type: 'info',
        //     title: 'โปรดทราบ!',
        //     html:
        //       '<div class="w-75 mx-auto mt-2">' +
        //       '<p><strong>ไฟล์ที่อัปโหลด:</strong> ' +
        //       body.data.uploadData.length +
        //       '</p>' +
        //       '<ul class="text-left">' +
        //       liElemUploaded +
        //       '</ul>' +
        //       '</div>' +
        //       '<div class="w-75 mx-auto mt-2">' +
        //       '<p><strong>ไฟล์ที่ไม่สามารถอัปโหลดได้ กรุณาตรวจสอบรหัสผู้ขาย: ' +
        //       body.data.noSellers.length +
        //       '</strong></p>' +
        //       '<ul class="text-left">' +
        //       liElemNoSeller +
        //       '</ul>' +
        //       '</div>',
        //     confirmButtonText: 'OK',
        //   }).then(() => {
        //     setTimeout(() => {
        //       this.clearData();
        //     }, 500);
        //   });
        // }
      } else {
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง',
          confirmButtonText: 'ลองอีกครั้ง',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async uploadImageFilesUnClassiFy() {
    try {
      let files = this.files.images;
      if (files.length <= 0) {
        console.warn('No files to upload');
        return;
      }

      this.loading = true;
      let formData = new FormData();

      for (const file of files) {
        formData.append(file.name, file, file.name);
      }

      let url = `${config.npay.apiUrl}/import/pdf`;
      let response = await http.post(url, { body: formData });
      if (response.status === 200) {
        let body = await response.json();
        let liElemUploaded = '';
        let liElemNoSeller = '';

        body.data.uploadData.forEach(el => {
          liElemUploaded += '<li>' + el + '</li>';
        });

        body.data.noSellers.forEach(el => {
          liElemNoSeller += '<li>' + el + '</li>';
        });

        let text = '';
        let isError = false;
        if (body.data.uploadData.length && body.data.noSellers.length) {
          text = `ไฟล์ที่อัปโหลด ${body.data.uploadData.length} ไฟล์<br/>และไฟล์ที่ไม่สามารถอัปโหลดได้ ${body.data.noSellers.length} ไฟล์`;
        } else if (body.data.uploadData.length) {
          text = `ไฟล์ที่อัปโหลด ${body.data.uploadData.length} ไฟล์`;
        } else if (body.data.noSellers.length) {
          isError = true;
          text = `ไฟล์ที่ไม่สามารถอัปโหลดได้ ${body.data.noSellers.length} ไฟล์`;
        }

        if (body.data.errors.length) {
          text += '<br/><br/>'
          body.data.errors.forEach(err => {
            text += `<div><span style="margin-right:5px;">${err.name}</span> <span style="color:#E20B19;">${err.error}</span></div><br/>`
          });
        }

        if (!isError) {
          Swal.fire({
            customClass: 'nexter-alert w-auto',
            type: 'success',
            title: 'อัปโหลดข้อมูลขึ้นระบบสำเร็จ!',
            html: text,
            confirmButtonText: 'กลับไป',
          }).then(() => {
            setTimeout(() => {
              this.clearData();
            }, 500);
          });
        } else {
          Swal.fire({
            customClass: 'nexter-alert w-auto',
            type: 'error',
            title: 'เกิดข้อผิดพลาด!',
            html: text,
            confirmButtonText: 'ลองอีกครั้ง',
          });
        }
        // if (!body.data.uploadData.length) {
        //   Swal.fire({
        //     customClass: 'nexter-alert',
        //     type: 'error',
        //     title: 'เกิดข้อผิดพลาด!',
        //     html: '<div class="w-75 mx-auto mt-2">' + '<p><strong>ไฟล์ที่ไม่สามารถอัปโหลดได้ กรุณาตรวจสอบรหัสผู้ขาย:</strong></p>' + '<ul class="text-left">' + liElemNoSeller + '</ul>' + '</div>',
        //     confirmButtonText: 'OK',
        //   }).then(() => {
        //     setTimeout(() => {
        //       this.clearData();
        //     }, 500);
        //   });
        // } else if (!body.data.noSellers.length) {
        //   Swal.fire({
        //     customClass: 'nexter-alert',
        //     type: 'success',
        //     title: 'สำเร็จ!',
        //     html: '<div class="w-75 mx-auto mt-2">' + '<p><strong>ไฟล์ที่อัปโหลด:</strong></p>' + '<ul class="text-left">' + liElemUploaded + '</ul>' + '</div>',
        //     confirmButtonText: 'OK',
        //   }).then(() => {
        //     setTimeout(() => {
        //       this.clearData();
        //     }, 500);
        //   });
        // } else {
        //   Swal.fire({
        //     customClass: 'nexter-alert',
        //     type: 'info',
        //     title: 'โปรดทราบ!',
        //     html:
        //       '<div class="w-75 mx-auto mt-2">' +
        //       '<p><strong>ไฟล์ที่อัปโหลด:</strong></p>' +
        //       '<ul class="text-left">' +
        //       liElemUploaded +
        //       '</ul>' +
        //       '</div>' +
        //       '<div class="w-75 mx-auto mt-2">' +
        //       '<p><strong>ไฟล์ที่ไม่สามารถอัปโหลดได้ กรุณาตรวจสอบรหัสผู้ขาย:</strong></p>' +
        //       '<ul class="text-left">' +
        //       liElemNoSeller +
        //       '</ul>' +
        //       '</div>',
        //     confirmButtonText: 'OK',
        //   }).then(() => {
        //     setTimeout(() => {
        //       this.clearData();
        //     }, 500);
        //   });
        // }
      } else {
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง',
          confirmButtonText: 'ลองอีกครั้ง',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async exportPresignedFileUrl(fileUrl, expire = 60) {
    try {
      this.loading = true;
      const encodedURI = encodeURIComponent(fileUrl)
      const url = `${config.npay.apiUrl}/file/presigned-url?url=${encodedURI}&expire=${expire}`;
      const response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        let fileUrl = body.data?.url;
        if (fileUrl && fileUrl !== 'undefined') {
          return fileUrl;
        }
      }
      return null;
    } catch (err) {
      console.error(err);
      return null;
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
}
