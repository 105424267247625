import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Page } from '../../components/Page';
import { Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { config } from '../../config';
import Container from 'reactstrap/lib/Container';

import PayOptionPage from './PayOption';
import DuePaymentPage from './DuePayment';
import TransactionFeePage from './TransactionFee';
import InstallmentPage from './Installment';
import VoidPage from './Void';
import PromotionPage from './Promotion/PromotionPage';
import OtherPage from './Other';
import Mid from './MID';

import NexterButton from '../../components/Button/NexterButton';
class SystemConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsActive: [false, false, false, false, false, true, false, false],
      loading: true,
      history_log: 'promotion'
    };
  }
  getInitialState() {
    return {
      modal: false,
      card: {
        absorb_interest_rate: 100,
        terms: [],
      },
    };
  }

  async componentDidMount() {
    // this.props.system.fetchConfig();
    const fetchSystemConfig = [
      this.props.system.fetchPaymentOptionConfig(),
      this.props.system.fetchDueDataConfig(),
      this.props.system.fetchTransactionFeeConfig(),
      this.props.system.fetchInstallmentDataConfig(),
      this.props.system.fetchVoidDataConfig(),
      this.props.system.fetchOtherDataConfig()
    ]
    Promise.all(fetchSystemConfig).finally(_ => {
      setTimeout(() => {
        this.setState({ loading: false })
      }, 500)
    })
  }

  handleTabsClick = index => {
    let temp = this.state.tabsActive;
    let tab = this.state.history_log;
    for (let i = 0; i < temp.length; i++) {
      if (i === index) {
        temp[i] = true;
      } else {
        temp[i] = false;
      }
      if (index === 0) {
        tab = 'payment_option';
      }
      if (index === 1) {
        tab = 'transaction_fee';
      }
      if (index === 2) {
        tab = 'due_of_payment';
      }
      if (index === 3) {
        tab = 'void';
      }
      if (index === 4) {
        tab = 'installment_interest';
      }
      if (index === 5) {
        tab = 'promotion';
      }
      if (index === 6) {
        tab = 'mid';
      }
      if (index === 7) {
        tab = 'other';
      }
    }
    this.setState({
      tabsActive: temp,
      history_log: tab,
    });
  };
  render() {
    let { loading } = this.props.system.getData();
    return (
      <Page loading={loading || this.state.loading}>
        <Container className="system-container" fluid>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="text-uppercase" style={{ marginBottom: 0 }}>
              <strong>System Config</strong>
            </h5>
          </div>
          <div className="tabs-card">
            <Nav className="nav-bar-setting">
              <NavItem>
                <div onClick={() => this.handleTabsClick(0)} className={this.state.tabsActive[0] === true ? 'tab-text tab-click' : 'tab-text'}>
                  Payment Option
                </div>
              </NavItem>
              <NavItem>
                <div onClick={() => this.handleTabsClick(1)} className={this.state.tabsActive[1] === true ? 'tab-text tab-click' : 'tab-text'}>
                  Transaction Fee
                </div>
              </NavItem>
              <NavItem>
                <div onClick={() => this.handleTabsClick(2)} className={this.state.tabsActive[2] === true ? 'tab-text tab-click' : 'tab-text'}>
                  Due of Payment
                </div>
              </NavItem>
              <NavItem>
                <div onClick={() => this.handleTabsClick(3)} className={this.state.tabsActive[3] === true ? 'tab-text tab-click' : 'tab-text'}>
                  Void
                </div>
              </NavItem>
              <NavItem>
                <div onClick={() => this.handleTabsClick(4)} className={this.state.tabsActive[4] === true ? 'tab-text tab-click' : 'tab-text'}>
                  Installment Interest
                </div>
              </NavItem>
              <NavItem>
                <div onClick={() => this.handleTabsClick(5)} className={this.state.tabsActive[5] === true ? 'tab-text tab-click' : 'tab-text'}>
                  Promotion
                </div>
              </NavItem>
              <NavItem>
                <div onClick={() => this.handleTabsClick(6)} className={this.state.tabsActive[6] === true ? 'tab-text tab-click' : 'tab-text'}>
                  MID
                </div>
              </NavItem>
              <NavItem>
                <div onClick={() => this.handleTabsClick(7)} className={this.state.tabsActive[7] === true ? 'tab-text tab-click' : 'tab-text'}>
                  Other
                </div>
              </NavItem>
            </Nav>
            {!this.state.loading && <>
              {this.state.tabsActive[0] ? <PayOptionPage /> : ''}
              {this.state.tabsActive[1] ? <TransactionFeePage /> : ''}
              {this.state.tabsActive[2] ? <DuePaymentPage /> : ''}
              {this.state.tabsActive[3] ? <VoidPage /> : ''}
              {this.state.tabsActive[4] ? <InstallmentPage /> : ''}
              {this.state.tabsActive[5] ? <PromotionPage /> : ''}
              {this.state.tabsActive[6] ? <Mid /> : ''}
              {this.state.tabsActive[7] ? <OtherPage /> : ''}
            </>}
          </div>
        </Container>
      </Page>
    );
  }
}

export default inject('system')(observer(SystemConfig));
