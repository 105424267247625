import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';

export class StatementStore extends BaseStore {
  constructor() {
    super();
    this.observable(this.getInitialData());
  }

  getInitialData() {
    return {
      loading: false,
      statements: {
        page: 1,
        size: 20,
        total: 1,
        items: [],
      },
      summary: {
        balance: 0,
      },
      filter: {
        type: [],
        gateway: [],
      },
    };
  }

  resetData() {
    this.observable(this.getInitialData());
  }

  clearFilter() {
    let { filter } = this.getInitialData();
    this.filter = filter;
  }

  selectPage(page) {
    this.statements.page = page;
    this.fetchStatementList();
  }

  toggleTypeFilter(type) {
    const index = this.filter.type.indexOf(type);
    if (index < 0) {
      this.filter.type.push(type);
    } else {
      this.filter.type.splice(index, 1);
    }
  }

  toggleGatewayFilter(gateway) {
    const index = this.filter.gateway.indexOf(gateway);
    if (index < 0) {
      this.filter.gateway.push(gateway);
    } else {
      this.filter.gateway.splice(index, 1);
    }
    this.filter.gateway = [...this.filter.gateway];
  }

  resetFilter() {
    this.clearFilter();
    this.fetchStatementList();
  }

  async fetchStatementSummary() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/statements/summary`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.summary = body.data;
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async fetchStatementList() {
    try {
      this.loading = true;
      let page = this.statements.page;
      let size = this.statements.size;
      let filter = JSON.stringify(this.filter);

      let url = `${config.npay.apiUrl}/statements?page=${page}&size=${size}&filter=${filter}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.statements.total = body.total;
        this.statements.items = body.data;
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }
}
