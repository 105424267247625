import React from 'react';
import { CardBody, CardTitle, CardText, Row, Col } from 'reactstrap';

import { config } from '../../config';

export const PaymentLink = ({ payment }) => {
  return (
    <CardBody>
      <Row>
        <Col className="text-center">
          <CardTitle style={{ fontWeight: 700 }}>{'ลิงค์ชำระเงิน'}</CardTitle>
          <CardText>
            <a href={`${config.npay.webUrl}/payments/${payment.uid}`}
              target="_blank" style={{ color: '#007bff' }}>
              {`${config.npay.webUrl}/payments/${payment.uid}`}
            </a>
          </CardText>
        </Col>
      </Row>
    </CardBody>
  );
};
