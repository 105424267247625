import React from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Props
 * color: String [*primary*, secondary, dark, info, success]
 * size: String [*md*, sm, lg]
 * icon: String
 * outline: Boolean [*false*]
 * disabled: Boolean [*false*]
 * active: Boolean [*false*]
 */

class NexterButton extends React.Component {
  render() {
    const props = this.props;
    return (
      <Button
        className={classNames('nexter-btn', props.className)}
        color={props.color}
        size={props.size}
        active={props.active}
        disabled={props.disabled}
        outline={props.outline}
        onClick={props.onClick}
        id={props.id}
      >
        <i className={classNames(
          props.icon,
          props.icon && !!props.children ? 'mr-2' : null,
        )} />
        {props.children}
      </Button>
    );
  }
}

NexterButton.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  active: PropTypes.bool,
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

NexterButton.defaultProps = {
  icon: null,
  color: 'primary',
  size: 'md',
  active: false,
  outline: false,
  disabled: false,
  onClick: null,
};

export default NexterButton;
