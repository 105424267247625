import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { PaymentInfo } from '../../report/PaymentInfo'
import { PaymentReport } from '../../report/PaymentReport'
import NexterButton from '../../../components/Button/NexterButton';
import { Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import Visibility from '../../../components/Visibility/Visibility';
import classnames from 'classnames';
import R1Report from './Report/R1';
import R2Report from './Report/R2';
import SellerReceipt from './Report/SellerReceipt';
import { TransferItemsTable } from '../../payment/TransferItemsTable';
export class PreTransferReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tabsActive: 'tab-report1',
			transferData: []
		};
		this.onClickTab = this.onClickTab.bind(this)
		this.onClickBackToListTable = this.onClickBackToListTable.bind(this)
	}
	componentWillUnmount() {
		this.props.payment.clearPayment()
	}
	printPDF() {
		window.print();
	}
	inquiry() {
		const { payment } = this.props.payment.getData();
		this.props.payment.inquiryPayment(payment.uid, false); // false = no redirect
	}

	onClickTab(e) {
		const tab = e.target.id
		// this.props.transfer.changeTransferStatus(transfer_status)
		// this.onClickBackToListTable()
		this.setState({ tabsActive: tab })
	}
	onClickBackToListTable() {
		this.props.payment.changeShowAllPayment(true)
	}
	fetchData() {

	}
	render() {
		const { payment, showAllPayment } = this.props.payment.getData()
		return (
			<>
				<Row>
					<Col>
						<div className="nav-bar-bottom-line" style={{ marginTop: '0.5rem', paddingLeft: '2rem' }}>
							<Visibility isVisibility={!showAllPayment}>
								<button className="btn-back no-print" onClick={this.onClickBackToListTable}>
									<i className="fas fa-chevron-left" style={{ fontSize: '30px' }}></i>
								</button>
							</Visibility>
							<Nav tabs className="no-print">
								<NavItem>
									<NavLink
										className={classnames({ active: this.state.tabsActive === 'tab-report1' })}
										onClick={this.onClickTab} id='tab-report1'>รายงาน R1</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({ active: this.state.tabsActive === 'tab-report2' })}
										onClick={this.onClickTab} id='tab-report2'>รายงาน R2</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({ active: this.state.tabsActive === 'tab-reportSeller' })}
										onClick={this.onClickTab} id='tab-reportSeller'>ผู้ขาย/ผู้รับเงิน</NavLink>
								</NavItem>
							</Nav>
						</div>
					</Col>
				</Row>
				<div className="p2">
					<Row>
						<Col style={{ alignSelf: 'flex-end' }}>
							<h5 className="title" >INVOICE NO. {payment.gateway_invoice_no}</h5>
						</Col>
						<Col>
							<div className="pull-right">
								<NexterButton className="no-print my-0 " size="sm" color="info" outline icon="fa fa-print"
									onClick={this.printPDF.bind(this)} >PRINT</NexterButton>
								<NexterButton className="no-print my-0  mr-1 ml-5px" size="sm" color="primary" icon="fa fa-history"
									onClick={this.inquiry.bind(this)}>UPDATE</NexterButton>
							</div>
						</Col>
					</Row>
					<hr />
					<PaymentInfo payment={payment} />
					<hr />
					<Visibility isVisibility={this.state.tabsActive === 'tab-report1'}>
						<PaymentReport payment={payment} />
					</Visibility>
					<Visibility isVisibility={this.state.tabsActive === 'tab-report2'}>
						<R2Report payment={payment} />
					</Visibility>
					<Visibility isVisibility={this.state.tabsActive === 'tab-reportSeller'}>
						<Row>
							<Col>
								<h5 className="title">รายละเอียดค่าบริการตามรายสินค้า</h5>
							</Col>
							<Col>
								<div style={{ padding: '0 0 1rem 0' }}>
									<span className="float-right text-seller" style={{ padding: '0 0 1rem 0' }}><b>ผู้ขาย/ผู้รับเงิน :</b> {payment.seller?.name ?? '-'}</span>
								</div>
							</Col>
						</Row>
						<TransferItemsTable payment={payment} seller={payment.seller} />
					</Visibility>
				</div>
			</>
		);
	}
}

export default inject('transfer', 'payment')(observer(PreTransferReport));
