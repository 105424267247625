export const AUTO_TRANSFER_SCHEDULE_TYPES = {
  daily: 'รายวัน',
  weekly: 'รายสัปดาห์',
  monthly: 'รายเดือน',
  dates: 'ระบุวันที่',
};

export const AUTO_TRANSFER_SCHEDULE_DAYS = {
  sun: 'อาทิตย์',
  mon: 'จันทร์',
  tue: 'อังคาร',
  wed: 'พุธ',
  thu: 'พฤหัสบดี',
  fri: 'ศุกร์',
  sat: 'เสาร์',
  eom: 'วันสุดท้ายของเดือน',
  everyday: 'ทุกวัน',
};
