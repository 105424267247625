import _ from 'lodash';
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { CardBody, Row, Col } from 'reactstrap';
import { config } from '../../../config';
import { ChannelTable } from '../ChannelTable';
import { PartnerTable } from '../PartnerTable';
import NexterButton from '../../../components/Button/NexterButton';
import { withRouter } from 'react-router-dom';
const EmptyTransferItems = () => (
  <CardBody>
    <h5 className="text-center">ไม่พบรายการโอนเงิน</h5>
  </CardBody>
);

class TransferReport extends Component {
  // componentDidMount() {
  //   let uid = this.props.match.params.uid;
  //   this.props.transfer.getTransferReport(uid);
  // }

  undoTransfer(reportNo, gateway) {
    this.props.transfer.undoTransfer(reportNo, gateway);
  }

  undoTransferExcel(reportNo, gateway) {
    this.props.transfer.undoTransferExcel(reportNo, gateway);
  }

  printPDF() {
    window.print();
  }

  rowClick(transfer) {
    if (transfer.payment && transfer.payment.uid) {
      console.log('transfer:', transfer);
      const id = transfer.payment.id;
      this.props.history.push(`${config.web.rootpath}/payments/detail/${id}`);
    }
  }

  exportFile = async () => {
    await this.props.transfer.exportFile({ source: 'Payment gateway (Transfer detail)' });
  }

  render() {
    let { transfer, loading } = this.props.transfer.getData();
    const { loggedinUser } = this.props.user.getData();
    let title = '';
    let bankInfo = {
      bank_name: '',
      bank_code: '',
      bank_account_no: '',
    };
    let table = <EmptyTransferItems />;
    if (transfer.items && transfer.items.length) {
      if (transfer.to_seller.type === 'channel') {
        title = 'รายการโอนเงินให้ Channel';
        table = <ChannelTable items={transfer.items} summary={transfer.summary} partnerTransfers={transfer.partner_transfers} onClickRow={this.rowClick.bind(this)} />;
      } else if (transfer.to_seller.type === 'partner') {
        title = 'รายการโอนเงินให้ Seller';
        table = <PartnerTable items={transfer.items} summary={transfer.summary} seller={transfer.to_seller} installment={transfer.installment} onClickRow={this.rowClick.bind(this)} />;
      }
    }
    if (transfer.to_seller.sellers_account) {
      const { bank_name, bank_code, bank_account_no, bank_account_name } = transfer.to_seller.sellers_account;
      bankInfo = {
        bank_name,
        bank_code,
        bank_account_no,
        bank_account_name,
      };
    }

    return (
      <div>
        <Row className="d-print-none">
          <Col>
            <h5 className="title" style={{ marginBottom: 0 }}>
              {title}
            </h5>
          </Col>
          <Col md={3}>
            <NexterButton className="no-print my-0 pull-right ml-1" size="sm" color="primary" icon="fas fa-download" onClick={this.exportFile}>
                EXCEL
            </NexterButton>
            <NexterButton className="no-print my-0 pull-right" size="sm" color="info" outline icon="fa fa-print" onClick={this.printPDF.bind(this)}>
              Print
            </NexterButton>
            {!transfer.file_url && (
              <NexterButton className="no-print my-0 pull-right mr-1" size="sm" color="info" outline icon="fa fa-undo-alt" onClick={this.undoTransfer.bind(this, transfer.report_no, transfer.gateway)}>
                Undo
              </NexterButton>
            )}
            {(loggedinUser.type === 'super_admin' || loggedinUser.type === 'as_channel_admin') &&
              (loggedinUser.username === 'amadmin' || loggedinUser.username === 'nexterpayadmin' || loggedinUser.username === 'nutminemz') &&
              transfer.file_url && (
                <NexterButton
                  className="no-print my-0 pull-right mr-1"
                  size="sm"
                  color="default"
                  icon="fa fa-undo-alt"
                  onClick={this.undoTransferExcel.bind(this, transfer.report_no, transfer.gateway)}
                >
                  UndoExcel
                </NexterButton>
              )}
            {/* <NexterButton className="no-print my-0 pull-right" size="sm" color="default" icon="fa fa-arrow-right">
                  onClick={this.transfer.bind(this, transfer.report_no)}>
                  Transfer
                </NexterButton> */}
          </Col>
        </Row>

        <hr className="print-block" />
        <Row className="print-block">
          <Col xs="6" sm="5">
            <h6>หมายเลขอ้างอิง: {transfer.report_no}</h6>
            <h6>รหัสร้านค้า: {transfer.to_seller.channel_sellers && !_.isEmpty(transfer.to_seller.channel_sellers) ? transfer.to_seller.channel_sellers[0].seller_code : '-'}</h6>
            <h6>ผู้รับเงิน: {transfer.to_seller.name}</h6>
            <h6>ประเภทการโอน: {transfer.is_auto_transfer ? 'Auto Transfer' : 'Manual Transfer'}</h6>
          </Col>
          <Col xs="6" sm="7">
            <h6>
              บัญชีธนาคาร: {bankInfo.bank_code ? <span>{bankInfo.bank_name} [{bankInfo.bank_code}]</span> : '-'}
            </h6>
            <h6>หมายเลขบัญชี: {bankInfo.bank_account_no || '-'}</h6>
            <h6>ชื่อบัญชี: {bankInfo.bank_account_name || '-'}</h6>
            <h6>วันที่ทำรายการ: {moment(transfer.created_at).format('DD/MM/YYYY HH:mm:ss')}</h6>
          </Col>  
        </Row>
        <div className="d-none print-block"><h5>ผู้รับเงิน: {transfer?.to_seller?.name || '-'}</h5></div>
        {table}
      </div>
    );
  }
}

export default withRouter(inject('transfer', 'user')(observer(TransferReport)));
