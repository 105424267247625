import React from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Row, Nav } from 'reactstrap';
import classNames from 'classnames';
import PerfectScrollbar from 'perfect-scrollbar';

import { config } from '../config';
import MenuGroup from './Sidebar/MenuGroup';
import MenuItem from './Sidebar/MenuItem';
import logo from '../assets/img/nexterlogo.png';

import ListGroupCollapse from './Collapse';


class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.state = { collapse: false };
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    // return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
    }
  }
  clickMenu(path) {
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }
  clearState() {
    this.setState(state => ({ collapse: true }));
  }
  render() {
    let path = window.location.pathname;

    return (
      <Row id="sidebar-container" className={classNames(
        'h-100 no-gutters flex-column',
        this.state.collapse ? 'sidebar-collapse' : null
      )}>
        <Col id="menu-container">
          <Nav>
            {this.props.routes.map((route, index) => {
              switch (route.type) {
                case 'group':
                  return (
                    <MenuGroup key={index} name={route.name} items={route.items} index={index} />
                  );
                case 'item':
                  return (
                    <MenuItem key={index} name={route.name} icon={route.icon} path={route.path} />
                  );
                case 'divider':
                  return (
                    <li key={index} className="menu-divider" />
                  );
              }
            })}
          </Nav>
        </Col>
        <Col xs="auto" id="footer-container">
          <p className="d-none">
            SCG-CBM respects your privacy. For more information, please review our
            <a href="#"> Privacy Policy </a>
          </p>
          <p>© 2021 SFinPay | All Rights Reserved.</p>
        </Col>
        <div className="trigger" onClick={this.toggle.bind(this)}>
          <i className={classNames(
            'nc-icon',
            this.state.collapse ? 'nc-minimal-right' : 'nc-minimal-left'
          )} />
        </div>
      </Row>
    );
  }
}

export default Sidebar;
