import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Page } from '../../../components/Page';
import { get, _ } from 'lodash';
import { KYCType } from '../../../utils/kyc';
import { CardHeader, CardBody, Row, Col, UncontrolledTooltip, Label, CustomInput, FormGroup, FormFeedback, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import NexterButton from '../../../components/Button/NexterButton';
import NexterInput from '../../../components/Input/NexterInput';
import { CheckBox } from '../../../components/Input/CheckBox';
import { validation } from '../../../utils/validation';
import qs from 'query-string';

const ERROR_MESSAGES = {
  name: 'กรุณาระบุชื่อผู้ขายให้ถูกต้อง',
  type: 'กรุณาเลือกประเภทผู้ขายให้ถูกต้อง',
  kyc_type: 'กรุณาเลือกประเภท KYC ให้ถูกต้อง',
  seller_code: 'กรุณาระบุรหัสผู้ขายให้ถูกต้อง',
  service_charge_rate: 'ค่าธรรมเนียมต้องมีค่ามากกว่าหรือเท่ากับ 3',
  phone_number: 'กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง',
  email: 'กรุณาระบุอีเมลให้ถูกต้อง',
};

class DataSellerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: {},
      status: '',
      touchStatus: false,
      isNoAuth: true,
      invalid_message: [],
      isOpenDropdownMoreMenu: false,
    };
  }

  componentDidMount() {
    let query = qs.parse(window.location.search);
    let isNoAuth = true
    if (Object.keys(query).includes('channel')) {
      isNoAuth = !!query.noauth
    } else {
      isNoAuth = false
    }
    const { seller } = this.props.seller.getData();

    const detectionInterval = setInterval(() => {
      const data = this.props.seller.getData();
      if (!data.loading) {
        this.props.seller.rawSeller = data.seller;
        clearInterval(detectionInterval);
      }
    }, 500);

    if (seller.is_active !== null && !this.state.touchStatus) {
      this.setState({
        seller: seller,
        status: seller.is_active,
        touchStatus: true,
        isNoAuth
      });
    } else {
      this.setState({
        seller,
        isNoAuth
      });
    }
  }

  componentWillUnmount() {
    this.props.seller.rawSeller = null;
    this.props.seller.editableSellerDetail = false;
  }

  onCancel() {
    const { rawSeller } = this.props.seller.getData();
    this.props.seller.seller = rawSeller;
    this.props.seller.editableSellerDetail = false;
    this.clearValidateInputs()
  }

  editConfig() {
    this.props.seller.editableSellerDetail = !this.props.seller.editableSellerDetail;
  }

  changeSellerCode(event) {
    let value = event.target.value.replace(/\s\s+/g, ' ');
    this.props.seller.changeSellerCode(value);
    this.validateInputs('seller_code')
  }

  changeSellerName(event) {
    this.props.seller.changeSellerName(event.target.value);
    this.validateInputs('name')
  }

  changeServiceChargeRate(event) {
    this.props.seller.changeServiceChargeRate(event.target.value);
    this.validateInputs('service_charge_rate')
  }

  changeServiceChargeAbsorb(event) {
    this.props.seller.changeServiceChargeAbsorb(event.target.checked);
  }

  changeKycType(selected) {
    this.props.seller.changeSellerKYCType(selected.value);
    this.validateInputs('kyc_type')
  }

  changePhoneNumber(event) {
    this.props.seller.changePhoneNumber(event.target.value);
    this.validateInputs('phone_number')
  }

  changeEmail(event) {
    this.props.seller.changeEmail(event.target.value);
    this.validateInputs('email');
  }

  changeSellerType(selected) {
    if (selected.value === 'channel') {
      this.props.seller.changeSellerCode('[System Generate]');
    } else {
      this.props.seller.changeSellerCode('');
    }
    this.props.seller.changeSellerType(selected.value);
    this.props.seller.changeServiceChargeAbsorb(selected.value === 'channel')
    this.validateInputs('seller_code')
    this.validateInputs('type')
    this.validateInputs('service_charge_rate')
  }

  changeStatus() {
    this.setState({ status: !this.state.status });
    this.props.seller.changeIsActive(!this.state.status);
  }

  saveSellerDetail() {
    this.props.seller.updateSellerDetail(() => this.validateInputs());
  }

  sellerCodeValidation(val) {
    let message = '';
    let invalid = false;
    if (!/^[a-zA-Z0-9-_ ]*$/.test(val)) {
      invalid = true;
      message = 'รูปแบบรหัสผู้ขายไม่ถูกต้อง';
    }
    return { invalid, message };
  }

  phoneValidation(val) {
    let message = '';
    let invalid = false;
    if (
      !validation.phone(val) ||
      !_.startsWith(val, '0') ||
      !(9 <= val.length && val.length <= 10) ||
      (val.length === 9 && !_.startsWith(val, '02')) ||
      (val.length === 10 && _.startsWith(val, '02')) ||
      _.startsWith(val, '00')
    ) {
      invalid = true;
      message = 'กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง';
      if (!_.startsWith(val, '0')) message = 'หมายเลขโทรศัพท์ต้องขึ้นต้นด้วย 0';
      else if (!(9 <= val.length && val.length <= 10)) message = 'หมายเลขโทรศัพท์ต้องมีความยาว 9-10 หลัก';
      else if ((val.length === 9 && !_.startsWith(val, '02')) || (val.length === 10 && _.startsWith(val, '02')) || _.startsWith(val, '00')) message = 'รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง';
    }
    return { invalid, message };
  }

  emailValidation(val) {
    let message = '';
    let invalid = false;
    if (!validation.email(val)) {
      invalid = true;
      message = ERROR_MESSAGES.email;
    }
    return { invalid, message };
  }

  getServiceChargeAbsorbText(seller) {
    if (seller.type === 'channel') {
      return '';
    } else {
      return seller.channel_seller && seller.channel_seller.service_charge_absorb === false
        ? `คิดค่าธรรมเนียม ${parseFloat(seller.channel_seller.service_charge_rate).toFixed(2)}%`
        : `ไม่คิดค่าธรรมเนียม ${parseFloat(seller.channel_seller.service_charge_rate).toFixed(2)}%`
    }
  }

  validateInputs(key = null) {
    const { seller } = this.props.seller.getData();
    const fieldsToCheck = key ? [key] : ['name', 'type', 'phone_number', 'email', 'kyc_type', 'seller_code', 'service_charge_rate'];
    const invalid_message = this.state.invalid_message;
    const detail = _.pick(seller, ['name', 'type', 'phone_number', 'email', 'kyc_type'])
    const channel_seller = _.pick(seller.channel_seller, ['seller_code', 'service_charge_rate'])
    const form = { ...detail, ...channel_seller }
    fieldsToCheck.forEach(field => (invalid_message[field] = null));
    fieldsToCheck.forEach(field => {
      const data = form[field];
      const defaultValidator = [undefined, null, ''].includes(data);
      switch (field) {
        case 'seller_code': {
          let { invalid, message } = this.sellerCodeValidation(data);
          if (seller.type == 'channel') {
            invalid = false;
            message = '';
          }
          if (defaultValidator) {
            invalid_message[field] = ERROR_MESSAGES[field];
          } else if (invalid) {
            invalid_message[field] = message;
          }
          break;
        }
        case 'phone_number': {
          const {invalid, message} = this.phoneValidation(data)
          if (invalid) {
            invalid_message[field] = message;
          }
          break;
        }
        case 'email': {
          if (!data) break;
          const {invalid, message} = this.emailValidation(data)
          if (invalid) {
            invalid_message[field] = message;
          }
          break;
        }
        case 'service_charge_rate': {
          if(!this.state.isNoAuth && detail.type === 'partner' && data < 3) 
            invalid_message[field] = ERROR_MESSAGES[field];
          break;
        }
        default:
          if (defaultValidator) invalid_message[field] = ERROR_MESSAGES[field];
      }
    })
    this.setState({ invalid_message });
    return !Object.keys(invalid_message).some(key => invalid_message[key] !== null)
  }

  clearValidateInputs() {
    this.setState({ invalid_message: [] });
  }

  toggleDropdownMoreMenu() {
    this.setState(prevStates => ({
      isOpenDropdownMoreMenu: !prevStates.isOpenDropdownMoreMenu
    }))
  }

  requestDeleteSeller(sellerId) {
    this.props.seller.checkCanRequestDeleteSeller(sellerId);
  }

  getMoreMenuItems() {
    const { seller } = this.props.seller.getData();
    const { user_rp } = this.props.user.getData();
    let menuItems = [];
    const isPermissionDelete = ['seller:delete'].some(role => user_rp.includes(role));
    if (isPermissionDelete && seller.type === 'partner' && !['requested', 'approved'].includes(seller.delete_status)) {
      menuItems.push({
        key: 'request-delete-seller',
        text: 'ส่งคำขอลบข้อมูลผู้ขาย',
        onClick: () => this.requestDeleteSeller(seller.id),
      });
    }
    return menuItems;
  }

  render() {
    const { seller, countSellerNum, countSellerChannelName } = this.props.seller.getData();
    const { user_rp } = this.props.user.getData();
    const { parentTab } = this.props;
    const { invalid_message, isNoAuth, isOpenDropdownMoreMenu } = this.state
    const disabledEditing = ['requested', 'approved'].includes(seller.delete_status)
    const isPermissionInactive = ['seller:inactivate'].some(role => user_rp.includes(role))
    const isPermissionEdit = ['seller:edit'].some(role => user_rp.includes(role))
    const isRegisterNoAuth = this.props.createNewSeller && isNoAuth
    const infoEditing = (isPermissionEdit && (this.props.seller.editableSellerDetail || this.props.createNewSeller)) || isRegisterNoAuth
    const moreMenuItems = !this.props.createNewSeller ? this.getMoreMenuItems() : [];

    this.state.status = seller.is_active;
    return (
      <Page>
        <CardHeader>
          <Row>
            <Col>
              <h1 className="text-data-seller">
                ข้อมูลผู้ขาย
                {!isNoAuth && (
                  <span className="ml-3">
                    <i className="fas fa-info-circle info-icon" id={`${parentTab}-noticIcon`}></i>
                    <UncontrolledTooltip placement="right" target={`${parentTab}-noticIcon`}>
                      ผู้ขายนี้อยู่ใน {countSellerNum} channel{' '}
                      {countSellerNum > 0 && (
                        <>
                          ได้แก่ {''}
                          <span className="text-capitalize">{countSellerChannelName.join()}</span>
                        </>
                      )}
                    </UncontrolledTooltip>
                  </span>
                )}
              </h1>
            </Col>
            <Col className="text-right p-0">
              <Col>
                {this.props.seller.editableSellerDetail && !this.props.createNewSeller ? (
                  <>
                    {!disabledEditing && (isPermissionInactive || isPermissionEdit) && (
                      <>
                        <NexterButton className="my-0 nexter-default-outline" size="sm" color="default" onClick={() => this.onCancel()} outline>
                          CANCEL
                        </NexterButton>
                        <NexterButton className="ml-3 mr-0 my-0" size="sm" icon="fa fa-save" color="success" onClick={() => this.saveSellerDetail()} outline>
                          SAVE
                        </NexterButton>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {seller.sellers_account.status !== 'pending' && !this.props.createNewSeller && (
                      <>
                        {!disabledEditing && (isPermissionInactive || isPermissionEdit) && (
                          <NexterButton className="mr-0 my-0" size="sm" icon="fa fa-pencil-alt" onClick={() => this.editConfig()}>
                            EDIT
                          </NexterButton>
                        )}
                      </>
                    )}
                    {moreMenuItems.length > 0 && (
                      <Dropdown isOpen={isOpenDropdownMoreMenu} toggle={this.toggleDropdownMoreMenu.bind(this)} direction="down" style={{ display: 'inline-block' }}>
                        <DropdownToggle size="sm" className="nexter-btn ml-3 mr-0 my-0">
                          <i className="fas fa-ellipsis-v"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                          {moreMenuItems.map(item => (
                            <DropdownItem key={item.key} onClick={() => item.onClick && item.onClick()}>
                              {item.text}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    )}
                  </>
                )}
              </Col>
            </Col>
          </Row>
        </CardHeader>
        <hr />
        <CardBody>
          <Row>
          {
            !this.props.createNewSeller && 
              <Col xs="3">
                  <div className="d-flex">
                    <Label className="text-label mr-3">Status :</Label>
                    <CustomInput
                      type="switch"
                      id={`${parentTab}-status`}
                      name="status"
                      checked={this.state.status}
                      value={this.state.status ? 'active' : 'inactive'}
                      disabled={!this.props.seller.editableSellerDetail || !isPermissionInactive}
                      onChange={this.changeStatus.bind(this)}
                    />
                    <Label className="text-capitalize ml-2" style={this.state.status ? { color: '#007aff' } : { color: '#525252' }}>
                      {this.state.status ? 'active' : 'inactive'}
                    </Label>
                  </div>
              </Col>
          }
          {
            !this.props.createNewSeller && 
              <Col xs="9">
                <Label className="text-label">Channel name : </Label>
                <span className="text-data inline">{` ${_.get(seller, 'channel_seller.channel.name', '-')}`}</span>
              </Col>
          }
          </Row>
          {infoEditing ? (
            <>
              <Row className="mt-4">
                {!this.props.seller.editableSellerDetail && <Col>
                  <FormGroup>
                    <Label className="text-label">Seller ID</Label>
                    <NexterInput value={seller.uid} disabled />
                  </FormGroup>
                </Col>}
                <Col>
                  <FormGroup className="required">
                    <Label className="control-label text-label">Seller Code</Label>
                    <NexterInput
                      value={seller?.channel_seller?.seller_code || ''}
                      onChange={this.changeSellerCode.bind(this)}
                      invalid={!!invalid_message['seller_code']}
                      disabled={seller.type === 'channel'}
                    />
                    <FormFeedback>{invalid_message['seller_code']}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  {this.props.createNewSeller ? 
                  <FormGroup className="required">
                    <Label className="control-label text-label">Seller Type</Label>
                    <NexterInput
                      type="select"
                      size="lg"
                      options={[{ value: 'channel', label: 'Channel' }, { value: 'partner', label: 'Seller' }]}
                      value={seller.type === 'channel' ? { value: 'channel', label: 'Channel' } : { value: 'partner', label: 'Seller' }}
                      onChange={this.changeSellerType.bind(this)}
                      className="select-bank-seller"
                      placeholder="เลือก Seller Type..."
                      disabled={this.state.isNoAuth}
                      invalid={!!invalid_message['type']}
                    />
                    <FormFeedback>{invalid_message['type']}</FormFeedback>
                  </FormGroup> :
                  <FormGroup>
                    <Label className="text-label">Seller Type</Label>
                    <NexterInput className="text-capitalize" value={seller.type ? (seller.type === 'partner' ? 'Seller' : seller.type === 'channel' ? 'Channel' : '-') : '-'} disabled />
                  </FormGroup>
                  }
                </Col>
                <Col>
                  <FormGroup className="required">
                    <Label className="control-label text-label">ชื่อผู้ขาย</Label>
                    <NexterInput className="text-capitalize" value={seller.name} onChange={this.changeSellerName.bind(this)} invalid={!!invalid_message['name']}/>
                    <FormFeedback>{invalid_message['name']}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-4">
                {!this.state.isNoAuth && <Col>
                  <FormGroup className="required">
                    <Label className="control-label text-label">ค่าธรรมเนียม</Label>
                    <NexterInput
                      allow="float"
                      className="text-capitalize"
                      value={seller.type === 'channel' ? '' : seller.channel_seller.service_charge_rate}
                      disabled={seller.type === 'channel'}
                      onChange={this.changeServiceChargeRate.bind(this)}
                      invalid={!!invalid_message['service_charge_rate']}
                    />
                    <FormFeedback>{invalid_message['service_charge_rate']}</FormFeedback>
                    <div style={{ height: '1rem' }} />
                    <CheckBox
                      label="ไม่คิดค่าธรรมเนียม"
                      value={!seller.channel_seller.service_charge_absorb}
                      checked={seller.channel_seller.service_charge_absorb}
                      disabled={seller.type === 'channel'}
                      onChange={this.changeServiceChargeAbsorb.bind(this)}
                    />
                  </FormGroup>
                </Col>}
                <Col>
                  <FormGroup className="required">
                    <Label className="control-label text-label">ประเภทบัญชี</Label>
                    <NexterInput
                      type="select"
                      size="lg"
                      options={[
                        { value: 'personal', label: 'บุคคลธรรมดา' },
                        { value: 'company', label: 'นิติบุคคล' }
                      ]}
                      value={seller.kyc_type ? seller.kyc_type === 'company' ? { value: 'company', label: 'นิติบุคคล' } : { value: 'personal', label: 'บุคคลธรรมดา' } : seller.kyc_type} 
                      onChange={selected => this.changeKycType(selected)}
                      className="select-bank-seller"
                      invalid={!!invalid_message['kyc_type']}
                      disabled={Boolean(seller.id)}
                      placeholder="เลือกประเภท..."
                    />
                    <FormFeedback>{invalid_message['kyc_type']}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="text-capitalize">
                <Col>
                  <FormGroup className="required">
                    <Label className="control-label text-label">เบอร์โทรศัพท์</Label>
                    <NexterInput allow="tel" invalid={!!invalid_message['phone_number']} value={seller.phone_number} onChange={this.changePhoneNumber.bind(this)} maxLength={10} />
                    <FormFeedback>{invalid_message['phone_number']}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label className="control-label text-label">อีเมล</Label>
                    <NexterInput invalid={!!invalid_message['email']} value={seller.email} onChange={this.changeEmail.bind(this)} />
                    <FormFeedback>{invalid_message['email']}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="mt-4">
                <Col>
                  <Label className="text-label">Seller ID</Label>
                </Col>
                <Col>
                  <Label className="text-label">Seller Code</Label>
                </Col>
                <Col>
                  <Label className="text-label">Seller Type</Label>
                </Col>
                <Col>
                  <Label className="text-label">ชื่อผู้ขาย</Label>
                </Col>
              </Row>
              <Row className="text-capitalize">
                <Col>
                  <p className="text-data" style={{ wordBreak: 'break-word' }}>
                    {seller.uid || <span className="data-is-null">-</span>}
                  </p>
                </Col>
                <Col>
                  <p className="text-data">{seller.channel_seller && seller.channel_seller.seller_code ? seller.channel_seller.seller_code : <span className="data-is-null">-</span>}</p>
                </Col>
                <Col>
                  <p className="text-data">
                    {seller.type ? seller.type === 'partner' ? 'Seller' : seller.type === 'channel' ? 'Channel' : <span className="data-is-null">-</span> : <span className="data-is-null">-</span>}
                  </p>
                </Col>
                <Col>
                  <p className="text-data">{seller.name || <span className="data-is-null">-</span>}</p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Label className="text-label">ค่าธรรมเนียม</Label>
                </Col>
                <Col>
                  <Label className="text-label">ประเภทบัญชี</Label>
                </Col>
                <Col>
                  <Label className="text-label">เบอร์โทรศัพท์</Label>
                </Col>
                <Col>
                  <Label className="text-label">อีเมล</Label>
                </Col>
              </Row>
              <Row className="text-capitalize">
                <Col>
                  <p className="text-data">
                    {this.getServiceChargeAbsorbText(seller)}
                  </p>
                </Col>
                <Col>
                  <p className="text-data">{get(KYCType, [seller.kyc_type], <span className="data-is-null">-</span>)}</p>
                </Col>
                <Col>
                  <p className="text-data text-lowercase">{seller.phone_number || <span className="data-is-null">-</span>}</p>
                </Col>
                <Col>
                  <p className="text-data text-lowercase">{seller.email || <span className="data-is-null">-</span>}</p>
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Page>
    );
  }
}

export default inject('seller', 'user')(observer(DataSellerDetail));
