import _ from 'lodash';
import React from 'react'
import { Card } from 'reactstrap';

import ReconcileListSearch from './ReconcileListSearch';
import ReconcileListTable from './ReconcileListTable';
import ReconcileTranSearch from './ReconcileTranSearch';
import ReconcileTranTable from './ReconcileTranTable';

const ReconcileComp = ({ channel, reconcile }) => {
	return (
		<>
			{_.isNull(channel) ? (
				<Card style={{ padding: 20 }}>
					<ReconcileListSearch />
					<ReconcileListTable />
				</Card>
			) : (
				<>
					<Card style={{ padding: 20 }}>
						<a
							onClick={() => {
								reconcile.viewChannelTransaction(null);
							}}
						>
							<h6 className="payment-title text-capitalize no-print">
								<i className="back-icon fas fa-chevron-left my-auto mr-2" />
								<span>{channel.name ? `Channel ${channel.name} Transaction` : `All Channel Transaction`}</span>
							</h6>
						</a>
						<ReconcileTranSearch />
						<ReconcileTranTable />
					</Card>
				</>
			)}
		</>
	)
}

export default ReconcileComp
