import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// react plugin used to create charts
import { Line, Doughnut, Bar, HorizontalBar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import {
  // UncontrolledTooltip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  ButtonGroup, Button
} from 'reactstrap';
import palette from 'google-palette'
import SelectInterval from './components/SelectInterval'
import moment from 'moment'
import * as _ from 'lodash'
import numeral from 'numeral';
class ChannelChart extends Component {
  backgroundColor = [
    "#57b8ff",
    "#1155cc",
    "#c28dff",
    "#f99600",
    "#21d59b",
    "#f9cf63",
    "#fde76e",
    "#fced86",
    "#ffffb7",
    "#fefeeb",
  ]
  constructor(props) {
    super(props);
    this.state = {
      selected_interval: 'month'
    }
  }

  componentDidMount() {
    this.props.dashboard.fetchDashboardPaymentByChannel(this.state.selected_interval)
  }
  getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  selectInterval(val) {
    this.setState({ selected_interval: val }, () => {
      this.props.dashboard.fetchDashboardPaymentByChannel(val)
      this.props.dashboard.setTotalByChannelInterval(val)
    })
  }

  render() {
    const { by_channel } = this.props.dashboard.getData();
    const options = {
      tooltips: {
        mode: 'label',
        intersect:false,
        bodyFontColor: '#161616',
        borderWidth:1,
  
        custom: function(tooltip) {
          
          tooltip.caretSize = 10,
          tooltip.backgroundColor = "#ffff",
          tooltip.titleFontColor = "#161616",
          tooltip.titleFontStyle = "bold",
          tooltip.bodyFontColor = "#161616",
          tooltip.borderColor='#7e84a3'
      },
        callbacks: {
          title: (tooltipItems, data) => {
            let total = numeral(tooltipItems.reduce((a, e) => a + parseFloat(e.yLabel || 0), 0)).format('0,0.00');
            let xLabel = tooltipItems[0].xLabel
            return  xLabel+ ': ' + '฿' + total.toLocaleString();
          },
          label: function (t, d) {
            var dstLabel = d.datasets[t.datasetIndex].label;
            var yLabel = numeral(t.yLabel || 0).format('0,0.00');
            return dstLabel + ': ' + '฿' + yLabel.toLocaleString();
          },
       

     
        },

      },
      plugins: {
        datalabels: {
          display: false,
        },
      },
      scales: {
        yAxes: [{
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'จำนวน (บาท)'
          },
          ticks: {
            beginAtZero: true,
            userCallback: function(label, index, labels) {
                return numeral(label).format('0,0.00')
            },
          }
        },
       
      ],
        xAxes: [{
          stacked: true,
          gridLines: {
            display: false
          },
        }]
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },

    }
    return (

      <Card className="card-chart">
        <CardHeader>
          <div className="chart-header-row">
            <CardTitle className="chart-title " tag="h5">ยอดรวมที่ลูกค้าชำระเปรียบเทียบทุก Channel</CardTitle>
            <SelectInterval selectInterval={(val) => this.selectInterval(val)} />
          </div>
        </CardHeader>
        <CardBody>
          <div className="no-print">
            <Bar
              data={by_channel.dataset}
              width={400}
              height={150}
              options={options}
            />
          </div>
        </CardBody>
      </Card>


    )
  }
}
export default inject('dashboard')(observer(ChannelChart));
