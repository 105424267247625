import dbAddress from './data.json';


const isString = (str) => (typeof str === 'string');

class ThaiAddress {
  getProvinces() {
    return dbAddress.map(item => item[0]);
  }

  getDistricts(province) {
    const ip = isString(province) ? this.getProvinces().indexOf(province) : province;
    return dbAddress[ip][1].map(item => item[0]);
  }

  getSubDistricts(province, district) {
    const ip = isString(province) ? this.getProvinces().indexOf(province) : province;
    const id = isString(district) ? this.getDistricts(province).indexOf(district) : district;
    return dbAddress[ip][1][id][1].map(item => item[0]);
  }

  getZipCode(province, district, subDistrict) {
    const ip = isString(province) ? this.getProvinces().indexOf(province) : province;
    const id = isString(district) ? this.getDistricts(province).indexOf(district) : district;
    const isd = isString(subDistrict) ? this.getSubDistricts(province, district).indexOf(subDistrict) : subDistrict;
    return dbAddress[ip][1][id][1][isd][1];
  }
}

export const address = new ThaiAddress();
