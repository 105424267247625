import React, { Fragment } from 'react';
import { Row, Col, FormGroup, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { CheckBox } from '../../../components/Input/CheckBox';
import NexterButton from '../../../components/Button/NexterButton';
import { AUTO_TRANSFER_SCHEDULE_TYPES } from '../../../constants/transferConstants';

const FILTERS = [
  {
    key: 'gateways',
    title: 'Payment Gateway',
    options: [
      { label: '2C2P', value: '2c2p' },
      { label: 'T2P', value: 't2p' },
    ],
  },
  {
    key: 'scheduleTypes',
    title: 'ประเภทการโอน',
    options: ['daily', 'weekly', 'monthly', 'dates'].map(value => ({ label: AUTO_TRANSFER_SCHEDULE_TYPES[value], value })),
  },
  {
    key: 'statuses',
    title: 'Status',
    options: [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ],
  },
];

const AutoTransferFilterModal = ({ isOpen, filter = {}, onChange, onClearFilters, onClose }) => {
  function onClear() {
    const filterKeys = FILTERS.map(filter => filter.key);
    onClearFilters(filterKeys);
  }

  return (
    <Modal isOpen={isOpen} toggle={() => onClose()}>
      <ModalBody className="seller-modal-body">
        <p className="filter-title">Filters</p>
        <hr />
        {FILTERS.map((filterItem, index) => (
          <Fragment key={index}>
            <FormGroup>
              <Label className="w-100 text-dark">
                <strong>{filterItem.title}</strong>
              </Label>
              <FormGroup className="mt-2">
                <Row>
                  {filterItem.options.map(option => (
                    <Col key={option.value} xs={4}>
                      <CheckBox label={option.label} checked={filter[filterItem.key].includes(option.value)} onChange={() => onChange(filterItem.key, option.value)} />
                    </Col>
                  ))}
                </Row>
              </FormGroup>
            </FormGroup>
            <hr />
          </Fragment>
        ))}
      </ModalBody>
      <ModalFooter className="seller-modal-footer">
        <NexterButton onClick={() => onClear()} color="secondary" className="btn-sm text-capitalize">
          Clear all filters
        </NexterButton>
        <NexterButton onClick={() => onClose()} className="btn-sm text-capitalize ml-3">
          Done
        </NexterButton>
      </ModalFooter>
    </Modal>
  );
};

export default AutoTransferFilterModal;
