import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';

export class BankStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      banks: [],
    });
  }

  async fetchBank() {
    try {
      let url = `${config.npay.apiUrl}/banks?filter=bank`;
      let response = await http.get(url);

      if (response.status === 200) {
        let body = await response.json();
        this.banks = body.data;
      }
    } catch (err) {
      console.error(err);
    }
  }
}
