import React from 'react';
import { Label, Input, FormGroup } from 'reactstrap';

export const CheckBox = ({ name, label, value, checked, disabled, onChange, defaultClassName }) => {
  return (
    <FormGroup check inline className={defaultClassName ?? 'mb-2 mr-sm-4 mb-sm-0 mb-md-2'}>
      <Label check>
        <Input type="checkbox" name={name} value={value} checked={checked} disabled={disabled} onChange={onChange} />
        <span className={`form-check-sign ${disabled ? 'disable' : ''}`} />
        {label}
      </Label>
    </FormGroup>
  );
};
