class Validation {
  username(username) {
    const regEx = /^[a-zA-Z][0-9a-zA-Z]+$/;
    return regEx.test((username || '').toLowerCase());
  }

  password(password) {
    return this.checkPasswordFormatList(password).every(format => format.passed);
  }

  email(email) {
    const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEx.test((email || '').toLowerCase());
  }

  phone(phone) {
    // const regEx = /^0\d{9}+$/;
    const regEx = /^[0-9]+$/;
    return regEx.test(phone || '');
  }

  paymentRefId(id) {
    const regEx = /^\s?([-_\/a-zA-Z0-9]\s?|[-_\/a-zA-Z0-9]$)+(^\s)*$/;
    return regEx.test(id);
  }

  integer(value) {
    const regEx = /^-?(0|[1-9]\d*)$/;
    return regEx.test(value);
  }

  checkPasswordFormatList(password) {
    const formatList = [
      { title: 'Be 8 to 15 characters', regExp: /^.{8,15}$/ },
      { title: 'Include at least one lowercase letter (a-z)', regExp: /[a-z]/ },
      { title: 'Include at least one uppercase letter (A-Z)', regExp: /[A-Z]/ },
      { title: 'Include number (0-9)', regExp: /[0-9]/ },
      { title: 'Include special character (!@#$%*&^)', regExp: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/ },
    ];
    let checkedList = formatList.map(format => ({
      ...format,
      passed: format.regExp.test(password),
    }));
    return checkedList;
  }
}

export const validation = new Validation();
