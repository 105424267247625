import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button, Card, CardHeader, CardBody, InputGroupText, InputGroupAddon, InputGroup, Input, Form, FormGroup, Label, CustomInput, Row, Col } from 'reactstrap';
import { config } from '../../config';
import { Page } from '../../components/Page';
import Visibility from '../../components/Visibility/Visibility';
import NexterButton from '../../components/Button/NexterButton';
import Swal from 'sweetalert2';

// import bay from '../../assets/img/bay.png';
// import bbl from '../../assets/img/bbl.jpg';
// import ktc from '../../assets/img/ktc.jpg';
// import scb from '../../assets/img/scb.jpg';
// import kbank from '../../assets/img/kbank.png';
import bay from '../../assets/img/bank/logo-Krungsri/logo-Krungsri.png';
import bbl from '../../assets/img/bank/logo-BBL/logo-BBL.png';
import ktc from '../../assets/img/ktc.jpg';
import scb from '../../assets/img/bank/logo-SCB/logo-SCB.png';
import kbank from '../../assets/img/bank/logo-Kbank/logo-Kbank.png';
import fcc from '../../assets/img/fcc.jpg';
import uob from '../../assets/img/uob.jpeg';
import tbank from '../../assets/img/tbank.jpg';
import ttb from '../../assets/img/ttb.png';
import other from '../../assets/img/other.jpg';
class InstallmentPage extends Component {
  constructor() {
    super();
    this.state = {
      installmentData: [],
    };
  }

  componentDidMount() {
    this.loading = true;
    const installmentDataConfig = this.props.system.installment_interests;
    let installmentData = JSON.parse(JSON.stringify(installmentDataConfig));
    this.setState({ installmentData: installmentData });
    this.loading = false;
  }

  editInstallmentDataBtn() {
    this.props.system.editableT4 = true;
  }

  saveInstallmentData() {
    this.props.system.editableT4 = false;
    this.props.system.updateInstallmentDataConfig(this.state.installmentData);
  }

  cancelInstallmentData() {
    this.props.system.editableT4 = false;
    const installmentData = this.props.system.installment_interests;
    this.setState({ installmentData: installmentData });
  }

  changeIntallmentInterestStatus(key, e) {
    let installmentData = this.state.installmentData.map(item => {
      if (item.id === key) {
        if (item.status === 'active') {
          item.status = 'inactive';
        } else {
          item.status = 'active';
        }
      }
      return item;
    });

    this.setState({ installmentData: installmentData });
    this.forceUpdate();
  }

  changeInstallmentData(key, type, e) {
    let installmentData = this.state.installmentData.map(item => {
      if (item.id === key) {
        if (type === 'cost') {
          // Gateway MDR
          let cost = parseFloat(e.target.value);
          let set_cost = parseFloat(item.default_cost);
          if (cost >= 0 && cost <= set_cost) {
            item.cost = e.target.value;
          } else if (cost > set_cost) {
            Swal.fire({
              customClass: 'nexter-alert',
              type: 'warning',
              title: 'Validation Fail',
              html: '<li>The installment cost can not be greater than the installment default cost.</li>',
              showCancelButton: false,
            });
            throw 'The installment cost can not be greater than the installment default cost.';
          } else if (cost < 0) {
            Swal.fire({
              customClass: 'nexter-alert',
              type: 'warning',
              title: 'Validation Fail',
              html: '<li>The installment cost can not be less than zero.</li>',
              showCancelButton: false,
            });
            throw 'The installment cost can not be less than zero.';
          }
        } else if (type === 'default') {
          // Channel MDR
          let default_cost = parseFloat(e.target.value);
          if (default_cost < 0) {
            Swal.fire({
              customClass: 'nexter-alert',
              type: 'warning',
              title: 'Validation Fail',
              html: '<li>The installment default cost can not be less than zero.</li>',
              showCancelButton: false,
            });
            throw 'The installment default cost can not be less than zero.';
          }
          item.default_cost = e.target.value;
        } else if (type === 'minimum') {
          let minimum_amount = parseFloat(e.target.value);
          if (minimum_amount < 0) {
            Swal.fire({
              customClass: 'nexter-alert',
              type: 'warning',
              title: 'Validation Fail',
              html: '<li>The installment minimum amount can not be less than zero.</li>',
              showCancelButton: false,
            });
            throw 'The installment minimum amount can not be less than zero.';
          }
          item.minimum_amount = e.target.value;
        }
      }
      return item;
    });

    this.setState({ installmentData: installmentData });
    this.forceUpdate();
  }

  toggleOptionPeriod(key, option, e) {
    let installmentData = this.state.installmentData.map(item => {
      if (item.id === key) {
        let terms = [];
        if (item.terms) {
          terms = item.terms.split(',');
        }
        const index = terms.indexOf(option);
        if (index > -1) {
          terms.splice(index, 1);
        } else {
          terms.push(option);
          terms = terms.map(i => {
            return parseInt(i);
          });
          terms.sort((a, b) => a - b);
        }
        item.terms = terms.join();
      }
      return item;
    });

    this.setState({ installmentData: installmentData });
    this.forceUpdate();
  }

  render() {
    const { loading, editableT4: installmentEditable } = this.props.system.getData();
    const installmentPeriods = ['3', '4', '6', '10'];
    const installmentInputs = this.state.installmentData.map((item, index) => {
      return (
        <Col lg={4} xs={12} key={index}>
          <Card className="bg-config">
            <CardHeader className="config-header">
              {item.abbr === 'BAY' ? ( // KCC
                <img src={bay} alt={item.abbr} className="icon-s-32-circle" />
              ) : item.abbr === 'BBL' ? (
                <img src={bbl} alt={item.abbr} className="icon-s-32-circle" />
              ) : item.abbr === 'FCC' ? ( // KFC
                <img src={fcc} alt={item.abbr} className="icon-s-32-circle" />
              ) : item.abbr === 'KBANK' ? (
                <img src={kbank} alt={item.abbr} className="icon-s-32-circle" />
              ) : item.abbr === 'KTC' ? ( // KTB
                <img src={ktc} alt={item.abbr} className="icon-s-32-circle" />
              ) : item.abbr === 'SCB' ? (
                <img src={scb} alt={item.abbr} className="icon-s-32-circle" />
              ) : item.abbr === 'UOB' ? (
                <img src={uob} alt={item.abbr} className="icon-s-32-circle" />
              ) : item.abbr === 'TBANK' ? (
                <img src={tbank} alt={item.abbr} className="icon-s-32-circle" />
              ) : item.abbr === 'TTB' ? (
                <img src={ttb} alt={item.abbr} className="icon-s-32-circle" />
              ) : (
                <img src={other} alt={item.abbr} className="icon-s-32-circle" />
              )}
              &nbsp;&nbsp;{item.name}
            </CardHeader>
            <CardBody className="bg-white">
              {!installmentEditable ? (
                <div className="installment-setting">
                  <div className="d-flex justify-content-between">
                    <label>Gateway MDR</label>
                    <strong>{item.cost}%</strong>
                  </div>
                  <hr />

                  <div className="d-flex justify-content-between">
                    <label>Channel MDR</label>
                    <strong>{item.default_cost}%</strong>
                  </div>
                  <hr />

                  <div className="d-flex justify-content-between">
                    <label>Min</label>
                    <strong>฿ {item.minimum_amount?.toLocaleString()}</strong>
                  </div>
                  <hr />

                  <FormGroup>
                    <Label>Period (Month)</Label>
                    <div className="d-flex">
                      {installmentPeriods.map(
                        month =>
                          item.terms?.includes(month) && (
                            <Button key={month} size="sm" disabled={!installmentEditable} className="btn-outline-nexter">
                              {month}
                            </Button>
                          )
                      )}
                    </div>
                  </FormGroup>
                </div>
              ) : (
                <Form>
                  <FormGroup>
                    <Label>Gateway MDR</Label>
                    <InputGroup size="sm">
                      <Input type="number" value={item.cost || ''} disabled={!installmentEditable} onChange={this.changeInstallmentData.bind(this, item.id, 'cost')} />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>%</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <Label>Channel MDR</Label>
                    <InputGroup size="sm">
                      <Input type="number" value={item.default_cost || ''} disabled={!installmentEditable} onChange={this.changeInstallmentData.bind(this, item.id, 'default')} />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>%</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <Label>Min</Label>
                    <InputGroup size="sm">
                      <Input type="number" value={item.minimum_amount || ''} disabled={!installmentEditable} onChange={this.changeInstallmentData.bind(this, item.id, 'minimum')} />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>฿</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label>Period (Month)</Label>
                    <div className="d-flex">
                      {installmentPeriods.map(month => (
                        <Button
                          key={month}
                          size="sm"
                          disabled={!installmentEditable}
                          className={item.terms.indexOf(month) > -1 ? 'btn-nexter' : 'btn-outline-nexter'}
                          onClick={this.toggleOptionPeriod.bind(this, item.id, month)}
                        >
                          {month}
                        </Button>
                      ))}
                    </div>
                  </FormGroup>
                </Form>
              )}

              <hr />
              <div className="d-flex justify-content-between">
                <Label>Status</Label>
                <CustomInput
                  id={index}
                  type="switch"
                  checked={item.status === 'active' ? true : false}
                  disabled={!installmentEditable}
                  onChange={this.changeIntallmentInterestStatus.bind(this, item.id)}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      );
    });

    return (
      <Page loading={loading} className="bg-white">
        <div className="container-fluid px-0">
          <Row>
            <Col>
              <Card className="no-r-tl">
                <CardBody className="pt-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="text-uppercase my-4 system-config-title">Installment Interest</h6>
                    {installmentEditable ? (
                      <div>
                        <NexterButton size="sm" color="secondary" onClick={this.cancelInstallmentData.bind(this)} outline>
                          CANCEL
                        </NexterButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <NexterButton size="sm" icon="fa fa-save" color="success" onClick={this.saveInstallmentData.bind(this)} outline>
                          SAVE
                        </NexterButton>
                      </div>
                    ) : (
                      <div>
                        <Visibility permission_code="system_configuration:set_default_installment_price">
                          <NexterButton size="sm" icon="fas fa-pencil-alt" onClick={this.editInstallmentDataBtn.bind(this)}>
                            EDIT
                          </NexterButton>
                        </Visibility>
                        <Link to={`${config.web.rootpath}/system-config/history?tab=installment_interest`}>
                          <NexterButton className="ml-3 my-0" size="sm" outline icon="fas fa-history">
                            History Log
                          </NexterButton>
                        </Link>
                      </div>
                    )}
                  </div>
                  <hr className="mt-0 mb-3" />
                  <div className="mt-2">
                    <Row>{installmentInputs}</Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Page>
    );
  }
}

export default inject('system')(observer(InstallmentPage));
