import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Card, CardHeader, CardBody, Row, Col, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import { config } from '../../config';
import { Page } from '../../components/Page';
import Visibility from '../../components/Visibility/Visibility';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';

import Swal from 'sweetalert2';

class DuePaymentPage extends Component {
  constructor() {
    super();
    this.state = {
      dueData: {
        default_due_date: 3,
        maximum_due_date: 30,
      },
    };
  }

  componentDidMount() {
    this.initDueData();
  }

  initDueData() {
    this.loading = true;
    const dueDataConfig = this.props.system.due_payments;
    let data = JSON.parse(JSON.stringify(dueDataConfig));
    let input = {
      default_due_date: parseInt(data.default_due_date),
      maximum_due_date: parseInt(data.maximum_due_date),
    };
    this.setState(prevState => {
      return { dueData: input };
    });
    this.loading = false;
  }

  changeDueData(type, e) {
    // console.log(type, e.target.value);
    let data = this.state.dueData;

    if (type === 'default') {
      data.default_due_date = parseInt(e.target.value);
    } else if (type === 'maximum') {
      data.maximum_due_date = parseInt(e.target.value);
    }

    this.setState(prevState => {
      return { dueData: data };
    });
    // this.forceUpdate();
  }

  editDueDataBtn() {
    this.props.system.editableT6 = true;
  }

  saveDueData() {
    const dueData = this.state.dueData;

    if (!dueData.default_due_date || isNaN(dueData.default_due_date) || dueData.default_due_date < 0) {
      // this.initDueData();

      Swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: 'Error!',
        text: 'Default Due Date could not be less than 0',
        confirmButtonText: 'OK',
      });
      return;
    }

    if (!dueData.maximum_due_date || isNaN(dueData.maximum_due_date) || dueData.maximum_due_date < 0) {
      // this.initDueData();

      Swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: 'Error!',
        text: 'Maximum Due Date could not be less than 0',
        confirmButtonText: 'OK',
      });
      return;
    }

    if (dueData.default_due_date > dueData.maximum_due_date) {
      // this.initDueData();

      Swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: 'Error!',
        text: 'Default Due Date could not be greater than Maximum Due Date',
        confirmButtonText: 'OK',
      });
      return;
    }

    this.props.system.editableT6 = false;
    this.props.system.updateDueDataConfig(this.state.dueData);
    // this.forceUpdate();
  }

  cancelDueData() {
    this.props.system.editableT6 = false;
    this.initDueData();
  }

  render() {
    const { loading, editableT6: dueEditable } = this.props.system.getData();
    // console.log('RENDER');
    // console.log('DUE: ', this.state.dueData);

    return (
      <Page loading={loading} className="bg-white">
        <div className="container-fluid px-0">
          <Row>
            <Col>
              <Card className="no-r-tl" style={{ height: 'calc(100vh - 230px)', minHeight: '320px' }}>
                <CardBody className="pt-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="text-uppercase my-4 system-config-title">Due of Payment</h6>
                    {dueEditable ? (
                      <div>
                        <NexterButton size="sm" color="secondary" onClick={this.cancelDueData.bind(this)} outline>
                          CANCEL
                        </NexterButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <NexterButton size="sm" icon="fa fa-save" color="success" onClick={this.saveDueData.bind(this)} outline>
                          SAVE
                        </NexterButton>
                      </div>
                    ) : (
                      <div>
                        <Visibility permission_code="system_configuration:set_default_due_of_payment">
                          <NexterButton size="sm" icon="fas fa-pencil-alt" onClick={this.editDueDataBtn.bind(this)}>
                            EDIT
                          </NexterButton>
                        </Visibility>
                        <Link to={`${config.web.rootpath}/system-config/history?tab=due_of_payment`}>
                          <NexterButton className="ml-3 my-0" size="sm" outline icon="fas fa-history">
                            History Log
                          </NexterButton>
                        </Link>
                      </div>
                    )}
                  </div>
                  <hr className="mt-0 mb-3" />
                  <div className="mt-2">
                    <Row>
                      <Col lg={4}>
                        <Card className="bg-config">
                          <CardHeader className="config-header">Due of Payment</CardHeader>
                          <CardBody className="bg-white">
                            {!dueEditable ? (
                              <div className="due-setting">
                                <div className="d-flex justify-content-between">
                                  <span>Default</span>
                                  <strong>{this.state.dueData.default_due_date}&nbsp;&nbsp;&nbsp;&nbsp;วัน</strong>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between">
                                  <span>Maximum</span>
                                  <strong>{this.state.dueData.maximum_due_date}&nbsp;&nbsp;&nbsp;&nbsp;วัน</strong>
                                </div>
                                <div style={{ height: 24 }} />
                              </div>
                            ) : (
                              <Form>
                                <FormGroup>
                                  <label htmlFor={'default'}>Default</label>
                                  <br />
                                  <InputGroup size="sm">
                                    <NexterInput
                                      id={'default'}
                                      type="number"
                                      value={this.state.dueData.default_due_date}
                                      disabled={!dueEditable}
                                      min={0}
                                      onChange={this.changeDueData.bind(this, 'default')}
                                      onBlur={this.changeDueData.bind(this, 'default')}
                                    />
                                    <InputGroupAddon addonType="append">
                                      <InputGroupText>วัน</InputGroupText>
                                    </InputGroupAddon>
                                  </InputGroup>
                                </FormGroup>

                                <FormGroup>
                                  <label htmlFor={'maximum'}>Maximum</label>
                                  <br />
                                  <InputGroup size="sm">
                                    <NexterInput
                                      id={'maximum'}
                                      type="number"
                                      value={this.state.dueData.maximum_due_date}
                                      disabled={!dueEditable}
                                      min={0}
                                      onChange={this.changeDueData.bind(this, 'maximum')}
                                      onBlur={this.changeDueData.bind(this, 'maximum')}
                                    />
                                    <InputGroupAddon addonType="append">
                                      <InputGroupText>วัน</InputGroupText>
                                    </InputGroupAddon>
                                  </InputGroup>
                                </FormGroup>
                              </Form>
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Page>
    );
  }
}

export default inject('system')(observer(DuePaymentPage));
