import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { config } from '../config';
import localStorage from '../utils/localStorage';

import Header from './Header';
import Sidebar from './Sidebar';

// Dashboard
import DashboardPage from '../pages/dashboard/DashboardPage';

// Channel
import ChannelManagementPage from '../pages/admin/ChannelManagementPage';
import CreateChannelPage from '../pages/admin/CreateChannelPage';
import ChannelHistoryLogPage from '../pages/channel/ChannelHistoryLogPage';
import ChannelSettingHistoryLogPage from '../pages/channel/ChannelSettingHistoryLogPage';

// Sellers
import SellerPage from '../pages/admin/SellerPage';
import ApproveSellerPage from '../pages/admin/ApproveSellerPage2';
import SellerDetailPage from '../pages/seller/SellerDetail/SellerDetailPage';
import SellerRegisterPage from '../pages/seller/SellerRegisterPage';
import ImportSellerPage from '../pages/seller/ImportSellerPage';

// User Management
import RolePermissionPage from '../pages/admin/RolePermissionPage';

// Auto transfer
import AutoTransferListPage from '../pages/autoTransfer/AutoTransferListPage/AutoTransferListPage';
import AutoTransferDetailPage from '../pages/autoTransfer/AutoTransferDetailPage/AutoTransferDetailPage';
import AutoTransferHistoryLogPage from '../pages/autoTransfer/AutoTransferHistoryLogPage/AutoTransferHistoryLogPage';

// System Config
import SystemConfig from '../pages/systemConfig/systemConfig';
import SystemConfigHistoryLogPage from '../pages/systemConfig/SystemConfigHistoryLogPage';

// Payment
import PaymentListPage from '../pages/payment/PaymentListPage';
import PaymentPage from '../pages/report/PaymentPage';

// Quick Pay
import CreatePaymentPage from '../pages/channel/CreatePaymentPage';

// Payment Gateway
import TransferPage from '../pages/transfer/TransferPage';

// Channel Setting
import ChannelSettingPage from '../pages/channel/ChannelSettingPage';
import CampaignCreatePage from '../pages/channel/CampaignCreatePage';

// User Profile
import UserProfilePage from '../pages/user/UserProfilePage';

// Reconcile Report
import Reconcile from '../pages/reconcile/Reconcile';

import Visibility from './Visibility/Visibility';
import * as _ from 'lodash';

const jwtDecode = require('jwt-decode');

const rootpath = config.web.rootpath;

const menuData = {
  // SFinPay Section

  allDashboard: {
    order: 1,
    section: 'SFinPay',
    type: 'item',
    path: `${rootpath}/dashboard`,
    name: 'Dashboard',
    icon: 'far fa-chart-bar',
    perm: 'admin.allDashboard',
  },
  allChannel: {
    order: 2,
    section: 'SFinPay',
    type: 'item',
    path: `${rootpath}/channels`,
    name: 'Channel',
    icon: 'far fa-building',
    perm: 'admin.allChannel',
  },
  allSeller: {
    order: 3,
    section: 'SFinPay',
    type: 'item',
    path: `${rootpath}/sellers`,
    name: 'All Seller',
    icon: 'fas fa-store',
    perm: 'admin.allSeller',
  },
  reconcileAdmin: {
    order: 4,
    section: 'SFinPay',
    type: 'item',
    path: `${rootpath}/reconcile`,
    name: 'Reconcile Report',
    icon: 'far fa-file-alt',
    perm: 'admin.reconcileReport',
  },
  userMgmtAdmin: {
    order: 5,
    section: 'SFinPay',
    type: 'item',
    path: `${rootpath}/user-management`,
    name: 'User Management',
    icon: 'fas fa-user-friends',
    perm: 'admin.userManagement',
  },
  allAutoTransfer: {
    order: 6,
    section: 'SFinPay',
    type: 'item',
    path: `${rootpath}/auto-transfers`,
    name: 'Auto Transfer',
    icon: 'fas fa-sync-alt',
    perm: 'admin.allAutoTransfer',
  },
  systemConfig: {
    order: 7,
    section: 'SFinPay',
    type: 'item',
    path: `${rootpath}/system-config`,
    name: 'System Config',
    icon: 'fas fa-cog',
    perm: 'admin.systemConfig',
  },

  // Channel Section

  dashboard: {
    order: 8,
    section: 'channel',
    type: 'item',
    path: `${rootpath}/dashboard`,
    name: 'Dashboard',
    icon: 'far fa-chart-bar',
    perm: 'channel.dashboard',
  },
  payment: {
    order: 9,
    section: 'channel',
    type: 'item',
    path: `${rootpath}/payments`,
    name: 'Payment',
    icon: 'fas fa-receipt',
    perm: 'channel.payment',
  },
  quickPay: {
    order: 10,
    section: 'channel',
    type: 'item',
    path: `${rootpath}/quick-pay`,
    name: 'Quick Pay',
    icon: 'far fa-credit-card',
    perm: 'channel.quickPay',
  },
  seller: {
    order: 11,
    section: 'channel',
    type: 'item',
    path: `${rootpath}/sellers`,
    name: 'Seller',
    icon: 'fas fa-store-alt',
    perm: 'channel.seller',
  },
  paymentGateway: {
    order: 12,
    section: 'channel',
    type: 'item',
    path: `${rootpath}/payment-gateway`,
    name: 'Payment Gateway',
    icon: 'fas fa-landmark',
    perm: 'channel.paymentGateway',
  },
  reconcileChannel: {
    order: 13,
    section: 'channel',
    type: 'item',
    path: `${rootpath}/reconcile`,
    name: 'Reconcile Report',
    icon: 'far fa-file-alt',
    perm: 'channel.reconcileReport',
  },
  userMgmtChannel: {
    order: 14,
    section: 'channel',
    type: 'item',
    path: `${rootpath}/user-management`,
    name: 'User Management',
    icon: 'fas fa-user-friends',
    perm: 'channel.userManagement',
  },
  autoTransfer: {
    order: 15,
    section: 'channel',
    type: 'item',
    path: `${rootpath}/auto-transfers`,
    name: 'Auto Transfer',
    icon: 'fas fa-sync-alt',
    perm: 'channel.autoTransfer',
  },
  channelSetting: {
    order: 16,
    section: 'channel',
    type: 'item',
    path: `${rootpath}/channel-setting`,
    name: 'Channel Setting',
    icon: 'fas fa-cog',
    perm: 'channel.channelSetting',
  },
};

class App extends React.Component {
  componentDidMount() {
    let token = localStorage.getItem('token');
    if (token) {
      let payload = jwtDecode(token);
      this.props.user.setCurrentUser(payload);
      this.props.user.getUserRole();
    } else {
      this.props.history.push(`${rootpath}/login`);
    }
  }

  render() {
    let { menu_perm } = this.props.user.getData();
    let routes = [];
    let uniqRoutes = [];
    const groupBySection = _.groupBy(Object.values(menuData), 'section');
    for (const [section, menus] of Object.entries(groupBySection)) {
      let menuAccess = menus.filter(item => menu_perm.includes(item.perm));
      let uniqMenuAccess = _.differenceBy(menuAccess, uniqRoutes, 'path');
      uniqRoutes = [...uniqRoutes, ...uniqMenuAccess];
      if (uniqMenuAccess.length > 0) {
        routes.push({ type: 'group', name: section, items: uniqMenuAccess });
        routes.push({ type: 'divider' });
      }
    }
    routes.pop(); // remove last divider
    return (
      <BrowserRouter>
        <Fragment>
          <Header />
          <Row className="header-padding no-gutters">
            <Col id="app-sidebar" xs="auto" className="no-print">
              <Sidebar location={this.props.location} routes={routes} />
            </Col>
            <Col id="app-content" className="content-container">
              {(menu_perm.includes('admin.allDashboard') || menu_perm.includes('channel.dashboard')) && (
                <Fragment>
                  <Route exact path={`${rootpath}/dashboard`} component={DashboardPage} />
                </Fragment>
              )}
              {menu_perm.includes('admin.allChannel') && (
                <Fragment>
                  <Route exact path={`${rootpath}/channels`} component={ChannelManagementPage} />
                  <Route exact path={`${rootpath}/channels/create`} component={CreateChannelPage} />
                  <Route exact path={`${rootpath}/channels/detail/:uid`} component={CreateChannelPage} />
                  <Route exact path={`${rootpath}/channels/history`} component={ChannelHistoryLogPage} />
                  <Route exact path={`${rootpath}/channels/:uid/setting/history`} component={ChannelSettingHistoryLogPage} />
                </Fragment>
              )}
              {menu_perm.includes('admin.allSeller') && (
                <Fragment>
                  <Route exact path={`${rootpath}/sellers`} component={SellerPage} />
                  <Route exact path={`${rootpath}/sellers/pending`} component={ApproveSellerPage} />
                  <Route exact path={`${rootpath}/sellers/detail/:uid`} component={SellerDetailPage} />
                  <Route exact path={`${rootpath}/sellers/detail/:channeluid/:uid`} component={SellerDetailPage} />
                </Fragment>
              )}
              {(menu_perm.includes('admin.reconcileReport') || menu_perm.includes('channel.reconcileReport')) && (
                <Fragment>
                  <Route exact path={`${rootpath}/reconcile`} component={Reconcile} />
                </Fragment>
              )}
              {menu_perm.includes('admin.userManagement') && (
                <Fragment>
                  <Route exact path={`${rootpath}/user-management`} component={RolePermissionPage} />
                </Fragment>
              )}
              <Fragment>
                <Route exact path={`${rootpath}/auto-transfers`} component={AutoTransferListPage} />
                <Route exact path={`${rootpath}/auto-transfers/detail/:channeluid/:gateway`} component={AutoTransferDetailPage} />
                <Route exact path={`${rootpath}/auto-transfers/history`} component={AutoTransferHistoryLogPage} />
              </Fragment>
              {menu_perm.includes('admin.systemConfig') && (
                <Fragment>
                  <Route exact path={`${rootpath}/system-config`} component={SystemConfig} />
                  <Route exact path={`${rootpath}/system-config/history`} component={SystemConfigHistoryLogPage} />
                </Fragment>
              )}
              {menu_perm.includes('channel.payment') && (
                <Fragment>
                  <Route exact path={`${rootpath}/payments`} component={PaymentListPage} />
                  <Route exact path={`${rootpath}/payments/detail/:id`} component={PaymentPage} />
                  <Route exact path={`${rootpath}/payments/consolidated/:uid`} component={PaymentPage} />
                </Fragment>
              )}
              {menu_perm.includes('channel.quickPay') && (
                <Fragment>
                  <Route exact path={`${rootpath}/quick-pay`} component={CreatePaymentPage} />
                </Fragment>
              )}
              {menu_perm.includes('channel.seller') && (
                <Fragment>
                  <Route exact path={`${rootpath}/channels/detail/:uid`} component={CreateChannelPage} />
                  <Route exact path={`${rootpath}/sellers`} component={SellerPage} />
                  <Route exact path={`${rootpath}/sellers/register`} component={SellerRegisterPage} />
                  <Route exact path={`${rootpath}/sellers/register/mass`} component={ImportSellerPage} />
                  <Route exact path={`${rootpath}/sellers/pending`} component={ApproveSellerPage} />
                  <Route exact path={`${rootpath}/sellers/detail/:channeluid/:uid`} component={SellerDetailPage} />
                </Fragment>
              )}
              {menu_perm.includes('channel.paymentGateway') && (
                <Fragment>
                  <Route exact path={`${rootpath}/payment-gateway`} component={TransferPage} />
                  <Route exact path={`${rootpath}/payment-gateway/:gateway`} component={TransferPage} />
                  <Route exact path={`${rootpath}/payment-gateway/transfer/report/:report_no`} component={TransferPage} />
                </Fragment>
              )}
              {menu_perm.includes('channel.userManagement') && (
                <Fragment>
                  <Route exact path={`${rootpath}/user-management`} component={RolePermissionPage} />
                </Fragment>
              )}
              {menu_perm.includes('channel.channelSetting') && (
                <Fragment>
                  <Route exact path={`${rootpath}/channel-setting`} component={ChannelSettingPage} />
                  <Route exact path={`${rootpath}/channel-setting/campaign/create`} component={CampaignCreatePage} />
                  <Route exact path={`${rootpath}/channel-setting/campaign/:id/edit`} component={CampaignCreatePage} />
                  <Route exact path={`${rootpath}/channel-setting/campaign/:id/edit/:uid`} component={CampaignCreatePage} />
                  <Route exact path={`${rootpath}/channel-setting/campaign/history`} component={ChannelSettingHistoryLogPage} />
                </Fragment>
              )}
              <Route exact path={`${rootpath}/users/profile`} component={UserProfilePage} />
            </Col>
          </Row>
        </Fragment>
      </BrowserRouter>
    );
  }
}

export default inject('user', 'role')(observer(App));
