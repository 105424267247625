import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import numeral from 'numeral';
import { Card, CardHeader, CardBody, CustomInput, Row, Col, Label, FormGroup, FormFeedback } from 'reactstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { config } from '../../../config';
import { AUTO_TRANSFER_SCHEDULE_TYPES } from '../../../constants/transferConstants';
import { Page } from '../../../components/Page';
import NexterInput from '../../../components/Input/NexterInput';
import NexterButton from '../../../components/Button/NexterButton';
import Visibility from '../../../components/Visibility/Visibility';
import ScheduleWeekDaySelector from './ScheduleWeekDaySelector';
import ScheduleMonthDateSelector from './ScheduleMonthDateSelector';

const SCHEDULE_TYPE_VALUES = ['daily', 'weekly', 'monthly', 'dates'];
const SCHEDULE_TYPE_OPTIONS = SCHEDULE_TYPE_VALUES.map(value => ({ label: AUTO_TRANSFER_SCHEDULE_TYPES[value], value }));

class AutoTransferDetailPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const channelUid = this.props.match?.params?.channeluid;
    const gateway = this.props.match?.params?.gateway;
    if (channelUid && gateway) {
      this.props.autoTransfer.getAutoTransfer(channelUid, gateway);
    }
  }

  componentWillUnmount() {
    this.props.autoTransfer.clearDetailForm();
  }

  onChangeValue(type, value) {
    const { autoTransfer } = this.props;
    switch (type) {
      case 'status':
        autoTransfer.changeDetailStatus(value);
        break;
      case 'minAmount':
        autoTransfer.changeDetailMinAmount(value);
        break;
      case 'scheduleType':
        autoTransfer.changeDetailScheduleType(value);
        break;
      case 'scheduleDay':
        autoTransfer.changeDetailScheduleDay(value);
        break;
      default:
        break;
    }
  }

  onSave() {
    this.props.autoTransfer.confirmUpdateAutoTransfer();
  }

  render() {
    const { loading, autoTransfer: data, autoTransferDetailForm: formData, autoTransferFormValidation: formDataValidation } = this.props.autoTransfer.getData();
    const selectedScheduleType = SCHEDULE_TYPE_OPTIONS.find(option => option.value === formData.schedule_type) || null;

    const scheduleTypeInfo =
      formData.schedule_type === 'daily'
        ? `กรณีเลือก <b>"รายวัน"</b> ระบบจะทำการโอนเงินอัตโนมัติ <b>"ทุกวัน"</b>`
        : formData.schedule_type === 'monthly'
        ? `กรณีเลือก <b>"รายเดือน"</b> ระบบจะทำการโอนเงินอัตโนมัติให้ <b>"ทุกวันสุดท้ายของเดือน"</b>`
        : null;

    const daySelector =
      formData.schedule_type === 'weekly' ? (
        <ScheduleWeekDaySelector days={formData.schedule_day} onChange={value => this.onChangeValue('scheduleDay', value)} />
      ) : formData.schedule_type === 'dates' ? (
        <ScheduleMonthDateSelector dates={formData.schedule_day} onChange={value => this.onChangeValue('scheduleDay', value)} />
      ) : null;

    return (
      <Page loading={loading}>
        <HeaderTitle onSave={this.onSave.bind(this)} />
        <Visibility permission_code="auto_transfer:edit">
          <Card className="auto-transfer-detail p-4 text-base">
            <CardHeader>
              <HeaderInfo channelName={data.channel_name} gateway={data.gateway} minAmount={data.global_min_amount} />
              <hr className="mt-5 mb-4" />
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs={12} lg={5} className="pb-4">
                  <div className="d-flex align-items-center py-2">
                    <Label className="form-label mb-0 mr-3">Status :</Label>
                    <CustomInput
                      type="switch"
                      id="status"
                      name="status"
                      value={formData.status === 'active' ? 'inactive' : 'active'}
                      checked={formData.status === 'active'}
                      onChange={e => this.onChangeValue('status', e.target.value)}
                    />
                    <Label className={classNames('text-capitalize ml-2 mb-0', { 'text-active-color': formData.status === 'active' })}>{formData.status}</Label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={5} className="pb-4">
                  <FormGroup className="required">
                    <Label className="control-label form-label">ยอดโอนขั้นต่ำ</Label>
                    <NexterInput
                      size="sm"
                      name="min_amount"
                      allow="numeric"
                      value={formData.min_amount}
                      invalid={!!formDataValidation.min_amount}
                      className="nexter-input-sm"
                      onChange={e => this.onChangeValue('minAmount', e.target.value)}
                    />
                    <div className={classNames('text-xs mt-1', !formDataValidation.min_amount ? 'text-secondary' : 'text-failed')}>
                      {formDataValidation.min_amount || `โปรดระบุยอดขั้นต่ำอย่างน้อย ${numeral(data.global_min_amount).format('0,0[.]00')} บาท`}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={5} className="pb-4">
                  <FormGroup className="required">
                    <Label className="control-label form-label">ประเภทการโอน</Label>
                    <NexterInput
                      type="select"
                      size="sm"
                      options={SCHEDULE_TYPE_OPTIONS}
                      value={selectedScheduleType}
                      invalid={!!formDataValidation.schedule_type}
                      className="nexter-select-sm"
                      placeholder="โปรดเลือกประเภทการโอน"
                      onChange={e => this.onChangeValue('scheduleType', e?.value)}
                    />
                    <FormFeedback className="text-xs mt-1">{formDataValidation.schedule_type}</FormFeedback>
                    {!!scheduleTypeInfo && (
                      <div className="d-flex align-items-center mt-4">
                        <i className="fa fa-info-circle text-blue text-md mr-2"></i>
                        <div dangerouslySetInnerHTML={{ __html: scheduleTypeInfo }}></div>
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={7}>
                  {daySelector}
                  {!!formDataValidation.schedule_day && <div className="text-xs mt-1 text-failed">{formDataValidation.schedule_day}</div>}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Visibility>
        <Visibility permission_code="auto_transfer:edit" invisibility_msg>
          <h5>ท่านไม่มีสิทธิ์ดูรายละเอียดของ Auto Transfer</h5>
        </Visibility>
      </Page>
    );
  }
}

const HeaderTitle = ({ onSave }) => (
  <div className="container-fluid p-0">
    <Row>
      <Col className="label-header text-uppercase mb-4">Auto Transfer</Col>
    </Row>
    <Visibility permission_code="auto_transfer:edit">
      <Row>
        <Col xs={12} md={6} className="mb-4">
          <Link to={`${config.web.rootpath}/auto-transfers`} className="title-goback">
            <i className="back-icon fas fa-chevron-left my-auto mr-4" />
            <span className="texthead-goback">Edit Auto Transfer</span>
          </Link>
        </Col>
        <Col xs={12} md={6} className="d-flex justify-content-end mb-4">
          <div className="d-flex align-items-center">
            <Link to={`${config.web.rootpath}/auto-transfers`}>
              <NexterButton className="my-0" size="sm" color="default" outline>
                Cancel
              </NexterButton>
            </Link>
            <NexterButton className="ml-2 my-0" size="sm" color="success" outline icon="fas fa-save" onClick={() => onSave()}>
              Save
            </NexterButton>
          </div>
        </Col>
      </Row>
    </Visibility>
  </div>
);

const HeaderInfo = ({ channelName, gateway, minAmount = 0 }) => (
  <Row style={{ gap: '20px 0' }}>
    <Col xs={12} md={3}>
      <div className="font-weight-bold mb-md-3">Channel Name</div>
      <div>{channelName}</div>
    </Col>
    <Col xs={12} md={3}>
      <div className="font-weight-bold mb-md-3">Payment Gateway</div>
      <div className="text-uppercase">{gateway}</div>
    </Col>
    <Col xs={12} md={3}>
      <div className="font-weight-bold mb-md-3">ยอดขั้นต่ำของ Payment Gateway</div>
      <div>{numeral(minAmount).format('0,0[.]00')}</div>
    </Col>
  </Row>
);

export default inject('autoTransfer', 'channel')(observer(AutoTransferDetailPage));
