import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Card, CardBody, CardFooter, Col, Row, Table, UncontrolledTooltip } from 'reactstrap';
import numeral from 'numeral';
import { StickyTable, Row as R, Cell } from '../../components/Table/StickyTable';

import NexterButton from '../../components/Button/NexterButton';
import { Amount } from '../../components/Field/Amount';
import { Pagination } from '../../components/Pagination/Pagination';
import { EmptyRow } from '../../components/Table/EmptyRow';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import iNoData from '../../assets/img/icon-no-data.svg';
import { ReconcileTab } from '../../stores/ReconcileStore';
const tableHeaderColumns = (tab) => [
  {
    title: 'Channel',
    width: 130,
  },
  {
    title: 'หมายเลขคำสั่งซื้อ',
    width: 160,
  },
  {
    title: 'Invoice',
    width: 160,
  },
  {
    title: 'ผู้ชำระเงิน',
  },
  {
    title: 'รายละเอียด',
    width: 160,
  },
  {
    title: 'ข่องทางชำระเงิน',
    width: 140,
  },
  {
    title: (
      <>
        Payment
        <br />
        Gateway
      </>
    ),
    width: 130,
  },
  {
    title: 'ผู้ขาย/ผู้รับเงิน',
  },
  {
    title: (
      <>
        สถานะ
        <br />
        การชำระเงิน
      </>
    ),
    className: 'text-center',
    width: 105,
  },
  tab === ReconcileTab.MONEY_OUT && {
    title: (
      <>
        สถานะ
        <br />
        การโอน
      </>
    ),
    className: 'text-center',
    width: 105,
  },
  {
    title: 'วันที่ชำระเงิน',
    className: 'text-center',
    width: 107,
  },
  tab === ReconcileTab.MONEY_OUT && {
    title: 'วันที่โอนเงิน',
    className: 'text-center',
    width: 107,
  },
  {
    title: (
      <>
        sFinPay net
        <br />
        amount
      </>
    ),
    className: 'text-right',
    width: 120,
  },
  {
    title: (
      <>
        Gateway net
        <br />
        amount
      </>
    ),
    className: 'text-right',
    width: 120,
  },
  {
    title: 'Different',
    className: 'text-right',
    width: 120,
  },
].filter(c => c);

class ReconcileTranTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hightLight: [],
    };
  }
  componentDidMount() {
    this.getTran();
  }
  getTran = () => {
    const { loggedinUser } = this.props.user.getData();
    const isSuperAdmin = ['super_admin', 'biz_system', 'ae_marketing', 'manager'].includes(loggedinUser.type);
    this.props.reconcile.getTran(isSuperAdmin);
  };
  getTranList() {
    const {
      tran: { items },
    } = this.props.reconcile.getData();
    return items && items.length > 0
      ? items.map(item => {
        let statusString = item.status;
        if (statusString === 'refunded') statusString = 'refund';
        else if (statusString === 'pending_settle') statusString = 'Pending Settle';
        else if (statusString === 'mismatch_settled') statusString = 'Mismatch Settled';
        const paymentMethodString = this.getPaymentMethodString(item.method);
        return { ...item, paymentMethodString, statusString };
      })
      : [];
  }
  getPaymentMethodString(method) {
    switch (method) {
      case 'credit_card':
        return 'Credit Card';
      case 'qr_box':
        return (
          <>
            Bill Payment (BBL)
            <br />
            &#8226; Mobile Banking
          </>
        );
      case 'bill_payment_mobile':
        return 'Mobile Banking';
      case 'bill_payment':
        return 'Counter Service';
      case 'line':
        return 'Line Pay';
      case 'installment':
        return 'Installment';
      case 'scg_wallet':
        return 'SCG Wallet';
      case 'chang_family_wallet':
        return 'Chang Family Wallet';
      case 'promptpay':
        return 'Thai QR Payment';
      case 'grabpay':
        return 'GrabPay';
      case 'cbdc':
        return 'CBDC Payment';
      case 'mobile_banking_opn':
        return 'Mobile Banking (App Switch)';
      default:
        return '-';
    }
  }
  selectPage(page) {
    this.props.reconcile.tran.page = page;
    this.getTran();
  }
  renderPaymentRecords() {
    const items = this.getTranList();
    const amount = items.reduce((sum, p) => sum + p.amount ?? 0, 0);
    const reconcile_amount = items.reduce((sum, p) => sum + p.reconcile_amount ?? 0, 0);
    const diff = items.reduce((sum, p) => sum + p.diff ?? 0, 0);
    const { tab } = this.props.reconcile.getData();
    return items && items.length ? (
      <>
        {items.map(({ channel, ref_id, customer_name, description, method, gateway, seller_name, status, transfer_status, payment_at, statusString, paymentMethodString, gateway_invoice_no, transfer_date, ...rest }, i) => (
          <R key={i}>
            <Cell>{channel}</Cell>
            <Cell>{ref_id}</Cell>
            <Cell>{gateway_invoice_no}</Cell>
            <Cell>
              <Highlighter searchWords={this.state.hightLight} autoEscape textToHighlight={customer_name} />
            </Cell>
            <Cell id={rest.uid + '_d'}>
              <Highlighter
                searchWords={this.state.hightLight}
                autoEscape
                textToHighlight={description && description.length > 26 ? description.substring(0, 36) + '...' : !description ? '-' : description}
              />
              {description && description.length > 26 ? (
                <UncontrolledTooltip placement="top" target={rest.uid + '_d'} delay={{ show: 500, hide: 0 }}>
                  {description}
                </UncontrolledTooltip>
              ) : (
                ''
              )}
            </Cell>
            <Cell>{paymentMethodString}</Cell>
            <Cell>{gateway.toUpperCase()}</Cell>
            <Cell>{seller_name ?? '-'}</Cell>
            <Cell className="text-center">
              <NexterButton size="sm" color="link">
                <span className={`badge status status-${status}`}>{statusString}</span>
              </NexterButton>
            </Cell>
            {tab === ReconcileTab.MONEY_OUT &&
              <Cell className="text-center text-capitalize">
                <span className={`badge transfer-status transfer-status-${transfer_status}`}>{transfer_status}</span>
              </Cell>}
            <Cell className="text-center">
              {payment_at && (
                <>
                  {moment(payment_at).format('YYYY-MM-DD')}
                  <br />
                  {moment(payment_at).format('HH:mm')}
                </>
              )}
            </Cell>
            {tab === ReconcileTab.MONEY_OUT &&
              <Cell className="text-center">
                {transfer_date && (
                  <>
                    {moment(transfer_date).format('YYYY-MM-DD')}
                    <br />
                    {moment(transfer_date).format('HH:mm')}
                  </>
                )}
              </Cell>
            }
            <Cell className="text-right">
              <Amount value={rest.amount ?? 0} />
            </Cell>
            <Cell className="text-right">
              <Amount value={rest.reconcile_amount ?? 0} />
            </Cell>
            <Cell className="text-right" style={{ color: rest.diff < 0 ? '#E10C19' : 'black' }}>
              <span id={`${rest.uid}_${i}`}>
                <Amount value={rest.diff} />
              </span>
              <UncontrolledTooltip placement="top-end" target={`${rest.uid}_${i}`} delay={{ show: 500, hide: 0 }}>
                {numeral(rest.diff).format('0,0.0000')}
              </UncontrolledTooltip>
            </Cell>
          </R>
        ))}
        <R>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          <Cell></Cell>
          {tab === ReconcileTab.MONEY_OUT && <Cell></Cell>}
          {tab === ReconcileTab.MONEY_OUT && <Cell></Cell>}
          <Cell className="text-right">{<Amount value={amount} />}</Cell>
          <Cell className="text-right">
            <Amount value={reconcile_amount} />
          </Cell>
          <Cell className="text-right" style={{ color: diff < 0 ? '#E10C19' : 'black' }}>
            <span id={`summary`}>
              <Amount value={diff} />
            </span>
            <UncontrolledTooltip placement="top-end" target={`summary`} delay={{ show: 500, hide: 0 }}>
              {numeral(diff).format('0,0.0000')}
            </UncontrolledTooltip>
          </Cell>
        </R>
      </>
    ) : (
      <React.Fragment>
        <div
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0%)',
            textAlign: 'center',
          }}
        >
          <div>
            <img src={iNoData} alt="data not found" />
          </div>
          <span>{'ไม่พบข้อมูล'}</span>
        </div>
        <div style={{ height: 140 }} />
      </React.Fragment>
    );
  }
  render() {
    const { tran, tab } = this.props.reconcile.getData();
    return (
      <>
        <hr className="mt-4 mb-0" />
        {/* uses mr-0 because cols use pr-0 */}
        <div style={{ width: '100%' }}>
          <StickyTable stickyHeaderCount={1} leftStickyColumnCount={0} rightStickyColumnCount={3} stickyFooterCount={1} borderWidth="1px" borderColor="rgba(0, 0, 0, 0.125)">
            <R>
              {tableHeaderColumns(tab).map(({ title, className = '', width = 100, props = {} }, i) => {
                return (
                  <Cell key={i} className={classNames(className)} style={{ minWidth: width, maxWidth: width, fontWeight: '700' }} scope="col" {...props}>
                    <div style={{ marginTop: 15 }}>{title}</div>
                  </Cell>
                );
              })}
            </R>
            {this.renderPaymentRecords()}
          </StickyTable>
        </div>
        {tran.items.length > 0 && (
          <Row>
            <Col>
              <Pagination className="pull-right" activePage={tran.page} displayPageNumber={5} maximumPageNumber={Math.ceil(tran.total / tran.size)} onSelect={this.selectPage.bind(this)} />
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default inject('channel', 'seller', 'reconcile', 'user')(observer(ReconcileTranTable));
