/* eslint-disable prefer-promise-reject-errors */
import React, { Component, useState, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Card, Table } from 'reactstrap';
import swal from 'sweetalert2';
import Visibility from '../../components/Visibility/Visibility';
import RolePermissionTable from './RolePermissionTable';
import * as _ from 'lodash';
class PermissionSetting extends Component {
  componentWillUnmount() {
    this.props.role.editableT5 = false;
  }
  componentDidMount() {
    this.props.role.fetchAll();
  }
  editConfig() {
    this.props.role.editableT5 = !this.props.role.editableT5;
    this.forceUpdate();
  }
  async onSaveRolePermission(rp) {
    const result = await swal.fire({
      customClass: 'nexter-alert',
      title: `ยืนยันการอัปเดต`,
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ยืนยัน',
    });
    if (result.value && rp.length > 0) {
      await this.props.role.updateRolePermission(rp);
    }
  }

  render() {
    const { permission } = this.props.role.getData();
    const PermGroup = _.groupBy(permission, 'permission_group');
    const select = Object.keys(PermGroup);
    return (
      <Fragment>
        <Card className="card-top-no-radius">
          <h2 className="role-permission-text">ROLE PERMISSSION</h2>
          <Visibility permission_code="role_permission:view" invisibility_msg>
            <h5 className="mx-3 mb-4">ท่านไม่มีสิทธิ์ดูการใช้งาน Role Permission</h5>
          </Visibility>
        </Card>
        <Visibility permission_code="role_permission:view">
          {select.map((permG, index) => {
            const code = permG.replace(' ', '_');
            if (permG !== 'Menu')
              return (
                <Card key={`${permG}-${index}}`} className="card-bottom-no-radius">
                  <RolePermissionTable
                    perm_list={PermGroup[permG]}
                    groupName={permG}
                    key={`rp-table-${code}-${index}}`}
                    id={`rp-table-${code}-${index}`}
                    onSave={this.onSaveRolePermission.bind(this)}
                  />
                </Card>
              );
          })}
        </Visibility>
      </Fragment>
    );
  }
}

export default inject('role')(observer(PermissionSetting));
