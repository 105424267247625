import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Tree } from 'antd';
import { Card, Row, Col } from 'reactstrap';
import swal from 'sweetalert2';
import { CloseCircleOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import * as _ from 'lodash';
import NexterButton from '../../components/Button/NexterButton';
import Visibility from '../../components/Visibility/Visibility';
import NexterInput from '../../components/Input/NexterInput';
import Select from 'react-select';

class MenuSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      select_role: {
        value: null,
      },
      role_option: [],
      menu_tree: [
        {
          title: 'Admin',
          key: 'admin',
          children: [
            {
              title: 'Dashboard',
              key: 'admin.allDashboard',
            },
            {
              title: 'Channel',
              key: 'admin.allChannel',
            },
            {
              title: 'All Seller',
              key: 'admin.allSeller',
            },
            {
              title: 'Reconcile Report',
              key: 'admin.reconcileReport',
            },
            {
              title: 'User Management',
              key: 'admin.userManagement',
            },
            {
              title: 'Auto Transfer',
              key: 'admin.allAutoTransfer',
            },
            {
              title: 'System Config',
              key: 'admin.systemConfig',
            },
          ],
        },
        {
          title: 'Channel',
          key: 'channel',
          children: [
            {
              title: 'Dashboard',
              key: 'channel.dashboard',
            },
            {
              title: 'Payment',
              key: 'channel.payment',
            },
            {
              title: 'Quick Pay',
              key: 'channel.quickPay',
            },
            {
              title: 'Seller',
              key: 'channel.seller',
            },
            {
              title: 'Payment Gateway',
              key: 'channel.paymentGateway',
            },
            {
              title: 'Reconcile Report',
              key: 'channel.reconcileReport',
            },
            {
              title: 'User Management',
              key: 'channel.userManagement',
            },
            {
              title: 'Auto Transfer',
              key: 'channel.autoTransfer',
            },
            {
              title: 'Channel Setting',
              key: 'channel.channelSetting',
            },
          ],
        },
      ],
    };
  }
  componentWillUnmount() {
    this.props.user.editable = false;
  }

  async componentDidMount() {
    await Promise.all([this.props.user.getAllRole(), this.props.role.fetchAll()]);
    const { role } = this.props.role.getData();
    const optionRole = role.map(item => {
      item.value = item.id;
      item.label = item.role_name;
      return item;
    });
    this.setState({ role_option: optionRole });
  }

  changeConfig(key, value) {
    const { menu_permission } = this.props.role.getData();
    if (key === 'role_id') {
      this.props.role.focus_role = value;
      let roleSelect = _.find(menu_permission, { role_id: value.value });
      this.props.role.focus_menu = roleSelect.menu.map(item => item.menu);
      this.setState({ select_role: value });
    } else if (key === 'menu_id') {
      this.props.role.focus_menu = value;
    }
  }

  async saveConfig() {
    const result = await swal.fire({
      customClass: 'nexter-alert',
      title: `ยืนยันการอัปเดต`,
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ยืนยัน',
    });
    if (result.value) {
      await this.props.role.updateRoleMenu();
      this.props.user.editable = false;
    }
  }
  onClickBtn(type, e) {
    if (type === 'edit') {
      this.props.user.editable = !this.props.user.editable;
    } else if (type === 'save') {
      this.saveConfig();
    } else if (type === 'cancel') {
      this.props.user.editable = !this.props.user.editable;
    }
    console.log(type);
  }
  render() {
    let { role_option, menu_tree, select_role } = this.state;
    return (
      <Card className="p2 card-top-no-radius">
        <Row>
          <Col>
            <h2 className="menu-text">Menu Setting</h2>
          </Col>
          <Visibility permission_code="menu_setting:view">
            <Col>
              <Visibility permission_code="menu_setting:edit">
                <div className="pull-right">
                  <Visibility isVisibility={this.props.user.editable}>
                    <NexterButton className="btn-sm mx-2" icon="fa fa-edit" color="default" outline onClick={this.onClickBtn.bind(this, 'cancel')} id="cancel">
                      Cancel
                    </NexterButton>
                    <NexterButton className="btn-sm" icon="fa fa-edit" color="success" outline onClick={this.onClickBtn.bind(this, 'save')} id="save">
                      Save
                    </NexterButton>
                  </Visibility>
                  <Visibility isVisibility={!this.props.user.editable}>
                    <NexterButton className="btn-sm" icon="fa fa-edit" onClick={this.onClickBtn.bind(this, 'edit')}>
                      Edit
                    </NexterButton>
                  </Visibility>
                </div>
              </Visibility>
            </Col>
          </Visibility>
        </Row>
        <hr />
        <Visibility permission_code="menu_setting:view">
          <Row className="p2">
            <Col md="6">
              <NexterInput
                label="เลือก Role"
                require
                type="select"
                placeholder="เลือก Role...."
                size="sm"
                className="seller no-print"
                value={select_role}
                options={role_option}
                disabled={!this.props.user.editable}
                onChange={this.changeConfig.bind(this, 'role_id')}
              />
              <br />
              <label>เลือก Menu</label>
              <Tree
                disabled={!this.props.user.editable}
                checkable
                checkedKeys={this.props.role.focus_menu}
                defaultExpandedKeys={['admin', 'channel']}
                onCheck={this.changeConfig.bind(this, 'menu_id')}
                treeData={menu_tree}
              />
            </Col>
          </Row>
        </Visibility>
        <Visibility permission_code="menu_setting:view" invisibility_msg>
          <h5>ท่านไม่มีสิทธิ์ดูการใช้งาน Menu Setting</h5>
        </Visibility>
      </Card>
    );
  }
}

export default inject('system', 'user', 'role')(observer(MenuSetting));
