import React, { Fragment } from 'react';

export const KYCType = { personal: 'บุคคลธรรมดา', company: 'นิติบุคคล' };

export const DisplayStatus = (status, fontSize) => {
  let display = <div />;
  switch (status) {
    case 'approved':
      display = <DisplayApproved fontSize={fontSize} />;
      break;
    case 'rejected':
      display = <DisplayRejected fontSize={fontSize} />;
      break;
    case 'pending':
      display = <DisplayPending fontSize={fontSize} />;
      break;
    case 'created':
      display = <DisplayCreated fontSize={fontSize} />;
      break;
    case 'incomplete':
    case 'incompleted':
      display = <DisplayIncomplete fontSize={fontSize} />;
      break;
    default:
      display = '-';
  }
  return display;
};

export const DisplayApproved = ({ fontSize }) => (
  <Fragment>
    <i className="nc-icon nc-check-2 text-success align-middle mr-2" style={{ fontSize: fontSize }} />
    <span className="align-middle">APPROVED</span>
  </Fragment>
);

export const DisplayPending = ({ fontSize }) => (
  <Fragment>
    <i className="nc-icon nc-single-copy-04 text-warning align-middle mr-2" style={{ fontSize: fontSize }} />
    <span className="align-middle">PENDING</span>
  </Fragment>
);

export const DisplayRejected = ({ fontSize }) => (
  <Fragment>
    <i className="nc-icon nc-simple-remove text-danger align-middle mr-2" style={{ fontSize: fontSize }} />
    <span className="align-middle">REJECTED</span>
  </Fragment>
);

export const DisplayCreated = ({ fontSize }) => (
  <Fragment>
    <i className="nc-icon nc-alert-circle-i text-muted align-middle mr-2" style={{ fontSize: fontSize }} />
    <span className="align-middle text-muted">CREATED</span>
  </Fragment>
);

export const DisplayIncomplete = ({ fontSize }) => (
  <Fragment>
    <i className="nc-icon nc-single-copy-04 text-danger align-middle mr-2" style={{ fontSize: fontSize }} />
    <span className="align-middle">ขอเอกสารเพิ่ม</span>
  </Fragment>
);
