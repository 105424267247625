import _ from 'lodash';
import React, { Component } from 'react';
import { Pagination as ReactPagination, PaginationItem, PaginationLink } from 'reactstrap';

export class Pagination extends Component {
  selectPage(page) {
    this.props.onSelect(page);
  }

  render() {
    const normal = { fontWeight: 700, width: 45 };
    const active = { ...normal, background: '#007bff', color: '#fff' };

    let { activePage, displayPageNumber, maximumPageNumber } = this.props;

    /// calculate min/max to display buttons ///
    let max = activePage + 2;
    if (max < displayPageNumber) max = displayPageNumber;
    if (max > maximumPageNumber) max = maximumPageNumber;

    let min = activePage - 2;
    if (min < 1 || max <= 5) min = 1;

    let items = _.range(min, max + 1).map(item => {
      return (
        <PaginationItem key={item}>
          <PaginationLink
            style={item === activePage ? active : normal}
            onClick={this.selectPage.bind(this, item)}>
            {item}
          </PaginationLink>
        </PaginationItem>
      );
    });

    return (
      <ReactPagination className={this.props.className || ''} size={this.props.size || 'md'}>
        <PaginationItem disabled={activePage <= 1}>
          <PaginationLink style={normal} onClick={this.selectPage.bind(this, 1)}>
            <i className="fas fa-angle-double-left" />
          </PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={activePage <= 1}>
          <PaginationLink style={normal} onClick={this.selectPage.bind(this, activePage - 1)}>
            <i className="fas fa-angle-left" />
          </PaginationLink>
        </PaginationItem>

        {items}

        <PaginationItem disabled={activePage === maximumPageNumber}>
          <PaginationLink style={normal} onClick={this.selectPage.bind(this, activePage + 1)}>
            <i className="fas fa-angle-right" />
          </PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={activePage === maximumPageNumber}>
          <PaginationLink style={normal} onClick={this.selectPage.bind(this, maximumPageNumber)}>
            <i className="fas fa-angle-double-right" />
          </PaginationLink>
        </PaginationItem>
      </ReactPagination>
    );
  }
}
