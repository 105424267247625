import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Nav, NavItem, NavLink, Row, Col, TabContent, TabPane, Card, Alert } from 'reactstrap';
import { Page } from '../../../components/Page';
import DataSellerDetail from './DataSellerDetail';
import DataAccountDetail from './DataAccountDetail';
import DataKYCDetail from './DataKYCDetail';
import HistoryLog from './HistoryLog';
import SellerDeleteStatusAlert from './SellerDeleteStatusAlert';
import { config } from '../../../config';

class SellerDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.changeActiveTab = this.changeActiveTab.bind(this);

    this.dataSellerDetail = React.createRef();
    this.dataAccountDetail = React.createRef();
  }

  async componentDidMount() {
    const uid = this.props.match?.params?.uid;
    const channelUid = this.props.match?.params?.channeluid;
    if (uid && channelUid) {
      // NL-1679
      const canUserTypeViewChannel = this.canUserTypeViewChannel(channelUid);
      this.setState({ canUserTypeViewChannel });
      if (!canUserTypeViewChannel) {
        return;
      }
      await this.props.seller.getSellerByUid(uid, channelUid);
      this.props.seller.countSellers(uid);
      this.showRejectDeleteSellerReasonModal();
    }
  }

  getInitialState() {
    let activeTab = 'seller-detail';
    const tab = new URL(window.location.href).searchParams.get('tab')
    switch (tab) {
      case 'account':
        activeTab = 'account-detail';
        break;
      case 'kyc':
        activeTab = 'kyc-detail';
        break;
      default:
        break;
    }
    return {
      activeTab: activeTab,
      canUserTypeViewChannel: true,
    };
  }

  canUserTypeViewChannel(channelUid) {
    const { loggedinUser } = this.props.user.getData();
    return !(['channel_admin', 'channel_user'].includes(loggedinUser.type) && loggedinUser.channel.uid !== channelUid);
  }

  changeActiveTab(e) {
    const tabId = e.target.id;
    this.setState({ activeTab: tabId });
  }

  goBack() {
    this.props.seller.pageSellerlist = 1;
    this.props.seller.pagePending = 1;
    this.props.seller.changeSellerMgmtView('list');
    window.location.href = `${config.npay.cmsUrl}/sellers`;
  }

  validateData = () => {
    const resDataAccountDetail = this.dataAccountDetail.current.wrappedInstance.validateInputs()
    const resDataSellerDetail = this.dataSellerDetail.current.wrappedInstance.validateInputs()
    return resDataAccountDetail && resDataSellerDetail
  }

  clearValidateData = () => {
    this.dataAccountDetail.current.wrappedInstance.clearValidateInputs()
    this.dataSellerDetail.current.wrappedInstance.clearValidateInputs()
  }

  showRejectDeleteSellerReasonModal() {
    const { user_rp } = this.props.user.getData();
    const isPermissiondDelete = ['seller:delete'].some(role => user_rp.includes(role));
    if (isPermissiondDelete) {
      this.props.seller.preConfirmRejectReasonDeleteSeller();
    }
  }

  approveDeleteSeller(sellerId) {
    this.props.seller.approveDeleteSeller(sellerId);
  }

  rejectDeleteSeller(sellerId) {
    this.props.seller.confirmRejectDeleteSeller(sellerId);
  }

  reApproveDeleteSeller(sellerId) {
    this.props.seller.reApproveDeleteSeller(sellerId);
  }

  render() {
    const { loading, seller } = this.props.seller.getData();
    const { user_rp } = this.props.user.getData();
    const { activeTab, canUserTypeViewChannel } = this.state;
    const { parentTab, createNewSeller = false, taxId = null, form = null, handleChange = null, handleKYCFile = null, removeKYCFile = null, onShareholderAction = null } = this.props;
    const KYCProps = {
      taxId,
      form,
      handleChange,
      handleKYCFile,
      removeKYCFile,
      onShareholderAction
    }
    const isPermissionApprove = ['seller:approve'].some(role => user_rp.includes(role));
    const isPermissionReApprove = ['seller:re_approve'].some(role => user_rp.includes(role));

    if (!canUserTypeViewChannel) {
      return <h5>ท่านไม่มีสิทธิ์ดูรายละเอียดของ Seller</h5>;
    }

    return (
      <Page loading={loading}>
        {!createNewSeller && <>
          <Row>
            <Col>
              <p className="label-header text-uppercase">Seller</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="title-goback">
                <a onClick={this.goBack.bind(this)}>
                  <i className="back-icon fas fa-chevron-left my-auto mr-4" />
                </a>
                <span className="texthead-goback">Seller Details</span>
              </div>
            </Col>
          </Row>
        </>}
        {!!seller.delete_status && (
          <SellerDeleteStatusAlert
            status={seller.delete_status}
            rejectReason={seller.reject_delete_reason}
            canApprove={isPermissionApprove}
            canReApprove={isPermissionReApprove}
            onApprove={this.approveDeleteSeller.bind(this, seller.id)}
            onReject={this.rejectDeleteSeller.bind(this, seller.id)}
            onReApprove={this.reApproveDeleteSeller.bind(this, seller.id)}
          />
        )}
        <Row>
          <Col>
            <Nav tabs className="float-left">
              <NavItem>
                <NavLink href="#" active={activeTab === 'seller-detail'} onClick={this.changeActiveTab} id="seller-detail">
                  ข้อมูลผู้ขาย
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" active={activeTab === 'account-detail'} onClick={this.changeActiveTab} id="account-detail">
                  ข้อมูลบัญชี
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" active={activeTab === 'KYC-detail'} onClick={this.changeActiveTab} id="KYC-detail">
                  ข้อมูล KYC
                </NavLink>
              </NavItem>
              {!this.props.createNewSeller && <NavItem>
                <NavLink href="#" active={activeTab === 'history-edit'} onClick={this.changeActiveTab} id="history-edit">
                  ประวัติการแก้ไข
                </NavLink>
              </NavItem>}
            </Nav>
          </Col>
        </Row>
        <Card className="card-seller-detail">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="seller-detail">
              <DataSellerDetail parentTab={parentTab} createNewSeller={createNewSeller} ref={this.dataSellerDetail}/>
            </TabPane>
            <TabPane tabId="account-detail">
              <DataAccountDetail createNewSeller={createNewSeller} ref={this.dataAccountDetail}/>
            </TabPane>
            <TabPane tabId="KYC-detail">
              <DataKYCDetail createNewSeller={createNewSeller} {...KYCProps}/>
            </TabPane>
            {!this.props.createNewSeller && <TabPane tabId="history-edit">
              <HistoryLog />
            </TabPane>}
          </TabContent>
        </Card>
      </Page>
    );
  }
}

export default inject('seller', 'user')(observer(SellerDetailPage));
