import '@babel/polyfill';

import React from 'react';
import { render } from 'react-dom';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';

import stores from './stores';
import { config } from './config';

import App from './components/App';
import SellerRegisterPage from './pages/seller/SellerRegisterPage';

import LoginPage from './pages/LoginPage';
import ForgotPassword from './pages/user/ForgotPassword';
import ResetPasswordPage from './pages/user/ResetPasswordPage';
import SwitchChannelPage from './pages/user/SwitchChannelPage';
import { NotFound } from './pages/NotFound';

import 'bootstrap/dist/css/bootstrap.css';
import 'antd/dist/antd.css';
import './assets/scss/paper-dashboard.scss';
import './assets/scss/app.scss';
import './assets/scss/transfer.scss';
import './assets/scss/payment.scss';
const rootpath = config.web.rootpath;

render((
  <Provider {...stores}>
    <BrowserRouter>
      <Switch>
        <Route exact path={`${rootpath}/login`} component={LoginPage} />
        <Route exact path={`${rootpath}/forgot-password`} component={ForgotPassword} />
        <Route exact path={`${rootpath}/reset-password`} component={ResetPasswordPage} />
        <Route exact path={`${rootpath}/switch-channel`} component={SwitchChannelPage} />
        <Route exact path={`/sellers/register`} component={SellerRegisterPage} />
        <Route path={`${rootpath}`} component={App} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  </Provider>
), document.getElementById('root'));
