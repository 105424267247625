import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ImageModal = ({ modal, toggle, imageFile, fileType, ...props }) => {
  const closeBtn = (
    <button className="modal-image-close" onClick={toggle}>
      <i className="far fa-times-circle" />
    </button>
  );

  const previewFile = () => {
    if (imageFile) {
      if (fileType === 'pdf') {
        return (
          <div>
            <object width="100%" height="400" data={imageFile} type="application/pdf" />
          </div>
        )
      }
      if (Array.isArray(imageFile)) {
        return (
          <div>
            {imageFile.map((url, i) => (<img key={i} src={url} />))}
          </div>
        )
      }
      return (
        <div>
          <img src={imageFile} />
        </div>
      );
    }

    return (
      <div className="body-inner">
        <p>ไม่พบไฟล์รูปภาพ</p>
      </div>
    );
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} contentClassName="modal-image-content" {...props}>
        <ModalHeader toggle={toggle} close={closeBtn} className="modal-image-header" />
        <ModalBody className="modal-image-body">
          {previewFile()}
        </ModalBody>
      </Modal>
    </>
  );
};

export default ImageModal;
