import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
// import classNames from 'classnames';
// import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { CheckBox } from '../../components/Input/CheckBox';
// import NexterInput from '../../components/Input/NexterInput';
// import NexterButton from '../../components/Button/NexterButton';
// import CustomInputDatePicker from '../../components/Input/DatePicker';
import PaymentSearch from './PaymentSearch';
import PaymentDetail from './PaymentDetail';
import PaymentTable from './PaymentTable';
import { Page } from '../../components/Page';
import Visibility from '../../components/Visibility/Visibility';
import PaymentHistoryLog from './PaymentHistoryLog'

class PaymentListPage extends Component {
  componentDidMount() {
    this.props.payment.clearPaymentDetail();
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      this.props.payment.getPaymentByUid(params.id);
    }
  }

  render() {
    const { location } = this.props;
    const { condition, loading, payment } = this.props.payment.getData();
    let { loggedinUser } = this.props.user.getData();
    const showSearchPanel = !!condition.channel_uid || !!['channel_admin', 'channel_user', 'as_channel_admin'].find(s => s === loggedinUser.type);
    const openPaymentDetail = !!payment.id;
    const query = queryString.parse(location.search);
    const isHistory = query.tab === 'history'
    return (
      <Page loading={loading}>
        <h5 className="text-uppercase no-print payment-title-header">
          <b>Payment</b>
        </h5>
        <Visibility permission_code="payment:transaction_view_list">
          {
            isHistory ? 
            <PaymentHistoryLog history={this.props.history}/> :
            <>
              <PaymentSearch showSearchPanel={showSearchPanel && !openPaymentDetail} />
              {showSearchPanel && !openPaymentDetail && <PaymentTable />}
              {showSearchPanel && openPaymentDetail && <PaymentDetail />}
            </>
          }
        </Visibility>
        <Visibility permission_code="payment:transaction_view_list" invisibility_msg>
          <h5>ท่านไม่มีสิทธิ์ดูรายการ Payment</h5>
        </Visibility>
      </Page>
    );
  }
}

export default inject('payment', 'user')(observer(PaymentListPage));
