import React from 'react';
import { Input, CustomInput, Label, FormGroup, FormFeedback, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import Select from 'react-select';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import '../../assets/scss/_variables.scss';

class NexterInput extends React.Component {
  restrictChars(event) {
    let acceptedKeycode = [];
    switch (this.props.allow) {
      case 'alphanum': {
        acceptedKeycode.push([48, 57]); // 0-9
        acceptedKeycode.push([65, 90]); // A-Z
        acceptedKeycode.push([97, 122]); // a-z
        acceptedKeycode.push([45, 45]); // -
        break;
      }
      case 'alphanumspace': {
        acceptedKeycode.push([48, 57]); // 0-9
        acceptedKeycode.push([65, 90]); // A-Z
        acceptedKeycode.push([97, 122]); // a-z
        acceptedKeycode.push([45, 45]); // -
        acceptedKeycode.push([32, 32]); // -
        break;
      }
      case 'alpha': {
        acceptedKeycode.push([65, 90]); // A-Z
        acceptedKeycode.push([97, 122]); // a-z
        break;
      }
      case 'numeric': {
        acceptedKeycode.push([48, 57]); // 0-9
        break;
      }
      case 'float': {
        acceptedKeycode.push([48, 57]); // 0-9
        acceptedKeycode.push([46, 46]); // .
        break;
      }
      case 'tel': {
        acceptedKeycode.push([48, 57]); // 0-9
        acceptedKeycode.push([45, 45]); // -
        break;
      }
      case 'payment_refid': {
        acceptedKeycode.push([48, 57]); // 0-9
        acceptedKeycode.push([65, 90]); // A-Z
        acceptedKeycode.push([97, 122]); // a-z
        acceptedKeycode.push([45, 45]); // -
        acceptedKeycode.push([47, 47]); // /
        acceptedKeycode.push([32, 32]); // space
        acceptedKeycode.push([95, 95]); // _
        break;
      }
    }
    let isValid = false;
    acceptedKeycode.forEach(range => {
      if (range[0] <= event.charCode && event.charCode <= range[1]) isValid = true;
    });
    if (!isValid) event.preventDefault();
  }

  checkRangeOfNumber(event) {
    let value = event.target.value;
    if (typeof this.props.min !== 'undefined' && value < this.props.min) {
      event.target.value = this.props.min;
    }
    if (typeof this.props.max !== 'undefined' && value > this.props.max) {
      event.target.value = this.props.max;
    }
  }

  onBlur(event) {
    this.checkRangeOfNumber(event);
    this.props.onBlur && this.props.onBlur(event);
  }

  onKeypress(event) {
    if (this.props.allow) this.restrictChars(event);
  }

  render() {
    const props = this.props;
    switch (this.props.type) {
      case 'select':
        return (
          <>
            {props.label ? (
              <label>
                {props.label} {props.require ? <i style={{ color: 'red' }}>*</i> : null}
              </label>
            ) : null}
            <Select
              className={classNames('nexter-select', props.className, {
                'is-invalid': props.invalid
              })}
              classNamePrefix="react-select"
              options={props.options}
              placeholder={props.placeholder}
              isDisabled={props.disabled}
              isClearable={props.isClearable}
              isMulti={props.isMulti}
              value={props.value}
              defaultValue={props.defaultValue}
              style={props.style}
              onChange={props.onChange}
              styles={{
                dropdownIndicator: (provided, state) => ({
                  ...provided,
                  transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
                }),
              }}
            />
          </>
        );
      case 'group':
        return (
          <InputGroup
            className={classNames('nexter-input-group', {
              selected: props.selected,
              'is-invalid': props.invalid,
            }, props.inputGroupClass)} disabled={props.disabled}>
            <Input
              id={props.id}
              className={classNames(props.className)}
              type={props.inputType}
              bsSize={props.size}
              invalid={props.invalid}
              defaultValue={props.defaultValue}
              disabled={props.disabled}
              readOnly={props.readOnly}
              name={props.name}
              placeholder={props.placeholder}
              value={props.value}
              onChange={props.onChange}
              onKeyPress={this.onKeypress.bind(this)}
              onBlur={this.onBlur.bind(this)}
              maxLength={props.maxLength}
              step={props.step}
              min={props.min}
              max={props.max}
              style={props.style}
            >
              {props.children}
            </Input>
            <InputGroupAddon
              addonType={props.addonType || "append"}
              className={classNames({ [`input-group-${props.addonType || "append"}-${props.size}`]: props.size })}
            >
              <InputGroupText>{props.addonText}</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        )
      default:
        return (
          <Input
            id={props.id}
            className={classNames('nexter-input', props.className)}
            type={props.type}
            bsSize={props.size}
            invalid={props.invalid}
            defaultValue={props.defaultValue}
            disabled={props.disabled}
            readOnly={props.readOnly}
            name={props.name}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            onKeyPress={this.onKeypress.bind(this)}
            onBlur={this.onBlur.bind(this)}
            maxLength={props.maxLength}
            step={props.step}
            min={props.min}
            max={props.max}
            style={props.style}
          >
            {props.children}
          </Input>
        );
    }
  }
}

NexterInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  allow: PropTypes.string,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

NexterInput.defaultProps = {
  id: null,
  className: null,
  type: 'text',
  value: null,
  name: null,
  size: 'md',
  placeholder: null,
  maxLength: null,
  allow: null,
  invalid: false,
  disabled: false,
  onClick: null,
  style: {},
};

export default NexterInput;
