import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { CardBody, Row, Col, FormGroup, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';

import DatePicker from 'react-datepicker';
import { Page } from '../../components/Page';
import { CheckBox } from '../../components/Input/CheckBox';
import CustomInputDatePicker from '../../components/Input/DatePicker';

import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';
import 'react-datepicker/dist/react-datepicker.css';
import SellerAllTable from './SellerAllTable';
import { DELETE_SELLER_STATUS } from './../../constants/sellerConstants';

class SellerListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      isSuperAdmin: false,
      isChannelAdmin: false,
      originalFilter: {},
    };
  }

  static defaultProps = {
    isChannelView: false,
  };

  componentDidMount() {
    this.checkUserType();
    this.tempOriginalFilter();
  }

  componentWillUnmount() {
    this.props.seller.filter = this.state.originalFilter;
  }

  tempOriginalFilter() {
    const { filter } = this.props.seller.getData();
    this.setState({ originalFilter: filter });
  }

  // Seller Code
  changeFilterSellerCode(event) {
    this.props.seller.changeFilterSellerCode(event.target.value);
  }

  // Channel UID
  changeFilterChannelID(value) {
    this.props.seller.changeFilterChannelID(value);
  }

  // Seller Company Name / Name
  changeFilterSellerName(event) {
    this.props.seller.changeFilterSellerName(event.target.value);
  }

  // Seller Email
  changeFilterEmail(event) {
    this.props.seller.changeFilterEmail(event.target.value);
  }

  // วันที่ Export ล่าสุด - Start Date
  changeExportFilterStartDate(date) {
    this.props.seller.changeExportFilterStartDate(date);
  }

  // วันที่ Export ล่าสุด - End Date
  changeExportFilterEndDate(date) {
    this.props.seller.changeExportFilterEndDate(date);
  }

  // Export ครั้งแรก
  toggleSellerExportSelection(selected) {
    const index = this.props.seller.filter.seller_selection_export_first.indexOf(selected);
    if (index < 0) {
      this.props.seller.filter.seller_selection_export_first.push(selected);
    } else {
      this.props.seller.filter.seller_selection_export_first.splice(index, 1);
    }
    this.props.seller.filter.seller_selection_export_first = [...this.props.seller.filter.seller_selection_export_first];
  }

  // สถานะบัญชี (multiple selection)
  toggleSellerAccountSelection(selected) {
    const index = this.props.seller.filter.seller_account_status_selection_export.indexOf(selected);
    if (index < 0) {
      this.props.seller.filter.seller_account_status_selection_export.push(selected);
    } else {
      this.props.seller.filter.seller_account_status_selection_export.splice(index, 1);
    }
    this.props.seller.filter.seller_account_status_selection_export = [...this.props.seller.filter.seller_account_status_selection_export];
  }

  // สถานะ KYC (multiple selection)
  toggleSellerKYCSelection(selected) {
    const index = this.props.seller.filter.seller_list_kyc_status_selection.indexOf(selected);
    if (index < 0) {
      this.props.seller.filter.seller_list_kyc_status_selection.push(selected);
    } else {
      this.props.seller.filter.seller_list_kyc_status_selection.splice(index, 1);
    }
    this.props.seller.filter.seller_list_kyc_status_selection = [...this.props.seller.filter.seller_list_kyc_status_selection];
  }

  // Seller Type (single selection)
  toggleSellerTypeSelection(selected) {
    const index = this.props.seller.filter.seller_type_selection.indexOf(selected);
    if (index < 0) {
      // if (this.props.seller.filter.seller_type_selection.length > 0) {
      //   this.props.seller.filter.seller_type_selection.pop();
      // }
      this.props.seller.filter.seller_type_selection.push(selected);
    } else {
      this.props.seller.filter.seller_type_selection.splice(index, 1);
    }
    this.props.seller.filter.seller_type_selection = [...this.props.seller.filter.seller_type_selection];
  }

  // Status (single selection)
  toggleSellerStatusSelection(selected) {
    const index = this.props.seller.filter.seller_status_selection.indexOf(selected);
    if (index < 0) {
      // if (this.props.seller.filter.seller_status_selection.length > 0) {
      //   this.props.seller.filter.seller_status_selection.pop();
      // }
      this.props.seller.filter.seller_status_selection.push(selected);
    } else {
      this.props.seller.filter.seller_status_selection.splice(index, 1);
    }
    this.props.seller.filter.seller_status_selection = [...this.props.seller.filter.seller_status_selection];
  }

  // สถานะ Delete Seller (multiple selection)
  toggleSellerDeleteSelection(selected) {
    const index = this.props.seller.filter.seller_delete_status_selection.indexOf(selected);
    if (index < 0) {
      this.props.seller.filter.seller_delete_status_selection.push(selected);
    } else {
      this.props.seller.filter.seller_delete_status_selection.splice(index, 1);
    }
    this.props.seller.filter.seller_delete_status_selection = [...this.props.seller.filter.seller_delete_status_selection];
  }

  // Other
  toggleSellerLegalEntity(val) {
    this.props.seller.changeFilterSellerLegalEntity(val.target.checked);
  }

  toggleChangeCommittee = () => {
    this.props.seller.changeFilterSellerCommittee();
  };

  searchSellerByFilter() {
    this.props.seller.pageSellerlist = 1;
    this.props.seller.searchSellerByFilter();
    this.tempOriginalFilter();
  }

  clearFilter = () => {
    this.props.seller.pageSellerlist = 1;
    this.props.seller.clearSellerListFilter();
  };

  onClear() {
    this.props.seller.pageSellerlist = 1;
    this.props.seller.clearExportFilter();
    this.props.seller.fetchChannelSellers();
    this.tempOriginalFilter();
  }

  modalToggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  checkUserType() {
    let { loggedinUser } = this.props.user.getData();
    switch (loggedinUser.type) {
      case 'super_admin':
      case 'ae_marketing':
      case 'biz_system':
      case 'manager':
        this.props.channel.fetchChannelAll();
        this.setState({ isSuperAdmin: true });
        break;
      case 'channel_admin':
      case 'channel_user':
      case 'as_channel_admin':
        this.changeFilterChannelID(loggedinUser.channel?.uid || '');
        this.setState({ isChannelAdmin: true });
        this.props.seller.filter.channel_id = { value: loggedinUser.channel?.uid || '' };
        break;
      default:
        break;
    }
  }

  /*
  changeSellerPage(page) {
    this.props.seller.changeSellerPage(page);
  }

  exportExcelSellerT2P() {
    this.props.seller.exportExcelSellerT2P();
  }

  exportExcelSellerCommittee() {
    this.props.seller.exportExcelSellerCommittee();
  }

  exportExcelSellerAccountT2P() {
    this.props.seller.exportExcelSellerAccountT2P();
  }
  */

  render() {
    const { filter } = this.props.seller.getData();
    const { channels } = this.props.channel.getData();
    const { isChannelView } = this.props;

    const channelOptions = channels.all.map(c => ({ value: c.uid, label: c.name }));

    return (
      <Page>
        <CardBody className="mt-3">
          <Row className="align-items-center">
            <Col xs={12}>
              <Label>
                <strong>พิมพ์ค้นหา</strong>
              </Label>
            </Col>
            <Col xs={isChannelView ? null : 3}>
              <FormGroup>
                <NexterInput placeholder="Seller Code" value={filter.seller_code} onChange={this.changeFilterSellerCode.bind(this)} />
              </FormGroup>
            </Col>
            <Col xs={isChannelView ? null : 3}>
              <FormGroup>
                <NexterInput
                  type="select"
                  className={'nexter-select-sm payment-channel-select z-index-6'}
                  options={channelOptions}
                  onChange={this.changeFilterChannelID.bind(this)}
                  value={channelOptions.find(co => co.value === filter.channel_id.value) ?? ''}
                  disabled={!this.state.isSuperAdmin || this.state.isChannelAdmin}
                  placeholder="Channel Name"
                />
              </FormGroup>
            </Col>
            <Col xs={isChannelView ? null : 3}>
              <FormGroup>
                <NexterInput placeholder="Company Name / Name" value={filter.seller_name} onChange={this.changeFilterSellerName.bind(this)} />
              </FormGroup>
            </Col>
            {!isChannelView && (
              <>
                <Col xs={3}>
                  <FormGroup>
                    <NexterInput placeholder="Email" value={filter.email} onChange={this.changeFilterEmail.bind(this)} />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <Label>
                    <strong>วันที่ Export ล่าสุด</strong>
                  </Label>
                </Col>
                <Col xs={6}>
                  <Label>
                    <strong>Export ครั้งแรก</strong>
                  </Label>
                </Col>
                <Col xs={3}>
                  <FormGroup className="mb-0">
                    <DatePicker
                      popperClassName="z-index-6"
                      customInput={<CustomInputDatePicker size="sm" />}
                      locale="th"
                      dateFormat="dd/MM/yyyy"
                      selected={filter.seller_export_start_date}
                      onChange={this.changeExportFilterStartDate.bind(this)}
                      selectsStart
                      startDate={filter.seller_export_start_date}
                      endDate={filter.seller_export_end_date}
                      // minDate={new Date()}
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <FormGroup className="mb-0">
                    <DatePicker
                      popperClassName="z-index-6"
                      customInput={<CustomInputDatePicker size="sm" />}
                      locale="th"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="End Date"
                      selected={filter.seller_export_end_date}
                      onChange={this.changeExportFilterEndDate.bind(this)}
                      selectsEnd
                      startDate={filter.seller_export_start_date}
                      endDate={filter.seller_export_end_date}
                      minDate={filter.seller_export_start_date}
                    />
                  </FormGroup>
                </Col>
                <Col xs={3}>
                  <div className="d-flex justify-content-between align-items-center">
                    <Row className="mx-n2">
                      <Col className="px-2" xs={'auto'}>
                        <CheckBox label="Yes" defaultClassName="m-0" checked={filter.seller_selection_export_first.includes('Yes')} onChange={this.toggleSellerExportSelection.bind(this, 'Yes')} />
                      </Col>
                      <Col className="px-2" xs={'auto'}>
                        <CheckBox label="No" defaultClassName="m-0" checked={filter.seller_selection_export_first.includes('No')} onChange={this.toggleSellerExportSelection.bind(this, 'No')} />
                      </Col>
                    </Row>
                    <NexterButton size="sm" color="info" outline onClick={this.onClear.bind(this)}>
                      Clear
                    </NexterButton>
                  </div>
                </Col>
              </>
            )}
            <Col xs={isChannelView ? 'auto' : 3}>
              <FormGroup className={isChannelView ? null : 'mb-0'}>
                <Row className="mx-n1 justify-content-between">
                  {isChannelView && (
                    <Col className="px-1">
                      <NexterButton size="sm" color="info" outline onClick={this.onClear.bind(this)}>
                        Clear
                      </NexterButton>
                    </Col>
                  )}
                  <Col xs={isChannelView ? null : 6} className="px-1">
                    <NexterButton className={isChannelView ? null : 'w-100'} size="sm" color="dark" icon="fa fa-search" onClick={this.searchSellerByFilter.bind(this)}>
                      Search
                    </NexterButton>
                  </Col>
                  <Col xs={isChannelView ? null : 6} className="px-1">
                    <NexterButton className={isChannelView ? null : 'w-100'} size="sm" color="dark" icon="fa fa-filter" onClick={() => this.modalToggle()}>
                      Filter
                    </NexterButton>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            {!isChannelView && (
              <>
                <Col xs={3}>
                  <Label>Start Date</Label>
                </Col>
                <Col xs={3}>
                  <Label>End Date</Label>
                </Col>
              </>
            )}
          </Row>
          {/*
              <Label>
                <Input type="checkbox" checked={filter.committee} onChange={this.toggleChangeCommittee} />
                แสดงเฉพาะผู้ขาย ที่เป็นนิติบุคคล และ มีนิติบุคคลเป็นคนที่ถือหุ้น
              </Label>
            */}
          {/*
            <Row>
              <Col>
                <hr />
                <FormGroup>
                  <Label className="w-100 text-dark">
                    <strong>สถานะบัญชี</strong>
                  </Label>
                  <CheckBox label="อนุมัติแล้ว" checked={filter.seller_account_status_selection_export.includes('approved')} onChange={this.toggleSellerAccountSelection.bind(this, 'approved')} />
                  <CheckBox label="ปฏิเสธ" checked={filter.seller_account_status_selection_export.includes('rejected')} onChange={this.toggleSellerAccountSelection.bind(this, 'rejected')} />
                  <CheckBox label="รอดำเนินการ" checked={filter.seller_account_status_selection_export.includes('pending')} onChange={this.toggleSellerAccountSelection.bind(this, 'pending')} />
                  <CheckBox label="ไม่พบข้อมูล" checked={filter.seller_account_status_selection_export.includes('created')} onChange={this.toggleSellerAccountSelection.bind(this, 'created')} />
                  <CheckBox label="ไม่พบข้อมูล" checked={filter.seller_account_status_selection_export.includes('created')} onChange={this.toggleSellerAccountSelection.bind(this, 'created')} />
                </FormGroup>
              </Col>
            </Row>
          */}
          {/*
            <Row>
              <Col>
                <h5 className="mt-2 mb-0">Sellers {this.props.seller.sellersTotal} ราย</h5>
              </Col>
            </Row>
            */}
          <hr className="hr-seller" />
          <SellerAllTable />
        </CardBody>

        <Modal isOpen={this.state.modal} toggle={this.modalToggle} onClosed={() => this.searchSellerByFilter()}>
          <ModalBody className="seller-modal-body">
            <p className="filter-title">Filters</p>
            <hr />
            <FormGroup>
              <Label className="w-100 text-dark">
                <strong>สถานะบัญชี</strong>
                {/*<!-- (multiple selection) -->*/}
              </Label>
              <FormGroup className="mt-2">
                <CheckBox label="Created" checked={filter.seller_account_status_selection_export.includes('created')} onChange={this.toggleSellerAccountSelection.bind(this, 'created')} />
                <CheckBox label="Approved" checked={filter.seller_account_status_selection_export.includes('approved')} onChange={this.toggleSellerAccountSelection.bind(this, 'approved')} />
                <CheckBox label="Rejected" checked={filter.seller_account_status_selection_export.includes('rejected')} onChange={this.toggleSellerAccountSelection.bind(this, 'rejected')} />
              </FormGroup>
              <FormGroup>
                <CheckBox label="Pending" checked={filter.seller_account_status_selection_export.includes('pending')} onChange={this.toggleSellerAccountSelection.bind(this, 'pending')} />
                <CheckBox label="Incomplete" checked={filter.seller_account_status_selection_export.includes('incomplete')} onChange={this.toggleSellerAccountSelection.bind(this, 'incomplete')} />
              </FormGroup>
            </FormGroup>
            <hr />
            <FormGroup>
              <Label className="w-100 text-dark">
                <strong>สถานะ KYC</strong>
                {/*<!-- (multiple selection) -->*/}
              </Label>
              <FormGroup className="mt-2">
                <CheckBox label="Created" checked={filter.seller_list_kyc_status_selection.includes('created')} onChange={this.toggleSellerKYCSelection.bind(this, 'created')} />
                <CheckBox label="Approved" checked={filter.seller_list_kyc_status_selection.includes('approved')} onChange={this.toggleSellerKYCSelection.bind(this, 'approved')} />
                <CheckBox label="Rejected" checked={filter.seller_list_kyc_status_selection.includes('rejected')} onChange={this.toggleSellerKYCSelection.bind(this, 'rejected')} />
              </FormGroup>
              <FormGroup>
                <CheckBox label="Pending" checked={filter.seller_list_kyc_status_selection.includes('pending')} onChange={this.toggleSellerKYCSelection.bind(this, 'pending')} />
                <CheckBox label="Incomplete" checked={filter.seller_list_kyc_status_selection.includes('incomplete')} onChange={this.toggleSellerKYCSelection.bind(this, 'incomplete')} />
              </FormGroup>
            </FormGroup>
            <hr />
            <FormGroup>
              <Label className="w-100 text-dark">
                <strong>Seller Type</strong>
                {/*<!-- (single selection) -->*/}
              </Label>
              <FormGroup className="mt-2">
                <CheckBox label="Seller" checked={filter.seller_type_selection.includes('partner')} onChange={this.toggleSellerTypeSelection.bind(this, 'partner')} />
                <CheckBox label="Channel" checked={filter.seller_type_selection.includes('channel')} onChange={this.toggleSellerTypeSelection.bind(this, 'channel')} />
              </FormGroup>
            </FormGroup>
            <hr />
            <FormGroup>
              <Label className="w-100 text-dark">
                <strong>Status</strong>
                {/*<!-- (single selection) -->*/}
              </Label>
              <FormGroup className="mt-2">
                <CheckBox label="Active" checked={filter.seller_status_selection.includes('active')} onChange={this.toggleSellerStatusSelection.bind(this, 'active')} />
                <CheckBox label="Inactive" checked={filter.seller_status_selection.includes('inactive')} onChange={this.toggleSellerStatusSelection.bind(this, 'inactive')} />
              </FormGroup>
            </FormGroup>
            <hr />
            <FormGroup>
              <Label className="w-100 text-dark">
                <strong>Delete status</strong>
                {/*<!-- (multiple selection) -->*/}
              </Label>
              <FormGroup className="mt-2">
                {Object.keys(DELETE_SELLER_STATUS).map(key => (
                  <CheckBox
                    key={key}
                    label={DELETE_SELLER_STATUS[key]}
                    checked={filter.seller_delete_status_selection.includes(key)}
                    onChange={this.toggleSellerDeleteSelection.bind(this, key)}
                  />
                ))}
              </FormGroup>
            </FormGroup>
            <hr />
            <FormGroup>
              <Label className="w-100 text-dark">
                <strong>Other</strong>
              </Label>
              <FormGroup className="mt-2">
                <CheckBox label="แสดงเฉพาะผู้ขาย ที่เป็นนิติบุคคล และ มีนิติบุคคลเป็นคนที่ถือหุ้น" checked={filter.committee} onChange={this.toggleChangeCommittee.bind(this)} />
              </FormGroup>
            </FormGroup>
            <hr />
          </ModalBody>
          <ModalFooter className="seller-modal-footer">
            <Row>
              <Col>
                <NexterButton onClick={() => this.clearFilter()} color="secondary" className="btn-sm text-capitalize">
                  Clear all filters
                </NexterButton>
              </Col>
              <Col>
                <NexterButton onClick={() => this.modalToggle()} className="btn-sm text-capitalize">
                  Done
                </NexterButton>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </Page>
    );
  }
}

export default inject('seller', 'channel', 'user')(observer(SellerListPage));
