import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from "prop-types";
import FilePicker from './FilePicker';
import Visibility from '../Visibility/Visibility';
import NexterButton from '../Button/NexterButton';

class LogoFile extends Component {
  render() {
    const {
      previewSrc,
      onRemove,
      onChange,
      disabled,
      height,
      width,
      inputProps,
      dropZoneProps,
      usePermission = true
    } = this.props;
    const VisibilityComp = ({children}) => (
      usePermission ? 
      <Visibility permission_code="channel:change_logo">{children}</Visibility> :
      <>{disabled ? null : children}</>
    )
    return (
      <div className="d-flex">
        <div className="logo-thumbnail">
          <div className="logo-thumbnail-container" style={{ width: parseInt(width) || 90, height: parseInt(height) || 90 }}>
            {previewSrc ? (
              <>
                <img src={previewSrc} width={width || 90} height={height || 90} />
                {!disabled && <i className="fas fa-times-circle remove-container" onClick={onRemove} />}
              </>
            ) : <span>No Logo</span>}
          </div>
        </div>
        <div className="ml-2">
          {/* <ImageUploader
            className="logo-upload-container"
            withIcon={false}
            buttonText=" Browse files"
            onChange={onChange}
            imgExtension={['.jpg', '.gif', '.png']}
            maxFileSize={1048576}
            fileSizeError=" file size is too big"
            buttonClassName="nexter-btn btn btn-dark btn-sm folder-before m-0 logo-upload-btn"
            fileContainerStyle={{ alignItems: 'start', padding: 0, margin: 0 }}
            labelStyles={{ display: 'none' }}
            singleImage
          /> */}
          <FilePicker
            onDrop={onChange}
            inputProps={{
              multiple: false,
              disabled,
              ...inputProps,
            }}
            dropZoneProps={{
              disabled,
              ...dropZoneProps,
              ...(inputProps.accept && { accept: inputProps.accept }),
            }}>
            <VisibilityComp>
              <div className="d-flex">
                <NexterButton color="dark" size="sm"
                  disabled={disabled}
                  className="folder-before my-0 btn-application-form">
                  <span> Browse files</span>
                </NexterButton>
              </div>
            </VisibilityComp>
          </FilePicker>
          <VisibilityComp>
            <span className="logo-upload-text">(Maximum upload file size: 1MB)</span>
          </VisibilityComp>
        </div>
      </div>
    )
  }
}

LogoFile.propTypes = {
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default LogoFile;