import _ from 'lodash';
import { Card, Col, Input, Label, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import ReactTable from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import classNames from 'classnames';

import 'react-table/react-table.css';

import { AbsorbTransfersTable } from '../ChannelTable';
import { Amount } from '../../../components/Field/Amount';
import { SummaryInterestTable } from '../SummaryInterestTable';
import { SummaryTable } from '../SummaryTable';
import TransferButton from './TransferButton';
import TransferManagementTab from './TransferManagementTab';
import TransferReport from './TransferReport';
import Visibility from '../../../components/Visibility/Visibility';

const SelectTable = selectTableHOC(ReactTable);

const defaultSelectInputComponent = props => {
  let body = (
    <div className="form-check">
      <Label className="form-check-label">
        <Input
          className="form-check-input"
          type="checkbox"
          checked={props.checked}
          onClick={e => {
            const { shiftKey } = e;
            e.stopPropagation();
            props.onClick(props.id, shiftKey, props.row);
          }}
          onChange={() => {}}
        />
        <span className="form-check-sign">
          <span className="check" />
        </span>
      </Label>
    </div>
  );

  if (props && props.row) {
    if (props.row.file_url || props.row.to_seller.sellers_account.status !== 'approved' || props.row.status === 'transferred') {
      return '';
    } else {
      return body;
    }
  } else {
    return body;
  }
};

class TransferManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsActive: 'approved',
      transferData: [],
    };
    this.onClickTab = this.onClickTab.bind(this);
    this.onClickDetails = this.onClickDetails.bind(this);
    this.onClickBackToListTable = this.onClickBackToListTable.bind(this);
    this.onTransfer = this.onTransfer.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    this.props.seller.fetchSellersByStatus('approved');
    if (this.props.filterSellerOpt) {
      this.props.filterSellerOpt(false);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { transfer_condition } = nextProps.transfer.getData();
    this.setState({ tabsActive: transfer_condition.status });
  }
  fetchData(transfer_status = null) {
    let { tabsActive } = this.state;
    if (!transfer_status) {
      transfer_status = tabsActive;
    }
    this.props.transfer.getTransfer(transfer_status);
  }

  toggleSelection = (key, shift, row) => {
    let selection = [...this.props.transfer.table.selection];
    const keyIndex = selection.indexOf(key);

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
    } else {
      // it does not exist so add it
      selection.push(key);
    }
    // update the store
    this.props.transfer.table.selection = selection;

    this.props.transfer.checkExportExcelFile();
  };

  toggleAll = () => {
    const selectAll = !this.props.transfer.table.selectAll;
    const selection = [];

    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.selectTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        if (item._original) {
          selection.push(item._original.report_no);
        }
      });
    }
    this.props.transfer.table.selectAll = selectAll;
    this.props.transfer.table.selection = selection;
    this.props.transfer.checkExportExcelFile();
  };

  isSelected = key => {
    return this.props.transfer.table.selection.includes(key);
  };

  changeChannel(optionSelected) {
    this.props.transfer.changeChannel(optionSelected);
  }

  changeStartDate(date) {
    this.props.transfer.changeStartDate(date);
  }

  changeEndDate(date) {
    this.props.transfer.changeEndDate(date);
  }
  onClickTab(status) {
    this.props.transfer.changeTransferStatus(status);
    this.onClickBackToListTable();
    this.setState({ tabsActive: status }, () => this.fetchData(status));
    // if (this.props.filterSellerOpt) {
    //   this.props.filterSellerOpt(!['exported', 'transferred'].includes(status));
    // }
  }
  onClickDetails(rowInfo) {
    const data = rowInfo.original;
    const { report_no } = data;
    this.props.transfer.changeTransferReport(report_no);
    this.props.transfer.getTransferReport(report_no);
  }
  onClickBackToListTable() {
    this.props.transfer.changeTransferReport(null);
  }

  async onTransfer(gateway) {
    if (gateway === 't2p') await this.props.transfer.transferScgWallet();
    if (gateway === '2c2p' || gateway === 'linepay') await this.props.transfer.transfer2c2p();
    if (gateway === 'opn') await this.props.transfer.transferOpn();
    this.fetchData();
  }

  render() {
    const { loading, transfers, table, transfer_condition, transfer, gateway } = this.props.transfer.getData();
    const { toggleSelection, toggleAll, isSelected } = this;

    const extraProps = {
      selectWidth: 38,
      selectAll: table.selectAll,
      isSelected,
      toggleAll,
      toggleSelection,
      selectType: table.selectType,
      SelectAllInputComponent: defaultSelectInputComponent,
      SelectInputComponent: defaultSelectInputComponent,
    };

    const columns = [
      {
        Header: 'รายงานการโอนเงิน',
        accessor: 'report_no',
        minWidth: 220,
        Cell: props => {
          return props.original?.type === 'adjust' ? (
            <div className="text-left">
              <i className="far fa-file-alt" /> {props.value}
            </div>
          ) : (
            <div className="text-left as-link" onClick={() => this.onClickDetails(props)}>
              <i className="far fa-file-alt" /> {props.value}
            </div>
          );
        },
        style: { whiteSpace: 'unset' },
      },
      {
        Header: () => <div className="text-center">ประเภทการโอนเงิน</div>,
        minWidth: 120,
        accessor: 'is_auto_transfer',
        Cell: ({ value }) => <div className="text-center">{value ? 'Auto Transfer' : 'Manual Transfer'}</div>,
      },
      {
        Header: () => {
          let label = `วันที่โอนเงิน`;
          if (gateway === `2c2p` || gateway === 'linepay') {
            switch (this.state.tabsActive) {
              case 'requested':
                label = `วันที่Channel โอนเงิน`;
                break;
              case 'created':
                label = `วันที่เตรียมโอน`;
                break;
            }
          }
          return <div className="text-center">{label}</div>;
        },
        accessor: ['transferred', 'requested'].includes(this.state.tabsActive) ? 'transfer_date' : 'created_at',
        Cell: ({ value, original: { created_at }, ...rest }) => {
          return (
            <>
              {value || created_at ? (
                <div className="text-center">
                  {moment(value || created_at).format('YYYY-MM-DD')}
                  <br />
                  {moment(value || created_at).format('HH:mm:ss')}
                </div>
              ) : (
                <div className="text-center">
                  <span className="data-is-null">-</span>
                </div>
              )}
            </>
          );
        },
        minWidth: 150,
      },
      ['2c2p', 'linepay', 't2p'].includes(gateway) &&
        ['transferred'].includes(this.state.tabsActive) && {
          Header: () => {
            return (
              <div className="text-center">
                <span>วันที่เงินเข้า</span>
                <br />
                <span>Bank ปลายทาง</span>
              </div>
            );
          },
          accessor: 'payout_date',
          Cell: ({ value }) => (
            <>
              {value ? (
                <div className="text-center">{moment(value).format('YYYY-MM-DD')}</div>
              ) : (
                <div className="text-center">
                  <span className="data-is-null">-</span>
                </div>
              )}
            </>
          ),
          minWidth: 150,
        },
      {
        Header: () => <div className="text-center">ผู้รับเงิน</div>,
        accessor: 'to_seller.name',
        Cell: props => <div className={classNames('text-center', { 'text-wrap': props.original?.to_seller?.deleted_at })}>{props.value}</div>,
      },
      {
        Header: () => <div className="text-right">ยอดโอน</div>,
        accessor: 'amount',
        Cell: props => (
          <div className="text-right">
            <Amount value={props.value} />
          </div>
        ),
      },
      {
        Header: () => <div className="text-right">ค่าโอน</div>,
        id: 'transferData',
        maxWidth: 60,
        accessor: 'gateway_transfer_fee',
        Cell: props => (
          <div className="text-right">
            <Amount value={0} />
          </div>
        ),
      },
      {
        Header: () => <div className="text-right">ยอดที่ได้รับ</div>,
        accessor: 'amount',
        Cell: props => (
          <div className="text-right">
            <Amount value={props.value} />
          </div>
        ),
      },
      {
        Header: () => <div className="text-center">แก้ไขล่าสุด</div>,
        accessor: 'updated_at',
        Cell: props => (
          <div className="text-center">
            {moment(props.value).format('YYYY-MM-DD')}
            <br />
            {moment(props.value).format('HH:mm:ss')}
          </div>
        ),
      },
      {
        Header: () => <div className="text-center">สถานะ</div>,
        accessor: 'status',
        Cell: props => {
          let status = props.value;
          if (this.state.tabsActive === 'transferred' && ['2c2p', 'linepay'].includes(props.original.gateway)) {
            status = 'transferred';
          }
          return (
            <div
              className="text-center"
              style={{
                color: status === 'transferred' ? '#4528a7' : status === 'failed' ? '#dc3545' : status === 'created' ? '#41B3C4' : status === 'voided' ? '#888888' : '#252422',
                fontWeight: 'bold',
              }}
            >
              {status || '-'}
            </div>
          );
        },
      },
      {
        Header: () => <div className="text-center">ไฟล์</div>,
        accessor: 'file_url',
        maxWidth: 35,
        Cell: props => (
          <div className="text-center">
            {props.value && props.value !== '-' ? (
              <a target="_blank" href={props.value}>
                <i className="nc-icon nc-paper text-success align-middle" />
              </a>
            ) : (
              '-'
            )}
          </div>
        ),
      },
      {
        Header: () => <div className="text-center">Reason</div>,
        accessor: 'gateway_response',
        Cell: props => {
          const safelyParseJSON = value => {
            let parsed = value;
            try {
              parsed = JSON.parse(value);
            } catch (e) {}
            return parsed;
          };
          const getObjResponse = obj => {
            return _.get(reasonObj, ['data', 'bankRemark']) || _.get(reasonObj, ['responseDesc']) || '-';
          };
          const reasonObj = props.value ? safelyParseJSON(props.value) : '-';
          const reason = typeof reasonObj === 'string' ? reasonObj : getObjResponse(reasonObj);
          return <div className="text-center">{reason}</div>;
        },
        show: this.state.tabsActive === 'failed' ? true : false,
        style: { whiteSpace: 'unset' },
        minWidth: 200,
        maxWidth: 250,
      },
    ].filter(c => c);

    return (
      <>
        <Card>
          <TransferManagementTab
            tabsActive={this.state.tabsActive}
            transfer_condition={transfer_condition}
            gateway={gateway}
            onClickTab={this.onClickTab}
            onClickBackToListTable={this.onClickBackToListTable}
          />

          <div className="p2 table-responsive table-scroll">
            <Visibility isVisibility={transfer_condition.report_no === null}>
              <TransferButton gateway={gateway} isActive={this.state.tabsActive === 'created' || this.state.tabsActive === 'failed'} onTransfer={this.onTransfer} />
              <hr className="mb-0" />
              <SelectTable
                keyField="report_no"
                data={transfers}
                columns={columns}
                ref={r => (this.selectTable = r)}
                className="table-responsive table-scroll custom-reacttable -highlight border-0"
                defaultPageSize={10}
                minRows={3}
                resizable={false}
                {...extraProps}
                style={{ overflow: 'scroll' }}
              />
            </Visibility>
            <Visibility isVisibility={transfer_condition.report_no !== null}>
              <TransferReport />
            </Visibility>
          </div>
        </Card>

        <Visibility isVisibility={transfer_condition.report_no && transfer.to_seller.type === 'partner'}>
          <div className="page-break">
            <Row>
              <Col md={6} className="print-col-4 print-border">
                <Card className="p2 card-transfer">
                  <SummaryInterestTable summary={transfer.summary} seller={transfer.to_seller} created_at={transfer.created_at} />
                </Card>
              </Col>
              <Col md={6} className="print-col-4 print-offset-4 print-border">
                <Card className="p2">
                  <SummaryTable total={transfer.summary.transfer_amount} fee={transfer.summary.net_transfer_fee} />
                </Card>
              </Col>
            </Row>
          </div>
        </Visibility>
        <Visibility isVisibility={transfer_condition.report_no && transfer.to_seller.type === 'channel'}>
          <div className="page-break">
            <Row style={{ marginTop: 10 }}>
              <Col md={6} className={classNames('print-col-4', !_.isEmpty(transfer.partner_transfers) ? 'print-border' : null)}>
                {transfer.partner_transfers.length ? (
                  <Card className="p2 card-transfer">
                    <AbsorbTransfersTable partnerTransfers={transfer.partner_transfers} />
                  </Card>
                ) : (
                  ''
                )}
              </Col>
              <Col md={6} className="print-col-4 print-offset-4 print-border">
                <Card className="p2 card-transfer">
                  <SummaryTable 
                    total={transfer.summary.transfer_amount} 
                    fee={transfer.summary.net_transfer_fee} 
                    channelFee={transfer.summary.channel_transfer_fee}
                    sellerFee={transfer.summary.partner_transfer_fee}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </Visibility>
      </>
    );
  }
}

export default inject('transfer', 'seller')(observer(TransferManagement));
