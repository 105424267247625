import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import numeral from 'numeral';
import { Card, CardHeader, CardBody, CardFooter, CustomInput, Row, Col, Label, FormGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import { config } from '../../../config';
import { Page } from '../../../components/Page';
import Visibility from '../../../components/Visibility/Visibility';
import AutoTransferHistoryLogTable from './AutoTransferHistoryLogTable';
import AutoTransferHistoryLogFilterTab from './AutoTransferHistoryLogFilterTab';
import { Pagination } from '../../../components/Pagination/Pagination';

const P8_ROLES = ['super_admin', 'biz_system', 'ae_marketing', 'manager'];

class AutoTransferHistoryLogPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.autoTransfer.getHistoryLogsAutoTransfer();
    this.props.channel.fetchChannelAll();
  }

  componentWillUnmount() {
    this.onClearFilter();
  }

  selectPage(page) {
    this.props.autoTransfer.selectHistolyLogPage(page);
  }

  onChangeFilter(type, value) {
    const { autoTransfer } = this.props;
    switch (type) {
      case 'channelId':
        autoTransfer.changeHistolyLogFilterChannel(value);
        break;
      case 'updatedStartDate':
        autoTransfer.changeHistolyLogFilterUpdatedStartDate(value);
        break;
      case 'updatedEndDate':
        autoTransfer.changeHistolyLogFilterUpdatedEndDate(value);
        break;
      default:
        break;
    }
  }

  onClearFilter() {
    this.props.autoTransfer.clearAllHistolyLogFilters();
  }

  onSubmitFilter() {
    this.selectPage(1);
  }

  render() {
    const { loading, historyLog, historyLogFilter: filter, historyLogPagination: pagination } = this.props.autoTransfer.getData();
    const { channels } = this.props.channel.getData();
    const { loggedinUser } = this.props.user.getData();
    const { page, size, total } = pagination;

    return (
      <Page loading={loading}>
        <HeaderTitle />
        <Visibility permission_code="auto_transfer:view">
          <Card className="historylog-wrapper p-2 text-base">
            <CardHeader>
              <AutoTransferHistoryLogFilterTab
                channels={channels}
                showChannels={P8_ROLES.includes(loggedinUser?.type)}
                filter={filter}
                onChange={this.onChangeFilter.bind(this)}
                onClear={this.onClearFilter.bind(this)}
                onSubmit={this.onSubmitFilter.bind(this)}
              />
            </CardHeader>
            <hr className="mx-3 border-secondary" />
            <CardBody>
              <AutoTransferHistoryLogTable logs={historyLog} />
            </CardBody>
            <hr className="mx-3 mt-0" />
            <CardFooter>
              <Row>
                <Col />
                <Col>
                  <Pagination className="pull-right" activePage={page} displayPageNumber={5} maximumPageNumber={Math.ceil(total / size)} onSelect={this.selectPage.bind(this)} />
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Visibility>
        <Visibility permission_code="auto_transfer:view" invisibility_msg>
          <h5>ท่านไม่มีสิทธิ์ดูรายการ History Log</h5>
        </Visibility>
      </Page>
    );
  }
}

const HeaderTitle = () => (
  <div className="container-fluid p-0">
    <Row>
      <Col className="label-header text-uppercase mb-4">Auto Transfer</Col>
    </Row>
    <Visibility permission_code="auto_transfer:view">
      <Row>
        <Col xs={12} md={6} className="mb-4">
          <Link to={`${config.web.rootpath}/auto-transfers`} className="title-goback">
            <i className="back-icon fas fa-chevron-left my-auto mr-4" />
            <span className="texthead-goback">History Log</span>
          </Link>
        </Col>
      </Row>
    </Visibility>
  </div>
);

export default inject('autoTransfer', 'channel', 'user')(observer(AutoTransferHistoryLogPage));
