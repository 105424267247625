import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Row, Col, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { config } from '../../config';
import { Page } from '../../components/Page';
import NexterButton from '../../components/Button/NexterButton';
import logo from '../../assets/img/Logo-SFinPay.png';

class SwitchChannelPage extends Component {
  state = {
    selectedChannelId: null,
  };

  timerRef = null;

  componentDidMount() {
    this.checkUserAccount();
    // re-check if page not rediect within 5 secs.
    this.timerRef = setTimeout(() => {
      this.checkUserAccount();
    }, 5000);
  }

  componentWillUnmount() {
    clearTimeout(this.timerRef);
  }

  checkUserAccount() {
    let accessToken = localStorage.getItem('token');
    if (accessToken) {
      this.props.user.getUserChannels({
        onSuccess: items => {
          if (items.length <= 1) {
            this.redirect();
          } else {
            clearTimeout(this.timerRef);
          }
        },
      });
    } else {
      this.redirect();
    }
  }

  redirect() {
    const { location } = this.props;
    const query = queryString.parse(location.search);
    if (query.redirect) {
      this.props.history.replace(`${config.web.rootpath}${query.redirect}`);
    } else {
      this.props.history.replace(config.web.rootpath);
    }
  }

  changeChannel(id) {
    this.setState({
      selectedChannelId: id,
    });
  }

  async onSubmit() {
    const id = this.state.selectedChannelId;
    await this.props.user.switchChannelAndRefreshToken(id);
    this.redirect();
  }

  render() {
    let { loading, user_channels: userChannels = [] } = this.props.user.getData();

    if (loading && userChannels.length <= 1) {
      return <Page loading className="d-flex min-vh-100 align-items-center" />;
    }

    return (
      <Page loading={loading} className="switch-channel-page d-flex min-vh-100 align-items-center">
        <Container className="py-3">
          <Row className="justify-content-center">
            <Col className="col-12 col-md-8 col-lg-5 text-center">
              <Card className="card-shadow0 m-0 p-4">
                <div className="mb-5">
                  <Link to={config.web.rootpath}>
                    <img src={logo} width="175px" />
                  </Link>
                </div>
                {userChannels.length > 0 && <h5 className="text-dark bold">เลือก Channel ที่ต้องการดูข้อมูล</h5>}
                <div className="channel-group my-3">
                  {userChannels.map(item => (
                    <div key={item.channel_id} className={`item ${this.state.selectedChannelId == item.channel_id ? 'selected' : ''}`} onClick={() => this.changeChannel(item.channel_id)}>
                      <div className="name text-sm">{item.channel?.name}</div>
                      <div className="check">
                        <i className="fa fa-check" />
                      </div>
                    </div>
                  ))}
                </div>
                <NexterButton className="mt-3 w-100" size="m" onClick={this.onSubmit.bind(this)} block disabled={!this.state.selectedChannelId && userChannels.length > 0}>
                  ดูข้อมูล
                </NexterButton>
              </Card>
            </Col>
          </Row>
        </Container>
      </Page>
    );
  }
}

export default inject('user')(observer(SwitchChannelPage));
