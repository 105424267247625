import React, { Component, Fragment } from 'react';
import { PaymentReport } from '../../../report/PaymentReport'
import { inject, observer } from 'mobx-react';
import { Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { Amount } from '../../../../components/Field/Amount';
import NexterButton from '../../../../components/Button/NexterButton';
import moment from 'moment';
import { Table } from 'reactstrap';
export class SellerReceipt extends Component {
	render() {
		const { payment } = this.props.payment.getData();
		let totalNet = 0;
		let totalVat = 0;
		let totalDiscount = 0;
		let ttotal = 0;
		let tsp = 0;
		let tsb = 0;
		let tsv = 0;
		let tssv = 0;
		let rows = payment.items.map(item => {
			let net = item.subtotal - item.discount;
			let vat = ((item.subtotal - item.discount) * 100) / 107;
			totalNet += net;
			totalVat += vat;
			totalDiscount += item.discount;
			
			let servicePerc = item.gateway_charge;
			let serviceBath = net * servicePerc / 100;
			let serviceVAT = item.vat;
			let serviceSumVAT = serviceVAT + serviceBath;
			tsp += servicePerc;
			tsb += serviceBath;
			tsv += serviceVAT;
			tssv += serviceSumVAT;
			ttotal += item.subtotal;

			return (
				<tr key={`item-${item.id}`}>
					<td>{item.type || '-'}</td>
					<td className="text-right"><Amount value={net} /></td>
					<td className="text-right">{item.service_charge}</td>
					<td className="text-right"><Amount value={item.gateway_charge} /></td>
					<td className="text-right">{item.status}</td>
					<td className="text-right">{moment(item.updated_at).format('YYYY-MM-DD HH:mm:ss')}</td>
					<td className="text-right">{item.updated_by}</td>
				</tr>
			);
		});
		return (
			<Fragment>
				<Row>
					<Col>
						<h5 className="title">รายละเอียดค่าบริการตามรายสินค้า</h5>
					</Col>
					<Col>
						<div style={{ padding: '0 0 1rem 0' }}>
							<span className="float-right text-seller" style={{ padding: '0 0 1rem 0' }}><b>ผู้ขาย/ผู้รับเงิน :</b> {payment.seller?.name ?? '-'}</span>
						</div>
					</Col>
				</Row>
				<hr />
				<Table>
					<thead>
						<tr>
							<th width="100" className="border-0 text-left">ประเภท</th>
							<th width="120" className="border-0 text-right">ยอดชำระ</th>
							<th width="100" className="border-0 text-right">ค่าบริการ BU(%)</th>
							<th width="100" className="border-0 text-center">ค่าบริการ PG</th>
							<th width="100" className="border-0 text-center">สถานะ</th>
							<th width="100" className="border-0 text-center">แก้ไขล่าสุด</th>
							<th width="100" className="border-0 text-center">แก้ไขโดย</th>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</Table>
			</Fragment>
		);
	}
}
export default inject('transfer', 'payment')(observer(SellerReceipt));