import React, { Component } from 'react';
import { Row, Col, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, CustomInput, FormFeedback } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import CustomInputDatePicker from '../../../components/Input/DatePicker';
import { th } from 'date-fns/locale';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import NexterButton from '../../../components/Button/NexterButton';
import NexterInput from '../../../components/Input/NexterInput';
import * as _ from 'lodash';
import { validate } from 'uuid';
import { Loader } from '../../../components/Loader';
registerLocale('th', th);

class PromotionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promotion_name: '',
      promotion_desc: '',
      loading: false
    };
  }

  setLoading = (loading = false) => {
    this.setState({ loading })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data.promotion_desc !== this.state.promotion_desc || nextProps.data.promotion_name !== this.state.promotion_name) {
      this.setState({ promotion_desc: nextProps.data.promotion_desc, promotion_name: nextProps.data.promotion_name });
    }
  }

  isStatusCanSubmit = () => {
    const { data } = this.props;
    const { start_date, end_date, is_active } = data;
    if(start_date && end_date && is_active === true)
      return moment().isBetween(moment(start_date).startOf('day'), moment(end_date).endOf('day'))
    else return true
  }

  render() {
    /* Start of Promotion Create Modal */
    const { data, option } = this.props;
    const { bank, channel_select, end_date, is_active, payment_method, promotion_desc, promotion_name, start_date } = data;
    const disabled =
    [promotion_name, promotion_desc, start_date, end_date].some(v => v === '') ||
    [channel_select, payment_method].some(v => v.length === 0) ||
    !this.props.thumbnailUrl ||
    !this.props.fileUrl || !this.isStatusCanSubmit()
    
    let startDate = this.props.currentDate !== '' ? this.props.currentDate : new Date();
    if (data.start_date > startDate) {
      startDate = data.start_date;
    }

    return (
      <Modal modalClassName="promotion-modal" isOpen={this.props.isOpen} toggle={this.state.loading ? null : this.props.toggle}>
        <ModalBody>
          <div className="CREATE"> {this.props.title}</div>
          <Form>
            <FormGroup>
              <Label>
                ชื่อโปรโมชั่น <span style={{ color: 'red' }}>*</span>
              </Label>
              <Input
                onChange={e => this.setState({ promotion_name: e.target.value })}
                onBlur={this.props.handleChange}
                value={this.state.promotion_name}
                name="promotion_name"
                invalid={this.props.validate.name}
                maxLength={200}
              />
              <FormFeedback>Required!</FormFeedback>
              <div className="text-right">{this.state.promotion_name ? this.state.promotion_name.length : 0}/200</div>
            </FormGroup>
            <FormGroup>
              <Label>
                รายละเอียดโปรโมชั่นบัตรเครดิต <span style={{ fontWeight: 'normal' }}>(Optional)</span>
              </Label>
              <Select
                isClearable
                options={option.bank}
                className={this.props.validate.channel ? 'promotion-select-error is-invalid' : 'promotion-select'}
                onChange={this.props.handleBank}
                value={data.bank}
                name="bank"
                placeholder={'เลือกบัตรเครดิต...'}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                รายละเอียดโปรโมชั่น <span style={{ color: 'red' }}>*</span>
              </Label>
              <Input
                onChange={e => this.setState({ promotion_desc: e.target.value })}
                onBlur={this.props.handleChange}
                value={this.state.promotion_desc}
                name="promotion_desc"
                rows={4}
                maxLength={1000}
                type="textarea"
              />
              <FormFeedback>Required!</FormFeedback>
              {/** Description Character Counter */}
              <div className="text-right">{this.state.promotion_desc ? this.state.promotion_desc.length : 0}/1000</div>
            </FormGroup>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Start Date <span style={{ color: 'red' }}>*</span>
                  </Label>
                  <div className={!this.props.validate.start_date ? 'modal-date-picker' : 'modal-date-picker is-invalid'}>
                    <DatePicker
                      customInput={
                        <CustomInputDatePicker size="sm">
                          <NexterInput placeholder="dd/mm/yyyy" readOnly size="sm" value={data.start_date !== '' ? moment(data.start_date).format('DD/MM/YYYY') : ''} />
                        </CustomInputDatePicker>
                      }
                      selected={data.start_date}
                      value={data.start_date}
                      onChange={this.props.handleStartDate}
                      name="start_date"
                      locale="th"
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date(new Date().setDate(new Date().getDate() - 7))}
                      maxDate={data.end_date}
                    />
                  </div>
                  <FormFeedback>Required!</FormFeedback>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>
                    End Date <span style={{ color: 'red' }}>*</span>
                  </Label>
                  <div className={!this.props.validate.end_date ? 'modal-date-picker' : 'modal-date-picker is-invalid'}>
                    <DatePicker
                      customInput={
                        <CustomInputDatePicker size="sm">
                          <NexterInput placeholder="dd/mm/yyyy" readOnly size="sm" value={data.end_date !== '' ? moment(data.end_date).format('DD/MM/YYYY') : ''} />
                        </CustomInputDatePicker>
                      }
                      selected={data.end_date}
                      value={data.end_date}
                      onChange={this.props.handleEndDate}
                      name="end_date"
                      locale="th"
                      dateFormat="dd/MM/yyyy"
                      minDate={startDate}
                    />
                  </div>
                  <FormFeedback>Required!</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Label>
              Upload ภาพโปรโมชั่น <span style={{ color: 'red' }}>*</span>
            </Label>
            <FormGroup className="pm-upload">
              <Row>
                <Col>
                  <div className="position-relative" style={{ minHeight: '32px', marginBottom: '8px' }}>
                    <div
                      className={this.props.uploadArea ? 'upload-input drop-input' : 'upload-input'}
                      onDrop={this.props.onDropThumbnail}
                      onDragOver={evt => evt.preventDefault()}
                      onDragEnter={evt => evt.preventDefault()}
                      onDragLeave={evt => evt.preventDefault()}
                    >
                      <Input type="file" className="d-none" id="upload_thumb" accept=".pdf, .jpg, .jpeg, .png" onChange={this.props.onChangeThumbnail} />
                      <label className="btn button-upload" htmlFor="upload_thumb">
                        <div className="d-flex align-items-center">
                          Upload&nbsp;&nbsp;
                          <FolderOpenIcon style={{ fontSize: '1.25rem' }} />
                        </div>
                      </label>
                    </div>
                    <div className="upload-text">
                      <p className="mb-n1">
                        {`ภาพโปรโมชั่น `}
                        <span style={{ textDecoration: 'underline' }}>ตัวอย่าง</span>
                        {` (รองรับไฟล์ pdf, jpg, png)`}
                      </p>
                      <span style={{ color: 'red', fontSize: '10px' }}>โปรดใช้รูปภาพสี่เหลี่ยม 1:1</span>
                    </div>
                  </div>
                </Col>
              </Row>
              {this.props.thumbnailUrl ? (
                <div className="prev-img">
                  <button type="button" className="close-preview" aria-label="Close" onClick={() => this.props.removeThumbnail()}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                  {this.props.thumbnailExt === 'pdf' ? (
                    <embed src={this.props.thumbnailUrl} type="application/pdf" className="img-promotion" />
                  ) : (
                    <img src={this.props.thumbnailUrl} alt="preview" className="img-promotion" />
                  )}
                </div>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup className="pm-upload">
              <Row>
                <Col>
                  <div className="position-relative" style={{ minHeight: '32px', marginBottom: '8px' }}>
                    <div
                      className={this.props.uploadArea ? 'upload-input drop-input' : 'upload-input'}
                      onDrop={this.props.onDropFile}
                      onDragOver={evt => evt.preventDefault()}
                      onDragEnter={evt => evt.preventDefault()}
                      onDragLeave={evt => evt.preventDefault()}
                    >
                      <Input type="file" className="d-none" id="upload" accept=".pdf, .jpg, .jpeg, .png" onChange={this.props.onChangeFile} />
                      <label className="btn button-upload" htmlFor="upload">
                        <div className="d-flex align-items-center">
                          Upload&nbsp;&nbsp;
                          <FolderOpenIcon style={{ fontSize: '1.25rem' }} />
                        </div>
                      </label>
                    </div>
                    <div className="upload-text">
                      <p className="mb-n1">
                        {`ภาพโปรโมชั่น `}
                        <span style={{ textDecoration: 'underline' }}>แบบเต็ม</span>
                        {` (รองรับไฟล์ pdf, jpg, png)`}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              {this.props.fileUrl ? (
                <div className="prev-img">
                  <button type="button" className="close-preview" aria-label="Close" onClick={() => this.props.removeImage()}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                  {this.props.fileExt === 'pdf' ? (
                    <embed src={this.props.fileUrl} type="application/pdf" className="img-promotion" />
                  ) : (
                    <img src={this.props.fileUrl} alt="preview" className="img-promotion" />
                  )}
                </div>
              ) : (
                ''
              )}
            </FormGroup>
            <FormGroup>
              <Label>
                เลือก Channel <span style={{ color: 'red' }}>*</span>
              </Label>
              <Select
                options={option.channel}
                className={this.props.validate.channel ? 'promotion-select-error is-invalid' : 'promotion-select-channel'}
                onChange={this.props.handleSelectChannel}
                value={data.channel_select}
                isMulti={true}
                name="channel"
                placeholder={'เลือก Channel...'}
              />
              <FormFeedback>Required!</FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label>
                Payment Method <span style={{ color: 'red' }}>* </span>
                (เลือกได้มากกว่า 1)
              </Label>
              <Row>
                {this.props.paymentMethods.map(item => (
                  <Col xs={6} key={item.code}>
                    <CustomInput checked={item.checked} onChange={this.props.handleMethod} value={item.code} id={item.id} type="checkbox" label={item.value} invalid={this.props.methodValidate} />
                  </Col>
                ))}
              </Row>
            </FormGroup>
            <FormGroup>
              <Label>
                เลือก Status
              </Label>
              <div className="status-btn">
                <div className="btn-group" role="group" aria-label="Promotion Status">
                  <NexterButton
                    className={`btn-sm btn-active ${data.is_active == true ? 'choose-active' : data.is_active == false || data.is_active === null ? 'choose-inactive' : ''}`}
                    name="is_active"
                    id="active"
                    onClick={() => this.props.handleChange({ target: { name: 'is_active', value: true } })}
                    active={data.is_active == true}
                    outline
                    color="success"
                  >
                    <div className="d-inline-block">
                      <i className="far fa-check-circle"></i> ACTIVE
                    </div>
                  </NexterButton>
                  <NexterButton
                    className={`btn-sm btn-inactive ${data.is_active == true || data.is_active === null ? 'choose-active' : data.is_active == false ? 'choose-inactive' : ''}`}
                    name="is_active"
                    id="inactive"
                    onClick={() => this.props.handleChange({ target: { name: 'is_active', value: false } })}
                    active={data.is_active === false}
                    outline
                  >
                    <div className="d-inline-block">
                      <i className="far fa-times-circle"></i> INACTIVE
                    </div>
                  </NexterButton>
                </div>
              </div>
              {!this.isStatusCanSubmit() && <span className="invalid-feedback d-block">ไม่สามารถกำหนดเป็น Active ได้เนื่องจากไม่ได้อยู่ในช่วง Start date และ End date</span>}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <NexterButton styles={{ backGround: '#e20a1a' }} className="promotion-modal-btn btn-small" onClick={this.props.onSummitNewPromotion} disabled={disabled}>
            Save
          </NexterButton>
        </ModalFooter>
        <Loader loading={this.state.loading}/>
      </Modal>
    );
  }
  /* End of Promotion Create Modal */
}

export default inject('system', 'channel')(observer(PromotionModal));
