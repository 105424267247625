import BaseStore from './BaseStore';
import { config } from '../config';
import { http } from '../utils/http';

export const CONST_FILTER_LIST = {
  CHANNEL: 'channel_uids',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  NO_DATE: 'no_date',
  PAYMENT_GATEWAY: 'gateways',
};

export const ReconcileTab = {
  MONEY_IN: 'money-in',
  MONEY_OUT: 'money-out',
  AVAILABLE_BALANCE: 'available-balance',
};

const INITIAL_DATA = {
  channel: null,
  loading: false,
  filter: {
    list: {
      [CONST_FILTER_LIST.CHANNEL]: [],
      [CONST_FILTER_LIST.START_DATE]: '',
      [CONST_FILTER_LIST.END_DATE]: '',
      [CONST_FILTER_LIST.NO_DATE]: false,
      [CONST_FILTER_LIST.PAYMENT_GATEWAY]: ['bbl', '2c2p', 't2p', '123', 'opn'],
    },
    tran: {
      ref_id: '',
      diff: false,
      seller_ids: [],
      card_type: [],
      status: [],
    },
  },
  list: {
    items: [],
  },
  tran: {
    items: [],
    size: 20,
    page: 1,
    total: 1,
  },
  tab: ReconcileTab.MONEY_IN,
  channel_method: [],
};

export class ReconcileStore extends BaseStore {
  constructor() {
    super();
    this.observable(INITIAL_DATA);
  }
  viewChannelTransaction(val) {
    this.channel = val;
  }
  filterList(field, val) {
    this.filter.list[field] = val;
  }
  filterTran(field, val) {
    this.filter.tran[field] = val;
  }
  clearFilterList() {
    this.filter.list = INITIAL_DATA.filter.list;
  }
  clearFilterTran() {
    this.filter.tran = INITIAL_DATA.filter.tran;
  }
  changeTab(tab) {
    this.tab = tab;
  }
  async getList(isSuperAdmin = false) {
    try {
      this.loading = true;

      let url =
        this.tab === ReconcileTab.MONEY_IN
          ? `${config.npay.apiUrl}/payment/reconcile/condition`
          : this.tab === ReconcileTab.MONEY_OUT
          ? `${config.npay.apiUrl}/payment/reconcile-out/condition`
          : `${config.npay.apiUrl}/payment/reconcile-available-balance/condition`;
      const body = {
        ...this.filter.list,
        [CONST_FILTER_LIST.PAYMENT_GATEWAY]: this.filter.list[CONST_FILTER_LIST.PAYMENT_GATEWAY].filter(gateway => (isSuperAdmin ? gateway : gateway !== 'bbl')),
      };
      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        this.list.items = body;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }
  async getTran(isSuperAdmin = false) {
    try {
      this.loading = true;
      let url =
        this.tab === ReconcileTab.MONEY_IN
          ? `${config.npay.apiUrl}/payment/reconcile/transaction?page=${this.tran.page}&size=${this.tran.size}`
          : `${config.npay.apiUrl}/payment/reconcile-out/transaction?page=${this.tran.page}&size=${this.tran.size}`;
      let response = await http.post(url, {
        body: {
          ...this.filter.list,
          ...this.filter.tran,
          channel_uids: [this.channel.uid].filter(uid => uid),
          [CONST_FILTER_LIST.PAYMENT_GATEWAY]: this.filter.list[CONST_FILTER_LIST.PAYMENT_GATEWAY].filter(gateway => (isSuperAdmin ? gateway : gateway !== 'bbl')),
        },
      });
      if (response.status === 200) {
        let body = await response.json();
        this.tran.items = body.data;
        this.tran.total = body.total;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }
  async exportFile(diff = false, isSuperAdmin = false, source) {
    try {
      let newTab = window.open();
      this.loading = true;
      let url = `${config.npay.apiUrl}/excel/reconcile`;
      let payments = await http.post(url, {
        body: {
          ...this.filter.list,
          diff,
          [CONST_FILTER_LIST.PAYMENT_GATEWAY]: this.filter.list[CONST_FILTER_LIST.PAYMENT_GATEWAY].filter(gateway => (isSuperAdmin ? gateway : gateway !== 'bbl')),
          source: `${source} (All channel transactions)`,
        },
      });
      if (payments.status === 200) {
        let body = await payments.json();
        if (newTab) newTab.location.href = body.data.url;
        else window.open(body.data.url);
      }
    } catch (err) {
      console.error(err);
      swal.error('Error', 'Export ข้อมูลไม่สำเร็จ');
    } finally {
      this.loading = false;
    }
  }
  async exportFileTransaction(isSuperAdmin = false, source = '') {
    try {
      this.loading = true;
      let url = this.tab === ReconcileTab.MONEY_IN ? `${config.npay.apiUrl}/excel/reconcile-transaction` : `${config.npay.apiUrl}/excel/reconcile-out-transaction`;
      let payments = await http.post(url, {
        body: {
          ...this.filter.list,
          ...this.filter.tran,
          channel_uids: [this.channel.uid].filter(uid => uid),
          [CONST_FILTER_LIST.PAYMENT_GATEWAY]: this.filter.list[CONST_FILTER_LIST.PAYMENT_GATEWAY].filter(gateway => (isSuperAdmin ? gateway : gateway !== 'bbl')),
          source: `${source} (${this.channel.uid ? 'Channel transaction detail' : 'All channel transactions detail'} - ${this.tab === ReconcileTab.MONEY_IN ? 'Money-in' : 'Money-out'})`,
        },
      });
      if (payments.status === 200) {
        let body = await payments.json();
        window.open(body.data.url);
      }
    } catch (err) {
      console.error(err);
      swal.error('Error', 'Export ข้อมูลไม่สำเร็จ');
    } finally {
      this.loading = false;
    }
  }
  async getChannelMethod(isSuperAdmin = false) {
    try {
      let url = `${config.npay.apiUrl}/payment/reconcile/get-channel-method`;
      let response = await http.post(url, {
        body: {
          ...this.filter.list,
          channel_uids: [this.channel.uid].filter(uid => uid),
          [CONST_FILTER_LIST.PAYMENT_GATEWAY]: this.filter.list[CONST_FILTER_LIST.PAYMENT_GATEWAY].filter(gateway => (isSuperAdmin ? gateway : gateway !== 'bbl')),
        },
      });
      if (response.status === 200) {
        let body = await response.json();
        this.channel_method = body.data;
      }
    } catch (err) {
      console.error(err);
    }
  }
}
