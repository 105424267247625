import React, { useState } from 'react'
import { Button, ButtonGroup } from 'reactstrap';
export default function SelectInterval(props) {
	const [interval, setInterval] = useState('month')
	const selectInterval = (val) => {
		if (props.selectInterval) {
			props.selectInterval(val)
		}
		setInterval(val)
	}
	return (
		<ButtonGroup className="no-print"  >
			<Button className={`dashboard-btn ${interval === 'month' ? 'clicked' : ''}`} size="sm" onClick={() => selectInterval('month')} >Month</Button>
			<Button className={`dashboard-btn ${interval === 'quarter' ? 'clicked' : ''}`} size="sm" onClick={() => selectInterval('quarter')} >Quarter</Button>
			<Button className={`dashboard-btn ${interval === 'year' ? 'clicked' : ''}`} size="sm" onClick={() => selectInterval('year')}>Year</Button>
		</ButtonGroup>
	)
}
