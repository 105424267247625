import moment from 'moment';
import queryString from 'query-string';
import _ from 'lodash';
import numeral from 'numeral';
import swal from 'sweetalert2';
import { config } from '../config';
import { http } from '../utils/http';
import BaseStore from './BaseStore';
import { validation } from '../utils/validation';

export class AutoTransferStore extends BaseStore {
  constructor() {
    super();
    this.observable(this.getInitialData());
  }

  getInitialData() {
    return {
      loading: false,
      autoTransfers: [],
      pagination: {
        page: 1,
        totalPage: 1,
        size: 10,
      },
      filter: {
        channelUid: null,
        updatedBy: '',
        updatedStartDate: null,
        updatedEndDate: null,
        gateways: [],
        scheduleTypes: [],
        statuses: [],
      },
      autoTransfer: {
        channel_id: null,
        channel_uid: null,
        channel_name: null,
        gateway: null,
        min_amount: null,
        global_min_amount: null,
        schedule_type: null,
        schedule_day: null,
        status: 'inactive',
        updated_at: null,
        updated_by: null,
      },
      autoTransferDetailForm: {
        status: 'inactive',
        min_amount: null,
        schedule_type: null,
        schedule_day: null,
      },
      autoTransferFormValidation: {
        min_amount: null,
        schedule_type: null,
        schedule_day: null,
      },
      historyLog: [],
      historyLogPagination: {
        page: 1,
        total: 1,
        size: 10,
      },
      historyLogFilter: {
        channelId: null,
        updatedStartDate: null,
        updatedEndDate: null,
      },
    };
  }

  async getAutoTransfers() {
    try {
      const { filter, pagination } = this.getData();
      this.loading = true;

      let query = {
        page: pagination.page,
        size: pagination.size,
        channel_uid: filter.channelUid,
        updated_by: filter.updatedBy,
        updated_start_date: filter.updatedStartDate ? moment(filter.updatedStartDate).startOf('day').valueOf() : null,
        updated_end_date: filter.updatedEndDate ? moment(filter.updatedEndDate).endOf('day').add(-10, 'seconds').valueOf() : null,
        gateways: filter.gateways.join(','),
        schedule_types: filter.scheduleTypes.join(','),
        statuses: filter.statuses.join(','),
      };
      query = _.omitBy(query, v => !v);

      let url = queryString.stringifyUrl({
        url: `${config.npay.apiUrl}/transfers/auto/list`,
        query,
      });
      let res = await http.get(url);
      if (res.status === 200) {
        let body = await res.json();
        this.autoTransfers = body.data;
        this.pagination.totalPage = body.total_page;
      } else {
        let body = await res.json();
        throw new Error(body?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async getAutoTransfer(channelUid, gateway) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/transfers/auto/channel_uid/${channelUid}/gateway/${gateway}`;
      let res = await http.get(url);
      if (res.status === 200) {
        let body = await res.json();
        this.autoTransfer = body;
        this.autoTransferDetailForm = _.pick(body, ['status', 'min_amount', 'schedule_type', 'schedule_day']);
      } else {
        let body = await res.json();
        throw new Error(body?.message);
      }
    } catch (err) {
      console.error(err);
      swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: err?.message,
      });
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async updateAutoTransfer(channelUid, gateway) {
    try {
      const { autoTransferDetailForm: formData } = this.getData();
      this.loading = true;
      let url = `${config.npay.apiUrl}/transfers/auto/channel_uid/${channelUid}/gateway/${gateway}`;
      let res = await http.put(url, {
        body: {
          schedule_type: formData.schedule_type,
          schedule_day: formData.schedule_day,
          min_amount: formData.min_amount,
          status: formData.status,
        },
      });
      if (res.status === 200) {
        swal
          .fire({
            customClass: 'nexter-alert',
            type: 'success',
            text: 'ข้อมูล Auto Transfer ถูกอัปเดตแล้ว',
            confirmButtonText: 'รับทราบ',
          })
          .then(async () => {
            window.location.href = `${config.npay.cmsUrl}/auto-transfers`;
          });
      } else {
        let body = await res.json();
        throw new Error(body?.message);
      }
    } catch (err) {
      swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: 'เกิดข้อผิดพลาด!',
        text: err?.message,
        confirmButtonText: 'รับทราบ',
      });
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async getHistoryLogsAutoTransfer() {
    try {
      const { historyLogPagination: pagination, historyLogFilter: filter } = this.getData();
      this.loading = true;

      let url = `${config.npay.apiUrl}/historylogs`;
      let body = {
        parent: 'channel',
        parent_id: filter.channelId,
        entity: ['auto_transfers'],
        page: pagination.page,
        size: pagination.size,
        start_date: filter.updatedStartDate ? moment(filter.updatedStartDate).startOf('day').valueOf() : null,
        end_date: filter.updatedEndDate ? moment(filter.updatedEndDate).endOf('day').add(-10, 'seconds').valueOf() : null,
      };
      let res = await http.post(url, { body });
      if (res.status === 200) {
        let body = await res.json();
        this.historyLog = body.data || [];
        this.historyLogPagination.total = body.total;
      } else {
        let body = await res.json();
        throw new Error(body?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async confirmUpdateAutoTransfer() {
    const { autoTransfer } = this.getData();
    const errors = this.validateDetailForm();
    const errorMessages = Object.values(errors).filter(msg => !!msg);
    if (errorMessages.length > 0) {
      swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        text: 'โปรดระบุข้อมูลให้ครบถ้วนและถูกต้อง!',
        confirmButtonText: 'รับทราบ',
      });
    } else {
      let result = await swal.fire({
        customClass: 'nexter-alert',
        type: 'warning',
        text: 'ยืนยันอัปเดตข้อมูล Auto Transfer?',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      });
      if (result.value) {
        await this.updateAutoTransfer(autoTransfer.channel_uid, autoTransfer.gateway);
      }
    }
  }

  selectPage(page) {
    this.pagination.page = page;
    this.getAutoTransfers();
  }

  changeAllFilters(filter) {
    if (!filter) return;
    this.filter = filter;
  }

  changeFilterChannel(channelUid) {
    this.filter.channelUid = channelUid;
  }

  changeFilterUpdatedBy(value) {
    this.filter.updatedBy = value;
  }

  changeFilterUpdatedStartDate(value) {
    this.filter.updatedStartDate = value;
  }

  changeFilterUpdatedEndDate(value) {
    this.filter.updatedEndDate = value;
  }

  toggleValueList(list = [], value) {
    let values = [...list];
    let index = values.indexOf(value);
    if (index >= 0) {
      values.splice(index, 1);
    } else {
      values.push(value);
    }
    return values;
  }

  toggleFilterGateways(gateway) {
    const { filter } = this.getData();
    this.filter.gateways = this.toggleValueList(filter.gateways, gateway);
  }

  toggleFilterScheduleTypes(type) {
    const { filter } = this.getData();
    this.filter.scheduleTypes = this.toggleValueList(filter.scheduleTypes, type);
  }

  toggleFilterStatuses(status) {
    const { filter } = this.getData();
    this.filter.statuses = this.toggleValueList(filter.statuses, status);
  }

  clearAllFilters() {
    const initialData = this.getInitialData();
    this.filter = initialData.filter;
    this.selectPage(1);
  }

  clearSomeFilters(keys = []) {
    const initialData = this.getInitialData();
    for (const key of keys) {
      this.filter[key] = initialData.filter[key];
    }
  }

  validateMinAmount(value, minAmount = 0) {
    let error = null;
    if (!validation.integer(value)) {
      error = 'ข้อมูลไม่ถูกต้อง';
    } else if (Number(value) < minAmount) {
      error = `โปรดระบุยอดขั้นต่ำอย่างน้อย ${numeral(minAmount).format('0,0[.]00')} บาท`;
    }
    return error;
  }

  validateScheduleDay(value = [], type) {
    let error = null;
    if (['weekly', 'dates'].includes(type) && value.length === 0) {
      error = 'โปรดระบุวันในการโอน';
    }
    return error;
  }

  validateRequired(value) {
    let error = null;
    if (!value) {
      error = 'โปรดระบุ';
    }
    return error;
  }

  validateDetailForm(key) {
    const { autoTransfer, autoTransferDetailForm: formData, autoTransferFormValidation: formDataValidation } = this.getData();
    let errors = { ...formDataValidation };

    if (!key || key === 'min_amount') {
      errors.min_amount = this.validateMinAmount(formData.min_amount, autoTransfer.global_min_amount);
    }
    if (!key || key === 'schedule_type') {
      errors.schedule_type = this.validateRequired(formData.schedule_type);
    }
    if (!key || key === 'schedule_day') {
      errors.schedule_day = this.validateScheduleDay(formData.schedule_day, formData.schedule_type);
    }

    this.autoTransferFormValidation = errors;
    return errors;
  }

  clearDetailForm() {
    const initialData = this.getInitialData();
    this.autoTransferDetailForm = initialData.autoTransferDetailForm;
    this.autoTransferFormValidation = initialData.autoTransferFormValidation;
  }

  changeDetailStatus(value) {
    this.autoTransferDetailForm.status = value;
  }

  changeDetailMinAmount(value) {
    this.autoTransferDetailForm.min_amount = value;
    this.validateDetailForm('min_amount');
  }

  changeDetailScheduleType(value) {
    const { autoTransferDetailForm: formData } = this.getData();
    this.autoTransferDetailForm.schedule_type = value;
    if (formData.schedule_type !== value) {
      this.changeDetailScheduleDay();
      this.autoTransferFormValidation.schedule_day = null;
    }
    this.validateDetailForm('schedule_type');
  }

  changeDetailScheduleDay(value = []) {
    this.autoTransferDetailForm.schedule_day = value;
    this.validateDetailForm('schedule_day');
  }

  selectHistolyLogPage(page) {
    this.historyLogPagination.page = page;
    this.getHistoryLogsAutoTransfer();
  }

  changeHistolyLogFilterChannel(channelId) {
    this.historyLogFilter.channelId = channelId;
  }

  changeHistolyLogFilterUpdatedStartDate(value) {
    this.historyLogFilter.updatedStartDate = value;
  }

  changeHistolyLogFilterUpdatedEndDate(value) {
    this.historyLogFilter.updatedEndDate = value;
  }

  clearAllHistolyLogFilters() {
    const initialData = this.getInitialData();
    this.historyLogFilter = initialData.historyLogFilter;
    this.selectHistolyLogPage(1);
  }
}
