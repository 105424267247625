import crypto from 'crypto';
import { config } from '../config';

class Crypto {
  getSha512(username, password) {
    let salt = `${username}.${config.auth.pkey}`;
    let hash = crypto.createHmac('sha512', salt); /** Hashing algorithm sha512 */
    let hash512 = hash.update(password).digest('hex');

    return hash512;
  }
}

export default new Crypto();
