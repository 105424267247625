import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Card, CardBody, Row, Col, FormGroup, Label, CustomInput } from 'reactstrap';

import { config } from '../../config';
import { Page } from '../../components/Page';
import NexterButton from '../../components/Button/NexterButton';
import { Link } from 'react-router-dom';
import FileUpload from '../../components/File/FileUpload';

class ImportSellerPage extends Component {
  componentWillUnmount() {
    this.props.import.clearData();
  }

  changeFile(key, files) {
    if (files && files.length) {
      if (files.length > 1) files.shift();
      this.props.import.changeFile(files[0], key);
    }
  }

  changeKycFileType(e) {
    this.props.import.changeKycFileType(e.target.value);
    this.props.import.selectImageFiles([]);
  }

  selectImageFiles(files) {
    this.props.import.selectImageFiles(files);
  }

  uploadSeller(key, update) {
    this.props.import.uploadSeller(key, { update });
  }

  uploadImageFiles() {
    this.props.import.uploadImageFiles();
  }

  uploadImageFileUnClassiFy() {
    this.props.import.uploadImageFilesUnClassiFy();
  }

  removeExcelFile(key) {
    this.props.import.changeFile(null, key);
  }

  removeImageFile(i) {
    let { files } = this.props.import.getData();
    const images = files.images;
    images.splice(i, 1);
    this.props.import.selectImageFiles(images);
  }

  async downloadFile(url, expire) {
    let fileUrl = await this.props.import.exportPresignedFileUrl(url, expire);
    if (fileUrl) {
      window.open(fileUrl);
    }
  }

  render() {
    let { loading, files } = this.props.import.getData();
    const uploadCard = [
      { title: 'เพิ่มข้อมูล Seller', keyName: 'seller_excel', update: false },
      { title: 'แก้ไขข้อมูล Seller', keyName: 'update_seller_excel', update: true },
    ];

    return (
      <Page loading={loading}>
        <p className="label-header text-uppercase">Seller</p>
        <div className="title-goback">
          <Link to={`${config.web.rootpath}/sellers`}>
            <i className="back-icon fas fa-chevron-left my-auto mr-4" />
          </Link>
          <span className="texthead-goback">Create/Update Seller (Mass)</span>
        </div>
        <Row>
          <Col>
            {uploadCard.map(card => {
              return (
                <Card key={card.keyName}>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <h5>{card.title}</h5>
                      <NexterButton size="sm" color="success" outline disabled={!files[card.keyName]} onClick={this.uploadSeller.bind(this, card.keyName, card.update)} icon="fas fa-save">
                        SAVE
                      </NexterButton>
                    </div>
                    <hr />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label className="control-label text-label">อัปโหลดข้อมูล Seller</Label>
                          <FileUpload
                            previewSrc={files[card.keyName]?.name || ''}
                            onRemove={this.removeExcelFile.bind(this, card.keyName)}
                            onChange={this.changeFile.bind(this, card.keyName)}
                            inputProps={{
                              multiple: false,
                              accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            }}
                            dropZoneProps={{
                              maxSize: 5242880,
                              accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            }}
                            titleButton="UPLOAD"
                            titlePre="รองรับเฉพาะไฟล์ Excel ที่ Download มาเท่านั้น"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              );
            })}
          </Col>
          <Col>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <h5>ข้อมูลบัญชี/ข้อมูล KYC</h5>
                  {files.kyc_file_type === 'classify' || !files.kyc_file_type ? (
                    <NexterButton size="sm" color="success" outline disabled={_.isEmpty(files.images)} onClick={this.uploadImageFiles.bind(this)} icon="fas fa-save">
                      SAVE
                    </NexterButton>
                  ) : (
                    ''
                  )}
                  {files.kyc_file_type === 'unclassify' && (
                    <NexterButton size="sm" color="success" outline disabled={_.isEmpty(files.images)} onClick={this.uploadImageFileUnClassiFy.bind(this)} icon="fas fa-save">
                      SAVE
                    </NexterButton>
                  )}
                </div>
                <hr />
                <Row>
                  <Col>
                    <FormGroup>
                      {/*<Label className="control-label form-label">Upload แบบระบุประเภท</Label>*/}
                      <div className="d-flex">
                        <CustomInput
                          type="radio"
                          id="kyc_file_type_classify"
                          name="kyc_file_type"
                          label="แบบระบุประเภท"
                          value="classify"
                          checked={files.kyc_file_type === 'classify'}
                          onChange={this.changeKycFileType.bind(this)}
                          className="ml-2"
                        />
                        <CustomInput
                          type="radio"
                          id="kyc_file_type_unclassify"
                          name="kyc_file_type"
                          label="แบบไม่ระบุประเภท"
                          value="unclassify"
                          className="mr-2"
                          checked={files.kyc_file_type === 'unclassify'}
                          onChange={this.changeKycFileType.bind(this)}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                {files.kyc_file_type === 'unclassify' && (
                  <Fragment>
                    <Row>
                      <Col>
                        <FormGroup>
                          <FileUpload
                            previewSrc={!_.isEmpty(files.images) ? files.images.map(i => i.name) : ''}
                            onRemove={this.removeImageFile.bind(this)}
                            onChange={this.selectImageFiles.bind(this)}
                            inputProps={{
                              multiple: true,
                              accept: '.pdf',
                            }}
                            dropZoneProps={{
                              // maxSize: 1048576,
                              accept: '.pdf',
                            }}
                            titleButton="UPLOAD"
                            titlePre="รองรับเฉพาะไฟล์ .pdf เท่านั้น"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="seller-notice-alert d-flex align-items-center">
                      <i className="fas fa-exclamation-circle" />
                      <div className="ml-2">เอกสาร KYC ทุกแผ่นจะต้องถูก scan และได้ออกมาเป็นไฟล์ pdf เพียง 1 ไฟล์ และตั้งชื่อไฟล์ด้วย seller code เช่น 11020008.pdf</div>
                    </div>
                  </Fragment>
                )}

                {files.kyc_file_type === 'classify' && (
                  <Fragment>
                    <Row>
                      <Col>
                        <FormGroup>
                          <FileUpload
                            previewSrc={!_.isEmpty(files.images) ? files.images.map(i => i.name) : ''}
                            onRemove={this.removeImageFile.bind(this)}
                            onChange={this.selectImageFiles.bind(this)}
                            inputProps={{
                              multiple: true,
                              accept: 'image/png, image/jpeg, .pdf',
                            }}
                            dropZoneProps={{
                              // maxSize: 1048576,
                              accept: 'image/png, image/jpeg',
                            }}
                            titleButton="UPLOAD"
                            titlePre="รองรับเฉพาะไฟล์ .jpg, .png, .pdf"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="seller-notice-alert d-flex align-items-center">
                      <i className="fas fa-exclamation-circle" />
                      <div className="ml-2">
                        เอกสาร KYC แต่ละแผ่นจะต้องถูก scan หรือ ถ่ายภาพเป็นไฟล์ jpg , png หรือ pdf และตั้งชื่อไฟล์ด้วย seller code ตามด้วยประเภทของไฟล์ เช่น 11020008_bookbank.jpg,
                        11020008_kycc_VAT20.jpg, 11020008_kycp_IDEN.jpg
                      </div>
                    </div>
                  </Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <h5>วิธีการใช้งาน</h5>
                  <NexterButton size="sm" outline icon="fas fa-download" onClick={() => this.downloadFile(config.massupload.template_url)}>
                    Download เอกสาร
                  </NexterButton>
                </div>
                <hr />
                <div>
                  <span>** ใช้สำหรับเพิ่มหรือแก้ไข seller type seller (partner/sub-merchant) เท่านั้น</span>
                  <br />
                  <b>1. Download Seller template</b>
                  <br />
                  1.1 Download เอกสาร แล้ว Save ไฟล์มาไว้บนเครื่อง เมื่อเปิดเอกสาร excel ที่ Download มาจะพบว่ามี 5 sheets
                  <br />
                  1.2 ให้กรอกข้อมูล ลงใน Sheet ที่ 1(Data) และดูตัวอย่างการใส่ข้อมูลได้ที่ Sheet ที่ 2 (Example)
                  <br />
                  1.3 รายละเอียดของ column ต่างๆ อธิบายไว้ที่ Sheet ที่ 3 (Instruction for upload seller)
                  <br />
                  1.4 หลังจากกรอกข้อมูลลง Sheet ที่ 1 เสร็จแล้วอย่าลืม save ไฟล์
                  <br />
                  <br />
                  <b>2. Create/Update Seller (Mass) เข้าระบบ</b>
                  <br />
                  เลือก Create หรือ Update Seller จาก Panel ให้ถูกต้อง โดย
                  <br />
                  ถ้าต้องการ Create เลือก Panel ชื่อ เพิ่มข้อมูล Seller
                  <br />
                  ถ้าต้องการ Update เลือก Panel ชื่อ แก้ไขข้อมูล Seller
                  <br />
                  หลังจากนั้น ดำเนินการตามขั้นตอนด้านล่างนี้
                  <br />
                  2.1 ทำการเลือกไฟล์ excel ที่กรอกข้อมูลครบถ้วนแล้วจากขั้นตอนที่ 1 แล้วจึงกดปุ่ม Save
                  <br />
                  2.2 รอระบบทำงานสักครู่ จะมี popup แจ้งเตือน ว่ามีข้อมูล record ไหนที่เข้าระบบได้สำเร็จ และไม่สำเร็จบ้าง
                  <br />
                  2.3 หากมีข้อมูลที่เข้าระบบไม่สำเร็จ ให้ตรวจสอบความถูกต้อง และ Upload ข้อมูลแค่ record นั้นๆอีกครั้งหนึ่ง
                  <br />
                  2.4 หากมีทั้ง ข้อมูลที่เข้าระบบสำเร็จและไม่สำเร็จในคราวเดียว ไม่จำเป็นต้องใส่ข้อมูลที่สำเร็จเข้าระบบอืก เพราะข้อมูลเข้าระบบไปแล้ว
                  <br />
                  <br />
                  <b>3. Upload ข้อมูลบัญชี (Bookbank) และข้อมูล KYC เข้าระบบ</b>
                  <br />
                  3.1 กดปุ่ม เลือกไฟล์ แล้วเลือกไฟล์ รูปภาพ หรือ pdf
                  <br />
                  3.2 เอกสาร KYC แต่ละแผ่นจะต้องถูก scan หรือ ถ่ายภาพเป็นไฟล์ jpg, jpeg, png และตั้งชื่อไฟล์ด้วย seller code ตามด้วยประเภทของไฟล์ เช่น 11020008_bookbank.jpg, 11020008_kycc_VAT20.jpg,
                  11020008_kycp_IDEN.jpg
                  <br />
                  3.3 รายละเอียดเพิ่มเติม ดูได้ที่ Sheet ที่ 3 (Instruction for upload seller)
                  <br />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}

export default inject('import')(observer(ImportSellerPage));
