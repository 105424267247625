import BaseStore from './BaseStore';
import moment from 'moment';
import { config } from '../config';
import { http } from '../utils/http';
import queryString from 'query-string';
import * as _ from 'lodash';
import palette from 'google-palette';
export class DashboardStore extends BaseStore {
  constructor() {
    super();
    this.observable(this.getInitialData());
  }

  getInitialData() {
    const yearNow = new Date().getFullYear().toString();
    return {
      loading: false,
      dashboard: {
        remaining_amount: 0,
      },
      total_payment: {
        totalAmount: 0,
        selected_interval: 'month',
        dataset: {
          labels: [],
          datasets: [],
        },
      },
      top_channel: {
        dataset: {
          labels: [],
          datasets: [],
        },
        rawData: [],
      },
      card_bank: {
        labels: [],
        data: [],
        bgColor: [],
        rawData: [],
        payment_type: { credit_card: true, installment: true },
      },
      by_channel: {
        selected_interval: 'month',
        totalAmount: 0,
        dataset: {
          labels: [],
          datasets: [],
        },
      },
      payment_method: {
        selected_interval: 'month',
        dataset: {
          labels: [],
          datasets: [],
        },
      },
      gateway: {
        selected_interval: 'month',
        totalAmount: 0,
        dataset: {
          labels: [],
          datasets: [],
        },
      },

      avg_dashboard: {
        data: {
          summary_avg_order: {
            interval: '',
            type: 'avg_order_val',
            avg_order_val: 0,
            last_interval: '2021-03-18',
            avg_order_val_last: 0,
            avg_order_change: 0,
          },
          summary_order_quantity: {
            interval: '',
            type: 'order_quantity',
            order_quantity_val: 0,
            last_interval: '',
            order_quantity_val_last: 0,
            order_quantity_change: 0,
          },
        },
      },
      start_date: '',
      end_date: '',
      min_start_date: '',
      max_start_date: '',
      max_end_date: '',
      min_end_date: '',
      start_date_filter: '',
      end_date_filter: '',
      method_filter: [],
      method_filter_all: [],
      dataStatus: false,

      totalpayment_interval: 'month',
      totalByChannel_interval: 'month',
      totalGateway_interval: 'month',
      year_diff: 0,
    };
  }
  clearFilter() {
    this.method_filter = [];
    this.method_filter_all = [];
  }
  setLoading(val) {
    this.loading = val;
  }
  setDataStatus(val) {
    this.dataStatus = val;
  }
  resetData() {
    this.observable(this.getInitialData());
  }
  async fetchSummaryDashboard(interval = 'day') {
    try {
      const query = {
        interval: interval,
      };
      this.loading = true;
      let url = `${config.npay.apiUrl}/dashboard/summary?${queryString.stringify(query)}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.avg_dashboard.data = body.data;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }

  setDataDashboardTotalPayment(dataReponse, selected_interval) {
    const { data, sum_amount } = dataReponse;
    let totalAmount = sum_amount;
    let mapData = data.map(item => {
      let date = moment(item.interval);
      let year = date.format('YYYY');
      let month = date.format('MMM');
      let label_show = month;
      let monthNum = date.get('month');
      if (selected_interval === 'quarter') {
        const quarter = item.interval.split(':');
        year = quarter[1];
        //let q = quarter[0].replace('Quarter-', 'Q')
        label_show = quarter[0];
      } else if (selected_interval === 'year') {
        label_show = year;
      }
      return {
        ...item,
        year,
        month,
        label_show,
        monthNum,
      };
    });

    let orderBy = selected_interval === 'quarter' ? 'label_show' : 'monthNum';
    mapData = _.orderBy(mapData, orderBy);
    let groupByData = _.groupBy(mapData, 'label_show');
    let groupByInterval = _.groupBy(mapData, 'year');

    let labelsFullMonth = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];
    let labelsFullQuarter = ['Quarter-1', 'Quarter-2', 'Quarter-3', 'Quarter-4'];
    let labels = Object.keys(groupByData);
    if (this.year_diff > 0 && selected_interval == 'month') {
      labels = labelsFullMonth;
    } else if (this.year_diff > 0 && selected_interval == 'quarter') {
      labels = labelsFullQuarter;
    }

    let datasets = [];
    let mapBackgroundColor = ['#21d59b', '#57b8ff', '#d5d7e3'];
    if (Object.keys(groupByInterval).length >= 3) {
      mapBackgroundColor = mapBackgroundColor.reverse();
    }
    let indexC = 0;
    for (const [label, data] of Object.entries(groupByInterval)) {
      let y_axis = [];

      data.forEach(item => {
        const indexOfData = labels.indexOf(item['label_show']);
        if (y_axis[indexOfData]) {
          y_axis[indexOfData] = y_axis[indexOfData] + item.net_amount;
        } else {
          y_axis[indexOfData] = item.net_amount;
        }
      });

      let dataSet = {
        label: label,
        data: y_axis,
        barPercentage: 0.35,
        backgroundColor: mapBackgroundColor[indexC] || this.getRandomColor(),
      };
      indexC++;
      datasets.push(dataSet);
    }
    this.total_payment = { dataset: { datasets, labels }, totalAmount };
  }

  setTotalPaymentInterval(interval) {
    this.totalpayment_interval = interval;
  }
  setTotalByChannelInterval(interval) {
    this.totalByChannel_interval = interval;
  }
  setTotalGatewayInterval(interval) {
    this.totalGateway_interval = interval;
  }
  setPaymentType(val) {
    let {
      card_bank: { payment_type },
    } = this.getData();
    payment_type[val] = !payment_type[val];
    this.card_bank.payment_type = payment_type;
  }
  setCardBankFilter(label, data, bgColor) {
    this.card_bank.labels = label;
    this.card_bank.data = data;
    this.card_bank.bgColor = bgColor;
  }
  async fetchDashboardTotalPayment(interval = 'month') {
    try {
      const { start_date_filter, end_date_filter, method_filter } = this.getData();
      let query = {
        start_date: start_date_filter,
        end_date: end_date_filter,
        interval,
        method_filter: method_filter.join(','),
      };
      let url = `${config.npay.apiUrl}/dashboard/totalpayment?${queryString.stringify(query)}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.setDataDashboardTotalPayment(body, interval);
        return body;
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }
  async fetchDashboardByPaymentMethod(interval = 'month') {
    const { start_date_filter, end_date_filter, method_filter } = this.getData();
    try {
      let query = {
        start_date: start_date_filter,
        end_date: end_date_filter,
        interval,
        method_filter: method_filter.join(','),
      };
      let url = `${config.npay.apiUrl}/dashboard/payment/method?${queryString.stringify(query)}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.setDashboardPaymentMethod(body.data, interval);
        return body;
      }
    } catch (error) {
      console.error(error);
    }
  }

  setDashboardPaymentMethod(data, interval) {
    let mapData = data.map(item => {
      let date = moment(item.interval);
      let year = date.format('YYYY');
      let month = date.format('MM');
      let label_show = `${year}-${month}`;
      let sort_key = `${year}-${month}`;
      let monthNum = date.get('month');
      if (interval === 'quarter') {
        const quarter = item.interval.split(':');
        year = quarter[1];
        let q = quarter[0].replace('Quarter-', 'Q');
        label_show = `${q} ${year}`;
        sort_key = `${year}-${q}`;
      } else if (interval === 'year') {
        label_show = year;
        sort_key = year;
      } else {
        monthNum = `${year}-${month}`;
      }
      return {
        ...item,
        year,
        month,
        label_show,
        monthNum,
        sort_key,
      };
    });
    let orderBy = interval === 'quarter' ? 'sort_key' : 'monthNum';
    mapData = _.orderBy(mapData, orderBy);
    let groupByData = _.groupBy(mapData, 'method');
    let groupByInterval = _.groupBy(mapData, 'label_show');
    let labels = this.year_diff > 0 ? this.checkLabel(interval) : Object.keys(groupByInterval);
    let datasets = [];
    for (const [label, data] of Object.entries(groupByData)) {
      let y_axis = [];
      data.forEach(item => {
        const indexOfData = labels.indexOf(item['label_show']);
        if (y_axis[indexOfData]) {
          y_axis[indexOfData] = y_axis[indexOfData] + item.net_amount;
        } else {
          y_axis[indexOfData] = item.net_amount;
        }
      });
      let dataSet = {
        label: label,
        data: y_axis,
      };
      datasets.push(dataSet);
    }
    this.payment_method.dataset.datasets = datasets;
    this.payment_method.dataset.labels = this.dateConverter(labels, interval);
  }
  async fetchDashboardCreditCardBankMethod() {
    const {
      start_date_filter,
      end_date_filter,
      card_bank: { payment_type },
    } = this.getData();
    let payment_type_string = Object.keys(payment_type).filter(o => payment_type[o]);

    try {
      let query = {
        start_date: start_date_filter,
        end_date: end_date_filter,
        payment_type: payment_type_string.join(','),
      };
      let url = `${config.npay.apiUrl}/dashboard/payment/credit-card?${queryString.stringify(query)}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.setDataDashboardCardBank(body.data);
        return body;
      }
    } catch (error) {
      console.error(error);
    }
  }
  async fetchDashboardPaymentByChannel(interval = 'month') {
    const { start_date_filter, end_date_filter, method_filter } = this.getData();
    try {
      let query = {
        start_date: start_date_filter,
        end_date: end_date_filter,
        interval,
        method_filter: method_filter.join(','),
      };
      let url = `${config.npay.apiUrl}/dashboard/channel/payment?${queryString.stringify(query)}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.setDashboardByChannel(body.data, interval);
        return body;
      }
    } catch (error) {
      console.error(error);
    }
  }
  async fetchDashboardGatewayFee(interval = 'month') {
    const { start_date_filter, end_date_filter, method_filter } = this.getData();
    try {
      let query = {
        start_date: start_date_filter,
        end_date: end_date_filter,
        interval,
        method_filter: method_filter.join(','),
      };
      let url = `${config.npay.apiUrl}/dashboard/payment-gateway/fee?${queryString.stringify(query)}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.setDashboardGateway(body.data, interval, body.total_amount);
        return body;
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }
  setDashboardGateway(data, selected_interval, total_amount) {
    let mapData = data.map(item => {
      let date = moment(item.interval);
      let year = date.format('YYYY');
      let month = date.format('MM');
      let monthNum = date.get('month');
      let label_show = `${year}-${month}`;
      let sort_key = `${year}-${month}`;
      if (selected_interval === 'quarter') {
        const quarter = item.interval.split(':');
        year = quarter[1];
        let q = quarter[0].replace('Quarter-', 'Q');
        label_show = `${q} ${year}`;
        sort_key = `${year}-${q}`;
      } else if (selected_interval === 'year') {
        label_show = year;
        sort_key = year;
      } else {
        monthNum = `${year}-${month}`;
      }
      return {
        ...item,
        year,
        month,
        label_show,
        monthNum,
        sort_key,
      };
    });
    let orderBy = selected_interval === 'quarter' ? 'sort_key' : 'monthNum';
    mapData = _.orderBy(mapData, orderBy);
    let groupByData = _.groupBy(mapData, 'gateway');
    let groupByInterval = _.groupBy(mapData, 'label_show');
    let labels = this.year_diff > 0 ? this.checkLabel(selected_interval) : Object.keys(groupByInterval);
    let datasets = [];
    for (const [label, data] of Object.entries(groupByData)) {
      let y_axis = [];
      data.forEach(item => {
        const indexOfData = labels.indexOf(item['label_show']);
        if (y_axis[indexOfData]) {
          y_axis[indexOfData] = y_axis[indexOfData] + item.total;
        } else {
          y_axis[indexOfData] = item.total;
        }
      });
      let dataSet = {
        label: label,
        data: y_axis,
        backgroundColor: this.getColor(label),
        barPercentage: 0.35,
      };
      datasets.push(dataSet);
    }
    this.gateway.dataset.datasets = datasets;
    this.gateway.dataset.labels = this.dateConverter(labels, selected_interval);
    this.gateway.totalAmount = total_amount;
  }
  getColor(name) {
    var color = '';
    switch (name) {
      case '2c2p':
        color = '#007aff';
        break;
      case '123':
        color = '#21d59b';
        break;
      case 't2p':
        color = '#ffc700';
    }
    return color;
  }
  async fetchDashboardTopChannel() {
    try {
      const { start_date_filter, end_date_filter, method_filter } = this.getData();
      let query = {
        start_date: start_date_filter,
        end_date: end_date_filter,
        method_filter: method_filter.join(','),
      };
      let url = `${config.npay.apiUrl}/dashboard/top-rank/channel/income?${queryString.stringify(query)}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.setDataDashboardTopChannel(body.data);
        return body;
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }
  setDataDashboardTopChannel(data) {
    let dataset = {
      labels: [],
      datasets: [],
    };
    let y_axis = [];
    let x_axis = [];
    data.forEach(item => {
      y_axis.push(item.net_amount);
      x_axis.push(item.name);
    });
    dataset.labels = x_axis;
    dataset.datasets = [
      {
        data: y_axis,
        borderColor: '#007aff',
        backgroundColor: '#007aff',
        borderWidth: 1,
        hoverBackgroundColor: '#007aff',
        hoverBorderColor: '#007aff',
        barPercentage: 0.35,
      },
    ];
    this.top_channel = { dataset, rawData: data };
  }

  handleStartDate(data) {
    const date = moment(data).format('YYYY-MM-DD');
    this.start_date_filter = date;
    this.start_date = data;
    var newDate = new Date(this.start_date_filter);
    var year = newDate.getFullYear() + 2;
    var currentYear = new Date().getFullYear();
    let getMonth = new Date(this.start_date).getMonth();

    if (year >= currentYear) {
      this.max_end_date = new Date();

      this.min_end_date = new Date((year - 2).toString(), getMonth.toString());
    } else {
      this.max_end_date = new Date(year.toString(), '11');
      this.min_end_date = new Date((year - 2).toString(), getMonth.toString());
    }

    if (this.end_date.length != 0) {
      let cal = this.end_date.getFullYear() - this.start_date.getFullYear();
      this.year_diff = cal;
    }
    if (this.start_date.length != 0 && this.end_date.length != 0) {
      this.min_end_date = '';
      this.min_start_date = '';
      this.max_start_date = '';
      this.max_start_date = '';
    }
  }
  handleEndDate(data) {
    var lastDay = new Date(data.getFullYear(), data.getMonth() + 1, 0);
    const date = moment(lastDay).format('YYYY-MM-DD');
    this.end_date_filter = date;
    this.end_date = data;
    var newDate = new Date(this.end_date_filter);
    var year = newDate.getFullYear() - 2;

    this.min_start_date = new Date(year.toString());
    this.max_start_date = this.end_date;
    if (this.start_date.length != 0) {
      let cal = this.end_date.getFullYear() - this.start_date.getFullYear();
      this.year_diff = cal;
    }
    if (this.start_date.length != 0 && this.end_date.length != 0) {
      this.min_end_date = '';
      this.min_start_date = '';
      this.max_start_date = '';
      this.max_start_date = '';
    }
  }
  setDataDashboardCardBank(data) {
    let dataState = [];
    let labels = [];
    let bank_code = [];
    let mapBank = {
      KBANK: { color: '#21d59b', name: 'กสิกรไทย (KBANK)' },
      SCB: { color: '#c28dff', name: 'ไทยพาณิชย์ (SCB)' },
      BAY: { color: '#ffc700', name: 'กรุงศรีอยุธยา (BAY)' },
      KTB: { color: '#57b8ff', name: 'กรุงไทย (KTB)' },
      KTC: { color: '#57b8ff', name: 'กรุงไทย (KTB)' },
      BBL: { color: '#1155cc', name: 'กรุงเทพ (BBL)' },
      TMB: { color: '#007aff', name: 'ทหารไทย (TMB)' },
      TTB: { color: '#007aff', name: 'ทหารไทยธนชาต (TTB)' },
      UOB: { color: '#7e84a3', name: 'ยูโอบี (UOB)' },
      CITY: { color: '#e20a1a', name: 'ซิตี้แบงก์ (Citybank)' },
      GSB: { color: '#ff8dc6', name: 'ออมสิน (GSB)' },
    };

    const abbrBank = [
      {
        value: 'KCC', // KCC
        label: 'Bank of Ayudhya (Krungsri)',
      },
      {
        value: 'BBL',
        label: 'Bangkok Bank',
      },
      {
        value: 'FCC', // KFC
        label: 'Krungsri First Choice',
      },
      {
        value: 'KBANK',
        label: 'Kasikorn Bank',
      },
      {
        value: 'KTB', // KTC ==> KTB
        label: 'Krungthai Bank',
      },
      {
        value: 'SCB',
        label: 'Siam Commercial Bank',
      },
      {
        value: 'UOB',
        label: 'United Overseas Bank',
      },
      {
        value: 'TBANK',
        label: 'Thanachart Bank',
      },
      {
        value: 'TTB',
        label: 'TMBThanachart Bank',
      },
      {
        value: 'TMB',
        label: 'TMB Bank',
      },
    ];

    let otherObject = {
      card_bank_code: 'Others',
      card_bank_name: 'Others',
      percents: 0,
      count_usage:0,
      total_usage: 0,
      backgroundColor: this.getRandomColor(),
    };

    let mapData = data.reduce((final, current) => {
      const isMatched = abbrBank.some(bank => bank.value === current.card_bank_code);
      if (isMatched) {
        switch (current.card_bank_code) {
          case 'TBANK':
          case 'TTB':
          case 'TMB':
            const isTTBExisted = final.findIndex(finalItem => finalItem.card_bank_code === 'TTB');
            if (isTTBExisted === -1) {
              bank_code.push('TTB');
              labels.push('TMBTHANACHART BANK');
              dataState.push(current.total_usage);
              current.backgroundColor = mapBank[current.card_bank_code]?.color || this.getRandomColor();
              current.card_bank_code = 'TTB'
              current.card_bank_name = 'TMBTHANACHART BANK'
              final.push(current);
            } else {
              dataState[isTTBExisted] += current.total_usage;
              final[isTTBExisted].total_usage += current.total_usage
              final[isTTBExisted].count_usage += current.count_usage
              final[isTTBExisted].percents += current.percents
              console.log(dataState[isTTBExisted])
            }
            break;
          default:
            bank_code.push(current.card_bank_code);
            labels.push(current.card_bank_name);
            dataState.push(current.total_usage);
            current.backgroundColor = mapBank[current.card_bank_code]?.color || this.getRandomColor();
            final.push(current);
            break;
        }
      } else {
        otherObject.total_usage += current.total_usage;
        otherObject.count_usage += current.count_usage;
        otherObject.percents += current.percents;
      }

      return final;
    }, []);

    if (otherObject.total_usage > 0) {
      bank_code.push(`Others`);
      labels.push(`Others`);
      dataState.push(otherObject.total_usage);
      mapData.push(otherObject);
    }

    if (this.card_bank.payment_type.credit_card === false && this.card_bank.payment_type.installment === false) {
      this.card_bank.labels = [];
      this.card_bank.data = [];
      this.card_bank.bgColor = [];
      this.card_bank.rawData = [];
    } else {
      this.card_bank.data = dataState;
      this.card_bank.labels = labels;
      this.card_bank.rawData = mapData;
      this.card_bank.bgColor = mapData.map(item => item.backgroundColor);
    }
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  generateArrTemplate(length) {
    let arrTemp = [];
    for (let i = 0; i < length; i++) {
      arrTemp.push(0);
    }
    return arrTemp;
  }
  setDashboardByChannel(data, selected_interval) {
    const seq = palette('tol-rainbow', data.length).map(item => `#${item}`);
    let mapData = data.map(item => {
      let date = moment(item.interval);
      let year = date.format('YYYY');
      let month = date.format('MM');
      let label_show = `${year}-${month}`;
      let sort_key = `${year}-${month}`;
      let monthNum = date.get('month');
      if (selected_interval === 'quarter') {
        const quarter = item.interval.split(':');
        year = quarter[1];
        let q = quarter[0].replace('Quarter-', 'Q');
        label_show = `${q} ${year}`;
        sort_key = `${year}-${q}`;
      } else if (selected_interval === 'year') {
        label_show = year;
        sort_key = year;
      } else {
        monthNum = `${year}-${month}`;
      }
      return {
        ...item,
        year,
        month,
        monthNum,
        label_show,
        sort_key,
      };
    });

    let orderBy = selected_interval === 'quarter' ? 'sort_key' : 'monthNum';
    mapData = _.orderBy(mapData, orderBy);
    let groupByData = _.groupBy(mapData, 'name');
    let groupByInterval = _.groupBy(mapData, 'label_show');
    let labels = this.year_diff > 0 ? this.checkLabel(selected_interval) : Object.keys(groupByInterval);
    let datasets = [];
    let index = 0;
    for (const [label, data] of Object.entries(groupByData)) {
      let y_axis = [];

      data.forEach(item => {
        const indexOfData = labels.indexOf(item['label_show']);
        if (y_axis[indexOfData]) {
          y_axis[indexOfData] = y_axis[indexOfData] + item.net_amount;
        } else {
          y_axis[indexOfData] = item.net_amount;
        }
      });

      let dataSet = {
        label: label,
        data: y_axis,
        backgroundColor: seq[index],
        barPercentage: 0.35,
      };
      index++;
      datasets.push(dataSet);
    }
    this.by_channel.dataset.datasets = datasets;
    this.by_channel.dataset.labels = this.dateConverter(labels, selected_interval);
  }

  dateConverter(labels, interval) {
    if (interval === 'month') {
      let newArr = [];
      labels.forEach(item => {
        let temp = item.split('-');
        let date = new Date(item);
        let shortMonth = date.toLocaleString('en-us', { month: 'short' });
        newArr.push(shortMonth + ' ' + temp[0]);
      });
      return newArr;
    } else {
      return labels;
    }
  }
  checkLabel(interval) {
    let result = [];

    if (this.start_date.length !== 0 && this.end_date.length !== 0) {
      let diff_length = this.monthDiff(this.start_date, this.end_date);

      for (let i = 0; i <= diff_length; i++) {
        let date = new Date(this.start_date.getFullYear(), this.start_date.getMonth() + i);
        let strDate = moment(date).format('YYYY-MM');
        result.push(strDate);
      }
      if (interval === 'month') {
        return result;
      } else if (interval === 'quarter') {
        let QuarterArr = result.map(item => {
          let quarterDate = new Date(item);
          let quarter = this.getQuarter(quarterDate);
          let year = quarterDate.getFullYear();
          let quarterStr = `Q${quarter} ${year}`;
          return quarterStr;
        });
        let final = _.uniq(QuarterArr);
        return final;
      } else if (interval === 'year') {
        let yearArr = [];
        let start = this.start_date.getFullYear();
        for (let i = 0; i <= this.year_diff; i++) {
          yearArr.push((start + i).toString());
        }
        return yearArr;
      }
    }
  }
  monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  getQuarter(d) {
    return Math.floor(d.getMonth() / 3) + 1;
  }
}
