import { UserStore } from './stores/UserStore';
import { SellerStore } from './stores/SellerStore';
import { SellerRegisterStore } from './stores/SellerRegisterStore';
import { ChannelStore } from './stores/ChannelStore';
import { PaymentStore } from './stores/PaymentStore';
import { TransferStore } from './stores/TransferStore';
import { DashboardStore } from './stores/DashboardStore';
import { StatementStore } from './stores/StatementStore';
import { ImportStore } from './stores/ImportStore';
import { RoleStore } from './stores/RoleStore';
import { SystemStore } from './stores/SystemStore';
import { ReconcileStore } from './stores/ReconcileStore';
import { BankStore } from './stores/BankStore';
import { AutoTransferStore } from './stores/AutoTransferStore';

export default {
  user: new UserStore(),
  seller: new SellerStore(),
  sellerRegister: new SellerRegisterStore(),
  channel: new ChannelStore(),
  payment: new PaymentStore(),
  transfer: new TransferStore(),
  dashboard: new DashboardStore(),
  statement: new StatementStore(),
  import: new ImportStore(),
  role: new RoleStore(),
  system: new SystemStore(),
  reconcile: new ReconcileStore(),
  bank: new BankStore(),
  autoTransfer: new AutoTransferStore(),
};
