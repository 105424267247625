import _ from 'lodash';
import {
  Modal, ModalBody, Row, Col, FormGroup, Label, Input,
  Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import { Page } from '../../components/Page';
import NexterButton from '../../components/Button/NexterButton';
import Visibility from '../../components/Visibility/Visibility';
import ReconcileComp from './ReconcileComp';
import { ReconcileTab } from '../../stores/ReconcileStore'

class ReconcileList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exportCheck: 'all',
      modalExport: false
    };
  }
  componentDidMount() {
    this.props.reconcile.viewChannelTransaction(null);
  }

  componentWillUnmount() {
    this.props.reconcile.clearFilterList();
  }

  toggleExport = () => {
    const rest = {};
    if (!this.state.modalExport) rest.exportCheck = 'all';
    this.setState({ modalExport: !this.state.modalExport, ...rest });
  };

  viewAllChannelTransaction = () => {
    this.props.reconcile.viewChannelTransaction('');
  };

  onChangeTab = (tab = ReconcileTab.MONEY_IN) => {
    this.props.reconcile.changeTab(tab);
  }
  render() {
    const { reconcile } = this.props;
    const { channel, tab } = reconcile.getData();
    const { loggedinUser } = this.props.user.getData();
    const isSuperAdmin = ['super_admin', 'biz_system', 'ae_marketing', 'manager'].includes(loggedinUser.type);
    return (
      <Page loading={reconcile.loading}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="text-uppercase" style={{ marginBottom: 0 }}>
              <b>Reconcile Report</b>
            </h5>
            <Visibility permission_code="reconcile:view">
              {_.isNull(channel) && (
                <div>
                  {(isSuperAdmin && tab !== ReconcileTab.AVAILABLE_BALANCE) && (
                    <NexterButton className="mr-2 my-0" size="sm" outline onClick={this.viewAllChannelTransaction}>
                      View All Channel Transaction
                    </NexterButton>
                  )}
                  <NexterButton className="mr-2 my-0" size="sm" icon="fas fa-download" onClick={this.toggleExport}>
                    EXCEL
                  </NexterButton>
                  <Modal isOpen={this.state.modalExport} toggle={this.toggleExport} centered className="modal-sm">
                    <ModalBody>
                      <h6 className="my-2">เลือกข้อมูลที่ต้องการ Export</h6>
                      <FormGroup check className="d-flex flex-column reconcile-export">
                        <Label className="input-status pl-3 my-1" check>
                          <Input
                            checked={this.state.exportCheck === 'all'}
                            name="status"
                            type="radio"
                            value="all"
                            style={{ width: 20, height: 20 }}
                            onChange={e => this.setState({ exportCheck: e.target.value })}
                          />
                          <span className="label-status ml-1" style={{ lineHeight: '30px', fontWeight: '600' }}>
                            Export All Transaction
                          </span>
                        </Label>
                        <Label className="input-status pl-3 my-1" check>
                          <Input
                            checked={this.state.exportCheck === 'diff'}
                            name="status"
                            type="radio"
                            value="diff"
                            style={{ width: 20, height: 20 }}
                            onChange={e => this.setState({ exportCheck: e.target.value })}
                          />
                          <span className="label-status ml-1 lin" style={{ lineHeight: '30px', fontWeight: '600' }}>
                            Export เฉพาะ Different Transaction
                          </span>
                        </Label>
                      </FormGroup>
                      <hr />
                      <Row>
                        <Col xs={6} className="d-flex pr-1">
                          <NexterButton size="sm" outline className="flex-fill" onClick={this.toggleExport}>
                            ยกเลิก
                          </NexterButton>
                        </Col>
                        <Col xs={6} className="d-flex pl-1">
                          <NexterButton
                            size="sm"
                            className="flex-fill"
                            onClick={() => {
                              this.props.reconcile.exportFile(this.state.exportCheck === 'all' ? false : true, isSuperAdmin, 'Reconcile report');
                              this.toggleExport();
                            }}
                          >
                            EXPORT
                          </NexterButton>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </div>
              )}
            </Visibility>
          </div>
          <Visibility permission_code="reconcile:view">
            <Nav tabs>
              <NavItem>
                <NavLink active={tab === ReconcileTab.MONEY_IN} onClick={() => this.onChangeTab(ReconcileTab.MONEY_IN)}>
                  Money-in
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink active={tab === ReconcileTab.MONEY_OUT} onClick={() => this.onChangeTab(ReconcileTab.MONEY_OUT)}>
                  Money-out
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink active={tab === ReconcileTab.AVAILABLE_BALANCE} onClick={() => this.onChangeTab(ReconcileTab.AVAILABLE_BALANCE)}>
                  Available balance
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={tab}>
              <TabPane tabId={ReconcileTab.MONEY_IN}>
                {tab === ReconcileTab.MONEY_IN && <ReconcileComp channel={channel} reconcile={reconcile} />}
              </TabPane>
              <TabPane tabId={ReconcileTab.MONEY_OUT}>
                {tab === ReconcileTab.MONEY_OUT && <ReconcileComp channel={channel} reconcile={reconcile} />}
              </TabPane>
              <TabPane tabId={ReconcileTab.AVAILABLE_BALANCE}>
                {tab === ReconcileTab.AVAILABLE_BALANCE && <ReconcileComp channel={channel} reconcile={reconcile} />}
              </TabPane>
            </TabContent>
          </Visibility>
          <Visibility permission_code="reconcile:view" invisibility_msg>
            <h5>ท่านไม่มีสิทธิ์ดูรายการ Reconcile Report</h5>
          </Visibility>
        </div>
      </Page>
    );
  }
}
export default inject('reconcile', 'user')(observer(ReconcileList));
