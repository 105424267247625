import swal from 'sweetalert2';
import BaseStore from './BaseStore';
import { config } from '../config';
import { http } from '../utils/http';
export class RoleStore extends BaseStore {
  constructor() {
    super();
    this.observable(this.getInitialData());
  }

  getInitialData() {
    return {
      rp_by_role: {},
      rolepermission: [],
      permission: [],
      keep_rolepermission: {},
      menu_permission: {},
      role: [],
      focus_menu: [],
      focus_role: '',
      current_key: 0,
      loading: false,
      editableT1: false,
      editableT2: false,
      editableT3: false,
      editableT4: false,
      editableT5: false,
      logs: [],
      checkedChangeValue: [],
      edit_role_permission: []
    };
  }

  changeConfig(key, val) {
    this.current_key = key;
    this.rolepermission[key] = val;
    this.updateRolePermission();
  }

  async updateRolePermission(update) {
    this.loading = true;
    try {
      let url = `${config.npay.apiUrl}/admin/rolepermission`;
      let body = update || this.edit_role_permission;
      let response = await http.put(url, { body });
      if (response.status === 200) {
        swal
          .fire({
            customClass: 'nexter-alert',
            type: 'success',
            title: 'อัปเดตสำเร็จ',
            text: 'Role & Permission has been successfully saved, re-Login is required for new configuration take effect',
            showCancelButton: false,
            confirmButtonText: 'กลับไป',
          })
          .then(async () => {
            await window.location.assign(`${config.web.rootpath}/user-management`);
          });
        this.loading = false;
        this.editableT1 = false;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async updateRoleMenu() {
    this.loading = true;
    try {
      // const changesets = require('diff-json');
      // const diff = changesets.diff(this.keep_rolepermission, this.rolepermission);
      let url = `${config.npay.apiUrl}/admin/rolepermission/menu`;
      let body = { value: this.focus_menu.map(i => `menu:${i}`), key: 'menu', role: this.focus_role };
      let response = await http.put(url, { body });
      if (response.status === 200) {
        this.loading = true;
        await this.fetchRole();
        swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          title: 'Success!',
          text: 'Role & Permission has been successfully saved',
          confirmButtonText: 'OK',
        });
        this.loading = false;
        this.editable = false;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async getRPHistoryLogs() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/historylogs`;
      let body = {
        parent: 'admin',
        parent_id: 0,
        entity: ['rolepermission'],
      };

      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        this.logs = body.data || [];
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
  async fetchAll() {
    try {
      const role = await this.fetchRole()
      const perms = await this.fetchPermission()
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
  async fetchRole() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/rolepermission`;
      let response = await http.get(url);

      if (response.status === 200) {
        let body = await response.json();
        let menu = [];
        let role = [];
        let role_rp = {}
        let rolepermission = body.rp.map(item => {
          const { role_permission, ...roleItem } = item;
          role_rp[roleItem.role_code] = role_permission.filter(f => f.is_active).map(i => i.permission_code)
          role.push(roleItem);
          const menuPerm = role_permission.filter(perm => perm.permission_code.startsWith('menu:') && perm.is_active).map(item => ({ ...item, menu: item.permission_code.replace('menu:', '') }));
          menu.push({ role_code: roleItem.role_code, role_id: roleItem.id, menu: menuPerm });
          return { [roleItem.role_code]: role_permission };
        });
        this.menu_permission = menu;
        this.rolepermission = rolepermission;
        this.role = role;
        this.rp_by_role = role_rp
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
  async fetchPermission() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/permission`;
      let response = await http.get(url);

      if (response.status === 200) {
        let body = await response.json();
        this.permission = body.data;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
}
