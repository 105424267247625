import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// react plugin used to create charts
import { Line, Doughnut, Bar, HorizontalBar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import {
  // UncontrolledTooltip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  ButtonGroup,
  Button,
} from 'reactstrap';

import SelectInterval from './components/SelectInterval';

import * as _ from 'lodash';
class AverageChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interval: 'day',
      color: '',
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  async fetchData() {
    const { interval } = this.state;
    await this.props.dashboard.fetchSummaryDashboard(interval);
  }
  selectInterval = val => {
    this.setState(
      {
        interval: val,
      },
      () => this.fetchData()
    );
  };
  textInterval = val => {
    if (this.state.interval === 'week') {
      return 'Since last week';
    }
    if (this.state.interval === 'month') {
      return 'Since last month';
    } else {
      return 'Since yesterday';
    }
  };
  numberWithCommas = x => {
    if (x) {
      const num = x.toString().replace(/[^0-9.]/g, '').split('.');
      num[0] = num[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return num.slice(0, 2).join('.');
    } else {
      return '0';
    }
  };
  render() {
    const { avg_dashboard } = this.props.dashboard.getData();
    const data = avg_dashboard.data;
    return (
      <Card className="card-chart">
        <CardHeader>
          <div className="chart-header-row">
            <CardTitle className="chart-title " tag="h5">
              Summary
            </CardTitle>
            <ButtonGroup className="no-print">
              <Button className={`dashboard-btn ${this.state.interval === 'day' ? 'clicked' : ''}`} size="sm" onClick={() => this.selectInterval('day')}>
                Day
              </Button>
              <Button className={`dashboard-btn ${this.state.interval === 'week' ? 'clicked' : ''}`} size="sm" onClick={() => this.selectInterval('week')}>
                Week
              </Button>
              <Button className={`dashboard-btn ${this.state.interval === 'month' ? 'clicked' : ''}`} size="sm" onClick={() => this.selectInterval('month')}>
                Month
              </Button>
            </ButtonGroup>
          </div>
        </CardHeader>
        <CardBody>
          <Row className="mb-3">
            <Col className="position-relative px-4 avg-dashboard-container">
              <p className="m-0 grey-text-dashboard">Order Value (Baht)</p>
              <Row>
                <Col className="py-2 avg-num-dashboard">{this.numberWithCommas(data.summary_avg_order?.avg_order_val?.toFixed(2) || 0)}</Col>
                <Col>
                  <div className={`percent-num ${data.summary_avg_order?.avg_order_change >= 0 ? 'green-text' : 'red-text'}`}>
                    <i className={`fa fa-${data.summary_avg_order?.avg_order_change >= 0 ? 'caret-up' : 'caret-down'}`} style={{ paddingRight: '10px' }} color="#07b53b" />
                    {data.summary_avg_order.avg_order_change >= 0 ? `+${data.summary_avg_order?.avg_order_change?.toFixed(1) || 0}%` : `${data.summary_avg_order?.avg_order_change?.toFixed(1) || 0}%`}
                  </div>
                  <div className="light-grey-text-dashboard textInterval">{this.textInterval()}</div>
                </Col>
              </Row>
            </Col>

            <Col className="position-relative px-4 avg-dashboard-container border-right border-left border-grey">
              <p className="m-0 grey-text-dashboard">Order Quantity (Order)</p>
              <Row>
                <Col className="py-2 avg-num-dashboard">{this.numberWithCommas(data.summary_order_quantity.order_quantity_val)}</Col>
                <Col>
                  <div className={`percent-num ${data.summary_order_quantity.order_quantity_change >= 0 ? 'green-text' : 'red-text'}`}>
                    <i className={`fa fa-${data.summary_order_quantity.order_quantity_change >= 0 ? 'caret-up' : 'caret-down'}`} style={{ paddingRight: '10px' }} color="#07b53b" />
                    {data.summary_order_quantity.order_quantity_change >= 0
                      ? `+${data.summary_order_quantity?.order_quantity_change?.toFixed(1) || 0}%`
                      : `${data.summary_order_quantity?.order_quantity_change?.toFixed(1) || 0}%`}
                  </div>
                  <div className="light-grey-text-dashboard textInterval">{this.textInterval()}</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}
export default inject('dashboard')(observer(AverageChart));
