/* global FormData */
import moment from 'moment';
import swal from 'sweetalert2';
import BaseStore from './BaseStore';
import { validation } from '../utils/validation';
import { config } from '../config';
import { http } from '../utils/http';
import { file as fileUtil } from '../utils/file';

export class ChannelStore extends BaseStore {
  constructor() {
    super();
    this.observable(this.getInitialData());
  }

  getInitialData() {
    return {
      loading: false,
      admin_channel_uid: '',
      defaultChannelFee: {},
      channel: {
        uid: '',
        ccpp_account: 'npay',
        name: '',
        logo_url: '',
        logo_preview: '',
        webhook_url: '',
        file_ref_url: '',
        installment: {
          name: '',
          minimum_amount: 1000,
          cards: [],
          start_date: new Date(),
          end_date: new Date(),
        },
        installments: [],
        channel_fee: {
          // NOTICE: use table channel_payment_methods
          // credit_card: '',
          // bill_payment: '',
          // mobile_banking: '',
          // installment: '',
          // scg_wallet: '',
          // line: '',
          // bbl: '',
          // credit_card_percent: '',
          // bill_payment_percent: '',
          // mobile_banking_percent: '',
          // installment_percent: '',
          // scg_wallet_percent: '',
          // line_percent: '',
          // bbl_percent: '',
          // credit_card_status: '',
          // bill_payment_status: '',
          // mobile_banking_status: '',
          // installment_status: '',
          // scg_wallet_status: '',
          // line_status: '',
          // bbl_status: '',
          //
          //
          // NOTICE: use system config defualt and maximum due date
          // credit_card_due_default: '',
          // bill_payment_due_default: '',
          // mobile_banking_due_default: '',
          // installment_due_default: '',
          // scg_wallet_due_default: '',
          // line_due_default: '',
          // bbl_due_default: '',
          // credit_card_due_maximum: '',
          // bill_payment_due_maximum: '',
          // mobile_banking_due_maximum: '',
          // installment_due_maximum: '',
          // scg_wallet_due_maximum: '',
          // line_due_maximum: '',
          // bbl_due_maximum: '',
        },
        channel_payment: {
          credit_card: false,
          bill_payment: false,
          mobile_banking: false,
          installment: false,
          scg_wallet: false,
          line: false,
          chang_family_wallet: false,
          bbl: false,
          promptpay: false,
          grabpay: false,
          cbdc: false,
          bbl_tax: '',
        },
        channel_contract: [
          {
            id: '',
            name: '',
            email: '',
            phone: '',
            position: '',
            type: '',
            channel_id: 0,
          },
        ],
        channel_payment_methods: [],
        channel_due_date: {},
        // channel_qrcode: '',
        channel_tax_suffix: '',
        card_options: [],
      },
      channels: {
        all: [],
        page: 1,
        size: 10,
        total: 1,
        items: [],
      },
      card_options: [],
      camp_option: [],
      editable: false,
      due_editable: false,
      fee_editable: false,
      payment_editable: false,
      channel_contract_state: [],
      validation: {
        phone: true,
        email: true,
      },
      logs: [],
      filter: {
        channel_selected: null,
        campaign_card_selection: [],
        campaign_status_selection: [],
        campaign_start_date: '',
        campaign_end_date: '',
        campaign_create_date: '',
        campaign_date_selected: '',
        validate: false,
      },
      campError: [],
      campCopyInstallId: {},
      channels_mid: [],
      channels_mid_options: [],
      channels_mid_opn: [],
      channels_mid_opn_options: [],
      channels_mid_errors: [],
    };
  }

  changeAdminChannelUid(val) {
    this.admin_channel_uid = val;
  }

  changeCampCopyInstallId(val) {
    let { channel } = this.getData();
    this.channel.installment.cards = [];
    this.campCopyInstallId.value = val.value;
    this.campCopyInstallId.label = val.label;
    this.channel.installment.cards = channel.installments
      .filter(i => i.id === val.value)[0]
      .cards.map(function (s) {
        return { minimum_amount: s.minimum_amount || 0, abbr: s.abbr, absorb_interest_rate: s.absorb_interest_rate, terms: s.terms };
      });
  }

  changeCampName(txt) {
    this.channel.installment.name = txt;
  }

  changedateSelected(selected) {
    let obj = { value: selected ? selected.value : null, label: selected ? selected.label : null };
    this.filter.campaign_date_selected = selected ? obj : null;
  }

  clearExportFilter() {
    this.filter.campaign_start_date = null;
    this.filter.campaign_end_date = null;
    this.filter.campaign_create_date = null;
    this.filter.campaign_card_selection = [];
    this.filter.campaign_status_selection = [];
    this.filter.campaign_date_selected = '';
    this.fetchChannelSettings();
  }

  changeExportFilterStartDate(val) {
    this.filter.campaign_start_date = val;
    if (!this.filter.campaign_end_date) {
      this.filter.campaign_end_date = this.filter.campaign_start_date;
    }
  }

  toggleCampaignCardSelection(selected) {
    const index = this.filter.campaign_card_selection.indexOf(selected);
    if (index < 0) {
      this.filter.campaign_card_selection.push(selected);
    } else {
      this.filter.campaign_card_selection.splice(index, 1);
    }
    this.filter.campaign_card_selection = [...this.filter.campaign_card_selection];
  }

  toggleCampaignStatusSelection(selected) {
    const index = this.filter.campaign_status_selection.indexOf(selected);
    if (index < 0) {
      this.filter.campaign_status_selection.push(selected);
    } else {
      this.filter.campaign_status_selection.splice(index, 1);
    }
    this.filter.campaign_status_selection = [...this.filter.campaign_status_selection];
  }

  changeExportFilterEndDate(val) {
    this.filter.campaign_end_date = val;
    if (!this.filter.campaign_start_date) {
      this.filter.campaign_start_date = this.filter.campaign_end_date;
    }
  }

  changeExportFilterCreateDate(val) {
    this.filter.campaign_create_date = val;
  }

  changeFilterSellerName(name) {
    this.filter.seller_name = name;
  }

  searchSellerByFilter() {
    this.fetchChannelSettings();
  }

  clearChannel() {
    let { channel } = this.getInitialData();
    this.seller = channel;
    this.validation = {
      phone: true,
      email: true,
    };
  }

  async onExcelExport() {
    try {
      this.loading = true;
      let { filter } = this.getData();

      let body = filter;
      let param = `${config.npay.apiUrl}/excel/campaign`;
      let payments = await http.post(param, { body: body });
      if (payments.status === 200) {
        let body = await payments.json();
        window.open(body.data.url);
      }
    } catch (err) {
      console.error(err);
      swal.error('Error', 'Export ข้อมูลไม่สำเร็จ');
    } finally {
      this.loading = false;
    }
  }

  async validateEmail() {
    this.channel.channel_contract.forEach(item => {
      if (!validation.email(item.email)) {
        this.validation.email = false;
      }
    });
  }

  async validatePhone() {
    this.channel.channel_contract.forEach(item => {
      if (!validation.phone(item.phone)) {
        this.validation.phone = false;
      }
    });
  }

  async validateUpdateContract() {
    await this.validatePhone();
    await this.validateEmail();
  }

  validateDefaultChannelFee(channelFees) {
    let keys = Object.keys(channelFees);
    let passed = true;
    for (let key of keys) {
      passed = passed && channelFees[key] !== null;
    }

    return passed;
  }

  validateChannelFee(channelFees) {
    if (!channelFees) return false;

    let passed = true;
    for (const key in channelFees) {
      if (channelFees.hasOwnProperty(key)) {
        const element = channelFees[key];
        passed = passed && !(element < this.defaultChannelFee[key]);
      }
    }
    return passed;
  }

  selectPage(page, inputs = {}) {
    this.channels.page = page;
    this.fetchChannels(inputs);
  }

  async fetchChannels(inputs = {}) {
    try {
      this.loading = true;
      let page = this.channels.page;
      let size = this.channels.size;

      let query = `page=${page}&size=${size}`;
      if (inputs.channelName) query += `&channel_name=${inputs.channelName}`;
      if (inputs.companyName) query += `&company_name=${inputs.companyName}`;
      if (inputs.statusSelected) query += `&status=${inputs.statusSelected.value}`;
      if (inputs.channelUid) query += `&uid=${inputs.channelUid}`;
      if (inputs.taxId) query += `&tax_id=${inputs.taxId}`;

      let url = `${config.npay.apiUrl}/admin/channels?${query}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.channels.total = body.total;
        this.channels.items = body.data;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async fetchChannelAll() {
    try {
      this.loading = true;

      let url = `${config.npay.apiUrl}/admin/channels/all`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.channels.all = body.data;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async fetchChannelSettings() {
    try {
      this.camp_option = [];
      let { filter } = this.getData();
      let body = filter;
      let validateCampaigDate = true;
      if (body.campaign_start_date || body.campaign_end_date) {
        if (!this.filter.campaign_date_selected.value) {
          validateCampaigDate = false;
        }
      }
      if (!validateCampaigDate) {
        swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'Error!',
          text: 'โปรดเลือกประเภทช่วงเวลาการค้นหา',
          confirmButtonText: 'OK',
        });
        throw new Error();
      }

      body.campaign_start_date = moment(body.campaign_start_date).add(config.time_calibate.UTC, 'hours').valueOf();
      body.campaign_end_date = moment(body.campaign_end_date).add(config.time_calibate.UTC, 'hours').valueOf();
      body.campaign_create_date = moment(body.campaign_create_date).add(config.time_calibate.UTC, 'hours').valueOf();
      body.campaign_date_selected = this.filter.campaign_date_selected.value;
      this.loading = true;
      let url = `${config.npay.apiUrl}/channels/settings/condition`;
      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        if (body.data.installment[0]) {
          for (let card of body.data.installment[0].cards) {
            this.hideCardOption(card.abbr);
          }
        }
        body.data.installment.forEach(item => {
          item.start_date = item.start_date ? moment(item.start_date).subtract(config.time_calibate.UTC, 'hours').valueOf() : null;
          item.end_date = item.end_date ? moment(item.end_date).subtract(config.time_calibate.UTC, 'hours').valueOf() : null;
          let cOp = { value: item.id, label: item.name };
          this.camp_option.push(cOp);
        });
        this.channel = body.data;
        this.channel.installments = body.data.installment;
        this.channel_contract_state = body.data.channel_contract;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async getSystemCardOptions() {
    try {
      let url = `${config.npay.apiUrl}/channels/settings/systemcardoptions`;
      let res = await http.get(url);
      if (res.status === 200) {
        let body = await res.json();
        return this.createCardOptions(body.data);
      }
    } catch (error) {
      throw error;
    }
  }

  createCardOptions(input) {
    // console.log('createCardOptions');
    let result = input.map(item => {
      let arrTerms = item.terms.split(',');
      let objTerms = [];
      objTerms = arrTerms.map(i => {
        return { term: i, checked: false };
      });

      return {
        value: item.abbr,
        label: item.name,
        selected: false,
        absorb_interest_rate: item.absorb_interest_rate,
        minimum_amount: item.minimum_amount,
        terms: objTerms,
      };
    });

    return result;
  }

  viewInstallmentDetail(id) {
    this.channel.installment = this.channel.installments.filter(i => i.id === id)[0];
    this.camp_option = this.camp_option.filter(a => a.value !== id);
  }

  viewNewInstallmentDetail() {
    this.channel.installment = {
      minimum_amount: 1000,
      cards: [],
      start_date: new Date(),
      end_date: new Date(),
    };
  }

  async getChannelByUid(uid) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/channels/${uid}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.channel = body.data;
        console.log(this.channel);
        if (!this.channel.channel_fee) {
          this.channel.channel_fee = {
            // fixed cost fee
            credit_card: '',
            bill_payment: '',
            mobile_banking: '',
            installment: '',
            scg_wallet: '',
            line: '',
            chang_family_wallet: '',
            bbl: '',
            promptpay: '',
            grabpay: '',
            cbdc: '',
            // percent fee
            credit_card_percent: '',
            bill_payment_percent: '',
            mobile_banking_percent: '',
            installment_percent: '',
            scg_wallet_percent: '',
            line_percent: '',
            chang_family_wallet_percent: '',
            bbl_percent: '',
            promptpay_percent: '',
            grabpay_percent: '',
            cbdc_percent: '',
            // fee type
            credit_card_status: '',
            bill_payment_status: '',
            mobile_banking_status: '',
            installment_status: '',
            scg_wallet_status: '',
            line_status: '',
            chang_family_wallet_status: '',
            bbl_status: '',
            promptpay_status: '',
            grabpay_status: '',
            cbdc_status: '',
          };
        }
        if (!this.channel.channel_payment) {
          // payment method options
          this.channel.channel_payment = {
            credit_card: false,
            bill_payment: false,
            mobile_banking: false,
            installment: false,
            scg_wallet: false,
            line: false,
            chang_family_wallet: false,
            bbl: false,
            promptpay: false,
            grabpay: false,
            cbdc: false,
            bbl_tax: '',
          };
        }
        if (!this.channel.channel_due_date) {
          this.channel.channel_due_date = {
            default_due_date: 3,
            maximum_due_date: 30,
          };
        }
        if (!this.channel.channel_tax_suffix) {
          this.channel.channel_tax_suffix = '';
        }
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async getChannelSettingByUid(uid) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/channel/setting`;
      let body = { uid };
      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        this.channel = body.data;
        if (!this.channel.channel_fee) {
          this.channel.channel_fee = {
            // fixed cost fee
            credit_card: '',
            bill_payment: '',
            mobile_banking: '',
            installment: '',
            scg_wallet: '',
            line: '',
            chang_family_wallet: '',
            bbl: '',
            promptpay: '',
            grabpay: '',
            cbdc: '',
            // percent fee
            credit_card_percent: '',
            bill_payment_percent: '',
            mobile_banking_percent: '',
            installment_percent: '',
            scg_wallet_percent: '',
            line_percent: '',
            chang_family_wallet_percent: '',
            bbl_percent: '',
            promptpay_percent: '',
            grabpay_percent: '',
            cbdc_percent: '',
            // fee type
            credit_card_status: '',
            bill_payment_status: '',
            mobile_banking_status: '',
            installment_status: '',
            scg_wallet_status: '',
            line_status: '',
            chang_family_wallet_status: '',
            bbl_status: '',
            promptpay_status: '',
            grabpay_status: '',
            cbdc_status: '',
          };
        }
        if (!this.channel.channel_payment) {
          // payment method options
          this.channel.channel_payment = {
            credit_card: false,
            bill_payment: false,
            mobile_banking: false,
            installment: false,
            scg_wallet: false,
            line: false,
            chang_family_wallet: false,
            bbl: false,
            promptpay: false,
            grabpay: false,
            cbdc: false,
            bbl_tax: '',
          };
        }
        if (!this.channel.channel_due_date) {
          this.channel.channel_due_date = {
            default_due_date: 3,
            maximum_due_date: 30,
          };
        }
        if (!this.channel.channel_tax_suffix) {
          this.channel.channel_tax_suffix = '';
        }
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async updateChannelDetail() {
    try {
      this.loading = true;
      let updateData = this.channel;

      let valid = this.validateChannelFee(updateData.channel_fee);
      if (valid) {
        let url = `${config.npay.apiUrl}/admin/channels/${updateData.uid}`;
        let response = await http.put(url, { body: updateData });

        if (response.status === 200) {
          window.location.href = `${config.web.rootpath}/channels/detail/${updateData.uid}`;
        } else {
          let body = await response.json();
          swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'Error!',
            text: body.message,
            confirmButtonText: 'OK',
          });
        }
      } else {
        const errMessage =
          '<div class="w-75 mx-auto mt-2">' +
          '<p><strong>Channel fee does not match the minimum of criteria:</strong></p>' +
          '<ul class="text-left">' +
          '<li><small>Credit Card</small> <strong>' +
          this.defaultChannelFee.credit_card +
          '%</strong> <small>(percent)</small></li>' +
          '<li><small>Installment</small> <strong>' +
          this.defaultChannelFee.installment +
          '%</strong> <small>(percent)</small></li>' +
          '<li><small>Bill Payment</small> <strong>' +
          this.defaultChannelFee.bill_payment +
          '฿</strong> <small>(THB)</small></li>' +
          '<li><small>Mobile Banking</small> <strong>' +
          this.defaultChannelFee.mobile_banking +
          '฿</strong> <small>(THB)</small></li>' +
          '<li><small>SCG Wallet</small> <strong>' +
          this.defaultChannelFee.scg_wallet +
          '฿</strong> <small>(THB)</small></li>' +
          '<li><small>Thai QR Payment</small> <strong>' +
          this.defaultChannelFee.promptpay +
          '฿</strong> <small>(THB)</small></li>' +
          '<li><small>GrabPay</small> <strong>' +
          this.defaultChannelFee.grabpay +
          '%</strong> <small>(percent)</small></li>' +
          '<li><small>Chang Family Wallet</small> <strong>' +
          this.defaultChannelFee.chang_family_wallet +
          '%</strong> <small>(percent)</small></li>' +
          '<li><small>Line</small> <strong>' +
          this.defaultChannelFee.line +
          '%</strong> <small>(percent)</small></li>' +
          '<li><small>CBDC Payment</small> <strong>' +
          this.defaultChannelFee.cbdc +
          '฿</strong> <small>(THB)</small></li>' +
          '<li><small>Mobile Banking (App Switch)</small> <strong>' +
          this.defaultChannelFee.mobile_banking_opn +
          '฿</strong> <small>(THB)</small></li>' +
          '</ul>' +
          '</div>';

        swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'Error!',
          html: errMessage,
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  /*
  async fetchDefaultChannelFee() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/channels/fee`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.channel.channel_fee = {
          credit_card: body.data.credit_card,
          bill_payment: body.data.bill_payment,
          mobile_banking: body.data.mobile_banking,
          installment: body.data.installment,
          scg_wallet: body.data.scg_wallet,
          line: body.data.line,
        };

        this.defaultChannelFee = { ...this.channel.channel_fee, prop: this.defaultChannelFee };
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async updateDefaultChannelFee() {
    try {
      let updateData = this.channel;

      let valid = this.validateDefaultChannelFee(updateData.channel_fee);
      if (valid) {
        let result = await swal.fire({
          text: 'ยืนยันการเปลี่ยนแปลงค่าบริการสำหรับทุก Channel',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#fbc658',
          confirmButtonText: 'Confirm',
        });
        if (result.value) {
          this.loading = true;
          let url = `${config.npay.apiUrl}/admin/channels/fee`;
          let response = await http.put(url, { body: updateData.channel_fee });

          if (response.status === 200) {
            window.location.href = `${config.web.rootpath}/channels/channelfee`;
          } else {
            let body = await response.json();
            swal.fire({
              type: 'error',
              title: 'Error!',
              text: body.message,
              confirmButtonText: 'OK',
            });
          }
        }
      } else {
        swal.fire({
          type: 'error',
          title: 'Error!',
          text: 'All fields are required',
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }
  */

  hideCardOption(abbr) {
    // TODO: should move hide card option to campaign create/update
    let card = this.card_options.find(option => option.value === abbr);
    // card.selected = true;
  }

  showCardOption(abbr) {
    // TODO: should move show card option to campaign create/update
    let card = this.card_options.find(option => option.value === abbr);
    // card.selected = false;
  }

  changeChannelName(name) {
    this.channel.name = name;
  }

  changeGatewayAccount(account) {
    this.channel.ccpp_account = account;
  }

  changeWebhookUrl(url) {
    this.channel.webhook_url = url;
  }

  changeChannelFee(val, key, field) {
    const method = this.channel.channel_payment_methods.find(c => c.code === key);
    method[field] = val;
  }

  changeChannelDue(val, key) {
    // all payment due date
    this.channel.channel_payment_methods = this.channel.channel_payment_methods.map(c => {
      c[key] = val;
      return c;
    });
    // system payment due date
    if (key === 'default_due_date') {
      this.channel.channel_due_date.default_due_date = val;
    } else if (key === 'maximum_due_date') {
      this.channel.channel_due_date.maximum_due_date = val;
    }
  }

  changePaymentStatus(val, key, field = 'option') {
    const method = this.channel.channel_payment_methods.find(c => c.code === key);
    method[field] = val;
  }

  async changeLogoImage(file) {
    let compressed = await fileUtil.compressImage(file);
    this.channel.logo_url = compressed;
    this.channel.logo_preview = URL.createObjectURL(compressed);
  }

  async createChannel() {
    try {
      this.loading = true;
      let data = this.getData();
      let body = data.channel;

      // TODO: change channel_fee to new structure
      let valid = this.validateChannelFee(body.channel_fee);
      if (valid) {
        let url = `${config.npay.apiUrl}/admin/channels`;
        let response = await http.post(url, { body });
        switch (response.status) {
          case 200:
            let body_ = await response.json();
            try {
              this.loading = true;
              let file = this.channel.file_ref_url;
              if (!file) {
                console.warn('No file to upload');
              } else {
                let formData = new FormData();
                formData.append('file_ref_url', file, file.name);
                let url = `${config.npay.apiUrl}/import/pdf/${body_.data.uid}`;
                await http.post(url, { body: formData });
              }
            } catch (err) {
              console.error(err);
            } finally {
              setTimeout(() => {
                this.loading = false;
              }, 100);
            }
            window.location.href = `${config.web.rootpath}/channels`;
            break;
          default:
            let body = await response.json();
            throw new Error(body.message);
        }
      } else {
        const errMessage =
          '<div class="w-75 mx-auto mt-2">' +
          '<p><strong>Channel fee does not match the minimum of criteria:</strong></p>' +
          '<ul class="text-left">' +
          '<li><small>Credit Card</small> <strong>' +
          this.defaultChannelFee.credit_card +
          '%</strong> <small>(percent)</small></li>' +
          '<li><small>Installment</small> <strong>' +
          this.defaultChannelFee.installment +
          '%</strong> <small>(percent)</small></li>' +
          '<li><small>Bill Payment</small> <strong>' +
          this.defaultChannelFee.bill_payment +
          '฿</strong> <small>(THB)</small></li>' +
          '<li><small>Mobile Banking</small> <strong>' +
          this.defaultChannelFee.mobile_banking +
          '฿</strong> <small>(THB)</small></li>' +
          '<li><small>SCG Wallet</small> <strong>' +
          this.defaultChannelFee.scg_wallet +
          '฿</strong> <small>(THB)</small></li>' +
          '<li><small>Thai QR Payment</small> <strong>' +
          this.defaultChannelFee.promptpay +
          '฿</strong> <small>(THB)</small></li>' +
          '<li><small>GrabPay</small> <strong>' +
          this.defaultChannelFee.grabpay +
          '%</strong> <small>(percent)</small></li>' +
          '<li><small>Chang Family Wallet</small> <strong>' +
          this.defaultChannelFee.chang_family_wallet +
          '%</strong> <small>(percent)</small></li>' +
          '<li><small>CBDC Payment</small> <strong>' +
          this.defaultChannelFee.cbdc +
          '฿</strong> <small>(THB)</small></li>' +
          '<li><small>Mobile Banking (App Switch)</small> <strong>' +
          this.defaultChannelFee.mobile_banking_opn +
          '฿</strong> <small>(THB)</small></li>' +
          '</ul>' +
          '</div>';

        swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'Error!',
          html: errMessage,
          confirmButtonText: 'OK',
        });
      }
      return;
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async deleteChannelById(id) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/channels/${id}`;
      let response = await http.delete(url);

      switch (response.status) {
        case 200:
          window.location.href = `${config.web.rootpath}/channels`;
          break;
        default:
          let body = await response.json();
          throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async renewToken() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/channels/settings/token`;
      let response = await http.put(url);
      if (response.status === 200) {
        let body = await response.json();
        this.channel = body.data;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async uploadLogoImage() {
    try {
      this.loading = true;
      let data = this.getData();

      if (data.channel.logo_url && typeof data.channel.logo_url === 'object') {
        let file = data.channel.logo_url;
        let formData = new FormData();
        formData.append('logo', file, file.name);

        let url = `${config.npay.apiUrl}/channels/settings/logo`;
        let response = await http.put(url, { body: formData });
        if (response.status === 200) {
          let body = await response.json();
          return body.data.url;
        } else {
          return '';
        }
      } else {
        return data.channel.logo_url;
      }
    } catch (err) {
      console.error(err);
      return '';
    }
  }

  // async updateChannelSettings() {
  //   try {
  //     let data = this.getData();
  //     let logoUrl = await this.uploadLogoImage();
  //     try {
  //       this.loading = true;
  //       let file = this.channel.file_ref_url;
  //       if (!file) {
  //         console.warn('No file to upload');
  //       } else {
  //         let formData = new FormData();
  //         formData.append('file_ref_url', file, file.name);
  //         let url = `${config.npay.apiUrl}/import/pdf/${data.channel.uid}`;
  //         await http.post(url, { body: formData });
  //       }
  //     } catch (err) {
  //       console.error(err);
  //     } finally {
  //       setTimeout(() => {
  //         this.loading = false;
  //       }, 100);
  //     }
  //     let json = {
  //       logo_url: logoUrl,
  //       webhook_url: data.channel.webhook_url,
  //     };
  //     let url = `${config.npay.apiUrl}/channels/settings`;
  //     let response = await http.put(url, { body: json });
  //     if (response.status === 200) {
  //       swal.fire({
  //         customClass: 'nexter-alert',
  //         type: 'success',
  //         title: 'Success!',
  //         text: 'บันทึกข้อมูลเรียบร้อย',
  //         confirmButtonText: 'OK',
  //       });

  //       let body = await response.json();
  //       if (body.data.installment.start_date && body.data.installment.end_date) {
  //         body.data.installment.start_date = moment(body.data.installment.start_date).valueOf();
  //         body.data.installment.end_date = moment(body.data.installment.end_date).set({ hour: 23, minute: 59, second: 59 }).valueOf();
  //       }
  //       this.channel = body.data;
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //     setTimeout(() => {
  //       this.loading = false;
  //     }, 100);
  //   }
  // }

  addInstallmentSetting(installment) {
    this.channel.installment.cards.push(installment);
    this.hideCardOption(installment.abbr);
  }

  deleteInstallmentSetting(abbr, terms, minimum_amount) {
    let data = this.getData();
    let cards = data.channel.installment.cards;
    this.channel.installment.cards = cards.filter(card => !(card.abbr === abbr && card.terms === terms && card.minimum_amount === minimum_amount));
  }

  changeMinimumAmount(amount) {
    this.channel.installment.minimum_amount = amount;
  }

  changeInstallmentStartDate(val) {
    this.channel.installment.start_date = val;

    if (this.channel.installment.start_date > this.channel.installment.end_date) {
      this.channel.installment.end_date = val;
    }
  }

  changeInstallmentEndDate(val) {
    this.channel.installment.end_date = val;
  }

  validateCamp() {
    let valid = true;
    this.campError = [];
    let msg = [];
    if (!this.channel.installment.name) {
      valid = false;
      msg.push('โปรดระบุชื่อ campaign');
    }
    if (!this.channel.installment.start_date && !this.channel.installment.end_date) {
      valid = false;
      msg.push('โปรดระบุชื่อ ช่วงเวลาของ campaign');
    }
    if (this.channel.installment.cards.length === 0) {
      valid = false;
      msg.push('โปรดเลือกบัตรที่ใช้ในการผ่อนชำระอย่างน้อย 1 ใบ');
    }
    this.campError = msg;
    return valid;
  }

  /*
  async checkBBLTaxStatus(bbl_tax) {
    try {
      return true;
    } catch (err) {
      console.error(err);
    }
  }
  */

  checkBBLQrcodeStatus(qrcode) {
    return true;
  }

  async saveInstallmentSetting(action) {
    try {
      this.loading = true;
      if (this.validateCamp()) {
        let { channel } = this.getData();
        let installment = channel.installment;

        let body = {
          id: installment.id || null,
          name: installment.name,
          cards: installment.cards.map(card => {
            return {
              minimum_amount: +card.minimum_amount,
              id: card.id,
              abbr: card.abbr,
              absorb_interest_rate: +card.absorb_interest_rate,
              terms: card.terms.split(','),
            };
          }),
        };

        if (installment.start_date && installment.end_date) {
          // body.start_date = moment(installment.start_date).add(config.time_calibate.UTC, 'hours').valueOf();
          // body.end_date = moment(installment.end_date).add(config.time_calibate.UTC, 'hours').valueOf();

          /**
            NOTICE: node-mssql have bug; 
            - when insert time for datetime to database, if insert 23:59:59, day will add 1 day when insert
            - when insert time for datetime to database, if insert 00:00:00, day will display with substract 1 day when select.
            So need insert with 12:00:00.000 then use logic in programming solve problem.
          */
          const dateStart = moment(installment.start_date).utcOffset(config.time_calibate.UTC).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
          const dateEnd = moment(installment.end_date).utcOffset(config.time_calibate.UTC).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });

          // console.log('date start:', dateStart.format('YYYY-MM-DD HH:mm:ss.SSS ZZ'));
          // console.log('date end:', dateEnd.format('YYYY-MM-DD HH:mm:ss.SSS ZZ'));

          body.start_date = dateStart.valueOf();
          body.end_date = dateEnd.valueOf();

          // console.log('Campaign date start', body.start_date);
          // console.log('Campaign date end', body.end_date);
        } else {
          throw new Error('ต้องตั้งค่า วันที่เริ่ม และ วันที่สิ้นสุด ของ Campaign');
        }

        let urlSave = `${config.npay.apiUrl}/channels/settings/installment`;
        let urlCampaignUpdate = `${config.npay.apiUrl}/channels/settings/campaign/status/force`;
        swal
          .fire({
            customClass: 'nexter-alert',
            title: 'ยืนยันการบันทึก',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'บันทึก',
            showLoaderOnConfirm: true,
            reverseButtons: true,
            type: 'question',
            preConfirm: () => {
              if (action === 'create') {
                return http
                  .post(urlSave, { body: body }) // insert method post
                  .then(response => Promise.all([response, response.json(), new Promise(resolve => setTimeout(resolve, 2000))]))
                  .then(([response, json]) => {
                    if (!response.ok) {
                      throw new Error(json.message);
                    }
                    if (installment.status === 'active' || !installment.status) {
                      http
                        .put(urlCampaignUpdate, { body: { id: json.data.id } })
                        .then(response_ => Promise.all([response_, response_.json()]))
                        .then(([response_, json_]) => {
                          if (!response_.ok) {
                            throw new Error(json_.message);
                          }
                          return json_.data;
                        })
                        .catch(error => {
                          swal.update({ customClass: 'nexter-alert', type: 'warning', text: '', cancelButtonText: 'ตกลง', confirmButtonColor: '#e20a1a' });
                          swal.showValidationMessage(`${error} สถานะของ Campaign นี้ ถูกปรับเป็น INACTIVE`.replace('Error: ', ''));
                        });
                    }
                  })
                  .catch(error => {
                    swal.update({ customClass: 'nexter-alert', type: 'warning', text: '', showConfirmButton: false, cancelButtonText: 'ตกลง', confirmButtonColor: '#e20a1a' });
                    swal.showValidationMessage(`${error}`.replace('Error: ', ''));
                  });
              } else if (action === 'update') {
                return http
                  .put(urlSave, { body: body }) // update method put
                  .then(response => Promise.all([response, response.json(), new Promise(resolve => setTimeout(resolve, 2000))]))
                  .then(([response, json]) => {
                    if (!response.ok) {
                      throw new Error(json.message);
                    }
                    if (installment.status === 'active' || !installment.status) {
                      http
                        .put(urlCampaignUpdate, { body: { id: json.data.id } })
                        .then(response_ => Promise.all([response_, response_.json()]))
                        .then(([response_, json_]) => {
                          if (!response_.ok) {
                            throw new Error(json_.message);
                          }
                          return json_.data;
                        })
                        .catch(error => {
                          swal.update({ type: 'warning', text: '', cancelButtonText: 'ตกลง' });
                          swal.showValidationMessage(`${error} สถานะของ Campaign นี้ ถูกปรับเป็น INACTIVE`.replace('Error: ', ''));
                        });
                    }
                  })
                  .catch(error => {
                    swal.update({ type: 'warning', text: '', showConfirmButton: false, cancelButtonText: 'ตกลง' });
                    swal.showValidationMessage(`${error}`.replace('Error: ', ''));
                  });
              } else {
                return () => {
                  swal.update({ type: 'warning', text: '', cancelButtonText: 'ตกลง' });
                  swal.showValidationMessage(`${error} ระบบไม่สามารถ สร้าง หรือ บันทึก ข้อมูลได้`.replace('Error: ', ''));
                };
              }
            },
            allowOutsideClick: () => !swal.isLoading(),
          })
          .then(result => {
            if (result.value) {
              swal
                .fire({
                  customClass: 'nexter-alert',
                  type: 'info',
                  text: 'บันทึกข้อมูลเรียบร้อย ',
                  showCancelButton: true,
                  cancelButtonText: 'ยกเลิก',
                  confirmButtonText: `กลับไปหน้า Campaign`,
                  reverseButtons: true,
                })
                .then(result => {
                  if (result.value) {
                    window.location.href = `${config.web.rootpath}/channel-setting`;
                  }
                });
            }
          });
      } else {
        swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: this.campError.join(', '),
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  changeFile(file, key) {
    this.channel.file_ref_url = file;
  }

  selectPDFFiles(files) {
    this.channel.file_ref_url = files;
  }

  addChannelContract() {
    this.channel.channel_contract.push({
      name: '',
      email: '',
      phone: '',
      position: '',
      type: '',
      channel_id: 0,
    });
  }

  changeChannelContract(val, key, i) {
    this.channel.channel_contract[i][key] = val;
  }

  removeChannelContract(i) {
    if (this.channel.channel_contract.length > 1) {
      this.channel.channel_contract.splice(i, 1);
    }
  }

  async updateChannelSettingsContract() {
    await this.validateUpdateContract();
    let arrayValidation = Object.values(this.validation);
    let someInvalid = arrayValidation.some(el => {
      return el === false;
    });
    if (!someInvalid) {
      try {
        let prepareContract = {
          channel_contract: this.channel.channel_contract,
          channel_contract_state: this.channel_contract_state,
        };
        let url = `${config.npay.apiUrl}/channels/settings/contract`;
        let response = await http.put(url, { body: prepareContract });
        if (response.status === 200) {
          swal.fire({
            customClass: 'nexter-alert',
            type: 'success',
            title: 'Success!',
            html: 'บันทึกข้อมูลเรียบร้อย',
            confirmButtonText: 'OK',
          });
          this.channel_contract_state = this.channel.channel_contract;
        }
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 100);
      }
    } else {
      swal.fire({
        customClass: 'nexter-alert',
        title: 'Error!',
        text: 'โปรดตรวจสอบข้อมูล',
        type: 'error',
        confirmButtonText: 'OK',
      });
      this.clearChannel();
    }
  }

  async getHistoryLogsByChannel() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/historylogs`;
      let body = {
        parent: 'channel',
        parent_id: this.channel.id,
        entity: [
          'channels',
          'channels_contract',
          'default_channel_fee',
          'channel_fee',
          // 'installments',
          // 'installment_cards',
        ],
      };

      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        this.logs = body.data || [];
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async getHistoryLogsByChannelCamp() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/historylogs`;
      let body = {
        parent: 'channel',
        parent_id: this.channel.id,
        entity: [
          // 'channels',
          // 'channels_contract',
          // 'default_channel_fee',
          // 'channel_fee',
          'installments',
          'installment_cards',
        ],
      };

      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        this.logs = body.data || [];
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async getChannelPaymentMethod() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/channels/paymentmethods`;
      let response = await http.get(url, { body: json });
      if (response.status === 200) {
        this.channel_payment_methods = body.data;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async updateChannelPaymentMethod() {
    try {
      this.loading = true;
      let data = this.getData();
      let json = {
        id: data.channel.id,
        payment_methods: data.channel.channel_payment_methods,
        tax_suffix: data.channel.channel_tax_suffix,
        due_date: data.channel.channel_due_date,
      };
      let url = `${config.npay.apiUrl}/channels/paymentmethods`;
      let response = await http.post(url, { body: json });
      if (response.status === 200) {
        swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          title: 'Success!',
          text: 'บันทึกข้อมูลเรียบร้อย',
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async fetchMIDChannel(props) {
    try {
      const orderBy = props?.orderBy || 'id';
      this.loading = true;
      const url = `${config.npay.apiUrl}/admin/mid?order_by=${orderBy}`;
      const response = await http.get(url);
      const results = await response.json();
      const mids = results.channels_mid || [];
      this.channels_mid = mids;
      this.channels_mid_options = mids
        .filter(mid => mid.active === true)
        .map(mid => ({
          label: mid.label,
          value: mid.code,
        }));
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async createChannelMID(payload) {
    try {
      this.loading = true;
      this.channels_mid_errors = [];
      const url = `${config.npay.apiUrl}/admin/mid/created`;
      const body = { payload };
      const response = await http.post(url, { body });
      if (response.status >= 400) {
        const result = await response.json();
        const errors = result?.errors || [];
        this.channels_mid_errors = errors;
        if (errors.length === 0) {
          return swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'Error!',
            text: result.message,
            confirmButtonText: 'OK',
          });
        }
        return;
      }
      return swal.fire({
        customClass: 'nexter-alert',
        type: 'success',
        title: 'Success!',
        text: 'The information has been create.',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  async updateChannelMID(id, payload) {
    try {
      this.loading = true;
      this.channels_mid_errors = [];
      const url = `${config.npay.apiUrl}/admin/mid/updated`;
      const body = { id, payload };
      const response = await http.put(url, { body });

      if (response.status >= 400) {
        const result = await response.json();
        const errors = result?.errors || [];
        this.channels_mid_errors = errors;
        if (errors.length === 0) {
          return swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'Error!',
            text: result.message,
            confirmButtonText: 'OK',
          });
        }
        return;
      }
      return swal.fire({
        customClass: 'nexter-alert',
        type: 'success',
        title: 'Success!',
        text: 'The information has been updated.',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  async fetchMIDOpnChannel(props) {
    try {
      const orderBy = props?.orderBy || 'id';
      this.loading = true;
      const url = `${config.npay.apiUrl}/admin/mid-opn?order_by=${orderBy}`;
      const response = await http.get(url);
      const results = await response.json();
      const mids = results.channels_mid_opn || [];
      this.channels_mid_opn = mids;
      this.channels_mid_opn_options = mids
        .filter(mid => mid.active === true)
        .map(mid => ({
          label: mid.label,
          value: mid.code,
        }));
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async createChannelMIDOpn(payload) {
    try {
      this.loading = true;
      this.channels_mid_errors = [];
      const url = `${config.npay.apiUrl}/admin/mid-opn/created`;
      const body = { payload };
      const response = await http.post(url, { body });
      if (response.status >= 400) {
        const result = await response.json();
        const errors = result?.errors || [];
        this.channels_mid_errors = errors;
        if (errors.length === 0) {
          return swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'Error!',
            text: result.message,
            confirmButtonText: 'OK',
          });
        }
        return;
      }
      return swal.fire({
        customClass: 'nexter-alert',
        type: 'success',
        title: 'Success!',
        text: 'The information has been created.',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  async updateChannelMIDOpn(id, payload) {
    try {
      this.loading = true;
      this.channels_mid_errors = [];
      const url = `${config.npay.apiUrl}/admin/mid-opn/updated`;
      const body = { id, payload };
      const response = await http.put(url, { body });

      if (response.status >= 400) {
        const result = await response.json();
        const errors = result?.errors || [];
        this.channels_mid_errors = errors;
        if (errors.length === 0) {
          return swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'Error!',
            text: result.message,
            confirmButtonText: 'OK',
          });
        }
        return;
      }
      return swal.fire({
        customClass: 'nexter-alert',
        type: 'success',
        title: 'Success!',
        text: 'The information has been updated.',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }
}
