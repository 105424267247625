import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import _ from 'lodash';
import { Col, Table, CardFooter } from 'reactstrap';
import { Page } from '../../components/Page';
import { Pagination } from '../../components/Pagination/Pagination';
import iNoData from '../../assets/img/icon-no-data.svg';
import { config } from '../../config';
import { EmptyRow } from '../../components/Table/EmptyRow';
import { getOpnRecipientStatus } from '../../utils/opn';

class SellerPendingTable extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.seller.pagePending = 1;
    this.props.seller.fetchChannelSellers('pending');
  }

  selectPage(page) {
    const elem = document.getElementsByClassName('content-container');
    elem[0].scrollTop = 0;
    this.props.seller.pagePending = page;
    this.props.seller.fetchChannelSellers('pending');
  }

  detailSellerPage(uid, channel_uid) {
    this.props.seller.getSellerByUid(uid, channel_uid);
    this.props.seller.countSellers(uid);
    this.props.seller.changeSellerMgmtView('detail');
  }

  async detailSellerPage2(id, channel_uid) {
    await this.props.seller.getSellerById(id, channel_uid);
    // this.props.seller.countSellers(uid);
    this.props.seller.changeSellerMgmtView('detail');
  }

  render() {
    const { pendingChannelSellers, pendingSellersTotal } = this.props.seller.getData();
    const { loggedinUser } = this.props.user.getData();

    const pageSize = 10;
    const isP8team = ['super_admin', 'biz_system', 'ae_marketing', 'manager'].includes(loggedinUser.type);

    const rows = pendingChannelSellers.map((item, index) => {
      const keyType = `kyc_${item.seller.kyc_type}`;

      let channelUrl = `${config.web.rootpath}/channels/detail/${item.created_by || ''}`;
      let sellerUrl = `${config.web.rootpath}/sellers/detail/${item.created_by}/${item.seller.uid || ''}`;
      let displayChannelUid = item.created_by || '-';
      if (loggedinUser?.channel?.uid) {
        channelUrl = `${config.web.rootpath}/channel-setting`;
        displayChannelUid = loggedinUser?.channel?.uid;
      }

      return (
        <tr key={index}>
          <td className="td-seller-code">
            <Link style={{ color: '#007bff' }} to={sellerUrl}>
              {item.seller_code || <span className="data-is-null">-</span>}
            </Link>
          </td>
          <td className="td-channel-id">
            {item.channel && item.channel.name ? (
              <a target="_blank" style={{ color: '#007bff' }} href={channelUrl}>
                {item.channel.name}
              </a>
            ) : (
              <span className="data-is-null">-</span>
            )}
          </td>
          <td className="td-company-name">{item.seller.name || <span className="data-is-null">-</span>}</td>
          <td className="td-status-ac text-uppercase text-status">
            {item.seller.sellers_account != null ? (
              <>
                <i className={`far fa-dot-circle ${item.seller.sellers_account.status} mr-1`}></i>
                {item.seller.sellers_account.status}
              </>
            ) : (
              <span className="data-is-null">-</span>
            )}
          </td>
          <td className="td-status-ac text-uppercase text-status">
            {!!item.channel?.opn_account ? (
              <>
                <i className={`far fa-dot-circle ${getOpnRecipientStatus(item.seller.sellers_account.status, item.opn_recipient?.status)} mr-1`}></i>
                {getOpnRecipientStatus(item.seller.sellers_account.status, item.opn_recipient?.status)}
              </>
            ) : (
              <span className="data-is-null">-</span>
            )}
          </td>
          <td className="td-status-kyc text-uppercase text-status">
            {item.seller[keyType] != null ? (
              <>
                <i className={`far fa-dot-circle ${item.seller[keyType].status} mr-1`}></i>
                {item.seller[keyType].status}
              </>
            ) : (
              <span className="data-is-null">-</span>
            )}
          </td>
          <td className="td-seller-type text-capitalize">
            {item.seller.type ? (
              item.seller.type === 'partner' ? (
                'Seller'
              ) : item.seller.type === 'channel' ? (
                'Channel'
              ) : (
                <span className="data-is-null">-</span>
              )
            ) : (
              <span className="data-is-null">-</span>
            )}
          </td>
          <td className="td-bank-name text-capitalize">{item.seller.sellers_account != null ? item.seller.sellers_account.bank_name : <span className="data-is-null">-</span>}</td>
          <td className="td-phone">{item.seller.phone_number || <span className="data-is-null">-</span>}</td>
          {isP8team && (
            <>
              <td className="td-export text-center">
                {item.seller.export_at_first ? moment(item.seller.export_at_first).format('YYYY-MM-DD') : <span className="data-is-null">-</span>}
                <br />
                {item.seller.export_at_first ? moment(item.seller.export_at_first).format('HH:mm:ss') : ''}
              </td>
              <td className="td-export text-center">
                {item.seller.export_at ? moment(item.seller.export_at).format('YYYY-MM-DD') : <span className="data-is-null">-</span>}
                <br />
                {item.seller.export_at ? moment(item.seller.export_at).format('HH:mm:ss') : ''}
              </td>
            </>
          )}
        </tr>
      );
    });

    return (
      <Page>
        <div className="table-reponsive table-scroll">
          <Table className="seller-table">
            <thead className="thead-seller">
              <tr>
                <th className="td-seller-code text-capitalize border-0">Seller Code</th>
                <th className="td-channel-id text-capitalize border-0">Channel Name</th>
                <th className="td-company-name text-capitalize border-0">Company Name / Name</th>
                <th className="td-status-ac text-capitalize border-0">สถานะบัญชี</th>
                <th className="td-status-ac text-capitalize border-0">สถานะบัญชี OPN</th>
                <th className="td-status-kyc text-capitalize border-0">สถานะ KYC</th>
                <th className="td-seller-type text-capitalize border-0">Seller Type</th>
                <th className="td-bank-name text-capitalize border-0">Bank</th>
                <th className="td-phone text-capitalize border-0">Phone No.</th>
                {isP8team && (
                  <>
                    <th className="td-export text-center border-0">
                      วันที่ Export <br />
                      ครั้งแรก
                    </th>
                    <th className="td-export text-center border-0">
                      วันที่ Export <br />
                      ล่าสุด
                    </th>
                  </>
                )}
              </tr>
            </thead>
            {pendingChannelSellers.length > 0 ? (
              <tbody className="tbody-seller">{rows}</tbody>
            ) : (
              <tbody>
                <EmptyRow colSpan={isP8team ? 10 : 8} displayText="ไม่พบข้อมูลผู้ขาย" />
              </tbody>
            )}
          </Table>
        </div>
        <CardFooter>
          <Col className="p-0">
            <Pagination
              className="pull-right"
              activePage={this.props.seller.pagePending}
              displayPageNumber={5}
              maximumPageNumber={Math.ceil(pendingSellersTotal / pageSize)}
              onSelect={this.selectPage.bind(this)}
            />
          </Col>
        </CardFooter>
      </Page>
    );
  }
}

export default inject('seller', 'user')(observer(SellerPendingTable));
