import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { config } from '../../config';
import { http } from '../../utils/http';
import { Card, CardBody, Col, CustomInput, Input, InputGroup, InputGroupAddon, InputGroupText, Row, FormGroup, Label, FormFeedback } from 'reactstrap';
import { Page } from '../../components/Page';
import Visibility from '../../components/Visibility/Visibility';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';

const INITIAL_INVALID_MESSAGE = {
  default_due_date: '',
  maximum_due_date: '',
};

const initialState = {
  admin_channel_uid: '',
  loading: false,
  mode: 'view',

  // input
  state_before_edit: null,
  default_due_date: '',
  maximum_due_date: '',

  // validation
  invalid_message: _.cloneDeep(INITIAL_INVALID_MESSAGE),
};

class PaymentSetting extends Component {
  constructor(props) {
    super(props);
    this.state = _.cloneDeep(initialState);
  }

  async componentDidMount() {
    const { admin_channel_uid } = this.props.channel.getData();
    let { loggedinUser } = this.props.user.getData();
    let user_type = loggedinUser.type;
    this.setState({ admin_channel_uid, user_type }, async () => {
      await this.fetchChannelDuePayment();
    });
  }

  getSearchQueryString() {
    const { admin_channel_uid } = this.state;
    let params = {};
    if (admin_channel_uid) params['channel_uid'] = admin_channel_uid;
    let query = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');
    return query ? `?${query}` : '';
  }

  async fetchChannelDuePayment() {
    this.setState({ loading: true });
    try {
      let query = this.getSearchQueryString();
      let url = `${config.npay.apiUrl}/channels/settings/duepayment${query}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        let channel_due_payment = body.data.channel_due_payment;
        let system = body.data.system;
        let default_due_date = channel_due_payment ? channel_due_payment.default_due_date : system ? system.default_due_date : '';
        let maximum_due_date = channel_due_payment ? channel_due_payment.maximum_due_date : system ? system.maximum_due_date : '';
        this.setState({ mode: 'view', default_due_date, maximum_due_date });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 100);
    }
  }

  async saveDuePayment() {
    if (this.validate()) {
      const { default_due_date, maximum_due_date } = this.state;
      try {
        let url = `${config.npay.apiUrl}/channels/settings/duepayment`;
        let body = { default_due_date, maximum_due_date };
        let response = await http.put(url, { body });
        if (response.status === 200) {
          await this.fetchChannelDuePayment();
        } else {
          let body = await response.json();
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'Error!',
            text: body.message,
            confirmButtonText: 'OK',
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
  }
  validate() {
    const { default_due_date, maximum_due_date } = this.state;

    let invalid_message = _.cloneDeep(INITIAL_INVALID_MESSAGE);
    let pass = true;
    if (isNaN(parseFloat(default_due_date))) {
      pass = false;
      invalid_message['default_due_date'] = 'กรุณากรอกข้อมูลให้ถูกต้อง';
    }
    if (isNaN(parseFloat(maximum_due_date))) {
      pass = false;
      invalid_message['maximum_due_date'] = 'กรุณากรอกข้อมูลให้ถูกต้อง';
    }
    this.setState({ invalid_message });
    return pass;
  }

  edit() {
    this.setState({
      state_before_edit: _.cloneDeep(this.state),
      mode: 'edit',
      has_changed: false,
    });
  }
  cancelEdit() {
    this.setState({
      ...this.state.state_before_edit,
      state_before_edit: null,
      mode: 'view',
    });
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  showActionByCondition() {
    let { mode } = this.state;
    let showSaveBtn = false;
    let showEditBtn = false;
    let showCancelBtn = false;
    switch (mode) {
      case 'edit':
        showSaveBtn = true;
        showCancelBtn = true;
        break;
      case 'view':
        showEditBtn = true && !this.disabledEditByRole('edit_info');
        break;
    }

    return {
      showSaveBtn,
      showEditBtn,
      showCancelBtn,
    };
  }

  disabledEditByRole(type) {
    const { user_type } = this.state;
    switch (type) {
      case 'edit_info': // not 3 roles, disabled
        return !['as_channel_admin', 'channel_admin', 'channel_user'].some(r => r === user_type);
      default:
        return false;
    }
  }

  render() {
    const { admin_channel_uid, loading, invalid_message, mode } = this.state;
    const { showSaveBtn, showEditBtn, showCancelBtn } = this.showActionByCondition();
    const disabled = !['edit'].find(str => mode === str);
    const historyLogUrl = this.state.admin_channel_uid ? 
      `${config.web.rootpath}/channels/${this.state.admin_channel_uid}/setting/history?tab=due-of-payment` : 
      `${config.web.rootpath}/channel-setting/campaign/history?tab=due-of-payment`;

    return (
      <Page loading={loading}>
        <Card className="card-channelmanagement">
          <CardBody>
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="text-uppercase title-channel">{admin_channel_uid ? 'วันครบกำหนดชำระเงิน' : 'ตั้งค่าครบกำหนดชำระเงิน'}</h6>
              <Link to={historyLogUrl}>
                <NexterButton className="my-0" size="sm" outline icon="fas fa-history">
                  History Log
                </NexterButton>
              </Link>
            </div>
            <hr />
            <Row>
              <Col lg={3}>
                <FormGroup className="required">
                  <Label className="control-label form-label">จำนวนวันเริ่มต้น</Label>
                  <NexterInput
                    type="group"
                    inputType="number"
                    allow="numeric"
                    name="default_due_date"
                    value={this.state.default_due_date}
                    min={0}
                    max={365}
                    onChange={this.handleChange.bind(this)}
                    onBlur={this.handleChange.bind(this)}
                    size="sm"
                    disabled={disabled}
                    invalid={!!invalid_message['default_due_date']}
                    addonType="append"
                    addonText="วัน"
                    inputGroupClass=""
                  />
                  <FormFeedback
                    className={classNames({
                      'd-block': invalid_message['default_due_date'],
                    })}
                  >
                    {invalid_message['default_due_date']}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <FormGroup className="required">
                  <Label className="control-label form-label">จำนวนวันสูงสุด</Label>
                  <NexterInput
                    type="group"
                    inputType="number"
                    allow="numeric"
                    name="maximum_due_date"
                    value={this.state.maximum_due_date}
                    min={0}
                    max={365}
                    onChange={this.handleChange.bind(this)}
                    onBlur={this.handleChange.bind(this)}
                    size="sm"
                    disabled={disabled}
                    invalid={!!invalid_message['maximum_due_date']}
                    addonType="append"
                    addonText="วัน"
                    inputGroupClass=""
                  />
                  <FormFeedback
                    className={classNames({
                      'd-block': invalid_message['maximum_due_date'],
                    })}
                  >
                    {invalid_message['maximum_due_date']}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Visibility permission_code="channel:set_channel_due_of_payment">
              {showSaveBtn || showEditBtn || showCancelBtn ? <hr /> : ''}
              <Row>
                <Col>
                  {showSaveBtn && (
                    <NexterButton className="mr-2 my-0" size="sm" color="success" outline onClick={this.saveDuePayment.bind(this)} icon="fas fa-save">
                      SAVE
                    </NexterButton>
                  )}
                  {showEditBtn && (
                    <NexterButton className="mr-2 my-0" size="sm" onClick={this.edit.bind(this)} icon="fas fa-pencil-alt">
                      Edit
                    </NexterButton>
                  )}
                  {showCancelBtn && (
                    <NexterButton className="mr-2 my-0" size="sm" color="info" outline onClick={this.cancelEdit.bind(this)} icon="fas fa-times-circle">
                      Cancel
                    </NexterButton>
                  )}
                </Col>
              </Row>
            </Visibility>
          </CardBody>
        </Card>
      </Page>
    );
  }
}

export default inject('channel', 'user')(observer(PaymentSetting));
