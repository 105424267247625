import { AUTO_TRANSFER_SCHEDULE_DAYS } from '../constants/transferConstants';

export const formatAutoTransferScheduleDates = (dates = []) => {
  let dateNumbers = [];
  let dateStrings = [];
  const { numbers, strings } = dates.reduce(
    (acc, item) => {
      isNaN(Number(item)) ? acc.strings.push(item) : acc.numbers.push(item);
      return acc;
    },
    { numbers: [], strings: [] }
  );
  if (numbers.length > 0) {
    const sortedNumbers = numbers.map(Number).sort((a, b) => a - b);
    dateNumbers = sortedNumbers
      .reduce((acc, num, index) => {
        if (index === 0 || num !== sortedNumbers[index - 1] + 1) {
          acc.push([num]);
        } else {
          acc[acc.length - 1].push(num);
        }
        return acc;
      }, [])
      .map(range => (range.length > 1 ? `${range[0]}-${range[range.length - 1]}` : range[0]));
  }
  if (strings.length > 0) {
    dateStrings = strings.map(day => AUTO_TRANSFER_SCHEDULE_DAYS[day] || 'unkown');
  }
  let result = [...dateNumbers, ...dateStrings].join(', ');
  if (dateNumbers.length > 0) {
    result = `วันที่ ${result}`;
  }
  return result;
};
