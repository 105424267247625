/* global FormData */
import _ from 'lodash';
import moment from 'moment';
import Swal from 'sweetalert2';
import queryString from 'query-string';
import BaseStore from './BaseStore';

import { config } from '../config';
import { http } from '../utils/http';
import { file as fileUtil } from '../utils/file';
import iEmailOutline from './../assets/img/icon-email-outline.png';

// import { Link } from 'react-router-dom';

export class SellerStore extends BaseStore {
  constructor() {
    super();
    this.observable(this.getInitialData());
  }

  getInitialData() {
    return {
      loading: false,
      rawSeller: null,
      seller: {
        name: '',
        type: '',
        kyc_type: '',
        service_charge_rate: 3,
        service_charge_absorb: true,
        phone_number: '',
        email: '',
        is_active: null,
        delete_status: '',
        reject_delete_reason: '',
        channel_seller: {
          seller_code: '',
          service_charge_rate: 3,
          service_charge_absorb: true,
        },
        sellers_account: {},
        kyc_company: {
          company_taxid: '',
          beneficial_owner_name: '',
          beneficial_owner_personal_id: '',
          kyc_shareholders: [
            {
              tax_id: '',
              name: '',
              lastname: '',
              type: '',
              kyc_shareholders_committee: [],
            },
          ],
        },
        kyc_personal: {
          name: '',
          personal_id: '',
          file_kyc_personal: '',
          file_idcard: '',
        },
      },
      kycCompanyUpload: {
        identification: null,
        certificate: null,
        vat20: null,
        boj5: null,
        beneficial_owner: null,
        beneficial_owner_authorization: null,
        other: null,
      },
      kycPersonalUpload: {
        kyc_personal: '',
        idcard: '',
      },
      kycShareholdersReserved: [],
      sellers: [],
      allSellers: [],
      sellersTotal: 0,
      allChannelSellers: [],
      channelSellersTotal: 0,
      pendingChannelSellers: [],
      pendingSellersTotal: 0,
      pendingDeleteChannelSellers: [],
      pendingDeleteSellersTotal: 0,
      countSellerNum: 0,
      countSellerChannelName: [],
      channelSellers: [],
      default_due_date: '',
      maximum_due_date: '',
      publicUid: '',
      kycs: [],
      table: {
        selection: [],
        selectAll: false,
        selectType: 'checkbox',
      },
      filter: {
        channel_selected: null,
        seller_account_status_selection: [],
        seller_kyc_status_selection: [],
        seller_list_kyc_status_selection: [],
        seller_type_selection: [],
        seller_status_selection: [],
        seller_delete_status_selection: [],
        seller_name: '',
        seller_code: '',
        channel_id: '', // NOTICE: channel uid
        email: '',
        committee: false,
        seller_account_status_selection_export: [],
        seller_selection_export_first: [],
        seller_export_start_date: '',
        seller_export_end_date: '',
        validate: false,
        seller_legal_entity: false,
      },
      filterPending: {
        channel_selected: null,
        seller_account_status_selection: ['pending'],
        seller_kyc_status_selection: [],
        seller_list_kyc_status_selection: [],
        seller_type_selection: [],
        seller_status_selection: [],
        seller_delete_status_selection: [],
        seller_name: '',
        seller_code: '',
        channel_id: '', // NOTICE: channel uid
        email: '',
        committee: false,
        seller_account_status_selection_export: [],
        seller_selection_export_first: [],
        seller_export_start_date: '',
        seller_export_end_date: '',
        validate: false,
      },
      filterPendingDelete: {
        channel_selected: null,
        seller_account_status_selection: [],
        seller_kyc_status_selection: [],
        seller_list_kyc_status_selection: [],
        seller_type_selection: [],
        seller_status_selection: [],
        seller_delete_status_selection: ['requested'],
        seller_name: '',
        seller_code: '',
        channel_id: '', // NOTICE: channel uid
        email: '',
        committee: false,
        seller_account_status_selection_export: [],
        seller_selection_export_first: [],
        seller_export_start_date: '',
        seller_export_end_date: '',
        validate: false,
      },
      btnApproveAll: true,
      btnApproveAllKYC: true,
      editable: {
        detail: false,
        bankaccount: false,
        kyc_company: false,
        kyc_personal: false,
        // kyc_shareholder: false,
      },
      rejectReason: '',
      pagination: {
        page: 1,
        limit: 20,
      },
      logs: [],
      fileCheckMode: 'auto',
      sellerMgmtView: 'list',
      editableSellerDetail: false,
      editableSellerAccountDetail: false,
      pagePending: 1,
      pageSellerlist: 1,
      pagePendingDeletelist: 1,
      activeTabSellerPage: 'seller-list',
    };
  }

  changeSellerMgmtView(view) {
    this.sellerMgmtView = view;
  }

  clearSellerListFilter() {
    this.filter.seller_list_kyc_status_selection = [];
    this.filter.seller_account_status_selection_export = [];
    this.filter.seller_type_selection = [];
    this.filter.seller_status_selection = [];
    this.filter.seller_delete_status_selection = [];
    this.filter.committee = false;
  }

  clearSellerListFilterPending() {
    this.filterPending.seller_list_kyc_status_selection = [];
    this.filterPending.seller_account_status_selection = ['pending'];
    this.filterPending.seller_type_selection = [];
    this.filterPending.seller_status_selection = [];
    this.filterPending.seller_delete_status_selection = [];
  }

  clearSellerListFilterPendingDelete() {
    this.filterPendingDelete.seller_delete_status_selection = ['requested'];
  }

  clearExportFilter() {
    this.filter.seller_export_start_date = null;
    this.filter.seller_export_end_date = null;
    this.filter.seller_account_status_selection_export = [];
    this.filter.seller_list_kyc_status_selection = [];
    this.filter.committee = false;
    this.filter.seller_type_selection = [];
    this.filter.seller_status_selection = [];
    this.filter.seller_delete_status_selection = [];
    this.filter.seller_selection_export_first = [];
    this.filter.seller_name = '';
    this.filter.seller_code = '';
    this.filter.channel_id = '';
    this.filter.email = '';
    this.filter.seller_legal_entity = false;
  }

  clearExportFilterPending() {
    this.filterPending.seller_export_start_date = null;
    this.filterPending.seller_export_end_date = null;
    this.filterPending.seller_account_status_selection_export = [];
    this.filterPending.seller_list_kyc_status_selection = [];
    this.filterPending.seller_type_selection = [];
    this.filterPending.seller_status_selection = [];
    this.filterPending.seller_delete_status_selection = [];
    this.filterPending.seller_selection_export_first = [];
    this.filterPending.seller_name = '';
    this.filterPending.seller_code = '';
    this.filterPending.channel_id = '';
    this.filterPending.email = '';
  }

  clearExportFilterPendingDelete() {
    this.filterPendingDelete.seller_export_start_date = null;
    this.filterPendingDelete.seller_export_end_date = null;
    this.filterPendingDelete.seller_account_status_selection_export = [];
    this.filterPendingDelete.seller_selection_export_first = [];
    this.filterPendingDelete.seller_delete_status_selection = [];
    this.filterPendingDelete.seller_name = '';
    this.filterPendingDelete.seller_code = '';
    this.filterPendingDelete.channel_id = '';
    this.filterPendingDelete.email = '';
  }

  changeExportFilterStartDate(val) {
    this.filter.seller_export_start_date = val;
    if (this.filter.seller_export_start_date > this.filter.seller_export_end_date) {
      this.filter.seller_export_end_date = val;
    }
  }

  changeExportFilterEndDate(val) {
    this.filter.seller_export_end_date = val;
  }

  changeFileCheckMode(data) {
    this.fileCheckMode = data;
  }

  clearPagination() {
    let { pagination } = this.getInitialData();
    this.pagination = pagination;
  }

  clearSellers() {
    let { sellers } = this.getInitialData();
    this.sellers = sellers;
  }

  clearSeller() {
    let { seller } = this.getInitialData();
    this.seller = seller;
  }

  clearFilter() {
    let { filter } = this.getInitialData();
    this.filter = filter;
  }

  clearFilterPending() {
    let { filterPending } = this.getInitialData();
    this.filterPending = filterPending;
  }

  clearFilterPendingDelete() {
    let { filterPendingDelete } = this.getInitialData();
    this.filterPendingDelete = filterPendingDelete;
  }

  clearTableSelection() {
    let { table } = this.getInitialData();
    this.table = table;
  }
  changeFilterSellerCode(code) {
    this.filter.seller_code = code;
  }

  changeFilterChannelID(id) {
    this.filter.channel_id = id;
  }

  changeFilterSellerName(name) {
    this.filter.seller_name = name;
  }

  changeFilterEmail(email) {
    this.filter.email = email;
  }

  changeFilterSellerLegalEntity(val) {
    this.filter.seller_legal_entity = val;
  }

  changeFilterPendingSellerCode(code) {
    this.filterPending.seller_code = code;
  }

  changeFilterPendingChannelID(id) {
    this.filterPending.channel_id = id;
  }

  changeFilterPendingSellerName(name) {
    this.filterPending.seller_name = name;
  }

  changeFilterPendingEmail(email) {
    this.filterPending.email = email;
  }

  changeFilterPendingDeleteSellerCode(code) {
    this.filterPendingDelete.seller_code = code;
  }

  changeFilterPendingDeleteChannelID(id) {
    this.filterPendingDelete.channel_id = id;
  }

  changeFilterPendingDeleteSellerName(name) {
    this.filterPendingDelete.seller_name = name;
  }

  changeFilterPendingDeleteEmail(email) {
    this.filterPendingDelete.email = email;
  }

  changeFilterSellerCommittee() {
    this.filter.committee = !this.filter.committee;
  }

  changeChannel(optionSelected) {
    let obj = { value: optionSelected.value, label: optionSelected.label };
    this.filterPending.channel_selected = obj;
  }

  clearChannel() {
    this.filter.channel_selected = null;
  }

  checkNotPendingStatus() {
    let newSellers = this.sellers.filter(el => {
      return this.table.selection.indexOf(el.uid) >= 0;
    });

    let someNotPending = newSellers.some(el => el.sellers_account.status === 'approved' || el.sellers_account.status === 'rejected');
    this.btnApproveAll = someNotPending !== true;
  }

  checkNotPendingStatusKYC() {
    let newSellers = this.sellers.filter(el => {
      return this.table.selection.indexOf(el.uid) >= 0;
    });

    let someNotPending = newSellers.some(el =>
      el.kyc_company ? el.kyc_company.status === 'approved' || el.kyc_company.status === 'rejected' : el.kyc_personal.status === 'approved' || el.kyc_personal.status === 'rejected'
    );
    this.btnApproveAllKYC = someNotPending !== true;
  }

  changeActiveTabSellerPage(tabId) {
    this.activeTabSellerPage = tabId;
  }

  async fetchSellers() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/channels/sellers`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.channelSellers = body.data.channel_sellers;
        this.publicUid = body.data.public_uid;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async exportSeller(tab = 'all') {
    try {
      this.loading = true;
      const paramsString = this.getParamFetchChannelSellers(tab);
      const url = `${config.npay.apiUrl}/excel/seller?${paramsString}`;
      const response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        window.open(body.data.url);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  getParamFetchChannelSellers(tab = 'all') {
    const { filter, pageSellerlist, filterPending, pagePending, filterPendingDelete, pagePendingDeletelist } = this.getData();
    let filterData = filter;
    let page = pageSellerlist;
    let accountKey = 'seller_account_status_selection_export';
    if (tab === 'pending') {
      filterData = filterPending;
      page = pagePending;
      accountKey = 'seller_account_status_selection';
    } else if (tab === 'pending_delete') {
      filterData = filterPendingDelete;
      page = pagePendingDeletelist;
    }

    const params = {
      page: page,
      size: 10,
      name: filterData.seller_name,
      code: filterData.seller_code,
      uid: filterData.channel_selected?.value || (filterData.channel_id ? filterData.channel_id.value : ''),
      email: filterData.email,
      order_by: 'seller_created_at',
      committee: filterData.committee,
      account_status: filterData[accountKey].join('-'),
      kyc_status: filterData.seller_list_kyc_status_selection.join('-'),
      delete_status: filterData.seller_delete_status_selection.join('-'),
      type: filterData.seller_type_selection,
      status: filterData.seller_status_selection,
      export_first: filterData.seller_selection_export_first.join('-'),
      date_start: moment(filterData.seller_export_start_date).set({ hour: 0, minute: 0, second: 0 }).valueOf(),
      date_end: moment(filterData.seller_export_end_date).set({ hour: 23, minute: 59, second: 59 }).valueOf(),
      source: 'Seller',
    };
    const paramsString = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    return paramsString;
  }

  async fetchChannelSellers(tab = 'all') {
    try {
      this.loading = true;

      const paramsString = this.getParamFetchChannelSellers(tab);

      const url = `${config.npay.apiUrl}/channels/sellers/filters?${paramsString}`;
      const response = await http.get(url);
      const body = await response.json();
      if (response.status === 200) {
        let dataKey = 'allChannelSellers';
        let totalKey = 'channelSellersTotal';
        if (tab === 'pending') {
          dataKey = 'pendingChannelSellers';
          totalKey = 'pendingSellersTotal';
        } else if (tab === 'pending_delete') {
          dataKey = 'pendingDeleteChannelSellers';
          totalKey = 'pendingDeleteSellersTotal';
        }
        this[dataKey] = body.data;
        this[totalKey] = body.total;
      } else {
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async countSellers(uid) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/channels/count/${uid}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.countSellerChannelName = body.channelName;
        this.countSellerNum = body.totalChannel;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async fetchSellersByStatus(status, channelUid = undefined, q = {}) {
    try {
      this.loading = true;
      let query = queryString.stringify({ channelUid, ...q }, { skipNull: true });
      let url = `${config.npay.apiUrl}/channels/sellers/${status}?${query}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        let list = body.data.map(item => {
          item.value = item.seller.id;
          item.label = item.seller.name;
          return item;
        });
        this.channelSellers = list;
        this.default_due_date = body.default_due_date;
        this.maximum_due_date = body.maximum_due_date;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async fetchAllSeller() {
    try {
      this.loading = true;
      this.clearTableSelection();
      // let url = `${config.npay.apiUrl}/admin/sellers`;

      // move exclude status from api to front
      let url = `${config.npay.apiUrl}/admin/sellers?exclude_account_status=created`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        // this.channelSellers = body.data;
        this.sellers = body.data;
        this.clearFilter();
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async fetchAllSellerList() {
    try {
      this.loading = true;
      let qs = this.getConditionQuery();
      let url = `${config.npay.apiUrl}/admin/sellers?mode=list${qs}`;

      let response = await http.get(url);
      let body = await response.json();
      if (response.status === 200) {
        this.allSellers = body.data;
        this.sellersTotal = body.total;
        // this.clearFilter();
      } else {
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async fetchAllSellerListPending() {
    try {
      this.loading = true;
      const committee = this.filterPending.committee;
      let filterPending = this.filterPending;
      let url = `${config.npay.apiUrl}/admin/sellers?mode=list`;
      url += `&name=${filterPending.seller_name}`;
      url += `&code=${filterPending.seller_code}`;
      url += `&uid=${filterPending.channel_id}`;
      url += `&email=${filterPending.email}`;
      url += `&order_by=seller_created_at`;
      url += `&committee=${committee}`;
      url += `&account_status=${filterPending.seller_account_status_selection.join('-')}`;
      url += `&kyc_status=${filterPending.seller_list_kyc_status_selection.join('-')}`;
      url += `&type=${filterPending.seller_type_selection}`;
      url += `&status=${filterPending.seller_status_selection}`;
      url += `&export_first=${filterPending.seller_selection_export_first.join('-')}`;
      url += `&date_start=${moment(filterPending.seller_export_start_date).set({ hour: 0, minute: 0, second: 0 }).valueOf()}`;
      url += `&date_end=${moment(filterPending.seller_export_end_date).set({ hour: 23, minute: 59, second: 59 }).valueOf()}`;

      let response = await http.get(url);
      let body = await response.json();
      if (response.status === 200) {
        this.sellers = body.data;
        this.sellersTotal = body.total;
      } else {
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  changeSellerPage(page) {
    this.pagination.page = page;
    this.fetchSellersWithPagination();
  }

  searchSellerByFilter() {
    this.clearPagination();
    this.fetchChannelSellers();
  }

  searchSellersByFilterPending() {
    let { filterPending } = this.getData();
    if (!filterPending.channel_selected) {
      this.fetchAllSellerListPending();
    } else {
      this.searchSellersPending();
    }
  }

  async fetchSellersWithPagination() {
    try {
      this.loading = true;
      const committee = this.filter.committee;
      let filter = this.filter;
      let { limit, page } = this.pagination;
      let url = `${config.npay.apiUrl}/admin/sellers?mode=pagination`;
      url += `&page=${page}`;
      url += `&limit=${limit}`;
      url += `&name=${filter.seller_name}`;
      url += `&code=${filter.seller_code}`;
      url += `&uid=${filter.channel_id}`;
      url += `&email=${filter.email}`;
      url += `&order_by=seller_created_at`;
      url += `&committee=${committee}`;
      url += `&account_status=${filter.seller_account_status_selection_export.join('-')}`;
      url += `&kyc_status=${filter.seller_kyc_status_selection.join('-')}`;
      url += `&type=${filter.seller_type_selection}`;
      url += `&type=${filter.seller_status_selection}`;
      url += `&export_first=${filter.seller_selection_export_first.join('-')}`;
      url += `&date_start=${moment(filter.seller_export_start_date).set({ hour: 0, minute: 0, second: 0 }).valueOf()}`;
      url += `&date_end=${moment(filter.seller_export_end_date).set({ hour: 23, minute: 59, second: 59 }).valueOf()}`;

      let response = await http.get(url);
      let body = await response.json();
      if (response.status === 200) {
        this.sellers = body.data;
        this.sellersTotal = body.total;
        // this.clearFilter();
      } else {
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async fetchPendingSellers() {
    try {
      this.loading = true;
      this.clearTableSelection();
      let url = `${config.npay.apiUrl}/admin/sellers/pending`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.sellers = body.data;
        this.clearFilterPending();
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async fetchPendingKYCs() {
    try {
      this.loading = true;
      this.clearTableSelection();
      let url = `${config.npay.apiUrl}/admin/kycs/pending`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.sellers = body.data;
        this.clearFilter();
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async searchSellers() {
    try {
      this.loading = true;
      this.clearTableSelection();
      let { filter } = this.getData();

      let body = filter;

      if (!filter.channel_selected) {
        console.warn('no channel selected');
        // return;
        body.channel_selected = null;
      } else {
        body.channel_selected = filter.channel_selected.value;
      }

      let url = `${config.npay.apiUrl}/admin/sellers`;
      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        // console.log('body', body);

        this.sellers = body.data;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async searchSellersPending() {
    try {
      this.loading = true;
      this.clearTableSelection();
      let { filterPending } = this.getData();

      let body = filterPending;

      if (!filterPending.channel_selected) {
        console.warn('no channel selected');
        // return;
        body.channel_selected = null;
      } else {
        body.channel_selected = filterPending.channel_selected.value;
      }

      let url = `${config.npay.apiUrl}/admin/sellers`;
      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        // console.log('body', body);

        this.sellers = body.data;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  changeSellerName(name) {
    this.seller.name = name;
  }

  changePhoneNumber(number) {
    this.seller.phone_number = number;
  }

  changeStatus(status) {
    this.seller.status = status;
  }

  changeIsActive(is_active) {
    this.seller.is_active = is_active;
  }

  changeEmail(email) {
    this.seller.email = email;
  }

  changeSellerCode(code) {
    this.seller.channel_seller.seller_code = code;
  }

  changeDealerCode(code) {
    this.seller.sellers_account.dealer_code = code;
  }

  changeSellerType(type) {
    this.seller.type = type;
  }

  changeSellerKYCType(type) {
    this.seller.kyc_type = type;
  }

  changeBank(code, name) {
    this.seller.sellers_account.bank_code = code;
    this.seller.sellers_account.bank_name = name;
  }

  changeBankAccountName(name) {
    this.seller.sellers_account.bank_account_name = name;
  }

  changeBankAccountNumber(no) {
    this.seller.sellers_account.bank_account_no = no;
  }

  async changeBankAccountImage(file) {
    if (file) {
      let compressed = file;
      if (['image/png', 'image/jpeg'].includes(file.type)) compressed = await fileUtil.compressImage(file);
      this.seller.sellers_account.bank_account_image = compressed;
      this.seller.sellers_account.bank_account_image_preview = URL.createObjectURL(compressed);
    } else {
      this.seller.sellers_account.bank_account_image = null;
      this.seller.sellers_account.bank_account_image_preview = null;
    }
  }

  changeServiceChargeRate(rate) {
    this.seller.channel_seller.service_charge_rate = rate;
  }

  changeServiceChargeAbsorb(val) {
    this.seller.channel_seller.service_charge_absorb = val;
  }

  changeCompanyInput(val, key) {
    this.seller.kyc_company[key] = val;
  }

  changeCompanyFile(file, key) {
    this.kycCompanyUpload[key] = file;
  }

  changeKYCPersonalInput(val, key) {
    this.seller.kyc_personal[key] = val;
  }

  changeKYCPersonalFile(file, key) {
    this.kycPersonalUpload[key] = file;
  }

  addKYCShareholder() {
    this.seller.kyc_company.kyc_shareholders.push({
      tax_id: '',
      name: '',
      lastname: '',
      type: '',
      kyc_shareholders_committee: [],
    });
  }

  addKYCShareholderCommittee(i) {
    this.seller.kyc_company.kyc_shareholders[i].kyc_shareholders_committee.push({
      personal_name: '',
      personal_id: '',
    });
  }

  removeKYCShareholder(i) {
    if (this.seller.kyc_company.kyc_shareholders.length > 1) {
      this.seller.kyc_company.kyc_shareholders.splice(i, 1);
    }
  }

  removeKYCShareholderCommittee(i, j) {
    this.seller.kyc_company.kyc_shareholders[i].kyc_shareholders_committee.splice(j, 1);
  }

  changeKYCShareholder(val, key, i) {
    this.seller.kyc_company.kyc_shareholders[i][key] = val;
  }

  changeKYCShareholderCommittee(val, key, i, j) {
    this.seller.kyc_company.kyc_shareholders[i].kyc_shareholders_committee[j][key] = val;
  }

  changeRejectReasonSellersAccount(val) {
    this.seller.sellers_account.reject_reason = val;
  }

  changeRejectReasonKYC(val) {
    this.rejectReason = val;
  }

  async approveSellerByUid(uid) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/sellers/${uid}/status/approved`;
      let response = await http.put(url, { body: {} });
      if (response.status === 200) {
        Swal.fire({
          customClass: 'nexter-alert nexter-alert-user-manage',
          type: 'success',
          title: 'Updated สถานะบัญชีและส่งข้อมูลบัญชีไปที่ระบบ T2P เสร็จเรียบร้อย',
          confirmButtonText: 'กลับไป',
        }).then(() => {
          this.sellerMgmtView = 'list';
          window.location.href = `${config.npay.cmsUrl}/sellers`;
        });
      } else {
        let body = await response.json();
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'เกิดข้อผิดพลาด!',
          html: body.message || 'ไม่สามารถอัปเดตสถานะบัญชีได้',
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async batchApproveSeller() {
    if (this.table.selection.length <= 0) {
      console.warn('No Selection');
    } else {
      try {
        this.loading = true;
        let updateData = {
          selection: this.table.selection,
        };

        let url = `${config.npay.apiUrl}/admin/sellers/status/approved`;
        let response = await http.put(url, { body: updateData });
        if (response.status === 200) {
          window.location.href = `${config.web.rootpath}/sellers/pending`;
        }
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    }
  }

  async rejectSellerByUid(uid) {
    try {
      this.loading = true;
      let rejectReason = this.seller.sellers_account.reject_reason;

      let updateData = {
        reject_reason: rejectReason,
      };

      let url = `${config.npay.apiUrl}/admin/sellers/${uid}/status/rejected`;
      let response = await http.put(url, { body: updateData });
      if (response.status === 200) {
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          title: 'Success!',
          text: 'บันทึกข้อมูลสำเร็จ',
          confirmButtonText: 'CONTINUE',
        }).then(async () => {
          window.location.href = `${config.npay.cmsUrl}/sellers`;
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async approveKYCByUid(uid) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/kycs/${uid}/status/approved`;
      let response = await http.put(url, { body: {} });
      if (response.status === 200) {
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          title: 'Success!',
          text: 'บันทึกข้อมูลสำเร็จ',
          confirmButtonText: 'CONTINUE',
        }).then(async () => {
          window.location.href = `${config.npay.cmsUrl}/sellers`;
        });
      } else {
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: 'ไม่สามารถอัปเดตสถานะ KYC ได้',
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      Swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: 'เกิดข้อผิดพลาด!',
        text: 'ไม่สามารถอัปเดตสถานะ KYC ได้',
        confirmButtonText: 'OK',
      });
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async batchApproveSellerKYC() {
    if (this.table.selection.length <= 0) {
      console.warn('No Selection');
    } else {
      try {
        this.loading = true;
        let updateData = {
          selection: this.table.selection,
        };

        let url = `${config.npay.apiUrl}/admin/kycs/status/approved`;
        let response = await http.put(url, { body: updateData });
        if (response.status === 200) {
          window.location.href = `${config.web.rootpath}/sellers/pending`;
        }
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    }
  }

  async rejectKYCByUid(uid) {
    try {
      this.loading = true;
      let rejectReason = this.rejectReason;

      if (!rejectReason) {
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: 'กรุณากรอกเหตุผลในการปฏิเสธ',
          confirmButtonText: 'OK',
        });
      } else {
        let updateData = {
          reject_reason: rejectReason,
        };

        let url = `${config.npay.apiUrl}/admin/kycs/${uid}/status/rejected`;
        let response = await http.put(url, { body: updateData });
        if (response.status === 200) {
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'success',
            title: 'Success!',
            text: 'บันทึกข้อมูลสำเร็จ',
            confirmButtonText: 'CONTINUE',
          }).then(async () => {
            window.location.href = `${config.npay.cmsUrl}/sellers`;
          });
        } else {
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'เกิดข้อผิดพลาด!',
            text: 'ไม่สามารถอัปเดตสถานะ KYC ได้',
            confirmButtonText: 'OK',
          });
        }
      }
    } catch (err) {
      Swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: 'เกิดข้อผิดพลาด!',
        text: 'ไม่สามารถอัปเดตสถานะ KYC ได้',
        confirmButtonText: 'OK',
      });
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async uploadBankAccountImage() {
    try {
      let data = this.getData();
      let file = data.seller.sellers_account.bank_account_image;

      let formData = new FormData();
      formData.append('bookbank', file, file.name);
      // formData.append('code', data.seller.code);
      formData.append('seller_uid', data.seller.uid);
      formData.append('channel_id', data.seller.channel_seller?.channel_id);

      let url = `${config.npay.apiUrl}/sellers/bookbank`;
      let response = await http.post(url, { body: formData });
      if (response.status === 200) {
        let body = await response.json();
        return body.data.url;
      } else {
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: 'ไม่สามารถอัปโหลดไฟล์ได้',
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  async uploadFile(files) {
    const temp = {};
    try {
      let data = this.getData();
      let formData = new FormData();
      // formData.append('code', data.seller.code);
      formData.append('seller_uid', data.seller.uid);
      const pathUrl = window.location.pathname.split('/');
      const channel_uid = Array.isArray(pathUrl) ? pathUrl[4] : null;
      if (channel_uid) formData.append('channel_uid', channel_uid);

      for (let prop in files) {
        if (typeof files[prop] === 'object') {
          formData.append(prop, files[prop]);
        } else {
          temp[`file_${prop}`] = files[prop];
        }
      }

      let url = `${config.npay.apiUrl}/sellers/kyc`;
      let response = await http.post(url, { body: formData });
      if (response.status === 200) {
        let body = await response.json();
        // console.log('TCL: SellerStore -> uploadFile -> body', body);
        return { ...temp, ...body.data };
      } else {
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: 'ไม่สามารถอัปโหลดไฟล์ได้',
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  validateSellerData(data) {
    let keys = Object.keys(data);
    let passed = true;
    for (let key of keys) {
      if (key === 'bank_account_no') {
        if (!data[key] || data[key].length < 10) {
          passed = '';
        }
      } else if (key === 'phone' || key === 'phone_number') {
        if (!data[key] || data[key][0] !== '0' || data[key].length < 9) {
          passed = '';
        }
      } else if (key === 'email') {
        // if (!validation.email(data[key])) {
        //   passed = '';
        // }
      } else if (key === 'service_charge_rate') {
        if (data[key] === '') {
          passed = '';
        }
      } else {
        passed = passed && data[key];
      }
    }
    return passed;
  }

  validateSellerServiceCharge() {
    let { seller } = this.getData();
    return seller.channel_seller.service_charge_rate >= 3;
  }
  validateKYCShareholders(shareholders) {
    let passed = true;
    shareholders.forEach(el => {
      let keys = Object.keys(el);
      for (let key of keys) {
        /*  if (key === 'tax_id' && (!el[key] || el[key].length < 13)) {
           passed = '';
         } else if (key === 'kyc_shareholders_committee') {
           // not
         } else {
           passed = passed && el[key];
           console.log(key, passed);
         } */
        if (key === 'tax_id' || key === 'name' || key === 'lastname') {
          if (!el.tax_id && !el.name) {
            passed = '';
          }
          if (el.type === 'individual' && (el.name || el.lastname) && !(el.name && el.lastname)) {
            passed = '';
          }
          if (el.tax_id && el.tax_id.length < 13) {
            passed = '';
          }
        } else if (key === 'kyc_shareholders_committee') {
          // not
        } else {
          passed = passed && el[key];
          console.log(key, passed);
        }
      }
    });
    return passed;
  }

  validateKYCCompanyFiles(files) {
    //   let keys = Object.keys(files);
    let passed = true;
    // for (let key of keys) {
    //   if (key === 'beneficial_owner_authorization') {
    //     // not required
    //   } else {
    //     passed = passed && files[key];
    //     console.log(key, passed);
    //   }
    // }
    return passed;
  }

  validateKYCPersonalFiles(files) {
    // let keys = Object.keys(files);
    // let { seller } = this.getData();
    let passed = true;
    // for (let key of keys) {
    //   let fileName = `file_${key}`;
    //   passed = passed && (files[key] || seller.kyc_personal[fileName]);
    // }
    return passed;
  }

  validateKYCCompany(data, status) {
    let keys = Object.keys(data);
    let passed = true;
    for (let key of keys) {
      if (key === 'company_taxid' || key === 'beneficial_owner_personal_id') {
        if (!data[key] || data[key].length < 13) {
          passed = '';
        }
      } else if (key === 'kyc_shareholders') {
        let testShareholders = this.validateKYCShareholders(data[key]);
        if (!testShareholders) {
          passed = '';
        }
      } else if (key === 'upload' && status === 'created') {
        let testUploadFiles = this.validateKYCCompanyFiles(data[key]);
        if (!testUploadFiles) {
          passed = '';
        }
      } else {
        passed = passed && data[key];
        console.log(key, passed);
      }
    }
    return passed;
  }

  validateKYCPersonal(data) {
    let keys = Object.keys(data);
    let passed = true;
    for (let key of keys) {
      if (data.personal_id === null || (key === 'personal_id' && data.personal_id.length !== 13)) {
        return false;
      } else if (key === 'upload') {
        if (!this.validateKYCPersonalFiles(data[key])) {
          return false;
        }
      } else {
        passed = !!(passed && data[key]);
      }
    }
    return passed;
  }

  async createSeller() {
    try {
      let data = this.getData();
      let seller = data.seller;

      let prepareData = {
        name: seller.name,
        code: seller.channel_seller.seller_code,
        type: seller.type,
        kyc_type: seller.kyc_type,
        service_charge_rate: seller.service_charge_rate,
        // service_charge_absorb: seller.service_charge_absorb,
      };

      let passed = this.validateSellerData(prepareData);

      if (passed) {
        this.loading = true;
        // let imageUrl = await this.uploadBankAccountImage();
        // seller.bank_account_image = imageUrl;
        seller.service_charge_rate = parseFloat(seller.service_charge_rate).toFixed(2);

        let url = `${config.npay.apiUrl}/sellers`;
        let response = await http.post(url, { body: seller });
        if (response.status === 200) {
          window.location.href = `${config.web.rootpath}/sellers`;
        } else {
          let body = await response.json();
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'Error!',
            text: body.message,
            confirmButtonText: 'OK',
          });
        }
      } else {
        // throw new Error('Missing some value');
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: 'กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน',
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async createSellerBU() {
    try {
      let data = this.getData();
      let seller = data.seller;
      seller.type = 'channel';
      seller.kyc_type = 'company';

      let prepareData = {
        name: seller.name,
        code: seller.channel_seller.seller_code,
        type: seller.type,
        kyc_type: seller.kyc_type,
        service_charge_rate: seller.service_charge_rate,
      };

      const passed = this.validateSellerData(prepareData);
      if (passed) {
        this.loading = true;
        seller.service_charge_rate = parseFloat(seller.service_charge_rate).toFixed(2);

        let url = `${config.npay.apiUrl}/sellers/bu`;
        let response = await http.post(url, { body: seller });
        if (response.status === 200) {
          window.location.href = `${config.web.rootpath}/sellers`;
        } else {
          let body = await response.json();
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'Error!',
            text: body.message,
            confirmButtonText: 'OK',
          });
        }
      } else {
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: 'กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน',
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async updateServiceCharge(isFree) {
    if (this.table.selection.length <= 0) {
      console.warn('No Selection');
    } else {
      try {
        this.loading = true;
        let updateData = {
          selection: this.table.selection,
          is_free: isFree,
        };

        let url = `${config.npay.apiUrl}/channels/seller/freeservicecharge`;
        let response = await http.put(url, { body: updateData });
        if (response.status === 200) {
          // console.log('Updated');
          window.location.href = `${config.web.rootpath}/sellers`;
        }
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    }
  }

  async updateSellerDetail(validateInputs, options = {}) {
    try {
      this.loading = true;
      let { seller } = this.getData();
      let updateData = {
        id: seller.id,
        code: seller.channel_seller.seller_code,
        name: seller.name,
        service_charge_rate: seller.channel_seller.service_charge_rate,
        phone: seller.phone_number,
        email: seller.email,
        channel_uid: seller.channel_seller.created_by ? seller.channel_seller.created_by : seller.created_by,
      };
      const validateInput = validateInputs ? validateInputs() : true;
      if (!this.validateSellerData(updateData) || !validateInput) {
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: 'กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน',
          confirmButtonText: 'กลับไป',
        });
        return;
      }

      updateData.service_charge_absorb = seller.channel_seller.service_charge_absorb;
      updateData.is_active = seller.is_active;

      updateData.service_charge_rate = parseFloat(updateData.service_charge_rate).toFixed(2);
      let url = `${config.npay.apiUrl}/sellers/${seller.uid}/detail`;
      let response = await http.put(url, { body: updateData });
      if (response.status === 200) {
        if (!options.disabledSuccessModal) {
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'success',
            title: 'Success!',
            text: 'บันทึกข้อมูลสำเร็จ',
            confirmButtonText: 'CONTINUE',
          }).then(async () => {
            window.location.href = `${config.npay.cmsUrl}/sellers`;
          });
        }
        options.onSuccess && options.onSuccess();
      } else {
        let body = await response.json();
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: body.message,
          confirmButtonText: 'กลับไป',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async updateSellerBankAccount(validateInputs) {
    try {
      this.loading = true;
      let { seller } = this.getData();
      let updateData = {
        mail_channel_id: seller.channel_seller.channel_id || '',
        bank_code: seller.sellers_account.bank_code,
        bank_name: seller.sellers_account.bank_name,
        bank_account_no: seller.sellers_account.bank_account_no,
        bank_account_name: seller.sellers_account.bank_account_name,
        bank_account_image: seller.sellers_account.bank_account_image,
      };

      let passed = this.validateSellerData(updateData);
      const validateInput = validateInputs ? validateInputs() : true;
      if (passed && validateInput) {
        if (seller.sellers_account.bank_account_image.name) {
          let imageUrl = await this.uploadBankAccountImage();
          updateData.bank_account_image = imageUrl;
        }

        let url = `${config.npay.apiUrl}/sellers/${seller.uid}/bankaccount`;
        let response = await http.put(url, { body: updateData });
        if (response.status === 200) {
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'success',
            title: 'Success!',
            text: 'บันทึกข้อมูลสำเร็จ',
            confirmButtonText: 'CONTINUE',
          }).then(async () => {
            window.location.href = `${config.npay.cmsUrl}/sellers`;
          });
        } else {
          let body = await response.json();
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'Error!',
            text: body.message,
            confirmButtonText: 'OK',
          });
        }
      } else {
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: 'กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน',
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async updateKYCCompany(prepareKYCCompany = null, kycCompanyUpload = null) {
    try {
      this.loading = true;
      let { seller } = this.getData();

      // let prepareKYCCompany = {
      //   company_taxid: seller.kyc_company.company_taxid,
      //   beneficial_owner_name: seller.kyc_company.beneficial_owner_name,
      //   beneficial_owner_personal_id: seller.kyc_company.beneficial_owner_personal_id,
      //   kyc_shareholders: seller.kyc_company.kyc_shareholders,
      //   upload: kycCompanyUpload,
      // };

      // let passed = this.validateKYCCompany(prepareKYCCompany, 'created');
      if (prepareKYCCompany && kycCompanyUpload) {
        // console.log('OK');
        let uploadData = await this.uploadFile(kycCompanyUpload);
        if (!uploadData) throw new Error('No file uploaded');

        let updateData = prepareKYCCompany;
        updateData.upload = uploadData;
        updateData.fileCheckMode = this.fileCheckMode;
        let url = `${config.npay.apiUrl}/sellers/${seller.uid}/kyc_company`;
        let response = await http.put(url, { body: updateData });
        if (response.status === 200) {
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'success',
            title: 'Success!',
            text: 'บันทึกข้อมูลสำเร็จ',
            confirmButtonText: 'CONTINUE',
          }).then(async () => {
            window.location.href = `${config.npay.cmsUrl}/sellers`;
          });
        } else {
          let body = await response.json();
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'Error!',
            text: body.message,
            confirmButtonText: 'OK',
          });
        }
      } else {
        // console.warn('Invalid');
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: 'กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน',
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async updateKYCCompanyRejected() {
    try {
      this.loading = true;

      let { seller, kycCompanyUpload, kycShareholdersReserved } = this.getData();
      let newKYCShareholders = [];
      for (let i = 0; i < seller.kyc_company.kyc_shareholders.length; i++) {
        const el = seller.kyc_company.kyc_shareholders[i];
        let data = {
          tax_id: el.tax_id,
          name: el.name,
          lastname: el.lastname,
          type: el.type,
        };

        if (el.id) {
          data['id'] = el.id;
        }
        newKYCShareholders.push(data);
      }

      let deleteShareholderIds = kycShareholdersReserved
        .filter(elOne => {
          return !newKYCShareholders.some(elTwo => {
            return elOne.id === elTwo.id;
          });
        })
        .map(obj => {
          return obj.id;
        });

      let prepareKYCCompany = {
        company_taxid: seller.kyc_company.company_taxid,
        beneficial_owner_name: seller.kyc_company.beneficial_owner_name,
        beneficial_owner_personal_id: seller.kyc_company.beneficial_owner_personal_id,
        kyc_shareholders: newKYCShareholders,
        upload: kycCompanyUpload,
      };

      let passed = this.validateKYCCompany(prepareKYCCompany, 'rejected');
      if (passed) {
        let uploadData = await this.uploadFile(kycCompanyUpload);
        if (!uploadData) throw new Error('No file uploaded');

        let updateData = prepareKYCCompany;
        updateData.upload = uploadData;
        updateData.deleteShareholders = deleteShareholderIds;
        updateData.fileCheckMode = this.fileCheckMode;
        let url = `${config.npay.apiUrl}/sellers/${seller.uid}/kyc_company_rejected`;
        let response = await http.put(url, { body: updateData });
        if (response.status === 200) {
          Swal.fire({
            customClass: 'nexter-alert',
            title: 'สำเร็จ!',
            text: 'อัปเดตข้อมูลการจดทะเบียนบริษัทเรียบร้อยแล้ว',
            type: 'success',
            confirmButtonText: 'ตกลง',
          }).then(() => {
            setTimeout(() => {
              window.location.href = `${config.web.rootpath}/sellers/detail/${seller.uid}`;
            }, 500);
          });
        } else {
          let body = await response.json();
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'Error!',
            text: body.message,
            confirmButtonText: 'OK',
          });
        }
      } else {
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: 'กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน',
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async updateKYCPersonal(prepareKYCPersonal = null, kycPersonalUpload = null) {
    try {
      this.loading = true;

      let { seller } = this.getData();
      // let prepareKYCPersonal = input && {
      //   name: seller.kyc_personal.name,
      //   personal_id: seller.kyc_personal.personal_id,
      //   upload: kycPersonalUpload,
      // };
      // let passed = this.validateKYCPersonal(prepareKYCPersonal);
      if (prepareKYCPersonal && kycPersonalUpload) {
        let updateData = prepareKYCPersonal;
        let hasUpload = Object.keys(kycPersonalUpload).find(key => kycPersonalUpload[key]);

        if (hasUpload) {
          let uploadData = await this.uploadFile(kycPersonalUpload);
          if (!uploadData) throw new Error('No file uploaded');
          updateData.upload = uploadData;
        } else {
          updateData.upload = {};
        }
        updateData.fileCheckMode = this.fileCheckMode;
        let url = `${config.npay.apiUrl}/sellers/${seller.uid}/kyc_personal`;
        let response = await http.put(url, { body: updateData });
        if (response.status === 200) {
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'success',
            title: 'Success!',
            text: 'บันทึกข้อมูลสำเร็จ',
            confirmButtonText: 'CONTINUE',
          }).then(async () => {
            window.location.href = `${config.npay.cmsUrl}/sellers`;
          });
        } else {
          let body = await response.json();
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'Error!',
            text: body.message,
            confirmButtonText: 'OK',
          });
        }
      } else {
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: 'กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน',
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      Swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: 'เกิดข้อผิดพลาด!',
        text: 'กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน',
        confirmButtonText: 'OK',
      });
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  withImagesKycCompany(data) {
    if (data.kyc_company && !_.isEmpty(data.kyc_company.kyc_files)) {
      for (const kyc_files of data.kyc_company.kyc_files) {
        switch (kyc_files.file_type) {
          case 'BOA':
            data.kyc_company.beneficial_owner_authorization = kyc_files.file_url;
            break;
          case 'BOJ5':
            data.kyc_company.boj5 = kyc_files.file_url;
            break;
          case 'BOO':
            data.kyc_company.beneficial_owner = kyc_files.file_url;
            break;
          case 'CERT':
            data.kyc_company.certificate = kyc_files.file_url;
            break;
          case 'IDEN':
            data.kyc_company.identification = kyc_files.file_url;
            break;
          case 'OTHER':
            data.kyc_company.other = kyc_files.file_url;
            break;
          case 'VAT20':
            data.kyc_company.vat20 = kyc_files.file_url;
            break;
          default:
            break;
        }
      }
    }
    return data;
  }

  setSellerId(id) {
    this.seller.id = id;
  }

  async getSellerByUid(uid, channel_uid = 'no-channel-uid') {
    try {
      this.loading = true;
      this.seller.delete_status = '';
      let url = `${config.npay.apiUrl}/sellers/uid/${uid}/${channel_uid}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.seller = this.withImagesKycCompany(body.data);
        if (this.seller.is_active === null) this.seller.is_active = false;

        if (this.seller.sellers_account) {
          this.seller.sellers_account.bank_account_image_preview = this.seller.sellers_account.bank_account_image;
        }

        if (this.seller.kyc_type === 'company') {
          if (this.seller.kyc_company !== null) {
            if (this.seller.kyc_company.status === 'created') {
              this.seller.kyc_company.kyc_shareholders.push({
                tax_id: '',
                name: '',
                lastname: '',
                type: '',
                kyc_shareholders_committee: [],
              });
            }

            if (this.seller.kyc_company.status === 'rejected' || this.seller.kyc_company.status === 'incomplete' || this.seller.kyc_company.status === 'created') {
              this.kycShareholdersReserved = [...this.seller.kyc_company.kyc_shareholders];
            }
          }
        }

        if (!this.seller.channel_seller) {
          this.seller.channel_seller = {};
          this.seller.channel_seller.service_charge_rate = 0;
          this.seller.channel_seller.service_charge_absorb = true;
        }

        let data = this.getData();
        this.rawSeller = data.seller;

        this.getHistoryLogsBySeller();
      } else {
        let body = await response.json();
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          html: body.message,
          confirmButtonText: 'OK',
        });
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async getSellerById(id, channel_uid = 'no-channel-uid') {
    try {
      this.loading = true;
      this.seller.delete_status = '';
      let body = { id: id, channel_uid: channel_uid };
      let url = `${config.npay.apiUrl}/sellers/no-uid`;

      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        this.seller = body.data;
        if (this.seller.is_active === null) this.seller.is_active = false;

        if (this.seller.sellers_account) {
          this.seller.sellers_account.bank_account_image_preview = this.seller.sellers_account.bank_account_image;
        }

        if (this.seller.kyc_type === 'company') {
          if (this.seller.kyc_company !== null) {
            if (this.seller.kyc_company.status === 'created') {
              this.seller.kyc_company.kyc_shareholders.push({
                tax_id: '',
                name: '',
                lastname: '',
                type: '',
                kyc_shareholders_committee: [],
              });
            }

            if (this.seller.kyc_company.status === 'rejected' || this.seller.kyc_company.status === 'incomplete' || this.seller.kyc_company.status === 'created') {
              this.kycShareholdersReserved = [...this.seller.kyc_company.kyc_shareholders];
            }
          }
        }

        let data = this.getData();
        this.rawSeller = data.seller;

        this.getHistoryLogsBySeller();
      } else {
        let body = await response.json();
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          html: body.message,
          confirmButtonText: 'OK',
        });
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async previewSeller() {
    if (this.table.selection.length <= 0) {
      console.warn('No Selection');
    } else {
      try {
        this.loading = true;
        let body = { sellers: this.table.selection };
        let url = `${config.npay.apiUrl}/admin/previewseller`;
        let response = await http.post(url, { body });
        // console.log('response', response);

        if (response.status === 200) {
          let body = await response.json();
          console.log('body', body);

          window.open(body.data.url);
        } else {
          let body = await response.json();
          throw new Error(body.message);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    }
  }

  async excelSeller(options = {}) {
    if (this.table.selection.length <= 0) {
      console.warn('No Selection');
    } else {
      try {
        this.loading = true;
        let body = { sellers: this.table.selection, source: options.page };
        let url = `${config.npay.apiUrl}/admin/excelseller`;
        let response = await http.post(url, { body });
        // console.log('response', response);

        if (response.status === 200) {
          let body = await response.json();
          // console.log('body', body);

          window.open(body.data.url);
        } else {
          let body = await response.json();
          throw new Error(body.message);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    }
  }

  async previewSellerKYC(sellerUid) {
    try {
      this.loading = true;
      let body = { seller: sellerUid };
      let url = `${config.npay.apiUrl}/admin/previewsellerkyc`;
      let response = await http.post(url, { body });

      if (response.status === 200) {
        let body = await response.json();
        window.open(body.data.url);
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async exportExcelSellerCommittee(options = {}) {
    try {
      this.loading = true;
      let qs = this.getConditionQuery();
      let page = `${options.page} (Dealer Committee)`;
      let url = `${config.npay.apiUrl}/admin/excelsellerCommittee?mode=export&source=${page}${qs}`;
      let response = await http.post(url, {});
      let body = await response.json();
      if (response.status === 200) {
        if (body.count !== 0) {
          window.open(body.data.url);
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'info',
            title: 'สำเร็จ',
            text: 'Export ' + body.count + ' sellers',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'warning',
            title: 'ไม่พบ sellers',
            text: 'ไม่พบ seller ตาม criteria ที่ระบุ',
            confirmButtonText: 'OK',
          });
        }
      } else {
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'info',
          html: body.message,
          confirmButtonText: 'OK',
        });
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async validateNotNullFilter() {
    this.filter.validate = false;
    let promise = new Promise((resolve, reject) => {
      const filter = this.filter;
      if (filter.seller_name) {
        this.filter.validate = true;
      }
      if (filter.seller_account_status_selection_export.length > 0) {
        this.filter.validate = true;
      }
      if (filter.seller_selection_export_first.length > 0) {
        this.filter.validate = true;
      }
      if (filter.seller_export_start_date) {
        this.filter.validate = true;
      }
      if (filter.seller_export_end_date) {
        this.filter.validate = true;
      }
      setTimeout(() => resolve(this.filter.validate), 100);
    });
    return await promise;
  }

  getConditionQuery() {
    let url = '';
    let { filter } = this.getData();
    const committee = filter.committee;
    url += `&name=${filter.seller_name}`;
    url += `&code=${filter.seller_code}`;
    url += `&uid=${filter.channel_id ? filter.channel_id.value : ''}`;
    url += `&email=${filter.email}`;
    url += `&order_by=seller_created_at`;
    url += `&committee=${committee}`;
    url += `&account_status=${filter.seller_account_status_selection_export.join('-')}`;
    url += `&kyc_status=${filter.seller_list_kyc_status_selection.join('-')}`;
    url += `&type=${filter.seller_type_selection}`;
    url += `&status=${filter.seller_status_selection}`;
    url += `&delete_status=${filter.seller_delete_status_selection.join('-')}`;
    url += `&export_first=${filter.seller_selection_export_first.join('-')}`;
    url += `&date_start=${moment(filter.seller_export_start_date).set({ hour: 0, minute: 0, second: 0 }).valueOf()}`;
    url += `&date_end=${moment(filter.seller_export_end_date).set({ hour: 23, minute: 58, second: 59 }).valueOf()}`;
    return url;
  }

  async exportExcelSellerT2P(options = {}) {
    const validateResult = await this.validateNotNullFilter();
    if (!validateResult) {
      Swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        html: 'กรุณาเลือก Criteria ก่อนกด Export ข้อมูล',
        confirmButtonText: 'OK',
      });
    } else {
      try {
        this.loading = true;
        let qs = this.getConditionQuery();
        let page = `${options.page} (Dealer T2P)`;
        let url = `${config.npay.apiUrl}/admin/excelseller/t2p?mode=export&source=${page}${qs}`;
        // url += `&page=${1}`;
        // url += `&limit=${99999}`;

        let response = await http.post(url, {});
        let body = await response.json();
        if (response.status === 200) {
          if (body.count !== 0) {
            window.open(body.data.url);
            Swal.fire({
              customClass: 'nexter-alert',
              type: 'info',
              title: 'สำเร็จ',
              text: 'Export ' + body.count + ' sellers',
              confirmButtonText: 'OK',
            });
          } else {
            Swal.fire({
              customClass: 'nexter-alert',
              type: 'warning',
              title: 'ไม่พบ sellers',
              text: 'ไม่พบ seller ตาม criteria ที่ระบุ',
              confirmButtonText: 'OK',
            });
          }
        } else {
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'info',
            html: body.message,
            confirmButtonText: 'OK',
          });
          throw new Error(body.message);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          // this.fetchSellersWithPagination();
          this.loading = false;
        }, 500);
      }
    }
  }

  async exportExcelSellerAccountT2P() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/excelseller/t2p/account`;
      let response = await http.post(url, {});
      let body = await response.json();
      if (response.status === 200) {
        window.open(body.data.url);
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'info',
          title: 'สำเร็จ',
          text: 'ตรวจพบ ' + body.count + 'sellerใหม่ หรือ sellerที่ถูกแก้ไขข้อมูล',
          confirmButtonText: 'OK',
        });
      } else {
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async getHistoryLogsBySeller() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/historylogs`;
      let body = {
        parent: 'seller',
        parent_id: this.seller.id,
        entity: ['sellers', 'sellers_account', 'channels_sellers', 'kyc_personals', 'kyc_companies', 'kyc_files', 'kyc_shareholders', 'kyc_shareholders_committee'],
      };
      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        this.logs = body.data || [];
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async checkCanRequestDeleteSeller(sellerId) {
    try {
      this.loading = true;
      const { seller } = this.getData();
      let url = `${config.npay.apiUrl}/sellers/id/${sellerId}/delete/check-request`;
      let response = await http.get(url);
      let body = await response.json();
      if (response.status === 200) {
        if (body.result) {
          Swal.fire({
            customClass: 'nexter-alert',
            type: 'warning',
            text: 'คุณต้องการยืนยันส่งคำขอเพื่อลบข้อมูลผู้ขายรายนี้ออกจากระบบ?',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
          }).then(async result => {
            if (result.value) {
              await this.requestDeleteSeller(sellerId);
            }
          });
        }
      } else {
        if (body.code === 'B0024') {
          Swal.fire({
            customClass: 'nexter-alert nexter-alert-user-manage',
            type: 'error',
            title: 'ไม่สามารถลบข้อมูลได้',
            html: `เนื่องจากมีรายการ payment คงค้างหรือมีรายการรอโอนเงินค้างอยู่ในระบบ กรุณาทำรายการ payment ที่คงค้างหรือโอนเงินทั้งหมด ก่อนขอลบใหม่อีกครั้ง<br/><br/>อีกทั้งกรุณา${
              seller.is_active ? 'เปลี่ยน' : 'คง'
            }สถานะผู้ขายรายนี้เป็น Inactive เพื่อไม่ให้มี transaction ใหม่เข้ามาในระบบก่อนลบ`,
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: seller.is_active ? 'เปลี่ยนเป็น Inactive' : 'รับทราบ',
            cancelButtonText: 'ยกเลิก',
          }).then(async result => {
            if (result.value && seller.is_active) {
              this.changeIsActive(false);
              await this.updateSellerDetail(null, {
                disabledSuccessModal: true,
                onSuccess: () => {
                  Swal.fire({
                    customClass: 'nexter-alert nexter-alert-user-manage',
                    type: 'success',
                    title: 'Success',
                    text: 'เปลี่ยนสถานะสำเร็จ',
                    confirmButtonText: 'รับทราบ',
                  });
                },
              });
            }
          });
        } else {
          throw new Error(body.message);
        }
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: 'Error!',
        text: err?.message,
        confirmButtonText: 'OK',
      });
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async requestDeleteSeller(sellerId) {
    try {
      this.loading = true;
      const { seller } = this.getData();
      let url = `${config.npay.apiUrl}/sellers/id/${sellerId}/delete/status`;
      let payload = {
        status: 'requested',
      };
      let response = await http.put(url, { body: payload });
      let body = await response.json();
      if (response.status === 200) {
        Swal.fire({
          customClass: 'nexter-alert',
          imageUrl: iEmailOutline,
          imageHeight: 64,
          html: 'ส่งคำขอเพื่อลบผู้ขายรายนี้แล้ว<br/>กรุณารอการอนุมัติ',
          confirmButtonText: 'รับทราบ',
        });
        let sellerUid = seller.uid;
        let channelUid = seller.channel_seller?.channel.uid;
        if (sellerUid && channelUid) {
          await this.getSellerByUid(sellerUid, channelUid);
        }
      } else {
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: 'Error!',
        text: err?.message,
        confirmButtonText: 'OK',
      });
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async confirmRejectDeleteSeller(sellerId) {
    try {
      let customClass = {
        popup: 'nexter-alert nexter-alert-user-manage',
        confirmButton: 'btn disabled',
      };
      Swal.fire({
        customClass,
        title: 'ปฏิเสธการลบข้อมูลผู้ขายนี้',
        input: 'textarea',
        inputAttributes: {
          placeholder: 'กรุณาระบุเหตุผลในการปฏิเสธ',
        },
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ยืนยัน',
        onOpen: function () {
          Swal.getInput().addEventListener('keyup', function (e) {
            if (e.target.value === '') {
              Swal.update({ customClass });
            } else {
              Swal.update({ customClass: { ...customClass, confirmButton: '' } });
            }
          });
        },
      }).then(async result => {
        if (result.value) {
          await this.rejectDeleteSeller(sellerId, result.value);
        }
      });
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async rejectDeleteSeller(sellerId, rejectReason = '') {
    try {
      this.loading = true;
      const { seller } = this.getData();
      let url = `${config.npay.apiUrl}/sellers/id/${sellerId}/delete/status`;
      let payload = {
        status: 'rejected',
        reject_reason: rejectReason,
      };
      let response = await http.put(url, { body: payload });
      let body = await response.json();
      if (response.status === 200) {
        Swal.fire({
          customClass: 'nexter-alert nexter-alert-user-manage',
          type: 'success',
          title: 'คำขอลบผู้ขายได้ถูกปฏิเสธแล้ว',
          text: `เนื่องจาก: ${rejectReason}`,
          confirmButtonText: 'รับทราบ',
        });
        let sellerUid = seller.uid;
        let channelUid = seller.channel_seller?.channel.uid;
        if (sellerUid && channelUid) {
          await this.getSellerByUid(sellerUid, channelUid);
        }
      } else {
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: 'Error!',
        text: err?.message,
        confirmButtonText: 'OK',
      });
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async preConfirmRejectReasonDeleteSeller() {
    try {
      const { seller } = this.getData();
      const { delete_status: deleteStatus, reject_delete_reason: rejectDeleteReason } = seller;
      if (deleteStatus !== 'rejected' || !rejectDeleteReason) {
        return;
      }
      Swal.fire({
        customClass: 'nexter-alert nexter-alert-user-manage',
        type: 'error',
        title: 'ปฏิเสธการลบข้อมูลนี้',
        text: `เนื่องจาก: ${seller.reject_delete_reason}`,
        confirmButtonText: 'ยืนยันรับทราบข้อมูล',
        allowOutsideClick: false,
      }).then(async result => {
        if (result.value) {
          await this.confirmRejectReasonDeleteSeller(seller.id, {
            onSuccess: async () => {
              let sellerUid = seller.uid;
              let channelUid = seller.channel_seller?.channel.uid;
              if (sellerUid && channelUid) {
                await this.getSellerByUid(sellerUid, channelUid);
              }
            },
          });
        }
      });
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async approveDeleteSeller(sellerId) {
    try {
      const result = await Swal.fire({
        customClass: 'nexter-alert',
        type: 'warning',
        text: 'คุณต้องการยืนยันลบข้อมูลผู้ขายรายนี้ออกจากระบบ?',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      });
      if (!result.value) {
        return;
      }
      this.loading = true;
      const { seller } = this.getData();
      let url = `${config.npay.apiUrl}/sellers/id/${sellerId}/delete/status`;
      let payload = {
        status: 'approved',
      };
      let response = await http.put(url, { body: payload });
      let body = await response.json();
      if (response.status === 200) {
        Swal.fire({
          customClass: 'nexter-alert nexter-alert-user-manage',
          type: 'success',
          title: 'ลบข้อมูลผู้ขายสำเร็จ',
          html: 'หากต้องการนำข้อมูลผู้ขายกลับมา<br/>คุณสามารถกดปุ่ม "Re-approve seller" ในระบบได้',
          confirmButtonText: 'รับทราบ',
        });
        let sellerUid = seller.uid;
        let channelUid = seller.channel_seller?.channel.uid;
        if (sellerUid && channelUid) {
          await this.getSellerByUid(sellerUid, channelUid);
        }
      } else {
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: 'Error!',
        text: err?.message,
        confirmButtonText: 'OK',
      });
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async reApproveDeleteSeller(sellerId) {
    try {
      const result = await Swal.fire({
        customClass: 'nexter-alert',
        type: 'warning',
        text: 'คุณต้องการยืนยันให้ผู้ขายรายนี้สามารถกลับมาใช้งานได้อีกครั้ง?',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      });
      if (!result.value) {
        return;
      }
      this.loading = true;
      const { seller } = this.getData();
      let url = `${config.npay.apiUrl}/sellers/id/${sellerId}/delete/status`;
      let payload = {
        status: 'reapproved',
      };
      let response = await http.put(url, { body: payload });
      let body = await response.json();
      if (response.status === 200) {
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          text: 'ข้อมูลผู้ขายสามารถกลับมาใช้งานได้อีกครั้ง',
        });
        let sellerUid = seller.uid;
        let channelUid = seller.channel_seller?.channel.uid;
        if (sellerUid && channelUid) {
          await this.getSellerByUid(sellerUid, channelUid);
        }
      } else {
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: 'Error!',
        text: err?.message,
        confirmButtonText: 'OK',
      });
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async confirmRejectReasonDeleteSeller(sellerId, options = {}) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/sellers/id/${sellerId}/delete/status`;
      let payload = {
        status: 'reject_confirmed',
      };
      let response = await http.put(url, { body: payload });
      let body = await response.json();
      if (response.status === 200) {
        options.onSuccess && options.onSuccess();
      } else {
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: 'Error!',
        text: err?.message,
        confirmButtonText: 'OK',
      });
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async resubmitOpnRecipient() {
    try {
      const result = await Swal.fire({
        customClass: 'nexter-alert',
        text: 'ยืนยันการส่งข้อมูลหา OPN อีกครั้ง?',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      });
      if (!result.value) {
        return;
      }

      this.loading = true;
      const { seller } = this.getData();
      const sellerUid = seller.uid;
      const channelUid = seller.channel_seller?.channel.uid;
      if (!sellerUid || !channelUid) {
        return;
      }

      let url = `${config.npay.apiUrl}/admin/sellers/resubmit-opn-recipient/${sellerUid}/${channelUid}`;
      let response = await http.post(url);
      let body = await response.json();
      if (response.status === 200) {
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          text: 'ข้อมูลถูกส่งหา OPN เรียบร้อยแล้ว',
        });

        await this.getSellerByUid(sellerUid, channelUid);
      } else {
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: 'Error!',
        text: err?.message,
        confirmButtonText: 'OK',
      });
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
}
