import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import _ from 'lodash';
import { config } from '../../config';
import { http } from '../../utils/http';
import { Table, Row, Col, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Page } from '../../components/Page';
import { EmptyRow } from '../../components/Table/EmptyRow';
import { Pagination } from '../../components/Pagination/Pagination';
import { ensureJSONparse } from '../../utils/string';
import NexterButton from '../../components/Button/NexterButton';

const FIELD = {
  installment_interests: {
    terms: 'Period (Month)',
    default_cost: 'Channel MDR',
    cost: 'Gateway MDR',
    minimum_amount: 'Min',
    status: 'Status'
  },
  payment_methods_fee: {
    fixed_cost_fee: 'Cost Baht (บาท)',
    default_fee_type: 'Type',
    default_fixed_cost_fee: 'Default Baht (บาท)',
    default_percent_fee: 'Default Percent (%)',
    percent_fee: 'Cost Percent (%)'
  },
  promotions: {
    promotion_name: 'ชื่อโปรโมชั่น',
    promotion_desc: 'รายละเอียดโปรโมชั่น',
    thumbnail_url: 'ภาพโปรโมชั่น (ตัวอย่าง)',
    is_active: 'เลือก Status',
    bank: 'รายละเอียดโปรโมชั่นบัตรเครดิต (ธนาคาร)',
    start_date: 'วันที่เริ่มโปรโมชั่น',
    end_date: 'วันที่สิ้นสุดโปรโมชั่น',
    file_url: 'ภาพโปรโมชั่นแบบเต็ม',
    deleted_at: 'Remove โปรโมชั่น'
  },
  WHT: {
    config_value: 'Cost',
  },
  VAT: {
    config_value: 'Cost',
  },
  channel_promotions: {
    name: 'Channel',
    is_active: 'สถานะ Promotion',
    nameNew: 'Channel',
    nameOld: 'Channel'
  },
  promotions_payment_methods: {
    description: 'Payment Method',
    is_active: 'สถานะ Payment Method'
  },
  channelmid: {
    active: 'สถานะ MID',
    merchant_id: 'Merchant ID',
    secret_key: 'Secret key',
  },
  channelmidopn : {
    active: 'สถานะ MID',
    merchant_id: 'Merchant ID',
    secret_key: 'Secret key',
    public_key: 'Public key',
    opn_holding_settled: 'Holding date',
  }
}

const conditions = {
  payment_methods_option: {},
  payment_methods_fee: {},
  installment_interests: {
    1: 'BAY',
    2: 'BBL',
    3: 'FCC',
    4: 'BANK',
    5: 'KTC',
    6: 'SCB',
    8: 'UOB',
    9: 'TBANK',
    10: 'TTB',
    11: 'TTB'
  }
}

const t = (entity, field) => {
  const translateField = _.get(FIELD, [entity, field], field)
  return translateField
}

class SystemConfigHistoryLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      logs: [],
      page: 1,
      size: 10,
      total: 1,
      stateValue: [],
      paymentOptions: [],
    };
  }

  async componentDidMount() {
    await Promise.all([
      this.fetchHistoryLogs(),
      this.fetchPaymentOptions(),
    ]);
  }

  async fetchHistoryLogs() {
    try {
      this.setState({ loading: true });
      const tab = new URL(window.location.href).searchParams.get('tab')
      let entity = [
        'installment_interests',
        'payment_methods_option',
        'payment_methods_fee',
        'promotions',
        'promotions_payment_methods',
        'channel_promotions',
        'WHT','VAT',
        'MAXIMUM_DUE_DATE',
        'DEFAULT_DUE_DATE',
        'Void_T2P_Actual_Time',
        'Void_2C2P_Actual_Time',
        'Void_T2P_Admin_Time',
        'Void_2C2P_Admin_Time',
        'Void_T2P_Channel_Time',
        'Void_2C2P_Channel_Time',
        'System_Email',
        'channelmid',
        'channelmidopn',
      ]
      switch (tab) {
        case 'payment_option':
          entity = ['payment_methods_option']
          break;
        case 'transaction_fee':
          entity = ['payment_methods_fee', 'WHT', 'VAT']
          break;
        case 'due_of_payment':
          entity = ['MAXIMUM_DUE_DATE', 'DEFAULT_DUE_DATE']
          break;
        case 'void':
          entity = ['Void_T2P_Actual_Time', 'Void_2C2P_Actual_Time', 'Void_T2P_Admin_Time', 'Void_2C2P_Admin_Time', 'Void_T2P_Channel_Time', 'Void_2C2P_Channel_Time']
          break;
        case 'installment_interest':
          entity = ['installment_interests']
          break;
        case 'promotion':
          entity = ['promotions', 'channel_promotions', 'promotions_payment_methods']
          break;
        case 'mid_2c2p':
          entity = ['channelmid']
          break;
        case 'mid_opn':
          entity = ['channelmidopn']
          break;
        case 'other':
          entity = ['System_Email']
          break;
        default:
          break;
      }
      let url = `${config.npay.apiUrl}/historylogs`;
      let body = {
        page: this.state.page,
        size: this.state.size,
        parent: 'system',
        entity: entity,
        action_name: [ 'update', 'create', 'delete' ],
      };

      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        this.setState({
          logs: body.data || [],
          total: body.total || 1,
        });
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 100);
    }
  }

  async fetchPaymentOptions() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/paymentmethods`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.setState({
          paymentOptions: body.data,
        });
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  selectPage(page) {
    this.setState({ page }, async () => {
      await this.fetchHistoryLogs();
    });
  }

  getTitle = () => {    
    let title = 'promotions_payment_methods'
    const tab = new URL(window.location.href).searchParams.get('tab')
    switch (tab) {
      case 'payment_option':
        title = 'Payment Option'
        break;
      case 'transaction_fee':
        title = 'Transaction Fee'
        break;
      case 'due_of_payment':
        title = 'Due of Payment'
        break;
      case 'void':
        title = 'Void'
        break;
      case 'installment_interest':
        title = 'Installment Interest'
        break;
      case 'promotion':
        title = 'Promotion'
        break;
      case 'mid_2c2p':
        title = 'MID 2C2P'
        break;
      case 'mid_opn':
        title = 'MID OPN'
        break;
      case 'other':
        title = 'Other'
        break;
      default:
        break;
    }
    return title
  }

  getConditionValue() {
    let values = { ...conditions };
    const paymentOptions = this.state.paymentOptions;
    values.payment_methods_option = _.mapValues(_.keyBy(paymentOptions, 'id'), 'name');
    values.payment_methods_fee = _.mapValues(_.keyBy(paymentOptions, 'id'), 'name');
    return values;
  }

  modalToggle = (data) => {
    this.setState({
      modal: !this.state.modal,
      stateValue: data,
    });
  };

  render() {
    const { logs, loading } = this.state;
    const conditionValues = this.getConditionValue();
    let arrayChannel = []

    let rows = logs.length ? (
      logs.map((item, index) => {
      const oldValue = ensureJSONparse(item.old_value);
      const newValue = ensureJSONparse(item.new_value);
      let actionName = _.get(item, 'action_name', '');
      let list = newValue !== null && !_.isEmpty(newValue) ? newValue : oldValue;
      const entity = _.get(item, 'entity')
      if (entity === 'channel_promotions' && actionName === 'create') {
        list = {
          name: newValue.name
        }
      }
      if (entity === 'promotions' && actionName === 'create') {
        list = {
          promotion: newValue.promotion_name
        }
      }
      if (['channelmid', 'channelmidopn'].includes(entity) && actionName === 'create') {
        list = {
          new: newValue.label
        }
      }

      let result = Object.entries(list)
        .filter(([key, value], i) => !['role_id', 'channel_id'].includes(key))
        .map(([key, value], i) => {
          const refId = _.get(item, 'ref_id')
          let field = t(entity, key);
          let ov = _.get(oldValue, key);
          let nv = _.get(newValue, key);
          let item_updated = entity === 'payment_methods_option' || entity === 'payment_methods_fee' || entity === 'installment_interests' ? conditionValues[entity][refId] : '-';
          if (key === 'status') {
            ov = ov.replace(/./, c => c.toUpperCase())
            nv = nv.replace(/./, c => c.toUpperCase())
          }
          let entityCheck = [
            'DEFAULT_DUE_DATE',
            'MAXIMUM_DUE_DATE',
            'VAT',
            'WHT',
            'Void_T2P_Actual_Time',
            'Void_2C2P_Actual_Time',
            'Void_T2P_Admin_Time',
            'Void_2C2P_Admin_Time',
            'Void_T2P_Channel_Time',
            'Void_2C2P_Channel_Time',
            'System_Email',
          ]
          if (entityCheck.includes(entity)) {
            let words = value.split('lll')
            item_updated = words[1]
            nv = words[0]
          }
          let updatedBy = ''
          if (entity === 'promotions') {
            let data = _.get(item, 'updated_by', '-')
            let words = data.split('lll')
            item_updated = 'ชื่อโปรโมชั่น : ' + words[1]
            updatedBy = words[0]
            if (actionName === 'create') {
              field = words[1]
            }
          }
          if (entity === 'channel_promotions') {
            let data = _.get(item, 'updated_by', '-')
            let words = data.split('lll')
            item_updated = 'ชื่อโปรโมชั่น : ' + words[2]
            updatedBy = words[0]
          }
          if (field === 'วันที่เริ่มโปรโมชั่น' || field === 'วันที่สิ้นสุดโปรโมชั่น') {
            nv = moment(nv).format('DD-MM-YYYY HH:mm')
            ov = moment(ov).format('DD-MM-YYYY HH:mm')
          }
          if (field === 'เลือก Status' || field === 'สถานะ Promotion') {
            nv = nv ? 'Active' : 'Inactive'
            ov = ov ? 'Active' : 'Inactive'
          }
          if (field === 'Remove โปรโมชั่น') {
            actionName = 'delete'
            nv = '-'
            ov = '-'
          }
          if (['channelmid', 'channelmidopn'].includes(entity)) {
            if (_.isString(value)) {
              let words = value.split('lll')
              nv = words[0]
              let data = _.get(item, 'updated_by', '-')
              let name = data.split('lll')
              updatedBy = name[0]
              item_updated = name[1]
            }
          }
          if (field === 'สถานะ MID') {
            nv = nv === true || nv === 'true' ? 'Active' : 'Inactive'
            ov = ov === true || ov === 'true' ? 'Active' : 'Inactive'
          }
          if (entity === 'channel_promotions' && actionName === 'update') {
            field = field === 'newValue' ? 'Channel Promotion' : field;
            nv = newValue.newValue
            if (nv === 'AllChannel') {
              nv = 'All Channel'
            } else {
              let valueState = this.state.stateValue
              if (valueState.length > 0) {
                let valueArr = valueState.split(',')
                arrayChannel = valueArr
              }
            }
            ov = oldValue.oldValue
            if (ov === 'AllChannel') {
              ov = 'All Channel'
            } else {
              let valueState = this.state.stateValue
              if (valueState.length > 0) {
                let valueArr = valueState.split(',')
                arrayChannel = valueArr
              }
            }
          }
          if (entity === 'promotions_payment_methods' && field === 'Payment Method' || entity === 'promotions_payment_methods' && field === 'สถานะ Payment Method') {
            let data = _.get(item, 'updated_by', '-')
            let words = data.split('lll')
            item_updated = 'ชื่อโปรโมชั่น : ' + words[1]
            updatedBy = words[0]
            field = 'Payment Method : ' + words[2]
            nv = nv ? 'Active' : 'Inactive'
            ov = ov ? 'Active' : 'Inactive'
            actionName = 'update'
          }
          if (entity === 'System_Email') {
            item_updated = 'System Email'
            field = 'Email'
          }
          if (entity === 'Void_2C2P_Actual_Time') {
            item_updated = 'Actual Time'
            field = '2C2P'
          }
          if (entity === 'Void_T2P_Actual_Time') {
            item_updated = 'Actual Time'
            field = 'T2P'
          }
          if (entity === 'Void_2C2P_Channel_Time') {
            item_updated = 'Channel Time'
            field = '2C2P'
          }
          if (entity === 'Void_T2P_Channel_Time') {
            item_updated = 'Channel Time'
            field = 'T2P'
          }
          if (entity === 'Void_2C2P_Admin_Time') {
            item_updated = 'Admin Time'
            field = '2C2P'
          }
          if (entity === 'Void_T2P_Admin_Time') {
            item_updated = 'Admin Time'
            field = 'T2P'
          }
          if (['channelmid', 'channelmidopn'].includes(entity) && field === 'new') {
            field = 'New Channel MID'
          }
          if (entity === 'DEFAULT_DUE_DATE') {
            item_updated = 'Default'
            field = 'Default Due Date (วัน)'
          }
          if (entity === 'MAXIMUM_DUE_DATE') {
            item_updated = 'Maximum'
            field = 'Maximum Due Date (วัน)'
          }
          return (
            <tr key={`item-${item.id}-${i}`}>
              <td width="142">
                {moment(_.get(item, 'updated_at', '-')).format('DD-MM-YYYY HH:mm')}
              </td>
              <td width="152">{updatedBy ? updatedBy : _.get(item, 'updated_by', '-')}</td>
              <td width="150">{item_updated}</td>
              <td width="80">
                <div className={`action-wrapper ${actionName}`}>{`${actionName}`}</div>
              </td>
              <td width="150" className="keys">
                {field}
              </td>
              <td width="150">
                {ov && key === 'newValue' && ov !== 'All Channel' ? 
                  <NexterButton className={ov ? null : 'w-100'} size="sm" color="dark" onClick={() => this.modalToggle(ov)}>
                    Channel list
                  </NexterButton> :
                ov ? ov : <span className="data-is-null">-</span>}
              </td>
              <td width="150">
                {nv && key === 'newValue' && nv !== 'All Channel' ? 
                  <NexterButton className={nv ? null : 'w-100'} size="sm" color="dark" onClick={() => this.modalToggle(nv)}>
                    Channel list
                  </NexterButton> :
                nv && !(key === 'create') ? nv : <span className="data-is-null">-</span>}
              </td>
            </tr>
          )
        })
      return result;
      })
    ) : (
      <EmptyRow colSpan={7} />
    );

    const channelModal = (
      <Modal className="modal-channel" isOpen={this.state.modal} toggle={() => this.fetchHistoryLogs} onClosed={() => this.fetchHistoryLogs()}>
        <ModalBody>
          <h6 className="pb-2">Channel list</h6>
          <ul style={{ listStyleType: 'none' }} className="p-0 m-0">
            {arrayChannel.map((item, index) => (
              <li key={index} style={{ padding: '0.75rem 0' }}>
                {item}
              </li>
            ))}
          </ul>
        </ModalBody>
        <ModalFooter className="seller-modal-footer">
            <Row>
              <Col>
                <NexterButton onClick={() => this.modalToggle(true)} className="btn-sm text-capitalize">
                  Done
                </NexterButton>
              </Col>
            </Row>
          </ModalFooter>
      </Modal>
    );

    return (
      <Fragment>
        <Page loading={loading}>
          <div className="historylog-wrapper">
          <h5 className="title-logs" style={{ marginTop: 4, marginBottom: 0 }}>{`ประวัติการแก้ไข ${this.getTitle()}`}</h5>
            <hr />
            <Table>
              <thead>
                <tr>
                  <th className="border-0">วันที่แก้ไข</th>
                  <th className="border-0">แก้ไขโดย</th>
                  <th className="border-0">รายการที่แก้ไข</th>
                  <th className="border-0">ชนิดการแก้ไข</th>
                  <th className="border-0">ข้อมูลที่แก้ไข</th>
                  <th className="border-0">ข้อมูลเดิม</th>
                  <th className="border-0">ข้อมูลใหม่</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
            <hr />
            <Row>
              <Col />
              <Col>
                <Pagination
                  className="pull-right"
                  activePage={this.state.page}
                  displayPageNumber={5}
                  maximumPageNumber={Math.ceil(this.state.total / this.state.size)}
                  onSelect={page => this.selectPage(page)}
                />
              </Col>
            </Row>
          </div>

          {channelModal}
        </Page>
      </Fragment>
    );
  }
}

export default inject('system')(observer(SystemConfigHistoryLog));
