import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, Col, Table } from 'reactstrap';
import { config } from '../../config';
import { Page } from '../../components/Page';
import Visibility from '../../components/Visibility/Visibility';
import NexterButton from '../../components/Button/NexterButton';
import Swal from 'sweetalert2';
/* TimePicker Component */
import { TimePicker } from 'antd';
import moment from 'moment';
// import { CloseCircleOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';

class VoidPage extends Component {
  constructor() {
    super();
    this.state = {
      voidData: [],
    };
  }

  componentDidMount() {
    this.loading = true;
    const voidDataConfig = this.props.system.void_settings;
    let voidData = JSON.parse(JSON.stringify(voidDataConfig));
    this.setState({ voidData: voidData });
    this.loading = false;
  }

  changeVoidData = (key, type, mtime, mString) => {
    let voidData = this.state.voidData.map(item => {
      if (item.gateway === key) {
        if (type === 'actual') {
          item.actual_time = mString;
        } else if (type === 'admin') {
          if (mString > item.actual_time) {
            Swal.fire({
              customClass: 'nexter-alert',
              type: 'warning',
              title: 'Validation Fail',
              html: '<li>The admin time can not be after the actual time.</li>',
              showCancelButton: false,
            });
            throw 'The admin time can not be after the actual time.';
          } else {
            item.admin_time = mString;
          }
        } else if (type === 'channel') {
          if (mString > item.actual_time) {
            Swal.fire({
              customClass: 'nexter-alert',
              type: 'warning',
              title: 'Validation Fail',
              html: '<li>The channel time can not be after the actual time.</li>',
              showCancelButton: false,
            });
            throw 'The channel time can not be after the actual time.';
          } else {
            item.channel_time = mString;
          }
        }
      }
      return item;
    });

    this.setState({ voidData: voidData });
    this.forceUpdate();
  };

  editVoidDataBtn() {
    this.props.system.editableT2 = true;
  }

  saveVoidData() {
    this.props.system.editableT2 = false;
    this.props.system.updateVoidDataConfig(this.state.voidData);
  }

  cancelVoidData() {
    this.props.system.editableT2 = false;
    const voidData = this.props.system.void_settings;
    this.setState({ voidData: voidData });
  }

  render() {
    const { loading, editableT2: voidEditable } = this.props.system.getData();

    // const gatewayList = [
    //   { gateway: '2C2P', actual_time: new Date(), admin_time: new Date(), channel_time: new Date() },
    //   { gateway: 'T2P', actual_time: new Date(), admin_time: new Date(), channel_time: new Date() },
    // ];

    const voidInputs = this.state.voidData.map((item, index) => {
      return (
        <tr key={index}>
          <th>{item.gateway}</th>
          <td>
            <TimePicker
              defaultValue={moment(item.actual_time, 'HH:mm:ss')}
              format={'HH:mm:ss'}
              onChange={this.changeVoidData.bind(this, item.gateway, 'actual')}
              disabled={!this.props.system.editableT2}
            />
          </td>
          <td>
            <TimePicker
              defaultValue={moment(item.admin_time, 'HH:mm:ss')}
              format={'HH:mm:ss'}
              onChange={this.changeVoidData.bind(this, item.gateway, 'admin')}
              disabled={!this.props.system.editableT2}
            />
          </td>
          <td>
            <TimePicker
              defaultValue={moment(item.channel_time, 'HH:mm:ss')}
              format={'HH:mm:ss'}
              onChange={this.changeVoidData.bind(this, item.gateway, 'channel')}
              disabled={!this.props.system.editableT2}
            />
          </td>
        </tr>
      );
    });

    return (
      <Page loading={loading}>
        <div className="container-fluid px-0">
          <Row>
            <Col>
              <Card className="no-r-tl" style={{ height: 'calc(100vh - 230px)', minHeight: '320px' }}>
                <CardBody className="pt-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="text-uppercase my-4 system-config-title">Void Setting</h6>
                    {voidEditable ? (
                      <div>
                        <NexterButton size="sm" color="secondary" onClick={this.cancelVoidData.bind(this)} outline>
                          CANCEL
                        </NexterButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <NexterButton size="sm" icon="fa fa-save" color="success" onClick={this.saveVoidData.bind(this)} outline>
                          SAVE
                        </NexterButton>
                      </div>
                    ) : (
                      <div>
                        <Visibility permission_code="system_configuration:set_void_time">
                          <NexterButton size="sm" icon="fas fa-pencil-alt" onClick={this.editVoidDataBtn.bind(this)}>
                            EDIT
                          </NexterButton>
                        </Visibility>
                        <Link to={`${config.web.rootpath}/system-config/history?tab=void`}>
                          <NexterButton className="ml-3 my-0" size="sm" outline icon="fas fa-history">
                            History Log
                          </NexterButton>
                        </Link>
                      </div>
                    )}
                  </div>
                  <hr className="mt-0 mb-3" />
                  <div className="mt-2">
                    <Table className="void-table">
                      <thead>
                        <tr>
                          <td className="w-p-25">
                            <strong>Gateway</strong>
                          </td>
                          <td className="w-p-25">
                            <strong>Actual Time</strong>
                          </td>
                          <td className="w-p-25">
                            <strong>Admin</strong>
                          </td>
                          <td className="w-p-25">
                            <strong>Channel</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>{voidInputs}</tbody>
                    </Table>
                  </div>
                  <strong>หมายเหตุ:</strong> <span>ไม่สามารถตั้งเวลา void มากกว่าเวลา actual time ของ gateway ได้</span>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Page>
    );
  }
}

export default inject('system')(observer(VoidPage));
