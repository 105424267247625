import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Card, CardBody, CardTitle, CardText, Col, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CheckBox } from '../../components/Input/CheckBox';
import NexterInput from '../../components/Input/NexterInput';
import NexterButton from '../../components/Button/NexterButton';
import CustomInputDatePicker from '../../components/Input/DatePicker';
import PropTypes from 'prop-types';
import { Amount } from '../../components/Field/Amount';
import { PaymentLink } from '../report/PaymentLink';
import { PaymentInfo } from '../report/PaymentInfo';
import Visibility from '../../components/Visibility/Visibility';

const inputStyles = classNames('nexter-input-sm');
const rowStyles = classNames('mr-0');
const colStyles = classNames('pr-0');
const colDefaultProps = {
  xs: { size: 12 },
  lg: { size: true },
};

class PaymentInvoice extends Component {
  constructor(props) {
    super(props);

    this.printPDF = this.printPDF.bind(this);
  }

  printPDF() {
    window.print();
  }

  inquiry() {
    const payment = this.props.payment;
    this.props.paymentProps.inquiryPayment(payment.uid, true); // false = no redirect
  }

  updateInvoice() {
    const payment = this.props.payment;
    this.props.paymentProps.updateInvoicePayment(payment.uid, false); // false = no redirect
  }

  render() {
    const payment = this.props.payment;
    const { loggedinUser } = this.props.user.getData();
    const sellerName = this.props.sellerName;

    return (
      <>
        {/* uses mr-0 because cols use pr-0 */}
        <Row className={classNames(rowStyles, 'payment-info')}>
          <Col className={colStyles}>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <h6 className="payment-title text-uppercase mt-auto mb-0">INVOICE NO. {payment.gateway_invoice_no || '-'} {sellerName ? 'ผู้ขาย: ' + sellerName : '' }</h6>
                <Visibility role_code="super_admin">
                  <a className="mt-auto text-capitalize mx-2 text-link" onClick={this.updateInvoice.bind(this)}>Edit Invoice No.</a>
                </Visibility>
              </div>
              <div className="no-print">
                { 
                  this.props.editDescription && (<NexterButton onClick={() => this.props.open(payment.id ,payment.description)} className="my-0" size="sm" icon="fa fa-edit" outline>แก้ไขรายละเอียด</NexterButton>)
                }
                <Visibility permission_code="payment:transaction_print_detail_page">
                  <NexterButton size="sm" color="info" outline={true} className="mx-2" icon="fa fa-print" onClick={this.printPDF}>PRINT</NexterButton>
                </Visibility>
                <Visibility role_code="super_admin">
                  <NexterButton className="no-print mr-1" size="sm"
                    icon="fa fa-history"
                    onClick={this.inquiry.bind(this)}
                  >Update
                  </NexterButton>
                </Visibility>
              </div>
            </div>
            <hr className="mt-2" />
            {payment.status === 'created' && (
              <Visibility permission_code="payment:visit_payment_screen">
                <PaymentLink payment={payment} />
                <hr />
              </Visibility>
            )}
            <PaymentInfo payment={payment} />
          </Col>
        </Row>
      </>
    );
  }
}

export default inject('user')(observer(PaymentInvoice));
