import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { CardHeader, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Page } from '../../components/Page';
import UserLogTable from './UserLogTable';
import RoleMenuLogTable from './RoleMenuLogTable';

class UserHistoryLogPage extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.changeActiveTab = this.changeActiveTab.bind(this);
  }

  getInitialState() {
    return {
      activeTab: 'role-menu-log',
      loading: false
    };
  }

  changeActiveTab(e) {
    const tabId = e.target.id;
    this.setState({ activeTab: tabId });
  }

  goBack() {
    this.props.user.changeUserMgmtView('list');
  }

  render() {
    const { activeTab, loading } = this.state;

    return (
      <Fragment>
        <Page loading={loading}>
          <CardHeader>
            <Row className="nav-history-log">
              <Col>
                <div className="title-goback">
                  <a className="float-left" onClick={this.goBack.bind(this)}>
                    <i className="back-icon fas fa-chevron-left my-auto mr-4" />
                  </a>
                  <Nav tabs className="float-left">
                    <NavItem>
                      <NavLink href="#" active={activeTab === 'role-menu-log'} onClick={this.changeActiveTab} id="role-menu-log">
                        Role & Menu Log
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="#" active={activeTab === 'user-log'} onClick={this.changeActiveTab} id="user-log">
                        User Log
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="role-menu-log">
              <div className="historylog-wrapper">
                <RoleMenuLogTable setLoading={(load) => this.setState({ loading: load })} />
              </div>
            </TabPane>
            <TabPane tabId="user-log">
              <div className="historylog-wrapper">
                <UserLogTable setLoading={(load) => this.setState({ loading: load })} />
              </div>
            </TabPane>
          </TabContent>
        </Page>
      </Fragment>
    );
  }
}

export default inject('user')(observer(UserHistoryLogPage));
