import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import _ from 'lodash';
import { config } from '../../config';
import { http } from '../../utils/http';
import { Table, Row, Col } from 'reactstrap';
import { EmptyRow } from '../../components/Table/EmptyRow';
import { Pagination } from '../../components/Pagination/Pagination';
import { ensureJSONparse } from '../../utils/string';

const FIELD = {
  users: {
    role_name: 'Role',
    channel_name: 'Channel',
    name: 'First Name',
    surname: 'Last Name',
    email: 'E-Mail',
    tel: 'Phone Number',
    status: 'Status'
  }
}

const t = (entity, field) => {
  const translateField = _.get(FIELD, [entity, field], field)
  return translateField
}

class UserLogTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      page: 1,
      size: 10,
      total: 1,
    };
  }

  componentDidMount() {
    this.fetchHistoryLogs();
  }

  async fetchHistoryLogs() {
    const { setLoading } = this.props
    const { user_info } = this.props.user.getData()
    const channel_id = _.get(user_info, ['channel_id'], null)
    let entity = [
      'users',
    ]
    try {
      setLoading(true)
      let url = `${config.npay.apiUrl}/historylogs`;
      const payload = {
        page: this.state.page,
        size: this.state.size,
        parent: 'user',
        entity: entity,
      }
      if (channel_id) payload.parent_id = channel_id
      let body = payload;

      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        this.setState({
          logs: body.data || [],
          total: body.total || 1,
        });
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 100);
    }
  }

  selectPage(page) {
    this.setState({ page }, async () => {
      await this.fetchHistoryLogs();
    });
  }

  render() {
    const { loggedinUser } = this.props.user.getData();
    const { logs } = this.state;

    const isP8team = ['super_admin', 'biz_system', 'ae_marketing', 'manager'].includes(loggedinUser.type);

    let rows = logs.length ? logs.map((item, index) => {
      const oldValue = ensureJSONparse(item.old_value);
      const newValue = ensureJSONparse(item.new_value);
      const actionName = _.get(item, 'action_name', '');
      const channelName = _.get(item, 'channel.name', '');
      let list = newValue !== null && !_.isEmpty(newValue) ? newValue : oldValue;

      let result = Object.entries(list)
        .filter(([key, value], i) => {
          if (isP8team) {
            return !['role_id', 'channel_id'].includes(key);
          }
          return !['role_id', 'channel_id', 'channel_name'].includes(key);
        })
        .map(([key, value], i) => {
          const entity = _.get(item, 'entity')
          let field = t(entity, key)
          let ov = _.get(oldValue, key);
          let nv = _.get(newValue, key);
          const [updated_by, item_updated] = (_.get(item, 'updated_by', '')).split('|||')
          if (key === 'status') {
            ov = ov?.replace(/./, c => c.toUpperCase()) || '-'
            nv = nv?.replace(/./, c => c.toUpperCase()) || '-'
          }
          if (key === 'create') {
            const [_, role] = value.split('|||')
            if (role) field += `: ${role}`
          }
          return (
            <tr key={`item-${item.id}-${i}`}>
              <td width="142">
                {moment(_.get(item, 'updated_at', '-')).format('DD-MM-YYYY HH:mm')}
              </td>
              <td width="152">{updated_by}</td>
              <td width="152">{channelName || <span className="data-is-null">-</span>}</td>
              <td width="150">{item_updated}</td>
              <td width="80">
                <div className={`action-wrapper ${actionName}`}>{`${actionName}`}</div>
              </td>
              <td width="150" className="keys">
                {field}
              </td>
              <td width="150">
                {ov ? ov : <span className="data-is-null">-</span>}
              </td>
              <td width="150">
                {nv && !(key === 'create') ? nv : <span className="data-is-null">-</span>}
              </td>
            </tr>
          )
        })
      return result;
    }) : <EmptyRow colSpan={6} />;

    return (
      <Fragment>
        <Table>
          <thead>
            <tr>
              <th className="border-0">วันที่แก้ไข</th>
              <th className="border-0">แก้ไขโดย</th>
              <th className="border-0">Channel Name</th>
              <th className="border-0">รายการที่แก้ไข</th>
              <th className="border-0">ชนิดการแก้ไข</th>
              <th className="border-0">ข้อมูลที่แก้ไข</th>
              <th className="border-0">ข้อมูลเดิม</th>
              <th className="border-0">ข้อมูลใหม่</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        <hr />
        <Row>
          <Col />
          <Col>
            <Pagination
              className="pull-right"
              activePage={this.state.page}
              displayPageNumber={5}
              maximumPageNumber={Math.ceil(this.state.total / this.state.size)}
              onSelect={(page) => this.selectPage(page)}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default inject('user')(observer(UserLogTable));
