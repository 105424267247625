import React from 'react';
import { Label, FormGroup } from 'reactstrap';
import classNames from 'classnames';
import { CheckBox } from '../../../components/Input/CheckBox';
import { AUTO_TRANSFER_SCHEDULE_DAYS as DAYS } from '../../../constants/transferConstants';

const DATE_OPTIONS = [
  ...Array(31)
    .fill()
    .map((_, index) => index + 1),
].map(date => date.toString());

const END_OF_MONTH = {
  label: DAYS['eom'],
  value: 'eom',
};

const ScheduleMonthDateSelector = ({ dates = [], onChange }) => {
  function onSelect(date) {
    let selectedDates = [...dates];
    let index = selectedDates.indexOf(date);
    if (index >= 0) {
      selectedDates.splice(index, 1);
    } else {
      selectedDates.push(date);
    }
    onChange(selectedDates);
  }

  return (
    <div className="schedule-month-date-selector">
      <FormGroup className="required">
        <Label className="control-label form-label mb-3">โปรดระบุวันที่ (สามารถระบุได้มากกว่า 1 วัน)</Label>
        <div className="calendar mb-3">
          {DATE_OPTIONS.map(date => (
            <div key={date} className={classNames('item', { selected: dates.includes(date) })} onClick={() => onSelect(date)}>
              {date}
            </div>
          ))}
          <div className="flex-fill" />
        </div>
        <CheckBox label={END_OF_MONTH.label} defaultClassName="checkbox-end-date" checked={dates.includes(END_OF_MONTH.value)} onChange={() => onSelect(END_OF_MONTH.value)} />
      </FormGroup>
      <div className="text-secondary mt-3">
        * กรณีเดือนไหนมีจำนวนวันน้อยกว่าวันที่เลือก (เช่น กุมภาพันธ์) ระบบจะโอนเงินให้ใน <b>"ครั้งถัดไป"</b> หรือหากต้องการให้โอนเงินในวันสุดท้ายของเดือน กรุณากด <b>"วันสุดท้ายของเดือน"</b>
      </div>
    </div>
  );
};

export default ScheduleMonthDateSelector;
