import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import PaymentPage from '../report/PaymentPage';
import Visibility from '../../components/Visibility/Visibility';
import NexterButton from '../../components/Button/NexterButton';

import { config } from '../../config';

const inputStyles = classNames('nexter-input-sm');
const rowStyles = classNames('mr-0 pb-3');
const colStyles = classNames('pr-0');
const colDefaultProps = {
  xs: { size: 12 },
  lg: { size: 3 },
};

class PaymentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'tab-report1',
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) this.setState({ activeTab: tab });
  }

  back() {
    this.props.payment.clearPaymentDetail();
  }

  render() {
    const { activeTab } = this.state;
    const { payment } = this.props.payment.getData();
    return (
      <>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <a onClick={this.back.bind(this)}>
            <h6 className="payment-title text-capitalize no-print">
              <i className="back-icon fas fa-chevron-left my-auto mr-2" />
              <span>Payment Details</span>
            </h6>
          </a>
          <Link to={`${config.web.rootpath}/payments?tab=history`}>
            <NexterButton className="mr-2 my-0" size="sm" outline icon="fas fa-history">
              History Log
            </NexterButton>
          </Link>
        </div>
        <Visibility permission_code="payment:transaction_view_detail">
          {payment.sub_payments ?
            (<PaymentPage paymentId={payment.id} paymentUid={payment.uid} />)
            : (<PaymentPage paymentId={payment.id} />)
          }
        </Visibility>
        <Visibility permission_code="payment:transaction_view_detail" invisibility_msg>
          <h5 className="mt-4">ท่านไม่มีสิทธิ์ดูรายละเอียด Transaction</h5>
        </Visibility>
      </>
    );
  }
}

export default inject('payment')(observer(PaymentDetail));
