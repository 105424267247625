import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Button, Input, FormFeedback, Row, Col } from 'reactstrap';
import { config } from '../../config';
import { Page } from '../../components/Page';
import Visibility from '../../components/Visibility/Visibility';
import NexterButton from '../../components/Button/NexterButton';
// import Swal from 'sweetalert2';

class OtherPage extends Component {
  constructor() {
    super();
    this.state = {
      system_email: '',
      errorType: null,
    };
  }

  componentDidMount() {
    this.loading = true;
    const configs = this.props.system.configs;
    let configData = JSON.parse(JSON.stringify(configs));
    this.setState({
      system_email: configData.system_email,
    });
    this.loading = false;
  }

  componentWillUnmount() {
    this.props.system.editableT5 = false;
  }

  getErrorMsg() {
    switch (this.state.errorType) {
      case 'required':
        return 'โปรดระบุอีเมล';
      case 'format':
        return 'รูปแบบของอีเมลไม่ถูกต้อง';
      default:
        return null;
    }
  }

  changeConfig(e) {
    const email = e.target.value;
    const emailRegEx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    let error = null;
    if (['', null, undefined].includes(email)) error = 'required';
    else if (!emailRegEx.test(email)) error = 'format';
    this.setState({
      system_email: email,
      errorType: error,
    });
  }

  editConfig() {
    this.props.system.editableT5 = true;
    this.forceUpdate();
  }

  async saveConfig() {
    this.props.system.editableT5 = false;
    this.props.system.updateSystemEmailConfig(this.state.system_email);
  }

  cancelConfig() {
    this.props.system.editableT5 = false;
    const configs = this.props.system.configs;
    let configData = JSON.parse(JSON.stringify(configs));
    this.setState({
      system_email: configData.system_email,
    });
    this.forceUpdate();
  }

  render() {
    const { loading, editableT5: otherEditable } = this.props.system.getData();
    return (
      <Page loading={loading}>
        <div className="container-fluid px-0">
          <Row>
            <Col>
              <Card className="no-r-tl" style={{ height: 'calc(100vh - 230px)', minHeight: '320px' }}>
                <CardBody className="pt-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="text-uppercase my-4 system-config-title">System E-mail</h6>
                    {otherEditable ? (
                      <div>
                        <NexterButton size="sm" color="secondary" onClick={this.cancelConfig.bind(this)} outline>
                          CANCEL
                        </NexterButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <NexterButton size="sm" icon="fa fa-save" color="success" onClick={this.saveConfig.bind(this)} outline disabled={this.state.errorType}>
                          SAVE
                        </NexterButton>
                      </div>
                    ) : (
                      <div>
                        <Visibility permission_code="system_configuration:set_other">
                          <NexterButton size="sm" icon="fas fa-pencil-alt" onClick={this.editConfig.bind(this)}>
                            EDIT
                          </NexterButton>
                        </Visibility>
                        <Link to={`${config.web.rootpath}/system-config/history?tab=other`}>
                          <NexterButton className="ml-3 my-0" size="sm" outline icon="fas fa-history">
                            History Log
                          </NexterButton>
                        </Link>
                      </div>
                    )}
                  </div>
                  <hr className="mt-0 mb-3" />
                  <div className="mt-2">
                    <Row>
                      <Col lg={5} md={6} sm={8} xs={12}>
                        <label className="w-100">
                          Email
                          <br />
                          <Input type="email" value={this.state.system_email} disabled={!otherEditable} onChange={this.changeConfig.bind(this)} invalid={this.state.errorType} />
                          <FormFeedback>{this.getErrorMsg()}</FormFeedback>
                        </label>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Page>
    );
  }
}

export default inject('system')(observer(OtherPage));
