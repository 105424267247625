import React from 'react';
import { PulseLoader } from 'react-spinners';

export const Loader = ({ loading }) => {
  return (
    <div>
      {loading && (
        <div className="sweet-loading">
          <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
            <PulseLoader sizeUnit={'px'} size={30} margin={'2px'} color={'#e20a1a'} loading />
          </div>
        </div>
      )}
    </div>
  );
};
