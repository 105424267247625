import React, { Component, Fragment } from 'react';
import { PaymentReport } from '../../../report/PaymentReport';
import { inject, observer } from 'mobx-react';
import { Amount } from '../../../../components/Field/Amount';
import { EmptyRow } from '../../../../components/Table/EmptyRow';

import { Table } from 'reactstrap';
export class R2Report extends Component {
  render() {
    const payment = this.props.payment;
    let totalNet = 0; // รวม ราคา - ส่วนลด
    let totalVat = 0; // รวม vat
    let totalDiscount = 0; // รวม ส่วนลด
    let ttotal = 0; // รวม ราคาปกติ
    let tsp = 0; // รวม ค่าบริการ %
    let tsb = 0; // รวม ค่าบริการ บาท
    let tsv = 0; // รวม vat 7 %
    let tssv = 0; // รวม ค่าบริการบวก vat
    let dvr = 0; //  vat rate
    let dwr = 0; // wht rate
    let totalPaid = 0; // สุทธิ
    let sumTotalPaid = 0; // รวม สุทธิ
    let sumSutti = 0; // รวม ค่าบริการสุทธิ
    let interestPercTotal = 0;
    let interestAftCalTotal = 0;
    let vatInterestPercTotal = 0;
    let interestTotalTotals = 0;
    let whtCostTotal = 0; // รวม WHT 3 %
    let promotionDiscountTotal = 0;
    let rows = payment.items.map(item => {
      // console.log(`item ====> `, item);
      let net = item.subtotal - item.discount;
      let vat = ((item.subtotal - item.discount) * 100) / 107;
      totalNet += net;
      totalVat += vat;
      totalDiscount += item.discount;

      let servicePerc; // ค่าบริการ %
      let serviceBath; // ค่าบริการ บาท
      let serviceVAT; // vat 7 %
      let serviceSumVAT; // ค่าบริการบวก vat
      let serviceSutti; // ค่าบริการสุทธิ
      let interestPerc; // ดอกเบี้ย %
      let interestAftCal; // ดอกเบี้ยหลังคำนวณ
      let vatInterestPerc; // vat ดอกเบี้ย %
      let interestTotal; // ดอกเบี้ยสุทธิ
      let monthInterest = payment.installment_period;
      let whtCost; // WHT 3 %
      let promotionDiscount = 0;
      if (payment.seller && payment.seller.type === 'channel' && payment.method === 'installment') {
        servicePerc = '-';
        serviceBath = '-';
        serviceVAT = '-';
        serviceSumVAT = '-';
        serviceSutti = '-';
        interestPerc = parseFloat(payment.installment_rate); // ดอกเบี้ย %
        interestAftCal = (interestPerc * net * monthInterest) / 100; // ดอกเบี้ยหลังคำนวณ
        vatInterestPerc = (interestAftCal * 7) / 100; // vat ดอกเบี้ย %
        interestTotal = interestAftCal + vatInterestPerc; // ดอกเบี้ยสุทธิ
        interestPercTotal += interestPerc;
        interestAftCalTotal += interestAftCal;
        vatInterestPercTotal += vatInterestPerc;
        interestTotalTotals += interestTotal;
        whtCostTotal = 0;
        tsp = '-';
        tsb = '-';
        tsv = '-';
        tssv = '-';
        dwr = '-';
        ttotal += item.subtotal;
        totalPaid = net - interestTotal;
        sumTotalPaid += totalPaid;
        sumSutti = 0;
        promotionDiscount = item.promotion_discount || 0;
        promotionDiscountTotal += promotionDiscount;
      } else if (payment.seller && payment.seller.type === 'channel' && payment.method !== 'installment') {
        servicePerc = '-';
        serviceBath = '-';
        serviceVAT = '-';
        serviceSumVAT = '-';
        serviceSutti = '-';
        monthInterest = '-';
        interestPerc = '-'; // ดอกเบี้ย %
        interestAftCal = '-'; // ดอกเบี้ยหลังคำนวณ
        vatInterestPerc = '-'; // vat ดอกเบี้ย %
        interestTotal = '-'; // ดอกเบี้ยสุทธิ
        interestPercTotal = '-';
        interestAftCalTotal = '-';
        vatInterestPercTotal = '-';
        interestTotalTotals = '-';
        whtCostTotal = 0;
        tsp = '-';
        tsb = '-';
        tsv = '-';
        tssv = '-';
        dwr = '-';
        ttotal += item.subtotal;
        totalPaid = net;
        sumTotalPaid += totalPaid;
        sumSutti = 0;
        promotionDiscount = item.promotion_discount || 0;
        promotionDiscountTotal += promotionDiscount;
      } else if (payment.seller && payment.seller.type === 'partner' && payment.method === 'installment') {
        if (item.service_charge_type === 'baht') {
          servicePerc = 0;
          serviceBath = item.service_charge;
        } else {
          servicePerc = item.service_charge;
          serviceBath = (item.subtotal * servicePerc) / 100;
        }
        serviceVAT = Math.round(item.vat * 100) / 100;
        serviceSumVAT = serviceVAT + serviceBath;
        if (payment.seller.kyc_type === 'company') {
          whtCost = Math.round(((serviceBath * 3) / 100) * 100) / 100;
        } else if (payment.seller.kyc_type === 'personal') {
          whtCost = 0;
        }
        serviceSutti = serviceSumVAT - whtCost;
        interestPerc = parseFloat(payment.installment_rate); // ดอกเบี้ย %
        interestAftCal = (interestPerc * net * monthInterest) / 100; // ดอกเบี้ยหลังคำนวณ
        vatInterestPerc = (interestAftCal * 7) / 100; // vat ดอกเบี้ย %
        interestTotal = interestAftCal + vatInterestPerc; // ดอกเบี้ยสุทธิ
        interestPercTotal += interestPerc;
        interestAftCalTotal += interestAftCal;
        vatInterestPercTotal += vatInterestPerc;
        interestTotalTotals += interestTotal;
        tsp += servicePerc;
        tsb += serviceBath;
        tsv += serviceVAT;
        tssv += serviceSumVAT;
        whtCostTotal += whtCost;
        ttotal += item.subtotal;
        totalPaid = net - serviceSutti - interestTotal;
        sumTotalPaid += totalPaid;
        sumSutti += serviceSutti;
        promotionDiscount = item.promotion_discount || 0;
        promotionDiscountTotal += promotionDiscount;
      } else if (payment.seller && payment.seller.type === 'partner' && payment.method !== 'installment') {
        if (item.service_charge_type === 'baht') {
          servicePerc = 0;
          serviceBath = item.service_charge;
        } else {
          servicePerc = item.service_charge;
          serviceBath = (item.subtotal * servicePerc) / 100;
        }
        serviceVAT = Math.round(item.vat * 100) / 100;
        serviceSumVAT = serviceVAT + serviceBath;
        if (payment.seller.kyc_type === 'company') {
          whtCost = Math.round(((serviceBath * 3) / 100) * 100) / 100;
        } else if (payment.seller.kyc_type === 'personal') {
          whtCost = 0;
        }
        serviceSutti = serviceSumVAT - whtCost;
        monthInterest = '-';
        interestPerc = '-'; // ดอกเบี้ย %
        interestAftCal = '-'; // ดอกเบี้ยหลังคำนวณ
        vatInterestPerc = '-'; // vat ดอกเบี้ย %
        interestTotal = '-'; // ดอกเบี้ยสุทธิ
        interestPercTotal = '-';
        interestAftCalTotal = '-';
        vatInterestPercTotal = '-';
        interestTotalTotals = '-';
        tsp += servicePerc;
        tsb += serviceBath;
        tsv += serviceVAT;
        tssv += serviceSumVAT;
        whtCostTotal += whtCost;
        ttotal += item.subtotal;
        totalPaid = net - serviceSutti;
        sumTotalPaid += totalPaid;
        sumSutti += serviceSutti;
        promotionDiscount = item.promotion_discount || 0;
        promotionDiscountTotal += promotionDiscount;
      }

      return (
        <tr key={`item-${item.id}`}>
          <td className='print-pre-warp'>{item.description || '-'}</td>
          <td className="text-right">
            <Amount value={item.subtotal} />
          </td>
          <td className="text-right">
            <Amount value={item.discount} />
          </td>
          <td className="text-right">
            <Amount value={net} />
          </td>
          <td className="text-right">
            {
              item.service_charge_type === 'baht' ? '-' : (<Amount value={servicePerc || 0} />)
            }
          </td>
          <td className="text-right">
            <Amount value={serviceBath || '-'} />
          </td>
          <td className="text-right">
            <Amount value={serviceVAT || '-'} />
          </td>
          <td className="text-right">
            <Amount value={serviceSumVAT || '-'} />
          </td>
          <td className="text-right">
            <Amount value={whtCost || '-'} />
          </td>
          <td className="text-right">
            <Amount value={serviceSutti || '-'} />
          </td>
          <td className="text-right">{monthInterest || '-'}</td>
          <td className="text-right">
            <Amount value={interestPerc || '-'} />
          </td>
          <td className="text-right">
            <Amount value={interestAftCal || '-'} />
          </td>
          <td className="text-right">
            <Amount value={vatInterestPerc || '-'} />
          </td>
          <td className="text-right">
            <Amount value={interestTotal || '-'} />
          </td>
          <td className="text-right">
            <Amount value={promotionDiscount} />
          </td>
          <td className="text-right">
            <Amount value={totalPaid} />
          </td>
        </tr>
      );
    });

    rows = rows.filter(r => r !== undefined);

    return (
      <Fragment>
        <h6 className="payment-title text-uppercase mt-auto my-2">รายละเอียดค่าบริการตามรายสินค้า</h6>
        <div className="payment-transfer-table table-reponsive table-scroll">
          <Table>
            <thead>
              <tr>
                <th width="180" className='print-pre-warp'>รายการ</th>
                <th width="120" className="text-center">
                  ราคารวม
                </th>
                <th className="text-right">ส่วนลด</th>
                <th width="100" className="text-center">
                  ราคา
                </th>
                <th width="100" className="text-center">
                  ค่าบริการ (%)
                </th>
                <th width="100" className="text-center">
                  ค่าบริการ
                </th>
                <th width="100" className="text-center">
                  VAT (7%)
                </th>
                <th width="100" className="text-center">
                  ค่าบริการบวก VAT
                </th>
                <th width="100" className="text-center">
                  WHT (%)
                </th>
                <th width="100" className="text-center">
                  ค่าบริการสุทธิ
                </th>
                <th width="100" className="text-center">
                  ผ่อนชำระ (เดือน)
                </th>
                <th width="100" className="text-center">
                  อัตราดอกเบี้ย (%)
                </th>
                <th width="100" className="text-center">
                  ดอกเบี้ยหลังคำนวณ
                </th>
                <th width="100" className="text-center">
                  VAT ดอกเบี้ย (%)
                </th>
                <th width="100" className="text-center">
                  ดอกเบี้ยสุทธิ
                </th>
                <th width="100" className="text-center">
                  ส่วนลดส่งเสริมการขาย
                </th>
                <th width="100" className="text-right">
                  สุทธิ
                </th>
                {/* <th width="100" className="text-right">ส่วนลด</th>
              <th width="100" className="text-right">ราคารวม VAT</th> */}
              </tr>
            </thead>
            {rows.length > 0 ? (
              <tbody>
                {rows}
                <tr>
                  <td className="text-right" style={{ fontWeight: 700 }} colSpan={1}>
                    ยอดรวม
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    <Amount value={ttotal} />
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    <Amount value={totalDiscount} />
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    <Amount value={totalNet} />
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    -
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    <Amount value={tsb} />
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    <Amount value={tsv} />
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    <Amount value={tssv} />
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    <Amount value={whtCostTotal} />
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    <Amount value={sumSutti} />
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    -
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    -
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    <Amount value={interestAftCalTotal} />
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    <Amount value={vatInterestPercTotal} />
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    <Amount value={interestTotalTotals} />
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    <Amount value={promotionDiscountTotal} />
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    <Amount value={sumTotalPaid} />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <EmptyRow colSpan={17} />
              </tbody>
            )}
          </Table>
        </div>
      </Fragment>
    );
  }
}
export default R2Report;
