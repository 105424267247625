import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CustomInput, Row, Col } from 'reactstrap';

import { config } from '../../config';
import { Page } from '../../components/Page';
import Visibility from '../../components/Visibility/Visibility';
import NexterButton from '../../components/Button/NexterButton';
class PayOptionPage extends Component {
  constructor() {
    super();
    this.state = {
      paymentOptions: [],
    };
  }

  componentDidMount() {
    this.loading = true;
    const paymentOptions = this.props.system.payment_options.map(item => {
      return {
        key: item.code,
        label: item.name,
        checked: item.status === 'active' ? true : false,
      };
    });
    this.setState({ paymentOptions: paymentOptions });
    this.loading = false;
  }

  async changePaymentStatus(key, e) {
    // console.log(this.state.paymentOptions);
    let paymentOptions = this.state.paymentOptions.map(item => {
      if (item.key === key) {
        return {
          key: item.key,
          label: item.label,
          checked: !item.checked,
        };
      } else {
        return {
          key: item.key,
          label: item.label,
          checked: item.checked,
        };
      }
    });

    this.setState({ paymentOptions: paymentOptions });
    this.forceUpdate();
  }

  editPaymentOptionBtn() {
    this.props.system.editableT3 = true;
  }

  savePaymentOption() {
    this.props.system.editableT3 = false;
    this.props.system.updatePaymentOptionConfig(this.state.paymentOptions);
  }

  cancelPaymentOption() {
    this.props.system.editableT3 = false;
    const paymentOptions = this.props.system.payment_options.map(item => {
      return {
        key: item.code,
        label: item.name,
        checked: item.status === 'active' ? true : false,
      };
    });
    this.setState({ paymentOptions: paymentOptions });
  }

  render() {
    const { loading, editableT3: paymentEditable } = this.props.system.getData();

    const paymentInputs = this.state.paymentOptions.map((payment, index) => {
      return (
        <div key={index}>
          {index > 0 && <hr />}
          <div className="d-flex justify-content-between align-items-center">
            <label className="text-capitalize font-weight-bold" style={{ color: '#161616' }}>
              {payment.label}
            </label>
            <CustomInput type="switch" id={payment.key} checked={payment.checked} disabled={!paymentEditable} onChange={this.changePaymentStatus.bind(this, payment.key)} />
          </div>
        </div>
      );
    });

    return (
      <Page loading={loading} className="bg-white">
        <div className="container-fluid px-0">
          <Row>
            <Col>
              <Card className="no-r-tl">
                <CardBody className="pt-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="text-uppercase my-4 system-config-title">Payment Method</h6>
                    {paymentEditable ? (
                      <div>
                        <NexterButton size="sm" color="secondary" onClick={this.cancelPaymentOption.bind(this)} outline>
                          CANCEL
                        </NexterButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <NexterButton size="sm" icon="fa fa-save" color="success" onClick={this.savePaymentOption.bind(this)} outline>
                          SAVE
                        </NexterButton>
                      </div>
                    ) : (
                      <div>
                        <Visibility permission_code="system_configuration:set_default_payment_option">
                          <NexterButton size="sm" icon="fas fa-pencil-alt" onClick={this.editPaymentOptionBtn.bind(this)}>
                            EDIT
                          </NexterButton>
                        </Visibility>
                        <Link to={`${config.web.rootpath}/system-config/history?tab=payment_option`}>
                          <NexterButton className="ml-3 my-0" size="sm" outline icon="fas fa-history">
                            History Log
                          </NexterButton>
                        </Link>
                      </div>
                    )}
                  </div>
                  <hr className="mt-0 mb-3" />
                  <div className="my-3">
                    <Row>
                      <Col lg={4}>{paymentInputs}</Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Page>
    );
  }
}

export default inject('system')(observer(PayOptionPage));
