import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import _ from 'lodash';
import { config } from '../../config';
import { http } from '../../utils/http';
import { Card, CardHeader, CardBody, Table, Row, Col } from 'reactstrap';
import { Page } from '../../components/Page';
import { EmptyRow } from '../../components/Table/EmptyRow';
import { Pagination } from '../../components/Pagination/Pagination';
import ChannelHistoryLog from './ChannelHistoryLog';
import ChannelSettingHistoryLog from './ChannelSettingHistoryLog';

class ChannelSettingHistoryLogPage extends Component {  
  backToChannel() {
    const admin_channel_uid = this.props.match.params.uid;
    if (admin_channel_uid) {
      this.props.channel.changeAdminChannelUid(admin_channel_uid);
    }
    
    this.props.history.goBack();
  }
  
  render() {
    const admin_channel_uid = this.props.match.params.uid;

    return (
      <Fragment>
        <Page>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="text-uppercase" style={{ marginBottom: 0 }}>
              <a onClick={this.backToChannel.bind(this)}>
                <i className="fas fa-chevron-left"></i> <b>CHANNEL SETTING</b>
              </a>
            </h5>
          </div>
          <Card className="historylog-wrapper">
            <CardBody>
              <ChannelSettingHistoryLog parentUid={admin_channel_uid} />
            </CardBody>
          </Card>
        </Page>
      </Fragment>
    );
  }
}

export default inject('channel')(observer(ChannelSettingHistoryLogPage));
