import React, { Fragment } from 'react';
import { CardBody, CardTitle, CardText, Row, Col } from 'reactstrap';
import classNames from 'classnames';
import moment from 'moment';
// import { config } from '../../config';

const InfoField = ({ title, value, style, className }) => {
  return (
    <Fragment>
      <CardTitle className="info-title">{title}</CardTitle>
      <CardText className={classNames('info-desc', className)} style={style}>
        {value}
      </CardText>
    </Fragment>
  );
};

export const PaymentInfo = ({ payment }) => {
  // convert bin card to thai lang
  let cardBankCode = payment.card_type?.card_bank_code;
  let binCardThai = '';
  switch (cardBankCode) {
    case 'KBANK':
      binCardThai = 'ธนาคารกสิกรไทย';
      break;
    case 'SCB':
      binCardThai = 'ธนาคารไทยพาณิชย์';
      break;
    case 'BBL':
      binCardThai = 'ธนาคารกรุงเทพ';
      break;
    case 'BAY':
      binCardThai = 'ธนาคารกรุงศรีอยุธยา';
      break;
    case 'KCC':
      binCardThai = 'ธนาคารกรุงศรีอยุธยา';
      break;
    case 'KTB':
      binCardThai = 'ธนาคารกรุงไทย';
      break;
    case 'KTC':
      binCardThai = 'ธนาคารกรุงไทย';
      break;
    case 'UOB':
      binCardThai = 'ธนาคารยูโอบี';
      break;
    case 'TTB':
      binCardThai = 'ธนาคารทหารไทยธนชาต';
      break;
    case 'TBANK':
      binCardThai = 'ธนาคารธนชาต';
      break;
    case 'KFC':
      binCardThai = 'กรุงศรีเฟิร์สช้อยส์';
      break;
    default:
      binCardThai = 'not found';
      break;
  }

  // let classNamePayment = {
  //   failed: 'badge badge-danger',
  //   success: 'badge badge-success',
  //   settled: 'badge badge-primary',
  //   voided: 'badge badge-reset',
  //   refunded: 'badge badge-reset',
  //   pending: 'badge badge-warning',
  //   created: 'badge badge-created',
  //   cancel: 'badge badge-cancel',
  // }[payment.status];

  return (
    <CardBody className="payment-info">
      <Row>
        <Col>
          <InfoField title="หมายเลขการสั่งซื้อ" value={payment.ref_id} />
          <InfoField title="หมายเลขอ้างอิง(เกตเวย์)" value={payment.gateway_charge_id || '-'} />
        </Col>
        <Col>
          <InfoField title="ผู้ชำระเงิน" value={payment.customer_name || '-'} />
          <InfoField title="เกตเวย์" value={payment.gateway} />
          {payment.method === 'line' && <InfoField title="Line Pay " value={' '} />}
        </Col>
        <Col>
          <InfoField title="Phone" value={payment.phone || '-'} />
          <InfoField title="รายละเอียด" value={payment.description || '-'} />
        </Col>
        <Col>
          <InfoField title="อีเมล" value={payment.email || '-'} />
          <InfoField title="วันที่ชำระเงิน" value={payment.payment_at ? moment(payment.payment_at).format('DD MMMM YYYY HH:mm') : '-'} />
        </Col>
        <Col>
          <InfoField title="สถานะ" value={<span className={`badge status status-${payment.status}`}>{payment.status}</span>} className="text-capitalize" />
          {payment.method === 'installment' ? <InfoField title="ผ่อนชำระ" value={`${payment.installment_period} เดือน ${binCardThai}`} /> : ''}
        </Col>
        {payment.chang_family_payment_info && (
          <Col>
            <InfoField title="Card no. 12 หลัก" value={payment.chang_family_payment_info.cardref || '-'} />
            <InfoField title="BPNO" value={payment.chang_family_payment_info.bpno || '-'} />
          </Col>
        )}
      </Row>
    </CardBody>
  );
};
