import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, InputGroupText, InputGroupAddon, InputGroup, Input, Form, FormGroup, Label, CustomInput, Row, Col } from 'reactstrap';

import { config } from '../../config';
import { Page } from '../../components/Page';
import Visibility from '../../components/Visibility/Visibility';
import NexterButton from '../../components/Button/NexterButton';
import Swal from 'sweetalert2';
class TransactionFeePage extends Component {
  constructor() {
    super();
    this.state = {
      feePayments: [],
      bankInstallment: [],
      vat: 0,
      vat_valid: true,
      wht: 0,
      wht_valid: true,
    };
  }

  componentDidMount() {
    this.initTransactionFee();
  }

  initTransactionFee() {
    this.loading = true;

    const feePayments = this.props.system.fee_payments.map(item => {
      return {
        key: item.code,
        label: item.name,
        type: item.default_fee_type,
        percent: item.percent_fee,
        default_percent: item.default_percent_fee,
        cost: item.fixed_cost_fee,
        default_cost: item.default_fixed_cost_fee,
        percent_valid: true,
        default_percent_valid: true,
        cost_valid: true,
        default_cost_valid: true,
      };
    });

    const configs = this.props.system.configs;
    let configData = JSON.parse(JSON.stringify(configs));
    let vatValue = parseFloat(configData.default_vat);
    vatValue = vatValue.toFixed(2);
    let whtValue = parseFloat(configData.default_wht);
    whtValue = whtValue.toFixed(2);

    this.setState({
      feePayments: feePayments,
      vat: vatValue,
      vat_valid: true,
      wht: whtValue,
      wht_valid: true,
    });

    this.loading = false;
  }

  changeTransactionFee(key, option, e) {
    // console.log(e.target.value);
    let feePayments = this.state.feePayments.map(item => {
      if (item.key === key) {
        if (option === 'fee_type') {
          item.type = e.target.value;
        } else if (option === 'percent') {
          item.percent = e.target.value;
        } else if (option === 'default_percent') {
          item.default_percent = e.target.value;
        } else if (option === 'cost') {
          item.cost = e.target.value;
        } else if (option === 'default_cost') {
          item.default_cost = e.target.value;
        }
      }
      return item;
    });

    this.setState({ feePayments });
    // this.forceUpdate();
  }

  editTransactionFeeBtn() {
    this.props.system.editableT1 = true;
  }

  saveTransactionFee() {
    let invalidCount = 0;
    let feePayments = this.state.feePayments.map(item => {
      if (item.type === 'percent') {
        item.percent_valid = true;
        if (item.percent == null || isNaN(item.percent) || item.percent < 0 || item.percent > 100 || item.percent > item.default_percent) {
          item.percent_valid = false;
        }

        if (!item.percent_valid) invalidCount++;

        item.default_percent_valid = true;
        if (item.default_percent == null || isNaN(item.default_percent) || item.default_percent < 0 || item.default_percent > 100) {
          item.default_percent_valid = false;
        }

        if (!item.default_percent_valid) invalidCount++;
      } else if (item.type === 'baht') {
        item.cost_valid = true;
        if (item.cost == null || isNaN(item.cost) || item.cost < 0 || item.cost > item.default_cost) {
          item.cost_valid = false;
        }

        if (!item.cost_valid) invalidCount++;

        item.default_cost_valid = true;
        if (item.default_cost == null || isNaN(item.default_cost) || item.default_cost < 0) {
          item.default_cost_valid = false;
        }

        if (!item.default_cost_valid) invalidCount++;
      }

      return item;
    });

    let vat_valid = this.state.vat_valid;
    if (!vat_valid) invalidCount++;
    let wht_valid = this.state.wht_valid;
    if (!wht_valid) invalidCount++;

    this.setState({ feePayments, vat_valid, wht_valid });

    if (invalidCount > 0) {
      Swal.fire({
        customClass: 'nexter-alert',
        html:
          '<strong>There are some error values:</strong>' +
          '<div style="text-align: left; font-size: smaller;">- <span style="color: red">Cost</span> could not be greater than <span style="color: red">Default Cost</span>.</div>' +
          '<div style="text-align: left; font-size: smaller;">- <span style="color: red">Cost</span> and <span style="color: red">Default Cost</span> could not be less than 0.</div>' +
          '<div style="text-align: left; font-size: smaller;">- <span style="color: red">Percent Fee</span> could not be greater than 100%.</div>',
        type: 'error',
        title: 'Error!',
        confirmButtonText: 'OK',
      });
      return;
    }

    this.props.system.editableT1 = false;
    this.props.system.updateTransactionFeeConfig(this.state.feePayments);
    const input = {
      vat: this.state.vat,
      wht: this.state.wht,
    };
    this.props.system.updateSystemGlobalConfig(input);

    let vatValue = parseFloat(this.state.vat);
    vatValue = vatValue.toFixed(2);
    let whtValue = parseFloat(this.state.wht);
    whtValue = whtValue.toFixed(2);

    this.setState({
      vat: vatValue,
      wht: whtValue,
    });
  }

  cancelTransactionFee() {
    this.props.system.editableT1 = false;
    this.initTransactionFee();
  }

  changeVatConfig(e) {
    let vat = this.state.vat;
    vat = e.target.value;
    let vat_valid = true;
    if (!vat || isNaN(vat) || vat < 0 || vat > 100) {
      vat_valid = false;
    }
    this.setState({ vat, vat_valid });
  }

  changeWhtConfig(e) {
    let wht = this.state.wht;
    wht = e.target.value;
    let wht_valid = true;
    if (!wht || isNaN(wht) || wht < 0 || wht > 100) {
      wht_valid = false;
    }
    this.setState({ wht, wht_valid });
  }

  /*
    <div key={index} className="bg-base rounded p-2 pt-2 pb-2 mb-2">
      <label className="font-weight-bold text-capitalize">{fee.label}</label>
      <div className="row mt-1">
        <div className="d-flex col-12 col-md-6">
          <InputGroup className={`ml-2`} size="xs">
            <Input id={fee.key + '_p'} type="number" value={fee.percent} disabled={!feeEditable} onChange={this.changeTransactionFee.bind(this, fee.key, 'percent')} />
            <InputGroupAddon addonType="append">
              <InputGroupText>%</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
        <div className="d-flex col-12 col-md-6">
          <InputGroup className={`ml-2`} size="xs">
            <Input id={fee.key + '_c'} type="number" value={fee.cost} disabled={!feeEditable} onChange={this.changeTransactionFee.bind(this, fee.key, 'cost')} />
            <InputGroupAddon addonType="append">
              <InputGroupText>Baht</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </div>
      </div>
    </div>
  */

  render() {
    const { loading, editableT1: feeEditable } = this.props.system.getData();

    const feeInputs = this.state.feePayments.map((fee, index) => {
      if (fee.key !== 'installment') {
        return (
          <Col lg={4} xs={12} key={index}>
            <Card className="bg-config">
              <CardHeader className="config-header">{fee.label}</CardHeader>
              <CardBody className="bg-white">
                {!feeEditable ? (
                  <div className="transaction-setting">
                    <div className="d-flex justify-content-between">
                      <span>Units</span>
                      <strong>{fee.type === 'percent' ? 'PERCENT (%)' : fee.type === 'baht' ? 'BAHT (฿)' : '-'}</strong>
                    </div>
                    {fee.type === 'percent' && (
                      <>
                        <hr />
                        <div className="d-flex justify-content-between">
                          <span>Cost</span>
                          <strong>{fee.percent ? parseFloat(fee.percent).toFixed(2) : 0} %</strong>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                          <span>Default</span>
                          <strong>{fee.default_percent ? parseFloat(fee.default_percent).toFixed(2) : 0} %</strong>
                        </div>
                      </>
                    )}
                    {fee.type === 'baht' && (
                      <>
                        <hr />
                        <div className="d-flex justify-content-between">
                          <span>Cost</span>
                          <strong>฿ {fee.cost ? parseFloat(fee.cost).toFixed(2).toLocaleString() : 0}</strong>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                          <span>Default</span>
                          <strong>฿ {fee.default_cost ? parseFloat(fee.default_cost).toFixed(2).toLocaleString() : 0}</strong>
                        </div>
                      </>
                    )}
                    <div style={{ height: 24 }} />
                  </div>
                ) : (
                  <Form>
                    <FormGroup>
                      <Label>Units</Label>
                      <Input type="select" bsSize="sm" value={fee.type} disabled={!feeEditable} onChange={this.changeTransactionFee.bind(this, fee.key, 'fee_type')}>
                        <option value="percent">PERCENT (%)</option>
                        {fee.label === 'Installment' ? '' : <option value="baht">BAHT (฿)</option>}
                      </Input>
                    </FormGroup>
                    {fee.type === 'percent' ? (
                      <>
                        <FormGroup>
                          <Label>Cost</Label>
                          <InputGroup size="sm">
                            <Input
                              className={!fee.percent_valid ? 'is-invalid' : ''}
                              type="number"
                              step={0.01}
                              value={fee.percent ? parseFloat(fee.percent) : ''}
                              disabled={!feeEditable}
                              min={0}
                              max={100}
                              onChange={this.changeTransactionFee.bind(this, fee.key, 'percent')}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>%</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>

                        <FormGroup>
                          <Label>Default</Label>
                          <InputGroup size="sm">
                            <Input
                              className={!fee.default_percent_valid ? 'is-invalid' : ''}
                              type="number"
                              step={0.01}
                              value={fee.default_percent ? parseFloat(fee.default_percent) : ''}
                              disabled={!feeEditable}
                              min={0}
                              max={100}
                              onChange={this.changeTransactionFee.bind(this, fee.key, 'default_percent')}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>%</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </>
                    ) : (
                      ''
                    )}
                    {fee.type === 'baht' && fee.label !== 'Installment' ? (
                      <>
                        <FormGroup>
                          <Label>Cost</Label>
                          <InputGroup size="sm">
                            <Input
                              className={!fee.cost_valid ? 'is-invalid' : ''}
                              type="number"
                              step={0.01}
                              value={fee.cost ? parseFloat(fee.cost) : ''}
                              disabled={!feeEditable}
                              min={0}
                              onChange={this.changeTransactionFee.bind(this, fee.key, 'cost')}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>฿</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>

                        <FormGroup>
                          <Label>Default</Label>
                          <InputGroup size="sm">
                            <Input
                              className={!fee.default_cost_valid ? 'is-invalid' : ''}
                              type="number"
                              step={0.01}
                              value={fee.default_cost ? parseFloat(fee.default_cost) : ''}
                              disabled={!feeEditable}
                              min={0}
                              onChange={this.changeTransactionFee.bind(this, fee.key, 'default_cost')}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>฿</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </>
                    ) : (
                      ''
                    )}
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        );
      }
      return '';
    });

    const vatInput = (
      <Col lg={4} xs={12}>
        <Card className="bg-config">
          <CardHeader className="config-header">VAT</CardHeader>
          <CardBody className="bg-white">
            {!feeEditable ? (
              <div className="d-flex justify-content-between">
                <span>Cost</span>
                <strong>{this.state.vat} %</strong>
              </div>
            ) : (
              <Form>
                <FormGroup>
                  <Label>Cost</Label>
                  <InputGroup size="sm">
                    <Input
                      className={!this.state.vat_valid ? 'is-invalid' : ''}
                      type="number"
                      step={0.01}
                      value={this.state.vat}
                      disabled={!feeEditable}
                      min={0}
                      max={100}
                      onChange={this.changeVatConfig.bind(this)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>%</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Form>
            )}
          </CardBody>
        </Card>
      </Col>
    );

    const whtInput = (
      <Col lg={4} xs={12}>
        <Card className="bg-config">
          <CardHeader className="config-header">WHT</CardHeader>
          <CardBody className="bg-white">
            {!feeEditable ? (
              <div className="d-flex justify-content-between">
                <span>Cost</span>
                <strong>{this.state.wht} %</strong>
              </div>
            ) : (
              <Form>
                <FormGroup>
                  <Label>Cost</Label>
                  <InputGroup size="sm">
                    <Input
                      className={!this.state.wht_valid ? 'is-invalid' : ''}
                      type="number"
                      step={0.01}
                      value={this.state.wht}
                      disabled={!feeEditable}
                      min={0}
                      max={100}
                      onChange={this.changeWhtConfig.bind(this)}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>%</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Form>
            )}
          </CardBody>
        </Card>
      </Col>
    );

    return (
      <Page loading={loading} className="bg-white">
        <div className="container-fluid px-0">
          <Row>
            <Col>
              <Card className="no-r-tl">
                <CardBody className="pt-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="text-uppercase my-4 system-config-title">Default Channel Fee</h6>
                    {feeEditable ? (
                      <div>
                        <NexterButton size="sm" color="secondary" onClick={this.cancelTransactionFee.bind(this)} outline>
                          CANCEL
                        </NexterButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <NexterButton size="sm" icon="fa fa-save" color="success" onClick={this.saveTransactionFee.bind(this)} outline>
                          SAVE
                        </NexterButton>
                      </div>
                    ) : (
                      <div>
                        <Visibility permission_code="system_configuration:set_default_trans_fee">
                          <NexterButton size="sm" icon="fas fa-pencil-alt" onClick={this.editTransactionFeeBtn.bind(this)}>
                            EDIT
                          </NexterButton>
                        </Visibility>
                        <Link to={`${config.web.rootpath}/system-config/history?tab=transaction_fee`}>
                          <NexterButton className="ml-3 my-0" size="sm" outline icon="fas fa-history">
                            History Log
                          </NexterButton>
                        </Link>
                      </div>
                    )}
                  </div>
                  <hr className="mt-0 mb-3" />
                  <div className="mt-2">
                    <Row>{feeInputs}</Row>
                    <hr />
                    <h5>VAT/WHT</h5>
                    <Row>
                      {vatInput}
                      {whtInput}
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Page>
    );
  }
}

export default inject('system')(observer(TransactionFeePage));
