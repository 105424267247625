import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// react plugin used to create charts
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import {
  // UncontrolledTooltip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table
} from 'reactstrap';
import moment from 'moment'
import * as _ from 'lodash'
import { Amount } from '../../components/Field/Amount';
import numeral from 'numeral';
class TOPChannelChart extends Component {
  constructor(props) {
    super(props)
  }
  async componentDidMount() {
    await this.props.dashboard.fetchDashboardTopChannel()
  }
  render() {
    const { top_channel } = this.props.dashboard.getData();
    const { dataset, rawData } = top_channel
    const userStat = {
      options: {
        tooltips: {
          mode: 'label',
          intersect:false,
          bodyFontColor: '#161616',
          borderWidth:1,
         
          
       
          custom: function(tooltip) {
            
            tooltip.caretSize = 10,
            tooltip.backgroundColor = "#ffff",
            tooltip.titleFontColor = "#161616",
            tooltip.titleFontStyle = "bold",
            tooltip.bodyFontColor = "#161616",
            tooltip.borderColor='#7e84a3'
        },
          callbacks: {
            label: function (t, d) {
              var dstLabel = d.datasets[t.datasetIndex].label;
              var yLabel = numeral(t.yLabel || 0).format('0,0.00');
              return '฿' + yLabel.toLocaleString();
            }
          },
        },
        plugins: {
          datalabels: {
            display: false
          }
        },
        legend: {
          display: false,
        },

        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              userCallback: function(label, index, labels) {
                return numeral(label).format('0,0.00')
              }
            },
            scaleLabel: {
              display: true,
              labelString: 'จำนวน (บาท)'
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }],

        }
      },
    };

    const chart = (
      <div className="no-print">
        <Bar data={dataset} options={userStat.options} width={400} height={200} />
      </div>
    );

    const table = (
      <Table>
        <thead>
          <tr>
            <th className="table-header-chart">Rank</th>
            <th className="table-header-chart">ชื่อ Channel</th>
            <th className="table-header-chart">จำนวน (%)</th>
            <th className="table-header-chart">จำนวน (บาท)</th>
          </tr>
        </thead>
        <tbody>
          {rawData.map((item, index) => {
            return (
              <tr key={`details-${index}`}>
                <th scope="row">{index + 1}</th>
                <th> {item.name}</th>
                <td>{item.percents?.toFixed(1)}</td>
                <td><Amount value={item.net_amount}></Amount></td>
              </tr>
            )
          }
          )}
        </tbody>
      </Table>
    );

    return (
      <Card className="card-chart">
        <CardHeader>
          <div className="chart-header-row">
            <CardTitle className="chart-title " tag="h5">Top 5 Channel</CardTitle>
          </div>
        </CardHeader>
        <CardBody>
          <div className="no-print">
            <div className="d-inline-block w-50 pr-3 align-top">{chart}</div>
            <div className="d-inline-block w-50 pl-3 align-top">{table}</div>
          </div>
          <div id="invisible-chart">
            <div>{chart}</div>
            <div className="py-3" />
            <div>{table}</div>
          </div>
        </CardBody>
      </Card>
    )
  }
}
export default inject('dashboard')(observer(TOPChannelChart));
