import swal from 'sweetalert2';
import cloneDeep from 'lodash/cloneDeep';
import BaseStore from './BaseStore';
import { config } from '../config';
import { http } from '../utils/http';
import * as _ from 'lodash';

export class SystemStore extends BaseStore {
  constructor() {
    super();
    this.observable(this.getInitialData());
  }

  getInitialData() {
    return {
      configs: {
        system_email: '',
        default_vat: '',
        default_wht: '',

        // OLD - TRANSACTION FEE BY PAYMENT METHOD
        // default_credit_card_c: '',
        // default_bill_payment_c: '',
        // default_ktc_eng_c: '',
        // default_scg_wallet_c: '',
        // default_qr_c: '',
        // default_credit_card_s: '',
        // default_bill_payment_s: '',
        // default_ktc_eng_s: '',
        // default_scg_wallet_s: '',
        // default_qr_s: '',

        // OLD - PAYMENT METHOD SUFFIX
        // default_credit_card_suf: '',
        // default_bill_payment_suf: '',
        // default_ktc_eng_suf: '',
        // default_scg_wallet_suf: '',
        // default_qr_suf: '',

        // OLD - VOID SETTINGS
        // void_kbank_actual_time: '',
        // void_kbank_admin_time: '',
        // void_kbank_channel_time: '',
        // void_bbl_actual_time: '',
        // void_bbl_admin_time: '',
        // void_bbl_channel_time: '',
        // void_krungsri_actual_time: '',
        // void_krungsri_admin_time: '',
        // void_krungsri_channel_time: '',
        // void_ktb_actual_time: '',
        // void_ktb_admin_time: '',
        // void_ktb_channel_time: '',
        // void_123_actual_time: '',
        // void_123_admin_time: '',
        // void_123_channel_time: '',
        // void_t2p_actual_time: '',
        // void_t2p_admin_time: '',
        // void_t2p_channel_time: '',

        // OLD - PAYMENT METHODS
        // payment_option_credit_card: false,
        // payment_option_installment: false,
        // payment_option_bill_payment: false,
        // payment_option_ktc_eng: false,
        // payment_option_scg_wallet: false,
        // payment_option_qr: false,

        // OLD - INSTALLMENT INTERESTS
        // installment_option_krungsri: false,
        // installment_option_bbl: false,
        // installment_option_kbank: false,
        // installment_option_ktb: false,
        // installment_option_scb: false,
        // installment_option_krungsri_plan: '',
        // installment_option_bbl_plan: '',
        // installment_option_kbank_plan: '',
        // installment_option_ktb_plan: '',
        // installment_option_scb_plan: '',
        // installment_interest_plan_full_choice: [],
        // installment_interest_krungsri_cost: '',
        // installment_interest_bbl_cost: '',
        // installment_interest_kbank_cost: '',
        // installment_interest_scb_cost: '',
        // installment_interest_ktb_cost: '',
        // installment_interest_krungsri_set: '',
        // installment_interest_bbl_set: '',
        // installment_interest_kbank_set: '',
        // installment_interest_scb_set: '',
        // installment_interest_ktb_set: '',
        // installment_interest_krungsri_min: '',
        // installment_interest_bbl_min: '',
        // installment_interest_kbank_min: '',
        // installment_interest_scb_min: '',
        // installment_interest_ktb_min: '',
      },
      keep_configs: {},
      promotion_list: [],
      payment_options: [],
      due_payments: {},
      fee_payments: [],
      void_settings: [],
      installment_interests: [],
      loading: false,
      editableT1: false, // Default Channel Fee setting - Transaction Fee setting
      editableT2: false, // Void setting
      editableT3: false, // Payment Option setting
      editableT4: false, // Installment Interest setting
      editableT5: false, // Other setting
      editableT6: false, // Due of Payment setting
      logs: [],
      read_description: '',
      bank: '',
      promotion_name: '',
      description: '',
      start_date: '',
      end_date: '',
      channel: '',
      payment_method: [],
      pictures: [],
      checkboxes: [
        { id: 1, value: 'Credit Card', code: 'credit_card' },
        { id: 2, value: 'QR/ Bill Payment (BBL)', code: 'qr_box' },
        { id: 3, value: 'SCG Cash Card/ SCG Wallet', code: 'scg_wallet' },
        { id: 4, value: 'Line Pay', code: 'line' },
        { id: 5, value: 'Mobile Banking', code: 'bill_payment_mobile' },
        { id: 6, value: 'Counter Service', code: 'bill_payment' },
        { id: 7, value: 'Installment', code: 'installment' },
        { id: 8, value: 'Chang Family Wallet', code: 'chang_family_wallet' },
        { id: 9, value: 'Thai QR Payment', code: 'promptpay' },
        { id: 10, value: 'GrabPay', code: 'grabpay' },
        { id: 11, value: 'CBDC Payment', code: 'cbdc' },
        { id: 12, value: 'Mobile Banking (App Switch)', code: 'mobile_banking_opn' },
      ],
      checkedItems: new Map(),
      promo_sort_direction: 'created_at_desc',
      has_more_promotion_list: true,
    };
  }

  changeConfig(key, val) {
    this.configs[key] = val;
  }

  async updateSystemConfig(section) {
    try {
      const changesets = require('diff-json');
      const diff = changesets.diff(this.keep_configs, this.configs);
      let sectionFil = diff.filter(conf => conf.key.indexOf(section) > -1);
      let url = `${config.npay.apiUrl}/admin/channels/config`;
      let response = await http.put(url, { body: sectionFil });
      if (response.status === 200) {
        this.loading = true;
        await this.fetchConfig();
        swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          title: 'Success!',
          text: 'System Config has been successfully saved',
          confirmButtonText: 'OK',
        });
        this.loading = false;
        this.editableT1 = false;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async getAdminHistoryLogs() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/historylogs`;
      let body = {
        parent: 'admin',
        parent_id: 0,
        entity: ['config'],
      };

      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        this.logs = body.data || [];
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async reorderPromotionList(sourceIndex, destinationIndex) {
    const oldList = cloneDeep(this.promotion_list);
    const newList = cloneDeep(this.promotion_list);
    const sourceItem = newList[sourceIndex];
    const destinationItem = newList[destinationIndex];
    const [reorderedItem] = newList.splice(sourceIndex, 1);
    newList.splice(destinationIndex, 0, reorderedItem);
    this.promotion_list = newList;
    try {
      const url = `${config.npay.apiUrl}/promotion/order`;
      const body = {
        source_id: sourceItem.id,
        destination_id: destinationItem.id,
      };
      let response = await http.put(url, { body });
      if (response.status === 200) {
        console.log('Success');
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (error) {
      this.promotion_list = oldList;
      console.error(error);
    }
  }

  async getPromotionList(page, size, showLoading = true) {
    try {
      const queryParams = { page, size };
      const queryString = new URLSearchParams(_.omitBy(queryParams, _.isUndefined));
      if (showLoading) {
        this.loading = true;
      }
      let url = `${config.npay.apiUrl}/promotion?${queryString.toString()}`;
      let response = await http.get(url);

      if (response.status === 200) {
        let body = await response.json();
        let data = body.data || [];
        if (page === 1) {
          this.promotion_list = data;
        } else {
          this.promotion_list = [...this.promotion_list, ...data];
        }
        if (data.length === 0) {
          this.has_more_promotion_list = false;
        }
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  removePromotionFromState(id) {
    let promotionList = _.cloneDeep(this.promotion_list);
    let foundIndex = promotionList.findIndex(item => item.id === id);
    if (foundIndex !== -1) {
      promotionList.splice(foundIndex, 1);
    }
    this.promotion_list = promotionList;
  }

  clearPromotionList() {
    this.promotion_list = [];
    this.has_more_promotion_list = true;
  }

  async fetchConfig() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/config/?type=all`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        // console.log(body);
        const configs = {
          system_email: body.conf.filter(con => con.config_key === 'System_Email')[0].config_value,
          default_vat: body.conf.filter(con => con.config_key === 'VAT')[0].config_value,
          default_wht: body.conf.filter(con => con.config_key === 'WHT')[0].config_value,

          // default_vat: body.conf.filter(con => con.config_key === 'Default_VAT')[0].config_value,
          // default_wht: body.conf.filter(con => con.config_key === 'Default_WHT')[0].config_value,

          // default_credit_card_c: body.conf.filter(con => con.config_key === 'Default_Credit_Card_Cost')[0].config_value,
          // default_bill_payment_c: body.conf.filter(con => con.config_key === 'Default_Bill_Payment_Cost')[0].config_value,
          // default_qr_c: body.conf.filter(con => con.config_key === 'Default_QR_Cost')[0].config_value,
          // default_ktc_eng_c: body.conf.filter(con => con.config_key === 'Default_KTC_Eng_Cost')[0].config_value,
          // default_scg_wallet_c: body.conf.filter(con => con.config_key === 'Default_SCG_Wallet_Cost')[0].config_value,
          // default_credit_card_s: body.conf.filter(con => con.config_key === 'Default_Credit_Card_Set')[0].config_value,

          // default_bill_payment_s: body.conf.filter(con => con.config_key === 'Default_Bill_Payment_Set')[0].config_value,
          // default_qr_s: body.conf.filter(con => con.config_key === 'Default_QR_Set')[0].config_value,
          // default_ktc_eng_s: body.conf.filter(con => con.config_key === 'Default_KTC_Eng_Set')[0].config_value,
          // default_scg_wallet_s: body.conf.filter(con => con.config_key === 'Default_SCG_Wallet_Set')[0].config_value,

          // default_credit_card_suf: body.conf.filter(con => con.config_key === 'Default_Credit_Card_Suffix')[0].config_value,
          // default_bill_payment_suf: body.conf.filter(con => con.config_key === 'Default_Bill_Payment_Suffix')[0].config_value,
          // default_ktc_eng_suf: body.conf.filter(con => con.config_key === 'Default_KTC_Eng_Suffix')[0].config_value,
          // default_scg_wallet_suf: body.conf.filter(con => con.config_key === 'Default_SCG_Wallet_Suffix')[0].config_value,
          // default_qr_suf: body.conf.filter(con => con.config_key === 'Default_QR_Suffix')[0].config_value,

          // NOTICE: use table void_settings instead of table config;
          // void_kbank_actual_time: body.conf.filter(con => con.config_key === 'Void_Kbank_Actual_Time')[0].config_value,
          // void_kbank_admin_time: body.conf.filter(con => con.config_key === 'Void_Kbank_Admin_Time')[0].config_value,
          // void_kbank_channel_time: body.conf.filter(con => con.config_key === 'Void_Kbank_Channel_Time')[0].config_value,
          // void_bbl_actual_time: body.conf.filter(con => con.config_key === 'Void_BBL_Actual_Time')[0].config_value,
          // void_bbl_admin_time: body.conf.filter(con => con.config_key === 'Void_BBL_Admin_Time')[0].config_value,
          // void_bbl_channel_time: body.conf.filter(con => con.config_key === 'Void_BBL_Channel_Time')[0].config_value,
          // void_krungsri_actual_time: body.conf.filter(con => con.config_key === 'Void_Krungsri_Actual_Time')[0].config_value,
          // void_krungsri_admin_time: body.conf.filter(con => con.config_key === 'Void_Krungsri_Admin_Time')[0].config_value,
          // void_krungsri_channel_time: body.conf.filter(con => con.config_key === 'Void_Krungsri_Channel_Time')[0].config_value,
          // void_ktb_actual_time: body.conf.filter(con => con.config_key === 'Void_KTB_Actual_Time')[0].config_value,
          // void_ktb_admin_time: body.conf.filter(con => con.config_key === 'Void_KTB_Admin_Time')[0].config_value,
          // void_ktb_channel_time: body.conf.filter(con => con.config_key === 'Void_KTB_Channel_Time')[0].config_value,
          // void_123_actual_time: body.conf.filter(con => con.config_key === 'Void_123_Actual_Time')[0].config_value,
          // void_123_admin_time: body.conf.filter(con => con.config_key === 'Void_123_Admin_Time')[0].config_value,
          // void_123_channel_time: body.conf.filter(con => con.config_key === 'Void_123_Channel_Time')[0].config_value,
          // void_t2p_actual_time: body.conf.filter(con => con.config_key === 'Void_T2P_Actual_Time')[0].config_value,
          // void_t2p_admin_time: body.conf.filter(con => con.config_key === 'Void_T2P_Admin_Time')[0].config_value,
          // void_t2p_channel_time: body.conf.filter(con => con.config_key === 'Void_T2P_Channel_Time')[0].config_value,

          // NOTICE: use table payment_methods instead of table config;
          // payment_option_credit_card: body.conf.filter(con => con.config_key === 'Payment_Option_Credit_Card')[0].config_value === 'true',
          // payment_option_installment: body.conf.filter(con => con.config_key === 'Payment_Option_Installment')[0].config_value === 'true',
          // payment_option_bill_payment: body.conf.filter(con => con.config_key === 'Payment_Option_Bill_Payment')[0].config_value === 'true',
          // payment_option_ktc_eng: body.conf.filter(con => con.config_key === 'Payment_Option_KTC_Eng')[0].config_value === 'true',
          // payment_option_scg_wallet: body.conf.filter(con => con.config_key === 'Payment_Option_SCG_Wallet')[0].config_value === 'true',
          // payment_option_qr: body.conf.filter(con => con.config_key === 'Payment_Option_QR')[0].config_value === 'true',

          // NOTICE: use table installment_interests instead of table config;
          // installment_option_krungsri: body.conf.filter(con => con.config_key === 'Installment_Option_Krungsri')[0].config_value === 'true',
          // installment_option_bbl: body.conf.filter(con => con.config_key === 'Installment_Option_BBL')[0].config_value === 'true',
          // installment_option_kbank: body.conf.filter(con => con.config_key === 'Installment_Option_Kbank')[0].config_value === 'true',
          // installment_option_ktb: body.conf.filter(con => con.config_key === 'Installment_Option_KTB')[0].config_value === 'true',
          // installment_option_scb: body.conf.filter(con => con.config_key === 'Installment_Option_SCB')[0].config_value === 'true',
          // installment_option_krungsri_plan: body.conf.filter(con => con.config_key === 'Installment_Option_Krungsri_Plan')[0].config_value.split(','),
          // installment_option_bbl_plan: body.conf.filter(con => con.config_key === 'Installment_Option_BBL_Plan')[0].config_value.split(','),
          // installment_option_kbank_plan: body.conf.filter(con => con.config_key === 'Installment_Option_Kbank_Plan')[0].config_value.split(','),
          // installment_option_ktb_plan: body.conf.filter(con => con.config_key === 'Installment_Option_KTB_Plan')[0].config_value.split(','),
          // installment_option_scb_plan: body.conf.filter(con => con.config_key === 'Installment_Option_SCB_Plan')[0].config_value.split(','),
          // installment_interest_plan_full_choice: body.conf.filter(con => con.config_key === 'Installment_Interest_Plan_Full_Choice')[0].config_value.split(','),
          // installment_interest_krungsri_set: body.conf.filter(con => con.config_key === 'Installment_Interest_Krungsri_Set')[0].config_value,
          // installment_interest_bbl_set: body.conf.filter(con => con.config_key === 'Installment_Interest_BBL_Set')[0].config_value,
          // installment_interest_kbank_set: body.conf.filter(con => con.config_key === 'Installment_Interest_Kbank_Set')[0].config_value,
          // installment_interest_scb_set: body.conf.filter(con => con.config_key === 'Installment_Interest_SCB_Set')[0].config_value,
          // installment_interest_ktb_set: body.conf.filter(con => con.config_key === 'Installment_Interest_KTB_Set')[0].config_value,
          // installment_interest_krungsri_cost: body.conf.filter(con => con.config_key === 'Installment_Interest_Krungsri_Cost')[0].config_value,
          // installment_interest_bbl_cost: body.conf.filter(con => con.config_key === 'Installment_Interest_BBL_Cost')[0].config_value,
          // installment_interest_kbank_cost: body.conf.filter(con => con.config_key === 'Installment_Interest_Kbank_Cost')[0].config_value,
          // installment_interest_scb_cost: body.conf.filter(con => con.config_key === 'Installment_Interest_SCB_Cost')[0].config_value,
          // installment_interest_ktb_cost: body.conf.filter(con => con.config_key === 'Installment_Interest_KTB_Cost')[0].config_value,
          // installment_interest_krungsri_min: body.conf.filter(con => con.config_key === 'Installment_Interest_Krungsri_Min')[0].config_value,
          // installment_interest_bbl_min: body.conf.filter(con => con.config_key === 'Installment_Interest_BBL_Min')[0].config_value,
          // installment_interest_kbank_min: body.conf.filter(con => con.config_key === 'Installment_Interest_Kbank_Min')[0].config_value,
          // installment_interest_scb_min: body.conf.filter(con => con.config_key === 'Installment_Interest_SCB_Min')[0].config_value,
          // installment_interest_ktb_min: body.conf.filter(con => con.config_key === 'Installment_Interest_KTB_Min')[0].config_value,
        };
        this.configs = await configs;
        this.keep_configs = await configs;
        console.log(this.configs);
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async fetchPaymentOptionConfig() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/paymentmethods/status`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.payment_options = body.data;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async updatePaymentOptionConfig(input) {
    try {
      let url = `${config.npay.apiUrl}/admin/paymentmethods/status`;
      let payment_methods = input.map(item => {
        return {
          code: item.key,
          status: item.checked,
        };
      });
      let bodyData = { payment_methods };
      let response = await http.put(url, { body: bodyData });
      if (response.status === 200) {
        console.log('OK');
        this.loading = true;
        await this.fetchPaymentOptionConfig();
        swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          title: 'Success!',
          text: 'Payment Option Config has been successfully saved',
          confirmButtonText: 'OK',
        });
        this.loading = false;
        this.editableT3 = false;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async fetchDueDataConfig() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/settings/duedate`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        let data = body.data;
        this.due_payments = {
          default_due_date: data.default_due_date,
          maximum_due_date: data.maximum_due_date,
        };
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async updateDueDataConfig(input) {
    try {
      let url = `${config.npay.apiUrl}/admin/settings/duedate`;
      let due_data = input;
      let bodyData = { due_data };
      let response = await http.put(url, { body: bodyData });
      if (response.status === 200) {
        this.loading = true;
        await this.fetchDueDataConfig();
        swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          title: 'Success!',
          text: 'Due of Payment Config has been successfully saved',
          confirmButtonText: 'OK',
        });
        this.loading = false;
        this.editableT6 = false;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async fetchTransactionFeeConfig() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/feepayments`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.fee_payments = body.data;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async updateTransactionFeeConfig(input) {
    try {
      let url = `${config.npay.apiUrl}/admin/feepayments`;
      let fee_payments = input.map(item => {
        return {
          code: item.key,
          percent_fee: item.percent,
          fixed_cost_fee: item.cost,
          default_fee_type: item.type,
          default_percent_fee: item.default_percent,
          default_fixed_cost_fee: item.default_cost,
        };
      });

      let bodyData = { fee_payments };
      let response = await http.put(url, { body: bodyData });
      if (response.status === 200) {
        this.loading = true;
        await this.fetchTransactionFeeConfig();
        swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          title: 'Success!',
          text: 'Transaction Fee Config has been successfully saved',
          confirmButtonText: 'OK',
        });
        this.loading = false;
        this.editableT1 = false;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async fetchInstallmentDataConfig() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/settings/installmentinterest`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.installment_interests = body.data;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async updateInstallmentDataConfig(input) {
    try {
      let url = `${config.npay.apiUrl}/admin/settings/installmentinterest`;
      let installment_interests = input.map(item => {
        return {
          id: item.id,
          status: item.status,
          cost: item.cost,
          default_cost: item.default_cost,
          minimum_amount: item.minimum_amount,
          terms: item.terms,
        };
      });
      let bodyData = { installment_interests };
      let response = await http.put(url, { body: bodyData });
      if (response.status === 200) {
        this.loading = true;
        await this.fetchInstallmentDataConfig();
        swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          title: 'Success!',
          text: 'Installment Interest Config has been successfully saved',
          confirmButtonText: 'OK',
        });
        this.loading = false;
        this.editableT4 = false;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async fetchVoidDataConfig() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/settings/void`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.void_settings = body.data;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async updateVoidDataConfig(input) {
    try {
      let url = `${config.npay.apiUrl}/admin/settings/void`;
      let void_settings = input;
      let bodyData = { void_settings };
      let response = await http.put(url, { body: bodyData });
      if (response.status === 200) {
        this.loading = true;
        await this.fetchInstallmentDataConfig();
        swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          title: 'Success!',
          text: 'Void Time Config has been successfully saved',
          confirmButtonText: 'OK',
        });
        this.loading = false;
        this.editableT4 = false;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async fetchOtherDataConfig() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/settings/other`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.configs.system_email = body.data.system_email;
        this.configs.default_vat = body.data.vat;
        this.configs.default_wht = body.data.wht;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async updateSystemEmailConfig(input) {
    try {
      let url = `${config.npay.apiUrl}/admin/settings/email`;
      let bodyData = { system_email: input };
      let response = await http.put(url, { body: bodyData });
      if (response.status === 200) {
        this.loading = true;
        await this.fetchOtherDataConfig();
        swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          title: 'Success!',
          text: 'System E-mail has been successfully saved',
          confirmButtonText: 'OK',
        });
        this.loading = false;
        this.editableT5 = false;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async updateSystemGlobalConfig(input) {
    try {
      let url = `${config.npay.apiUrl}/admin/settings/global`;
      let bodyData = { vat: input.vat, wht: input.wht };
      let response = await http.put(url, { body: bodyData });
      if (response.status === 200) {
        await this.fetchOtherDataConfig();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  sortArrayViewDate(mode) {
    this.promo_sort_direction = mode;
    if (this.promo_sort_direction === 'created_at_asc') {
      this.sortArrayFirstCreateDate();
    } else if (this.promo_sort_direction === 'updated_at_desc') {
      this.sortArrayLastUpdateDate();
    } else if (this.promo_sort_direction === 'updated_at_asc') {
      this.sortArrayFirstUpdateDate();
    } else if (this.promo_sort_direction === 'start_date_desc') {
      this.sortArrayLastStartDate();
    } else if (this.promo_sort_direction === 'start_date_asc') {
      this.sortArrayFirstStartDate();
    } else if (this.promo_sort_direction === 'end_date_desc') {
      this.sortArrayLastEndDate();
    } else if (this.promo_sort_direction === 'end_date_asc') {
      this.sortArrayFirstEndDate();
    } else {
      // created_at_desc
      this.sortArrayLastCreateDate();
    }
  }

  sortArrayFirstStartDate() {
    function sortByDate(a, b) {
      if (a.start_date > b.start_date) {
        return 1;
      }
      if (a.start_date < b.start_date) {
        return -1;
      }
      return 0;
    }

    const sorted = this.promotion_list.sort(sortByDate);
    this.promotion_list = sorted;
  }

  sortArrayLastStartDate() {
    function sortByDate(a, b) {
      if (a.start_date < b.start_date) {
        return 1;
      }
      if (a.start_date > b.start_date) {
        return -1;
      }
      return 0;
    }

    const sorted = this.promotion_list.sort(sortByDate);
    this.promotion_list = sorted;
  }

  sortArrayFirstEndDate() {
    function sortByDate(a, b) {
      if (a.end_date > b.end_date) {
        return 1;
      }
      if (a.end_date < b.end_date) {
        return -1;
      }
      return 0;
    }

    const sorted = this.promotion_list.sort(sortByDate);
    this.promotion_list = sorted;
  }

  sortArrayLastEndDate() {
    function sortByDate(a, b) {
      if (a.end_date < b.end_date) {
        return 1;
      }
      if (a.end_date > b.end_date) {
        return -1;
      }
      return 0;
    }

    const sorted = this.promotion_list.sort(sortByDate);
    this.promotion_list = sorted;
  }

  sortArrayFirstCreateDate() {
    function sortByDate(a, b) {
      if (a.created_at > b.created_at) {
        return 1;
      }
      if (a.created_at < b.created_at) {
        return -1;
      }
      return 0;
    }

    const sorted = this.promotion_list.sort(sortByDate);
    this.promotion_list = sorted;
  }

  sortArrayLastCreateDate() {
    function sortByDate(a, b) {
      if (a.created_at < b.created_at) {
        return 1;
      }
      if (a.created_at > b.created_at) {
        return -1;
      }
      return 0;
    }

    const sorted = this.promotion_list.sort(sortByDate);
    this.promotion_list = sorted;
  }

  sortArrayFirstUpdateDate() {
    function sortByDate(a, b) {
      if (a.updated_at > b.updated_at) {
        return 1;
      }
      if (a.updated_at < b.updated_at) {
        return -1;
      }
      return 0;
    }

    const sorted = this.promotion_list.sort(sortByDate);
    this.promotion_list = sorted;
  }

  sortArrayLastUpdateDate() {
    function sortByDate(a, b) {
      if (a.updated_at < b.updated_at) {
        return 1;
      }
      if (a.updated_at > b.updated_at) {
        return -1;
      }
      return 0;
    }

    const sorted = this.promotion_list.sort(sortByDate);
    this.promotion_list = sorted;
  }
}
