import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import _ from 'lodash';
import { config } from '../../config';
import { http } from '../../utils/http';
import { Card, CardBody, Table, Row, Col } from 'reactstrap';
import { Page } from '../../components/Page';
import { EmptyRow } from '../../components/Table/EmptyRow';
import { Pagination } from '../../components/Pagination/Pagination';
import { ensureJSONparse } from '../../utils/string';

const FIELD = {
  payments: {
    description: 'รายละเอียด',
  },
  payment_items: {
    description: 'รายการ',
  },
};

const t = (entity, field) => {
  const translateField = _.get(FIELD, [entity, field], field);
  return translateField;
};

class PaymentHistoryLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      logs: [],
      page: 1,
      size: 10,
      total: 1,
    };
  }

  componentDidMount() {
    this.fetchLogs();
  }

  async fetchLogs() {
    try {
      this.setState({ loading: true });
      const { payment } = this.props.payment.getData();
      let parent_id = [payment.id];
      if (payment.sub_payments) {
        parent_id = payment.sub_payments.map(p => p.id);
      }
      let url = `${config.npay.apiUrl}/historylogs`;
      let body = {
        page: this.state.page,
        size: this.state.size,
        parent: 'payment',
        entity: ['payments', 'payment_items'],
        parent_id,
      };
      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        this.setState({
          logs: body.data || [],
          total: body.total || 1,
        });
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 100);
    }
  }

  selectPage(page) {
    this.setState({ page }, async () => {
      await this.fetchLogs();
    });
  }

  backToChannel() {
    this.props.history.goBack();
  }

  render() {
    const { loading, logs } = this.state;
    const rows = logs.length ? (
      logs.map((item, index) => {
        const oldValue = ensureJSONparse(item.old_value);
        const newValue = ensureJSONparse(item.new_value);
        const actionName = _.get(item, 'action_name', '');
        const updated_by = _.get(item, 'updated_by', '');

        let list = newValue !== null && !_.isEmpty(newValue) ? newValue : oldValue;
        let result = Object.entries(list).map(([key, value], i) => {
          const entity = _.get(item, 'entity');
          const field = t(entity, key);
          const ov = _.get(oldValue, key);
          const nv = _.get(newValue, key);
          return (
            <tr key={`item-${item.id}`}>
              <td width="142">{moment(_.get(item, 'updated_at', '-')).format('DD-MM-YYYY HH:mm')}</td>
              <td width="152">{updated_by}</td>
              <td width="150">
                <div className={`action-wrapper ${actionName}`}>{actionName}</div>
              </td>
              <td width="80" className="keys">
                <span>{field}</span>
              </td>
              <td width="150">
                <span>{ov}</span>
              </td>
              <td width="150">
                <span>{nv}</span>
              </td>
            </tr>
          );
        });
        return result;
      })
    ) : (
      <EmptyRow colSpan={7} />
    );
    return (
      <Fragment>
        <Page loading={loading}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="text-uppercase" style={{ marginBottom: 0, marginTop: 25 }}>
              <a onClick={this.backToChannel.bind(this)}>
                <i style={{ color: '#e20a1a', marginRight: 20 }} className="fas fa-chevron-left"></i>
                <b>History Log</b>
              </a>
            </h5>
          </div>
          <Card className="historylog-wrapper">
            <CardBody>
              <Fragment>
                <Table>
                  <thead>
                    <tr>
                      <th className="border-0">วันที่แก้ไข</th>
                      <th className="border-0">แก้ไขโดย</th>
                      <th className="border-0">กิจกรรม</th>
                      <th className="border-0">ฟิลด์ที่แก้ไข</th>
                      <th className="border-0">ข้อมูลเดิม</th>
                      <th className="border-0">ข้อมูลที่แก้ไข</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </Table>
                <hr />
                <Row>
                  <Col />
                  <Col>
                    <Pagination
                      className="pull-right"
                      activePage={this.state.page}
                      displayPageNumber={5}
                      maximumPageNumber={Math.ceil(this.state.total / this.state.size)}
                      onSelect={page => this.selectPage(page)}
                    />
                  </Col>
                </Row>
              </Fragment>
            </CardBody>
          </Card>
        </Page>
      </Fragment>
    );
  }
}

export default inject('payment')(observer(PaymentHistoryLog));
