if (typeof window === 'undefined' || !window || !window.document) {
  global.window = { location: { host: 'localhost' } };
}

export const config = ((host) => {
  switch (true) {
    default:
      return require('./config/config.localhost');
  }
})(window.location.host);
