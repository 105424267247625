import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// react plugin used to create charts
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col } from 'reactstrap';
import * as _ from 'lodash';
import { CheckBox } from '../../components/Input/CheckBox';
import { Amount } from '../../components/Field/Amount';
import numeral from 'numeral';

var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function () {
    originalDoughnutDraw.apply(this, arguments);

    var chart = this.chart.chart;
    var ctx = chart.ctx;
    var width = chart.width;
    var height = chart.height;

    var fontSize = (height / 150).toFixed(2);
    ctx.font = fontSize + 'em Verdana';
    ctx.textBaseline = 'middle';

    var text = chart.config.data.text,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2.25;
    var text2 = chart.config.data.text2,
      textX2 = Math.round((width - ctx.measureText(text2).width) / 2),
      textY2 = height / 1.75;

    ctx.fillText(text, textX, textY);
    ctx.fillText(text2, textX2, textY2);
  },
});
class CardBankChart extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.dashboard.fetchDashboardCreditCardBankMethod();
  }
  onChangeCheckbox(val) {
    this.props.dashboard.setPaymentType(val);
    this.props.dashboard.fetchDashboardCreditCardBankMethod();
  }
  onChangeCheckboxFilter(label, data, indexFilter, bgColor) {
    let tempLabels = this.props.dashboard.card_bank.labels;
    let tempData = this.props.dashboard.card_bank.data;
    let tempBgColor = this.props.dashboard.card_bank.bgColor;
    const index = tempLabels.indexOf(label);

    if (index < 0) {
      tempLabels.splice(indexFilter, 0, label);
      tempData.splice(indexFilter, 0, data);
      tempBgColor.splice(indexFilter, 0, bgColor);
    } else {
      tempLabels.splice(index, 1);
      tempData.splice(index, 1);
      tempBgColor.splice(index, 1);
    }
    
    this.props.dashboard.setCardBankFilter(tempLabels, tempData, tempBgColor);
  }

  numberWithCommas = x => {
    if (x) {
      const num = x.toString().replace(/[^0-9.]/g, '').split('.');
      num[0] = num[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return num.slice(0, 2).join('.');
    } else {
      return '0';
    }
  };

  render() {
    const { card_bank } = this.props.dashboard.getData();
    let sortedCardBank = _.sortBy(card_bank.rawData, s => s.total_usage).reverse();
    const userGate = {
      data: canvas => {
        return {
          labels: card_bank.labels,
          datasets: [
            {
              label: 'Gateway Usages',
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: card_bank.bgColor,
              borderWidth: 0,
              data: card_bank.data,
            },
          ],
          text: this.numberWithCommas(card_bank.data.reduce((acc, amt) => (acc + amt), 0).toFixed(2)),
          text2: 'บาท',
        };
      },
      options: {
        responsive: true,
        plugins: {
          datalabels: {
            display: false,
          },
        },
        tooltips: {
          bodyFontColor: '#161616',
          borderWidth: 1,

          custom: function (tooltip) {
            (tooltip.caretSize = 10),
              (tooltip.backgroundColor = '#ffff'),
              (tooltip.titleFontColor = '#161616'),
              (tooltip.titleFontStyle = 'bold'),
              (tooltip.bodyFontColor = '#161616'),
              (tooltip.borderColor = '#7e84a3');
          },
          callbacks: {
            title: function (tooltipItem, data) {
              return data['labels'][tooltipItem[0]['index']];
            },
            label: function (tooltipItem, data) {
              let num = numeral(data['datasets'][0]['data'][tooltipItem['index']] || 0).format('0,0.00');
              let yLabel = num.toLocaleString();
              return '฿' + yLabel;
            },
          },
        },
        legend: {
          display: false,
        },

        cutoutPercentage: 85,
      },
    };
    return (
      <Card>
        <CardHeader>
          <div className="chart-header-row">
            <CardTitle className="chart-title " tag="h5">
              การใช้งานตามประเภทบัตร
            </CardTitle>
          </div>
          <div>
            <CheckBox label="Full Payment" checked={card_bank.payment_type.credit_card} onChange={this.onChangeCheckbox.bind(this, 'credit_card')} />
            <CheckBox label="Installment" checked={card_bank.payment_type.installment} onChange={this.onChangeCheckbox.bind(this, 'installment')} />
          </div>
        </CardHeader>
        <CardBody>
          <div className="doughnut-con">
            <div className="no-print">
              <Doughnut data={userGate.data} options={userGate.options} />
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <ul className="p-0 m-0">
            <p>
              ทั้งหมด: {sortedCardBank.reduce((a, b) => a + (b.count_usage || 0), 0)} รายการ
            </p>
            {sortedCardBank.map((item, index) => {
              return (
                <li className="card-bank-legend" key={`card-${index}`}>
                  <div className="card-bank-list">
                    <CheckBox
                      defaultClassName='mb-2 mr-sm-1 mb-sm-0 mb-md-2'
                      checked={card_bank.labels.includes(sortedCardBank[index].card_bank_name)}
                      onChange={this.onChangeCheckboxFilter.bind(this, sortedCardBank[index].card_bank_name, card_bank.rawData[index].total_usage, index, card_bank.rawData[index].backgroundColor)}
                    />
                    <div>
                      <i className={`fa fa-circle`} style={{ color: item.backgroundColor }} />
                      {` ${item.card_bank_code === 'KTB' ? 'KTC' : item.card_bank_code}`}
                    </div>
                  </div>
                  <p className={`percent-bank`}>
                    {item.percents === null ? 0 : item.percents.toFixed(2)}% | {` `}
                  </p>
                  <p className={`total-amount-bank`}>
                    <Amount value={item.total_usage === null ? 0 : item.total_usage.toFixed(2)}> </Amount> บาท
                  </p>
                </li>
              );
            })}
          </ul>
        </CardFooter>
      </Card>
    );
  }
}
export default inject('dashboard')(observer(CardBankChart));
