import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { config } from '../../config';
import swal from 'sweetalert2';
import { Page } from '../../components/Page';
import moment from 'moment';
import { Row, Col, CardHeader, FormGroup, FormFeedback, Label, Form } from 'reactstrap';
import Visibility from '../../components/Visibility/Visibility';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';
import MultiSelect from '../../components/Forms/MultiSelect';
import { validation } from '../../utils/validation';

class AccessUserProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role_id: null,
      channel_id: null,
      status: null,
      setValue: {
        role_id: false,
        channel_id: false,
        status: false,
      },
      errors: {},
    };
  }

  componentWillUnmount() {
    this.props.user.editable_channel = false;
    this.props.user.editable = false;
    this.props.user.createable = true;
    this.props.user.nonSelectChannel = true;
    this.props.user.viewuser = [];
    this.props.user.reserveEmail = [];
  }

  componentDidMount() {
    this.props.user.reserveEmail = [];
    // let uid = this.props.match.params.uid;
    let uid = this.props.user.getData().selectedUser;
    this.props.user.getUsersByUId(uid);
    this.props.user.getAllRole();
    this.props.user.getAllChannel();
    // this.props.user.getUsers();
  }

  changeConfig(key, value) {
    let tmpV = null;
    switch (key) {
      case 'role_id':
      case 'channel_id':
      case 'status':
        this.state[key] = value;
        tmpV = value.value;
        break;
      case 'channel_ids':
        tmpV = value;
        break;
      case 'tel':
        tmpV = value.target.value;
        break;
      default:
        tmpV = value.target.value;
    }
    this.validateInputs(key, tmpV);
    this.props.user.nonSelectChannel = true;
    this.props.user.changeUserDetail(key, tmpV);
    this.props.user.changeConfig(key, tmpV);
    if (key === 'role_id') {
      let { role } = this.props.user.getData();
      role = role.filter(r => r.id === tmpV);
      this.props.user.changeUserDetail('type', role[0].role_code);
      if (role[0].role_code === 'channel_user' || role[0].role_code === 'channel_admin') {
        this.props.user.editable_channel = true;
      } else {
        this.props.user.editable_channel = false;
      }
      this.state.channel_id = null;
      this.props.user.viewuser.channel_ids = [];

      if (role[0].role_code.indexOf('channel') === -1) {
        // this.props.user.changeConfig('channel_id', 0);
        this.props.user.viewuser.channel_id = 0;
      } else {
        if (!this.props.user.viewuser['channel_ids']?.length) {
          this.props.user.nonSelectChannel = false;
        } else {
          this.props.user.nonSelectChannel = true;
        }
      }
      let { users, user } = this.props.user.getData();
      if (user.channel_id === null) {
        user.channel_id = this.props.user.viewuser['channel_id'];
      }
    }
    if (key === 'channel_ids') {
      this.props.user.viewuser.channel_id = value[0];
      this.props.user.getChannelByIds(tmpV);
    }
  }

  editConfig() {
    this.props.user.editable = !this.props.user.editable;
    if (this.props.user.viewuser.type === 'channel_user' || this.props.user.viewuser.type === 'channel_admin') {
      this.props.user.editable_channel = !this.props.user.editable_channel;
    }
  }

  validateInputs(key = null, value = null) {
    const fieldsToCheck = key ? [key] : ['name', 'surname', 'role_id', 'channel_ids', 'tel', 'email', 'status'];
    const errors = this.state.errors;
    fieldsToCheck.forEach(field => (errors[field] = null));
    const { viewuser, role } = this.props.user.getData();

    // Validate inputs
    let channelGroup = role.filter(el => el.role_group === 'Channel').map(el => el.id);

    const requiredErrorMsgMapper = {
      name: 'กรุณากรอก First Name',
      surname: 'กรุณากรอก Last Name',
      role_id: 'กรุณาเลือก Role',
      status: 'กรุณาเลือก Status',
      channel_ids: 'กรุณาเลือก Channel',
      tel: 'กรุณากรอก Phone Number',
      email: 'กรุณากรอก E-Mail',
    };

    fieldsToCheck.forEach(field => {
      const data = key ? value : viewuser[field];
      const defaultValidator = [undefined, null, '', 0].includes(data);
      switch (field) {
        // case 'channel_id': {
        //   let channelValidator = true;
        //   if (!channelGroup.includes(viewuser.role_id)) channelValidator = false;
        //   if (channelValidator && defaultValidator) errors[field] = requiredErrorMsgMapper[field];
        //   break;
        // }
        case 'channel_ids': {
          let channelValidator = true;
          if (!channelGroup.includes(viewuser.role_id)) channelValidator = false;
          if (channelValidator && !data?.length) errors[field] = requiredErrorMsgMapper[field];
          break;
        }
        case 'tel': {
          if (defaultValidator) {
            errors[field] = requiredErrorMsgMapper[field];
            break;
          }
          if (data[0] !== '0') {
            errors[field] = 'หมายเลขโทรศัพท์ต้องขึ้นต้นด้วย 0';
            break;
          }
          if (data.length < 9) {
            errors[field] = 'หมายเลขโทรศัพท์ต้องมีความยาวอย่างน้อย 9 หลัก';
            break;
          }
          if ((data.length === 9 && data[1] !== '2') || (data.length === 10 && data[1] === '2') || data[1] === '0') {
            errors[field] = 'รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง';
            break;
          }
          break;
        }
        case 'email': {
          if (defaultValidator) {
            errors[field] = requiredErrorMsgMapper[field];
            break;
          }
          if (!validation.email(data)) {
            errors[field] = 'รูปแบบของ E-mail ไม่ถูกต้อง';
            break;
          }
          break;
        }
        default:
          if (defaultValidator) errors[field] = requiredErrorMsgMapper[field];
      }
    });
    this.setState({ errors });
  }

  async saveConfig() {
    // Validate inputs
    this.validateInputs();
    // validate if email is already exist
    if (!(await this.props.user.checkUser('edit'))) {
      const errors = this.state.errors;
      errors.email = 'อีเมลนี้ถูกใช้แล้ว';
      this.setState({ errors });
    }
    // Stop if the error exist
    if (
      Object.values(this.state.errors)
        .map(val => typeof val)
        .includes('string')
    )
      return;

    const { viewuser, viewuser_keep, role } = this.props.user.getData();
    const isRoleChanged = viewuser_keep.role_id !== viewuser.role_id;
    let confirmUpdate = true;
    if (isRoleChanged) {
      const roleName = {};
      role.forEach(obj => (roleName[obj.id] = obj.role_name));
      const result = await swal.fire({
        customClass: 'nexter-alert nexter-alert-user-manage',
        text: `Change role from ${roleName[viewuser_keep.role_id]} to ${roleName[viewuser.role_id]}?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK',
      });
      confirmUpdate = !Object.keys(result).includes('dismiss');
    }
    if (confirmUpdate) {
      await this.props.user.updateUserProfile();
      this.props.user.editable_channel = false;
      this.props.user.editable = false;
    }
  }

  saveUserProfile() {
    this.setState({ mode: 'read' }, () => {
      this.props.user.saveUserProfile();
    });
  }

  getPreValue(value, options, field) {
    let result = null;
    options.forEach(obj => {
      if (Object.keys(obj).includes('options')) {
        obj.options.forEach(subObj => {
          if (subObj[field] === value) result = subObj;
        });
      } else {
        if (obj[field] === value) result = obj;
      }
    });
    return result;
  }

  onCancel() {
    this.setState({ errors: {} });
    this.props.user.resetViewuser();
    // window.location.reload(false);
    this.props.user.editable = false;
    this.props.user.editable_channel = false;
  }

  goBack() {
    this.props.user.clearFilter();
    this.props.user.setSelectedUser(null);
    this.props.user.changeUserMgmtView('list');
  }

  render() {
    let { viewuser, role, channelss, channeldetails, users, loggedinUser } = this.props.user.getData();
    let roleBoxP8G = [];
    let roleBoxChannelG = [];
    role.forEach(el => {
      if (el.role_group === 'P8 Team') {
        roleBoxP8G.push({
          value: el.id,
          label: el.role_name.replace('_', ' '),
        });
      }
      if (el.role_group === 'Channel') {
        roleBoxChannelG.push({
          value: el.id,
          label: el.role_name.replace('_', ' '),
        });
      }
    });

    let groupedOptions = [
      {
        label: 'P8 Team',
        options: roleBoxP8G,
      },
      {
        label: 'Channel',
        options: roleBoxChannelG,
      },
    ];

    let channelBox = [];
    channelss
      .filter(b => b.status === 'approved')
      .forEach(el => {
        channelBox.push({
          value: el.id,
          label: el.name,
        });
      });

    let channelMemberCount = users.list.filter(a => a.channel_id === viewuser.channel_id && a.role.role_name === 'Channel User' && a.status === 'active').length;
    let channelAdmin = users.list.filter(a => a.channel_id === viewuser.channel_id && a.role.role_name === 'Channel Admin');
    let roleDetail = role.filter(a => a.id === viewuser.role_id);

    let statusOptions = [
      {
        value: 'active',
        label: 'Active',
      },
      {
        value: 'inactive',
        label: 'Inactive',
      },
    ];

    const preValueData = [
      { stateKey: 'role_id', valueKey: 'role_id', options: groupedOptions, fieldKey: 'value' },
      { stateKey: 'channel_id', valueKey: 'channel_name', options: channelBox, fieldKey: 'label' },
      { stateKey: 'status', valueKey: 'status', options: statusOptions, fieldKey: 'value' },
    ];

    preValueData.forEach(obj => {
      if (this.state[obj.stateKey] == null && !this.state.setValue[obj.stateKey]) {
        this.state[obj.stateKey] = this.getPreValue(viewuser[obj.valueKey], obj.options, obj.fieldKey);
        if (this.state[obj.stateKey] != null) this.state.setValue[obj.stateKey] = true;
      }
    });

    return (
      <Page>
        <CardHeader>
          <Row>
            <Col>
              <div className="title-goback">
                <a onClick={this.goBack.bind(this)}>
                  <i className="back-icon fas fa-chevron-left my-auto mr-4" />
                </a>
                <span className="texthead-managementpage">USER INFO</span>
              </div>
            </Col>
            <Visibility permission_code="user:view">
              <Col className="text-right p-0">
                <Col>
                  {this.props.user.editable ? (
                    <>
                      <NexterButton className="mr-3 my-0 nexter-default-outline" size="sm" color="default" onClick={() => this.onCancel()} outline>
                        CANCEL
                      </NexterButton>
                      <NexterButton className="mr-0 my-0" size="sm" icon="fa fa-save" color="success" onClick={() => this.saveConfig()} outline>
                        SAVE
                      </NexterButton>
                    </>
                  ) : (
                    <Visibility permission_code="user:update">
                      <NexterButton className="mr-0 my-0" size="sm" icon="fa fa-pencil-alt" onClick={() => this.editConfig()}>
                        EDIT
                      </NexterButton>
                    </Visibility>
                  )}
                </Col>
              </Col>
            </Visibility>
          </Row>
        </CardHeader>
        <hr className="mt-0 mb-4" />
        <Visibility permission_code="user:view">
          {viewuser.role_id && (
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup className="required">
                    <Label className="control-label text-user-info-h3">First Name</Label>
                    <NexterInput
                      value={viewuser.name}
                      onChange={this.changeConfig.bind(this, 'name')}
                      disabled={!this.props.user.editable}
                      invalid={!['', null, undefined].includes(this.state.errors.name)}
                    />
                    <FormFeedback>{this.state.errors.name}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="required">
                    <Label className="control-label text-user-info-h3">Last Name</Label>
                    <NexterInput
                      value={viewuser.surname}
                      onChange={this.changeConfig.bind(this, 'surname')}
                      disabled={!this.props.user.editable}
                      invalid={!['', null, undefined].includes(this.state.errors.surname)}
                    />
                    <FormFeedback>{this.state.errors.surname}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup className="required">
                    <Label className="control-label text-user-info-h3">เลือก Role</Label>
                    <NexterInput
                      type="select"
                      placeholder="เลือก Role..."
                      value={this.state.role_id}
                      options={groupedOptions}
                      onChange={this.changeConfig.bind(this, 'role_id')}
                      disabled={!this.props.user.editable || loggedinUser.type === 'channel_admin' || loggedinUser.type === 'channel_user'}
                      invalid={!['', null, undefined].includes(this.state.errors.role_id)}
                    />
                    <FormFeedback>{this.state.errors.role_id}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="required">
                    <Label
                      className={!groupedOptions[0].options.map(obj => obj.label).includes(this.state.role_id?.label) ? 'control-label text-user-info-h3' : 'control-label text-user-info-h3-disable'}
                    >
                      เลือก Channel
                    </Label>
                    <MultiSelect
                      placeholder="เลือก CHANNEL"
                      options={channelBox}
                      value={viewuser.channel_ids.map(String)}
                      onChange={this.changeConfig.bind(this, 'channel_ids')}
                      disabled={!this.props.user.editable_channel || loggedinUser.type === 'channel_admin' || loggedinUser.type === 'channel_user'}
                      className={!['', null, undefined].includes(this.state.errors.channel_ids) ? 'is-invalid' : ''}
                    />
                    <span className="invalid-feedback">{this.state.errors.channel_ids}</span>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup className="required">
                    <Label className="control-label text-user-info-h3">Phone Number</Label>
                    <NexterInput
                      allow="tel"
                      value={viewuser.tel}
                      onChange={this.changeConfig.bind(this, 'tel')}
                      disabled={!this.props.user.editable}
                      maxLength={10}
                      invalid={!['', null, undefined].includes(this.state.errors.tel)}
                    />
                    <FormFeedback>{this.state.errors.tel}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="required">
                    <Label className="control-label text-user-info-h3">E-Mail</Label>
                    <NexterInput
                      value={viewuser.email}
                      onChange={this.changeConfig.bind(this, 'email')}
                      disabled={!this.props.user.editable}
                      invalid={!['', null, undefined].includes(this.state.errors.email)}
                    />
                    <FormFeedback>{this.state.errors.email}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup className="required">
                    <Label className="control-label text-user-info-h3">เลือก Status</Label>
                    <NexterInput
                      type="select"
                      placeholder="เลือก Status..."
                      value={this.state.status}
                      options={statusOptions}
                      onChange={this.changeConfig.bind(this, 'status')}
                      disabled={!this.props.user.editable}
                      invalid={!['', null, undefined].includes(this.state.errors.status)}
                    />
                    <FormFeedback>{this.state.errors.status}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}></Col>
              </Row>
            </Form>
          )}

          <Row className="descript-user-info">
            <Col xs="2" className="text-user-info-h4">
              Updated By
            </Col>
            <Col xs="4" className="text-user-info-descript">
              {viewuser.updated_by}
            </Col>
            <Col xs="2" className="text-user-info-h4">
              Updated At
            </Col>
            <Col xs="4" className="text-user-info-descript">
              {moment(viewuser.updated_at).format('DD-MMM-YY HH:mm')}
            </Col>
          </Row>
          <Row className="descript-user-info">
            <Col xs="2" className="text-user-info-h4">
              Created By
            </Col>
            <Col xs="4" className="text-user-info-descript">
              {viewuser.created_by}
            </Col>
            <Col xs="2" className="text-user-info-h4">
              Created At
            </Col>
            <Col xs="4" className="text-user-info-descript">
              {moment(viewuser.created_at).format('DD-MMM-YY HH:mm')}
            </Col>
          </Row>
          <Row className="descript-user-info">
            <Col xs="2" className="text-user-info-h4">
              UserName
            </Col>
            <Col xs="4" className="text-user-info-descript">
              {viewuser.email || '-'}
            </Col>
            <Col xs="2"></Col>
            <Col xs="4"></Col>
          </Row>

          {roleDetail[0] && roleDetail[0].role_name.indexOf('Channel') > -1 && (
            <>
              <hr className="mt-4 mb-0" />
              <Row className="descript-user-info">
                <Col xs="2">
                  <h1 className="texthead-managementpage">Channel Info</h1>
                </Col>
              </Row>
              {channeldetails.map((channel, index) => (
                <Row key={channel.id} className="descript-user-info">
                  <Col xs="2" className="text-user-info-h4">
                    Channel Name {index + 1}
                  </Col>
                  <Col xs="4" className="text-user-info-descript">
                    {channel.name}
                  </Col>
                  <Col xs="2" className="text-user-info-h4">
                    Link Channel
                  </Col>
                  <Col xs="4" className="text-user-info-descript">
                    <Link to={`${config.web.rootpath}/channels/detail/${channel.uid}`} className="user-info-link">
                      {channel.uid}
                    </Link>
                  </Col>
                  <Col xs="2" className="text-user-info-h4">
                    Email
                  </Col>
                  <Col xs="4" className="text-user-info-descript">
                    {channel.channel_admin_email || '-'}
                  </Col>
                  <Col xs="12">
                    <hr className="mt-4 mb-0" />
                  </Col>
                </Row>
              ))}
            </>
          )}

          {roleDetail[0] && (
            <>
              <Row className="descript-user-info">
                <Col xs="2">
                  <h1 className="texthead-managementpage">Role info</h1>
                </Col>
              </Row>
              <Row className="descript-last-user-info">
                <Col xs="2">
                  <h6 className="text-user-info-h4 text-capitalize">{roleDetail[0].role_name.replace('_', ' ')}</h6>
                </Col>
                <Col>
                  <p className="text-user-info-descript">{roleDetail[0].role_description}</p>
                </Col>
              </Row>
            </>
          )}
        </Visibility>
        <Visibility permission_code="user:view" invisibility_msg>
          <h5>ท่านไม่มีสิทธิ์ดูรายละเอียดของ User</h5>
        </Visibility>
      </Page>
    );
  }
}

export default inject('user')(observer(AccessUserProfilePage));
