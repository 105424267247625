import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import numeral from 'numeral';
import { Table } from 'reactstrap';
import { EmptyRow } from '../../../components/Table/EmptyRow';
import { ensureJSONparse } from '../../../utils/string';
import { formatAutoTransferScheduleDates } from '../../../utils/date';
import { AUTO_TRANSFER_SCHEDULE_TYPES } from '../../../constants/transferConstants';

const FIELD = {
  status: 'Status',
  min_amount: 'ยอดขั้นต่ำ',
  schedule_type: 'ประเภทการโอน',
  schedule_day: 'วันในการโอน',
};

const t = field => {
  const translateField = _.get(FIELD, [field], field);
  return translateField;
};

const STATUS = {
  active: 'Active',
  inactive: 'Inactive',
};

const nullData = <span className="data-is-null">-</span>;

const AutoTransferHistoryLogTable = ({ logs = [] }) => {
  const formatValue = (key, oldValue, newValue) => {
    let ov = oldValue;
    let nv = newValue;
    switch (key) {
      case 'status':
        ov = STATUS[ov];
        nv = STATUS[nv];
        break;
      case 'schedule_type':
        ov = AUTO_TRANSFER_SCHEDULE_TYPES[ov];
        nv = AUTO_TRANSFER_SCHEDULE_TYPES[nv];
        break;
      case 'schedule_day':
        ov = !ov ? null : formatAutoTransferScheduleDates(ov.split(','));
        nv = !nv ? null : formatAutoTransferScheduleDates(nv.split(','));
        break;
      case 'min_amount':
        ov = isNaN(ov) || ov === null ? null : numeral(ov).format('0,0[.]00');
        nv = isNaN(nv) || nv === null ? null : numeral(nv).format('0,0[.]00');
        break;
      default:
        break;
    }
    return { oldValue: ov, newValue: nv };
  };

  let rows = logs.length ? (
    logs.map((item, index) => {
      const oldValue = ensureJSONparse(item.old_value);
      const newValue = ensureJSONparse(item.new_value);
      const actionName = _.get(item, 'action_name', '');
      let list = newValue !== null && !_.isEmpty(newValue) ? newValue : oldValue;

      let result = Object.entries(list)
        .filter(([key, value], i) => {
          let ov = _.get(oldValue, key);
          let nv = _.get(newValue, key);
          return (!!ov || !!nv) && ov !== nv;
        })
        .map(([key, value], i) => {
          const field = t(key);
          const gateway = _.get(oldValue, 'gateway');
          let ov = _.get(oldValue, key);
          let nv = _.get(newValue, key);
          let values = formatValue(key, ov, nv);
          ov = values.oldValue;
          nv = values.newValue;
          return (
            <tr key={`item-${item.id}-${i}`}>
              <td width="142">{moment(_.get(item, 'updated_at', '-')).format('DD-MM-YYYY HH:mm')}</td>
              <td width="152">{item.updated_by}</td>
              <td width="150">{_.get(item.channel, 'name', '-')}</td>
              <td width="80" className="text-uppercase">
                {gateway}
              </td>
              <td width="80">
                <div className={`action-wrapper ${actionName}`}>{actionName}</div>
              </td>
              <td width="150">{field}</td>
              <td width="150">{ov || nullData}</td>
              <td width="150">{nv || nullData}</td>
            </tr>
          );
        });
      return result;
    })
  ) : (
    <EmptyRow colSpan={8} />
  );

  return (
    <Table responsive>
      <thead>
        <tr>
          <th className="border-0">วันที่แก้ไข</th>
          <th className="border-0">แก้ไขโดย</th>
          <th className="border-0 text-capitalize">Channel Name</th>
          <th className="border-0 text-capitalize">Payment Gateway</th>
          <th className="border-0">กิจกรรม</th>
          <th className="border-0">ฟิลด์ที่แก้ไข</th>
          <th className="border-0">ข้อมูลเดิม</th>
          <th className="border-0">ข้อมูลที่แก้ไข</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
};

export default AutoTransferHistoryLogTable;
