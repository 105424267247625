import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types'

class Visibility extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isVisibility: false,
			showInvisibilityMsg: false
		}
	}
	componentWillReceiveProps(nextProps, nextContext) {
		this.setUpVisibility(nextProps)
	}
	componentDidMount() {
		this.setUpVisibility(this.props)
	}
	setUpVisibility(props) {
		const { isVisibility, role_code, permission_code, role_list, invisibility_msg } = props
		const { user_rp, user_info } = this.props.user.getData()
		let is_visibility = isVisibility
		if (role_code) {
			if (user_info.role && user_info.role.role_code === role_code) {
				is_visibility = true
			}
		}
		if (permission_code) {
			if (user_rp.includes(permission_code)) {
				is_visibility = true
			}
		}
		if (role_list) {
			if (user_info.role && user_info.role.role_code && role_list.includes(user_info.role.role_code)) {
				is_visibility = true
			}
		}
		this.setState({
			isVisibility: is_visibility,
			showInvisibilityMsg: invisibility_msg
		})
	}
	render() {
		return (
			<>
				{this.state.isVisibility && !this.state.showInvisibilityMsg ? this.props.children : null}
				{!this.state.isVisibility && this.state.showInvisibilityMsg ? this.props.children : null}
			</>
		);
	}
}
Visibility.propTypes = {
	isVisibility: PropTypes.bool,
	role_code: PropTypes.string,
	permission_code: PropTypes.string,
	role_list: PropTypes.array,
	permission_list: PropTypes.array,
	invisibility_msg: PropTypes.bool
}

export default inject('role', 'user')(observer(Visibility));
