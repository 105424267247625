import React from 'react'

export function NexterLabelHeader({children}) {
	return (
		<>
			<h1 className="label-header">{children}</h1>
		</>
	)
}
export function NexterLabelSpan({children}) {
	return (
		<>
			<span className="label-header">{children}</span>
		</>
	)
}

