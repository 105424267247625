export const DELETE_SELLER_STATUS = {
  requested: 'Pending for Delete',
  approved: 'Approving for Delete',
  rejected: 'Delete Rejected',
};

export const OPN_REJECT_REASON_ACCOUNT = {
  name_mismatch: 'ชื่อบัญชีไม่ถูกต้อง',
  account_not_found: 'เลขบัญชีไม่ถูกต้อง',
  bank_not_found: 'ไม่พบบัญชีของท่าน',
};

export const OPN_WAITING_RESUBMIT_DAY = 2;
