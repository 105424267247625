import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { Row, Col, FormGroup, Label, Input, Button, CustomInput } from 'reactstrap';
import NexterInput from '../../components/Input/NexterInput';
import FileUpload from '../../components/File/FileUpload';
import Number from '../../components/Forms/Number';
import FilePicker from '../../components/Forms/FilePicker';
import SellerShareholder from './SellerShareholder';
import { basename } from '../../utils/string';

class SellerRegisterKYCCompany extends Component {
  changeCompanyFile(key, files) {
    if (files && files.length) {
      if (files.length > 1) files.shift();
      this.props.sellerRegister.changeCompanyFile(files[0], key);
    }
  }

  changeCompanyInput(key, event) {
    this.props.sellerRegister.changeCompanyInput(event.target.value, key);
  }

  changeKYCShareholder(i, key, event) {
    this.props.sellerRegister.changeKYCShareholder(event.target.value, key, i);
  }

  changeKYCShareholderType(selected, i, key) {
    this.props.sellerRegister.changeKYCShareholder(selected, key, i);
  }

  changeKYCShareholderCommittee(i, j, key, event) {
    this.props.sellerRegister.changeKYCShareholderCommittee(event.target.value, key, i, j);
  }

  addKYCShareholder() {
    this.props.sellerRegister.addKYCShareholder();
  }

  addKYCShareholderCommittee(i) {
    this.props.sellerRegister.addKYCShareholderCommittee(i);
  }

  removeKYCShareholder(i) {
    this.props.sellerRegister.removeKYCShareholder(i);
  }

  removeKYCShareholderCommittee(i, j) {
    this.props.sellerRegister.removeKYCShareholderCommittee(i, j);
  }

  handleChange(name, value) {
    if (this.props.handleChange) 
      this.props.handleChange('kycCompany', name, value)
  }

  handleKYCFile(name, files) {
    this.props.handleKYCFile
      ? this.props.handleKYCFile('kycCompany', name, files)
      : this.changeCompanyFile(name, files);
  }

  removeKYCFile(name) {
    if (this.props.removeKYCFile)
      this.props.removeKYCFile('kycCompany', name)
  }

  getFileName(filename) {
    return filename?.name || basename(filename);
  }

  render() {
    const { seller, kycCompanyUpload, taxId: storeTaxId } = this.props.sellerRegister.getData();
    const colStyle = { size: 9, offset: 1 };
    const data = _.get(seller, 'kyc_company', {});
    const form = this.props.form || data;
    const upload = this.props.form || kycCompanyUpload;
    const taxId = this.props.taxId || storeTaxId;
    const showInfo = this.props.showInfo !== false;

    return (
      <Fragment>
        {showInfo && (
          <div className="seller-notice-alert">
            <i className="fas fa-exclamation-circle" /> ข้อมูลส่วนนี้ สามารถเพิ่มได้ในภายหลัง
          </div>
        )}
        <Row className="my-3">
          <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">เลขประจำตัวผู้เสียภาษีอากร</Label>
              <NexterInput
                allow="numeric"
                size="sm"
                value={taxId}
                disabled={true}
                className="nexter-input-sm"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">แบบรายการแสดงตน KYC</Label>
              <FileUpload
                previewSrc={this.getFileName(upload.identification)}
                onRemove={() => this.removeKYCFile('identification')}
                onChange={(files) => this.handleKYCFile('identification', files)}
                inputProps={{
                  multiple: false,
                  accept: 'application/pdf, image/png, image/jpeg',
                }}
                dropZoneProps={{
                  maxSize: 5242880,
                }}
                titleButton="UPLOAD"
                titlePre="รองรับเฉพาะไฟล์ .pdf, .jpg, .png"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">ใบทะเบียนภาษีมูลค่าเพิ่ม (ภพ. 20)</Label>
              <FileUpload
                previewSrc={this.getFileName(upload.vat20)}
                onRemove={() => this.removeKYCFile('vat20')}
                onChange={(files) => this.handleKYCFile('vat20', files)}
                inputProps={{
                  multiple: false,
                  accept: 'application/pdf, image/png, image/jpeg',
                }}
                dropZoneProps={{
                  maxSize: 5242880,
                }}
                titleButton="UPLOAD"
                titlePre="รองรับเฉพาะไฟล์ .pdf, .jpg, .png"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">หนังสือรับรองการจดทะเบียนบริษัท</Label>
              <FileUpload
                previewSrc={this.getFileName(upload.certificate)}
                onRemove={() => this.removeKYCFile('certificate')}
                onChange={(files) => this.handleKYCFile('certificate', files)}
                inputProps={{
                  multiple: false,
                  accept: 'application/pdf, image/png, image/jpeg',
                }}
                dropZoneProps={{
                  maxSize: 5242880,
                }}
                titleButton="UPLOAD"
                titlePre="รองรับเฉพาะไฟล์ .pdf, .jpg, .png"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">บัญชีรายชื่อผู้ถือหุ้น (บอจ. 5)</Label>
              <FileUpload
                previewSrc={this.getFileName(upload.boj5)}
                onRemove={() => this.removeKYCFile('boj5')}
                onChange={(files) => this.handleKYCFile('boj5', files)}
                inputProps={{
                  multiple: false,
                  accept: 'application/pdf, image/png, image/jpeg',
                }}
                dropZoneProps={{
                  maxSize: 5242880,
                }}
                titleButton="UPLOAD"
                titlePre="รองรับเฉพาะไฟล์ .pdf, .jpg, .png"
              />
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <h6><b>ผู้ได้รับมอบอำนาจทอดสุดท้าย</b></h6>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">ชื่อ - นามสกุล</Label>
              <NexterInput
                size="sm"
                name="beneficial_owner_name"
                value={form.beneficial_owner_name}
                onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                className="nexter-input-sm"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">เลขประจำตัวประชาชน</Label>
              <NexterInput
                allow="numeric"
                size="sm"
                name="beneficial_owner_personal_id"
                value={form.beneficial_owner_personal_id}
                maxLength={13}
                onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                className="nexter-input-sm"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">สำเนาบัตรประชาชน พร้อมรับรอง</Label>
              <FileUpload
                previewSrc={this.getFileName(upload.beneficial_owner)}
                onRemove={() => this.removeKYCFile('beneficial_owner')}
                onChange={(files) => this.handleKYCFile('beneficial_owner', files)}
                inputProps={{
                  multiple: false,
                  accept: 'application/pdf, image/png, image/jpeg',
                }}
                dropZoneProps={{
                  maxSize: 5242880,
                }}
                titleButton="UPLOAD"
                titlePre="รองรับเฉพาะไฟล์ .pdf, .jpg, .png"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">เอกสารมอบอำนาจ (ถ้ามี)</Label>
              <FileUpload
                previewSrc={this.getFileName(upload.beneficial_owner_authorization)}
                onRemove={() => this.removeKYCFile('beneficial_owner_authorization')}
                onChange={(files) => this.handleKYCFile('beneficial_owner_authorization', files)}
                inputProps={{
                  multiple: false,
                  accept: 'application/pdf, image/png, image/jpeg',
                }}
                dropZoneProps={{
                  maxSize: 5242880,
                }}
                titleButton="UPLOAD"
                titlePre="รองรับเฉพาะไฟล์ .pdf, .jpg, .png"
              />
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <h6><b>เอกสารอื่นๆ</b></h6>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="control-label form-label">เอกสารที่ไม่สามารถจำแนกตามหัวข้อข้างต้นได้</Label>
              <FileUpload
                previewSrc={this.getFileName(upload.other)}
                onRemove={() => this.removeKYCFile('other')}
                onChange={(files) => this.handleKYCFile('other', files)}
                inputProps={{
                  multiple: false,
                  accept: 'application/pdf, image/png, image/jpeg',
                }}
                dropZoneProps={{
                  maxSize: 5242880,
                }}
                titleButton="UPLOAD"
                titlePre="รองรับเฉพาะไฟล์ .pdf, .jpg, .png"
              />
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <h6><b>ข้อมูลผู้ถือหุ้น (ถือหุ้น 25% ขึ้นไป)</b></h6>
        <SellerShareholder
          items={form.kyc_shareholders}
          onChange={(...params) => this.props.handleChange('kycCompany', ...params)}
          onAction={this.props.onShareholderAction}
        />
      </Fragment>
    );
  }
}

export default inject('sellerRegister')(observer(SellerRegisterKYCCompany));
