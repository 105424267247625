import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import swal from 'sweetalert2';
import _ from 'lodash';
import { Card, CardBody, Row, Col, FormGroup, FormText, FormFeedback, Label, CustomInput, TabContent, TabPane, Nav, NavItem, NavLink, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Page } from '../../components/Page';
import { config } from '../../config';
import { http } from '../../utils/http';
import Visibility from '../../components/Visibility/Visibility';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';
import ContractPersonList from '../../components/ContractPerson/ContractPersonList';
import ApplicationFile from '../../components/File/ApplicationFile';
import LogoFile from '../../components/File/LogoFile';
import { CheckBox } from '../../components/Input/CheckBox';
import { validation } from '../../utils/validation';

import BAY from '../../assets/img/bay.png';
import BBL from '../../assets/img/bbl.jpg';
import FCC from '../../assets/img/fcc.jpg';
import KBANK from '../../assets/img/kbank.png';
import KTC from '../../assets/img/ktc.jpg';
import SCB from '../../assets/img/scb.jpg';
import UOB from '../../assets/img/uob.jpeg';
import TBANK from '../../assets/img/tbank.jpg';
import TTB from '../../assets/img/ttb.png';
const BANKS = { BAY, BBL, FCC, KBANK, KTC, SCB, UOB, TBANK, TTB };
const MAX_LENGTH = 200;
const INITIAL_INVALID_MESSAGE = {
  name: '',
  company_name: '',
  ebill_account: '',
  company_tax_id: '',
  app_file: '',
  ccpp_account: '',
  opn_account: '',
  fee_valid: '',
};

function basename(path) {
  if (path) {
    path = path.split('?')[0];
    path = path.split('/').reverse()[0];
    return path;
  }
  return '';
}

class CreateChannelPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // channel root state
      user_type: '',
      loading: false,
      activeTab: 'tab-channel-info',
      mode: 'create',
      has_changed: false,

      // exist channel
      channel_uid: '',
      channel_name: '',
      channel_status: '', // created, pending, approved, rejected
      state_before_edit: null,
      reject_reason: '',

      // inputs
      status: 'active',
      payment_email: 'uncheck',
      name: '',
      company_name: '',
      ebill_account: '',
      bill_type_options: [
        { label: 'SCG SAP', value: 1 },
        { label: 'SCG Non-SAP', value: 2 },
        { label: 'Non SCG', value: 3 },
      ],
      company_tax_id: '',
      logo_file: '',
      logo_url: '',
      app_file: '',
      app_name: '',
      app_url: '',
      ccpp_account: '',
      temp_ccpp_account: '', // first data from api
      opn_account: '',
      temp_opn_account: '', // first data from api
      contract_persons: [],
      contract_type_options: [
        { label: 'BUSINESS', value: 'BA' },
        { label: 'TECH', value: 'IT' },
      ],
      callback_path: '',
      webhook_path_payment: '',
      webhook_path_transfer: '',
      url_test: '',
      url_prod: '',
      payment_methods: [],
      system_payment_methods: [],
      installment_interests: [],
      system_installment_interests: [],
      qrcode_invalid: false,
      qrcode_invalid_message: '',
      tax_suffix: '',

      // validation
      all_channel_name_without_itself: [],
      invalid_message: _.cloneDeep(INITIAL_INVALID_MESSAGE),

      // channel permission
      canUserTypeViewChannel: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleContractTypeChange = this.handleContractTypeChange.bind(this);
    this.hasPermissionCode = this.hasPermissionCode.bind(this);
  }

  async componentDidMount() {
    let { loggedinUser } = this.props.user.getData();
    // loggedinUser.type = 'biz_system';
    const uid = this.props.match.params.uid;
    // NL-1679
    if (uid) {
      const canUserTypeViewChannel = this.canUserTypeViewChannel(uid);
      this.setState({ canUserTypeViewChannel });
      if (!canUserTypeViewChannel) {
        return;
      }
    }

    // set default value
    this.setState({ loading: true });
    this.addChannelContract();

    await Promise.all[(this.props.channel.fetchMIDChannel(), this.props.channel.fetchMIDOpnChannel())];
    await this.fetchChannelPaymentMethod();
    await this.fetchInstallmentInterests();
    await this.fetchAllChannelName();

    // for channel detail page
    if (uid) {
      await this.fetchAllChannelDetail(uid);
    }
    this.setState({ loading: false, user_type: loggedinUser.type });
  }

  toggle(tab) {
    const { activeTab } = this.state;
    const isCreate = !Boolean(this.props.match?.params?.uid);
    if (activeTab !== tab)
      this.setState({ activeTab: tab }, () => {
        if (!isCreate) this.cancelEdit();
      });
  }

  async fetchAllChannelDetail(uid) {
    this.setState({ loading: true });
    await this.fetchChannelDetail(uid);
    await this.fetchChannelInstallment(uid);
    this.setState({ loading: false });
  }

  async fetchChannelPaymentMethod() {
    try {
      // this.loading = true;
      let url = `${config.npay.apiUrl}/admin/paymentmethods`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        let payment_methods = body.data;

        // System config active payment methods
        payment_methods = payment_methods.filter(item => item.status === 'active');
        payment_methods.sort((a, b) => a.sequence - b.sequence);
        // initial default value for channel when creating
        payment_methods = payment_methods.map(pm => {
          return {
            ...pm,
            option: !['qr_box', 'chang_family_wallet'].includes(pm.code),
            percent_fee: pm.default_percent_fee,
            fixed_cost_fee: pm.default_fixed_cost_fee,
            fee_type: pm.default_fee_type,
          };
        });

        this.setState({
          payment_methods,
          system_payment_methods: _.cloneDeep(payment_methods),
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        // this.loading = false;
      }, 100);
    }
  }

  async fetchInstallmentInterests() {
    try {
      // Get Default System Installment Interest
      let url = `${config.npay.apiUrl}/admin/installmentinterests`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        let installment_interests = body.data;
        // Cost - Gateway MDR
        // Default Cost - Channel MDR
        installment_interests = installment_interests.map(item => ({ ...item, cost: item.default_cost }));
        this.setState({
          installment_interests,
          system_installment_interests: _.cloneDeep(installment_interests),
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  async fetchAllChannelName() {
    try {
      let url = `${config.npay.apiUrl}/admin/channelsname/all`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.setState({ all_channel_name_without_itself: body.data });
      }
    } catch (err) {
      console.error(err);
    }
  }

  async fetchChannelDetail(uid) {
    try {
      // prevent file "change" status is invalid while edit many times.
      this.resetFileState();
      let url = `${config.npay.apiUrl}/admin/channels/${uid}`;
      let response = await http.get(url);
      if (response.status === 200) {
        const { channels_mid_options, channels_mid_opn_options } = this.props.channel.getData();

        let body = await response.json();
        let data = body.data;

        let payment_methods = data.channel_payment_methods.map(item => {
          return { ...item, percent_valid: '', cost_valid: '' };
        });

        this.setState(
          state => {
            return {
              mode: 'view',
              ..._.pick(data, ['name', 'company_name', 'company_tax_id', 'logo_url', 'callback_path', 'url_test', 'url_prod', 'reject_reason']),
              channel_uid: data.uid,
              channel_name: data.name,
              channel_status: data.status,
              webhook_path_payment: data.webhook_path,
              webhook_path_transfer: data.webhook_path_transfer,
              status: data.is_active ? 'active' : 'inactive',
              payment_email: data.payment_success_email ? 'checked' : 'uncheck',
              ebill_account: state.bill_type_options.find(option => option.value === data.ebill_account),
              temp_ccpp_account: channels_mid_options.find(option => option.value === data.ccpp_account),
              ccpp_account: channels_mid_options.find(option => option.value === data.ccpp_account),
              temp_opn_account: channels_mid_opn_options.find(option => option.value === data.opn_account),
              opn_account: channels_mid_opn_options.find(option => option.value === data.opn_account),
              app_name: basename(data.file_ref_url).split('-').reverse()[0],
              logo_file: '',
              app_url: data.file_ref_url,
              contract_persons: data.channel_contract
                .filter(item => !item.is_obsolete)
                .map(item => {
                  return {
                    ..._.pick(item, ['id', 'name', 'email', 'phone']),
                    type: state.contract_type_options.find(option => option.value === item.type),
                  };
                }),
              payment_methods: payment_methods,
              tax_suffix: data.channel_tax_suffix,
              // qrcode: data.channel_payment_methods.find(item => item.code === 'qr_box')['tax'] || '',
            };
          },
          () => {
            // prevent case old channel doesn't have any contract person.
            if (this.state.contract_persons.length === 0) this.addChannelContract();
          }
        );

        // console.log(this.state.payment_methods);
      } else {
        let body = await response.json();
        this.showErrorAlert(body?.message);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async fetchChannelInstallment(uid) {
    try {
      let url = `${config.npay.apiUrl}/admin/channels/${uid}/installments`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        let data = body.data;
        let installment_interests = this.state.installment_interests.map(system => {
          const local = data.find(e => e.installment_interest_id === system.id);
          if (local) system.cost = local.cost;
          system.invalid_message = '';
          return system;
        });
        // Fetch Channel Installment Interest
        this.setState({ installment_interests });
      }
    } catch (err) {
      console.error(err);
    }
  }

  approveChannel() {
    swal
      .fire({
        customClass: 'nexter-alert',
        type: 'warning',
        title: 'Are you sure you want to approve this request ?',
        text: 'หลังจาก Approve แล้ว Status จะเป็น Approved',
        showCancelButton: true,
        cancelButtonText: 'CANCEL',
        confirmButtonText: 'APPROVE',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        onOpen: () => swal.getConfirmButton().blur(),
        preConfirm: async () => {
          return await this.submitStatus('approved');
        },
        allowOutsideClick: () => !swal.isLoading(),
      })
      .then(result => {
        if (result.value) this.showSuccessAndRedirect('การอนุมัติสำเร็จ', true);
      });
  }

  rejectChannel() {
    swal
      .fire({
        customClass: 'nexter-alert',
        type: 'warning',
        title: 'Reason for rejection ?',
        input: 'textarea',
        inputPlaceholder: 'กรุณาระบุเหตุผลในการปฏิเสธอนุมัติ *',
        inputAttributes: {
          maxLength: 100,
          autocapitalize: 'off',
          autocorrect: 'off',
        },
        inputValidator: value => {
          if (!value) return 'กรุณาระบุเหตุผล';
        },
        showCancelButton: true,
        cancelButtonText: 'CANCEL',
        confirmButtonText: 'REJECT',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        onOpen: () => swal.getConfirmButton().blur(),
        preConfirm: async reject_reason => {
          return await this.submitStatus('rejected', reject_reason); // status, reject reason
        },
        allowOutsideClick: () => !swal.isLoading(),
      })
      .then(result => {
        if (result.value) this.showSuccessAndRedirect('ปฏิเสธการอนุมัติสำเร็จ', true);
      });
  }

  async saveChannel() {
    if (this.validate()) {
      swal
        .fire({
          customClass: 'nexter-alert',
          type: 'warning',
          title: 'ต้องการบันทึก และ Submit ภายหลัง?',
          text: 'หลังจาก Save แล้ว Status จะเป็น Created ระหว่างนี้คุณยังสามารถแก้ไขข้อมูลได้ จนกว่าจะกด Submit',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'บันทึก',
          showLoaderOnConfirm: true,
          reverseButtons: true,
          onOpen: () => swal.getConfirmButton().blur(),
          preConfirm: async () => {
            return await this.submitForm();
          },
          allowOutsideClick: () => !swal.isLoading(),
        })
        .then(result => {
          if (result.value) this.showSuccessAndRedirect();
        });
    } else {
      this.showErrorAlert('โปรดตรวจสอบข้อมูลอีกครั้ง');
    }
  }

  async submitChannel() {
    const { channel_status } = this.state;
    if (this.validate()) {
      let title = 'ต้องการบันทึกข้อมูลที่แก้ไข?';
      let text = '';
      if (channel_status !== 'approved') {
        title = 'ต้องการบันทึก และส่งให้ Biz&System ตรวจสอบ?';
        text = 'หลังจาก Submit แล้ว Status จะเป็น Pending ระหว่างนี้จะไม่สามารถแก้ไขข้อมูลได้ จนกว่าข้อมูลจะถูกตรวจสอบเสร็จสิ้น';
      }
      swal
        .fire({
          customClass: 'nexter-alert',
          type: 'warning',
          title,
          text,
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'ยืนยัน',
          showLoaderOnConfirm: true,
          reverseButtons: true,
          onOpen: () => swal.getConfirmButton().blur(),
          preConfirm: async () => {
            return await this.submitForm('submit');
          },
          allowOutsideClick: () => !swal.isLoading(),
        })
        .then(result => {
          if (result.value) this.showSuccessAndRedirect(null, true);
        });
    } else {
      this.showErrorAlert('โปรดตรวจสอบข้อมูลอีกครั้ง');
    }
  }

  async submitStatus(status, reject_reason = '') {
    const { channel_uid } = this.state;
    try {
      const url = `${config.npay.apiUrl}/admin/channels/${channel_uid}/status`;
      const response = await http.put(url, { body: { status, reject_reason } });
      const [response_1, json] = await Promise.all([response, response.json()]);
      if (!response_1.ok) {
        throw new Error(json.message);
      }
      return true;
    } catch (error) {
      swal.update({
        customClass: 'nexter-alert',
        type: 'warning',
        text: '',
        showConfirmButton: false,
        cancelButtonText: 'CONTINUE',
        confirmButtonColor: '#e20a1a',
      });
      swal.showValidationMessage(`${error}`.replace('Error: ', ''));
    }
  }

  async submitForm(formType = '') {
    const { channel_uid } = this.state;
    const formData = this.convertDataToForm(formType); // submit or null
    const transactionFeeBody = this.convertDataToForm('fee');
    const paymentBody = this.convertDataToForm('payment');

    if (!formData || !transactionFeeBody || !paymentBody) {
      this.showErrorAlert('โปรดตรวจสอบข้อมูลอีกครั้ง');
      return false;
    }

    // TODO: validate before send request;

    try {
      if (channel_uid !== '' && (this.state.mode === 'edit' || this.state.mode === 'view')) {
        console.log('edit or view');
        const url = `${config.npay.apiUrl}/admin/channels/${channel_uid}`;
        const url_fee = `${config.npay.apiUrl}/admin/channels/${channel_uid}/fee`;
        const url_payments = `${config.npay.apiUrl}/admin/channels/${channel_uid}/paymentoption`;
        const responses = await Promise.all([http.put(url, { body: formData }), http.put(url_fee, { body: transactionFeeBody })]);
        // prevent duplicate channel payment method
        responses.concat(await Promise.all([http.put(url_payments, { body: paymentBody })]));
        for (const response of responses) {
          const [response_1, json] = await Promise.all([response, response.json()]);
          if (!response_1.ok) throw new Error(json.message);
        }
      } else {
        console.log('other');
        const url = `${config.npay.apiUrl}/admin/channels`;
        const response = await http.post(url, { body: formData });
        const [response_1, json] = await Promise.all([response, response.json()]);
        if (!response_1.ok) {
          throw new Error(json.message);
        }
      }
      return true;
    } catch (error) {
      swal.update({
        customClass: 'nexter-alert',
        type: 'warning',
        text: '',
        showConfirmButton: false,
        cancelButtonText: 'CONTINUE',
        confirmButtonColor: '#e20a1a',
        reverseButtons: true,
      });
      swal.showValidationMessage(`${error}`.replace('Error: ', ''));
    }
  }

  validate() {
    const {
      channel_name,
      name,
      company_name,
      ebill_account,
      company_tax_id,
      app_file,
      app_name,
      ccpp_account,
      contract_persons,
      all_channel_name_without_itself,
      payment_methods,
      system_payment_methods,
      installment_interests,
      system_installment_interests,
    } = this.state;

    let invalid_message = _.cloneDeep(INITIAL_INVALID_MESSAGE);
    let pass = true;
    if (_.isEmpty(name) || name.length > MAX_LENGTH) {
      pass = false;
      invalid_message['name'] = 'กรุณาระบุ Channel Name';
    }
    if (all_channel_name_without_itself.some(cname => cname === name.toUpperCase() && name.toUpperCase() !== channel_name.toUpperCase())) {
      pass = false;
      invalid_message['name'] = 'This channel is already existed';
    }
    if (_.isEmpty(company_name) || company_name.length > MAX_LENGTH) {
      pass = false;
      invalid_message['company_name'] = 'กรุณาระบุ Company Name';
    }
    if (_.isEmpty(ebill_account)) {
      pass = false;
      invalid_message['ebill_account'] = 'กรุณาระบุ Billing Type';
    }
    if (_.isEmpty(company_tax_id) || isNaN(parseInt(company_tax_id, 10)) || company_tax_id.length != 13) {
      pass = false;
      invalid_message['company_tax_id'] = 'กรุณาระบุเลขประจำตัวผู้เสียภาษี 13 หลัก';
    }
    if (_.isEmpty(app_file) && _.isEmpty(app_name)) {
      pass = false;
      invalid_message['app_file'] = 'กรุณาอัปโหลด Application Form';
    }
    if (_.isEmpty(ccpp_account)) {
      pass = false;
      invalid_message['ccpp_account'] = 'กรุณาเลือก Payment Account';
    }
    const new_contract_persons = contract_persons.map(item => {
      let item_invalid_message = {
        name_invalid: '',
        email_invalid: '',
        phone_invalid: [],
        type_invalid: '',
      };
      if (_.isEmpty(item.name)) {
        pass = false;
        item_invalid_message['name_invalid'] = 'กรุณาระบุ ชื่อผู้ติดต่อ';
      }
      if (!validation.email(item.email)) {
        pass = false;
        item_invalid_message['email_invalid'] = 'กรุณาระบุ E-Mail ให้ถูกต้อง';
      }
      if (
        !validation.phone(item.phone) ||
        !_.startsWith(item.phone, '0') ||
        !(9 <= item.phone.length && item.phone.length <= 10) ||
        (item.phone.length === 9 && !_.startsWith(item.phone, '02')) ||
        (item.phone.length === 10 && _.startsWith(item.phone, '02')) ||
        _.startsWith(item.phone, '00')
      ) {
        pass = false;
        if (['', null].includes(item.phone)) {
          item_invalid_message['phone_invalid'].push('กรุณาระบุหมายเลขโทรศัพท์');
        } else {
          item_invalid_message['phone_invalid'] = [];
          if (!_.startsWith(item.phone, '0')) item_invalid_message['phone_invalid'].push('หมายเลขโทรศัพท์ต้องขึ้นต้นด้วย 0');
          if (!(9 <= item.phone.length && item.phone.length <= 10)) item_invalid_message['phone_invalid'].push('หมายเลขโทรศัพท์ต้องมีความยาว 9-10 หลัก');
          if ((item.phone.length === 9 && !_.startsWith(item.phone, '02')) || (item.phone.length === 10 && _.startsWith(item.phone, '02')) || _.startsWith(item.phone, '00'))
            item_invalid_message['phone_invalid'].push('รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง');
        }
      }
      if (_.isEmpty(item.type)) {
        pass = false;
        item_invalid_message['type_invalid'] = 'กรุณาระบุ ประเภทผู้ติดต่อ';
      }
      return { ...item, ...item_invalid_message };
    });

    // validation channel fee;
    let checked_payments = payment_methods.map(item => {
      const paramFilter = system_payment_methods.filter(it => it.code === item.code);
      const paramCheck = paramFilter[0];
      item.percent_valid = '';
      item.cost_valid = '';
      if (item.fee_type === 'percent' && item.percent_fee < paramCheck.default_percent_fee) {
        item.percent_valid = `กรุณาระบุข้อมูลให้ถูกต้อง ขั้นต่ำ ${paramCheck.default_percent_fee}%`;
        pass = false;
      } else if (item.fee_type === 'baht' && item.fixed_cost_fee < paramCheck.default_fixed_cost_fee) {
        item.cost_valid = `กรุณาระบุข้อมูลให้ถูกต้อง ขั้นต่ำ ${paramCheck.default_fixed_cost_fee} บาท`;
        pass = false;
      }
      return item;
    });

    // validation installment interest;
    let checked_interests = installment_interests.map(item => {
      const paramFilter = system_installment_interests.filter(it => it.abbr === item.abbr);
      const paramCheck = paramFilter[0];
      item.invalid_message = '';
      // validate by Channel MDR
      // Cost - Gateway MDR
      // Default Cost - Channel MDR
      if (item.status === 'active' && item.cost < paramCheck.default_cost) {
        item.invalid_message = `ห้ามต่ำกว่าค่า Channel MDR`;
        pass = false;
      }
      return item;
    });

    this.setState({ invalid_message, payment_method: checked_payments, installment_interests: checked_interests, contract_persons: new_contract_persons });
    return pass;
  }

  convertDataToForm(type = '') {
    let {
      channel_uid,
      channel_status,
      status,
      payment_email,
      name,
      company_name,
      ebill_account,
      company_tax_id,
      logo_file,
      logo_file_change,
      app_file,
      app_file_change,
      ccpp_account,
      opn_account,
      contract_persons,
      callback_path,
      webhook_path_payment,
      webhook_path_transfer,
      url_test,
      url_prod,
      payment_methods,
      installment_interests,
      tax_suffix,
      // qrcode,
    } = this.state;

    try {
      contract_persons = contract_persons.map(item => ({ ...item, type: item.type.value }));
      payment_methods = payment_methods.map(item => {
        return {
          ...item,
          percent_fee: parseFloat(item.percent_fee) || 0,
          fixed_cost_fee: parseFloat(item.fixed_cost_fee) || 0,
        };
      });
      installment_interests = installment_interests.map(item => ({
        ...item,
        cost: parseFloat(item.cost) || parseFloat(item.default_cost) || 0,
      }));

      let submit_status = '';
      if (type === 'submit') {
        submit_status = channel_status === 'approved' ? 'approved' : 'pending';
      }

      switch (type) {
        case 'fee':
          return { payment_methods, installment_interests };
        case 'payment':
          return { payment_methods, tax_suffix };
        default:
          const formData = new FormData();
          formData.append('channel_uid', channel_uid);
          formData.append('channel_status', channel_status);
          formData.append('is_active', status);
          formData.append('payment_email', payment_email);
          formData.append('name', name);
          formData.append('company_name', company_name);
          formData.append('ebill_account', ebill_account.value);
          formData.append('company_tax_id', company_tax_id);
          formData.append('logo_file', logo_file);
          formData.append('logo_file_change', logo_file_change);
          formData.append('app_file', app_file);
          formData.append('app_file_change', app_file_change);
          formData.append('ccpp_account', ccpp_account?.value);
          if (opn_account?.value) formData.append('opn_account', opn_account.value);
          formData.append('contract_persons', JSON.stringify(contract_persons));
          formData.append('callback_path', callback_path);
          formData.append('webhook_path', webhook_path_payment);
          formData.append('webhook_path_transfer', webhook_path_transfer);
          formData.append('url_test', url_test);
          formData.append('url_prod', url_prod);
          formData.append('payment_methods', JSON.stringify(payment_methods));
          formData.append('installment_interests', JSON.stringify(installment_interests));
          // formData.append('qrcode', qrcode);
          formData.append('tax_suffix', tax_suffix);
          formData.append('submit_status', submit_status);
          return formData;
      }
    } catch (_) {
      console.log(_);
      return false;
    }
  }

  async removeLogo() {
    const sa2Confirm = await swal.fire({
      customClass: 'nexter-alert',
      type: 'warning',
      title: 'Delete this item',
      text: 'Are you sure you want delete this item ?',
      showCancelButton: true,
      cancelButtonText: 'CANCEL',
      confirmButtonText: 'DELETE',
      reverseButtons: true,
      onOpen: () => swal.getConfirmButton().blur(),
    });

    if (sa2Confirm.value) {
      this.setState({
        logo_url: '',
        logo_file: '',
        logo_file_change: 'change',
        has_changed: true,
      });
    }
  }

  showSuccessAndRedirect(text = 'บันทึกข้อมูลสำเร็จ', forceRedirect = false) {
    const { channel_uid } = this.state;
    swal
      .fire({
        customClass: 'nexter-alert',
        type: 'success',
        title: 'Success!',
        text,
        confirmButtonText: 'CONTINUE',
      })
      .then(async () => {
        // redirect to channel list
        if (this.state.mode === 'create' || forceRedirect) {
          this.props.history.push(`${config.web.rootpath}/channels`);
        } else {
          await this.fetchAllChannelDetail(channel_uid);
        }
      });
  }

  showErrorAlert(text = '') {
    swal.fire({
      customClass: 'nexter-alert',
      type: 'error',
      title: 'Error!',
      text,
      confirmButtonText: 'ลองใหม่อีกครั้ง',
    });
  }

  edit() {
    this.setState({
      state_before_edit: _.cloneDeep(this.state),
      mode: 'edit',
      has_changed: false,
    });
  }

  cancelEdit() {
    this.setState({
      ..._.omit(this.state.state_before_edit, ['activeTab']), // prevent changing tab after cancel
      state_before_edit: null,
      mode: 'view',
    });
  }

  viewApplicationFile() {
    if (this.state.app_url) {
      window.open(this.state.app_url);
    }
  }

  /**
   * Prevent state "change" is invalid
   */
  resetFileState() {
    this.setState({
      logo_file: '',
      logo_file_change: '',
      app_file: '',
      app_file_change: '',
    });
  }

  addChannelContract() {
    const contract_persons = this.state.contract_persons;
    const person = {
      name: '',
      email: '',
      phone: '',
      type: '',
      name_invalid: '',
      email_invalid: '',
      phone_invalid: '',
      type_invalid: '',
    };
    contract_persons.push(person);
    this.setState({ contract_persons });
  }

  removeChannelContract(i) {
    const contract_persons = this.state.contract_persons;
    if (contract_persons.length > 1) {
      contract_persons.splice(i, 1);
    }
    this.setState({ contract_persons, has_changed: true });
  }

  resetInvalidMessage(field) {
    const invalid_message = this.state.invalid_message;
    invalid_message[field] = '';
    this.setState({ invalid_message });
  }

  handleChange(event) {
    this.resetInvalidMessage(event.target.name);
    this.setState({ [event.target.name]: event.target.value, has_changed: true });
  }

  handleBillTypeChange(ebill_account) {
    this.resetInvalidMessage('ebill_account');
    this.setState({ ebill_account, has_changed: true });
  }

  handleCCPPAccountChange(ccpp_account) {
    this.resetInvalidMessage('ccpp_account');
    this.setState({ ccpp_account, has_changed: true });
  }

  handleOpnAccountChange(opn_account) {
    this.resetInvalidMessage('opn_account');
    this.setState({ opn_account, has_changed: true });
  }

  handleContractChange(i, event) {
    const contract_persons = this.state.contract_persons;
    contract_persons[i][event.target.name] = event.target.value;
    contract_persons[i][`${event.target.name}_invalid`] = '';

    if (event.target.name === 'phone') {
      contract_persons[i][`${event.target.name}_invalid`] = [];
      if (
        !validation.phone(event.target.value) ||
        !_.startsWith(event.target.value, '0') ||
        !(9 <= event.target.value.length && event.target.value.length <= 10) ||
        (event.target.value.length === 9 && !_.startsWith(event.target.value, '02')) ||
        (event.target.value.length === 10 && _.startsWith(event.target.value, '02')) ||
        _.startsWith(event.target.value, '00')
      ) {
        if (['', null].includes(event.target.value)) {
          contract_persons[i][`${event.target.name}_invalid`].push('กรุณาระบุหมายเลขโทรศัพท์');
        } else {
          if (!_.startsWith(event.target.value, '0')) contract_persons[i][`${event.target.name}_invalid`].push('หมายเลขโทรศัพท์ต้องขึ้นต้นด้วย 0');
          if (!(9 <= event.target.value.length && event.target.value.length <= 10)) contract_persons[i][`${event.target.name}_invalid`].push('หมายเลขโทรศัพท์ต้องมีความยาว 9-10 หลัก');
          if (
            (_.startsWith(event.target.value, '02') && event.target.value.length !== 9) ||
            (!_.startsWith(event.target.value, '0') && event.target.value.length === 10) ||
            (_.startsWith(event.target.value, '0') && !_.startsWith(event.target.value, '02') && event.target.value.length < 10) ||
            _.startsWith(event.target.value, '00') ||
            (!_.startsWith(event.target.value, '0') && !(9 <= event.target.value.length && event.target.value.length <= 10))
          )
            contract_persons[i][`${event.target.name}_invalid`].push('รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง');
        }
      }
    }

    this.setState({ contract_persons, has_changed: true });
  }

  handleContractTypeChange(type_selected, i) {
    const contract_persons = this.state.contract_persons;
    contract_persons[i]['type'] = type_selected;
    contract_persons[i]['type_invalid'] = '';
    this.setState({ contract_persons, has_changed: true });
  }

  handleLogo(acceptedFiles) {
    if (acceptedFiles && acceptedFiles.length) {
      const file = acceptedFiles[0];
      const objectUrl = URL.createObjectURL(file);
      this.setState({
        logo_file: file,
        logo_url: objectUrl,
        logo_file_change: 'change',
        has_changed: true,
      });
    }
  }

  handleAppForm(acceptedFiles) {
    if (acceptedFiles && acceptedFiles.length) {
      const app_file = acceptedFiles[0];
      this.resetInvalidMessage('app_file');
      this.setState({
        app_file,
        app_name: app_file.name,
        app_file_change: 'change',
        has_changed: true,
      });
    }
  }

  changeFeeStatus(i, event) {
    let payment_methods = this.state.payment_methods;
    payment_methods[i]['fee_type'] = event.target.value;
    payment_methods[i]['percent_valid'] = '';
    payment_methods[i]['cost_valid'] = '';
    this.setState({ payment_methods, has_changed: true });
  }

  changeChannelFee(i, field, event) {
    if (isNaN(event.target.value)) {
      return;
    }
    const payment_methods = this.state.payment_methods;
    // const system_payment_methods = this.state.system_payment_methods;
    // const system_fee = parseFloat(system_payment_methods[i][field]) || 0;
    let rate = parseFloat(event.target.value) || 0;
    if (field === 'percent_fee' && rate > 100) rate = 100;
    // if (rate < system_fee) rate = system_fee;
    else rate = event.target.value;
    payment_methods[i][field] = rate;
    payment_methods[i]['percent_valid'] = '';
    payment_methods[i]['cost_valid'] = '';
    this.setState({ payment_methods, has_changed: true });
  }

  changeQrcode(e) {
    if (e.target.value.length === 15) {
      this.setState({
        tax_suffix: e.target.value,
        qrcode_invalid: false,
        qrcode_invalid_message: '',
      });
    } else if (e.target.value.length < 15) {
      this.setState({
        tax_suffix: e.target.value,
        qrcode_invalid: true,
        qrcode_invalid_message: 'กรุณาระบุ Tax ID+Suffix 15 หลัก',
      });
    }
    this.setState({ tax_suffix: e.target.value, has_changed: true });
  }

  changePaymentStatus = async (i, code, e) => {
    let checked = e.target.checked;
    if (code === 'qr_box' && checked) {
      // const tax = options?.tax;
      const tax_suffix = this.state.tax_suffix;
      if (!tax_suffix || tax_suffix.length !== 15) {
        this.setState({
          qrcode_invalid: true,
          qrcode_invalid_message: 'กรุณาระบุ Tax ID+Suffix 15 หลัก',
        });
        return;
      } else {
        // TODO: validate qrcode with BBL
        // checked = this.props.channel.checkBBLQrcodeStatus(tax_suffix);
        // if (!checked) {
        //   this.setState({
        //     qrcode_invalid: true,
        //     qrcode_invalid_message: 'Tax ID+Suffix ไม่ถูกต้อง',
        //   });
        //   return;
        // }

        this.setState({
          qrcode_invalid: false,
          qrcode_invalid_message: '',
        });
      }
    }

    const payment_methods = this.state.payment_methods;
    payment_methods[i]['option'] = checked;
    this.setState({ payment_methods, has_changed: true });
  };

  changeInstallmentCost(i, event) {
    const installment_interests = this.state.installment_interests;
    // const system_installment_interests = this.state.system_installment_interests;
    // const system_cost = parseFloat(system_installment_interests[i]['cost']) || 0;
    let rate = parseFloat(event.target.value) || 0;
    if (rate > 100) rate = 100;
    // else if (rate < system_cost) rate = system_cost;
    else rate = event.target.value;
    installment_interests[i]['cost'] = rate;
    this.setState({ installment_interests, has_changed: true });
  }

  showActionByCondition(activeTab) {
    const { user_rp } = this.props.user.getData();
    let { channel_status, mode } = this.state;
    let showApproved = false;
    let showPending = false;
    let showSaveBtn = false;
    let showSubmitBtn = false;
    let showEditBtn = false;
    let showCancelBtn = false;
    let showApproveBtn = false;
    let showRejectBtn = false;

    let isPermission = false;
    if (activeTab === 'tab-channel-info') {
      isPermission = ['channel:edit', 'channel:change_logo', 'channel:set_payment_successful_email', 'channel:inactivate'].some(role => user_rp.includes(role));
    } else if (activeTab === 'tab-transaction-fee') {
      isPermission = ['channel:set_channel_trans_fee', 'channel:set_channel_installment_price'].some(role => user_rp.includes(role));
    } else if (activeTab === 'tab-payment-option') {
      isPermission = ['channel:set_payment_method'].some(role => user_rp.includes(role));
    }

    switch (channel_status) {
      case 'approved':
        if (mode === 'edit') {
          // showSaveBtn = true;
          showSubmitBtn = true;
          showCancelBtn = true;
        } else {
          showApproved = true;
          showEditBtn = isPermission;
        }
        break;
      case 'rejected':
        if (mode === 'edit') {
          showSubmitBtn = true;
          showCancelBtn = true;
        } else {
          showEditBtn = isPermission;
        }
        break;
      case 'pending':
        if (this.hasPermissionCode('channel:approve_channel')) {
          showApproveBtn = true;
          showRejectBtn = true;
        } else {
          showPending = true;
        }
        break;
      default:
        showSubmitBtn = true;
        switch (mode) {
          case 'edit':
            // showSaveBtn = true;
            showCancelBtn = true;
            break;
          case 'view':
            showEditBtn = isPermission;
            break;
          default:
            showSaveBtn = true;
        }
    }
    return {
      showApproved,
      showPending,
      showSaveBtn,
      showSubmitBtn,
      showEditBtn,
      showCancelBtn,
      showApproveBtn,
      showRejectBtn,
    };
  }

  hasPermissionCode(permission_code) {
    const { user_rp } = this.props.user.getData();
    return user_rp.includes(permission_code);
  }

  canUserTypeViewChannel(channelUid) {
    const { loggedinUser } = this.props.user.getData();
    return !(['channel_admin', 'channel_user'].includes(loggedinUser.type) && loggedinUser.channel.uid !== channelUid);
  }

  render() {
    const { loading, activeTab, invalid_message, mode, canUserTypeViewChannel } = this.state;
    const disabled = !['create', 'edit'].find(str => mode === str);
    const disabledInfo = disabled || (mode !== 'create' && !this.hasPermissionCode('channel:edit'));
    const disabledLogo = disabled || (mode !== 'create' && !this.hasPermissionCode('channel:change_logo'));
    const disabledFee = disabled || !this.hasPermissionCode('channel:set_channel_trans_fee');
    const disabledInstallmentPrice = disabled || !this.hasPermissionCode('channel:set_channel_installment_price');
    const disabledPayment = disabled || !this.hasPermissionCode('channel:set_payment_method');
    const disabledPaymentSuccessfulEmail = disabled || (mode !== 'create' && !this.hasPermissionCode('channel:set_payment_successful_email'));
    const disableInactivate = disabled || (mode !== 'create' && !this.hasPermissionCode('channel:inactivate'));
    const { showApproved, showPending, showSaveBtn, showSubmitBtn, showEditBtn, showCancelBtn, showApproveBtn, showRejectBtn } = this.showActionByCondition(activeTab);

    if (!canUserTypeViewChannel) {
      return <h5>ท่านไม่มีสิทธิ์ดูรายละเอียดของ Channel</h5>;
    }

    return (
      <Page loading={loading}>
        <div className="container-fluid create-channel-wrapper">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="text-uppercase" style={{ marginBottom: 0 }}>
              <Link to={`${config.web.rootpath}/channels`} className="link-back">
                <i className="fas fa-chevron-left my-auto mr-2" /> <b>Channel</b>
              </Link>
            </h5>
            <Visibility permission_code="channel:view_profile">
              <div className="d-flex align-items-center">
                {showApproved && (
                  <>
                    <div className="approved-wrapper">
                      <div className="p-2 approved-container">
                        <i className="fas fa-check-circle mr-2"></i>
                        <span>ได้รับการอนุมัติ</span>
                      </div>
                    </div>
                    <div className="vertical-line mx-3"></div>
                  </>
                )}
                {showPending && (
                  <div className="p-2 pending-container">
                    <i className="fas fa-file-contract mr-2"></i>
                    <span>อยู่ระหว่างตรวจสอบข้อมูล...</span>
                  </div>
                )}
                {showSaveBtn && (
                  <NexterButton
                    className="mr-2 my-0"
                    size="sm"
                    color="success"
                    outline
                    disabled={!this.state.has_changed && this.state.channel_status !== 'created' && this.state.mode !== 'create'}
                    onClick={this.saveChannel.bind(this)}
                    icon="fas fa-save"
                  >
                    SAVE
                  </NexterButton>
                )}
                {showSubmitBtn && (
                  <NexterButton
                    className="mr-2 my-0"
                    size="sm"
                    color="info"
                    onClick={this.submitChannel.bind(this)}
                    disabled={!this.state.has_changed && this.state.channel_status !== 'created' && this.state.mode !== 'create'}
                    icon="fas fa-paper-plane"
                  >
                    Submit
                  </NexterButton>
                )}
                {showEditBtn && (
                  <NexterButton className="mr-2 my-0" size="sm" icon="fas fa-pencil-alt" onClick={this.edit.bind(this)}>
                    Edit
                  </NexterButton>
                )}
                {showCancelBtn && (
                  <NexterButton className="mr-2 my-0" size="sm" color="info" outline icon="fas fa-times-circle" onClick={this.cancelEdit.bind(this)}>
                    Cancel
                  </NexterButton>
                )}
                {showRejectBtn && (
                  <NexterButton className="mr-2 my-0" size="sm" color="dark" onClick={this.rejectChannel.bind(this)} icon="fas fa-times-circle">
                    Reject
                  </NexterButton>
                )}
                {showApproveBtn && (
                  <Visibility permission_code="channel:approve_channel">
                    <NexterButton className="mr-2 my-0" size="sm" color="success" onClick={this.approveChannel.bind(this)} icon="fas fa-check-circle">
                      Approve
                    </NexterButton>
                  </Visibility>
                )}
              </div>
            </Visibility>
          </div>
          <Visibility permission_code="channel:view_profile">
            <Alert isOpen={!!this.state.reject_reason} className="alert-rejected">
              <i className="fas fa-times-circle mr-2" />
              <span>
                <b>เหตุผลปฏิเสธการอนุมัติ</b>
              </span>{' '}
              : {this.state.reject_reason || ''}
            </Alert>
            <Nav tabs className="no-print channel-page">
              <NavItem style={{ width: 140 }}>
                <NavLink style={{ width: '100%' }} className={classNames({ active: activeTab === 'tab-channel-info' })} onClick={() => this.toggle('tab-channel-info')}>
                  Channel Info
                </NavLink>
              </NavItem>
              <NavItem style={{ width: 160 }}>
                <NavLink style={{ width: '100%' }} className={classNames({ active: activeTab === 'tab-transaction-fee' })} onClick={() => this.toggle('tab-transaction-fee')}>
                  Transaction Fee
                </NavLink>
              </NavItem>
              <NavItem style={{ width: 160 }}>
                <NavLink style={{ width: '100%' }} className={classNames({ active: activeTab === 'tab-payment-option' })} onClick={() => this.toggle('tab-payment-option')}>
                  Payment Option
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="pb-0">
              <TabPane tabId="tab-channel-info">
                <Card className="card-channelmanagement">
                  <CardBody>
                    <h6
                      className={classNames('text-uppercase', {
                        'title-channel-info': mode === 'create',
                        'title-channel-info-view': mode !== 'create',
                      })}
                    >
                      {mode === 'create' && <span className="text-red">[CREATE] </span>}CHANNEL INFORMATION
                    </h6>
                    <hr />
                    <Row>
                      <Col md={6}>
                        <div className="d-flex align-items-center py-2">
                          <Label className="form-label mr-3">Status :</Label>
                          <CustomInput
                            type="switch"
                            id="status"
                            name="status"
                            value={this.state.status === 'active' ? 'inactive' : 'active'}
                            disabled={disableInactivate}
                            checked={this.state.status === 'active'}
                            onChange={this.handleChange}
                          />
                          <Label className="text-uppercase">{this.state.status}</Label>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="checkbox-payment-wrapper p-2">
                          <div>
                            <i className="far fa-envelope"></i> Get payment successful email
                          </div>
                          <div>
                            <CheckBox
                              id="payment_email"
                              name="payment_email"
                              value={this.state.payment_email === 'checked' ? 'uncheck' : 'checked'}
                              checked={this.state.payment_email === 'checked'}
                              disabled={disabledPaymentSuccessfulEmail}
                              onChange={this.handleChange}
                              defaultClassName="my-auto"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      {this.state.channel_uid && (
                        <Col md={6} className="order-1">
                          <FormGroup className="required">
                            <Label className="control-label form-label">Channel ID</Label>
                            <NexterInput size="sm" id="channel_uid" name="channel_uid" value={this.state.channel_uid ?? ''} disabled={true} className="nexter-input-sm" />
                          </FormGroup>
                        </Col>
                      )}
                      <Col md={6} className="order-2">
                        <FormGroup className="required">
                          <Label className="control-label form-label">Channel Name</Label>
                          <NexterInput
                            size="sm"
                            id="name"
                            name="name"
                            value={this.state.name ?? ''}
                            onChange={this.handleChange}
                            invalid={invalid_message['name'] ? true : false}
                            disabled={disabledInfo}
                            className="nexter-input-sm"
                          />
                          <FormFeedback>{invalid_message['name']}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6} className="order-3">
                        <FormGroup className="required">
                          <Label className="control-label form-label">Company Name</Label>
                          <NexterInput
                            size="sm"
                            id="company_name"
                            name="company_name"
                            value={this.state.company_name ?? ''}
                            onChange={this.handleChange}
                            invalid={invalid_message['company_name'] ? true : false}
                            disabled={disabledInfo}
                            className="nexter-input-sm"
                          />
                          <FormFeedback>{invalid_message['company_name']}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col
                        md={6}
                        className={classNames({
                          'order-4': mode === 'create',
                          'order-5': mode !== 'create',
                        })}
                      >
                        <FormGroup className="required">
                          <Label className="control-label form-label">Billing Type</Label>
                          <NexterInput
                            type="select"
                            size="sm"
                            options={this.state.bill_type_options}
                            value={this.state.ebill_account}
                            onChange={this.handleBillTypeChange.bind(this)}
                            className="nexter-select-sm"
                            invalid={invalid_message['ebill_account'] ? true : false}
                            disabled={disabledInfo}
                            placeholder="Choose Billing Type…"
                          />
                          <FormFeedback>{invalid_message['ebill_account']}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col
                        md={6}
                        className={classNames({
                          'order-5': mode === 'create',
                          'order-4': mode !== 'create',
                        })}
                      >
                        <FormGroup className="required">
                          <Label className="control-label form-label">Company Tax ID</Label>
                          <NexterInput
                            allow="numeric"
                            size="sm"
                            id="company_tax_id"
                            name="company_tax_id"
                            value={this.state.company_tax_id ?? ''}
                            invalid={invalid_message['company_tax_id'] ? true : false}
                            disabled={disabledInfo}
                            maxLength={13}
                            onChange={this.handleChange}
                            className="nexter-input-sm"
                          />
                          <FormFeedback>{invalid_message['company_tax_id']}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col
                        md={6}
                        className={classNames({
                          'order-6': mode === 'create',
                          'order-7': mode !== 'create',
                        })}
                      >
                        <FormGroup className="required">
                          <Label className="form-label">Channel Logo</Label>
                          <LogoFile
                            previewSrc={this.state.logo_url}
                            onRemove={this.removeLogo.bind(this)}
                            onChange={this.handleLogo.bind(this)}
                            disabled={disabledLogo}
                            usePermission={false}
                            height="90"
                            width="90"
                            inputProps={{
                              multiple: false,
                              accept: 'image/png, image/jpeg',
                            }}
                            dropZoneProps={{
                              maxSize: 1048576,
                            }}
                          />
                          {/* <div className="d-flex">
                            <div className="logo-thumbnail">
                              <div className="logo-thumbnail-container">
                                {this.state.logo_url ? (
                                  <>
                                    <img src={this.state.logo_url} width="90" height="90" />
                                    {!disabledLogo && <i className="fas fa-times-circle remove-container" onClick={this.removeLogo.bind(this)} />}
                                  </>
                                ) : <span>No Logo</span>}
                              </div>
                            </div>
                            <div className="ml-2">
                              <FilePicker
                                onDrop={this.handleLogo.bind(this)}
                                inputProps={{
                                  multiple: false,
                                  accept: 'image/png, image/jpeg',
                                  disabled: disabledLogo
                                }}
                                dropZoneProps={{
                                  maxSize: 1048576,
                                  disabled: disabledLogo
                                }}>
                                <div className="d-flex">
                                  <NexterButton color="dark" size="sm"
                                    disabled={disabledLogo}
                                    className="folder-before my-0 btn-application-form">
                                    <span> Browse files</span>
                                  </NexterButton>
                                </div>
                              </FilePicker>
                              <span className="logo-upload-text">(Maximum upload file size: 1MB)</span>
                            </div>
                          </div> */}
                        </FormGroup>
                      </Col>
                      <Col
                        md={6}
                        className={classNames({
                          'order-7': mode === 'create',
                          'order-6': mode !== 'create',
                        })}
                      >
                        <FormGroup className="required">
                          <Label className="control-label form-label">Application Form</Label>
                          <ApplicationFile
                            disabled={disabledInfo}
                            onDrop={this.handleAppForm.bind(this)}
                            onView={this.viewApplicationFile.bind(this)}
                            previewName={this.state.app_name}
                            feedbackMessage={invalid_message['app_file']}
                            inputProps={{
                              multiple: false,
                              accept: 'image/png, image/jpeg, image/bmp, .pdf',
                            }}
                            dropZoneProps={{
                              maxSize: 1048576,
                            }}
                          />
                          {/* <FilePicker
                            onDrop={this.handleAppForm.bind(this)}
                            inputProps={{
                              multiple: false,
                              accept: 'image/png, image/jpeg, image/bmp, .pdf',
                              disabled: disabledInfo
                            }}
                            dropZoneProps={{
                              maxSize: 1048576,
                              disabled: disabledInfo
                            }}>
                            <div className="d-flex">
                              <div className="file-application-form w-100 pl-2" onClick={this.viewApplicationFile.bind(this)}>
                                <span>{this.state.app_name || 'click browse files'}</span>
                              </div>
                              <div>
                                <NexterButton color="dark" size="sm"
                                  disabled={disabledInfo}
                                  className="folder-before ml-2 my-0 btn-application-form">
                                  <span> Browse files</span>
                                </NexterButton>
                              </div>
                            </div>
                          </FilePicker> */}
                          <FormFeedback
                            className={classNames({
                              'd-block': invalid_message['app_file'],
                            })}
                          >
                            {invalid_message['app_file']}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr />

                    <Row>
                      <Col xs="12" className="pb-2">
                        <h6>Payment Account</h6>
                      </Col>
                      <Col Col xs="12" md="6">
                        <FormGroup className="required">
                          <Label className="control-label form-label">2C2P</Label>
                          <NexterInput
                            type="select"
                            size="sm"
                            options={this.props.channel.channels_mid_options}
                            value={this.state.ccpp_account}
                            onChange={this.handleCCPPAccountChange.bind(this)}
                            className="nexter-select-sm"
                            invalid={invalid_message['ccpp_account'] ? true : false}
                            disabled={disabledInfo || (this.state.channel_status === 'approved' && !!this.state.temp_ccpp_account)}
                            placeholder="Choose payment account for 2C2P"
                          />
                          <FormFeedback>{invalid_message['ccpp_account']}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col Col xs="12" md="6">
                        <FormGroup>
                          <Label className="control-label form-label">OPN</Label>
                          <NexterInput
                            type="select"
                            size="sm"
                            options={this.props.channel.channels_mid_opn_options}
                            value={this.state.opn_account}
                            onChange={this.handleOpnAccountChange.bind(this)}
                            className="nexter-select-sm"
                            invalid={invalid_message['opn_account'] ? true : false}
                            disabled={disabledInfo || (this.state.channel_status === 'approved' && !!this.state.temp_opn_account)}
                            placeholder="Choose payment account for OPN"
                          />
                          <FormFeedback>{invalid_message['opn_account']}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <h6 className="text-uppercase title-contact-person">CONTACT PERSON</h6>
                    <hr />
                    <ContractPersonList
                      items={this.state.contract_persons}
                      typeOptions={this.state.contract_type_options}
                      disabled={disabledInfo}
                      onChange={this.handleContractChange.bind(this)}
                      onSelect={this.handleContractTypeChange.bind(this)}
                      onRemove={this.removeChannelContract.bind(this)}
                      onAdd={this.addChannelContract.bind(this)}
                    />
                    {/* {this.state.contract_persons.map((el, i) => (
                      <Fragment key={i}>
                        {i > 0 && (<hr />)}
                        <div className="d-flex justify-content-between align-items-center">
                          <h6>No. {i + 1}</h6>
                        </div>
                        <Row>
                          <Col md={6}>
                            <FormGroup className="required">
                              <Label className="control-label form-label">Name</Label>
                              <NexterInput size="sm"
                                name="name"
                                value={el.name ?? ''}
                                invalid={el.name_invalid}
                                disabled={disabledInfo}
                                onChange={this.handleContractChange.bind(this, i)}
                                className="nexter-input-sm"
                              />
                              <FormFeedback>{el.name_invalid}</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="required">
                              <Label className="control-label form-label">Email</Label>
                              <NexterInput size="sm"
                                name="email"
                                value={el.email ?? ''}
                                invalid={el.email_invalid}
                                disabled={disabledInfo}
                                onChange={this.handleContractChange.bind(this, i)}
                                className="nexter-input-sm"
                              />
                              <FormFeedback>{el.email_invalid}</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="required">
                              <Label className="control-label form-label">Telephone No.</Label>
                              <NexterInput size="sm"
                                allow="tel"
                                name="phone"
                                value={el.phone ?? ''}
                                onChange={this.handleContractChange.bind(this, i)}
                                pattern="[0-9]*"
                                invalid={el.phone_invalid}
                                disabled={disabledInfo}
                                maxLength={10}
                                className="nexter-input-sm"
                              />
                              <FormFeedback>{el.phone_invalid}</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="required">
                              <Label className="control-label form-label">Contract Type</Label>
                              <NexterInput type="select" size="sm"
                                options={this.state.contract_type_options}
                                value={el.type}
                                invalid={el.type_invalid}
                                disabled={disabledInfo}
                                onChange={(selected) => this.handleContractTypeChange(selected, i)}
                                className="nexter-select-sm"
                                placeholder="Choose Contract Type…"
                              />
                              <FormFeedback>{el.type_invalid}</FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {this.state.contract_persons.length > 1 &&
                              <NexterButton className="my-0 mr-2" size="sm"
                                color="dark" outline icon="fas fa-minus-circle"
                                disabled={disabledInfo}
                                onClick={this.removeChannelContract.bind(this, i)}>
                                Remove
                                </NexterButton>
                            }
                            {i === this.state.contract_persons.length - 1 &&
                              <NexterButton className="my-0"
                                size="sm" icon="fa fa-plus"
                                disabled={disabledInfo}
                                onClick={this.addChannelContract.bind(this)}>
                                Add Contact
                              </NexterButton>
                            }
                          </Col>
                        </Row>
                      </Fragment>
                    ))
                    } */}
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <h6 className="title-api-configuration">API Configuration</h6>
                    <hr />
                    <Row>
                      <Col lg={12}>
                        <FormGroup>
                          <Label className="control-label form-label">Callback Path :</Label>
                          <NexterInput
                            size="sm"
                            className="nexter-input-sm"
                            id="callback_path"
                            name="callback_path"
                            value={this.state.callback_path ?? ''}
                            disabled={disabledInfo}
                            onChange={this.handleChange}
                          />
                          <FormText className="text-description">e.g. /callback (path only)</FormText>
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label className="control-label form-label">Payment & KYC Webhook Path :</Label>
                          <NexterInput
                            size="sm"
                            className="nexter-input-sm"
                            id="webhook_path_payment"
                            name="webhook_path_payment"
                            value={this.state.webhook_path_payment ?? ''}
                            disabled={disabledInfo}
                            onChange={this.handleChange}
                          />
                          <FormText className="text-description">e.g. /webhook (path only)</FormText>
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label className="control-label form-label">Transfer Webhook Path :</Label>
                          <NexterInput
                            size="sm"
                            className="nexter-input-sm"
                            id="webhook_path_transfer"
                            name="webhook_path_transfer"
                            value={this.state.webhook_path_transfer ?? ''}
                            disabled={disabledInfo}
                            onChange={this.handleChange}
                          />
                          <FormText className="text-description">e.g. /webhook (path only)</FormText>
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label className="control-label form-label">URL Test :</Label>
                          <NexterInput size="sm" className="nexter-input-sm" id="url_test" name="url_test" value={this.state.url_test ?? ''} disabled={disabledInfo} onChange={this.handleChange} />
                          <FormText className="text-description">e.g. https://api.test-your-domain.com (https only)</FormText>
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label className="control-label form-label">URL Production :</Label>
                          <NexterInput size="sm" className="nexter-input-sm" id="url_prod" name="url_prod" value={this.state.url_prod ?? ''} disabled={disabledInfo} onChange={this.handleChange} />
                          <FormText className="text-description">e.g. https://api.your-domain.com (https only)</FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="tab-transaction-fee">
                <Card className="card-channelmanagement">
                  <CardBody>
                    <h6
                      className={classNames('text-uppercase', {
                        'title-channel-info': mode === 'create',
                        'title-channel-info-view': mode !== 'create',
                      })}
                    >
                      {mode === 'create' && <span className="text-red">[CREATE] </span>}FULL PAYMENT
                    </h6>
                    <hr />
                    <Row>
                      {this.state.payment_methods.map((pm, i) =>
                        pm.code !== 'installment' ? (
                          <Col md={6} key={i}>
                            <div className="bg-base rounded p-2 mb-2">
                              <label className="font-weight-bold text-capitalize">{pm.name}</label>
                              <Row className="mt-1">
                                <Col md={6}>
                                  <div className="d-flex">
                                    <div className="custom-radio-fee">
                                      <input type="radio" name={pm.code} value="percent" checked={pm.fee_type === 'percent'} disabled={disabledFee} onChange={this.changeFeeStatus.bind(this, i)} />
                                    </div>
                                    <NexterInput
                                      type="group"
                                      inputType="number"
                                      allow="float"
                                      min={0}
                                      value={pm.percent_fee ?? 0}
                                      selected={pm.fee_type === 'percent'}
                                      disabled={disabledFee || pm.fee_type !== 'percent'}
                                      size="sm"
                                      addonType="append"
                                      addonText="%"
                                      invalid={!!pm.percent_valid}
                                      inputGroupClass={'ml-2'}
                                      onChange={this.changeChannelFee.bind(this, i, 'percent_fee')}
                                      onBlur={this.changeChannelFee.bind(this, i, 'percent_fee')}
                                    />
                                  </div>
                                  {pm.percent_valid && <span className="custom-invalid-feedback-fee ml-2">{pm.percent_valid}</span>}
                                </Col>
                                <Col md={6}>
                                  <div className="d-flex">
                                    <div className="custom-radio-fee">
                                      <input type="radio" name={pm.code} value="baht" checked={pm.fee_type === 'baht'} disabled={disabledFee} onChange={this.changeFeeStatus.bind(this, i)} />
                                    </div>
                                    <NexterInput
                                      type="group"
                                      inputType="number"
                                      allow="float"
                                      min={0}
                                      value={pm.fixed_cost_fee ?? 0}
                                      selected={pm.fee_type === 'baht'}
                                      disabled={disabledFee || pm.fee_type !== 'baht'}
                                      size="sm"
                                      addonType="append"
                                      addonText="Baht"
                                      invalid={!!pm.cost_valid}
                                      inputGroupClass={'ml-2'}
                                      onChange={this.changeChannelFee.bind(this, i, 'fixed_cost_fee')}
                                      onBlur={this.changeChannelFee.bind(this, i, 'fixed_cost_fee')}
                                    />
                                  </div>
                                  {pm.cost_valid && <span className="custom-invalid-feedback-fee ml-2">{pm.cost_valid}</span>}
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        ) : (
                          ''
                        )
                      )}
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <h6 className="text-uppercase title-contact-person">INSTALLMENT</h6>
                    <hr />
                    <Row>
                      {this.state.installment_interests.map((item, i) => (
                        <Col md={6} key={i} className="pb-4">
                          <div className="d-flex justify-content-between pl-2">
                            <div className="text-right">
                              <img src={BANKS[item.abbr]} className="bank-icon" width="32" height="32" />
                            </div>
                            <div className="ml-2 d-flex justify-content-end align-items-center">
                              <span>{item.name}</span>
                              <div className="ml-2">
                                <NexterInput
                                  type="group"
                                  allow="float"
                                  value={item.cost}
                                  selected={true}
                                  disabled={disabledInstallmentPrice || item.status === 'inactive'}
                                  size="sm"
                                  addonType="append"
                                  addonText="%"
                                  invalid={!!item.invalid_message}
                                  inputGroupClass="installment-interest-group"
                                  onChange={this.changeInstallmentCost.bind(this, i)}
                                />
                              </div>
                            </div>
                          </div>
                          {item.invalid_message && (
                            <div className="text-right">
                              <span className="custom-invalid-feedback-fee ml-2">{item.invalid_message}</span>
                            </div>
                          )}
                          {item.status === 'inactive' && (
                            <div className="text-right">
                              <small className="feedback">** ไม่ถูกนำมาใช้ เนื่องจากปิดระบบการชำระเงินนี้</small>
                            </div>
                          )}
                        </Col>
                      ))}
                    </Row>
                  </CardBody>
                </Card>
                {/*
                <Card>
                  <CardBody>
                    <h6 className="text-uppercase title-contact-person">SERVICE CHARGE</h6>
                    <hr />
                    <Col md={6}>
                      <div className="bg-base rounded p-2 mb-2">
                        <label className="font-weight-bold text-capitalize"></label>
                        <Row className="mt-1">
                          <Col md={6}></Col>
                        </Row>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
                */}
              </TabPane>
              <TabPane tabId="tab-payment-option">
                <Card className="card-channelmanagement">
                  <CardBody>
                    <h6
                      className={classNames('text-uppercase', {
                        'title-channel-info': mode === 'create',
                        'title-channel-info-view': mode !== 'create',
                      })}
                    >
                      {mode === 'create' && <span className="text-red">[CREATE] </span>}PAYMENT METHOD
                    </h6>
                    <hr />
                    <Row>
                      <Col md={4}>
                        {this.state.payment_methods.map((payment, index) => (
                          <div key={index}>
                            {index > 0 && <hr className="my-2" />}
                            <div className="d-flex justify-content-between align-items-center">
                              <label className="font-weight-bold text-capitalize">{payment.name}</label>
                              <CustomInput
                                type="switch"
                                id={payment.code}
                                checked={payment.code === 'qr_box' ? payment.option && this.state.tax_suffix.length === 15 : payment.option}
                                disabled={disabledPayment}
                                onChange={this.changePaymentStatus.bind(this, index, payment.code)}
                              />
                            </div>
                            {payment.code === 'qr_box' && (
                              <>
                                <small>
                                  Tax ID+Suffix (สำหรับ Barcode)
                                  <span className="text-red">*</span>
                                </small>
                                <NexterInput
                                  allow="numeric"
                                  type="text"
                                  value={this.state.tax_suffix || ''}
                                  onChange={this.changeQrcode.bind(this)}
                                  disabled={disabledPayment || !this.hasPermissionCode('channel:set_payment_method')}
                                  maxLength={15}
                                  invalid={this.state.qrcode_invalid}
                                  className="nexter-input-sm"
                                  size="sm"
                                />
                                <div className="invalid-feedback">{this.state.qrcode_invalid_message}</div>
                              </>
                            )}
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Visibility>
          <Visibility permission_code="channel:view_profile" invisibility_msg>
            <h5>ท่านไม่มีสิทธิ์ดูรายละเอียดของ Channel</h5>
          </Visibility>
        </div>
      </Page>
    );
  }
}

export default inject('user', 'channel')(observer(CreateChannelPage));
