import React from 'react';

import NexterButton from '../../../components/Button/NexterButton';
import Visibility from '../../../components/Visibility/Visibility';

const displayTransferGatewaysButton = ['2c2p', 't2p', 'linepay', 'opn'];

function TransferButton(props) {
  return (
    <>
      {displayTransferGatewaysButton.includes(props.gateway) ? (
        <Visibility isVisibility={props.isActive}>
          <div style={{ textAlign: 'end' }}>
            <NexterButton outline color="primary" size="sm" onClick={() => props.onTransfer(props.gateway)}>
              โอนเงิน
            </NexterButton>
          </div>
        </Visibility>
      ) : null}
    </>
  );
}

export default TransferButton;
