import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { Table, Card, CardHeader, CardBody, CardFooter, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import { config } from '../../config';

import { Page } from '../../components/Page';
import { Pagination } from '../../components/Pagination/Pagination';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';
import Visibility from '../../components/Visibility/Visibility';
import { EmptyRow } from '../../components/Table/EmptyRow';

const initialState = {
  channelName: '',
  companyName: '',
  statusSelected: null,
  statusOptions: [
    { label: 'Create', value: 'created' },
    { label: 'Pending', value: 'pending' },
    { label: 'Approved', value: 'approved' },
    { label: 'Rejected', value: 'rejected' },
  ],
  channelUid: '',
  taxId: '',
};

class ChannelManagementPage extends Component {
  constructor(props) {
    super(props);
    this.state = _.cloneDeep(initialState);
  }

  componentDidMount() {
    this.props.channel.fetchChannels();
  }

  // deleteChannelById(id) {
  //   this.props.channel.deleteChannelById(id);
  // }

  clear() {
    this.setState(_.cloneDeep(initialState));
    setTimeout(() => this.search(), 0);
  }

  search() {
    // reset page and fetch channels again.
    this.props.channel.selectPage(1, { ...this.state });
  }

  selectPage(page) {
    this.props.channel.selectPage(page, { ...this.state });
  }

  changeChannelName(event) {
    let channelName = event.target.value;
    this.setState({ channelName });
  }

  changeCompanyName(event) {
    let companyName = event.target.value;
    this.setState({ companyName });
  }

  changeStatus(statusSelected, actionMeta) {
    this.setState({ statusSelected });
  }

  changeChannelUid(event) {
    let channelUid = event.target.value;
    this.setState({ channelUid });
  }

  changeTaxID(event) {
    let taxId = event.target.value;
    this.setState({ taxId });
  }

  render() {
    let data = this.props.channel.getData();
    let { loggedinUser } = this.props.user.getData();
    const showCreateBtn = ['super_admin', 'ae_marketing'].some(r => r === loggedinUser.type);

    let rows = !_.isEmpty(data.channels.items) ? (
      data.channels.items.map(channel => {
        return (
          <tr key={channel.uid}>
            <td>
              <Link style={{ color: '#007bff' }} to={`${config.web.rootpath}/channels/detail/${channel.uid}`}>
                {channel.uid}
              </Link>
            </td>
            <td>{channel.name}</td>
            <td>{channel.company_name ?? '-'}</td>
            <td>{channel.company_tax_id ?? '-'}</td>
            <td>
              {channel.channel_contract && channel.channel_contract.length > 0 ? (
                <>
                  {channel.channel_contract.map((item, ii) => (
                    <Fragment key={ii}>
                      {item.phone && !item.is_obsolete ? (
                        <>
                          {item.phone}
                          {ii !== channel.channel_contract.length - 1 && <br />}
                        </>
                      ) : (
                        ''
                      )}
                    </Fragment>
                  ))}
                </>
              ) : (
                '-'
              )}
            </td>
            <td className="text-uppercase td-status">
              {channel.status ? (
                <>
                  <i className={classNames('far fa-dot-circle', channel.status)}></i> {channel.status}
                </>
              ) : (
                '-'
              )}
            </td>
          </tr>
        );
      })
    ) : (
      <EmptyRow colSpan={6} />
    );

    /**
      <Link to={`${config.web.rootpath}/channels/channelfee`}>
        <NexterButton className="mr-2 my-0" size="sm">
          Channel Fee
        </NexterButton>
      </Link>
    */

    return (
      <Page loading={data.loading}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="text-uppercase" style={{ marginBottom: 0 }}>
              <b>Channel</b>
            </h5>
            <div>
              <Visibility permission_code="channel:view_list">
                <Link to={`${config.web.rootpath}/channels/history`}>
                  <NexterButton className="mr-2 my-0" size="sm" outline icon="fas fa-history">
                    History Log
                  </NexterButton>
                </Link>
              </Visibility>
              {showCreateBtn && (
                <Visibility permission_code="channel:create">
                  <Link to={`${config.web.rootpath}/channels/create`}>
                    <NexterButton className="mr-2 my-0" size="sm" icon="fa fa-plus">
                      Create Channel
                    </NexterButton>
                  </Link>
                </Visibility>
              )}
            </div>
          </div>
          <Visibility permission_code="channel:view_list">
            <Card>
              <Visibility permission_code="channel:search">
                <CardHeader>
                  <Row>
                    <Col lg="3">
                      <NexterInput size="sm" className="nexter-input-sm" value={this.state.channelName} onChange={this.changeChannelName.bind(this)} placeholder="Channel Name" />
                    </Col>
                    <Col lg="3">
                      <NexterInput size="sm" className="nexter-input-sm" value={this.state.companyName} onChange={this.changeCompanyName.bind(this)} placeholder="Company Name" />
                    </Col>
                    <Col lg="3">
                      <NexterInput
                        type="select"
                        className={'nexter-select-sm'}
                        isClearable
                        placeholder="Status..."
                        options={this.state.statusOptions}
                        value={this.state.statusSelected}
                        onChange={this.changeStatus.bind(this)}
                      />
                    </Col>
                    <Col className="pr-0">
                      <NexterButton size="sm" color="info" outline onClick={this.clear.bind(this)}>
                        clear
                      </NexterButton>
                      <NexterButton size="sm" color="dark" className="mx-1" icon="fas fa-search" onClick={this.search.bind(this)}>
                        search
                      </NexterButton>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col lg="3">
                      <NexterInput size="sm" className="nexter-input-sm" value={this.state.channelUid} onChange={this.changeChannelUid.bind(this)} placeholder="Channel ID" />
                    </Col>
                    <Col lg="3">
                      <NexterInput size="sm" className="nexter-input-sm" value={this.state.taxId} onChange={this.changeTaxID.bind(this)} placeholder="Tax ID" />
                    </Col>
                  </Row>
                </CardHeader>

                <hr />
              </Visibility>

              <CardBody>
                <div className="table-reponsive table-scroll">
                  <Table className="table-channel">
                    <thead>
                      <tr>
                        <th className="text-capitalize border-0">Channel ID</th>
                        <th className="text-capitalize border-0">Channel Name</th>
                        <th className="text-capitalize border-0">Company Name</th>
                        <th className="text-capitalize border-0">Tax ID</th>
                        <th className="text-capitalize border-0">Phone No.</th>
                        <th className="text-capitalize border-0">Status</th>
                      </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                  </Table>
                </div>
              </CardBody>

              <hr style={{ marginTop: 0 }} />
              <CardFooter>
                <Row>
                  <Col />
                  <Col>
                    <Pagination
                      className="pull-right"
                      activePage={data.channels.page}
                      displayPageNumber={5}
                      maximumPageNumber={Math.ceil(data.channels.total / data.channels.size)}
                      onSelect={this.selectPage.bind(this)}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Visibility>
          <Visibility permission_code="channel:view_list" invisibility_msg>
            <h5>ท่านไม่มีสิทธิ์ดูรายการ Channel</h5>
          </Visibility>
        </div>
      </Page>
    );
  }
}

export default inject('channel', 'user')(observer(ChannelManagementPage));
