import React from 'react';
import { Media } from 'reactstrap';

import bay from '../../assets/img/bay.png';
import bbl from '../../assets/img/bbl.jpg';
import fcc from '../../assets/img/fcc.jpg';
import ktc from '../../assets/img/ktc.jpg';
import scb from '../../assets/img/scb.jpg';
import kbank from '../../assets/img/kbank.png';
import uob from '../../assets/img/uob.jpeg';
import tbank from '../../assets/img/tbank.jpg';
import ttb from '../../assets/img/ttb.png';

const cardInfo = {
  bay: {
    logo: bay,
    name: 'Bank of Ayudhya (Krungsri)',
  },
  bbl: {
    logo: bbl,
    name: 'Bangkok Bank',
  },
  fcc: {
    logo: fcc,
    name: 'Krungsri First Choice',
  },
  kbank: {
    logo: kbank,
    name: 'Kasikorn Bank',
  },
  ktc: {
    logo: ktc,
    name: 'Krungthai Bank',
  },
  scb: {
    logo: scb,
    name: 'Siam Commercial Bank',
  },
  uob: {
    logo: uob,
    name: 'United Overseas Bank',
  },
  tbank: {
    logo: tbank,
    name: 'Thanachart Bank',
  },
  ttb: {
    logo: ttb,
    name: 'TMBThanachart Bank',
  },
};

const InstallmentPeriod = ({ data, onDelete, disabled }) => {
  const info = cardInfo[data.abbr.toLowerCase()];
  return (
    <div className="installment-card-wrapper">
      <img alt="logo" width={32} src={info.logo} className="p-0 mr-2 installment-card-img" />
      <div>
        <b>{data.abbr} : {info.name}</b>
        <br />
        <span>ผ่อนชำระ {data.terms} เดือน, ขั้นต่ำ {data.minimum_amount} บาท</span>
      </div>
      {!disabled && <i className="fas fa-times-circle ic-remove-btn" onClick={onDelete} />}
    </div>
  );
};

export default InstallmentPeriod;
