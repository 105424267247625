import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import React from 'react';

import Visibility from '../../../components/Visibility/Visibility';

function TransferManagementTab(props) {
  const changeTab = e => {
    props.onClickTab(e.target.id);
  };

  const checkVisible = gateway => {
    const gateways = ['t2p', '2c2p', 'linepay', 'opn'];
    return gateways.includes(gateway);
  };

  const isT2P = gateway => 't2p' === gateway;
  const is2c2p = gateway => '2c2p' === gateway;
  const isLinepay = gateway => 'linepay' === gateway;
  const isOPN = gateway => 'opn' === gateway;

  return (
    <div className="nav-bar-bottom-line no-print" style={{ marginTop: '0.5rem', paddingLeft: '2rem' }}>
      <Visibility isVisibility={props.transfer_condition.report_no !== null}>
        <button className="btn-back no-print" onClick={props.onClickBackToListTable}>
          <i className="fas fa-chevron-left" style={{ fontSize: '30px' }} />
        </button>
      </Visibility>
      <Nav tabs className="no-print">
        <Visibility isVisibility={!checkVisible(props.gateway)}>
          <NavItem>
            <NavLink className={classnames({ active: props.tabsActive === 'approved' })} onClick={changeTab} id="approved">
              รอดำเนินการ
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: props.tabsActive === 'exported' })} onClick={changeTab} id="exported">
              สร้างไฟล์แล้ว
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: props.tabsActive === 'pending' })} onClick={changeTab} id="pending">
              บัญชีรอตรวจสอบ
            </NavLink>
          </NavItem>
        </Visibility>
        <Visibility isVisibility={is2c2p(props.gateway) || isLinepay(props.gateway) || isT2P(props.gateway) || isOPN(props.gateway)}>
          <NavItem>
            <NavLink className={classnames({ active: props.tabsActive === 'created' })} onClick={changeTab} id="created">
              ยังไม่ได้โอน
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: props.tabsActive === 'requested' })} onClick={changeTab} id="requested">
              กำลังโอนเงิน
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: props.tabsActive === 'transferred' })} onClick={changeTab} id="transferred">
              โอนเงินสำเร็จ
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: props.tabsActive === 'failed' })} onClick={changeTab} id="failed">
              โอนเงินไม่สำเร็จ
            </NavLink>
          </NavItem>
        </Visibility>
      </Nav>
    </div>
  );
}

export default TransferManagementTab;
