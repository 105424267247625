import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment';
import swal from 'sweetalert2';
import { http } from '../../utils/http';
import { Page } from '../../components/Page';
import { Link } from 'react-router-dom';
import { Row, Col, Label, FormGroup, Card, CardHeader, CardBody, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';
import 'react-datepicker/dist/react-datepicker.css';

import { CheckBox } from '../../components/Input/CheckBox';
import CustomInputDatePicker from '../../components/Input/DatePicker';
import InstallmentCard from './InstallmentCard';
import { config } from '../../config';
import { th } from 'date-fns/locale';
registerLocale('th', th);

class CampaignCreatePage extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.handleChange = this.handleChange.bind(this);
    this.hasPermissionCode = this.hasPermissionCode.bind(this);
  }

  getInitialState() {
    return {
      modal: false,
      mode: 'create',
      user_type: '',
      admin_channel_uid: '',
      id: '',

      // new
      name: '',
      start_date: '',
      end_date: '',
      installments: [], // all campaigns in object
      exist_capaign_options: [], // all campaign in select option
      exist_campaign: null,
      cards: [],
      // modal
      card: {
        abbr: '',
        absorb_interest_rate: 100,
        terms: [],
        minimum_amount: 0,
      },
      minimum_amount: '',
      card_options: [],
    };
  }
  setInitialCard() {
    return {
      modal: false,
      card: {
        abbr: '',
        absorb_interest_rate: 100,
        terms: [],
        minimum_amount: 0,
      },
    };
  }

  async componentDidMount() {
    let { loggedinUser } = this.props.user.getData();
    let user_type = loggedinUser.type;
    const id = this.props.match.params.id;
    const admin_channel_uid = this.props.match.params.uid;
    this.setState({ admin_channel_uid, user_type }, async () => {
      if (!admin_channel_uid) {
        await this.fetchAllCampaign();
        await this.fetchSystemCardOptions();
      }

      if (id) await this.fetchCampaignDetail(id);
    });
  }

  async fetchCampaignDetail(id) {
    try {
      const { admin_channel_uid } = this.state;
      let params = {};
      if (admin_channel_uid) params['channel_uid'] = admin_channel_uid;
      let query = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');
      let url = `${config.npay.apiUrl}/channels/settings/installment/${id}${query ? '?' + query : ''}`;
      let res = await http.get(url);
      if (res.status === 200) {
        let body = await res.json();
        let data = body.data;
        this.setState({
          id,
          mode: 'edit',
          name: data.name,
          start_date: new Date(data.start_date),
          end_date: new Date(data.end_date),
          cards: data.cards
            ? data.cards.map(item => ({
                ..._.pick(item, ['id', 'abbr', 'absorb_interest_rate', 'minimum_amount', 'terms']),
              }))
            : [],
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  async fetchSystemCardOptions() {
    try {
      let url = `${config.npay.apiUrl}/channels/settings/systemcardoptions`;
      let res = await http.get(url);
      if (res.status === 200) {
        let body = await res.json();
        const card_options = this.createCardOptions(body.data);
        this.setState({ card_options });
      }
    } catch (error) {
      throw error;
    }
  }
  async fetchAllCampaign() {
    try {
      let url = `${config.npay.apiUrl}/channels/settings/all/installment`;
      let res = await http.get(url);
      if (res.status === 200) {
        let body = await res.json();
        let data = body.data;
        const exist_capaign_options = data ? data.filter(item => item.cards && item.cards.length > 0).map(item => ({ value: item.id, label: item.name })) : [];
        this.setState({ exist_capaign_options, installments: data });
      }
    } catch (error) {
      throw error;
    }
  }
  createCardOptions(input) {
    let result = input.map(item => {
      let arrTerms = item.terms.split(',');
      let objTerms = [];
      objTerms = arrTerms.map(i => {
        return { term: i, checked: false };
      });

      return {
        value: item.abbr,
        label: item.name,
        selected: false,
        absorb_interest_rate: item.absorb_interest_rate,
        minimum_amount: item.minimum_amount,
        terms: objTerms,
      };
    });

    return result;
  }
  showSuccessAndRedirect(text = 'บันทึกข้อมูลสำเร็จ') {
    swal
      .fire({
        customClass: 'nexter-alert',
        type: 'success',
        title: 'Success!',
        text,
        confirmButtonText: 'CONTINUE',
      })
      .then(async () => {
        this.backToChannel();
      });
  }
  showErrorAlert(text = '') {
    swal.fire({
      customClass: 'nexter-alert',
      type: 'error',
      title: 'Error!',
      text,
      confirmButtonText: 'TRY AGAIN',
    });
  }
  async checkCampStatus() {
    try {
      if (!this.state.start_date || !this.state.end_date) {
        this.showErrorAlert('คุณยังไม่ได้ระบุช่วงวันที่เริ่ม-วันที่สิ้นสุด');
        return;
      }

      let dateStart = moment(this.state.start_date).utcOffset(config.time_calibate.UTC).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      let dateEnd = moment(this.state.end_date).utcOffset(config.time_calibate.UTC).set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

      swal
        .fire({
          customClass: 'nexter-alert',
          type: 'warning',
          title: 'ตรวจเช็ค Campaign ที่มีช่วงเวลานี้ ?',
          text: 'ระบบตรวจเช็ค Campaign ใดกำลัง Active ในช่วงเวลานี้ ถ้ามีช่วงวันที่ซ้ำกัน ยังสามารถสร้างได้ แต่สถานะจะเป็น Inactive',
          showCancelButton: true,
          cancelButtonText: 'CANCEL',
          confirmButtonText: 'CHECK',
          showLoaderOnConfirm: true,
          reverseButtons: true,
          preConfirm: async () => {
            return await this.submitCheckStatus();
          },
          allowOutsideClick: () => !swal.isLoading(),
        })
        .then(result => {
          if (result?.value?.status) {
            swal.fire({
              customClass: 'nexter-alert',
              type: 'success',
              title: `ไม่มี Campaign อื่น Active ในช่วงวันที่นี้`,
              text: `${dateStart.format('DD/MM/YYYY')} - ${dateEnd.format('DD/MM/YYYY')}`,
              confirmButtonText: 'CONTINUE',
            });
          } else {
            swal.fire({
              customClass: 'nexter-alert',
              title: result?.value?.message || '',
              type: 'warning',
              text: 'กำลัง Active ในช่วงเวลาเดียวกัน',
              showCancelButton: false,
              confirmButtonText: 'CONTINUE',
            });
          }
        });
    } catch (err) {
      console.error(err);
    }
  }
  async submitCheckStatus() {
    try {
      let body = {};
      if (this.state.id) body.id = this.state.id;
      let dateStart = moment(this.state.start_date).utcOffset(config.time_calibate.UTC).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      let dateEnd = moment(this.state.end_date).utcOffset(config.time_calibate.UTC).set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
      body.start_date = dateStart.valueOf();
      body.end_date = dateEnd.valueOf();
      let url = `${config.npay.apiUrl}/channels/settings/campaign/status/check`;
      const response = await http.put(url, { body });
      const [response_1, json] = await Promise.all([response, response.json()]);
      if (!response_1.ok) throw new Error(json.message);
      const data = json.data;
      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async submitCampaign(is_active_status) {
    try {
      const { id, name, cards, start_date, end_date } = this.state;

      /**
        NOTICE: node-mssql have bug; 
        - when insert time for datetime to database, if insert 23:59:59, day will add 1 day when insert
        - when insert time for datetime to database, if insert 00:00:00, day will display with substract 1 day when select.
        So need insert with 12:00:00.000 then use logic in programming solve problem.
      */
      const dateStart = moment(start_date).utcOffset(config.time_calibate.UTC).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
      const dateEnd = moment(end_date).utcOffset(config.time_calibate.UTC).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
      let body = {
        id: id || null,
        name,
        cards: cards.map(card => {
          return {
            minimum_amount: +card.minimum_amount,
            id: card.id || null,
            abbr: card.abbr,
            absorb_interest_rate: +card.absorb_interest_rate,
            terms: card.terms.split(','),
          };
        }),
        start_date: dateStart.valueOf(),
        end_date: dateEnd.valueOf(),
        status: is_active_status ? 'active' : 'inactive',
      };

      const urlSave = `${config.npay.apiUrl}/channels/settings/installment`;
      const response = await http.post(urlSave, { body });
      const [response_1, json_1] = await Promise.all([response, response.json()]);
      if (!response_1.ok) throw new Error(json_1.message);

      // const urlCampaignUpdate = `${config.npay.apiUrl}/channels/settings/campaign/status/force`;
      // const response2 = await http.put(urlCampaignUpdate, { body: { id: json_1.data.id } })
      // const [response_2, json_2] = await Promise.all([response, response.json()]);
      // if (!response_2.ok) throw new Error(json_2.message);

      return true;
    } catch (error) {
      swal.update({
        customClass: 'nexter-alert',
        type: 'warning',
        text: '',
        showConfirmButton: false,
        cancelButtonText: 'CONTINUE',
        confirmButtonColor: '#e20a1a',
      });
      swal.showValidationMessage(`${error}`.replace('Error: ', ''));
      console.error(error);
    }
  }
  async saveInstallmentSetting() {
    const { mode } = this.state;
    if (this.validate()) {
      try {
        const result = await swal
          .fire({
            customClass: 'nexter-alert',
            type: 'warning',
            title: mode === 'edit' ? 'ยืนยันแก้ไขและบันทึก Campaign นี้ ?' : 'ยืนยันสร้าง Campaign นี้ ?',
            showCancelButton: true,
            cancelButtonText: 'CANCEL',
            confirmButtonText: 'CONFIRM',
            showLoaderOnConfirm: true,
            reverseButtons: true,
            preConfirm: async () => {
              const check_status = await this.submitCheckStatus();
              if (check_status.status) {
                const save_success = await this.submitCampaign(true);
                return { status: save_success };
              } else {
                return check_status;
              }
            },
            allowOutsideClick: () => !swal.isLoading(),
          })
          if (result?.value?.status) {
            // await this.submitCampaign(true);
            this.showSuccessAndRedirect();
          } else if (!result?.value?.status && result?.value?.message) {
            this.confirmInstallmentSetting(result?.value?.message);
          }
      } catch (err) {
        console.error(err);
      }
    }
  }
  async confirmInstallmentSetting(activeCampaignName = '') {
    try {
      swal
        .fire({
          customClass: 'nexter-alert text-left',
          type: 'warning',
          title: 'โปรดทราบ',
          html: `<div class="text-left">• ${activeCampaignName} กำลัง Active ในช่วงเวลาเดียวกัน<br />• ${this.state.name} นี้จะถูกปรับเป็น Inactive</div>`.replace('Error: ', ''),
          showCancelButton: true,
          cancelButtonText: 'CANCEL',
          confirmButtonText: 'CONFIRM',
          showLoaderOnConfirm: true,
          reverseButtons: true,
          preConfirm: async () => {
            return await this.submitCampaign(false);
          },
          allowOutsideClick: () => !swal.isLoading(),
        })
        .then(result => {
          if (result.value) {
            this.showSuccessAndRedirect();
          }
        });
    } catch (err) {
      console.error(err);
    }
  }
  validate() {
    let valid = true;
    let msg = [];
    const { name, start_date, end_date, cards } = this.state;

    if (!name) {
      valid = false;
      msg.push('โปรดระบุชื่อ campaign');
    }
    if (!start_date || !end_date) {
      valid = false;
      msg.push('โปรดระบุชื่อ ช่วงเวลาของ campaign');
    }
    if (!cards || cards.length === 0) {
      valid = false;
      msg.push('โปรดเลือกบัตรที่ใช้ในการผ่อนชำระอย่างน้อย 1 ใบ');
    }
    this.showErrorAlert('เกิดข้อผิดพลาด กรุณาระบุข้อมูลให้ครบถ้วน');
    return valid;
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal,
      card: {
        absorb_interest_rate: 100,
        terms: [],
        minimum_amount: 0,
      },
      minimum_amount: 0,
    });
  }
  selectCard(selected) {
    const card = _.cloneDeep(selected);
    this.setState({ card, minimum_amount: card.minimum_amount });
  }
  setTerm(term) {
    let card = { ...this.state.card };
    // let found = card.terms.find(item => item.term === term);
    // if (found) found.checked = !found.checked;
    let terms = this.state.card.terms.map(item => {
      if (item.term === term) {
        item.checked = !item.checked;
      }
      return item;
    });
    card.terms = terms;
    this.setState({ card });
  }

  addToInstallment() {
    let card = this.state.card;
    let cardDup = true;
    let installment = {
      minimum_amount: this.state.minimum_amount,
      abbr: card.value,
      // name: card.label,
      absorb_interest_rate: card.absorb_interest_rate,
      terms: card.terms
        .filter(item => item.checked)
        .map(item => item.term)
        .join(),
    };

    this.state.cards.forEach(item => {
      if (installment.abbr === item.abbr) {
        if (_.intersection(installment.terms.split(','), item.terms.split(',')).length > 0) {
          cardDup = false;
        }
      }
    });
    let error_message = '';
    if (!cardDup) {
      error_message = 'เดือนซ้ำ ข้อมูลบัตร และ จำนวนเดือนผ่อนชำระ นี้ถูกสร้างไว้แล้ว';
    } else if (installment && (_.isEmpty(installment.terms) || _.isEmpty(installment.abbr))) {
      error_message = 'กรุณาเลือกประเภทบัตรและจำนวนเดือนที่ผ่อนชำระ';
    } else if (!installment.minimum_amount || (installment && this.state.card.minimum_amount > installment.minimum_amount)) {
      error_message = 'บัตร ' + installment.abbr + ' ต้องมียอดชำระขั้นต่ำ ' + this.state.card.minimum_amount + ' บาท';
    }

    if (error_message) {
      this.showErrorAlert(error_message);
    } else {
      const cards = this.state.cards;
      let isGrouped = false;
      cards.forEach((card, index) => {
        let isGroupable = true;
        for (const [key, value] of Object.entries(card)) {
          if (key !== 'terms' && installment[key] !== value) isGroupable = false;
        }
        if (isGroupable) {
          const allTerms = [...installment.terms.split(','), ...card.terms.split(',')];
          installment.terms = allTerms.sort((a, b) => parseInt(a) - parseInt(b)).join(',');
          cards[index] = installment;
          isGrouped = true;
        }
      });
      if (!isGrouped) cards.push(installment);
      this.setState({ ...this.setInitialCard(), cards, exist_campaign: null });
    }
  }
  deleteInstallment(i) {
    let cards = this.state.cards;
    cards.splice(i, 1);
    this.setState({ cards, exist_campaign: null });
  }

  async checkInstallmentSetting() {
    await this.checkCampStatus();
  }

  backToChannel() {
    if (this.state.admin_channel_uid) {
      this.props.channel.changeAdminChannelUid(this.state.admin_channel_uid);
    }

    this.props.history.push(`${config.web.rootpath}/channel-setting`);
  }

  async clearCard() {
    const { mode } = this.state;
    let showTitle = mode === 'create' ? 'ยกเลิกสร้าง Campaign ?' : 'ยกเลิกแก้ไข Campaign นี้ ?'

    const sa2Confirm = await swal.fire({
      customClass: 'nexter-alert',
      type: 'warning',
      title: showTitle,
      showCancelButton: true,
      cancelButtonText: 'NO',
      confirmButtonText: 'YES',
      reverseButtons: true,
    });

    if (sa2Confirm.value) {
      this.backToChannel();
    }
  }
  handleChange(name, val) {
    this.setState({ [name]: val });
  }
  handleExistCampaignChange(exist_campaign) {
    const campaignId = exist_campaign.value;
    const campaign = this.state.installments.find(item => item.id === campaignId);
    const cards = campaign.cards
      ? campaign.cards.map(item => ({
          abbr: item.abbr.toUpperCase(),
          absorb_interest_rate: item.absorb_interest_rate,
          terms: item.terms || '',
          minimum_amount: item.minimum_amount,
        }))
      : [];
    this.setState({ exist_campaign, cards });
  }

  disabledEditByRole(type) {
    const { user_type } = this.state;
    switch (type) {
      case 'admin_select': // enable only 3 roles
        return !['as_channel_admin', 'channel_admin', 'channel_user'].some(r => r === user_type);
      default:
        return false;
    }
  }

  hasPermissionCode(permission_code) {
    const { user_rp } = this.props.user.getData();
    return user_rp.includes(permission_code);
  }

  render() {
    const { loading } = this.props.channel.getData();
    const { mode, cards: installment_cards } = this.state;
    const disabledForNotChannelAdmin = !this.hasPermissionCode('channel:set_channel_installment_campaign');

    let cards = [];
    if (installment_cards) {
      cards = installment_cards.map((item, i) => {
        return (
          <Col md={6} key={i} style={{ marginBottom: 16 }}>
            <InstallmentCard data={item} onDelete={this.deleteInstallment.bind(this, i)} disabled={disabledForNotChannelAdmin} />
          </Col>
        );
      });
    }

    return (
      <Fragment>
        <Page loading={loading}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="text-uppercase" style={{ marginBottom: 0 }}>
              <a onClick={this.backToChannel.bind(this)}>
                <i className="fas fa-chevron-left"></i> <b>CHANNEL SETTINGS</b>
              </a>
            </h5>
          </div>
          <Card className="create-campaign-wrapper">
            <CardBody>
              <h6 className="text-uppercase title-channel">{disabledForNotChannelAdmin ? '(Info) INSTALLMENT' : mode} CAMPAIGN</h6>
              <Row className="mt-3">
                <Col lg={4}>
                  <FormGroup className="required">
                    <Label className="control-label form-label">Campaign Name</Label>
                    <NexterInput
                      size="sm"
                      name="name"
                      value={this.state.name}
                      onChange={e => this.handleChange(e.target.name, e.target.value)}
                      placeholder="กรุณาระบุชื่อ Campaign"
                      disabled={disabledForNotChannelAdmin}
                      maxLength={30}
                      className="nexter-input-sm"
                    />
                    <div className="d-flex justify-content-end text-length">
                      <span>{this.state.name ? this.state.name.length : 0}/30</span>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="required">
                    <Label className="control-label form-label">Start Date - End Date</Label>
                    <div className="d-flex">
                      <DatePicker
                        customInput={
                          <CustomInputDatePicker size="sm" className="campaign-date" iconCalendarStyle={{ position: 'absolute', right: 5, top: 5 }}>
                            {this.state.start_date ? (
                              <span className="datepicker-custom-text">{moment(this.state.start_date).format('DD/MM/YYYY')}</span>
                            ) : (
                              <span className="datepicker-custom-text">วันที่เริ่ม</span>
                            )}
                          </CustomInputDatePicker>
                        }
                        selected={this.state.start_date}
                        onChange={val => this.handleChange('start_date', val)}
                        disabled={disabledForNotChannelAdmin}
                        locale="th"
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        maxDate={this.state.end_date}
                      />
                      <div className="mx-2">
                        <DatePicker
                          customInput={
                            <CustomInputDatePicker size="sm" className="campaign-date" iconCalendarStyle={{ position: 'absolute', right: 5, top: 5 }}>
                              {this.state.end_date ? (
                                <span className="datepicker-custom-text">{moment(this.state.end_date).format('DD/MM/YYYY')}</span>
                              ) : (
                                <span className="datepicker-custom-text">วันที่สิ้นสุด</span>
                              )}
                            </CustomInputDatePicker>
                          }
                          selected={this.state.end_date}
                          onChange={val => this.handleChange('end_date', val)}
                          locale="th"
                          disabled={disabledForNotChannelAdmin}
                          dateFormat="dd/MM/yyyy"
                          minDate={this.state.start_date}
                        />
                      </div>
                      {!disabledForNotChannelAdmin && (
                        <NexterButton size="sm" color="dark" onClick={this.checkInstallmentSetting.bind(this)} icon="fas fa-search">
                          CHECK
                        </NexterButton>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col lg={4}>
                  {mode === 'create' && (
                    <FormGroup>
                      <Label className="control-label form-label">เลือกบัตรเครดิตจาก Campaign (Optional)</Label>
                      <NexterInput
                        type="select"
                        size="sm"
                        options={this.state.exist_capaign_options}
                        value={this.state.exist_campaign}
                        onChange={this.handleExistCampaignChange.bind(this)}
                        className="nexter-select-sm"
                        placeholder="Choose Campaign…"
                      />
                    </FormGroup>
                  )}
                  {mode === 'edit' && <Label className="form-label">บัตรเครดิตธนาคารที่จัด Campaign</Label>}
                </Col>
              </Row>
              <Row>{cards}</Row>
              <Row>
                <Col>
                  {!disabledForNotChannelAdmin && (
                    <FormGroup className="required">
                      <Label className="control-label form-label">เพิ่มบัตรเครดิตที่ใช้ผ่อนชำระ</Label>
                      <br />
                      <NexterButton size="sm" onClick={this.toggleModal.bind(this)} icon="fa fa-plus">
                        CREDIT CARD
                      </NexterButton>
                    </FormGroup>
                  )}
                </Col>
              </Row>
              {!disabledForNotChannelAdmin && (
                <>
                  <hr />
                  <Row>
                    <Col>
                      <NexterButton className="mr-2 my-0" size="sm" color="success" outline onClick={this.saveInstallmentSetting.bind(this)} icon="fas fa-save">
                        SAVE
                      </NexterButton>
                      <NexterButton className="mr-2 my-0" size="sm" color="dark" onClick={this.clearCard.bind(this)} outline>
                        Cancel
                      </NexterButton>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>

            <Modal isOpen={this.state.modal} toggle={this.toggleModal.bind(this)} className="create-campaign-modal-wrapper">
              <ModalBody>
                <h6 className="title-channel">Installment Settings</h6>
                <hr />
                <Row>
                  <Col>
                    <FormGroup className="required">
                      <Label className="control-label form-label">เลือกบัตรธนาคาร</Label>
                      <NexterInput
                        type="select"
                        options={this.state.card_options}
                        value={this.state.card.value ? this.state.card : ''}
                        onChange={this.selectCard.bind(this)}
                        placeholder="Choose a bank card…"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="required">
                      <Label className="control-label form-label">ตั้งค่าชำระขั้นต่ำ</Label>
                      <NexterInput
                        type="group"
                        inputType="number"
                        allow="float"
                        name="minimum_amount"
                        value={this.state.minimum_amount}
                        min={this.state.card.minimum_amount}
                        max={10000000}
                        onChange={e => this.handleChange(e.target.name, e.target.value)}
                        onBlur={e => this.handleChange(e.target.name, e.target.value)}
                        // selected={pm.fee_type === 'percent'}
                        // disabled={disabledFee}
                        size="sm"
                        addonType="append"
                        addonText="Baht"
                        inputGroupClass=""
                        className="text-right"
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label className="control-label form-label">ชำระดอกเบี้ยแทนผู้ขาย (Seller)</Label>
                      <NexterInput
                        type="group"
                        allow="float"
                        value={this.state.card.absorb_interest_rate || ''}
                        // selected={pm.fee_type === 'percent'}
                        disabled={true}
                        size="sm"
                        addonType="append"
                        addonText="%"
                        inputGroupClass=""
                        className="text-right"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="required">
                      <Label className="control-label form-label">จำนวนเดือนผ่อนชำระ</Label>
                      {this.state.card.terms && this.state.card.terms.length > 0 ? (
                        <>
                          {this.state.card.terms.map(item => {
                            return (
                              <div key={this.state.card.abbr + '_' + item.term}>
                                <CheckBox value={item.term} label={`${item.term} เดือน`} checked={item.checked} onChange={this.setTerm.bind(this, item.term)} defaultClassName="" />
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <div>
                          <Label className="form-label">(กรุณาเลือกบัตรธนาคารเพื่อตั้งค่า)</Label>
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className="d-flex justify-content-end">
                    <NexterButton className="mr-2" size="sm" color="light" onClick={this.toggleModal.bind(this)}>
                      CANCEL
                    </NexterButton>
                    <NexterButton size="sm" onClick={this.addToInstallment.bind(this)}>
                      CONFIRM
                    </NexterButton>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </Card>
        </Page>
      </Fragment>
    );
  }
}

export default inject('channel', 'user')(observer(CampaignCreatePage));
