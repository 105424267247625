import React from 'react';

import moment from 'moment';
import { Table } from 'reactstrap';
import { Amount } from '../../components/Field/Amount';
import iNoData from '../../assets/img/icon-no-data.svg';

const EmptyRow = () => (
  <tr>
    <td colSpan={7} className="text-center">
      <div>
        <img src={iNoData} />
      </div>
      <span>ไม่มีรายการโอนเงิน</span>
    </td>
  </tr>
);

export class TransferItemsTable extends React.Component {
  render() {
    const ChargePercent = ({ type, charge }) => {
      if (type === 'percent') {
        return <Amount value={charge} />;
      } else {
        return '-';
      }
    }

    const ChargeCost = ({ type, charge }) => {
      if (type !== 'percent') {
        return <Amount value={charge} />;
      } else {
        return '-';
      }
    }

    const ChargePGPercent = ({ type, charge, gateway, item_type }) => {
      if ((gateway === 't2p' || gateway === 'bbl') && charge === 0) {
        return '-';
      } else if (gateway === '123' && item_type !== 'gateway_charge') {
        charge = 0; 
      }
      if (type === 'percent' || charge === 0) {
        return <Amount value={charge} />;
      } else {
        return '-';
      }
    }

    const ChargePGCost = ({ type, charge, gateway, item_type, hasGatewayChargeItem}) => {
      if (((gateway === 't2p' || gateway === 'bbl') && charge === 0)
        || (gateway === '123' && item_type !== 'gateway_charge')) {
        return '-';
      } else if (['2c2p', 'opn'].includes(gateway) && hasGatewayChargeItem && item_type !== 'gateway_charge') {
        charge = 0;
      }
      if (type !== 'percent') {
        return <Amount value={charge} />;
      } else {
        return '-';
      }
    }
    let payment = this.props.payment;
    let items = this.props.payment?.transfer_items || [];
    const sellerType = this.props.seller ? this.props.seller.type : '';
    let mapType = {
      'refunded': 'product'
    }
    const hasGatewayChargeItem = !!items.find(item => item.type == "gateway_charge")
    let rows = items.length ? items.map(item => {
      let type = item.type in mapType ? mapType[item.type] : item.type
      return (
        <tr key={item.id}>
          <td>{type}</td>
          <td className="text-right"><Amount value={(item.subtotal - (item.discount ?? 0))} /></td>
          <td className="text-right">
            { sellerType === 'channel' ? '-': (<ChargePercent type={item.service_charge_type} charge={item.service_charge} />) }
          </td>
          <td className="text-right">
            { sellerType === 'channel' ? '-': (<ChargeCost type={item.service_charge_type} charge={item.service_charge} />) }
          </td>
          <td className="text-right"><ChargePGPercent 
            type={payment.npay_charge_type} charge={payment.npay_charge} gateway={payment.gateway} item_type={item.type}/>
          </td>
          <td className="text-right"><ChargePGCost 
            type={payment.npay_charge_type} charge={payment.npay_charge} gateway={payment.gateway} item_type={item.type} hasGatewayChargeItem={hasGatewayChargeItem}/>
          </td>
          <td className="text-center">{item.status === 'requested' ? 'transferring' : item.status}</td>
          <td className="text-center">
            {moment(item.updated_at).format('YYYY-MM-DD')}
            <br />
            {moment(item.updated_at).format('HH:mm')}
          </td>
          <td className="text-center">{item.updated_by}</td>
        </tr>
      );
    }) : <EmptyRow />;

    return (
      <div className="payment-transfer-table">
        <Table>
          <thead>
            <tr>
              <th className="border-0">ประเภท</th>
              <th className="text-right border-0">ยอดชำระ</th>
              <th className="text-right border-0">ค่าบริการ BU(%)</th>
              <th className="text-right border-0">ค่าบริการ BU(Exclude vat and WHT)</th>
              <th className="text-right border-0">ค่าบริการ PG(%)</th>
              <th className="text-right border-0">ค่าบริการ PG</th>
              <th className="text-center border-0">สถานะ</th>
              <th className="text-center border-0">แก้ไขล่าสุด</th>
              <th className="text-center border-0">แก้ไขโดย</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
      </div>
    );
  }
}
