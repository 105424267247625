import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import moment from 'moment';
import { Card, CardBody, Label, Input, Row, Col } from 'reactstrap';
import { Nav, NavItem, NavLink } from 'reactstrap';
import 'react-table/react-table.css';
import ReactTable from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import PreTransferReport from './PreTransferReport';
import DatePicker from 'react-datepicker';
import CustomInputDatePicker from '../../../components/Input/DatePicker';
import Tabs from '../../../components/Tabs/Tabs';
import { config } from '../../../config';
import classnames from 'classnames';
import { Page } from '../../../components/Page';
import { Amount } from '../../../components/Field/Amount';
import NexterButton from '../../../components/Button/NexterButton';
import NexterInput from '../../../components/Input/NexterInput';
import Visibility from '../../../components/Visibility/Visibility';
import { SummaryTable } from '../SummaryTable';
import { SummaryInterestTable } from '../SummaryInterestTable';
import { AbsorbTransfersTable } from '../ChannelTable';
import VoidAction from '../../report/VoidAction';
import RefundAction from '../../report/RefundAction';
import { ChannelTable } from '../ChannelTable';
import { PartnerTable } from '../PartnerTable';
import { EmptyRow } from '../../../components/Table/EmptyRow';
class PreTransferManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pre_transferData: [],
    };
    this.onClickDetails = this.onClickDetails.bind(this);
    this.onClickBackToListTable = this.onClickBackToListTable.bind(this);
    this.renderTable = this.renderTable.bind(this);
  }
  componentDidMount() {
    this.fetchData();
  }
  componentDidMount() {
    this.props.seller.fetchSellersByStatus('approved');
  }
  componentWillUnmount() {
    this.props.payment.changeShowAllPayment(true);
  }

  fetchData() {
    this.props.transfer.getTransfer('created');
  }
  async onClickDetails(rowInfo) {
    if (rowInfo.payment && rowInfo.payment.id) {
      const paymentId = rowInfo.payment.id;
      await this.props.payment.getPaymentByUid(paymentId);
      this.props.payment.changeShowAllPayment(false);
    }
  }
  onClickBackToListTable() {
    this.props.transfer.changeTransferReport(null);
  }
  renderTable() {
    let { transfer, transfer_condition } = this.props.transfer.getData();
    let body = (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <table>
          <tbody>
            <EmptyRow colSpan={24} displayText={`ไม่พบข้อมูลเตรียมการโอนเงิน`} />
            <tr style={{ textAlign: 'center' }} colSpan={24}>
              <td>{`${!transfer_condition.seller_selected ? 'กรุณาเลือกผู้ขาย/ผู้รับเงิน' : ''}`}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
    if (transfer && transfer.to_seller && transfer.items.length) {
      body = (
        <>
          <Visibility isVisibility={transfer.to_seller.type === 'partner'}>
            <PartnerTable
              items={transfer.items}
              summary={transfer.summary}
              seller={transfer.to_seller}
              installment={transfer.installment}
              paymentMethod={transfer.channel_payment_method}
              onClickRow={this.onClickDetails}
            />
          </Visibility>
          <Visibility isVisibility={transfer.to_seller.type === 'channel'}>
            <ChannelTable
              items={transfer.items}
              onClickRow={this.onClickDetails}
              summary={transfer.summary}
              paymentMethod={transfer.channel_payment_method}
              partnerTransfers={transfer.partner_transfers}
            />
          </Visibility>
        </>
      );
    }
    return body;
  }
  render() {
    const { payment, loading, showAllPayment } = this.props.payment.getData();
    const { transfer } = this.props.transfer.getData();
    return (
      <Page loading={loading}>
        <Card className="no-pl-print">
          <Visibility isVisibility={showAllPayment}>
            <div className="d-none d-print-block"><h5>ผู้รับเงิน: {transfer?.to_seller?.name || '-'}</h5></div>
            <div className="transfer-table p2">{this.renderTable()}</div>
          </Visibility>
          <Visibility isVisibility={!showAllPayment}>
            <PreTransferReport />
          </Visibility>
        </Card>
        <Visibility isVisibility={!showAllPayment}>
          <Row style={{ marginTop: 10 }}>
            <Col md={6}>
              <Card className="p2">
                <VoidAction paymentInfo={payment} />
              </Card>
            </Col>
            <Col md={6}>
              <Card className="p2">
                <RefundAction paymentInfo={payment} />
              </Card>
            </Col>
          </Row>
        </Visibility>

        <Visibility isVisibility={showAllPayment && transfer.to_seller.type === 'partner' && transfer.items.length > 0}>
          <div className="page-break">
            <Row>
              <Col md={6} className="print-col-4 print-border">
                <Card className="p2 card-transfer">
                  <SummaryInterestTable summary={transfer.summary} seller={transfer.to_seller} created_at={transfer.created_at} />
                </Card>
              </Col>
              <Col md={6} className="print-col-4 print-offset-4 print-border">
                <Card className="p2 card-transfer">
                  <SummaryTable total={transfer.summary.transfer_amount} fee={transfer.summary.net_transfer_fee} />
                </Card>
              </Col>
            </Row>
          </div>
        </Visibility>
        <Visibility isVisibility={showAllPayment && transfer.to_seller.type === 'channel' && transfer.items.length > 0}>
          <div className="page-break">
            <Row style={{ marginTop: 10 }}>
              <Col md={6} className="print-col-4 print-border">
                <Card className="p2 card-transfer">
                  <AbsorbTransfersTable partnerTransfers={transfer.partner_transfers} />
                </Card>
              </Col>
              <Col md={6} className="print-col-4 print-offset-4 print-border">
                <Card className="p2 card-transfer">
                  <SummaryTable 
                    total={transfer.summary.transfer_amount} 
                    fee={transfer.summary.net_transfer_fee} 
                    channelFee={transfer.summary.channel_transfer_fee}
                    sellerFee={transfer.summary.partner_transfer_fee}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </Visibility>
      </Page>
    );
  }
}

export default inject('transfer', 'seller', 'payment')(observer(PreTransferManagement));
