import React from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CustomInputDatePicker from '../../../components/Input/DatePicker';
import NexterButton from '../../../components/Button/NexterButton';
import NexterInput from '../../../components/Input/NexterInput';
import 'react-datepicker/dist/react-datepicker.css';

const AutoTransferHistoryLogFilterTab = ({ channels = [], showChannels, filter = {}, onChange, onClear, onSubmit }) => {
  const { channelId, updatedStartDate, updatedEndDate } = filter;

  const channelOptions = channels.all.map(c => ({ value: c.id, label: c.name }));

  return (
    <Row className="filter-auto-transfer align-items-end px-2">
      {showChannels && (
        <Col xs={12} lg={4} xl={3} className="px-2">
          <Label>
            <strong>ค้นหา</strong>
          </Label>
          <FormGroup>
            <NexterInput
              type="select"
              className={'nexter-select-sm payment-channel-select z-index-6'}
              options={channelOptions}
              value={channelOptions.find(co => co.value === channelId) ?? ''}
              onChange={e => onChange('channelId', e.value)}
              placeholder="เลือก Channel"
            />
          </FormGroup>
        </Col>
      )}
      <Col xs={12} lg={8} xl={6} className="px-2">
        <Label>
          <strong>วันที่ Update</strong>
        </Label>
        <Row className="px-2">
          <Col xs={12} md={6} className="px-2">
            <FormGroup>
              <DatePicker
                popperClassName="z-index-6"
                customInput={
                  <CustomInputDatePicker size="sm" className="w-100" children={updatedStartDate ? null : <div className="flex-fill text-base text-capitalize text-secondary">Start Date</div>} />
                }
                locale="th"
                dateFormat="dd/MM/yyyy"
                selected={updatedStartDate}
                onChange={value => onChange('updatedStartDate', value)}
                selectsStart
                startDate={updatedStartDate}
                endDate={updatedEndDate}
                maxDate={updatedEndDate}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6} className="px-2">
            <FormGroup>
              <DatePicker
                popperClassName="z-index-6"
                customInput={
                  <CustomInputDatePicker size="sm" className="w-100" children={updatedEndDate ? null : <div className="flex-fill text-base text-capitalize text-secondary">End Date</div>} />
                }
                locale="th"
                dateFormat="dd/MM/yyyy"
                selected={updatedEndDate}
                onChange={value => onChange('updatedEndDate', value)}
                selectsEnd
                startDate={updatedStartDate}
                endDate={updatedEndDate}
                minDate={updatedStartDate}
              />
            </FormGroup>
          </Col>
        </Row>
      </Col>
      <Col lg={12} xl={showChannels ? 3 : 6} className="px-2 pb-3 d-flex flex-column flex-md-row justify-content-end" style={{ gap: 15 }}>
        <NexterButton size="sm" color="info" outline onClick={() => onClear()}>
          Clear
        </NexterButton>
        <NexterButton size="sm" color="dark" icon="fa fa-search" onClick={() => onSubmit()}>
          Search
        </NexterButton>
      </Col>
    </Row>
  );
};

export default AutoTransferHistoryLogFilterTab;
