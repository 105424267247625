import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { config } from '../config';
import { Container, Collapse, NavbarBrand, Nav, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Col, NavbarToggler } from 'reactstrap';
import Select from 'react-select';
import banner from '../assets/img/sfinpay-banner.png';
import logo from '../assets/img/sfinpay-logo.png';

const rootpath = config.web.rootpath;

class HeaderBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: 'transparent',
    };
    this.toggle = this.toggle.bind(this);
    // this.dropdownToggle = this.dropdownToggle.bind(this);
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    if (window.innerWidth >= 760) {
      this.openSidebar();
    }
  }
  componentDidMount() {
    let { loggedinUser } = this.props.user.getData();
    if (loggedinUser.type === 'super_admin' || loggedinUser.type === 'as_channel_admin') {
      this.props.channel.fetchChannelAll();
    }

    let channel = localStorage.getItem('channel');
    if (channel) {
      this.props.user.selectedChannel = JSON.parse(channel);
    }
  }
  resize() {
    // if (window.innerWidth >= 760) {
    //   this.openSidebar();
    // }
  }
  logout() {
    this.props.user.logout();
  }

  toggleDropdown() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  onChangeRole(selected) {
    this.props.user.switchAsChannel(selected);
  }
  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: 'transparent',
      });
    } else {
      this.setState({
        color: 'dark',
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  openSidebar() {
    document.documentElement.classList.toggle('nav-open');
    //  this.sidebarToggle.current.classList.toggle('toggled');
  }
  render() {
    let { loggedinUser, role, user_info } = this.props.user.getData();
    let channelOptions = [];

    if (loggedinUser.type === 'super_admin' || loggedinUser.type === 'as_channel_admin') {
      const { channels } = this.props.channel.getData();
      channelOptions = channels.all.map(item => ({
        value: item.uid,
        label: item.name,
      }));

      channelOptions.unshift({ value: 'backtosuperadmin', label: 'SUPER ADMIN' }, { value: null, label: null, isDisabled: true });
    }

    let currentRole = null;
    // Role 'as_channel_admin' is not defined in database for the new requirement
    if (loggedinUser.type === 'as_channel_admin') currentRole = 'As Channel Admin';
    // Get role name from database
    else
      role.forEach(obj => {
        if (loggedinUser.type === obj.role_code) currentRole = obj.role_name;
      });
    // In case that not able to get role from database
    if (currentRole === null)
      switch (loggedinUser.type) {
        case 'super_admin':
          currentRole = 'Super Admin';
          break;
        case 'ae_marketing':
          currentRole = 'AE Marketing';
          break;
        case 'biz_system':
          currentRole = 'BIZ System';
          break;
        case 'manager':
          currentRole = 'Manager';
          break;
        case 'channel_admin':
          currentRole = 'Channel Admin';
          break;
        case 'channel_user':
          currentRole = 'Channel User';
          break;
        case 'as_channel_admin':
          currentRole = 'As Channel Admin';
          break;
      }

    return (
      <Row id="header-container" className="no-gutters align-items-center no-print">
        <Col xs="auto">
          <a className="logo-link" href={`${config.web.rootpath}`}>
            <img src={banner} className="d-none d-md-inline logo-img" />
            <img src={logo} className="d-md-none logo-img" />
          </a>
        </Col>
        <Col className="pl-5 pr-3">
          {['channel_admin', 'channel_user'].includes(loggedinUser.type) && loggedinUser.channel && loggedinUser.channel.name && <span className="text-white shop-text">{loggedinUser.channel.name}</span>}
        </Col>
        {/*<Col xs="auto">*/}
        {/*  {(loggedinUser.type === 'super_admin' || loggedinUser.type === 'as_channel_admin') && (*/}
        {/*    <div id="admin-privilege">*/}
        {/*      <Select placeholder="เลือก channel..." value={this.props.user.selectedChannel} options={channelOptions} onChange={this.onChangeRole.bind(this)} />*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</Col>*/}
        <Col xs="auto" className="ml-auto">
          <Nav navbar>
            <Dropdown id="user-dropdown" tag="li" isOpen={this.state.dropdownOpen} toggle={e => this.toggleDropdown(e)}>
              <DropdownToggle id="user-toggle">
                <i className="far fa-user-circle mr-2" />
                <span>{loggedinUser.name}</span>
                <i className="fas fa-chevron-down ml-3" />
              </DropdownToggle>
              <DropdownMenu id="user-menu" right>
                <DropdownItem header className="px-3">
                  <p className="text-muted text-capitalize font-weight-light font-size-sm m-0">Role:</p>
                  <span className="text-capitalize font-weight-bold">{currentRole}</span>
                </DropdownItem>
                {user_info?.user_channels?.length > 1 && (
                  <>
                    <DropdownItem divider className="pb-1" />
                    <DropdownItem tag="a" href={`${config.web.rootpath}/switch-channel`} className="px-3">
                      <Row className="no-gutters justify-content-between">
                        <Col xs="auto">
                          <span className="font-weight-normal">Switch Channel</span>
                        </Col>
                        <Col xs="auto">
                          <i className="fa fa-sync-alt fa-rotate-90" />
                        </Col>
                      </Row>
                    </DropdownItem>
                  </>
                )}
                {/*<DropdownItem tag="a" href={`${rootpath}/users/profile`}>*/}
                {/*  <i className="far fa-user-circle" />*/}
                {/*  <span className="pl-2 font-weight-bold">Profile</span>*/}
                {/*</DropdownItem>*/}
                <DropdownItem divider className="border-0 pt-1" />
                <DropdownItem divider className="pb-1" />
                <DropdownItem className="px-3" onClick={this.logout.bind(this)}>
                  <Row className="no-gutters justify-content-between">
                    <Col xs="auto">
                      <span className="font-weight-normal">Sign Out</span>
                    </Col>
                    <Col xs="auto">
                      <i className="fa fa-sign-out-alt" />
                    </Col>
                  </Row>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Col>
      </Row>
    );
  }
}

export default inject('user', 'channel')(observer(HeaderBar));
