import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import moment from 'moment';
import numeral from 'numeral';
import { config } from '../../../config';
import { EmptyRow } from '../../../components/Table/EmptyRow';
import { formatAutoTransferScheduleDates } from '../../../utils/date';
import { AUTO_TRANSFER_SCHEDULE_TYPES as TYPES, AUTO_TRANSFER_SCHEDULE_DAYS as DAYS } from '../../../constants/transferConstants';

const nullData = <span className="data-is-null">-</span>;

const AutoTransferTable = ({ items = [], page = 1, size = 1, canViewDetail }) => {
  const getDayWithFormat = (type, days) => {
    switch (type) {
      case 'daily':
        return DAYS.everyday;
      case 'monthly':
        return DAYS.eom;
      default:
        return formatAutoTransferScheduleDates(days);
    }
  };

  const rows = items.map((item, index) => (
    <tr key={`${item.channel_id}-${item.gateway}`}>
      <td>{(page - 1) * size + index + 1}</td>
      <td style={{ minWidth: 180, maxWidth: 180 }}>{item.channel_name}</td>
      <td className="text-uppercase" style={{ minWidth: 100 }}>
        {item.gateway}
      </td>
      <td className="text-nowrap" style={{ minWidth: 180 }}>
        {item.updated_at ? moment(item.updated_at).format('DD/MM/YYYY HH:mm') : nullData}
      </td>
      <td style={{ minWidth: 100, maxWidth: 200 }}>{item.updated_by || nullData}</td>
      <td style={{ minWidth: 120 }}>{TYPES[item.schedule_type] || nullData}</td>
      <td style={{ minWidth: 130, maxWidth: 200 }}>{getDayWithFormat(item.schedule_type, item.schedule_day) || nullData}</td>
      <td style={{ minWidth: 120 }}>{item.min_amount ? numeral(item.min_amount || 0).format('0,0[.]00') : nullData}</td>
      <td className="text-uppercase td-status text-nowrap" style={{ minWidth: 120 }}>
        {!!item.status ? (
          <>
            <i className={`far fa-dot-circle ${item.status} mr-1`}></i>
            {item.status}
          </>
        ) : (
          nullData
        )}
      </td>
      <td className="pr-3">
        {canViewDetail && (
          <Link style={{ color: '#007bff' }} to={`${config.web.rootpath}/auto-transfers/detail/${item.channel_uid}/${item.gateway}`}>
            Edit
          </Link>
        )}
      </td>
    </tr>
  ));

  return (
    <Table className="table-auto-transfer">
      <thead>
        <tr>
          <th className="text-capitalize border-0">No.</th>
          <th className="text-capitalize border-0">Channel Name</th>
          <th className="text-capitalize border-0">Payment Gateway</th>
          <th className="text-capitalize border-0">Update date</th>
          <th className="text-capitalize border-0">Update by</th>
          <th className="text-capitalize border-0">ประเภทการโอน</th>
          <th className="text-capitalize border-0">วันที่โอน</th>
          <th className="text-capitalize border-0">ยอดขั้นต่ำ</th>
          <th className="text-capitalize border-0">สถานะ</th>
          <th className="text-capitalize border-0"></th>
        </tr>
      </thead>
      <tbody>{rows.length > 0 ? rows : <EmptyRow colSpan={9} />}</tbody>
    </Table>
  );
};

export default AutoTransferTable;
