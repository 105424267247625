import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import NexterButton from '../../components/Button/NexterButton';
import { Row, Col } from 'reactstrap';
import addDays from 'date-fns/addDays';
import Visibility from '../../components/Visibility/Visibility';
class RefundAction extends Component {
  componentDidMount() {
    let { paymentInfo } = this.props;
    let id = paymentInfo.id;
    if (id) {
      this.props.transfer.getTransferItemById(id);
    }
  }

  refund(payment) {
    this.props.payment.refundPayment(payment.id);
  }
  disabledBtn(paymentInfo) {
    const { method, status, transfer_status, payment_at } = paymentInfo;
    const payment_method_can = ['credit_card', 'installment', 'line', 'grabpay'];
    const now = new Date();
    const expire = addDays(new Date(payment_at), 30);
    if (payment_method_can.includes(method)) {
      if (status === 'settled' && now < expire && (transfer_status === '-' || transfer_status === 'pending')) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  render() {
    let { paymentInfo } = this.props;
    return (
      <Fragment>
        <Row>
          <Col>
            {' '}
            <h4 className="title" style={{ marginTop: '8px' }}>
              Refund
            </h4>
          </Col>
          <Col>
            <Visibility permission_code="payment:transaction_refund_api">
              <NexterButton className="no-print m-0 pull-right" size="sm" color="primary" outline onClick={this.refund.bind(this, paymentInfo)} disabled={this.disabledBtn(paymentInfo)}>
                Refund
              </NexterButton>
            </Visibility>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Visibility permission_code="payment:transaction_refund_api">
              <b>เงื่อนไขการ Refund</b>
              <br />
              <p className="mt-1">
                - ต้องชำระเงินด้วยบัตรเครดิต, Line Pay หรือ GrabPay
                <br />
                - สถานะต้องเป็น Settled เท่านั้น
                <br />
                - ยังไม่สร้างรายการโอนเงิน
                <br />- สามารถ Refund ได้ในวันถัดไปของวันที่ชำระเงิน และรายการต้องมีระยะเวลาไม่เกิน 1 เดือน
              </p>
            </Visibility>
            <Visibility permission_code="payment:transaction_refund_api" invisibility_msg>
              <p>
                <b>ท่านไม่มีสิทธิ์ดำเนินการ Refund</b>
              </p>
            </Visibility>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default inject('payment', 'transfer')(observer(RefundAction));
