import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// react plugin used to create charts
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import moment from 'moment'
import {
  // UncontrolledTooltip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Button,
  ButtonGroup
} from 'reactstrap';
import { Amount } from '../../components/Field/Amount';
import NexterButton from '../../components/Button/NexterButton'
import * as _ from 'lodash'
import SelectInterval from './components/SelectInterval'
import numeral from 'numeral';
class TotalPaymentChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_interval: 'month',
    }
  }
  async componentDidMount() {
    await this.fetchData()
  }
  async fetchData() {
    let { selected_interval } = this.state
    const data = await this.props.dashboard.fetchDashboardTotalPayment(selected_interval)
  }

  selectInterval(val) {
    this.setState({ selected_interval: val }, () => {
      this.fetchData()
      this.props.dashboard.setTotalPaymentInterval(val)
    })
  }

  render() {
    const { total_payment } = this.props.dashboard.getData()
    const { dataset, totalAmount } = total_payment
    const userStat = {
      options: {
        tooltips: {
          mode: 'label',
          intersect:false,
          bodyFontColor: '#161616',
          borderWidth:1,
         
          
       
          custom: function(tooltip) {
            
            tooltip.caretSize = 10,
            tooltip.backgroundColor = "#ffff",
            tooltip.titleFontColor = "#161616",
            tooltip.titleFontStyle = "bold",
            tooltip.bodyFontColor = "#161616",
            tooltip.borderColor='#7e84a3'
        },
          callbacks: {
            label: function (t, d) {
              let num = numeral(t.yLabel || 0).format('0,0.00');
              var yLabel = num.toLocaleString();
              return '฿' + yLabel;
            },
          },
        },
        plugins: {
          datalabels: {
            display: false,
            formatter: function (value, context) {
              let temA = Math.round(value).toString();
              let b = '';
              let c = 0;
              for (let i = temA.length; i > 0; i--) {
                if ((c % 3 === 0) && (c !== 0)) {
                  b = ',' + b;
                }
                b = temA.substring(i - 1, i) + b;
                c++;
              }
              return b;
            },
          },
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true,
          },
        },

        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              display: true,
              userCallback: function(label, index, labels) {
                return numeral(label).format('0,0.00')
              },
            },
            scaleLabel: {
              display: true,
              labelString: 'จำนวน (บาท)'
            }
            
          }]
        }
      },
    };

    return (
      <Card className="card-chart">
        <CardHeader>
          <div className="chart-header-row">
            <CardTitle className="chart-title " tag="h5">ยอดรวมที่ลูกค้าชำระ</CardTitle>
            <SelectInterval selectInterval={(val) => this.selectInterval(val)} />
          </div>

          <div className="chart-legend chart-title-light">
            ยอดสะสมทั้งหมด: <Amount value={totalAmount}> </Amount> บาท
          </div>

        </CardHeader>
        <CardBody>
          <div className="no-print">
            <Bar data={dataset} options={userStat.options} width={400} height={150} />
          </div>
        </CardBody>
      </Card>


    )
  }
}
export default inject('dashboard')(observer(TotalPaymentChart));
