import React from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col } from 'reactstrap';
import NexterInput from '../../components/Input/NexterInput';
import PropTypes from 'prop-types';

class AdminSelectWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_children: props.open || false,
    }
  }
  async componentDidMount() {
    let { loggedinUser } = this.props.user.getData();
    let user_type = loggedinUser.type;
    if (['super_admin', 'ae_marketing', 'biz_system', 'manager', 'as_channel_admin'].some(r => r === user_type)) {
      await this.props.channel.fetchChannelAll();
    }
    const { admin_channel_uid } = this.props.channel.getData();
    if (admin_channel_uid) {
      this.setState({ show_children: true });
    }
  }

  componentWillUnmount() {
    this.props.channel.changeAdminChannelUid('');
  }

  onChannelSelected(selectedOption, actionMeta) {
    const admin_channel_uid = selectedOption.value;
    this.props.channel.changeAdminChannelUid(admin_channel_uid);
    this.setState({ show_children: false }, () => {
      this.setState({ show_children: true });
      this.props.onChange && this.props.onChange(selectedOption, actionMeta);
    });
  }

  channelOptionList() {
    const { channels } = this.props.channel.getData();
    return channels.all
      .filter((channel) => channel.status === 'approved')
      .map(c => ({ value: c.uid, label: c.name }));
  }

  render() {
    // not use the wrapper.
    if (this.props.disabled) {
      return this.props.children;
    }

    // manual trigger.
    let _show_children = this.state.show_children;
    if (this.props.open) _show_children = this.props.open;
    
    const { admin_channel_uid } = this.props.channel.getData();
    const channelOptions = this.channelOptionList();
    const selectedChannel = channelOptions.find(co => co.value === admin_channel_uid) ?? '';
    return (
      <>
        <Row className="mb-4">
          <Col md={4}>
            <NexterInput
              type="select"
              className={'nexter-select-sm'}
              options={channelOptions}
              onChange={this.onChannelSelected.bind(this)}
              value={selectedChannel}
              placeholder="เลือก CHANNEL"
            />
          </Col>
        </Row>
        {_show_children && (
          <>
            {this.props.children}
          </>
        )}
      </>
    );
  }
}

AdminSelectWrapper.propTypes = {
  open: PropTypes.bool,
  disaabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default inject('channel', 'user')(observer(AdminSelectWrapper));
