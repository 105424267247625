import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import qs from 'query-string';
// import { Link } from 'react-router-dom';
// import { config } from '../../config';
import swal from 'sweetalert2';
import { Page } from '../../components/Page';
import { Row, Col, CardHeader, FormGroup, FormFeedback, Label, Form } from 'reactstrap';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';
import MultiSelect from '../../components/Forms/MultiSelect';
import { validation } from '../../utils/validation';

class CreateUserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role_id: null,
      channel_id: null,
      status: { value: 'active', label: 'Active' },
      setValue: {
        role_id: false,
        channel_id: false,
        status: false,
      },
      errors: {},
    };
  }

  async componentDidMount() {
    await this.props.user.fetchUserType();
    await this.props.user.getAllRole();
    await this.props.user.getAllChannel();
    this.props.user.editable = true;

    let { loggedinUser } = this.props.user.getData();
    if (loggedinUser.type === 'channel_admin') {
      let { role } = this.props.user.getData();
      const { id } = role.filter(el => el.role_code === 'channel_user')[0];
      this.props.user.changeUserDetail('role_id', id);
      this.props.user.changeUserDetail('channel_id', loggedinUser.channel.cid);
      this.props.user.changeUserDetail('channel_name', loggedinUser.channel.name);
      this.props.user.changeUserDetail('channel_ids', [loggedinUser.channel.cid]);
      this.props.user.getChannelByIds([loggedinUser.channel.cid]);
    }
    if (!this.props.user.user.type) {
      this.props.user.user.type = 'channel_user';
    }
  }

  componentWillUnmount() {
    this.props.user.editable_channel = false;
    this.props.user.editable = false;
    this.props.user.createable = true;
    this.props.user.nonSelectChannel = true;
    this.props.user.viewuser = [];
    this.props.user.clearUser();
  }

  changeConfig(key, value) {
    let tmpV = null;
    switch (key) {
      case 'role_id':
      case 'channel_id':
      case 'status':
        this.state[key] = value;
        tmpV = value.value;
        break;
      case 'channel_ids':
        tmpV = value;
        break;
      case 'tel':
        tmpV = value.target.value;
        break;
      default:
        tmpV = value.target.value;
    }
    this.validateInputs(key, tmpV);
    this.props.user.nonSelectChannel = true;
    this.props.user.changeUserDetail(key, tmpV);
    if (key === 'role_id') {
      let { role } = this.props.user.getData();
      role = role.filter(r => r.id === tmpV);
      this.props.user.changeUserDetail('type', role[0].role_code);
      if (role[0].role_code === 'channel_user' || role[0].role_code === 'channel_admin') {
        this.props.user.editable_channel = true;
      } else {
        this.props.user.editable_channel = false;
      }
      this.state.channel_id = null;
      this.props.user.changeUserDetail('channel_ids', []);

      if (role[0].role_code.indexOf('channel') === -1) {
        this.props.user.changeUserDetail('channel_id', null);
      } else {
        if (!this.props.user.user['channel_ids']?.length) {
          this.props.user.nonSelectChannel = false;
        } else {
          this.props.user.nonSelectChannel = true;
        }
      }
    }
    if (key === 'channel_ids') {
      this.props.user.user.channel_id = value[0];
    }
  }

  validateInputs(key = null, value = null) {
    const fieldsToCheck = key ? [key] : ['name', 'surname', 'role_id', 'channel_ids', 'tel', 'email', 'status'];
    const errors = this.state.errors;
    fieldsToCheck.forEach(field => (errors[field] = null));
    const { user, role } = this.props.user.getData();

    let channelGroup = role.filter(el => el.role_group === 'Channel').map(el => el.id);

    const requiredErrorMsgMapper = {
      name: 'กรุณากรอก First Name',
      surname: 'กรุณากรอก Last Name',
      role_id: 'กรุณาเลือก Role',
      status: 'กรุณาเลือก Status',
      channel_ids: 'กรุณาเลือก Channel',
      tel: 'กรุณากรอก Phone Number',
      email: 'กรุณากรอก E-Mail',
    };

    fieldsToCheck.forEach(field => {
      const data = key ? value : user[field];
      const defaultValidator = [undefined, null, ''].includes(data);
      switch (field) {
        case 'channel_ids': {
          let channelValidator = true;
          if (!channelGroup.includes(user.role_id)) channelValidator = false;
          if (channelValidator && !data?.length) errors[field] = requiredErrorMsgMapper[field];
          break;
        }
        case 'tel': {
          if (defaultValidator) {
            errors[field] = requiredErrorMsgMapper[field];
            break;
          }
          if (data[0] !== '0') {
            errors[field] = 'หมายเลขโทรศัพท์ต้องขึ้นต้นด้วย 0';
            break;
          }
          if (data.length < 9) {
            errors[field] = 'หมายเลขโทรศัพท์ต้องมีความยาวอย่างน้อย 9 หลัก';
            break;
          }
          if ((data.length === 9 && data[1] !== '2') || (data.length === 10 && data[1] === '2') || data[1] === '0') {
            errors[field] = 'รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง';
            break;
          }
          break;
        }
        case 'email': {
          if (defaultValidator) {
            errors[field] = requiredErrorMsgMapper[field];
            break;
          }
          if (!validation.email(data)) {
            errors[field] = 'รูปแบบของ E-mail ไม่ถูกต้อง';
            break;
          }
          break;
        }
        default:
          if (defaultValidator) errors[field] = requiredErrorMsgMapper[field];
      }
    });
    this.setState({ errors });
  }

  async saveConfig() {
    let canBut = true;
    let confBut = 'ยืนยัน';

    // Validate inputs
    this.validateInputs();
    // validate if email is already exist
    if (!(await this.props.user.checkUser('create'))) {
      const errors = this.state.errors;
      errors.email = 'อีเมลนี้ถูกใช้แล้ว';
      this.setState({ errors });
    }
    // Stop if the error exist
    if (
      Object.values(this.state.errors)
        .map(val => typeof val)
        .includes('string')
    )
      return;

    let result = await swal.fire({
      customClass: 'nexter-alert nexter-alert-user-manage',
      type: 'warning',
      title: 'ยืนยันการสร้างผู้ใช้งาน',
      showCancelButton: canBut,
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: confBut,
      reverseButtons: true,
    });
    if (result.value) {
      this.props.user.createUser();
      this.props.user.editable_channel = false;
      this.props.user.editable = false;
    }
  }

  getPreValue(value, options, field) {
    let result = null;
    options.forEach(obj => {
      if (Object.keys(obj).includes('options')) {
        obj.options.forEach(subObj => {
          if (subObj[field] === value) result = subObj;
        });
      } else {
        if (obj[field] === value) result = obj;
      }
    });
    return result;
  }

  goBack() {
    this.props.user.changeUserMgmtView('list');
    this.props.user.clearUser();
  }

  render() {
    let { user, role, channelss, channeldetail, users, loggedinUser } = this.props.user.getData();

    let roleBoxP8G = [];
    let roleBoxChannelG = [];
    role.forEach(el => {
      if (el.role_group === 'P8 Team') {
        roleBoxP8G.push({
          value: el.id,
          label: el.role_name.replace('_', ' '),
        });
      }
      if (el.role_group === 'Channel') {
        roleBoxChannelG.push({
          value: el.id,
          label: el.role_name.replace('_', ' '),
        });
      }
    });

    let groupedOptions = [
      {
        label: 'P8 Team',
        options: roleBoxP8G,
      },
      {
        label: 'Channel',
        options: roleBoxChannelG,
      },
    ];

    let channelBox = [];
    channelss
      .filter(b => b.status === 'approved')
      .forEach(el => {
        channelBox.push({
          value: el.id,
          label: el.name,
        });
      });

    // let channelMemberCount = users.list.filter(a => a.channel_id === user.channel_id && a.role.role_code === 'channel_user' && a.status === 'active').length;
    // let channelAdmin = users.list.filter(a => a.channel_id === user.channel_id && a.role.role_code === 'channel_admin');
    let roleDetail = role.filter(a => a.id === user.role_id);

    let statusOptions = [
      {
        value: 'active',
        label: 'Active',
      },
      {
        value: 'inactive',
        label: 'Inactive',
      },
    ];

    const preValueData = [
      { stateKey: 'role_id', valueKey: 'role_id', options: groupedOptions, fieldKey: 'value' },
      { stateKey: 'channel_id', valueKey: 'channel_name', options: channelBox, fieldKey: 'label' },
      { stateKey: 'status', valueKey: 'status', options: statusOptions, fieldKey: 'value' },
    ];

    preValueData.forEach(obj => {
      if (this.state[obj.stateKey] == null && !this.state.setValue[obj.stateKey]) {
        this.state[obj.stateKey] = this.getPreValue(user[obj.valueKey], obj.options, obj.fieldKey);
        if (this.state[obj.stateKey] != null) this.state.setValue[obj.stateKey] = true;
      }
    });

    return (
      <Page>
        <CardHeader>
          <Row>
            <Col>
              <div className="title-goback">
                <a onClick={this.goBack.bind(this)}>
                  <i className="back-icon fas fa-chevron-left my-auto mr-4" />
                </a>
                <span className="texthead-managementpage">CREATE USER</span>
              </div>
            </Col>
            <Col className="text-right p-0">
              <Col>
                <NexterButton className="mr-0 my-0" size="sm" icon="fa fa-edit" color="success" onClick={() => this.saveConfig()} outline>
                  Save
                </NexterButton>
              </Col>
            </Col>
          </Row>
        </CardHeader>
        <hr className="mt-0 mb-4" />
        <Form>
          <Row>
            <Col md={6}>
              <FormGroup className="required">
                <Label className="control-label text-user-info-h3">First Name</Label>
                <NexterInput value={user.name} onChange={this.changeConfig.bind(this, 'name')} invalid={!['', null, undefined].includes(this.state.errors.name)} />
                <FormFeedback>{this.state.errors.name}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="required">
                <Label className="control-label text-user-info-h3">Last Name</Label>
                <NexterInput value={user.surname} onChange={this.changeConfig.bind(this, 'surname')} invalid={!['', null, undefined].includes(this.state.errors.surname)} />
                <FormFeedback>{this.state.errors.surname}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="required">
                <Label className="control-label text-user-info-h3">เลือก Role</Label>
                <NexterInput
                  type="select"
                  placeholder="เลือก Role..."
                  value={this.state.role_id}
                  options={groupedOptions}
                  onChange={this.changeConfig.bind(this, 'role_id')}
                  disabled={loggedinUser.type === 'channel_admin' || loggedinUser.type === 'channel_user'}
                  invalid={!['', null, undefined].includes(this.state.errors.role_id)}
                />
                <FormFeedback>{this.state.errors.role_id}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="required">
                <Label
                  className={
                    !groupedOptions[0].options.map(obj => obj.label).includes(this.state.role_id === null ? this.state.role_id : this.state.role_id?.label) && this.state.role_id !== null
                      ? 'control-label text-user-info-h3'
                      : 'control-label text-user-info-h3-disable'
                  }
                >
                  เลือก Channel
                </Label>
                <MultiSelect
                  placeholder="เลือก CHANNEL"
                  options={channelBox}
                  value={user.channel_ids.map(String)}
                  onChange={this.changeConfig.bind(this, 'channel_ids')}
                  disabled={!this.props.user.editable_channel}
                  className={!['', null, undefined].includes(this.state.errors.channel_ids) ? 'is-invalid' : ''}
                />
                <span className="invalid-feedback">{this.state.errors.channel_ids}</span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="required">
                <Label className="control-label text-user-info-h3">Phone Number</Label>
                <NexterInput allow="tel" value={user.tel} onChange={this.changeConfig.bind(this, 'tel')} maxLength={10} invalid={!['', null, undefined].includes(this.state.errors.tel)} />
                <FormFeedback>{this.state.errors.tel}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="required">
                <Label className="control-label text-user-info-h3">E-Mail</Label>
                <NexterInput value={user.email} onChange={this.changeConfig.bind(this, 'email')} invalid={!['', null, undefined].includes(this.state.errors.email)} />
                <FormFeedback>{this.state.errors.email}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="required">
                <Label className="control-label text-user-info-h3">เลือก Status</Label>
                <NexterInput
                  type="select"
                  placeholder="เลือก Status..."
                  value={this.state.status}
                  options={statusOptions}
                  onChange={this.changeConfig.bind(this, 'status')}
                  invalid={!['', null, undefined].includes(this.state.errors.status)}
                />
                <FormFeedback>{this.state.errors.status}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={6}></Col>
          </Row>
        </Form>
        <hr className="mt-2 mb-0" />
        {/* {user.channel_id && (
            <>
              <hr className="mt-4 mb-0" />
              <Row className="descript-user-info">
                <Col xs="2">
                  <h1 className="texthead-managementpage">Channel Info</h1>
                </Col>
              </Row>
              <Row className="descript-user-info">
                <Col xs="2" className="text-user-info-h4">
                  Channel Name
                </Col>
                <Col xs="4" className="text-user-info-descript">
                  {channeldetail.name}
                </Col>
                <Col xs="2" className="text-user-info-h4">
                  Channel Admin
                </Col>
                <Col xs="4" className="text-user-info-descript">
                  {channelAdmin[0] ? channelAdmin[0].name : '-'}
                </Col>
              </Row>
              <Row className="descript-user-info">
                <Col xs="2" className="text-user-info-h4">
                  Link
                </Col>
                <Col xs="4" className="text-user-info-descript">
                  <Link to={`${config.web.rootpath}/channels/detail/${channeldetail.uid}`} className="user-info-link">
                    {channeldetail.uid}
                  </Link>
                </Col>
                <Col xs="2" className="text-user-info-h4">
                  User no.
                </Col>
                <Col xs="4" className="text-user-info-descript">
                  ({channelMemberCount}/5)
                </Col>
              </Row>
            </>
          )} */}
        {roleDetail[0] && (
          <>
            {/* <hr className="mt-4 mb-0" /> */}
            <Row className="descript-user-info">
              <Col xs="2">
                <h1 className="texthead-managementpage">Role info</h1>
              </Col>
            </Row>
            <Row className="descript-last-user-info">
              <Col xs="2">
                <h6 className="text-user-info-h4 text-capitalize">{roleDetail[0].role_name}</h6>
              </Col>
              <Col>
                <p className="text-user-info-descript">{roleDetail[0].role_description}</p>
              </Col>
            </Row>
          </>
        )}
      </Page>
    );
  }
}

export default inject('user')(observer(CreateUserPage));
