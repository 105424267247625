import React from 'react';
import { Row, Col, Alert } from 'reactstrap';
import NexterButton from '../../../components/Button/NexterButton';

const SellerDeleteStatusAlert = ({ status, rejectReason, canApprove, canReApprove, onApprove, onReject, onReApprove }) => {
  if (status === 'requested') {
    return <RequestedStatusAlert canManage={canApprove} onApprove={onApprove} onReject={onReject} />;
  } else if (status === 'approved') {
    return <ApprovedStatusAlert canManage={canReApprove} onReApprove={onReApprove} />;
  } else if (status === 'rejected' && rejectReason) {
    return <RejectedStatusAlert />;
  }
  return null;
};

const RequestedStatusAlert = ({ canManage, onApprove, onReject }) => (
  <Alert className="alert-warning-light">
    <Row className="align-items-center">
      <Col>
        <i className="fas fa-exclamation-circle"></i> <b>Pending for Delete</b>
      </Col>
      {canManage && (
        <Col className="text-right">
          <NexterButton color="dark" size="sm" icon="far fa-times-circle" onClick={() => onReject()}>
            <span style={{ textTransform: 'none' }}>Reject</span>
          </NexterButton>
          <NexterButton size="sm" icon="fas fa-check-circle" className="ml-3" onClick={() => onApprove()}>
            <span style={{ textTransform: 'none' }}>Approve for Delete</span>
          </NexterButton>
        </Col>
      )}
    </Row>
  </Alert>
);

const ApprovedStatusAlert = ({ canManage, onReApprove }) => (
  <Alert className="alert-danger-light">
    <Row className="align-items-center">
      <Col>
        <i className="fas fa-trash"></i> <b>Approving for Delete</b>
      </Col>
      {canManage && (
        <Col className="text-right">
          <NexterButton size="sm" icon="fas fa-undo-alt" className="ml-3" onClick={() => onReApprove()}>
            <span style={{ textTransform: 'none' }}>Re-approve Seller</span>
          </NexterButton>
        </Col>
      )}
    </Row>
  </Alert>
);

const RejectedStatusAlert = () => (
  <Alert className="alert-danger-light">
    <i className="fas fa-times-circle"></i> <b>Delete Rejected</b>
  </Alert>
);

export default SellerDeleteStatusAlert;
