import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import classNames from 'classnames';
import swal from 'sweetalert2';
import { config } from '../../config';
import { http } from '../../utils/http';
import { validation } from '../../utils/validation';
import { Card, CardBody, Row, Col, FormGroup, Label, FormText, FormFeedback } from 'reactstrap';

import { Page } from '../../components/Page';
// import ImagePicker from '../../components/Image/ImagePicker';
// import FilePicker from '../../components/File/FilePicker';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';
import LogoFile from '../../components/File/LogoFile';
import ContractPersonList from '../../components/ContractPerson/ContractPersonList';
import ApplicationFile from '../../components/File/ApplicationFile';
import { Link } from 'react-router-dom';
import { CheckBox } from '../../components/Input/CheckBox';

const INITIAL_INVALID_MESSAGE = {
  name: '',
  company_name: '',
  ebill_account: '',
  company_tax_id: '',
  app_file: '',
  ccpp_account: '',
  callback_path: '',
  webhook_path_payment: '',
  webhook_path_transfer: '',
  url_test: '',
  url_prod: '',
};

const WEBHOOK_TRIGGER_STATUS = {
  payment: {
    is_send_webhook_success: 'success',
    is_send_webhook_failed: 'failed',
    is_send_webhook_pending: 'pending',
    is_send_webhook_voided: 'voided',
    is_send_webhook_refunded: 'refunded',
  },
  transfer: {
    is_send_webhook_transfer_created: 'created',
    is_send_webhook_transfer_requested: 'requested',
    is_send_webhook_transfer_transferred: 'transferred',
  },
};

function basename(path) {
  if (path) {
    path = path.split('?')[0];
    path = path.split('/').reverse()[0];
    return path;
  }
  return '';
}

class ChannelSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // channel root state
      admin_channel_uid: '',
      mode: 'view',
      user_type: '',
      loading: false,
      token_btn_text: 'Copy',
      url_btn_text: 'Copy',
      has_changed: false,

      channel_uid: '',
      channel_name: '',
      channel_status: '', // created, pending, approved, rejected
      state_before_edit: null,

      // Input
      name: '',
      logo_file: '',
      logo_url: '',
      app_file: '',
      app_name: '',
      app_url: '',
      contract_persons: [],
      contract_type_options: [
        { label: 'BUSINESS', value: 'BA' },
        { label: 'TECH', value: 'IT' },
      ],
      callback_path: '',
      webhook_path_payment: '',
      webhook_path_transfer: '',
      url_test: '',
      url_prod: '',
      token: '',
      public_uid: '',
      payment_email: 'uncheck',

      // validation
      invalid_message: _.cloneDeep(INITIAL_INVALID_MESSAGE),
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    // parent will call these function.
    this.props.setSave(this.saveChannel.bind(this));
    this.props.setEdit(this.edit.bind(this));
    this.props.setCancelEdit(this.cancelEdit.bind(this));

    let { admin_channel_uid } = this.props.channel.getData();
    let { loggedinUser } = this.props.user.getData();
    let user_type = loggedinUser.type;
    this.setState({ admin_channel_uid, user_type }, async () => {
      // if (['as_channel_admin', 'channel_admin', 'channel_user'].some(r => r === user_type)) {
      await this.fetchChannelDetail();
      // }
    });
  }

  getSearchQueryString() {
    const { admin_channel_uid } = this.state;
    let params = {};
    if (admin_channel_uid) params['channel_uid'] = admin_channel_uid;
    let query = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');
    return query ? `?${query}` : '';
  }

  async fetchChannelDetail() {
    try {
      this.setState({ loading: true });

      // prevent file "change" status is invalid while edit many times.
      this.resetFileState();
      let query = this.getSearchQueryString();
      let url = `${config.npay.apiUrl}/channels/settings${query}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        let data = body.data;

        const send_webhook_payment = this.getWebhookFields('payment').reduce((obj, cur) => ({ ...obj, [cur]: data[cur] }), {});
        const send_webhook_transfer = this.getWebhookFields('transfer').reduce((obj, cur) => ({ ...obj, [cur]: data[cur] }), {});

        this.setState(
          state => {
            return {
              ..._.pick(data, ['name', 'logo_url', 'reject_reason', 'token', 'public_uid']),
              mode: 'view',
              channel_uid: data.uid,
              channel_name: data.name,
              channel_status: data.status,
              payment_email: data.payment_success_email ? 'checked' : 'uncheck',
              app_name: basename(data.file_ref_url).split('-').reverse()[0],
              logo_file: '',
              app_url: data.file_ref_url,
              contract_persons: data.channel_contract
                .filter(item => !item.is_obsolete)
                .map(item => {
                  return {
                    ..._.pick(item, ['id', 'name', 'email', 'phone']),
                    type: state.contract_type_options.find(option => option.value === item.type),
                  };
                }),
              callback_path: data.callback_path || '',
              webhook_path_payment: data.webhook_path || '',
              webhook_path_transfer: data.webhook_path_transfer || '',
              url_test: data.url_test || '',
              url_prod: data.url_prod || '',
              ...send_webhook_payment,
              ...send_webhook_transfer,
            };
          },
          () => {
            // prevent case old channel doesn't have any contract person.
            if (this.state.contract_persons.length === 0) this.addChannelContract();
            // change mode in parent
            this.props.onChangeParentMode && this.props.onChangeParentMode('view');
          }
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 100);
    }
  }

  async saveChannel() {
    if (this.validate()) {
      swal
        .fire({
          customClass: 'nexter-alert',
          type: 'warning',
          title: 'ยืนยันแก้ไขและบันทึกข้อมูล ?',
          showCancelButton: true,
          cancelButtonText: 'CANCEL',
          confirmButtonText: 'CONFIRM',
          showLoaderOnConfirm: true,
          reverseButtons: true,
          onOpen: () => swal.getConfirmButton().blur(),
          preConfirm: async () => {
            return await this.submitForm();
          },
          allowOutsideClick: () => !swal.isLoading(),
        })
        .then(result => {
          if (result.value) this.showSuccessAndRedirect();
        });
    } else {
      this.showErrorAlert('โปรดตรวจสอบข้อมูลอีกครั้ง');
    }
  }
  async submitForm() {
    const formData = this.convertDataToForm(); // submit or null
    if (!formData) {
      this.showErrorAlert('โปรดตรวจสอบข้อมูลอีกครั้ง');
      return false;
    }

    try {
      const url = `${config.npay.apiUrl}/channels/settings`;
      const response = await http.put(url, { body: formData });
      const [response_1, json] = await Promise.all([response, response.json()]);
      if (!response_1.ok) throw new Error(json.message);
      return true;
    } catch (error) {
      swal.update({
        customClass: 'nexter-alert nexter-alert-channel-manage',
        type: 'warning',
        text: '',
        showConfirmButton: false,
        cancelButtonText: 'CONTINUE',
        confirmButtonColor: '#e20a1a',
      });
      swal.showValidationMessage(`${error}`.replace('Error: ', ''));
    }
  }
  validate() {
    const { app_file, app_name, contract_persons } = this.state;

    let invalid_message = _.cloneDeep(INITIAL_INVALID_MESSAGE);
    let pass = true;
    if (_.isEmpty(app_file) && _.isEmpty(app_name)) {
      pass = false;
      invalid_message['app_file'] = 'กรุณาอัปโหลด Application Form';
    }
    const new_contract_persons = contract_persons.map(item => {
      let item_invalid_message = {
        name_invalid: '',
        email_invalid: '',
        phone_invalid: '',
        type_invalid: '',
      };
      if (_.isEmpty(item.name)) {
        pass = false;
        item_invalid_message['name_invalid'] = 'กรุณาระบุ ชื่อผู้ติดต่อ';
      }
      if (!validation.email(item.email)) {
        pass = false;
        item_invalid_message['email_invalid'] = 'กรุณาระบุ E-Mail ให้ถูกต้อง';
      }
      if (!validation.phone(item.phone)) {
        pass = false;
        item_invalid_message['phone_invalid'] = 'กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง';
      }
      if (_.isEmpty(item.type)) {
        pass = false;
        item_invalid_message['type_invalid'] = 'กรุณาระบุ ประเภทผู้ติดต่อ';
      }
      return { ...item, ...item_invalid_message };
    });
    this.setState({ invalid_message, contract_persons: new_contract_persons });
    return pass;
  }
  convertDataToForm() {
    let { admin_channel_uid, logo_file, logo_file_change, app_file, app_file_change, contract_persons, callback_path, webhook_path_payment, webhook_path_transfer, url_test, url_prod, payment_email } =
      this.state;

    try {
      contract_persons = contract_persons.map(item => ({ ...item, type: item.type.value }));
      const formData = new FormData();
      if (admin_channel_uid) formData.append('channel_uid', admin_channel_uid);
      formData.append('logo_file', logo_file);
      formData.append('logo_file_change', logo_file_change);
      formData.append('app_file', app_file);
      formData.append('app_file_change', app_file_change);
      formData.append('contract_persons', JSON.stringify(contract_persons));
      formData.append('callback_path', callback_path);
      formData.append('webhook_path', webhook_path_payment);
      formData.append('webhook_path_transfer', webhook_path_transfer);
      formData.append('url_test', url_test);
      formData.append('url_prod', url_prod);
      formData.append('payment_email', payment_email);

      const webhook_field = [...this.getWebhookFields('payment'), ...this.getWebhookFields('transfer')];
      for (const field of webhook_field) {
        formData.append(field, this.state[field] ? 1 : 0);
      }

      return formData;
    } catch (_) {
      console.error(_);
      return false;
    }
  }
  showSuccessAndRedirect(text = 'บันทึกข้อมูลสำเร็จ') {
    swal
      .fire({
        customClass: 'nexter-alert',
        type: 'success',
        title: 'Success!',
        text,
        confirmButtonText: 'CONTINUE',
      })
      .then(async () => {
        // redirect to channel list
        await this.fetchChannelDetail();
      });
  }
  showErrorAlert(text = '') {
    swal.fire({
      customClass: 'nexter-alert',
      type: 'error',
      title: 'Error!',
      text,
      confirmButtonText: 'TRY AGAIN',
    });
  }
  viewApplicationFile() {
    if (this.state.app_url) {
      window.open(this.state.app_url);
    }
  }
  /**
   * Prevent state "change" is invalid
   */
  resetFileState() {
    this.setState({
      logo_file: '',
      logo_file_change: '',
      app_file: '',
      app_file_change: '',
    });
  }
  copyToClipboard(text) {
    text && navigator.clipboard.writeText(text);
  }

  addChannelContract() {
    const contract_persons = this.state.contract_persons;
    const person = {
      name: '',
      email: '',
      phone: '',
      type: '',
      name_invalid: '',
      email_invalid: '',
      phone_invalid: '',
      type_invalid: '',
    };
    contract_persons.push(person);
    this.setState({ contract_persons });
  }
  removeChannelContract(i) {
    const contract_persons = this.state.contract_persons;
    if (contract_persons.length > 1) {
      contract_persons.splice(i, 1);
    }
    this.setState({ contract_persons, has_changed: true });
  }
  resetInvalidMessage(field) {
    const invalid_message = this.state.invalid_message;
    invalid_message[field] = '';
    this.setState({ invalid_message });
  }
  handleChange(event) {
    this.resetInvalidMessage(event.target.name);
    this.setState({ [event.target.name]: event.target.value, has_changed: true });
  }
  handleContractChange(i, event) {
    const contract_persons = this.state.contract_persons;
    contract_persons[i][event.target.name] = event.target.value;
    contract_persons[i][`${event.target.name}_invalid`] = '';
    this.setState({ contract_persons, has_changed: true });
  }
  handleContractTypeChange(type_selected, i) {
    const contract_persons = this.state.contract_persons;
    contract_persons[i]['type'] = type_selected;
    contract_persons[i]['type_invalid'] = '';
    this.setState({ contract_persons, has_changed: true });
  }
  handleAppForm(acceptedFiles) {
    if (acceptedFiles && acceptedFiles.length) {
      const app_file = acceptedFiles[0];
      this.resetInvalidMessage('app_file');
      this.setState({
        app_file,
        app_name: app_file.name,
        app_file_change: 'change',
        has_changed: true,
      });
    }
  }

  handleWebhookConfigChange = event => {
    this.setState({
      [event.target.name]: !this.state[event.target.name],
    });
  };

  async removeLogo() {
    const sa2Confirm = await swal.fire({
      customClass: 'nexter-alert nexter-alert-channel-manage',
      type: 'warning',
      title: 'Delete this item',
      text: 'Are you sure you want delete this item ?',
      showCancelButton: true,
      cancelButtonText: 'CANCEL',
      confirmButtonText: 'DELETE',
      onOpen: () => swal.getConfirmButton().blur(),
    });

    if (sa2Confirm.value) {
      this.setState({
        logo_url: '',
        logo_file: '',
        logo_file_change: 'change',
        has_changed: true,
      });
    }
  }
  handleLogo(acceptedFiles) {
    if (acceptedFiles && acceptedFiles.length) {
      const file = acceptedFiles[0];
      const objectUrl = URL.createObjectURL(file);
      this.setState({
        logo_file: file,
        logo_url: objectUrl,
        logo_file_change: 'change',
        has_changed: true,
      });
    }
  }
  handleCopy(text, btn_key) {
    this.copyToClipboard(text);
    const prevText = _.clone(this.state[btn_key]);
    this.setState({ [btn_key]: 'Copied' }, () => {
      setTimeout(() => {
        this.setState({ [btn_key]: prevText });
      }, 3000);
    });
  }
  edit() {
    this.setState({
      state_before_edit: _.cloneDeep(this.state),
      mode: 'edit',
      has_changed: false,
    });
  }
  cancelEdit() {
    this.setState({
      ..._.omit(this.state.state_before_edit, ['activeTab']), // prevent changing tab after cancel
      state_before_edit: null,
      mode: 'view',
    });
  }

  hasPermissionCode(permission_code) {
    const { user_rp } = this.props.user.getData();
    return user_rp.includes(permission_code);
  }

  getWebhookFields = type => {
    return Object.keys(WEBHOOK_TRIGGER_STATUS[type] || {});
  };

  getCheckboxWebhookConfiguration = type => {
    return this.getWebhookFields(type).map(field => ({
      field,
      label: WEBHOOK_TRIGGER_STATUS[type][field],
    }));
  };

  render() {
    const { loading, invalid_message, mode } = this.state;
    const disabled = !['create', 'edit'].find(str => mode === str);
    const disabledInfo = disabled || !this.hasPermissionCode('channel:edit');
    const disabledLogo = disabled || !this.hasPermissionCode('channel:change_logo');
    const disabledPaymentSuccessfulEmail = disabled || !this.hasPermissionCode('channel:set_payment_successful_email');
    let registerUrl = `${window.location.origin || config.npay.cmsUrl.replace('/admin', '')}/sellers/register?channel=${this.state.public_uid}&noauth=1` || '';
    const historyLogUrl = this.state.admin_channel_uid ? `${config.web.rootpath}/channels/${this.state.admin_channel_uid}/setting/history` : `${config.web.rootpath}/channel-setting/campaign/history`;

    return (
      <Page loading={loading}>
        <Card className="card-channelmanagement">
          <CardBody>
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="text-uppercase title-channel">CHANNEL INFORMATION</h6>
              <Link to={historyLogUrl}>
                <NexterButton className="my-0" size="sm" outline icon="fas fa-history">
                  History Log
                </NexterButton>
              </Link>
            </div>
            <hr />
            <Row>
              <Col lg={5}>
                <FormGroup>
                  <Label className="form-label">Channel Logo</Label>
                  <LogoFile
                    previewSrc={this.state.logo_url}
                    onRemove={this.removeLogo.bind(this)}
                    onChange={this.handleLogo.bind(this)}
                    disabled={disabledLogo}
                    usePermission={false}
                    height="162"
                    width="162"
                    inputProps={{
                      multiple: false,
                      accept: 'image/png, image/jpeg',
                    }}
                    dropZoneProps={{
                      maxSize: 1048576,
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="control-label form-label">Channel ID</Label>
                  <NexterInput size="sm" id="channel_uid" name="channel_uid" value={this.state.channel_uid} disabled={true} className="nexter-input-sm" />
                </FormGroup>
              </Col>
              <Col>
                <div className="checkbox-payment-wrapper p-2 mb-2">
                  <div>
                    <i className="far fa-envelope"></i> Get payment successful email
                  </div>
                  <div>
                    <CheckBox
                      id="payment_email"
                      name="payment_email"
                      value={this.state.payment_email === 'checked' ? 'uncheck' : 'checked'}
                      checked={this.state.payment_email === 'checked'}
                      disabled={disabledPaymentSuccessfulEmail}
                      onChange={this.handleChange}
                      defaultClassName="my-auto"
                    />
                  </div>
                </div>
                <FormGroup>
                  <div className="d-flex justify-content-between align-items-end mb-2">
                    <Label className="control-label form-label mb-0">API Token</Label>
                    <NexterButton size="sm" color="dark" icon="fa fa-copy" onClick={this.handleCopy.bind(this, this.state.token, 'token_btn_text')} className="">
                      {this.state.token_btn_text}
                    </NexterButton>
                  </div>
                  <NexterInput size="sm" type="textarea" id="token" name="token" value={this.state.token} disabled={true} style={{ height: 96, fontSize: 14 }} />
                </FormGroup>
                <FormGroup>
                  <div className="d-flex justify-content-between align-items-end mb-2">
                    <Label className="control-label form-label mb-0">Register Seller URL</Label>
                    <NexterButton size="sm" color="dark" icon="fa fa-copy" onClick={this.handleCopy.bind(this, registerUrl, 'url_btn_text')} className="">
                      {this.state.url_btn_text}
                    </NexterButton>
                  </div>
                  <NexterInput size="sm" type="textarea" id="register_url" name="register_url" value={registerUrl} disabled={true} style={{ height: 96, fontSize: 14 }} />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h6 className="text-uppercase title-channel">CONTACT PERSON</h6>
            <hr />
            <ContractPersonList
              items={this.state.contract_persons}
              typeOptions={this.state.contract_type_options}
              disabled={disabledInfo}
              onChange={this.handleContractChange.bind(this)}
              onSelect={this.handleContractTypeChange.bind(this)}
              onRemove={this.removeChannelContract.bind(this)}
              onAdd={this.addChannelContract.bind(this)}
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h6 className="title-channel">API Configuration</h6>
            <hr />
            <Row>
              <Col lg={12}>
                <FormGroup>
                  <Label className="control-label form-label">Callback Path :</Label>
                  <NexterInput size="sm" className="nexter-input-sm" id="callback_path" name="callback_path" value={this.state.callback_path} disabled={disabledInfo} onChange={this.handleChange} />
                  <FormText className="text-description">e.g. /callback (path only)</FormText>
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label className="control-label form-label">Payment & KYC Webhook Path :</Label>
                  <NexterInput
                    size="sm"
                    className="nexter-input-sm"
                    id="webhook_path_payment"
                    name="webhook_path_payment"
                    value={this.state.webhook_path_payment}
                    disabled={disabledInfo}
                    onChange={this.handleChange}
                  />
                  <FormText className="text-description">e.g. /webhook (path only)</FormText>
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label className="control-label form-label">Transfer Webhook Path :</Label>
                  <NexterInput
                    size="sm"
                    className="nexter-input-sm"
                    id="webhook_path_transfer"
                    name="webhook_path_transfer"
                    value={this.state.webhook_path_transfer}
                    disabled={disabledInfo}
                    onChange={this.handleChange}
                  />
                  <FormText className="text-description">e.g. /webhook (path only)</FormText>
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label className="control-label form-label">URL Test :</Label>
                  <NexterInput size="sm" className="nexter-input-sm" id="url_test" name="url_test" value={this.state.url_test} disabled={disabledInfo} onChange={this.handleChange} />
                  <FormText className="text-description">e.g. https://api.test-your-domain.com (https only)</FormText>
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label className="control-label form-label">URL Production :</Label>
                  <NexterInput size="sm" className="nexter-input-sm" id="url_prod" name="url_prod" value={this.state.url_prod} disabled={disabledInfo} onChange={this.handleChange} />
                  <FormText className="text-description">e.g. https://api.your-domain.com (https only)</FormText>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h6 className="text-uppercase title-channel">เอกสารผู้ขอใช้บริการ</h6>
            <hr />
            <Row>
              <Col lg={6}>
                <FormGroup className="required">
                  <Label className="control-label form-label">Application Form</Label>
                  <ApplicationFile
                    disabled={disabledInfo}
                    onDrop={this.handleAppForm.bind(this)}
                    onView={this.viewApplicationFile.bind(this)}
                    previewName={this.state.app_name}
                    feedbackMessage={invalid_message['app_file']}
                    inputProps={{
                      multiple: false,
                      accept: 'application/pdf, image/png, image/jpeg',
                    }}
                    dropZoneProps={{
                      maxSize: 5242880,
                    }}
                  />
                  <FormFeedback
                    className={classNames({
                      'd-block': invalid_message['app_file'],
                    })}
                  >
                    {invalid_message['app_file']}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h6 className="text-uppercase title-channel">Payment & KYC Webhook Configuration</h6>
            <hr />
            {this.getCheckboxWebhookConfiguration('payment').map(({ field, label }) => (
              <Row key={field}>
                <Col>
                  <CheckBox defaultClassName="text-capitalize" disabled={disabledInfo} label={label} name={field} checked={this.state[field]} onChange={this.handleWebhookConfigChange} />
                </Col>
              </Row>
            ))}
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h6 className="text-uppercase title-channel">Transfer Webhook Configuration</h6>
            <hr />
            {this.getCheckboxWebhookConfiguration('transfer').map(({ field, label }) => (
              <Row key={field}>
                <Col>
                  <CheckBox defaultClassName="text-capitalize" disabled={disabledInfo} label={label} name={field} checked={this.state[field]} onChange={this.handleWebhookConfigChange} />
                </Col>
              </Row>
            ))}
          </CardBody>
        </Card>
      </Page>
    );
  }
}

export default inject('channel', 'user')(observer(ChannelSetting));
