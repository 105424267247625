import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// react plugin used to create charts
import { Line, Doughnut, Bar, HorizontalBar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import {
  // UncontrolledTooltip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  ButtonGroup, Button
} from 'reactstrap';
import palette from 'google-palette'
import { Amount } from '../../components/Field/Amount';
import SelectInterval from './components/SelectInterval'
import * as _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral';
class GatewayChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_interval: 'month',
      totalAmount: 0,
      dataset: {
        labels: [],
        datasets: []
      }
    }
  }


  componentWillMount() {
   this.props.dashboard.fetchDashboardGatewayFee(this.state.interval)
  }
  // async fetchData() {
  //   const { start_date_filter, end_date_filter } = this.props.dashboard.getData();
  //   let { selected_interval, dataset } = this.state
  //   const { data,total_amount } = await this.props.dashboard.fetchDashboardGatewayFee(start_date_filter, end_date_filter, selected_interval)
  //   let mapData = data.map(item => {
  //     let date = moment(item.interval)
  //     let year = date.format('YYYY')
  //     let month = date.format('MMM')
  //     let label_show = month
  //     if (selected_interval === 'quarter') {
  //       const quarter = item.interval.split(':')
  //       year = quarter[1]
  //       label_show = quarter[0]
  //     }else if(selected_interval==='year'){
  //       label_show = year
  //     }
  //     return {
  //       ...item,
  //       year,
  //       month,
  //       label_show
  //     }
  //   })
  //   let groupByYear = _.groupBy(mapData, 'gateway')
  //   let labels = []
  //   let datasets = []
  //   for (const [label, data] of Object.entries(groupByYear)) {
  //     let y_axis = []
  //     let gatewayColor = []
  //     data.forEach(item => {
  //       labels.push(item.label_show)
  //       y_axis.push(item.total)
  //       gatewayColor.push(this.getColor(item.gateway))
  //     })
  //     datasets.push({
  //       label: label,
  //       data: y_axis,
  //       backgroundColor: gatewayColor,
  //     })
  //   }
  //   labels = _.uniq(labels)
  //   dataset = {
  //     labels,
  //     datasets,
  //   }
  //   this.setState({ dataset,totalAmount:total_amount })
  // }

  selectInterval(val) {
    this.setState({ selected_interval: val }, () => {
      this.props.dashboard.fetchDashboardGatewayFee(val)
      this.props.dashboard.setTotalGatewayInterval(val)
    })
  }

  render() {
    const { gateway } = this.props.dashboard.getData();
    const options = {
      tooltips: {
        mode: 'label',
        intersect:false,
        bodyFontColor: '#161616',
        borderWidth:1,

        custom: function(tooltip) {

          tooltip.caretSize = 10,
          tooltip.backgroundColor = "#ffff",
          tooltip.titleFontColor = "#161616",
          tooltip.titleFontStyle = "bold",
          tooltip.bodyFontColor = "#161616",
          tooltip.borderColor='#7e84a3'
        },

        callbacks: {
          label: function (t, d) {
            var dstLabel = d.datasets[t.datasetIndex].label;
            var yLabel = numeral(t.yLabel || 0).format('0,0.00');
            return dstLabel + ': ' + '฿' + yLabel.toLocaleString();
          },
          footer: (tooltipItems, data) => {
            let total = tooltipItems.reduce((a, e) => a + parseInt(e.yLabel || 0), 0);
            return 'Total: ' + '฿' + total.toLocaleString();
          }
        },
      },
      plugins: {
        datalabels: {
          display: false,
        },
      },
      scales: {
        yAxes: [{
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'จำนวน (บาท)'
          },
          ticks: {
            beginAtZero: true,
            userCallback: function(label, index, labels) {
                return numeral(label).format('0,0.00')
            },
          }
        }],
        xAxes: [{
          stacked: true,
          gridLines: {
            display: false
          },
        }]
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },


    }
    return (

      <Card className="card-chart">

        <CardHeader>
          <div className="chart-header-row">
            <CardTitle className="chart-title " tag="h5">ค่าบริการ Gateway แยกตาม Gateway (รวม vat)</CardTitle>
            <SelectInterval selectInterval={(val) => this.selectInterval(val)} />
          </div>
          <div className="chart-legend chart-title-light">
            ค่าบริการ Gateway ทั้งหมด : <Amount value={gateway.totalAmount}> </Amount> บาท
          </div>

        </CardHeader>
        <CardBody>
          <div className="no-print">
            <Bar
              data={gateway.dataset}
              width={400}
              height={150}
              options={options}
            />
          </div>
        </CardBody>
      </Card>


    )
  }
}
export default inject('dashboard')(observer(GatewayChart));
