import React, { Fragment } from 'react';
import { Table } from 'reactstrap';
import { config } from '../../config';
import { Amount } from '../../components/Field/Amount';

export const SummaryInterestTable = ({ summary, seller, className, created_at }) => {
  const refDate = created_at ? new Date(created_at) : new Date();
  const isCalculationR1 = refDate >= new Date(config.calculation.release1)
  let charge = 0;
  let isAbsorb = false;
  if (seller.service_charge_absorb) {
    isAbsorb = seller.service_charge_absorb;
  } else if(seller.channel_sellers && seller.channel_sellers.length > 0) {
    isAbsorb = seller.channel_sellers[0].service_charge_absorb
  }
  if(isAbsorb) {
    charge = summary.charge
  }
  let wht = (charge + summary.interest_net) * (config.tax.wht / 100) || 0;
  let net = (charge + summary.interest_net + summary.totaldiscount) - wht || 0;
  if (isCalculationR1) {
    net = (charge + summary.interest_net + summary.totaldiscount) || 0;
  }
  return (
    <div className={className}>
      {
        !isCalculationR1 ?
          (<h5 className="title">สรุปยอดยกเว้นค่าธรรมเนียม (คืนภายหลัง)</h5>)
          : (<h5 className="title">สรุปยอดค่าสินค้า (คืนภายหลัง)</h5>)
      }
      <hr/>
      <div className="table-reponsive table-scroll">
        <Table className="table-report">
          <thead>
            <tr>
              <th className="text-left" width="45%">รายการ</th>
              <th className="text-right" width="55%">จำนวนเงิน</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-left">ค่าบริการ Channel</td>
              <td className="text-right"><Amount value={charge} currencyUnit={true} /></td>
            </tr>
            <tr>
              <td className="text-left">ค่าดอกเบี้ยสุทธิ</td>
              <td className="text-right"><Amount value={summary.interest_net} currencyUnit={true} /></td>
            </tr>
            {
              !isCalculationR1 && (
                <tr>
                  <td className="text-left">WHT</td>
                  <td className="text-right"><Amount value={`-${wht}`} currencyUnit={true} /></td>
                </tr>
              )
            }
            <tr>
              <td className="text-left">ส่วนลด</td>
              <td className="text-right"><Amount value={summary.totaldiscount} currencyUnit={true} /></td>
            </tr>
            <tr className="bold border-top">
              <td className="text-left">โอนเงินภายหลังสุทธิ</td>
              <td className="text-right"><Amount value={net} currencyUnit={true} /></td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};
