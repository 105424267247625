import React, { useState } from 'react';
import { Label, Button, Col, Form, FormFeedback, FormGroup, Spinner, CustomInput, Row } from 'reactstrap';
import _ from 'lodash';
import swal from 'sweetalert2';
import classNames from 'classnames';
import { OPN_HOLDING_DAYS_SETTLED } from '../../../../constants/systemConstants';
import { PAYMENT_GATEWAYS } from '../../../../constants/paymentConstants';
import NexterInput from '../../../../components/Input/NexterInput';
import NexterButton from '../../../../components/Button/NexterButton';
import { Page } from '../../../../components/Page';

const INITIAL_INVALID_MESSAGE = {
  code: '',
  merchant_id: '',
  company_name: '',
  label: '',
  public_key: '',
  secret_key: '',
  opn_holding_settled: '',
};

const OPN_HOLDING_DAYS_ERR = `Please fill in at least ${OPN_HOLDING_DAYS_SETTLED.min} day and not more than ${OPN_HOLDING_DAYS_SETTLED.max} days.`;

function CreateOrUpdate(props) {
  const { gateway } = props;
  const [code, setCode] = useState(props.editChannel ? props.editChannel.code : '');
  const [merchant_id, setMerchantId] = useState(props.editChannel ? props.editChannel.merchant_id : '');
  const [company_name, setCompanyName] = useState(props.editChannel ? props.editChannel.company_name : '');
  const [label, setLabel] = useState(props.editChannel ? props.editChannel.label : '');
  const [public_key, setPublicKey] = useState(props.editChannel ? props.editChannel.public_key : '');
  const [secret_key, setSecretKey] = useState(props.editChannel ? props.editChannel.secret_key : '');
  const [opn_holding_settled, setOpnHoldingSettled] = useState(props.editChannel ? props.editChannel.opn_holding_settled : OPN_HOLDING_DAYS_SETTLED.min);
  const [active, setActive] = useState(props.editChannel ? props.editChannel.active : true);
  const [invalid_message, setInvalidMessage] = useState(_.cloneDeep(INITIAL_INVALID_MESSAGE));

  const resetForm = () => {
    setCode('');
    setMerchantId('');
    setCompanyName('');
    setLabel('');
    setPublicKey('');
    setSecretKey('');
    setOpnHoldingSettled(OPN_HOLDING_DAYS_SETTLED.min);
    setActive(true);
    setInvalidMessage(_.cloneDeep(INITIAL_INVALID_MESSAGE));
  };

  const validate = () => {
    let invalid_message = _.cloneDeep(INITIAL_INVALID_MESSAGE);
    let pass = true;
    const requiredErrorText = 'Please fill in.';

    if (_.isEmpty(code)) {
      pass = false;
      invalid_message['code'] = requiredErrorText;
    }
    if (gateway === PAYMENT_GATEWAYS.CCPP && _.isEmpty(merchant_id)) {
      pass = false;
      invalid_message['merchant_id'] = requiredErrorText;
    }
    if (_.isEmpty(company_name)) {
      pass = false;
      invalid_message['company_name'] = requiredErrorText;
    }
    if (_.isEmpty(label)) {
      pass = false;
      invalid_message['label'] = requiredErrorText;
    }
    if (gateway === PAYMENT_GATEWAYS.OPN && _.isEmpty(public_key)) {
      pass = false;
      invalid_message['public_key'] = requiredErrorText;
    }
    if (_.isEmpty(secret_key)) {
      pass = false;
      invalid_message['secret_key'] = requiredErrorText;
    }
    if (gateway === PAYMENT_GATEWAYS.OPN) {
      if (isNaN(opn_holding_settled)) {
        pass = false;
        invalid_message['opn_holding_settled'] = requiredErrorText;
      } else if (opn_holding_settled < OPN_HOLDING_DAYS_SETTLED.min || opn_holding_settled > OPN_HOLDING_DAYS_SETTLED.max) {
        pass = false;
        invalid_message['opn_holding_settled'] = OPN_HOLDING_DAYS_ERR;
      }
    }

    setInvalidMessage(invalid_message);
    return pass;
  };

  const onChnageOpnHoldinSettled = value => {
    let numberOfDays = parseInt(value);
    if (isNaN(numberOfDays)) {
      numberOfDays = '';
    }
    setOpnHoldingSettled(numberOfDays);
  };

  const create = async () => {
    if (gateway === PAYMENT_GATEWAYS.CCPP) {
      await props.channel.createChannelMID({ code, merchant_id, company_name, label, secret_key, active });
    } else if (gateway === PAYMENT_GATEWAYS.OPN) {
      await props.channel.createChannelMIDOpn({ code, company_name, label, secret_key, public_key, opn_holding_settled, active });
    }
    const { channels_mid_errors: errors } = props.channel.getData();
    if (errors.length > 0) {
      setResponseErrorMessages(errors);
    } else {
      resetForm();
      props.showList(true);
    }
  };

  const update = async () => {
    if (gateway === PAYMENT_GATEWAYS.CCPP) {
      await props.channel.updateChannelMID(props.editChannel.id, { code, merchant_id, company_name, label, secret_key, active });
    } else if (gateway === PAYMENT_GATEWAYS.OPN) {
      await props.channel.updateChannelMIDOpn(props.editChannel.id, { code, company_name, label, secret_key, public_key, opn_holding_settled, active });
    }
    const { channels_mid_errors: errors } = props.channel.getData();
    if (errors.length > 0) {
      setResponseErrorMessages(errors);
    } else {
      resetForm();
      props.showList(true);
    }
  };

  const setResponseErrorMessages = (errors = []) => {
    let invalidMessage = _.cloneDeep(invalid_message);
    invalidMessage.merchant_id = ''
    invalidMessage.code = ''
    for (const item of errors) {
      invalidMessage[item.field] = item.message;
    }
    setInvalidMessage(invalidMessage);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    const displayText = {
      text: props.isEdited ? `Do you want to update ${label} MID channel?` : 'Do you want to create a new MID channel?',
      btnText: props.isEdited ? 'UPDATE' : 'CREATE',
    };
    swal
      .fire({
        customClass: 'nexter-alert',
        type: 'warning',
        title: displayText.title,
        text: displayText.text,
        showCancelButton: true,
        cancelButtonText: 'CANCEL',
        confirmButtonText: displayText.btnText,
        reverseButtons: true,
      })
      .then(async result => {
        if (result.value) {
          if (props.isCreated) {
            return await create();
          }
          return await update();
        }
      });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h6 className="text-uppercase my-4 system-config-title">
            {props.isEdited ? 'EDIT' : 'CREATE'} MID Channel [{gateway}]
          </h6>
          <div>
            <NexterButton size="sm" color="secondary" onClick={() => props.onBack()} outline>
              CANCEL
            </NexterButton>
            <NexterButton size="sm" icon="fa fa-save" color="success" className="ml-3" onClick={handleSubmit} outline>
              SAVE
            </NexterButton>
          </div>
        </div>

        <Row>
          <Col md={6} sm={12} xs={12} className="mt-1">
            <FormGroup className="required">
              <Label className="control-label form-label">DISPLAY LABEL</Label>
              <NexterInput size="sm" id="name" name="name" value={label} onChange={e => setLabel(e.target.value)} invalid={invalid_message['label'] ? true : false} className="nexter-input-sm" />
              <FormFeedback>{invalid_message['label']}</FormFeedback>
            </FormGroup>
          </Col>

          <Col md={6} sm={12} xs={12} className="mt-1">
            <FormGroup className="required">
              <Label className="control-label form-label">COMPANY</Label>
              <NexterInput
                size="sm"
                id="company_name"
                name="company_name"
                value={company_name}
                onChange={e => setCompanyName(e.target.value)}
                invalid={invalid_message['company_name'] ? true : false}
                className="nexter-input-sm"
              />
              <FormFeedback>{invalid_message['company_name']}</FormFeedback>
            </FormGroup>
          </Col>

          <Col md={6} sm={12} xs={12} className="mt-1">
            <FormGroup className="required">
              <Label className="control-label form-label">CODE</Label>
              <NexterInput size="sm" id="code" name="code" value={code} onChange={e => setCode(e.target.value)} invalid={invalid_message['code'] ? true : false} className="nexter-input-sm" />
              <FormFeedback>{invalid_message['code']}</FormFeedback>
            </FormGroup>
          </Col>

          {gateway === PAYMENT_GATEWAYS.CCPP && (
            <Col md={6} sm={12} xs={12} className="mt-1">
              <FormGroup className="required">
                <Label className="control-label form-label">MERCHANT ID</Label>
                <NexterInput
                  size="sm"
                  id="merchant_id"
                  name="merchant_id"
                  value={merchant_id}
                  onChange={e => setMerchantId(e.target.value)}
                  invalid={invalid_message['merchant_id'] ? true : false}
                  className="nexter-input-sm"
                />
                <FormFeedback>{invalid_message['merchant_id']}</FormFeedback>
              </FormGroup>
            </Col>
          )}

          {gateway === PAYMENT_GATEWAYS.OPN && (
            <Col md={6} sm={12} xs={12} className="mt-1">
              <FormGroup className="required">
                <Label className="control-label form-label">PUBLIC KEY</Label>
                <NexterInput
                  size="sm"
                  id="public_key"
                  name="public_key"
                  value={public_key}
                  onChange={e => setPublicKey(e.target.value)}
                  invalid={invalid_message['public_key'] ? true : false}
                  className="nexter-input-sm"
                />
                <FormFeedback>{invalid_message['public_key']}</FormFeedback>
              </FormGroup>
            </Col>
          )}

          <Col md={gateway === PAYMENT_GATEWAYS.CCPP ? 12 : 6} sm={12} xs={12} className="mt-1">
            <FormGroup className="required">
              <Label className="control-label form-label">SECRET KEY</Label>
              <NexterInput
                size="sm"
                id="secret_key"
                name="secret_key"
                value={secret_key}
                onChange={e => setSecretKey(e.target.value)}
                invalid={invalid_message['secret_key'] ? true : false}
                className="nexter-input-sm"
              />
              <FormFeedback>{invalid_message['secret_key']}</FormFeedback>
            </FormGroup>
          </Col>

          {gateway === PAYMENT_GATEWAYS.OPN && (
            <Col md={6} sm={12} xs={12} className="mt-1">
              <FormGroup className="required">
                <Label className="control-label form-label">HOLDING DATE (Day)</Label>
                <NexterInput
                  size="sm"
                  id="opn_holding_settled"
                  name="opn_holding_settled"
                  allow="numeric"
                  value={opn_holding_settled}
                  onChange={e => onChnageOpnHoldinSettled(e.target.value)}
                  invalid={invalid_message['opn_holding_settled'] ? true : false}
                  className="nexter-input-sm"
                />
                <div className={classNames('text-xs mt-1', !invalid_message['opn_holding_settled'] ? 'text-secondary' : 'text-failed')}>
                  {invalid_message['opn_holding_settled'] || OPN_HOLDING_DAYS_ERR}
                </div>
              </FormGroup>
            </Col>
          )}

          <Col xs={12} className="mt-1">
            <FormGroup>
              <div className="d-flex">
                <Label className="control-label form-label mr-3">STATUS</Label>
                <CustomInput type="switch" id="active" name="active" value={active} checked={active} onChange={() => setActive(!active)} />
                <Label className="text-capitalize ml-2" style={active ? { color: '#007aff' } : { color: '#525252' }}>
                  {active ? 'active' : 'inactive'}
                </Label>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default CreateOrUpdate;
