import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Row, Col, Card, CardHeader, CardBody, FormGroup, FormText, FormFeedback, Label, Table } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { validation } from '../../utils/validation';

import { Page } from '../../components/Page';
import { Amount } from '../../components/Field/Amount';
import { CheckBox } from '../../components/Input/CheckBox';
import CustomInputDatePicker from '../../components/Input/DatePicker';
import Visibility from '../../components/Visibility/Visibility';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';
import MultiSelect from '../../components/Forms/MultiSelect';

import { th } from 'date-fns/locale';
import addDays from 'date-fns/addDays';
registerLocale('th', th);

class CreatePaymentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable_payment: false,
      sellerOptions: [],
      default_due_date: null,
      maximum_due_date: null,
    };
  }
  async componentDidMount() {
    await this.props.seller.fetchSellersByStatus('approved', undefined, { is_active: true });
    // NL-146 defect due date not correct
    const { default_due_date, maximum_due_date } = this.props.seller.getData();
    if (default_due_date) {
      const date = addDays(new Date(), default_due_date);
      this.props.payment.default_expire_at = date;
      this.props.payment.paymentInfo.expire_at = date;
    }

    // eslint-disable-next-line camelcase
    let { channelSellers } = this.props.seller.getData();
    let sellerOptions = [];
    for (let i = 0; i < channelSellers.length; i++) {
      const el = channelSellers[i];
      sellerOptions.push({
        value: el.id,
        label: channelSellers.filter(s => s.seller.name === el.seller.name).length > 1 ? `${el.seller.name} (${el.seller_code})` : el.seller.name,
      });
    }

    this.setState({ sellerOptions, default_due_date, maximum_due_date });
  }

  changeRefID(event) {
    let value = event.target.value.replace(/\s\s+/g, ' ');
    this.props.payment.changeRefID(value);
  }

  changeDescription(event) {
    this.props.payment.changeDescription(event.target.value);
  }

  changeCustomerName(event) {
    this.props.payment.changeCustomerName(event.target.value);
  }

  changePhone(event) {
    this.props.payment.changePhone(event.target.value);
  }

  changeEmail(event) {
    this.props.payment.changeEmail(event.target.value);
  }

  changeProductName(i, event) {
    this.props.payment.changeProductName(i, event.target.value);
  }

  changeProductCategory(i, event) {
    this.props.payment.changeProductCategory(i, event.target.value);
  }

  changeProductCode(i, event) {
    this.props.payment.changeProductCode(i, event.target.value);
  }

  changeProductPrice(i, event) {
    this.props.payment.changeProductPrice(i, event.target.value);
  }

  changeProductDiscount(i, event) {
    this.props.payment.changeProductDiscount(i, event.target.value);
  }

  changeProductSeller(i, event) {
    if (event) this.props.payment.changeProductSeller(i, event);
    else this.props.payment.changeProductSeller(i, null);
  }

  changeProductTypeShipping(i) {
    this.props.payment.changeProductTypeShipping(i);
  }

  changeExpireDate(date) {
    this.props.payment.changeExpireDate(date);
  }

  selectSeller(selected) {
    if (selected) this.props.payment.changeChannelSellerID(selected);
    else this.props.payment.changeChannelSellerID(null);
  }

  addProduct() {
    if (this.props.payment.paymentInfo.items.length < 30) {
      this.props.payment.paymentInfo.items.push({
        subtotal: 0,
        discount: 0,
        description: null,
        channel_seller_id: null,
        type_shipping: false,
      });
    }

    this.props.payment.paymentInfo.items = [...this.props.payment.paymentInfo.items];
  }

  removeProduct(i) {
    if (this.props.payment.paymentInfo.items.length > 1) {
      this.props.payment.paymentInfo.items.splice(i, 1);
      this.props.payment.paymentInfo.items = [...this.props.payment.paymentInfo.items];
    }
  }

  createPayment() {
    const { loggedinUser } = this.props.user.getData();
    this.setState({ disable_payment: true }, async () => {
      const res = await this.props.payment.createPayment(loggedinUser.channel);
      if (res) {
        this.disableBtn(!res);
      }
    });
  }

  disableBtn(val) {
    this.setState({ disable_payment: val });
  }

  validateItemPrice(value = '', name = 'ราคา') {
    if (value === '') {
      return { isValid: true };
    }
    if (isNaN(value)) {
      return { isValid: false, mesaage: `${name}ไม่ถูกต้อง` };
    }
    if (Math.floor(Number(value)) > 9999999999) {
      return { isValid: false, mesaage: `${name}ไม่สามารถกำหนดได้เกินกว่า 10 หลัก` };
    }
    if (value.includes('.') && `${value}`.split('.')[1].length > 2) {
      return { isValid: false, mesaage: 'ไม่สามารถระบุทศนิยมได้เกิน 2 ตำแหน่ง' };
    }
    if (Math.ceil(Number(value)) <= 0) {
      return { isValid: false, mesaage: `${name}ต้องมีค่ามากกว่า 0.00 บาท` };
    }
    return { isValid: true };
  }

  validateItemSubtotal(subtotal) {
    return this.validateItemPrice(subtotal, 'ยอดชำระ');
  }

  validateItemDiscount(discount, subtotal) {
    let result = this.validateItemPrice(discount, 'ส่วนลดคืน seller ');
    if (!result.isValid) return result;
    if (Number(subtotal) < Number(discount)) {
      return { isValid: false, mesaage: 'ไม่สามารถกรอกส่วนลดคืน seller มากกว่ายอดชำระ' };
    }
    return { isValid: true };
  }

  validatePaymentInfoItems(items = []) {
    let isValid = true;
    for (let item of items) {
      if (!this.validateItemSubtotal(item.subtotal || '').isValid) {
        isValid = false;
        break;
      }
      if (!this.validateItemDiscount(item.discount || '', item.subtotal || '').isValid) {
        isValid = false;
        break;
      }
    }
    return isValid;
  }

  render() {
    let { paymentInfo, loading } = this.props.payment.getData();
    let disabledSubmit = this.state.disable_payment || !this.validatePaymentInfoItems(paymentInfo.items);

    const totalPrice = paymentInfo.items.reduce((total, el) => {
      return total + (+Number(el.subtotal) - +Number(el.discount));
    }, 0);

    return (
      <Page loading={loading}>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <h1 className="text-quickpay-h1">QUICK PAY</h1>
            <Visibility permission_code="payment:create_quick_pay" invisibility_msg>
              <h5>ท่านไม่มีสิทธิ์ในการสร้าง Quick Pay</h5>
            </Visibility>
          </Col>
        </Row>
        <Visibility permission_code="payment:create_quick_pay">
          <Row>
            <Col md={{ size: 8, offset: 2 }}>
              <Card className="card-quickpay">
                <CardHeader>
                  <h1 className="text-quickpay-h2">สร้างรายการชำระเงิน</h1>
                </CardHeader>
                <CardBody>
                  <div className="create-payment">
                    <Row>
                      <Col md={6}>
                        <FormGroup className="required">
                          <Label className="control-label text-quickpay-h3">Reference ID</Label>
                          <NexterInput
                            allow="payment_refid"
                            id="pay_refid"
                            value={paymentInfo.ref_id}
                            invalid={!!paymentInfo.ref_id && paymentInfo.ref_id !== ' ' && !validation.paymentRefId(paymentInfo.ref_id)}
                            onChange={this.changeRefID.bind(this)}
                          />
                          <FormText className="text-description">เฉพาะภาษาอังกฤษและตัวเลข</FormText>
                          <FormFeedback className="font-weight-normal">โปรดกรอก Reference ID ให้ถูกต้อง</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="required">
                          <Label className="control-label text-quickpay-h3">ชำระภายในวันที่</Label>
                          <DatePicker
                            customInput={<CustomInputDatePicker />}
                            selected={paymentInfo.expire_at}
                            onChange={this.changeExpireDate.bind(this)}
                            locale="th"
                            dateFormat="dd/MM/yyyy"
                            minDate={addDays(new Date(), this.state.default_due_date ?? 3)}
                            maxDate={addDays(new Date(), this.state.maximum_due_date ?? 30)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormGroup className="required">
                      <Label className="control-label text-quickpay-h3">รายละเอียด</Label>
                      <NexterInput id="pay_detail" value={paymentInfo.description} onChange={this.changeDescription.bind(this)} />
                    </FormGroup>
                  </div>
                </CardBody>
              </Card>
              <Card className="card-quickpay">
                <CardHeader>
                  <h1 className="text-quickpay-h2">ข้อมูลผู้ซื้อ</h1>
                </CardHeader>
                <CardBody>
                  <div className="buyer-info">
                    <FormGroup className="required">
                      <Label className="control-label text-quickpay-h3">ชื่อ-นามสกุล</Label>
                      <NexterInput id="pay_name" value={paymentInfo.customer_name} onChange={this.changeCustomerName.bind(this)} />
                    </FormGroup>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="required">
                          <Label className="control-label text-quickpay-h3">เบอร์โทรศัพท์</Label>
                          <NexterInput
                            allow="tel"
                            id="pay_phone"
                            value={paymentInfo.phone}
                            onChange={this.changePhone.bind(this)}
                            pattern="[0-9]*"
                            invalid={(paymentInfo.phone[0] != '0' && paymentInfo.phone.length == 10) || paymentInfo.phone.length == 9}
                            maxLength={10}
                          />
                          <FormFeedback className="font-weight-normal">โปรดกรอกหมายเลขโทรศัพท์ให้ถูกต้อง</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="required">
                          <Label className="control-label text-quickpay-h3">อีเมล</Label>
                          <NexterInput id="pay_mail" value={paymentInfo.email} onChange={this.changeEmail.bind(this)} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
                <hr className="hr-main" />
                <CardHeader>
                  <h1 className="text-quickpay-h2">ผู้ขาย/ ผู้รับเงิน</h1>
                </CardHeader>
                <CardBody>
                  <div className="buyer-seller">
                    <FormGroup>
                      <Label className="control-label text-quickpay-h3">เลือกผู้รับเงิน</Label>
                      <MultiSelect
                        allowClear
                        showSearch
                        showArrow
                        labelInValue
                        mode={undefined}
                        listHeight={300}
                        className="quick-pay"
                        placeholder="เลือกผู้รับเงิน..."
                        options={this.state.sellerOptions}
                        value={paymentInfo.channel_seller_id || undefined}
                        onChange={this.selectSeller.bind(this)}
                        notFoundContent={<div className="not-found">No options</div>}
                        disabled={paymentInfo.items.some(el => !!el.channel_seller_id)}
                      />
                    </FormGroup>
                  </div>
                </CardBody>
              </Card>
              <Card className="card-quickpay">
                <CardHeader>
                  <h1 className="text-quickpay-h2">รายการสินค้า</h1>
                </CardHeader>

                <CardBody>
                  <div className="order-product">
                    {paymentInfo.items.map((el, i) => (
                      <div key={i}>
                        <Row>
                          <Col className="text-quickpay-h3">สินค้าที่ {i + 1}</Col>
                          <Col className="text-right">
                            <span className="text-center cursor-pointer" onClick={this.removeProduct.bind(this, i)}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path fill="#6f6f6f" d="M7 11v2h10v-2zm5-9a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.011 8.011 0 0 1-8 8z" />
                              </svg>
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <NexterInput className="input-name-product" value={el.description || ''} onChange={this.changeProductName.bind(this, i)} />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="buyer-seller">
                              <FormGroup>
                              <MultiSelect
                                allowClear
                                showSearch
                                showArrow
                                labelInValue
                                mode={undefined}
                                listHeight={300}
                                className="quick-pay"
                                placeholder="เลือกผู้รับเงิน..."
                                options={this.state.sellerOptions}
                                value={el.channel_seller_id || undefined}
                                onChange={this.changeProductSeller.bind(this, i)}
                                notFoundContent={<div className="not-found">No options</div>}
                                disabled={!!paymentInfo.channel_seller_id}
                              />
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>

                        <Table borderless>
                          <tbody>
                            <tr key={i}>
                              <td className="t-1">
                                <FormGroup>
                                  <Label className="control-label text-quickpay-h3">Product Category</Label>
                                  <NexterInput value={el.product_category || ''} onChange={this.changeProductCategory.bind(this, i)} />
                                </FormGroup>
                              </td>
                              <td className="t-2">
                                <FormGroup>
                                  <Label className="control-label text-quickpay-h3">Product Code</Label>
                                  <NexterInput value={el.product_code || ''} onChange={this.changeProductCode.bind(this, i)} />
                                </FormGroup>
                              </td>
                              <td className="t-3">
                                <FormGroup className="required position-relative">
                                  <Label className="control-label text-quickpay-h3">ยอดชำระ (บาท)</Label>
                                  <NexterInput
                                    allow="float"
                                    id={'item' + i}
                                    value={el.subtotal || ''}
                                    onChange={this.changeProductPrice.bind(this, i)}
                                    invalid={!this.validateItemSubtotal(el.subtotal || '').isValid}
                                  />
                                  <FormFeedback className="font-weight-normal position-absolute">
                                    {this.validateItemSubtotal(el.subtotal || '').mesaage}
                                  </FormFeedback>
                                </FormGroup>
                              </td>
                              <td className="t-4">
                                <FormGroup className="position-relative">
                                  <Label className="control-label text-quickpay-h3">ส่วนลดคืน Seller (บาท)</Label>
                                  <NexterInput
                                    allow="float"
                                    id={'discount' + i}
                                    value={el.discount || ''}
                                    onChange={this.changeProductDiscount.bind(this, i)}
                                    invalid={!this.validateItemDiscount(el.discount || '', el.subtotal || '').isValid}
                                  />
                                  <FormFeedback className="font-weight-normal position-absolute">
                                    {this.validateItemDiscount(el.discount || '', el.subtotal || '').mesaage}
                                  </FormFeedback>
                                </FormGroup>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <Row>
                          <Col>
                            <Label className="control-label text-quickpay-h3">ค่าขนส่ง</Label>
                            <CheckBox onChange={this.changeProductTypeShipping.bind(this, i)} checked={el.type_shipping} />
                          </Col>
                        </Row>
                        <hr className="hr-main" />
                      </div>
                    ))}
                    <Row>
                      <Col>
                        <NexterButton
                          className="button-add-product"
                          color="info"
                          size="sm"
                          icon="fas fa-plus"
                          onClick={this.addProduct.bind(this)}
                          outline
                          disabled={this.props.payment.paymentInfo.items.length == 30}
                        >
                          เพิ่มรายการสินค้า
                        </NexterButton>
                      </Col>
                      <Col>
                        {totalPrice >= 0 && (
                          <h1 className="text-total-price">
                            <Amount value={totalPrice} />
                          </h1>
                        )}
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <NexterButton className="button-quickpay-create" onClick={this.createPayment.bind(this)} disabled={disabledSubmit}>
                สร้างรายการชำระเงิน
              </NexterButton>
            </Col>
          </Row>
        </Visibility>
      </Page>
    );
  }
}

export default inject('payment', 'user', 'seller')(observer(CreatePaymentPage));
