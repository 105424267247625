import Compressor from 'compressorjs';

class File {
  compressImage(file) {
    // console.log('file', file);
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unused-vars
      try {
        new Compressor(file, {
          // maxHeight: maxHeight,
          quality: 0.8,
          success(result) {
            console.log('File -> compressImage() -> success -> result', result);
            resolve(result);
          },
          error(err) {
            console.log('File -> compressImage() -> error -> msg', err.message);
            reject(err);
          },
        });
      } catch (e) {
        reject(err);
      }
    });
  }
}

export const file = new File();
