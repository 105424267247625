import jwtDecode from 'jwt-decode';
import queryString from 'query-string';

import BaseStore from './BaseStore';
import swal from 'sweetalert2';

import { config } from '../config';
import { http } from '../utils/http';
import { validation } from '../utils/validation';

import localStorage from '../utils/localStorage';
import crypto from '../utils/crypto';
import _ from 'lodash';
import iTimerOff from '../assets/img/icon-timer-off.svg';

const INITIAL_USER_DATA = {
  name: '',
  surname: '',
  username: '',
  password: '',
  email: '',
  new_password: '',
  confirm_new_password: '',
  reset_password: false,
  reset_password_ref_id: null,
  password_expires_at: null,
  type: null,
  channel_uid: null,
  channel_id: null,
  channel_ids: [],
  tel: '',
  status: 'active',
  role_id: null,
};
export class UserStore extends BaseStore {
  constructor() {
    super();
    this.observable(this.getInitialData());
  }

  getInitialData() {
    return {
      loading: false,
      pagination: {
        page: 1,
        size: 10,
        total: 1,
      },
      editable: false,
      editable_channel: false,
      createable: true,
      nonSelectChannel: true,
      role: [],
      tuid: '',
      user_info: {},
      user_rp: [],
      user: _.cloneDeep(INITIAL_USER_DATA),
      channelss: [],
      channeldetail: {},
      channeldetails: [],
      viewuser: {},
      viewuser_keep: {},
      users: {
        list: [],
        channelRoleUsersInChannel: [],
      },
      user_channels: [],
      input_type: 'password',
      type_list: [],
      show_channel_options: false,
      validation: {
        type: true,
        channel: true,
        username: true,
        password: true,
        name: true,
        email: true,
      },
      loggedinUser: {},
      selectedChannel: null,
      logs: [],
      reserveEmail: [],
      userIdInChannel: [],
      filter: {
        channel_selected: {},
        user_name: '',
        channel_name: '',
        user_type: '',
        user_is_active: '',
        user_email: '',
        seller_search_personal_id: '',
        seller_account_status_selection: [],
        seller_kyc_status_selection: [],
      },
      selectedUser: null,
      userMgmtView: 'list',
      menu_perm: [],
      lockedLoginExpiredAt: this.getLockedLoginExpiredAt(),
      hasExpiredResetPassword: false,
      isFailedCurrentPassword: false,
      isFailedUsername: false,
    };
  }

  setLoading(val) {
    this.loading = val;
  }

  clearPagination() {
    let { pagination } = this.getInitialData();
    this.pagination = pagination;
  }

  changePagination(page) {
    this.pagination.page = page;
  }

  clearUsers() {
    let { users } = this.getInitialData();
    this.users = users;
  }

  clearSeller() {
    let { users } = this.getInitialData();
    this.users = users;
  }

  clearFilter() {
    let { filter } = this.getInitialData();
    this.filter = filter;
  }

  changeFilterUserName(name) {
    this.filter.user_name = name;
  }

  changeFilterUserCode(code) {
    this.filter.channel_name = code;
  }

  changeFilterUserType(type) {
    this.filter.user_type = type;
  }

  changeFilterUserAccountStatus(status) {
    this.filter.user_is_active = status;
  }

  changeFilterUserEmail(email) {
    this.filter.user_email = email;
  }

  getLockedLoginExpiredAt() {
    let expiryTime = parseInt(localStorage.getItem('locked_login_expired_at')) || 0;
    return expiryTime;
  }

  async getChannelRoleUsersInChannel() {
    try {
      this.loading = true;
      const { channel_id, role_id } = this.user;
      const params = {
        channel_id: channel_id,
        role_id: role_id,
        is_active: 'active',
      };
      let paramsString = Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      let url = `${config.npay.apiUrl}/users/filter?${paramsString}`;
      let response = await http.get(url);

      if (response.status === 200) {
        let body = await response.json();
        const { data } = body;
        this.users.channelRoleUsersInChannel = data;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      // console.log('err ::', err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async searchUserByFilterAsAdmin() {
    try {
      this.loading = true;
      let filter = this.filter;
      let { page, size } = this.pagination;
      const params = {
        name: filter.user_name.trim(),
        channel: filter.channel_name.trim(),
        type: filter.user_type.value || '',
        is_active: filter.user_is_active,
        email: filter.user_email.trim(),
        page: page,
        size: size,
      };
      let paramsString = Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      let url = `${config.npay.apiUrl}/users/filter?${paramsString}`;
      let response = await http.get(url);

      if (response.status === 200) {
        let body = await response.json();
        const { data, total } = body;
        this.users.list = data.filter(user => user.status !== user.email);
        this.pagination.total = total;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      // console.log('err ::', err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  changeConfig(key, val) {
    this.viewuser[key] = val;
  }

  changeUserDetail(key, val) {
    this.user[key] = val;
  }

  async updateUserProfile() {
    this.loading = true;
    try {
      // const changesets = require('diff-json');
      // const diff = changesets.diff(this.viewuser_keep, this.viewuser);
      let url = `${config.npay.apiUrl}/users/profile`;
      let body = this.viewuser;
      let response = await http.put(url, { body });
      let bodys = await response.json();
      if (response.status === 200) {
        swal
          .fire({
            customClass: 'nexter-alert nexter-alert-user-manage',
            type: 'success',
            title: 'อัปเดตสำเร็จ',
            text: 'อัปเดตข้อมูลผู้ใช้งาน ' + bodys.data.email + ' เรียบร้อยแล้ว',
            confirmButtonText: 'กลับไป',
          })
          .then(() => {
            window.location.href = `${config.web.rootpath}/user-management`;
          });
      } else {
        swal
          .fire({
            customClass: 'nexter-alert nexter-alert-user-manage',
            type: 'error',
            title: 'เกิดข้อผิดพลาด!',
            text: bodys.message,
            confirmButtonText: 'กลับไป',
          })
          .then(() => {
            this.userMgmtView = 'detail';
          });
      }
    } catch (err) {
      console.error(err);
    } finally {
      //  window.location.reload(false);
      this.getUsersByUId(this.tuid);
      setTimeout(() => {
        // this.loading = false;
      }, 100);
    }
  }

  formatPermissions(data) {
    const newPerm = { perm: {} };
    if (data.perm && data.perm.length > 0) {
      data.perm.forEach(obj => {
        const [module, operation] = obj.split(':');
        if (!newPerm.perm[module]) newPerm.perm[module] = [];
        newPerm.perm[module].push(operation);
      });
    } else {
      console.error('DATA PERMISSION IS NOT FOUND!');
      data.perm = {};
    }

    return Object.assign(data, newPerm);
  }

  setCurrentUser(payload) {
    const permission = this.formatPermissions(payload);
    this.loggedinUser = permission;
    this.menu_perm = permission.perm?.menu || [];
  }

  changeType(option) {
    let obj = { value: option.value, label: option.label };
    this.user.type = obj;
    this.show_channel_options = obj.value === 'channel_admin';
    this.user.channel_uid = obj.value === 'channel_admin' ? this.user.channel_uid : '';
  }

  changeChannel(option) {
    let obj = { value: option.value, label: option.label };
    this.user.channel_uid = obj;
  }

  changeUsername(username) {
    this.user.username = username;
    this.isFailedUsername = false;
  }

  changePassword(password) {
    this.user.password = password;
    this.isFailedCurrentPassword = false;
  }

  changeNewPassword(password) {
    this.user.new_password = password;
  }

  changeConfirmNewPassword(password) {
    this.user.confirm_new_password = password;
  }

  changeDisplayName(name) {
    this.user.name = name;
  }

  changeEmail(email) {
    this.user.email = email;
  }

  changeTel(tel) {
    this.user.tel = tel;
  }

  prepareToUpdate() {
    let { loggedinUser } = this.getData();
    this.user.username = loggedinUser.username;
    this.user.name = loggedinUser.name;
    this.user.email = loggedinUser.email;
  }

  setSelectedUser(uid) {
    this.selectedUser = uid;
  }

  changeUserMgmtView(view) {
    this.userMgmtView = view;
  }

  clearUser() {
    this.show_channel_options = false;
    this.user = _.cloneDeep(INITIAL_USER_DATA);
    this.validation = {
      type: true,
      channel: true,
      username: true,
      password: true,
      name: true,
      email: true,
    };
  }

  resetViewuser() {
    this.viewuser = Object.assign({}, this.viewuser_keep);
  }

  changeResetPasswordStatus() {
    this.user.reset_password = this.user.reset_password === false ? (this.user.reset_password = true) : (this.user.reset_password = false);
  }

  async logout(options = {}) {
    options.redirect = options.hasOwnProperty('redirect') ? options.redirect : true;
    options.redirectPath = options.hasOwnProperty('redirectPath') ? options.redirectPath : '';
    this.loading = true;
    let data = this.getData();
    let email = data.loggedinUser.email;
    try {
      let url = `${config.npay.apiUrl}/auth/logout`;
      let body = { email };
      const logoutResponse = await http.post(url, { body });
      console.log(logoutResponse);
    } catch (err) {
      console.error(err);
    } finally {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('channel');
      if (options.redirect) {
        let queryOpions = {};
        if (options.redirectPath) {
          queryOpions.redirect = options.redirectPath;
        }
        const redirectUrl = queryString.stringifyUrl({
          url: `${config.npay.cmsUrl}/login`,
          query: queryOpions,
        });
        window.location.href = redirectUrl;
      }
    }
  }

  async validateType() {
    if (!this.user.type || this.user.type === '') {
      this.validation.type = false;
    } else {
      this.validation.type = true;
    }
  }

  async validateChannel() {
    if (this.user.type && this.user.type.value === 'channel_admin') {
      if (!this.user.channel_uid || this.user.channel_uid === '') {
        this.validation.channel = false;
      } else {
        this.validation.channel = true;
      }
    }
  }

  async validateUsername() {
    if (!validation.username(this.user.username)) {
      this.validation.username = false;
    } else {
      this.validation.username = true;
    }
  }

  async validateDisplayName() {
    if (!this.user.name || this.user.name === '') {
      this.validation.name = false;
    } else {
      this.validation.name = true;
    }
  }

  async validateEmail() {
    if (!validation.email(this.user.email)) {
      this.validation.email = false;
    } else {
      this.validation.email = true;
    }
  }

  decodeToken() {
    let token = localStorage.getItem('token');
    return jwtDecode(token);
  }

  toggleShowHide() {
    this.input_type = this.input_type === 'text' ? 'password' : 'text';
  }

  async fetchUserType() {
    this.type_list = [
      { value: 'super_admin', label: 'Super Admin' },
      { value: 'channel_admin', label: 'Channel Admin' },
    ];
  }

  async handleOnLoginSuccess(data = {}, query = '') {
    if (data.has_expired_password) {
      swal
        .fire({
          customClass: 'nexter-alert nexter-alert-user-manage',
          imageUrl: iTimerOff,
          title: 'Your password has expired',
          text: 'Please press "RESET PASSWORD" to change it.',
          confirmButtonText: 'RESET PASSWORD',
        })
        .then(async result => {
          if (result.value) {
            localStorage.setItem('token', data.token);
            localStorage.setItem('refresh_token', data.refresh_token);
            await window.location.assign(`${config.web.rootpath}/reset-password`);
          }
        });
    } else {
      localStorage.setItem('token', data.token);
      localStorage.setItem('refresh_token', data.refresh_token);
      await window.location.assign(`${config.web.rootpath}/switch-channel?redirect=${query}`);
    }
  }

  async login() {
    this.loading = true;
    let data = this.getData();
    let username = data.user.username;
    let password = data.user.password;

    try {
      let url = `${config.npay.apiUrl}/auth/login`;
      let body = { username, password };

      let response = await http.post(url, { body });
      let json = await response.json();

      switch (response.status) {
        case 200:
          await this.handleOnLoginSuccess(json);
          break;
        case 429:
          this.lockedLoginExpiredAt = json.expired_at;
          localStorage.setItem('locked_login_expired_at', json.expired_at);
          swal.fire({
            customClass: 'nexter-alert nexter-alert-user-manage',
            type: 'warning',
            title: 'Warning!',
            text: json.message,
          });
          this.loading = false;
          break;
        default:
          if (json.code === 'B0021') {
            this.isFailedUsername = true;
          }
          swal.fire({
            customClass: 'nexter-alert nexter-alert-user-manage',
            type: 'error',
            text: json.message,
            confirmButtonText: json.code == 'A1000' ? 'TRY AGAIN' : 'OK',
          });
          this.loading = false;
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async loginWithRedirect(query) {
    this.loading = true;
    let data = this.getData();
    let username = data.user.username;
    let password = data.user.password;

    try {
      let url = `${config.npay.apiUrl}/auth/login`;
      let body = { username, password };

      let response = await http.post(url, { body });
      let json = await response.json();

      switch (response.status) {
        case 200:
          await this.handleOnLoginSuccess(json, query);
          break;
        default:
          await window.location.assign(`${config.web.rootpath}`);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async loginAD(code) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/ad/login`;
      let body = { code };
      let response = await http.post(url, { body });
      let json = await response.json();

      switch (response.status) {
        case 200:
          localStorage.setItem('token', json.token);
          localStorage.setItem('refresh_token', json.refresh_token);
          break;
        default:
          swal.fire({
            customClass: 'nexter-alert nexter-alert-user-manage',
            type: 'error',
            title: 'Error!',
            text: json.message,
            confirmButtonText: 'กลับไป',
          });
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async checkUser(mode) {
    try {
      const key = mode === 'create' ? 'user' : 'viewuser';
      let params = { email: encodeURIComponent(this[key].email) };
      if (mode === 'edit') {
        params = { ...params, except: this[key].id };
      }
      const paramsString = Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      const url = `${config.npay.apiUrl}/checkUser?${paramsString}`;
      const response = await http.get(url);

      if (response.status === 200) {
        const body = await response.json();
        const { count } = body;
        return count === 0;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      // console.log('err ::', err);
    }
  }

  async createUser() {
    this.loading = true;
    let { user } = this.getData();
    let password = user.email.split('@')[0];
    let body = user;
    body.username = user.username;
    body.password = password;
    body.channel_id = user.channel_id;
    body.email = user.email;
    body.tel = user.tel;
    body.role_id = user.role_id;
    body.username = user.email.split('@')[0];
    try {
      let url = `${config.npay.apiUrl}/users`;
      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        swal
          .fire({
            customClass: 'nexter-alert nexter-alert-user-manage',
            title: 'สร้างผู้ใช้งานสำเร็จ',
            text: 'สร้างผู้ใช้งาน ' + body.data.email + ' เรียบร้อยแล้ว',
            type: 'success',
            confirmButtonText: 'กลับไป',
          })
          .then(() => {
            this.clearUser();
            // window.location.href = `${config.web.rootpath}/user/profile/${body.data.uid}`;
            window.location.href = `${config.web.rootpath}/user-management`;
          });
      } else {
        let body = await response.json();
        this.editable = true;
        swal.fire({
          customClass: 'nexter-alert nexter-alert-user-manage',
          title: 'เกิดข้อผิดพลาด!',
          text: body.message,
          type: 'error',
          confirmButtonText: 'กลับไป',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async saveUserProfile() {
    try {
      this.loading = true;
      let { user } = this.getData();
      let url = `${config.npay.apiUrl}/users/profile`;
      let body = { user };

      let response = await http.put(url, { body });
      if (response.status === 200) {
        swal.fire({
          customClass: 'nexter-alert nexter-alert-user-manage',
          type: 'success',
          title: 'Success',
          text: 'แก้ไขข้อมูลสำเร็จ, ข้อมูลจะอัปเดตเมื่อล็อกอินครั้งถัดไป',
        });
      } else {
        swal.fire({
          customClass: 'nexter-alert nexter-alert-user-manage',
          type: 'error',
          title: 'Error',
          text: 'ไม่สามารถแก้ไขข้อมูลได้',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.getUsersByUId(this.tuid);
      this.loading = false;
    }
  }

  async updatePassword() {
    this.loading = true;
    let data = this.getData();
    let password = data.user.password;
    let newPassword = data.user.new_password;
    try {
      let url = `${config.npay.apiUrl}/users/password`;
      let updateData = { password, new_password: newPassword };
      let response = await http.put(url, { body: updateData });
      let body = await response.json();
      if (response.status === 200) {
        swal
          .fire({
            customClass: 'nexter-alert nexter-alert-user-manage',
            title: 'Success!',
            text: 'Password has been changed',
            type: 'success',
            onOpen: () => this.logout({ redirect: false }),
          })
          .then(() => window.location.assign(config.npay.cmsUrl));
      } else if (response.status === 429) {
        swal
          .fire({
            customClass: 'nexter-alert nexter-alert-user-manage',
            type: 'warning',
            title: 'Warning!',
            text: body.message,
            onOpen: () => this.logout({ redirect: false }),
          })
          .then(() => window.location.assign(config.npay.cmsUrl));
      } else {
        if (body.code === 'A1016') {
          this.isFailedCurrentPassword = true;
        }
        swal.fire({
          customClass: 'nexter-alert nexter-alert-user-manage',
          type: 'error',
          text: body.message,
          confirmButtonText: 'TRY AGAIN',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async updatePasswordByRefId() {
    this.loading = true;
    let data = this.getData();
    let refId = data.user.reset_password_ref_id;
    let username = data.user.username;
    let newPassword = data.user.new_password;
    let passwordExpiresAt = data.user.password_expires_at;
    try {
      let url = `${config.npay.apiUrl}/users/password/ref`;
      let updateData = { refid: refId, username, new_password: newPassword };
      let response = await http.put(url, { body: updateData });
      if (response.status === 200) {
        swal
          .fire({
            customClass: 'nexter-alert nexter-alert-user-manage',
            type: 'success',
            title: 'Success!',
            text: passwordExpiresAt ? 'Password has been changed' : 'Successfully submitted!',
            onOpen: () => this.logout({ redirect: false }),
          })
          .then(() => window.location.assign(config.npay.cmsUrl));
      } else {
        let body = await response.json();
        swal.fire({
          customClass: 'nexter-alert nexter-alert-user-manage',
          type: 'error',
          text: body.message,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async switchAsChannel(selectedChannel) {
    if (selectedChannel.value === 'backtosuperadmin') {
      this.switchToSuperAdmin();
    } else {
      let url = `${config.npay.apiUrl}/admin/switchaschannel/${selectedChannel.value}/${selectedChannel.label}`;
      let response = await http.get(url);

      if (response.status === 200) {
        let body = await response.json();

        this.selectedChannel = selectedChannel;
        localStorage.setItem('channel', JSON.stringify(selectedChannel));
        localStorage.setItem('token', body.token);

        let payload = jwtDecode(body.token);
        this.loggedinUser = this.formatPermissions(payload);

        window.location.href = `${config.web.rootpath}`;
      }
    }
  }

  async switchToSuperAdmin() {
    let url = `${config.npay.apiUrl}/admin/switchassuper`;
    let response = await http.get(url);

    if (response.status === 200) {
      let body = await response.json();

      this.selectedChannel = null;
      localStorage.removeItem('channel');
      localStorage.setItem('token', body.token);

      let payload = jwtDecode(body.token);
      this.loggedinUser = this.formatPermissions(payload);

      window.location.href = `${config.web.rootpath}`;
    }
  }

  async sendResetPasswordEmail() {
    if (this.user.email === '') {
      this.validation.email = false;
      swal.fire({
        customClass: 'nexter-alert nexter-alert-user-manage',
        type: 'error',
        title: 'ERROR!',
        text: 'Please enter username or email',
      });
      return;
    }
    this.loading = true;
    try {
      let url = `${config.npay.apiUrl}/users/password/mail`;
      let response = await http.put(url, { body: this.user.email });
      let body = await response.json();
      if (response.status === 200) {
        swal
          .fire({
            customClass: 'nexter-alert nexter-alert-user-manage',
            type: 'success',
            text: 'Successfully submitted, Please check your Mail box (Junk)',
          })
          .then(() => this.logout());
      } else {
        swal.fire({
          customClass: 'nexter-alert nexter-alert-user-manage',
          type: 'error',
          title: 'ERROR!',
          text: body.message,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async getUsers() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/users`;
      let response = await http.get(url);

      if (response.status === 200) {
        let body = await response.json();
        this.users.list = body.data.filter(user => user.status !== 'deleted');
        body.data
          .filter(user => user.status !== 'deleted')
          .map(user => {
            if (this.viewuser.email !== user.email) {
              this.reserveEmail.push(user.email);
            }
          });
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async getUsersByChannel(channelId) {
    try {
      this.loading = true;
      let filter = this.filter;
      let { page, size } = this.pagination;
      const params = {
        name: filter.user_name.trim(),
        channel: filter.channel_name.trim(),
        type: filter.user_type.value || '',
        is_active: filter.user_is_active,
        email: filter.user_email.trim(),
        page: page,
        size: size,
      };
      let paramsString = Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      let url = `${config.npay.apiUrl}/usersbychannel/${channelId}?${paramsString}`;
      let response = await http.get(url);

      if (response.status === 200) {
        let body = await response.json();
        const { data, total } = body;
        this.users.list = data.filter(user => user.status !== user.email);
        this.pagination.total = total;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      // console.log('err ::', err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async getUsersByUId(uid) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/usersbyuid/${uid}`;
      let response = await http.get(url);
      this.tuid = uid;
      if (response.status === 200) {
        const body = await response.json();
        this.viewuser = body.data;
        this.viewuser_keep = body.data;
        if (body.data.channel_id) {
          this.getChannelByIds(body.data.channel_ids);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async getUsersById(id) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/users/${id}`;
      let response = await http.get(url);
      if (response.status === 200) {
        const body = await response.json();
        this.user.password_expires_at = body.data?.password_expires_at;
        this.loggedinUser.email = body.data?.email;
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async deleteUser(id) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/users/${id}`;
      let response = await http.delete(url);
      if (response.status === 200) {
        swal
          .fire({
            customClass: 'nexter-alert nexter-alert-user-manage',
            type: 'success',
            title: 'Success',
            text: 'ลบผู้ใช้งานสำเร็จ',
          })
          .then(() => {
            window.location.href = `${config.web.rootpath}/user-management`;
          });
      } else {
        let body = await response.json();
        swal.fire({
          customClass: 'nexter-alert nexter-alert-user-manage',
          type: 'error',
          title: 'Error',
          text: body.message,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async ActiveUser(id, status) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/users/status`;
      let body = { id: id, status: status };
      let response = await http.put(url, { body });
      if (response.status === 200) {
        swal
          .fire({
            customClass: 'nexter-alert nexter-alert-user-manage',
            type: 'success',
            title: 'อัปเดตสถานะสำเร็จ',
            confirmButtonText: 'กลับไป',
          })
          .then(() => {
            window.location.href = `${config.web.rootpath}/user-management`;
          });
      } else {
        let body = await response.json();
        swal.fire({
          customClass: 'nexter-alert nexter-alert-user-manage',
          type: 'error',
          title: body.message,
          confirmButtonText: 'กลับไป',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async getHistoryLogs() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/historylogs`;
      let body = {
        parent: 'user',
        parent_id: null,
        entity: ['users'],
      };

      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        this.logs = body.data || [];
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
    }
  }

  async getAllRole() {
    try {
      // this.loading = true;
      let url = `${config.npay.apiUrl}/users/getallrole`;
      let response = await http.get(url);

      if (response.status === 200) {
        const body = await response.json();
        this.role = body.data;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
      // this.loading = false;
    }
  }

  async getUserRole() {
    try {
      // this.loading = true;
      let url = `${config.npay.apiUrl}/users/role`;
      let response = await http.get(url);

      if (response.status === 200) {
        const body = await response.json();
        this.user_info = body.data;
        this.user_rp = body.data.role?.role_permission.map(item => item.permission_code);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
      // this.loading = false;
    }
  }

  async getAllChannel() {
    try {
      // this.loading = true;
      let url = `${config.npay.apiUrl}/users/getchannellist`;
      let response = await http.get(url);

      if (response.status === 200) {
        const body = await response.json();
        this.channelss = body.data;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
      // this.loading = false;
    }
  }

  async getChannelById(id) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/channelsbyid/${id}`;
      let response = await http.get(url);
      if (response.status === 200) {
        const body = await response.json();
        this.channeldetail = body.data;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
      this.loading = false;
    }
  }

  async getChannelByIds(ids = []) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/admin/channelsbyids`;
      let response = await http.post(url, { body: { ids: ids.map(Number) } });
      if (response.status === 200) {
        const body = await response.json();
        this.channeldetails = body.data;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 100);
      this.loading = false;
    }
  }

  async getUsersIdInCHannel(channelId) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/channels/userinchannel/${channelId}`;
      let response = await http.get(url);

      if (response.status === 200) {
        let body = await response.json();
        this.userIdInChannel = body.data;
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async getUserByRefId(refId) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/users/ref/${refId}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.user.reset_password_ref_id = refId;
        this.user.username = body.data?.username;
        this.user.password_expires_at = body.data?.password_expires_at;
        this.loggedinUser.email = body.data?.email;
      } else {
        let body = await response.json();
        if (body.code === 'A1018') {
          this.hasExpiredResetPassword = true;
          swal.fire({
            customClass: 'nexter-alert nexter-alert-user-manage',
            imageUrl: iTimerOff,
            text: 'Link หมดอายุแล้ว กรุณากดปุ่ม "Resend" เพื่อขอ Link สำหรับการตั้งค่ารหัสผ่านใหม่อีกครั้ง',
            confirmButtonText: 'RESEND',
            allowOutsideClick: false,
            preConfirm: async () => {
              await this.logout({ redirect: false });
              window.location.assign(`${config.web.rootpath}/forgot-password`);
              return new Promise(() => {});
            },
          });
        } else {
          swal.fire({
            customClass: 'nexter-alert nexter-alert-user-manage',
            type: 'error',
            text: body.message,
            allowOutsideClick: false,
            preConfirm: () => {
              window.location.assign(`${config.web.rootpath}/login?logout=true`);
              return new Promise(() => {});
            },
          });
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async getUserChannels({ onSuccess }) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/users/channels`;
      let response = await http.get(url);
      if (response.status === 200) {
        const body = await response.json();
        const userChannels = body?.data || [];
        this.user_channels = userChannels;
        onSuccess && onSuccess(userChannels)
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async switchChannelAndRefreshToken(id) {
    try {
      this.loading = true;
      const url = `${config.npay.apiUrl}/users/switch-channel`;
      await http.put(url, { body: { channel_id: id } });
      // refresh token
      const refreshTokenUrl = `${config.npay.apiUrl}/auth/refresh-token`;
      const refreshToken = localStorage.getItem('refresh_token');
      const accessToken = localStorage.getItem('token');
      let resRefreshToeken = await http.post(refreshTokenUrl, {
        body: { refresh_token: refreshToken, access_token: accessToken },
      });
      let body = await resRefreshToeken.json();
      if (resRefreshToeken.status === 200) {
        localStorage.setItem('refresh_token', body.refresh_token);
        localStorage.setItem('token', body.access_token);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }
}
