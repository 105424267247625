import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Container, Card, CardBody, CardTitle, Row, Col, Button, FormGroup, FormFeedback, Label, Input, FormText, InputGroup, InputGroupAddon, CustomInput } from 'reactstrap';
import { config } from '../../config';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';
import FileUpload from '../../components/File/FileUpload';

class SellerShareholder extends Component {
  render() {
    const { items, onChange, onAction } = this.props;
    return (
      <Fragment>
        {items && items.map((el, i) => (
          <Fragment key={i}>
            <div className="shareholder-container mt-3">
              <div className="shareholder-header">
                <h6>ผู้ขอถือหุ้น รายที่ {i + 1}</h6>
              </div>
              <div className="shareholder-content">
                <Row>
                  <Col>
                    <FormGroup>
                      <Label className="control-label form-label">เลขประจำตัวประชาชน/นิติบุคคล</Label>
                      <NexterInput
                        allow="numeric"
                        size="sm"
                        value={el.tax_id}
                        maxLength={13}
                        onChange={(e) => onChange(`kyc_shareholders[${i}].tax_id`, e.target.value)}
                        className="nexter-input-sm"
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label className="control-label form-label">ประเภท</Label>
                      <div className="d-flex">
                        <CustomInput
                          type="radio"
                          id={`shareholder_individual_${i}`}
                          label="บุคคลธรรมดา"
                          value="individual"
                          className="mr-2"
                          checked={el.type === 'individual'}
                          onChange={(e) => onChange(`kyc_shareholders[${i}].type`, e.target.value)}
                        />
                        <CustomInput
                          type="radio"
                          id={`shareholder_corporate_${i}`}
                          label="นิติบุคคล"
                          value="corporate"
                          checked={el.type === 'corporate'}
                          onChange={(e) => onChange(`kyc_shareholders[${i}].type`, e.target.value)}
                          className="ml-2"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                {el.type === 'individual' && (
                  <Fragment>
                    <hr />
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label className="control-label form-label">ชื่อ (ถ้ามี)</Label>
                          <NexterInput
                            size="sm"
                            value={el.name}
                            onChange={(e) => onChange(`kyc_shareholders[${i}].name`, e.target.value)}
                            className="nexter-input-sm"
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label className="control-label form-label">นามสกุล</Label>
                          <NexterInput
                            size="sm"
                            value={el.lastname}
                            onChange={(e) => onChange(`kyc_shareholders[${i}].lastname`, e.target.value)}
                            className="nexter-input-sm"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Fragment>
                )}
                {el.type === 'corporate' && (
                  <Fragment>
                    <hr />
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label className="control-label form-label">ชื่อบริษัท (ถ้ามี)</Label>
                          <NexterInput
                            size="sm"
                            value={el.name}
                            onChange={(e) => onChange(`kyc_shareholders[${i}].name`, e.target.value)}
                            className="nexter-input-sm"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {el.kyc_shareholders_committee && el.kyc_shareholders_committee.map((elc, ic) => (
                      <Fragment key={ic}>
                        <div className="shareholder-container mt-3">
                          <div className="shareholder-header">
                            <h6>คณะกรรมการ รายที่ {ic + 1}</h6>
                          </div>
                          <div className="shareholder-content">
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label className="control-label form-label">ชื่อ - นามสกุล</Label>
                                  <NexterInput
                                    size="sm"
                                    value={elc.personal_name}
                                    onChange={(e) => onChange(`kyc_shareholders[${i}].kyc_shareholders_committee[${ic}].personal_name`, e.target.value)}
                                    className="nexter-input-sm"
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label className="control-label form-label">เลขประจำตัวประชาชน (ถ้ามี)</Label>
                                  <NexterInput
                                    allow="numeric"
                                    size="sm"
                                    value={elc.personal_id}
                                    maxLength={13}
                                    onChange={(e) => onChange(`kyc_shareholders[${i}].kyc_shareholders_committee[${ic}].personal_id`, e.target.value)}
                                    className="nexter-input-sm"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="mt-2 d-flex justify-content-end">
                          {ic === el.kyc_shareholders_committee.length - 1 && (
                            <NexterButton
                              onClick={e => onAction(`kyc_shareholders[${i}].kyc_shareholders_committee`, 'add', ic, e)}
                              size="sm" icon="fa fa-plus"
                              className="my-0 mr-2">
                              เพิ่มผู้คณะกรรมการ
                            </NexterButton>
                          )}
                          {el.kyc_shareholders_committee.length > 1 && (
                            <NexterButton
                              onClick={e => onAction(`kyc_shareholders[${i}].kyc_shareholders_committee`, 'remove', ic, e)}
                              size="sm" color="dark" outline
                              icon="fas fa-minus-circle"
                              className="my-0">
                              ลบคณะกรรมการ
                            </NexterButton>
                          )}
                        </div>
                      </Fragment>
                    ))}
                  </Fragment>
                )}
              </div>
            </div>
            <div className="mt-2 d-flex justify-content-end">
              {items.length > 1 && (
                <NexterButton
                  onClick={e => onAction(`kyc_shareholders`, 'remove', i, e)}
                  size="sm" color="dark" outline
                  icon="fas fa-minus-circle"
                  className="my-0">
                  ลบผู้ขอถือหุ้น
                </NexterButton>
              )}
            </div>
          </Fragment>
        ))}
        <NexterButton
          onClick={e => onAction(`kyc_shareholders`, 'add')}
          size="sm" icon="fa fa-plus"
          className="my-0 mr-2">
          เพิ่มผู้ขอถือหุ้น
        </NexterButton>
      </Fragment>
    )
  }
}

export default SellerShareholder;