import numeral from 'numeral';

export const Amount = ({ value, currencyUnit = false, digit = '00' }) => {
  let unit = '';
  let format = '0,0.';
  if (value < 0 && currencyUnit) {
    value = Math.abs(value);
    unit = '-฿';
  } else if (currencyUnit) {
    unit = '฿';
  }
  format += digit
  return `${unit} ${numeral(value).format(format)}`;
};
