import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

class MenuItem extends React.Component {
  render() {
    const props = this.props;

    return (
      <li className="menu-item">
        <NavLink to={props.path} className="menu-link" activeClassName="active">
          <i className={classNames('menu-icon', props.icon)} />
          <span className="menu-label">{props.name}</span>
        </NavLink>
      </li>
    );
  }
}

export default MenuItem;
