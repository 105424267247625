import React, { Component } from 'react';
import { Row, Col, Container, Modal, ModalBody, Badge, Card, CardBody, UncontrolledTooltip, Spinner } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';

import { http } from '../../../utils/http';
import { config } from '../../../config';
import Swal from 'sweetalert2';
import Truncate from 'react-truncate';

import { registerLocale } from 'react-datepicker';
import { th } from 'date-fns/locale';
import SortIcon from '@material-ui/icons/Sort';

import Kbank from '../../../assets/img/bank/logo-Kbank/logo-Kbank.png';
import SCB from '../../../assets/img/bank/logo-SCB/logo-SCB.png';
import BBL from '../../../assets/img/bank/logo-BBL/logo-BBL.png';
import Krungsri from '../../../assets/img/bank/logo-Krungsri/logo-Krungsri.png';
import KTB from '../../../assets/img/bank/logo-KTB/logo-KTB.png';
import UOB from '../../../assets/img/uob.jpeg';
import TBANK from '../../../assets/img/tbank.jpg';
import TTB from '../../../assets/img/ttb.png';
import Image from '../../../assets/img/Image.png';

import Visibility from '../../../components/Visibility/Visibility';
import NexterButton from '../../../components/Button/NexterButton';
import PromotionModal from './PromotionModal';
import * as _ from 'lodash';
import * as mime from 'mime-types';
registerLocale('th', th);

const ITEMS_PER_PAGE = 8;

class PromotionPage extends Component {
  constructor(props) {
    super(props);
    this.state = this.initState();
    this.handleChange = this.handleChange.bind(this);
    this.onSummitNewPromotion = this.onSummitNewPromotion.bind(this);
    this.onDropFile = this.onDropFile.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.onDropThumbnail = this.onDropThumbnail.bind(this);
    this.onChangeThumbnail = this.onChangeThumbnail.bind(this);
    this.handleMethod = this.handleMethod.bind(this);
    this.refModal = React.createRef();
  }
  initState() {
    return {
      modal: false,
      title: 'CREATE',
      readMoreModal: false,
      readChannel: false,
      read_description: '',
      read_title: '',
      data: {
        bank: '',
        promotion_name: '',
        promotion_desc: '',
        start_date: '',
        end_date: '',
        payment_method: [],
        channel_select: [],
        is_active: null,
      },
      validate: {
        bank: false,
        name: false,
        start_date: false,
        end_date: false,
        method: false,
        channel: false,
      },
      option: {
        channel: [],
        bank: [
          { value: 'KBANK', label: 'กสิกรไทย' },
          { value: 'SCB', label: 'ไทยพาณิชย์' },
          { value: 'BBL', label: 'กรุงเทพ' },
          { value: 'KTC', label: 'เคทีซี' },
          { value: 'BAY', label: 'กรุงศรี' },
          { value: 'KFC', label: 'กรุงศรีเฟิร์สช้อยส์' },
          { value: 'THE1', label: 'เซ็นทรัล เดอะวัน' },
          { value: 'TSC', label: 'เทสโก้ โลตัส' },
          { value: 'UOB', label: 'ยูโอบี' },
          { value: 'TTB', label: 'ทหารไทยธนชาต' },
          { value: 'GSB', label: 'ออมสิน' },
          { value: 'AEON', label: 'อิออน' },
        ],
      },
      current_date: '',
      channel_list: [],
      pictures: null,
      file_ext: '',
      file_url: null,
      thumbnails: null,
      thumbnail_ext: '',
      thumbnail_url: null,
      checkboxes: [
        { id: 0, value: 'Credit Card', code: 'credit_card', checked: false },
        { id: 4, value: 'Mobile Banking', code: 'bill_payment_mobile', checked: false },
        { id: 1, value: 'QR/ Bill Payment (BBL)', code: 'qr_box', checked: false },
        { id: 5, value: 'Counter Service', code: 'bill_payment', checked: false },
        { id: 2, value: 'SCG Cash Card/ SCG Wallet', code: 'scg_wallet', checked: false },
        { id: 6, value: 'Installment', code: 'installment', checked: false },
        { id: 3, value: 'Line Pay', code: 'line', checked: false },
        { id: 7, value: 'Chang Family Wallet', code: 'chang_family_wallet', checked: false },
        { id: 8, value: 'Thai QR Payment', code: 'promptpay', checked: false },
        { id: 9, value: 'GrabPay', code: 'grabpay', checked: false },
        { id: 10, value: 'CBDC Payment', code: 'cbdc', checked: false },
        { id: 11, value: 'Mobile Banking (App Switch)', code: 'mobile_banking_opn', checked: false },
      ],
      editID: 0,
      dropdown: false,
      upload_area: false,
      sort_direction: 'created_at_desc',
      currentPage: 1,
    };
  }

  componentWillMount() {
    const { currentPage } = this.state;
    this.props.system.getPromotionList(currentPage, ITEMS_PER_PAGE);
    this.props.channel.fetchChannelAll();
  }

  componentWillUnmount() {
    this.props.system.clearPromotionList();
  }

  async onLoadMorePromotionList() {
    await this.setState(
      prevState => ({
        currentPage: prevState.currentPage + 1,
      }),
      async () => {
        await this.props.system.getPromotionList(this.state.currentPage, ITEMS_PER_PAGE, false);
      }
    );
  }

  onDropFile(evt) {
    evt.preventDefault();
    this.onChangeFile(evt);
  }

  onDropThumbnail(evt) {
    evt.preventDefault();
    this.onChangeThumbnail(evt);
  }

  validateFile(type, evt) {
    let file_validate = false;
    let file = null;
    let extension = '';
    if (evt.target.files[0]) {
      file = evt.target.files[0];
      const fileType = mime.lookup(file.name);
      const acceptFileType = ['pdf', 'jpg', 'jpeg', 'png'].map(i => mime.lookup(i));
      if (acceptFileType.includes(fileType)) {
        file_validate = true;
        extension = mime.extension(fileType);
      } else {
        extension = mime.extension(fileType);
      }
    }
    if (file_validate) {
      const objectUrl = URL.createObjectURL(file);
      const fileKey = type === 'file' ? 'pictures' : 'thumbnails';
      const extKey = `${type}_ext`;
      const urlKey = `${type}_url`;
      this.setState({
        [fileKey]: file,
        [extKey]: extension,
        [urlKey]: objectUrl,
      });
    } else {
      Swal.fire({
        customClass: 'nexter-alert',
        type: 'warning',
        title: 'Validation Fail',
        html: '<li> File .' + extension + ' is not supported. </li>',
        showCancelButton: false,
      });
    }
  }

  onChangeFile(evt) {
    this.validateFile('file', evt);
  }

  onChangeThumbnail(evt) {
    this.validateFile('thumbnail', evt);
  }

  handleSubmit(event) {
    event.preventDefault();
  }
  bankNameConvert = name => {
    switch (name) {
      case 'KBANK':
        return 'กสิกรไทย';
      case 'SCB':
        return 'ไทยพาณิชย์';
      case 'BBL':
        return 'กรุงเทพ';
      case 'BAY':
      case 'KCC':
        return 'กรุงศรี';
      case 'KTC':
        return 'เคทีซี';
      case 'UOB':
        return 'ยูโอบี';
      case 'TBANK':
        return 'ธนาคารธนชาต';
      case 'TTB':
        return 'ทหารไทยธนชาต';
      case 'KFC':
        return 'กรุงศรีเฟิร์สช้อยส์';
      default:
        return '';
    }
  };

  toggle = () => {
    let state = this.initState();
    state.modal = !this.state.modal;
    this.setState(state);
  };

  readMoreToggle = () => {
    this.setState({
      readMoreModal: !this.state.readMoreModal,
    });
  };

  readMoreToggleChannel = () => {
    this.setState({
      readChannel: !this.state.readChannel,
    });
  };

  readChannel = (index, item) => {
    const { promotion_list } = this.props.system.getData();
    this.readMoreToggleChannel();
    let channelList = (item ? item : promotion_list[index]).channel_promotions.map(item => {
      return item.channel.name;
    });
    this.setState({
      channel_list: channelList,
    });
  };

  readMore = (promotion_name, promotion_desc) => {
    this.readMoreToggle();
    this.setState({
      read_description: promotion_desc,
      read_title: promotion_name,
    });
  };

  onSummitNewPromotion = async event => {
    this.refModal.current.wrappedInstance.setLoading(true);
    event.preventDefault();
    let { data, currentPage } = this.state;
    var formData = new FormData();
    formData.append('promotion_desc', data.promotion_desc);
    formData.append('promotion_name', data.promotion_name);
    formData.append('start_date', moment(data.start_date).format('YYYY-MM-DD'));
    formData.append('end_date', moment(data.end_date).format('YYYY-MM-DD'));
    if (data.is_active === null) {
      const today = new Date();
      const startDate = data.start_date;
      startDate.setHours(0, 0, 0);
      const endDate = data.end_date;
      endDate.setHours(23, 59, 59);
      const isTodayInPeriod = startDate <= today && today <= endDate;
      formData.append('is_active', isTodayInPeriod);
    } else {
      formData.append('is_active', data.is_active);
    }
    formData.append('bank', data.bank?.value);
    formData.append('payment_method', JSON.stringify(data.payment_method));
    let channel_select = data.channel_select.map(item => item.value);
    if (channel_select[0] === 'all') {
      formData.append('channels', 'all');
    } else {
      formData.append('channels', JSON.stringify(channel_select));
    }
    if (this.state.pictures) {
      formData.append('attach', this.state.pictures);
    } else if (this.state.file_url) {
      formData.append('file_url', this.state.file_url);
    }
    if (this.state.thumbnails) {
      formData.append('thumbnail', this.state.thumbnails);
    } else if (this.state.thumbnail_url) {
      formData.append('thumbnail_url', this.state.thumbnail_url);
    }
    let checker = arr => arr.every(v => v === false);
    let validate = checker(Object.values(this.handleValidation()));

    if (this.state.title === 'CREATE') {
      setTimeout(async () => {
        if (validate) {
          try {
            this.loading = true;
            let url = `${config.npay.apiUrl}/promotion`;
            let response = await http.post(url, { body: formData });

            if (response.status === 200) {
              let body = await response.json();
              this.promotion_list = body.data || [];
            } else {
              let body = await response.json();
              throw new Error(body.message);
            }
          } catch (error) {
            console.error(error);
          } finally {
            setTimeout(() => {
              this.loading = false;
            }, 500);
          }
          this.toggle();
          this.props.system.clearPromotionList();
          this.props.system.getPromotionList(1, ITEMS_PER_PAGE);
          this.refModal.current.wrappedInstance.setLoading(false);
        } else {
          this.refModal.current.wrappedInstance.setLoading(false);
        }
      }, 100);
    } else if (this.state.title === 'EDIT') {
      setTimeout(async () => {
        if (validate) {
          try {
            this.loading = true;
            let url = `${config.npay.apiUrl}/promotion/${this.state.editID}`;
            let response = await http.put(url, { body: formData });

            if (response.status === 200) {
              let body = await response.json();
              this.promotion_list = body.data || [];
            } else {
              let body = await response.json();
              throw new Error(body.message);
            }
          } catch (error) {
            console.error(error);
          } finally {
            setTimeout(() => {
              this.loading = false;
            }, 500);
          }
          this.toggle();
          this.props.system.clearPromotionList();
          this.props.system.getPromotionList(1, ITEMS_PER_PAGE);
          this.refModal.current.wrappedInstance.setLoading(false);
        } else {
          this.refModal.current.wrappedInstance.setLoading(false);
        }
      }, 100);
    }
  };

  handleBank = event => {
    let { data } = this.state;
    this.setState({ data: { ...data, bank: event } });
  };

  handleChange = event => {
    let { data } = this.state;
    const key = event.target.name;
    const value = event.target.value;
    this.setState({ data: { ...data, [key]: value } });
  };
  handleStartDate = date => {
    let { data } = this.state;
    this.setState({ data: { ...data, start_date: date } });
  };

  handleEndDate = date => {
    let { data } = this.state;
    this.setState({ data: { ...data, end_date: date } });
  };
  handleMethod(event) {
    let { data } = this.state;
    var isChecked = event.target.checked;
    var code = event.target.value;
    let newPaymentMethod = this.state.checkboxes.map(item => {
      if (item.code === code) {
        item.checked = isChecked;
      }
      return item;
    });
    let payment_method = newPaymentMethod.filter(item => item.checked).map(i => i.code);
    this.setState({ checkboxes: newPaymentMethod, data: { ...data, payment_method } });
  }
  handleChannelName = event => {
    this.setState({ channel: event.target.value });
  };
  onEdit = promotionId => {
    const { promotion_list } = this.props.system.getData();
    let { checkboxes, data, option } = this.state;
    let promotionInfo = promotion_list.find(a => a.id == promotionId);
    let promotionPM = promotionInfo.promotion_payment_method.map(i => i.payment_method.code);
    checkboxes = checkboxes.map(item => {
      if (promotionPM.includes(item.code)) {
        item.checked = true;
      } else {
        item.checked = false;
      }
      return item;
    });
    let channels = promotionInfo.channel_promotions;
    let channel_select = channels.map(i => ({ value: i.channel.uid, label: i.channel.name || i.channel.uid }));
    if (promotionInfo.chanel?.isAllChannel) {
      channel_select = [{ value: 'all', label: 'All' }];
    }
    data = {
      bank: _.find(option.bank, { value: promotionInfo.bank }),
      end_date: moment(moment(promotionInfo.end_date).utc().format('YYYY-MM-DD HH:mm:ss')).toDate(),
      start_date: moment(moment(promotionInfo.start_date).utc().format('YYYY-MM-DD HH:mm:ss')).toDate(),
      promotion_desc: promotionInfo.promotion_desc,
      promotion_name: promotionInfo.promotion_name,
      channel_select: channel_select,
      payment_method: promotionPM,
      is_active: promotionInfo.is_active,
    };
    this.setState({
      editID: promotionInfo.id,
      modal: !this.state.modal,
      title: 'EDIT',
      file_url: promotionInfo.file_url,
      thumbnail_url: promotionInfo.thumbnail_url,
      data,
      checkboxes,
    });
  };

  onDelete = async id => {
    Swal.fire({
      customClass: 'nexter-alert',
      type: 'warning',
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.value) {
        this.deletePromotionById(id);
      }
    });
  };

  async deletePromotionById(id) {
    try {
      this.loading = true;
      const url = `${config.npay.apiUrl}/promotion/${id}`;
      const response = await http.delete(url);
      if (response.status === 200) {
        this.props.system.removePromotionFromState(id);
        Swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          title: 'Deleted!',
          text: 'Your promotion has been removed',
          icon: 'success',
        });
      } else {
        const result = await response.json();
        return swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'Error!',
          text: result.message,
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  /* use with react-select */
  handleSelectChannel = (selectedList, actionMeta) => {
    let data = this.state.data;
    let channelTemp = data.channel_select;
    let option;
    switch (actionMeta.action) {
      case 'select-option':
        option = actionMeta.option;
        const hasSelectedAll = selectedList.some(i => i.value === 'all');
        if (option.value === 'all') channelTemp = [option];
        else if (!hasSelectedAll) channelTemp.push(option);
        break;
      case 'remove-value':
        option = actionMeta.removedValue;
        channelTemp = channelTemp.filter(item => item.value !== option.value);
        break;
      case 'clear':
        channelTemp = [];
    }
    this.setState({ data: { ...data, channel_select: channelTemp } });
  };

  toggleDropdown = () => {
    this.setState({
      dropdown: !this.state.dropdown,
    });
  };

  /*
  sortStartDate = () => {
    this.props.system.sortArrayStartDate();
    this.setState({ sort_direction: 'start_date' });
  };

  sortEndDate = () => {
    this.props.system.sortArrayEndDate();
    this.setState({ sort_direction: 'end_date' });
  };
  */

  sortFirstStartDate = () => {
    this.props.system.sortArrayViewDate('start_date_asc');
    this.setState({ sort_direction: 'start_date_asc' });
  };

  sortLastStartDate = () => {
    this.props.system.sortArrayViewDate('start_date_desc');
    this.setState({ sort_direction: 'start_date_desc' });
  };

  sortFirstEndDate = () => {
    this.props.system.sortArrayViewDate('end_date_asc');
    this.setState({ sort_direction: 'end_date_asc' });
  };

  sortLastEndDate = () => {
    this.props.system.sortArrayViewDate('end_date_desc');
    this.setState({ sort_direction: 'end_date_desc' });
  };

  sortFirstCreateDate = () => {
    this.props.system.sortArrayViewDate('created_at_asc');
    this.setState({ sort_direction: 'created_at_asc' });
  };

  sortLastCreateDate = () => {
    this.props.system.sortArrayViewDate('created_at_desc');
    this.setState({ sort_direction: 'created_at_desc' });
  };

  sortFirstUpdateDate = () => {
    this.props.system.sortArrayViewDate('updated_at_asc');
    this.setState({ sort_direction: 'updated_at_asc' });
  };

  sortLastUpdateDate = () => {
    this.props.system.sortArrayViewDate('updated_at_desc');
    this.setState({ sort_direction: 'updated_at_desc' });
  };

  handleValidation = () => {
    let { validate, data } = this.state;
    if (data.promotion_name.length === 0) validate.name = true;
    if (data.promotion_name.length > 0) validate.name = false;
    if (typeof data.start_date === 'string') validate.start_date = true;
    if (typeof data.start_date === 'object') validate.start_date = false;
    if (typeof data.end_date === 'string') validate.end_date = true;
    if (typeof data.end_date === 'object') validate.end_date = false;
    if (data.payment_method.length === 0) validate.method = true;
    if (data.payment_method.length > 0) validate.method = false;
    if (data.channel_select.length === 0) validate.channel = true;
    if (data.channel_select.length > 0) validate.channel = false;
    this.setState({ validate });
    return validate;
  };

  removeImage = () => {
    this.setState({
      file_url: null,
      pictures: null,
    });
  };

  removeThumbnail = () => {
    this.setState({
      thumbnail_url: null,
      thumbnails: null,
    });
  };

  handleOnDragEnd = result => {
    if (!result.destination) {
      return;
    }
    this.props.system.reorderPromotionList(result.source.index, result.destination.index);
  };

  render() {
    const { promotion_list, has_more_promotion_list } = this.props.system.getData();
    let { option, data } = this.state;
    const { channels: channelsData } = this.props.channel.getData();
    let channel_option = channelsData.all.map(i => ({ value: i.uid, label: i.name || i.uid }));
    channel_option.unshift({ value: 'all', label: 'All' });
    option.channel = channel_option;

    const valid_list = promotion_list.filter(item => !item.is_expired);
    const expired_list = promotion_list.filter(item => item.is_expired);

    /* Start of Promotion Read more */
    const readMoreModal = (
      <Modal className="modal-read-more" isOpen={this.state.readMoreModal} toggle={this.readMoreToggle}>
        <ModalBody>
          <h6 style={{ wordWrap: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' }}>{this.state.read_title}</h6>
          <div style={{ wordWrap: 'break-word', overflow: 'hidden' }}>{this.state.read_description}</div>
          {this.state.read_description && this.state.read_description.length > 0 ? <div className="text-right">({this.state.read_description.length} characters)</div> : ''}
        </ModalBody>
      </Modal>
    );
    /* End of Promotion Read more */
    /* Start of Promotion Channel List Modal */
    const channelModal = (
      <Modal className="modal-channel" isOpen={this.state.readChannel} toggle={this.readMoreToggleChannel}>
        <ModalBody>
          <h6 className="pb-2">Channel list</h6>
          <ul style={{ listStyleType: 'none' }} className="p-0 m-0">
            {this.state.channel_list.map((item, index) => (
              <li key={index} style={{ padding: '0.75rem 0' }}>
                {item}
              </li>
            ))}
          </ul>
        </ModalBody>
      </Modal>
    );
    /* End of Promotion Channel List Modal */

    /** Start of Promotion List */

    const promotionListCard = (
      <DragDropContext onDragEnd={this.handleOnDragEnd}>
        <Droppable droppableId="promotions">
          {provided => (
            <ul className="promotions p-0" {...provided.droppableProps} ref={provided.innerRef}>
              {valid_list.map((item, index) => {
                return (
                  <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                    {provided => (
                      <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Container fluid className="promotion-list-card" key={item.id}>
                          <div className="bank-header row">
                            <div className="d-flex align-items-center">
                              <span className="bank-title" id={`promo-${item.id}`}>
                                {item.promotion_name.length > 50 ? `${item.promotion_name.substring(0, 50)}...` : item.promotion_name}
                                <UncontrolledTooltip placement="top" target={`promo-${item.id}`} delay={{ show: 500, hide: 0 }}>
                                  {' '}
                                  {item.promotion_name}
                                </UncontrolledTooltip>
                              </span>
                            </div>
                            <div className="d-flex">
                              <Visibility permission_code="promotion:management_delete">
                                <NexterButton outline onClick={() => this.onDelete(item.id)} className="btn-sm remove-promotion-btn" icon="fa fa-minus-circle">
                                  Remove
                                </NexterButton>
                              </Visibility>
                              <Visibility permission_code="promotion:management_edit">
                                <NexterButton onClick={() => this.onEdit(item.id)} className="btn-sm" icon="fas fa-pencil-alt">
                                  Edit
                                </NexterButton>
                              </Visibility>
                            </div>
                          </div>
                          <hr className="hr-system" />
                          <Row>
                            <Col xs={12} lg={7}>
                              <Row>
                                <Col xs="3">
                                  <img className="promotion-list-image" src={item.thumbnail_url ? item.thumbnail_url : Image} />
                                </Col>
                                <Col xs="9" className="pr-0">
                                  <div className="line-seperate pr-3">
                                    {item.bank && <div className="d-flex align-items-center promotion-text">{this.bankNameConvert(item.bank)}</div>}
                                    <div className="promotion-description">
                                      <Truncate lines={3} ellipsis={<span>...</span>}>
                                        {item.promotion_desc}
                                      </Truncate>
                                    </div>
                                    <div className="read-more" onClick={() => this.readMore(item.promotion_name, item.promotion_desc)}>
                                      อ่านต่อ...
                                    </div>
                                  </div>

                                  <div>
                                    <div className="promotion-payment-method">Payment method</div>
                                    <div className="payment-method-row">
                                      {item.promotion_payment_method.map((item, index) => (
                                        <div key={item.id} className="payment-method-list">
                                          {item.payment_method.name}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={12} lg={5}>
                              <Row>
                                <Col className="px-0 text-center">
                                  <div className="line-seperate">
                                    <div className="promotion-text">Start date</div>
                                    <div>{moment.utc(item.start_date).format('DD/MM/YYYY')}</div>
                                  </div>
                                </Col>
                                <Col className="px-0 text-center">
                                  <div className="line-seperate">
                                    <div className="promotion-text">End date</div>
                                    <div>{moment.utc(item.end_date).format('DD/MM/YYYY')}</div>
                                  </div>
                                </Col>
                                <Col className="px-0 text-center">
                                  <div className="line-seperate">
                                    <div className="promotion-text">Channel</div>
                                    <div>
                                      {item.chanel.isAllChannel ? (
                                        'All'
                                      ) : (
                                        <span onClick={() => this.readChannel(index, item)} className="read-more-channel">
                                          specific
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                                <Col className="px-0 text-center">
                                  <div className="line-seperate">
                                    <div className="promotion-text">Status</div>
                                    {item.is_active ? (
                                      <Badge color="success" style={{ fontSize: '0.75rem' }}>
                                        Active
                                      </Badge>
                                    ) : (
                                      <Badge color="danger" style={{ fontSize: '0.75rem' }}>
                                        Inactive
                                      </Badge>
                                    )}
                                  </div>
                                </Col>
                                <Col className="px-0 text-center">
                                  <div>
                                    <div className="promotion-text">Expired</div>
                                    {!item.is_expired ? (
                                      <Badge color="success" style={{ fontSize: '0.75rem' }}>
                                        Valid
                                      </Badge>
                                    ) : (
                                      <Badge color="secondary" style={{ fontSize: '0.75rem' }}>
                                        Expired
                                      </Badge>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Container>
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    );

    const listCard = list => (
      <ul className="promotions p-0">
        {list.map((item, index) => {
          return (
            <li key={item.id}>
              <Container fluid className="promotion-list-card" key={item.id}>
                <div className="bank-header row">
                  <div className="d-flex align-items-center">
                    <span className="bank-title" id={`promo-${item.id}`}>
                      {item.promotion_name.length > 50 ? `${item.promotion_name.substring(0, 50)}...` : item.promotion_name}
                      <UncontrolledTooltip placement="top" target={`promo-${item.id}`} delay={{ show: 500, hide: 0 }}>
                        {' '}
                        {item.promotion_name}
                      </UncontrolledTooltip>
                    </span>
                  </div>
                  <div className="d-flex">
                    <Visibility permission_code="promotion:management_delete">
                      <NexterButton outline onClick={() => this.onDelete(item.id)} className="btn-sm remove-promotion-btn" icon="fa fa-minus-circle">
                        Remove
                      </NexterButton>
                    </Visibility>
                    <Visibility permission_code="promotion:management_edit">
                      <NexterButton onClick={() => this.onEdit(item.id)} className="btn-sm" icon="fas fa-pencil-alt">
                        Edit
                      </NexterButton>
                    </Visibility>
                  </div>
                </div>
                <hr className="hr-system" />
                <Row>
                  <Col xs={12} lg={7}>
                    <Row>
                      <Col xs="3">
                        <img className="promotion-list-image" src={item.thumbnail_url ? item.thumbnail_url : Image} />
                      </Col>
                      <Col xs="9" className="pr-0">
                        <div className="line-seperate pr-3">
                          <div className="promotion-text">{this.bankNameConvert(item.bank)}</div>
                          <div className="promotion-description">
                            <Truncate lines={3} ellipsis={<span>...</span>}>
                              {item.promotion_desc}
                            </Truncate>
                          </div>
                          <div className="read-more" onClick={() => this.readMore(item.promotion_name, item.promotion_desc)}>
                            อ่านต่อ...
                          </div>
                        </div>

                        <div>
                          <div className="promotion-payment-method">Payment method</div>
                          <div className="payment-method-row">
                            {item.promotion_payment_method.map((item, index) => (
                              <div key={item.id} className="payment-method-list">
                                {item.payment_method.name}
                              </div>
                            ))}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} lg={5}>
                    <Row>
                      <Col className="px-0 text-center">
                        <div className="line-seperate">
                          <div className="promotion-text">Start date</div>
                          <div>{moment.utc(item.start_date).format('DD/MM/YYYY')}</div>
                        </div>
                      </Col>
                      <Col className="px-0 text-center">
                        <div className="line-seperate">
                          <div className="promotion-text">End date</div>
                          <div>{moment.utc(item.end_date).format('DD/MM/YYYY')}</div>
                        </div>
                      </Col>
                      <Col className="px-0 text-center">
                        <div className="line-seperate">
                          <div className="promotion-text">Channel</div>
                          <div>
                            {item.chanel.isAllChannel ? (
                              'All'
                            ) : (
                              <span onClick={() => this.readChannel(index)} className="read-more-channel">
                                specific
                              </span>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col className="px-0 text-center">
                        <div className="line-seperate">
                          <div className="promotion-text">Status</div>
                          {item.is_active ? (
                            <Badge color="success" style={{ fontSize: '0.75rem' }}>
                              Active
                            </Badge>
                          ) : (
                            <Badge color="danger" style={{ fontSize: '0.75rem' }}>
                              Inactive
                            </Badge>
                          )}
                        </div>
                      </Col>
                      <Col className="px-0 text-center">
                        <div>
                          <div className="promotion-text">Expired</div>
                          {!item.is_expired ? (
                            <Badge color="success" style={{ fontSize: '0.75rem' }}>
                              Valid
                            </Badge>
                          ) : (
                            <Badge color="secondary" style={{ fontSize: '0.75rem' }}>
                              Expired
                            </Badge>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </li>
          );
        })}
      </ul>
    );
    /** End of Promotion List */

    return (
      <div className="container-fluid px-0 bg-white">
        <Row>
          <Col>
            <Card className="no-r-tl" style={{ minHeight: '320px' }}>
              <CardBody className="pt-0">
                <div className="promotion-row">
                  <h6 className="text-uppercase my-4 system-config-title">Promotion List</h6>
                  <Row className="promotion-btn-row">
                    <Visibility permission_code="promotion:management_create">
                      <NexterButton onClick={this.toggle} className="btn-sm promotion-btn" icon="fa fa-plus">
                        CREATE NEW PROMOTION
                      </NexterButton>
                    </Visibility>
                    <Link to={`${config.web.rootpath}/system-config/history?tab=promotion`}>
                      <NexterButton className="my-0" size="sm" outline icon="fas fa-history">
                        History Log
                      </NexterButton>
                    </Link>
                    {/* <Visibility permission_code="promotion:management_view">
                      <Dropdown isOpen={this.state.dropdown} toggle={this.toggleDropdown}>
                        <DropdownToggle color="danger" className="nexter-btn btn-outline-primary btn-sm sort-dropdown mr-3">
                          SORT BY <SortIcon />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem active={this.state.sort_direction === 'created_at_desc' ? true : false} onClick={() => this.sortLastCreateDate()}>
                            Last Create Date
                          </DropdownItem>
                          <DropdownItem active={this.state.sort_direction === 'created_at_asc' ? true : false} onClick={() => this.sortFirstCreateDate()}>
                            First Create Date
                          </DropdownItem>
                          <DropdownItem active={this.state.sort_direction === 'updated_at_desc' ? true : false} onClick={() => this.sortLastUpdateDate()}>
                            Last Update Date
                          </DropdownItem>
                          <DropdownItem active={this.state.sort_direction === 'updated_at_asc' ? true : false} onClick={() => this.sortFirstUpdateDate()}>
                            First Update Date
                          </DropdownItem>
                          <DropdownItem active={this.state.sort_direction === 'start_date_desc' ? true : false} onClick={() => this.sortLastStartDate()}>
                            Last Start Date
                          </DropdownItem>
                          <DropdownItem active={this.state.sort_direction === 'start_date_asc' ? true : false} onClick={() => this.sortFirstStartDate()}>
                            First Start Date
                          </DropdownItem>
                          <DropdownItem active={this.state.sort_direction === 'end_date_desc' ? true : false} onClick={() => this.sortLastEndDate()}>
                            Last End Date
                          </DropdownItem>
                          <DropdownItem active={this.state.sort_direction === 'end_date_asc' ? true : false} onClick={() => this.sortFirstEndDate()}>
                            First End Date
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Visibility> */}
                  </Row>
                </div>
                <hr className="hr-system" />

                <PromotionModal
                  ref={this.refModal}
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  title={this.state.title}
                  handleBank={this.handleBank}
                  data={this.state.data}
                  option={option}
                  handleChange={this.handleChange}
                  validate={this.state.validate}
                  handleStartDate={this.handleStartDate}
                  handleEndDate={this.handleEndDate}
                  currentDate={this.state.current_date}
                  uploadArea={this.state.upload_area}
                  files={this.state.pictures}
                  opDropFile={this.onDropFile}
                  onChangeFile={this.onChangeFile}
                  fileUrl={this.state.file_url}
                  fileExt={this.state.file_ext}
                  removeImage={this.removeImage}
                  onDropThumbnail={this.onDropThumbnail}
                  onChangeThumbnail={this.onChangeThumbnail}
                  thumbnailUrl={this.state.thumbnail_url}
                  thumbnailExt={this.state.thumbnail_ext}
                  removeThumbnail={this.removeThumbnail}
                  handleSelectChannel={this.handleSelectChannel}
                  paymentMethods={this.state.checkboxes}
                  handleMethod={this.handleMethod}
                  onSummitNewPromotion={this.onSummitNewPromotion}
                />
                {readMoreModal}
                {channelModal}

                <Visibility permission_code="promotion:management_view">
                  <div className="px-3">
                    {promotion_list.length > 0 && (
                      <InfiniteScroll
                        style={{ overflow: 'none' }}
                        dataLength={promotion_list.length}
                        next={this.onLoadMorePromotionList.bind(this)}
                        hasMore={has_more_promotion_list}
                        scrollableTarget="app-content"
                        scrollThreshold={0.7}
                        loader={<Spinner className="d-block mx-auto mb-3" color="danger" />}
                        endMessage={<p className="text-center text-danger">No more promotions to load!</p>}
                      >
                        {promotionListCard}
                        {listCard(expired_list)}
                      </InfiniteScroll>
                    )}
                  </div>
                </Visibility>
                <Visibility permission_code="promotion:management_view" invisibility_msg>
                  <h5 className="pl-3 mt-3">ท่านไม่มีสิทธิ์ดูรายการ Promotion</h5>
                </Visibility>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default inject('system', 'channel')(observer(PromotionPage));
