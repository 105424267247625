import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { CardBody, Table, Row, Col, UncontrolledTooltip } from 'reactstrap';
import { Amount } from '../../components/Field/Amount';
import { EmptyRow } from '../../components/Table/EmptyRow';

const GatewayCharge = ({ item }) => {
  const { gateway_charge_type, gateway_charge, payment } = item;
  if (gateway_charge_type === 'baht') {
    if (payment) {
      switch (payment.npay_charge_type) {
        case 'fixed':
        case 'baht':
          return '-';
        case 'percent':
          return <Amount value={payment.npay_charge} currencyUnit={false} />;
        default:
          return '-';
      }
    }
    return '-';
  } else {
    return <Amount value={gateway_charge} currencyUnit={false} />;
  }
}

const ServiceChargePercentage = ({ service_charge_type, service_charge }) => {
  if (service_charge_type === 'baht') {
    return '-';
  } else {
    return <Amount value={service_charge} currencyUnit={false} />;
  }
}

const FeeRow = ({ item, onClickRow }) => {
  const onClick = (data) => {
    if (onClickRow) {
      onClickRow(data)
    }
  }
  return (
    <tr key={item.id}>
      <td className="text-center">
        <span onClick={() => onClick(item)} className="as-link">{item.payment ? item.payment.ref_id : '-'}</span>
      </td>
      <td className="text-center">{item.invoice_no}</td>
      <td className="text-center">{_.get(item, 'payment.seller.name', '-').substring(0, 30)}</td>
      <td className="text-center">{item.type}</td>
      <td>{item.customer_name}</td>
      <td className="text-right"><Amount value={item.subtotal} /></td>
      <td className="text-right"><Amount value={item.discount} /></td>
      <td className="text-right"><Amount value={item.customer_paid} /></td>
      <td className="text-right"><ServiceChargePercentage
        service_charge={item.service_charge} service_charge_type={item.service_charge_type} /></td>
      <td className="text-right"><Amount value={item.bucharge || 0} /></td>
      <td className="text-right"><Amount value={item.vat || 0} /></td>
      <td className="text-right"><Amount value={item.wht || 0} /></td>
      <td className="text-right"><Amount value={item.bucharge_net || 0} /></td>
      <td className="text-right"><GatewayCharge item={item} /></td>
      <td className="text-right"><Amount value={item.gwcharge || 0} /></td>
      <td className="text-right"><Amount value={item.gwvat || 0} /></td>
      <td className="text-right"><Amount value={item.gwcharge_net || 0} /></td>
      <td className="text-right">{item.installment_period || '-'}</td>
      <td className="text-right"><Amount value={item.installment_rate} currencyUnit={false} /></td>
      <td className="text-right"><Amount value={item.interest} /></td>
      <td className="text-right"><Amount value={item.interest_vat} /></td>
      <td className="text-right"><Amount value={item.interest_net} /></td>
      <td className="text-right"><Amount value={item.transfer_net} /></td>
    </tr>
  );
};
const RefundRow = ({ item, onClickRow }) => {
  const onClick = (data) => {
    if (onClickRow) {
      onClickRow(data)
    }
  }
  return (
    <tr key={item.id}>
      <td className="text-center">
        <span onClick={() => onClick(item)} className="as-link">{item.payment ? item.payment.ref_id : '-'}</span>
      </td>
      <td className="text-center">{item.invoice_no}</td>
      <td className="text-center">{_.get(item, 'payment.seller.name', '-').substring(0, 30)}</td>
      <td className="text-center">{item.type}</td>
      <td>{item.customer_name}</td>
      <td className="text-right"><Amount value={item.subtotal} /></td>
      <td className="text-right"><Amount value={item.discount} /></td>
      <td className="text-right"><Amount value={item.customer_paid} /></td>
      <td className="text-right">-</td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right"><GatewayCharge item={item} /></td>
      <td className="text-right"><Amount value={item.gwcharge} /></td>
      <td className="text-right"><Amount value={item.gwvat} /></td>
      <td className="text-right"><Amount value={item.gwcharge_net} /></td>
      <td className="text-right">{item.installment_period || '-'}</td>
      <td className="text-right"><Amount value={item.installment_rate} currencyUnit={false} /></td>
      <td className="text-right"><Amount value={item.interest} /></td>
      <td className="text-right"><Amount value={item.interest_vat} /></td>
      <td className="text-right"><Amount value={item.interest_net} /></td>
      <td className="text-right"><Amount value={item.transfer_net} /></td>
    </tr>
  );
};

const ProductRow = ({ item, onClickRow }) => {
  const onClick = (data) => {
    if (onClickRow) {
      onClickRow(data)
    }
  }
  return (
    <tr key={item.id}>
      <td className="text-center" >
        <span onClick={() => onClick(item)} className="as-link">{item.payment ? item.payment.ref_id : '-'}</span>
      </td>
      <td className="text-center">{item.invoice_no}</td>
      <td className="text-center">{_.get(item, 'payment.seller.name', '-').substring(0, 30)}</td>
      <td className="text-center">{item.type}</td>
      <td >{item.customer_name}</td>
      <td className="text-right"><Amount value={item.subtotal} /></td>
      <td className="text-right"><Amount value={item.discount} /></td>
      <td className="text-right"><Amount value={item.customer_paid} /></td>
      <td className="text-right">-</td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right"><GatewayCharge item={item} /></td>
      <td className="text-right"><Amount value={item.gwcharge || 0} /></td>
      <td className="text-right"><Amount value={item.gwvat || 0} /></td>
      <td className="text-right"><Amount value={item.gwcharge_net || 0} /></td>
      <td className="text-right">{item.installment_period || '-'}</td>
      <td className="text-right"><Amount value={item.installment_rate} currencyUnit={false} /></td>
      <td className="text-right"><Amount value={item.interest} /></td>
      <td className="text-right"><Amount value={item.interest_vat} /></td>
      <td className="text-right"><Amount value={item.interest_net} /></td>
      <td className="text-right"><Amount value={item.transfer_net} /></td>
    </tr>
  );
};

const GatewayChargeRow = ({ item, onClickRow }) => {
  const onClick = (data) => {
    if (onClickRow) {
      onClickRow(data)
    }
  }
  return (
    <tr key={item.id}>
      <td className="text-center">
        <span onClick={() => onClick(item)} className="as-link">{item.payment ? item.payment.ref_id : '-'}</span>
      </td>
      <td className="text-center">{item.invoice_no}</td>
      <td className="text-center">{_.get(item, 'payment.seller.name', '-').substring(0, 30)}</td>
      <td className="text-center">{item.type}</td>
      <td>{item.customer_name}</td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right">-</td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right"><GatewayCharge item={item} /></td>
      <td className="text-right"><Amount value={item.gwcharge || 0} /></td>
      <td className="text-right"><Amount value={item.gwvat || 0} /></td>
      <td className="text-right"><Amount value={item.gwcharge_net || 0} /></td>
      <td className="text-right">-</td>
      <td className="text-right">-</td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right"><Amount value={0} /></td>
      <td className="text-right"><Amount value={item.transfer_net} /></td>
    </tr>
  );
};

export const AbsorbTransfersTable = ({ partnerTransfers, className }) => {
  let rows = partnerTransfers.map(transfer => {
    if (transfer.to_seller.type === 'partner' ) {
      return (
        <tr key={transfer.id}>
          <td className="text-left">{transfer.report_no}</td>
          <td className="text-center">{transfer.to_seller.name}</td>
          <td className="text-right"><Amount value={transfer.gateway_transfer_fee} currencyUnit={true} /></td>
        </tr>
      );
    }
  });

  return (
    <div className={className}>
      <h5 className="title">ค่าโอนจาก DP</h5>
      <hr />
      <div className="table-responsive table-scroll">
        <Table className="table-report">
          <thead>
            <tr>
              <th className="text-left">รายการสินค้า</th>
              <th className="text-center">ผู้รับเงิน</th>
              <th className="text-right" width="80">ยอดชำระ</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </div>
    </div>
  );
};

export class ChannelTable extends Component {
  render() {
    let { items, summary, partnerTransfers, onClickRow, paymentMethod } = this.props;

    let rows = items.map(item => {
      switch (item.type) {
        case 'fee':
          return <FeeRow key={item.id} item={item} onClickRow={onClickRow} paymentMethod={paymentMethod} />;
        case 'product':
          return <ProductRow key={item.id} item={item} onClickRow={onClickRow} paymentMethod={paymentMethod} />;
        case 'gateway_charge':
          return <GatewayChargeRow key={item.id} item={item} onClickRow={onClickRow} />;
        case 'refunded':
          return <RefundRow key={item.id} item={item} onClickRow={onClickRow} />
        default:
          return <tr key={item.id} />;
      }
    });
    if (rows.length === 0) {
      rows = <EmptyRow colSpan={24} />
    }

    return (
      <div className="table-responsive table-scroll print-transfer-table channel">
        <Table>
          <thead>
            <tr className="no-print">
              <th className="text-center">หมายเลขการสั่งซื้อ</th>
              <th>Invoice No.</th>
              <th className="text-center">Seller (ชื่อร้าน)</th>
              <th className="text-center">ประเภท</th>
              <th className="text-center">ชื่อลูกค้า</th>
              <th className="text-center">ราคารวม</th>
              <th className="text-center">ส่วนลด</th>
              <th className="text-center">ยอดเงินที่ลูกค้าชำระ</th>
              <th className="text-center">ค่าบริการรับจาก<br />SELLER (%)</th>
              <th className="text-center">ค่าบริการรับจาก<br />SELLER</th>
              <th className="text-center">VAT ค่าบริการ (7%)</th>
              <th className="text-center">WHT</th>
              <th className="text-center">ค่าบริการสุทธิ</th>
              <th className="text-center">ค่าบริการ<br />Gateway (%)</th>
              <th className="text-center">ค่าบริการ<br />Gateway</th>
              <th className="text-center">VAT (7%)</th>
              <th className="text-center">ค่าบริการ<br />Gateway<br />สุทธิ</th>
              <th className="text-center">ผ่อนชำระ (เดือน)</th>
              <th className="text-center">อัตราดอกเบี้ย (%) </th>
              <th className="text-center">ดอกเบี้ยหลังคำนวณ</th>
              <th className="text-center">VAT ดอกเบี้ย<br /> (7%)</th>
              <th className="text-center">ดอกเบี้ยสุทธิ</th>
              <th className="text-center">ยอดเตรียมโอน</th>
            </tr>
            <tr className="d-none print-only">
              <th className="text-center">หมาย<br/>เลข<br/>การสั่ง<br/>ซื้อ</th>
              <th className="text-center"><br/>Invoice <br/>No.</th>
              <th className="text-center">Seller<br/>(ชื่อร้าน)</th>
              <th className="text-center">ประ<br/>เภท</th>
              <th className="text-center">ชื่อลูกค้า</th>
              <th className="text-center">ราคารวม</th>
              <th className="text-center">ส่วนลด</th>
              <th className="text-center">ยอดเงินที่ลูกค้าชำระ</th>
              <th className="text-center">ค่า<br/>บริการ<br/>รับจาก<br />SELL<br/>ER (%)</th>
              <th className="text-center">ค่าบริการรับจาก<br />SELLER</th>
              <th className="text-center">VAT ค่าบริการ (7%)</th>
              <th className="text-center">WHT</th>
              <th className="text-center">ค่าบริการสุทธิ</th>
              <th className="text-center">ค่า<br/>บริการ<br />Gate<br/>way <br/>(%)</th>
              <th className="text-center">ค่าบริการ<br />Gateway</th>
              <th className="text-center">VAT (7%)</th>
              <th className="text-center">ค่าบริการ<br />Gateway<br />สุทธิ</th>
              <th className="text-center">ผ่อนชำระ <br/>(เดือน)</th>
              <th className="text-center">อัตรา<br/>ดอก<br/>เบี้ย (%) </th>
              <th className="text-center">ดอกเบี้ยหลังคำนวณ</th>
              <th className="text-center">VAT ดอกเบี้ย<br /> (7%)</th>
              <th className="text-center">ดอกเบี้ยสุทธิ</th>
              <th className="text-center">ยอดเตรียมโอน</th>
            </tr>
          </thead>
          <tbody>
            {rows}
            <tr className="bold spacer">
              <td className="text-right" colSpan={5}>
                <strong>รวม</strong>
              </td>
              <td className="text-right"><Amount value={summary.subtotal} /></td>
              <td className="text-right"><Amount value={summary.totaldiscount} /></td>
              <td className="text-right"><Amount value={summary.total_customer_paid} /></td>
              <td className="text-right">-</td>
              <td className="text-right"><Amount value={summary.bucharge} /></td>
              <td className="text-right"><Amount value={summary.vat} /></td>
              <td className="text-right"><Amount value={summary.wht} /></td>
              <td className="text-right"><Amount value={summary.bucharge_net} /></td>
              <td className="text-right">-</td>
              <td className="text-right"><Amount value={summary.gwcharge} currencyUnit={false} /></td>
              <td className="text-right"><Amount value={summary.gwvat} /></td>
              <td className="text-right"><Amount value={summary.gwcharge_net} /></td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right"><Amount value={summary.interest} /></td>
              <td className="text-right"><Amount value={summary.interest_vat} /></td>
              <td className="text-right"><Amount value={summary.interest_net} /></td>
              <td className="text-right"><Amount value={summary.transfer_amount} /></td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}
