import { Select } from 'antd';
import React from 'react';

const MultiSelect = ({ options = [], ...rest }) => {
  return (
    <Select
      mode="multiple"
      style={{ width: '100%' }}
      maxTagCount="responsive"
      maxTagPlaceholder={e => <span>{`${e.length}+`}</span>}
      getPopupContainer={trigger => trigger.parentNode}
      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      {...rest}
    >
      {options.map(option => (
        <Select.Option key={option.value}>{option.label}</Select.Option>
      ))}
    </Select>
  );
};

export default MultiSelect;
