import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Page } from '../../components/Page';
import InstalmentSetting from './InstallmentSetting';
import ChannelSetting from './ChannelSetting';
import PaymentSetting from './PaymentSetting';
import NexterButton from '../../components/Button/NexterButton';
import AdminSelectWrapper from '../admin/AdminSelectWrapper';

class ChannelSettingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // channel root state
      activeTab: props.match.params.tab || 'information',
      mode: 'view',
      user_type: '',
    };
  }

  async componentDidMount() {
    let { loggedinUser } = this.props.user.getData();
    let user_type = loggedinUser.type;
    this.setState({ loading: false, user_type });
  }


  changeActiveTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, mode: 'view' });
    }
  }

  setSave(method) {
    this.onChannelSettingSave = method;
  }
  setEdit(method) {
    this.onChannelSettingEdit = method;
  }
  setCancelEdit(method) {
    this.onChannelSettingCancel = method;
  }
  onSave() {
    this.onChannelSettingSave();
  }
  onEdit() {
    this.setState({ mode: 'edit' });
    this.onChannelSettingEdit();
  }
  onCancelEdit() {
    this.setState({ mode: 'view' });
    this.onChannelSettingCancel();
  }
  handleChangeMode(mode) {
    this.setState({ mode });
  }

  showActionByCondition(role_type = '') {
    let { admin_channel_uid } = this.props.channel.getData();
    let { mode } = this.state;
    let showSaveBtn = false;
    let showEditBtn = false;
    let showCancelBtn = false;
    switch (mode) {
      case 'edit':
        showSaveBtn = true;
        showCancelBtn = true;
        break;
      case 'view': {
        const { user_rp, user_info } = this.props.user.getData();
        const isChannel = Boolean(user_info.channel)
        const isPermission = ['channel:change_logo', 'channel:edit', 'channel:set_payment_successful_email'].some(role => user_rp.includes(role))
        showEditBtn = isChannel ? isPermission : Boolean(admin_channel_uid) && isPermission
        break;
      }
    }
    return {
      showSaveBtn,
      showEditBtn,
      showCancelBtn,
    };
  }

  disabledEditByRole(type) {
    const { user_type } = this.state;
    switch (type) {
      case 'admin_select':// disabled only 3 roles
        return [
          'as_channel_admin',
          'channel_admin',
          'channel_user'
        ].some(r => r === user_type);
      default:
        return false;
    }
  }


  render() {
    const { user_type } = this.state;
    const disabledAdminSelect = this.disabledEditByRole('admin_select');
    const {
      showSaveBtn,
      showEditBtn,
      showCancelBtn,
    } = this.showActionByCondition(user_type);

    return (
      <Page loading={this.state.loading}>
        <div className="channel-setting-wrapper">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h5 className="text-uppercase" style={{ marginBottom: 0 }}>
              <b>CHANNEL SETTINGS</b>
            </h5>
            {this.state.activeTab === 'information' && (
              <div className="d-flex">
                {showSaveBtn && (
                  <NexterButton className="mr-2 my-0" size="sm"
                    color="success"
                    outline
                    onClick={this.onSave.bind(this)}
                    icon="fas fa-save">
                    SAVE
                  </NexterButton>
                )}
                {showEditBtn && (
                  <NexterButton className="mr-2 my-0" size="sm"
                    icon="fas fa-pencil-alt"
                    onClick={this.onEdit.bind(this)}
                  >
                    Edit
                  </NexterButton>
                )}
                {showCancelBtn && (
                  <NexterButton className="mr-2 my-0" size="sm"
                    color="info" outline
                    icon="fas fa-times-circle"
                    onClick={this.onCancelEdit.bind(this)}
                  >
                    Cancel
                  </NexterButton>
                )}
              </div>
            )}
          </div>
          <AdminSelectWrapper
            disabled={disabledAdminSelect}
            onChange={() => this.changeActiveTab('information')}
          >
            <Nav tabs>
              <NavItem>
                <NavLink active={this.state.activeTab === 'information'} onClick={this.changeActiveTab.bind(this, 'information')}>
                  Channel Info
              </NavLink>
              </NavItem>
              <NavItem>
                <NavLink active={this.state.activeTab === 'installment'} onClick={this.changeActiveTab.bind(this, 'installment')}>
                  Installment Campaign
              </NavLink>
              </NavItem>
              <NavItem>
                <NavLink active={this.state.activeTab === 'payment'} onClick={this.changeActiveTab.bind(this, 'payment')}>
                  Due of Payment
              </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="information">
                {this.state.activeTab === 'information' && (
                  <ChannelSetting
                    setSave={this.setSave.bind(this)}
                    setEdit={this.setEdit.bind(this)}
                    setCancelEdit={this.setCancelEdit.bind(this)}
                    onChangeParentMode={this.handleChangeMode.bind(this)}
                  />
                )}
              </TabPane>
              <TabPane tabId="installment">
                {this.state.activeTab === 'installment' && <InstalmentSetting />}
              </TabPane>
              <TabPane tabId="payment">
                {this.state.activeTab === 'payment' && <PaymentSetting />}
              </TabPane>
            </TabContent>
          </AdminSelectWrapper>
        </div>
      </Page>
    );
  }
}

export default inject('channel', 'user')(observer(ChannelSettingPage));

