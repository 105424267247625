import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import _ from 'lodash';
import { Page } from '../../../components/Page';
import { Pagination } from '../../../components/Pagination/Pagination';
import { Row, Col, CardHeader, CardBody, CardFooter, Table } from 'reactstrap';
import iNoData from '../../../assets/img/icon-no-data.svg';
import { ensureJSONparse } from '../../../utils/string';
import { DELETE_SELLER_STATUS } from '../../../constants/sellerConstants';

const FIELD = {
  sellers: {
    name: 'ชื่อผู้ขาย',
    email: 'อีเมล',
    phone_number: 'เบอร์โทรศัพท์',
    is_active: 'สถานะผู้ขาย',
    delete_status: 'สถานะการลบ',
    verified_delete_by: 'ยืนยันโดย',
    verified_reject_delete_by: 'ยืนยันโดย',
    reject_delete_reason: 'เหตุผลในการปฏิเสธ',
  },
  channels_sellers: {
    seller_code: 'Seller Code',
    service_charge_absorb: 'คิดค่าธรรมเนียม',
    service_charge_rate: 'ค่าธรรมเนียม'
  },
  sellers_account: {
    status: 'สถานะ',
    bank_name: 'ธนาคาร',
    bank_account_no: 'เลขบัญชี',
    bank_account_name: 'ชื่อบัญชี',
    bank_account_image: 'สำเนาสมุดบัญชี',
    verified_by: 'ยืนยันโดย',
    reject_reason: 'เหตุผลที่ปฏิเสธ'
  },
  kyc_personals: {
    name: 'ชื่อ - นามสกุล',
    phone: 'เบอร์โทรศัพท์',
    file_kyc_personal: 'แบบแสดงรายการตน KYC',
    file_idcard: 'สำเนาบัตรประชาชน พร้อมรับรอง',
    status: 'สถานะ',
    email: 'อีเมล',
    personal_id: 'เลขบัตรประชาชน',
    id_address: 'ที่อยู่ตามบัตรประชาชน ที่อยู่',
    id_province: 'ที่อยู่ตามบัตรประชาชน จังหวัด',
    id_district: 'ที่อยู่ตามบัตรประชาชน อำเภอ/เขต',
    id_subdistrict: 'ที่อยู่ตามบัตรประชาชน ตำบล/แขวง',
    id_zipcode: 'ที่อยู่ตามบัตรประชาชน รหัสไปรษณีย์',
    current_address: 'ที่อยู่ปัจจุบันที่ติดต่อได้ ที่อยู่',
    current_district: 'ที่อยู่ปัจจุบันที่ติดต่อได้ อำเภอ/เขต',
    current_subdistrict: 'ที่อยู่ปัจจุบันที่ติดต่อได้ ตำบล/แขวง',
    current_province: 'ที่อยู่ปัจจุบันที่ติดต่อได้ จังหวัด',
    current_postcode: 'ที่อยู่ปัจจุบันที่ติดต่อได้ รหัสไปรษณีย์',
    company_name: 'ชื่อสถานที่ทำงาน',
    occupation: 'อาชีพ',
    company_address: 'ที่อยู่ที่ทำงาน ที่อยู่',
    company_province: 'ที่อยู่ที่ทำงาน จังหวัด',
    company_district: 'ที่อยู่ที่ทำงาน อำเภอ/เขต',
    company_subdistrict: 'ที่อยู่ที่ทำงาน ตำบล/แขวง',
    company_zipcode: 'ที่อยู่ที่ทำงาน รหัสไปรษณีย์',
    verified_by: 'ยืนยันโดย',
    reject_reason: 'เหตุผลที่ปฏิเสธ'
  },
  kyc_companies: {
    status: 'สถานะ',
    beneficial_owner_name: 'ผู้ได้รับมอบอำนาจทอดสุดท้าย ชื่อ - นามสกุล',
    beneficial_owner_personal_id: 'ผู้ได้รับมอบอำนาจทอดสุดท้าย เลขประจำตัวประชาชน',
    verified_by: 'ยืนยันโดย',
    reject_reason: 'เหตุผลที่ปฏิเสธ'
  },
  kyc_files: {
    file_identification: 'แบบแสดงรายการตน KYC',
    file_vat20: 'ใบทะเบียนภาษีมูลค่าเพิ่ม (ภพ. 20)',
    file_certificate: 'หนังสือรับรองการจดทะเบียนบริษัท',
    file_boj5: 'บัญชีรายชื่อผู้ถือหุ้น (บอจ. 5)',
    file_beneficial_owner: 'สำเนาบัตรประชาชน พร้อมรับรอง',
    file_beneficial_owner_authorization: 'เอกสารมอบอำนาจ',
    file_other: 'เอกสารอื่นๆ'
  },
  kyc_shareholders: {
    type: 'ข้อมูลผู้ถือหุ้น ประเภท',
    tax_id: 'ข้อมูลผู้ถือหุ้น เลขประจำตัวประชาชน/นิติบุคคล',
    name: 'ข้อมูลผู้ถือหุ้น ชื่อ/ชื่อบริษัท',
    lastname: 'ข้อมูลผู้ถือหุ้น นามสกุล'
  },
  kyc_shareholders_committee: {
    personal_id: 'คณะกรรมการ เลขประจำตัวประชาชน',
    personal_name: 'คณะกรรมการ ชื่อ - นามสกุล'
  }
}

const ENTITY = {
  sellers: 'ข้อมูลผู้ขาย',
  channels_sellers: 'ข้อมูลผู้ขาย',
  sellers_account: 'ข้อมูลบัญชี',
  kyc_personals: 'ข้อมูล KYC',
  kyc_companies: 'ข้อมูล KYC',
  kyc_files: 'ข้อมูล KYC',
  kyc_shareholders: 'ข้อมูล KYC',
  kyc_shareholders_committee: 'ข้อมูล KYC'
}

const t = (entity, field) => {
  const translateField = _.get(FIELD, [entity, field], field)
  const translateEntity = _.get(ENTITY, [entity], entity)
  return [translateEntity, translateField]
}

const EmptyRow = () => (
  <tr>
    <td colSpan={7} className="text-center">
      <div>
        <img src={iNoData} />
      </div>
      <span>ไม่มีประวัติการแก้ไข</span>
    </td>
  </tr>
);

class HistoryLog extends Component {
  componentDidMount() {
    this.props.seller.pageSellerlist = 1;
  }

  selectPage(page) {
    const elem = document.getElementsByClassName('content-container');
    elem[0].scrollTop = 0;
    this.props.seller.pageSellerlist = page;
  }

  render() {
    const { logs, seller } = this.props.seller.getData();
    const pageSize = 10;
    const start = pageSize * this.props.seller.pageSellerlist - pageSize;
    const end = logs.length <= pageSize * this.props.seller.pageSellerlist ? logs.length : pageSize * this.props.seller.pageSellerlist;
    const logsData = logs.slice(start, end);

    let rows = logsData.map((item) => {
      const oldValue = ensureJSONparse(item.old_value);
      const newValue = ensureJSONparse(item.new_value);
      let list = newValue !== null && !_.isEmpty(newValue) ? newValue : oldValue;
      let result = Object.entries(list)
      .filter(([key, value], i) => {
        let ov = _.get(oldValue, key);
        let nv = _.get(newValue, key);
        if (key === 'id_zipcode' || key === 'current_postcode' || key === 'company_zipcode') {
          return +ov !== +nv
        } else if (key === 'bank_code') {
          return false
        } else {
          if (seller.delete_status === 'approved') {
            return true;
          }
          return typeof ov === typeof nv ? ov !== nv : Boolean(ov) !== Boolean(nv)
        }
      })
      .map(([key, value], i) => {
        let [entity, field] = t(_.get(item, 'entity'), key)
        value = _.isString(value) ? value : JSON.stringify(value);
        let ov = _.get(oldValue, key);
        let nv = _.get(newValue, key);
        if(['file_kyc_personal', 'file_idcard'].includes(key)) {
          if(ov && !nv) item.action_name = 'delete'
        }
        else if (typeof ov === 'boolean' && key === 'service_charge_absorb') {
          ov = ov ? 'ไม่คิดค่าธรรมเนียม' : 'คิดค่าธรรมเนียม';
          nv = nv ? 'ไม่คิดค่าธรรมเนียม' : 'คิดค่าธรรมเนียม';
        }
        else if (typeof ov === 'boolean' && key === 'is_active') { 
          ov = ov ? 'Active' : 'Inactive';
          nv = nv ? 'Active' : 'Inactive';
        }
        // NL-1642
        else if (item.entity === 'sellers' && key === 'delete_status') { 
          if (ov != nv && nv === 'approved') {
            item.action_name = 'delete';
          }
          ov = DELETE_SELLER_STATUS[ov] || ov;
          nv = DELETE_SELLER_STATUS[nv] || nv;
        }
        // NL-1642
        else if (item.entity === 'sellers' && key === 'reject_delete_reason') {
          if(oldValue.delete_status === 'rejected' && !newValue.delete_status && ov && !nv) {
            field = 'รับทราบเหตุผลในการปฏิเสธการลบข้อมูลผู้ขาย';
          }
        }
        item.action_name_ed = item.action_name ? `${item.action_name}d` : null
        return (
          <tr key={`item-${item.id}-${i}`}>
            <td className="td-date-his">
              {i === 0 && moment(_.get(item, 'updated_at', <span className="data-is-null">-</span>)).format('YYYY-MM-DD HH:mm')}
            </td>
            <td className="td-editby-his">
              {i === 0 && (_.get(item, 'updated_by') === '-' ? <span className="data-is-null">-</span> : _.get(item, 'updated_by', <span className="data-is-null">-</span>))}
            </td>
            <td className="td-channel-his">{entity}</td>
            <td className="td-active-his box-action text-uppercase">
              <span className={`box-action-${_.get(item, 'action_name')}`}>
                {item.action_name_ed ? item.action_name_ed : <span className="data-is-null">-</span>}
              </span>
            </td>
            <td className="td-field-his text-uppercase">
              {field && key !== 'create' ? field : <span className="data-is-null">-</span>}
            </td>
            <td className={`td-olddata-his  ${key === 'status' ? 'text-uppercase' : ''}`} style={{ wordBreak: 'break-word' }}>
              {ov && key !== 'create' ? ov : <span className="data-is-null">-</span>}
            </td>
            <td className={`td-newdata-his ${key === 'status' ? 'text-uppercase' : ''}`} style={{ wordBreak: 'break-word' }}>
              {nv && key !== 'create' ? nv : <span className="data-is-null">-</span>}
            </td>
          </tr>
        );
      });

      return result;
    });
    return (
      <Page>
        <CardHeader>
          <Row>
            <Col>
              <h1 className="text-data-seller">ประวัติการแก้ไข</h1>
            </Col>
          </Row>
        </CardHeader>
        <hr style={{ border: 'solid 1px #525252' }} />
        <CardBody>
          <div style={{ overflowX: 'scroll' }}>
            <Table className="history-seller-table">
              <thead className="thead-seller">
                <tr>
                  <th className="td-date-his border-0">วันที่แก้ไข</th>
                  <th className="td-editby-his border-0">แก้ไขโดย</th>
                  <th className="td-channel-his text-capitalize border-0">ข้อมูล</th>
                  <th className="td-active-his border-0 text-center">กิจกรรม</th>
                  <th className="td-field-his border-0">ฟิลด์ที่แก้ไข</th>
                  <th className="td-olddata-his border-0">ข้อมูลเดิม</th>
                  <th className="td-newdata-his border-0">ข้อมูลใหม่</th>
                </tr>
              </thead>
              {logs.length > 0 ? (
                <tbody className="tbody-seller-history">{rows}</tbody>
              ) : (
                <tbody>
                  <EmptyRow />
                </tbody>
              )}
            </Table>
          </div>
          <CardFooter>
            <Col className="p-0">
              <Pagination
                className="pull-right"
                activePage={this.props.seller.pageSellerlist}
                displayPageNumber={pageSize}
                maximumPageNumber={Math.ceil(logs.length / pageSize)}
                onSelect={this.selectPage.bind(this)}
              />
            </Col>
          </CardFooter>
        </CardBody>
      </Page>
    );
  }
}

export default inject('seller')(observer(HistoryLog));
