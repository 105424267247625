const config = {
  web: {
    rootpath: '/admin',
  },
  massupload: {
    template_url:
      'https://sfinpay-document.s3.ap-southeast-1.amazonaws.com/template/uat/new+mass+upload+template.xlsx',
  },
  channel_doc: {
    request_doc:
      'https://sfinpay-document.s3.ap-southeast-1.amazonaws.com/template/NEXTERPAY_REQ.pdf',
  },
  auth: {
    pkey: '2JnLEFlaZX5mUy7rU1JFn6zh75oo5kF0',
  },
  ccpp: {
    securePayUrl:
      'https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/PaymentAuth.aspx',
  },
  npay: {
    webUrl: 'https://p8-web-uat.sfinpay.com',
    cmsUrl: 'https://p8-uat.sfinpay.com/admin',
    apiUrl: 'https://p8-api-uat.sfinpay.com',
  },
  is_ready_kbank_transfer_file: true,
  adfs: {
    client_id: '887651e4-ab51-4fa1-885a-6caa8461a4b0',
    redirect_uri: 'https://p8-api-uat.sfinpay.com',
    authorize_url: 'https://accessdev.scg.co.th/adfs/oauth2/authorize',
    redeem_url: 'https://accessdev.scg.co.th/adfs/oauth2/token',
    logout_irl: 'https://accessdev.scg.co.th/adfs/ls'
  },
  azure: {
    ad: {
      auth_path: 'https://login.microsoftonline.com/5db8bf0e-8592-4ed0-82b2-a6d4d77933d4/oauth2/v2.0',
      client_id: '968d02e3-078c-4815-af87-6c14eada1ca4',
    },
  },
  tax: {
    wht: 3.0,
  },
  time_calibate: {
    UTC: 7,
  },
  calculation: {
    release1: '2021-06-11'
  },
  fix_cost_fee: {
    set_date: '2022-02-24'
  },
};

module.exports = config;
