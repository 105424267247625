import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Row, Col, Card, FormGroup, Label } from 'reactstrap';
import { config } from '../../config';
import { Page } from '../../components/Page';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';
import logo from '../../assets/img/Logo-SFinPay.png';

class ForgotPassword extends Component {
  componentDidMount() {
    let accessToken = localStorage.getItem('token');
    if (accessToken) {
      this.props.history.replace(config.web.rootpath);
    }
  }

  changeEmail(event) {
    this.props.user.changeEmail(event.target.value);
  }

  sendResetPasswordEmail() {
    this.props.user.sendResetPasswordEmail();
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    let { loading, user } = this.props.user.getData();
    return (
      <Page loading={loading} className="d-flex min-vh-100 align-items-center">
        <Container className="py-3">
          <Row className="justify-content-center">
            <Col className="col-12 col-md-8 col-lg-5 text-center">
              <Card className="card-shadow0 m-0 p-4">
                <div className="position-relative">
                  <button className="no-btn-default position-absolute" style={{ left: 0 }} onClick={this.goBack.bind(this)}>
                    <i className="back-icon fas fa-chevron-left my-auto mr-2" style={{ fontSize: '1.4rem' }} />
                  </button>
                  <img src={logo} width="175px" />
                </div>
                <h4 className="text-blue bold">Reset or Forgot password?</h4>
                <p style={{ fontWeight: '600' }}>Enter your email and we'll send you a link to reset your password.</p>
                <FormGroup>
                  <Label className="float-left">
                    Email <span style={{ color: 'red' }}>*</span>
                  </Label>
                  <NexterInput value={user.email} onChange={this.changeEmail.bind(this)} />
                  <NexterButton className="mt-3 w-100" size="m" onClick={this.sendResetPasswordEmail.bind(this)} block>
                    Send
                  </NexterButton>
                </FormGroup>
              </Card>
            </Col>
          </Row>
        </Container>
      </Page>
    );
  }
}

export default inject('user')(observer(ForgotPassword));
