import React, { Component } from 'react';
import classNames from 'classnames';
import { Button, Card, CardBody, Col, Modal, ModalHeader, ModalBody, ModalFooter, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CheckBox } from '../../components/Input/CheckBox';
import NexterInput from '../../components/Input/NexterInput';
import NexterButton from '../../components/Button/NexterButton';
import CustomInputDatePicker from '../../components/Input/DatePicker';
import PropTypes from 'prop-types';
import _ from 'lodash';

const inputStyles = classNames('nexter-input-sm');
const rowStyles = classNames('mx-0 pb-3');
const colStyles = classNames('px-0');
const colDefaultProps = {
  xs: { size: 12 },
  lg: { size: 4 },
};

class PaymentModalFilter extends Component {
  constructor(props) {
    super(props);

    this.onChecked = this.onChecked.bind(this);
  }

  /** WATCH */
  onChecked(index, type) {
    const { onCheck } = this.props;
    onCheck(index, type);
  }
  /** WATCH */

  render() {
    const {
      open,
      toggle,
      executeFilter,
      clearState,
      filters: {
        statuses,
        tranferStatus,
        cards,
        dateFiltterType,
      },
      className
    } = this.props;

    return (
      <>
        <Modal isOpen={open} toggle={toggle} className={classNames(className, 'modal-payment-filter')}>
          <ModalBody>
            <h6 className="text-capitalize">filters</h6>
            <hr />
            <h6>สถานะชำระเงิน</h6>
            <Row className={rowStyles}>
              {statuses.map((props, i) => (
                <Col key={i} {...colDefaultProps} className={colStyles}>
                  <CheckBox {...props} onChange={() => this.onChecked(i, 'statuses')} defaultClassName="mx-0" />
                </Col>
              ))}
            </Row>
            <hr />
            <h6>สถานะโอนเงิน</h6>
            <Row className={rowStyles}>
              {tranferStatus.map((props, i) => (
                <Col key={i} {...colDefaultProps} className={colStyles}>
                  <CheckBox {...props} onChange={() => this.onChecked(i, 'tranferStatus')} defaultClassName="mx-0" />
                </Col>
              ))}
            </Row>
            <hr />
            <h6>ช่องทางชำระเงิน</h6>
            <Row className={rowStyles}>
              {cards.map((props, i) => (
                <Col key={i} {...colDefaultProps} className={colStyles}>
                  <CheckBox {...props} onChange={() => this.onChecked(i, 'cards')} defaultClassName="mx-0" />
                </Col>
              ))}
            </Row>
            <hr />
            <h6>เฉพาะวันที่</h6>
            <Row className={rowStyles}>
              {dateFiltterType.map((props, i) => (
                <Col key={i} {...colDefaultProps} className={colStyles}>
                  <CheckBox {...props} onChange={() => this.onChecked(i, 'dateFiltterType')} defaultClassName="mx-0" />
                </Col>
              ))}
            </Row>
            <hr />
            <div className="d-flex justify-content-end">
              <NexterButton size="sm" className="mx-2" onClick={clearState}>Clear all filters</NexterButton>
              <NexterButton size="sm" onClick={executeFilter}>Done</NexterButton>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

PaymentModalFilter.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func, // require
  filters: PropTypes.object, // require
  executeFilter: PropTypes.func, // require
  onCheck: PropTypes.func, // require
  clearState: PropTypes.func, // require
}

PaymentModalFilter.defaultProps = {
  open: false,
}

export default PaymentModalFilter;