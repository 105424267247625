import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import {Col, FormGroup, Label, Row} from 'reactstrap';
import NexterInput from '../../../components/Input/NexterInput';
import NexterButton from '../../../components/Button/NexterButton';
import ImageModal from '../../../components/Modal/ImageModal';
import Visibility from '../../../components/Visibility/Visibility';
import SellerRegisterKYCPersonal from '../SellerRegisterKYCPersonal';
import SellerRegisterKYCCompany from '../SellerRegisterKYCCompany';
import { basename, pathinfo } from '../../../utils/string';
import _ from 'lodash';
import moment from 'moment';
import Swal from 'sweetalert2';
import swal from "sweetalert2";

moment.locale('th');

const INITIAL_SHAREHOLDER = {
  tax_id: '',
  name: '',
  lastname: '',
  type: '',
  kyc_shareholders_committee: [
    { personal_name: '', personal_id: '' },
  ],
};

const INITIAL_SHAREHOLDER_COMMITTEE = { personal_name: '', personal_id: '' };

const inputData = {
  personal: [
    {
      title: 'ข้อมูลบุคคลธรรมดา',
      fields: [
        // {
        //   type: 'text',
        //   label: 'ชื่อ - นามสกุล',
        //   key: 'name',
        // },
        {
          type: 'text',
          label: 'เลขประจำตัวประชาชน',
          key: 'personal_id',
        },
        // {
        //   type: 'date',
        //   label: 'เกิดวันที่',
        //   key: 'dob_at',
        // },
        // {
        //   type: 'text',
        //   label: 'เบอร์โทรศัพท์',
        //   key: 'phone',
        // },
        // {
        //   type: 'text',
        //   label: 'อีเมล',
        //   key: 'email',
        // },
        // {
        //   type: 'file',
        //   label: 'แบบแสดงรายการตน KYC',
        //   key: 'file_kyc_personal',
        // },
        // {
        //   type: 'file',
        //   label: 'สำเนาบัตรประชาชน พร้อมรับรอง',
        //   key: 'file_idcard',
        // },
      ],
    },
    // {
    //   title: 'ข้อมูลที่อยู่',
    //   fields: [
    //     {
    //       type: 'text',
    //       label: 'ที่อยู่ตามบัตรประชาชน',
    //       key: [
    //         'id_address',
    //         'id_subdistrict',
    //         'id_district',
    //         'id_province',
    //         'id_zipcode',
    //       ],
    //     },
    //     {
    //       type: 'text',
    //       label: 'ที่อยู่ปัจจุบันที่ติดต่อได้',
    //       key: [
    //         'current_address',
    //         'current_subdistrict',
    //         'current_district',
    //         'current_province',
    //         'current_postcode',
    //       ],
    //     },
    //     {
    //       type: 'text',
    //       label: 'ที่อยู่ที่ทำงาน',
    //       key: [
    //         'company_address',
    //         'company_subdistrict',
    //         'company_district',
    //         'company_province',
    //         'company_zipcode',
    //       ],
    //     },
    //   ],
    // },
  ],
  company: [
    {
      title: 'ข้อมูลนิติบุคคล',
      fields: [
        {
          type: 'text',
          label: 'เลขประจำตัวผู้เสียภาษีอากร',
          key: 'company_taxid'
        },
        {
          type: 'file',
          label: 'แบบแสดงรายการตน KYC',
          key: 'kyc_files',
          params: {
            type: 'array',
            key: 'file_type',
            value: 'IDEN',
          },
        },
        {
          type: 'file',
          label: 'ใบทะเบียนภาษีมูลค่าเพิ่ม (ภพ. 20)',
          key: 'kyc_files',
          params: {
            type: 'array',
            key: 'file_type',
            value: 'VAT20',
          },
        },
        {
          type: 'file',
          label: 'หนังสือรับรองการจดทะเบียนบริษัท',
          key: 'kyc_files',
          params: {
            type: 'array',
            key: 'file_type',
            value: 'CERT',
          },
        },
        {
          type: 'file',
          label: 'บัญชีรายชื่อผู้ถือหุ้น (บอจ. 5)',
          key: 'kyc_files',
          params: {
            type: 'array',
            key: 'file_type',
            value: 'BOJ5',
          },
        },
      ],
    },
    {
      title: 'ผู้ได้รับมอบอำนาจทอดสุดท้าย',
      fields: [
        {
          type: 'text',
          label: 'ชื่อ - นามสกุล',
          key: 'beneficial_owner_name',
        },
        {
          type: 'text',
          label: 'เลขประจำตัวประชาชน',
          key: 'beneficial_owner_personal_id',
        },
        {
          type: 'file',
          label: 'สำเนาบัตรประชาชน พร้อมรับรอง',
          key: 'kyc_files',
          params: {
            type: 'array',
            key: 'file_type',
            value: 'BOO',
          },
        },
        {
          type: 'file',
          label: 'เอกสารมอบอำนาจ (ถ้ามี)',
          key: 'kyc_files',
          params: {
            type: 'array',
            key: 'file_type',
            value: 'BOA',
          },
        },
      ],
    },
    {
      title: 'เอกสารอื่นๆ',
      fields: [
        {
          type: 'file',
          key: 'kyc_files',
          params: {
            type: 'array',
            key: 'file_type',
            value: 'OTHER',
          },
        },
      ],
    },
    {
      title: 'ข้อมูลผู้ถือหุ้น (ถือหุ้น 25% ขึ้นไป)',
      fields: [
        {
          size: 9,
          type: 'list',
          key: 'kyc_shareholders',
          params: {
            type: 'array',
            key: 'name',
          }
        },
      ],
    },
  ],
};

const statusIcon = {
  pending: 'fas fa-clipboard-list',
  created: 'far fa-file-alt',
  incompleted: 'far fa-file-alt',
  incomplete: 'far fa-file-alt',
  approved: 'fa fa-check-circle',
  rejected: 'far fa-times-circle',
};

class DataKYCDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: this.props.createNewSeller ? 'edit' : 'view',
      modal: false,
      imageFile: null,

      // data state
      channel: {}, // id, uid, logo, public, url_test
      sellerKyc: {},
      channelSeller: {},

      // input state
      form: _.cloneDeep(this.getInitialFormState()),
      kycShareholdersReserved: [],
      deleteCommitteeIds: [],
    };

    this.changeMode = this.changeMode.bind(this);
    this.handleKYCChange = this.handleKYCChange.bind(this);
    this.handleKYCFile = this.handleKYCFile.bind(this);
    this.removeKYCFile = this.removeKYCFile.bind(this);
    this.onShareholderAction = this.onShareholderAction.bind(this);
  }

  componentDidMount() {
    const sellerInterval = setInterval(() => {
      const { seller } = this.props.seller.getData();
      if (seller.kyc_type !== '') {
        const form = this.state.form;
        const dataKey = `kyc_${seller.kyc_type}`;
        const formKey = `kyc${_.capitalize(seller.kyc_type)}`;
        form[formKey] = this.formatForm(seller[dataKey]);
        this.setState({ form: form, kycShareholdersReserved: seller?.kyc_company?.kyc_shareholders || [] });
        clearInterval(sellerInterval);
      }
    }, 500);
  }

  formatForm(data) {
    const result = data || {};
    const dateISOregEx = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
    Object.entries(result).forEach(([key, value]) => {
      if (value === null) result[key] = '';
      if (value && typeof(value) === 'object')
      result[key] = this.formatForm(result[key]);
      if (dateISOregEx.test(value)) result[key] = new Date(value);
      
      if (key === 'current_postcode') result.current_postcode = value;
      if (key === 'file_kyc_personal') result.kyc_personal = value;
      if (key === 'file_idcard') result.idcard = value;
      // case: the kyc company wants to show file in file upload
      // not show because AS-IS can show previous files upload.
      // if (key === 'kyc_files' && Array.isArray(value)) {
      //   value.forEach(v => {
      //     let file_key = '';
      //     switch (v.file_type) {
      //       case 'IDEN':
      //         file_key = 'identification';
      //         break;
      //       case 'VAT20':
      //         file_key = 'vat20';
      //         break;
      //       case 'CERT':
      //         file_key = 'certificate';
      //         break;
      //       case 'BOJ5':
      //         file_key = 'boj5';
      //         break;
      //       case 'BOO':
      //         file_key = 'beneficial_owner';
      //         break;
      //       case 'BOA':
      //         file_key = 'beneficial_owner_authorization';
      //         break;
      //       case 'OTHER':
      //         file_key = 'other';
      //         break;
      //     }
      //     result[file_key] = v.file_url;
      //   })
      // }
    });
    return result;
  }

  onShareholderAction(name, action, index) {
    const isCommitee = name.includes('kyc_shareholders_committee');
    const value = _.cloneDeep(isCommitee ? INITIAL_SHAREHOLDER_COMMITTEE : INITIAL_SHAREHOLDER);
    const kyc = this.state.form.kycCompany;
    const arr = _.get(kyc, name);
    if (action === 'add') arr.push(value);
    if (action === 'remove' && arr.length > 0) {
      if (isCommitee && arr[index]?.id) {
        const { deleteCommitteeIds } = this.state;
        deleteCommitteeIds.push(arr[index].id);
        this.setState({ deleteCommitteeIds });
      } 
      arr.splice(index, 1);
    }
    this.handleKYCChange('kycCompany', name, arr);
  }

  handleKYCChange(kycType, name, value) {
    const form = this.state.form;
    const kyc = form[kycType];
    _.set(kyc, name, value); // nest set

    if (name === 'contact_same' || kyc.contact_same) {
      if (kyc.contact_same === true) {
        kyc.current_address = kyc.id_address;
        kyc.current_province = kyc.id_province;
        kyc.current_district = kyc.id_district;
        kyc.current_subdistrict = kyc.id_subdistrict;
        kyc.current_postcode = kyc.id_zipcode;
      } else {
        kyc.current_address = '';
        kyc.current_province = '';
        kyc.current_district = '';
        kyc.current_subdistrict = '';
        kyc.current_postcode = '';
      }
    }
    form[kycType] = kyc;
    this.setState({ form });
  }

  handleKYCFile(kycType, name, acceptedFiles) {
    if (acceptedFiles && acceptedFiles.length) {
      const file = acceptedFiles[0];
      const form = this.state.form;
      form[kycType][name] = file;
      this.setState({ form });
    }
  }

  removeKYCFile(kycType, name) {
    this.handleKYCChange(kycType, name, '');
  }

  getInitialFormState() {
    return {
      kycType: null,
      kycPersonal: {
        name: '',
        dob_at: '',
        phone: '',
        email: '',
        kyc_personal: '', // file
        idcard: '', // file
        current_address: '',
        current_province: '',
        current_district: '',
        current_subdistrict: '',
        current_postcode: '',
        contact_same: false,
        id_address: '',
        id_province: '',
        id_district: '',
        id_subdistrict: '',
        id_zipcode: '',
        company_name: '',
        occupation: '',
        company_address: '',
        company_province: '',
        company_district: '',
        company_subdistrict: '',
        company_zipcode: '',
      },
      kycCompany: {
        identification: '', // file
        certificate: '', // file
        vat20: '', // file
        boj5: '', // file
        beneficial_owner_name: '',
        beneficial_owner_personal_id: '',
        beneficial_owner: '', // file
        beneficial_owner_authorization: '', // file
        other: '', // file
        kyc_shareholders: [],
      }
    };
  }

  getFieldText(data, field) {
    if (!data) return '';
    switch (field.type) {
      case 'text':
        return !Array.isArray(field.key)
          ? (data[field.key] || <span className="data-is-null">-</span>)
          : this.getMultipleFieldsText(data, field.key);
      case 'date':
        return !data[field.key] ? <span className="data-is-null">-</span> : (
          moment(data[field.key]).format('LL')
        );
      case 'file':
        let file = null;
        if (!Object.keys(field).includes('params')) file = data[field.key]
        else {
          switch (field.params.type) {
            case 'array':
              const matchedFiles = data[field.key].filter((obj) => obj[field.params.key] === field.params.value)
              // if (matchedFiles.length >= 1) file = matchedFiles.reverse()[0].file_url;
              if (matchedFiles.length >= 1) file = matchedFiles.map(i => i.file_url);
              break;
          }
        }

        let fileToDisplay = _.isArray(file) && file.length > 0 ? file[0] : file

        return !fileToDisplay ? (
          <span className="data-is-null">-</span>
        ) : pathinfo(fileToDisplay, 'PATHINFO_EXTENSION') === 'pdf' ? (
          <a style={{ color: '#007bff' }} onClick={() => this.downloadFile(fileToDisplay)}>
            ดูข้อมูล
          </a>
        ) : (
          <a style={{ color: '#007bff' }} onClick={() => this.toggleImgModal(true, fileToDisplay)}>
            ดูข้อมูล
          </a>
        );
      case 'list':
        if (field.params.type === 'array')
          return (
            <>
              {data[field.key].map((obj, index) => {
                if (!obj.type) return (''); // prevent empty shareholder is shown.
                return (
                  <div className="border rounded overflow-hidden mb-2" key={obj.id}>
                    <div className="px-4 py-3 bg-light">
                      <h6 className="m-0">ผู้ขอถือหุ้น รายที่ {index + 1}</h6>
                    </div>
                    <div className="px-4 py-3">
                      {obj.type === 'individual' && (
                        <Row>
                          <Col xs="4">
                            <Label className="text-label">ประเภทบุคคล</Label>
                            <div className="text-data">บุคคลธรรมดา</div>
                          </Col>
                          <Col xs="4">
                            <Label className="text-label">ชื่อ - นามสกุล</Label>
                            <div className="text-data">
                              {obj.name?.concat(' ', obj?.lastname ?? '') || <span className="data-is-null">-</span>}
                            </div>
                          </Col>
                          <Col xs="4">
                            <Label className="text-label">เลขประจำตัวประชาชน</Label>
                            <div className="text-data">
                              {obj.tax_id || <span className="data-is-null">-</span>}
                            </div>
                          </Col>
                        </Row>
                      )}
                      {obj.type === 'corporate' && (
                        <Row>
                          <Col xs="4">
                            <Label className="text-label">ประเภทบุคคล</Label>
                            <div className="text-data">นิติบุคคล</div>
                          </Col>
                          <Col xs="4">
                            <Label className="text-label">ชื่อบริษัท</Label>
                            <div className="text-data">
                              {obj.name || <span className="data-is-null">-</span>}
                            </div>
                          </Col>
                          <Col xs="4">
                            <Label className="text-label">เลขประจำตัวผู้เสียภาษีอากร</Label>
                            <div className="text-data">
                              {obj.tax_id || <span className="data-is-null">-</span>}
                            </div>
                          </Col>
                          <Col xs="12"><hr className="mb-0" /></Col>
                          {obj.kyc_shareholders_committee.map((committee, subIndex) => {
                            return (
                              <Fragment key={`${index}.${subIndex}`}>
                                <Col className="mt-3" xs="4">
                                  <Label className="text-label">
                                    {`${index + 1}.${subIndex + 1} รายชื่อคณะกรรมการ`}
                                  </Label>
                                </Col>
                                <Col className="mt-3" xs="4">
                                  <Label className="text-label">ชื่อ - นามสกุล</Label>
                                  <div className="text-data">{committee.personal_name}</div>
                                </Col>
                                <Col className="mt-3" xs="4">
                                  <Label className="text-label">เลขประจำตัวประชาชน</Label>
                                  <div className="text-data">{committee.personal_id}</div>
                                </Col>
                              </Fragment>
                            );
                          })}
                        </Row>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          );
    }
  }

  getMultipleFieldsText(data, keys) {
    const result = keys.map((key) => data[key] ? data[key] : '').join(' ').trim();
    return result !== '' ? result : <span className="data-is-null">-</span>;
  }

  async toggleImgModal(modal, imageFile) {
    if (imageFile) {
      try {
        let fileUrl = await this.props.import.exportPresignedFileUrl(imageFile);
        if (fileUrl) {
          this.setState({ modal, imageFile: fileUrl });
        }
      } catch (error) {}
    } else {
      this.setState({ modal });
      setTimeout(() => this.setState({ imageFile }), 500);
    }
  }

  async downloadFile(url) {
    let fileUrl = await this.props.import.exportPresignedFileUrl(url);
    if (fileUrl) {
      window.open(fileUrl);
    }
  }

  async changeMode(mode) {
    let isConfirmed = true;
    if (mode === 'view') {
      const result = await Swal.fire({
        customClass: 'nexter-alert nexter-alert-user-manage',
        title: 'ต้องการยกเลิกการแก้ไขข้อมูล!',
        confirmButtonText: 'ยืนยัน',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        reverseButtons: true,
      });
      const { seller } = this.props.seller.getData();
      const form = this.state.form;
      const dataKey = `kyc_${seller.kyc_type}`;
      const formKey = `kyc${_.capitalize(seller.kyc_type)}`;
      form[formKey] = this.formatForm(seller[dataKey]);
      this.setState({ form: form });
      if (Object.keys(result).includes('dismiss')) isConfirmed = false;
    }
    if (isConfirmed) this.setState({ mode: mode });
  }

  async changeStatus(status, uid) {
    let swalOptions = {
      customClass: 'nexter-alert nexter-alert-user-manage',
      confirmButtonText: 'ยืนยัน',
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      reverseButtons: true,
    };
    if (status === 'approve') swalOptions = {
      ...swalOptions,
      title: 'อนุมัติข้อมูล KYC',
    }
    if (status === 'reject') swalOptions = {
      ...swalOptions,
      title: 'ปฏิเสธข้อมูล KYC',
      input: 'text',
      inputAttributes: {
        placeholder: 'ระบุเหตุผลในการปฏิเสธ',
      },
    }
    const result = await Swal.fire(swalOptions);
    if (Object.keys(result).includes('value')) {
      if (status === 'approve') {
        this.props.seller.approveKYCByUid(uid);
      }
      if (status === 'reject') {
        this.props.seller.changeRejectReasonKYC(result.value);
        this.props.seller.rejectKYCByUid(uid);
      }
    }
  }

  async onSave(kycType) {
    const result = await swal.fire({
      customClass: 'nexter-alert',
      type: 'warning',
      title: 'ลงทะเบียนและส่งตรวจสอบเอกสาร',
      html: 'เมื่อกดยืนยัน ข้อมูลจะถูกส่งไปตรวจสอบ<br/>สถานะบางรายการจะเปลี่ยนเป็น “Pending” <br/>คุณต้องการยืนยันข้อมูลนี้หรือไม่?',
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ยืนยัน',
      reverseButtons: true,
    });
    if (Object.keys(result).includes('value')) {
      let { form, kycShareholdersReserved, deleteCommitteeIds } = this.state;
      if (kycType === 'personal') {
        const kycPersonalUpload = {
          kyc_personal: form.kycPersonal.kyc_personal,
          idcard: form.kycPersonal.idcard,
        }
        this.props.seller.updateKYCPersonal({
          ..._.pick(form.kycPersonal, [
            'name',
            'phone',
            'email',
            'current_address',
            'current_province',
            'current_district',
            'current_subdistrict',
            'current_postcode',
            'id_address',
            'id_province',
            'id_district',
            'id_subdistrict',
            'id_zipcode',
            'company_name',
            'occupation',
            'company_address',
            'company_province',
            'company_district',
            'company_subdistrict',
            'company_zipcode',
            'personal_id',
          ]),
          dob_at: form.kycPersonal.dob_at ? moment(form.kycPersonal.dob_at).valueOf() : '',
          upload: kycPersonalUpload,
        }, kycPersonalUpload);
      } else if (kycType === 'company') {
        const kycCompanyUpload = _.pick(form.kycCompany, [
          'identification',
          'certificate',
          'vat20',
          'boj5',
          'beneficial_owner',
          'beneficial_owner_authorization',
          'other',
        ]);
        let newKYCShareholders = [];
        for (let i = 0; i < form.kycCompany.kyc_shareholders.length; i++) {
          const el = form.kycCompany.kyc_shareholders[i];
          let data = {
            tax_id: el.tax_id,
            name: el.name,
            lastname: el.lastname,
            type: el.type,
          };
          if (el.id) data['id'] = el.id;
          if (el.type === 'corporate') data['kyc_shareholders_committee'] = el.kyc_shareholders_committee || [];
          newKYCShareholders.push(data);
        }
        const originKYCshareholders = _.get(this.props.seller.getData(), ['seller', 'kyc_company', 'kyc_shareholders'], [])
        const deleteShareholderIds = originKYCshareholders.filter(elOne => !newKYCShareholders.some(elTwo => elOne.id === elTwo.id)).map(obj => obj.id);
        this.props.seller.updateKYCCompany({
          company_taxid: form.kycCompany.company_taxid,
          beneficial_owner_name: form.kycCompany.beneficial_owner_name,
          beneficial_owner_personal_id: form.kycCompany.beneficial_owner_personal_id,
          kyc_shareholders: newKYCShareholders,
          deleteShareholders: deleteShareholderIds,
          deleteCommitteeIds,
        }, kycCompanyUpload);
      }
    }
  }

  render() {
    const { loading, seller } = this.props.seller.getData();
    const keyType = `kyc_${seller.kyc_type}`;

    const viewForm = (field, index) => (
      <Col key={index} xs={field.size || 3}>
        {field.label && <Label className="text-label">{field.label}</Label>}
        <div className="text-data">{this.getFieldText(seller[keyType], field)}</div>
      </Col>
    );

    return loading && !seller.id ? null : (
      <>
        <Row className="justify-content-between">
          <Col xs="auto">
            <h1 className="text-data-seller">ข้อมูล KYC</h1>
          </Col>
          {!this.props.createNewSeller && <Col xs="auto">
            <NexterButton
              size="sm"
              icon={statusIcon[seller[keyType]?.status || 'incomplete']}
              className={`text-capitalize nexter-bt-${seller[keyType]?.status || 'incomplete'}`}
              outline
            >
              {seller[keyType]?.status || 'incomplete'}
            </NexterButton>
            {seller[keyType]?.status === 'pending' ? (
              <Visibility permission_code="seller:approve">
                <div className="d-inline-block ml-3 align-middle border-right border-left" style={{height: '36px'}} />
                <NexterButton
                  className="ml-3"
                  color="dark"
                  size="sm"
                  icon="far fa-times-circle"
                  onClick={() => this.changeStatus('reject', seller.uid)}
                >
                  Reject
                </NexterButton>
                <NexterButton
                  className="ml-3"
                  color="success"
                  size="sm"
                  icon="fas fa-check-circle"
                  onClick={() => this.changeStatus('approve', seller.uid)}
                >
                  Approve
                </NexterButton>
              </Visibility>
            ) : this.state.mode === 'view' ? (
              <Visibility isVisibility={!['requested', 'approved'].includes(seller.delete_status) && seller.kyc_type !== 'personal'}>
                <Visibility permission_code="seller:edit">
                  <div className="d-inline-block ml-3 align-middle border-right border-left" style={{height: '36px'}} />
                  <NexterButton
                    className="ml-3"
                    size="sm"
                    icon="fa fa-pencil-alt"
                    onClick={() => this.changeMode('edit')}
                  >
                    Edit {seller.delete_status}
                  </NexterButton>
                </Visibility>
              </Visibility>
            ) : (
              <Visibility permission_code="seller:edit">
                <div className="d-inline-block ml-3 align-middle border-right border-left" style={{height: '36px'}} />
                <NexterButton
                  className="ml-3"
                  size="sm"
                  color="dark"
                  outline
                  onClick={() => this.changeMode('view')}
                >
                  Cancel
                </NexterButton>
                <NexterButton
                  className="ml-3"
                  size="sm"
                  icon="fa fa-save"
                  color="success"
                  outline
                  onClick={() => this.onSave(seller.kyc_type)}
                >
                  Save
                </NexterButton>
              </Visibility>
            )}
          </Col>}
          <Col xs="12"><hr/></Col>
          {seller[keyType]?.status === 'rejected' && seller[keyType]?.reject_reason && (
            <div className="box-rejected">
              <span className="text-reason">เหตุผลในการปฏิเสธ : {seller[keyType]?.reject_reason}</span>
            </div>
          )}
        </Row>
        <Row>
          {this.state.mode === 'view' && inputData[seller.kyc_type] ? inputData[seller.kyc_type].map((section, sectionIdx) => (
            <Fragment key={section.title}>
              <Col xs="12"><p className="text-title">{section.title}</p></Col>
              {section.fields.map((field, fieldIdx) => viewForm(field, fieldIdx))}
              {sectionIdx !== inputData[seller.kyc_type].length - 1 && <Col xs="12"><hr/></Col>}
            </Fragment>
          )) : ('')}
          {this.state.mode === 'edit' && seller.kyc_type && (
            <Col xs="12">
              {seller.kyc_type === 'personal'
                ? <SellerRegisterKYCPersonal
                    taxId={this.props.taxId ? this.props.taxId : seller.kyc_personal?.personal_id}
                    form={this.props.form ? this.props.form : { ...this.state.form.kycPersonal }}
                    handleChange={this.props.handleChange ? this.props.handleChange : this.handleKYCChange}
                    handleKYCFile={this.props.handleKYCFile ? this.props.handleKYCFile : this.handleKYCFile}
                    removeKYCFile={this.props.removeKYCFile ? this.props.removeKYCFile : this.removeKYCFile}
                    showInfo={false}
                  />
                : <SellerRegisterKYCCompany
                    taxId={this.props.taxId ? this.props.taxId : seller.kyc_company?.company_taxid}
                    form={this.props.form ? this.props.form : { ...this.state.form.kycCompany }}
                    handleChange={this.props.handleChange ? this.props.handleChange : this.handleKYCChange}
                    handleKYCFile={this.props.handleKYCFile ? this.props.handleKYCFile : this.handleKYCFile}
                    removeKYCFile={this.props.removeKYCFile ? this.props.removeKYCFile : this.removeKYCFile}
                    onShareholderAction={this.props.onShareholderAction ? this.props.onShareholderAction : this.onShareholderAction}
                    showInfo={false}
                  />
              }
            </Col>
          )}
        </Row>
        <ImageModal
          modal={this.state.modal}
          toggle={() => this.toggleImgModal(false, null)}
          imageFile={this.state.imageFile}
        />
      </>
    );
  }
}

export default inject('seller', 'import')(observer(DataKYCDetail));