import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, Button, FormFeedback } from 'reactstrap';
import classNames from 'classnames';
import NexterButton from '../../../components/Button/NexterButton';
import NexterInput from '../../../components/Input/NexterInput';
import Visibility from '../../../components/Visibility/Visibility';
import { validation } from '../../../utils/validation';

const ResetPasswordForm = props => {
  let { disabledSubmit, invalidInputs } = getWarning(props.currentPassword, props.newPassword, props.confirmNewPassword, props.requiredCurrentPassword);

  return (
    <Form onSubmit={props.onSubmit.bind(this)}>
      <Visibility isVisibility={props.requiredCurrentPassword}>
        <FormGroup className="mb-3">
          <Label>Current Password</Label>
          <NexterInput
            type="password"
            name="current_password"
            value={props.currentPassword}
            invalid={invalidInputs.hasOwnProperty('current_password') || props.isFailedCurrentPassword}
            onChange={e => props.onChangeCurrentPassword(e.target.value)}
          />
          <FormFeedback>{invalidInputs['current_password']}</FormFeedback>
        </FormGroup>
      </Visibility>

      <FormGroup className="mb-3">
        <Label>{props.requiredCurrentPassword ? 'New Password' : 'Password'}</Label>
        <NexterInput type="password" name="new_password" value={props.newPassword} invalid={invalidInputs.hasOwnProperty('new_password')} onChange={e => props.onChangeNewPassword(e.target.value)} />
        <FormFeedback>{invalidInputs['new_password']}</FormFeedback>
      </FormGroup>

      <FormGroup className="mb-3">
        <Label>Confirm New Password</Label>
        <NexterInput
          type="password"
          name="confirm_new_password"
          value={props.confirmNewPassword}
          invalid={invalidInputs.hasOwnProperty('confirm_new_password')}
          onChange={e => props.onChangeConfirmNewPassword(e.target.value)}
        />
        <FormFeedback>{invalidInputs['confirm_new_password']}</FormFeedback>
      </FormGroup>

      <div className="mt-4 mb-0 pl-0">
        <p className="bold">Password Must:</p>
        {validation.checkPasswordFormatList(props.newPassword).map((format, index) => (
          <p
            key={index}
            className={classNames('mb-1', {
              'text-green': format.passed,
              'text-secondary': !format.passed && props.newPassword == '',
              'text-failed': !format.passed && props.newPassword !== '',
            })}
          >
            <span
              className={classNames('mr-2', {
                'fa fa-check': format.passed,
                'fa fa-circle text-small': !format.passed,
                'text-medium-gray': !format.passed && props.newPassword === '',
                'text-failed': !format.passed && props.newPassword !== '',
              })}
            ></span>
            {format.title}
          </p>
        ))}
      </div>

      <NexterButton className="w-100 mt-4" block disabled={disabledSubmit}>
        {props.requiredCurrentPassword ? 'CHANGE PASSWORD' : 'SUBMIT'}
      </NexterButton>
      <Visibility isVisibility={props.requiredCurrentPassword}>
        <NexterButton className="w-100 mt-2 text-capitalize" size="sm" color="link" block onClick={props.onCancel.bind(this)}>
          <span className="text-secondary">Not Change Now</span>
        </NexterButton>
      </Visibility>
    </Form>
  );
};

const getWarning = (currentPassword, newPassword, confirmNewPassword, requiredCurrentPassword) => {
  let disabledSubmit = true;
  let invalidInputs = {};
  if (currentPassword && newPassword && currentPassword === newPassword) {
    invalidInputs = {
      current_password: '',
      new_password: 'New password can not be the same as current password',
    };
  } else if (newPassword && confirmNewPassword && newPassword !== confirmNewPassword) {
    invalidInputs = {
      new_password: '',
      confirm_new_password: 'Password are not matching',
    };
  } else if (validation.password(newPassword)) {
    disabledSubmit = false;
  }
  if ((requiredCurrentPassword && !currentPassword) || !newPassword || !confirmNewPassword) {
    disabledSubmit = true;
  }
  return {
    disabledSubmit,
    invalidInputs,
  };
};

ResetPasswordForm.propTypes = {
  requiredCurrentPassword: PropTypes.bool,
  currentPassword: PropTypes.string,
  newPassword: PropTypes.string,
  confirmNewPassword: PropTypes.string,
  isFailedCurrentPassword: PropTypes.bool,
  onChangeCurrentPassword: PropTypes.func,
  onChangeNewPassword: PropTypes.func,
  onChangeConfirmNewPassword: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

ResetPasswordForm.defaultProps = {
  requiredCurrentPassword: false,
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
  isFailedCurrentPassword: false,
  onChangeCurrentPassword: () => {},
  onChangeNewPassword: () => {},
  onChangeConfirmNewPassword: () => {},
  onSubmit: () => {},
  onCancel: () => {},
};

export default ResetPasswordForm;
