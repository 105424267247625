import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import swal from 'sweetalert2';
import moment from 'moment';
import _ from 'lodash';
import classNames from 'classnames';
import { Page } from '../../../components/Page';
import { CardHeader, CardBody, Row, Col, Label, FormGroup, FormFeedback, Alert } from 'reactstrap';
import { OPN_REJECT_REASON_ACCOUNT, OPN_WAITING_RESUBMIT_DAY } from '../../../constants/sellerConstants';
import Visibility from '../../../components/Visibility/Visibility';
import NexterButton from '../../../components/Button/NexterButton';
import NexterInput from '../../../components/Input/NexterInput';
import FileUpload from '../../../components/File/FileUpload';
// import ImageModal from '../../../components/Modal/ImageModal';
import { basename, pathinfo } from '../../../utils/string';
import OpnAccountAlert from './OpnAccountAlert';
import { getOpnRecipientStatus } from '../../../utils/opn';

const ERROR_MESSAGES = {
  bank_name: 'กรุณาเลือกประเภทบัญชีให้ถูกต้อง',
  bank_account_name: 'กรุณาระบุชื่อบัญชีให้ถูกต้อง',
  bank_account_no: 'กรุณาระบุเลขบัญชีให้ถูกต้อง',
  bank_account_image: 'กรุณาอัปโหลดสำเนาสมุดบัญชี',
};

class DataAccountDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { status: 'active', modal: false, invalid_message: [] };
    this.getFileName = this.getFileName.bind(this);
  }

  componentDidMount() {
    this.props.bank.fetchBank();
  }

  componentWillUnmount() {
    this.props.seller.editableSellerAccountDetail = false;
    this.props.seller.clearSeller();
  }

  onCancel() {
    const { rawSeller } = this.props.seller.getData();
    this.props.seller.seller = rawSeller;
    this.props.seller.editableSellerAccountDetail = false;
    this.clearValidateInputs();
  }

  editConfig() {
    this.props.seller.editableSellerAccountDetail = !this.props.seller.editableSellerAccountDetail;
  }

  async approveSellerByUid(uid) {
    let result = await swal.fire({
      customClass: 'nexter-alert',
      title: 'อนุมัติข้อมูลบัญชี',
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ยืนยัน',
      reverseButtons: true,
    });
    if (result.value) {
      this.props.seller.approveSellerByUid(uid);
    }
  }

  async rejectSellerByUid(uid) {
    const { value: text } = await swal.fire({
      customClass: 'nexter-alert',
      input: 'textarea',
      title: 'ปฏิเสธข้อมูลบัญชี',
      inputPlaceholder: 'ระบุเหตุผลในการปฏิเสธ',
      inputAttributes: {
        'aria-label': 'ระบุเหตุผลในการปฏิเสธ',
      },
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ยืนยัน',
      reverseButtons: true,
    });
    if (!text) {
      swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: 'เกิดข้อผิดพลาด!',
        text: 'กรุณากรอกเหตุผลในการปฏิเสธ',
        confirmButtonText: 'กลับไป',
      });
    } else {
      this.props.seller.changeRejectReasonSellersAccount(text);
      this.props.seller.rejectSellerByUid(uid);
    }
  }

  changeBank(selected) {
    this.props.seller.changeBank(selected.value, selected.label);
    this.validateInputs('bank_name');
  }

  changeBankAccountName(event) {
    this.props.seller.changeBankAccountName(event.target.value);
    this.validateInputs('bank_account_name');
  }

  changeBankAccountNumber(event) {
    this.props.seller.changeBankAccountNumber(event.target.value);
    this.validateInputs('bank_account_no');
  }

  async removeBookBankImage() {
    const sa2Confirm = await swal.fire({
      customClass: 'nexter-alert',
      type: 'warning',
      title: 'Delete this item',
      text: 'Are you sure you want delete this item ?',
      showCancelButton: true,
      cancelButtonText: 'CANCEL',
      confirmButtonText: 'DELETE',
      onOpen: () => swal.getConfirmButton().blur(),
    });

    if (sa2Confirm.value) {
      this.props.seller.changeBankAccountImage(null);
      this.validateInputs('bank_account_image');
    }
  }

  changeBankAccountImage(acceptedFiles) {
    if (acceptedFiles && acceptedFiles.length) {
      this.props.seller.changeBankAccountImage(acceptedFiles[0]);
      const invalid_message = this.state.invalid_message;
      invalid_message['bank_account_image'] = '';
      this.setState({ invalid_message });
    }
  }

  saveSellerBankAccount() {
    this.props.seller.updateSellerBankAccount(() => this.validateInputs());
  }

  toggleImage = () => {
    this.setState({ modal: !this.state.modal });
  };

  getFileName(filename) {
    return filename?.name || basename(filename);
  }

  async downloadFile(url, expire) {
    let fileUrl = await this.props.import.exportPresignedFileUrl(url, expire);
    if (fileUrl) {
      window.open(fileUrl);
    }
  }

  bankAccountNoValidation(val) {
    let message = '';
    let invalid = false;
    if (!/^[0-9]*$/.test(val)) {
      invalid = true;
      message = ERROR_MESSAGES.bank_account_no;
    }
    return { invalid, message };
  }

  validateInputs(key = null) {
    const { seller } = this.props.seller.getData();
    const fieldsToCheck = key ? [key] : ['bank_name', 'bank_account_name', 'bank_account_no', 'bank_account_image'];
    const invalid_message = this.state.invalid_message;
    const account = _.pick(seller.sellers_account, ['bank_name', 'bank_account_name', 'bank_account_no', 'bank_account_image']);
    const form = { ...account };
    fieldsToCheck.forEach(field => (invalid_message[field] = null));
    fieldsToCheck.forEach(field => {
      const data = form[field];
      const defaultValidator = [undefined, null, ''].includes(data);
      switch (field) {
        case 'bank_account_no': {
          const { invalid, message } = this.bankAccountNoValidation(data);
          if (invalid) {
            invalid_message[field] = message;
          }
          break;
        }
        default:
          if (defaultValidator) invalid_message[field] = ERROR_MESSAGES[field];
      }
    });
    this.setState({ invalid_message });
    return !Object.keys(invalid_message).some(key => invalid_message[key] !== null);
  }

  clearValidateInputs() {
    this.setState({ invalid_message: [] });
  }

  resubmitOpnRecipient() {
    this.props.seller.resubmitOpnRecipient();
  }

  canResubmitOpnRecipient() {
    const { user_rp } = this.props.user.getData();
    const { seller } = this.props.seller.getData();
    const recipient = seller.channel_seller?.opn_recipient;
    const isPermissionEdit = ['seller:edit'].some(role => user_rp.includes(role));
    return (
      isPermissionEdit &&
      recipient?.status === 'pending' &&
      moment(recipient?.updated_at)
        .startOf('day')
        .add(OPN_WAITING_RESUBMIT_DAY + 1, 'days')
        .isBefore(moment())
    );
  }

  render() {
    let { seller } = this.props.seller.getData();
    const { invalid_message } = this.state;
    const { banks } = this.props.bank.getData();
    const bankOptions = banks.map(bank => ({ value: bank.code, label: bank.fullname_th }));

    const currentBank = {
      value: seller.sellers_account.bank_code,
      label: seller.sellers_account.bank_name,
    };

    const statusIcon = {
      pending: 'fas fa-clipboard-list',
      created: 'far fa-file-alt',
      incompleted: 'far fa-file-alt',
      incomplete: 'far fa-file-alt',
      approved: 'fa fa-check-circle',
      rejected: 'far fa-times-circle',
    };

    return (
      <Page>
        <CardHeader>
          <Row>
            <Col md={3}>
              <h1 className="text-data-seller">ข้อมูลบัญชี</h1>
            </Col>
            <Col className="text-right p-0">
              <Col>
                {this.props.seller.editableSellerAccountDetail && !this.props.createNewSeller ? (
                  <Visibility permission_code="seller:edit">
                    <NexterButton className="my-0 nexter-default-outline" size="sm" color="default" onClick={() => this.onCancel()} outline>
                      CANCEL
                    </NexterButton>
                    <NexterButton className="ml-3 mr-0 my-0" size="sm" icon="fa fa-save" color="success" onClick={() => this.saveSellerBankAccount()} outline>
                      SAVE
                    </NexterButton>
                  </Visibility>
                ) : (
                  !this.props.createNewSeller && (
                    <>
                      <div className="d-inline-block">
                        <div className="d-inline-block">
                          <div className="d-inline-block font-weight-bold mr-2">สถานะบัญชี:</div>
                          <NexterButton
                            className={`my-0 text-capitalize nexter-bt-${seller.sellers_account.status || 'incomplete'}`}
                            size="sm"
                            icon={statusIcon[seller.sellers_account.status || 'incomplete']}
                            outline
                          >
                            {seller.sellers_account.status || 'incomplete'}
                          </NexterButton>
                        </div>
                        {!!seller.channel_seller?.channel?.opn_account && (
                          <div className="d-inline-block ml-3">
                            <div className="d-inline-block font-weight-bold mr-2">สถานะบัญชี OPN:</div>
                            <NexterButton
                              className={`my-0 text-capitalize nexter-bt-${getOpnRecipientStatus(seller.sellers_account.status, seller.channel_seller?.opn_recipient?.status)}`}
                              size="sm"
                              icon={statusIcon[getOpnRecipientStatus(seller.sellers_account.status, seller.channel_seller?.opn_recipient?.status)]}
                              outline
                            >
                              {getOpnRecipientStatus(seller.sellers_account.status, seller.channel_seller?.opn_recipient?.status)}
                            </NexterButton>
                          </div>
                        )}
                      </div>

                      {seller.sellers_account.status === 'pending' && (
                        <Visibility permission_code="seller:approve">
                          <div className="d-inline-block ml-3 align-middle border-right border-left" style={{ height: '36px' }} />
                          <NexterButton className="ml-3 my-0 text-capitalize nexter-bt-Rejected" size="sm" icon="far fa-times-circle" onClick={this.rejectSellerByUid.bind(this, seller.uid)} outline>
                            Reject
                          </NexterButton>
                          <NexterButton className="ml-3 my-0 text-capitalize nexter-bt-Approve" size="sm" icon="fas fa-check-circle" onClick={this.approveSellerByUid.bind(this, seller.uid)} outline>
                            Approve
                          </NexterButton>
                        </Visibility>
                      )}
                      {seller.sellers_account.status !== 'pending' && !['requested', 'approved'].includes(seller.delete_status) && (
                        <Visibility permission_code="seller:edit">
                          <div className="d-inline-block ml-3 align-middle border-right border-left" style={{ height: '36px' }} />
                          <NexterButton className="ml-3 mr-0 my-0" size="sm" icon="fa fa-pencil-alt" onClick={() => this.editConfig()}>
                            EDIT
                          </NexterButton>
                        </Visibility>
                      )}
                    </>
                  )
                )}
              </Col>
            </Col>
          </Row>
        </CardHeader>
        <hr />
        <CardBody>
          {this.props.seller.editableSellerAccountDetail ? (
            <>
              <Row className="mt-4">
                <Col>
                  <FormGroup className="required">
                    <Label className="control-label text-label">ธนาคาร</Label>
                    <NexterInput
                      className="select-bank-seller"
                      type="select"
                      size="lg"
                      value={currentBank}
                      options={bankOptions}
                      onChange={this.changeBank.bind(this)}
                      invalid={!!invalid_message['bank_name']}
                    />
                    <FormFeedback>{invalid_message['bank_name']}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="required">
                    <Label className="control-label text-label">ชื่อบัญชี</Label>
                    <NexterInput value={seller.sellers_account.bank_account_name || ''} onChange={this.changeBankAccountName.bind(this)} invalid={!!invalid_message['bank_account_name']} />
                    <FormFeedback>{invalid_message['bank_account_name']}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="6" sm="6">
                  <FormGroup className="required">
                    <Label className="control-label text-label">เลขบัญชี</Label>
                    <NexterInput
                      className="text-capitalize"
                      type="text"
                      allow="numeric"
                      value={seller.sellers_account.bank_account_no || ''}
                      onChange={this.changeBankAccountNumber.bind(this)}
                      pattern="[0-9]*"
                      maxLength={20}
                      invalid={!!invalid_message['bank_account_no']}
                    />
                    <FormFeedback>{invalid_message['bank_account_no']}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs="6" sm="6">
                  <FormGroup className="required">
                    <Label className="control-label text-label">สำเนาสมุดบัญชี</Label>
                    <FileUpload
                      previewSrc={this.getFileName(seller.sellers_account.bank_account_image)}
                      onRemove={this.removeBookBankImage.bind(this)}
                      onChange={this.changeBankAccountImage.bind(this)}
                      height="90"
                      width="90"
                      inputProps={{
                        multiple: false,
                        accept: 'image/png, image/jpeg, application/pdf',
                      }}
                      dropZoneProps={{ maxSize: 5242880 }}
                      titleButton="UPLOAD"
                      titlePre="รองรับเฉพาะไฟล์ .pdf, .jpg, .png"
                    />
                    <FormFeedback className={classNames({ 'd-block': invalid_message['bank_account_image'] })}>{invalid_message['bank_account_image']}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </>
          ) : (
            <>
              {seller.sellers_account.status === 'rejected' && (
                <Alert className="alert-danger-light text-dark">
                  <span className="font-weight-bold">เหตุผลในการปฏิเสธ : </span>
                  {seller.sellers_account.reject_reason || '-'}
                </Alert>
              )}
              <OpnAccountAlert
                status={seller.channel_seller?.opn_recipient?.status}
                rejectReason={OPN_REJECT_REASON_ACCOUNT[seller.channel_seller?.opn_recipient?.failure_code] || seller.channel_seller?.opn_recipient?.failure_code}
                canResubmit={this.canResubmitOpnRecipient()}
                onResubmit={this.resubmitOpnRecipient.bind(this)}
              />
              <Row className="mt-4">
                <Col>
                  <Label className="text-label">ธนาคาร</Label>
                </Col>
                <Col>
                  <Label className="text-label">ชื่อบัญชี</Label>
                </Col>
                <Col>
                  <Label className="text-label">เลขบัญชี</Label>
                </Col>
                <Col>
                  <Label className="text-label">Dealer Code (T2P)</Label>
                </Col>
              </Row>
              <Row className="text-capitalize">
                <Col>
                  <p className="text-data">{seller.sellers_account.bank_name || <span className="data-is-null">-</span>}</p>
                </Col>
                <Col>
                  <p className="text-data">{seller.sellers_account.bank_account_name || <span className="data-is-null">-</span>}</p>
                </Col>
                <Col>
                  <p className="text-data text-lowercase">{seller.sellers_account.bank_account_no || <span className="data-is-null">-</span>}</p>
                </Col>
                <Col>
                  <p className="text-data">{'SCGNexterPay'}</p>
                  {/* <p className="text-data">{seller.sellers_account.dealer_code || 'SCGNexterPay'}</p> */}
                </Col>
              </Row>
              <Row className="mt-4">
                <Visibility isVisibility={seller.delete_status !== 'approved'}>
                  <Col>
                    <Label className="text-label">สำเนาสมุดบัญชี</Label>
                  </Col>
                </Visibility>
              </Row>
              <Row className="text-capitalize mt-2">
                <Visibility isVisibility={seller.delete_status !== 'approved'}>
                  {seller.sellers_account?.bank_account_image ? (
                    pathinfo(seller.sellers_account.bank_account_image, 'PATHINFO_EXTENSION') === 'pdf' ? (
                      <Col xs={12}>
                        <iframe frameBorder={0} width="100%" height="800px" src={`${seller.sellers_account.bank_account_image}#zoom=FitH`}></iframe>
                        <div className="text-center">
                          <NexterButton className="mt-1" size="sm" icon="fa fa-search" color="info" onClick={() => this.downloadFile(seller.sellers_account.bank_account_image)} outline>
                            ดูแบบเต็มจอ
                          </NexterButton>
                        </div>
                      </Col>
                    ) : (
                      <Col xs={12} lg={6}>
                        <img
                          src={seller.sellers_account.bank_account_image}
                          className="img-fluid mx-auto mb-3 d-block cursor-pointer"
                          alt="bookbank"
                          onClick={() => this.downloadFile(seller.sellers_account.bank_account_image)}
                        />
                      </Col>
                    )
                  ) : (
                    <Col xs={12} lg={6}>
                      <span className="data-is-null">-</span>
                    </Col>
                  )}
                </Visibility>
              </Row>
            </>
          )}
        </CardBody>
      </Page>
    );
  }
}

export default inject('seller', 'bank', 'import', 'user')(observer(DataAccountDetail));
