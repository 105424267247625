import queryString from 'query-string';
import { config } from '../config';

class ADAuth {
  getLoginUrl() {
    const query = {
      response_type: 'code',
      response_mode: 'query',
      prompt: 'login',
      client_id: config.azure.ad.client_id,
      redirect_uri: config.npay.apiUrl + '/ad/authen/callback',
      scope: 'openid offline_access https://graph.microsoft.com/user.read',
    };
    const query_string = queryString.stringify(query);
    const url = `${config.azure.ad.auth_path}/authorize?${query_string}`;
    return url;
  }

  getLogoutUrl() {
    const query = {
      client_id: config.azure.ad.client_id,
      post_logout_redirect_uri: config.npay.cmsUrl,
    };
    const query_string = queryString.stringify(query);
    const url = `${config.azure.ad.auth_path}/logout?${query_string}`;
    return url;
  }

  login() {
    const url = this.getLoginUrl();
    window.open(url, '_self');
  }

  logout() {
    const url = this.getLogoutUrl();
    window.open(url, '_self');
  }
}

export default new ADAuth();
