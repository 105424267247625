import React, { Component, Fragment } from 'react';
import { Page } from '../../components/Page';
import HistoryLogTable from './HistoryLogTable'

class ChannelHistoryLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
  }

  render() {
    const { loading } = this.state;

    return (
      <Fragment>
        <Page loading={loading}>
          <div className="historylog-wrapper">
            <h5 className="title-logs" style={{ marginTop: 4, marginBottom: 0 }}>ประวัติการแก้ไข</h5>
            <hr />
            <HistoryLogTable setLoading={(load) => this.setState({ loading: load })} />
          </div>
        </Page>
      </Fragment>
    );
  }
}

export default ChannelHistoryLog;
