// import * as moment from 'moment';

const moment = require('moment');
const random = require('unique-random')(100, 999);

class Unique {
  generatePaymentUid() {
    return `PAY${moment().format('YYMMDDHHmmss') + random()}`;
  }

  generateChannelUid() {
    return `CH${moment().format('YYMMDDHHmmss') + random()}`;
  }

  generateSellerUid() {
    return `SL${moment().format('YYMMDDHHmmss') + random()}`;
  }

  generateReportNumber() {
    return `TR${moment().format('YYMMDDHHmmss') + random()}`;
  }

  generateSellerCode() {
    return `SC${moment().format('YYMMDDHHmmss') + random()}`;
  }
}

export const unique = new Unique();
