import React, { Component, Fragment } from 'react';
import { PaymentReport } from '../../../report/PaymentReport'
import { inject, observer } from 'mobx-react';
import { Amount } from '../../../../components/Field/Amount';

import { Table } from 'reactstrap';
export class R1Report extends Component {
	render() {
		const { payment } = this.props.payment.getData()
		let totalNet = 0;
		let totalVat = 0;
		let totalDiscount = 0;
		let rows = payment.items.map(item => {
			let net = item.subtotal - item.discount;
			let vat = ((item.subtotal - item.discount) * 100) / 107;
			totalNet += net;
			totalVat += vat;
			totalDiscount += item.discount;
			return (
				<tr key={`item-${item.id}`}>
					<td>{item.product_code || '-'}</td>
					<td>{item.product_category || '-'}</td>
					<td>{item.description}</td>
					<td className="text-right"><Amount value={vat} /></td>
					<td className="text-right"><Amount value={item.subtotal - vat} /></td>
					<td className="text-right"><Amount value={net} /></td>
					<td className="text-right"><Amount value={item.discount} /></td>
					<td className="text-right"><Amount value={item.subtotal} /></td>
				</tr>
			);
		});
		return (
			<Fragment>
				<h5 className="title">รายการคำสั่งซื้อ</h5>
				<hr />
				<div className="table-reponsive table-scroll">
					<Table>
						<thead>
							<tr>
								<th width="100" className="border-0">รหัสสินค้า</th>
								<th width="120" className="border-0">หมวดหมู่</th>
								<th className="border-0">รายการ</th>
								<th width="100" className="border-0 text-right">ราคาก่อน VAT</th>
								<th width="100" className="border-0 text-right">VAT (7%)</th>
								<th width="100" className="border-0 text-right">ราคารวม VAT</th>
								<th width="100" className="border-0 text-right">ส่วนลดคืน seller</th>
								<th width="100" className="border-0 text-right">ราคาหลังหักส่วนลด</th>
								{/* <th width="100" className="border-0 text-right">ส่วนลด</th>
								<th width="100" className="border-0 text-right">ราคารวม VAT</th> */}
							</tr>
						</thead>
						<tbody>
							{rows}
							<tr>
								<td className="text-right" style={{ fontWeight: 700 }} colSpan={3}>ยอดรวม</td>
								<td className="text-right" style={{ fontWeight: 700 }}>
									<Amount value={totalVat} />
								</td>
								<td className="text-right" style={{ fontWeight: 700 }}>
									<Amount value={payment.total - totalVat} />
								</td>
								<td className="text-right" style={{ fontWeight: 700 }}>
									<Amount value={payment.total} />
								</td>
								<td className="text-right" style={{ fontWeight: 700 }}><Amount value={totalDiscount} /></td>
								<td className="text-right" style={{ fontWeight: 700 }}><Amount value={totalNet} /></td>
							</tr>
						</tbody>
					</Table>
				</div>
			</Fragment>
		);
	}
}
export default inject('transfer','payment')(observer(R1Report));