import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Row, Col, FormGroup, FormFeedback, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import NexterButton from '../Button/NexterButton';
import NexterInput from '../Input/NexterInput';
import { validation } from '../../utils/validation';

class ContractPersonList extends Component {
  phoneValidation(val) {
    let message = '';
    let invalid = false;
    if (
      !validation.phone(val) ||
      !_.startsWith(val, '0') ||
      !(9 <= val.length && val.length <= 10) ||
      (val.length === 9 && !_.startsWith(val, '02')) ||
      (val.length === 10 && _.startsWith(val, '02')) ||
      _.startsWith(val, '00')
    ) {
      invalid = true;
      message = 'กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง';
      if (val === '') message = 'กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง';
      else if (!_.startsWith(val, '0')) message = 'หมายเลขโทรศัพท์ต้องขึ้นต้นด้วย 0';
      else if (!(9 <= val.length && val.length <= 10)) message = 'หมายเลขโทรศัพท์ต้องมีความยาว 9-10 หลัก';
      else if ((val.length === 9 && !_.startsWith(val, '02')) || (val.length === 10 && _.startsWith(val, '02')) || _.startsWith(val, '00')) message = 'รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง';
    }
    return { invalid, message };
  }

  render() {
    const { items, typeOptions, disabled, onChange, onSelect, onRemove, onAdd } = this.props;
    return (
      <>
        {items.map((el, i) => (
          <Fragment key={i}>
            {i > 0 && <hr />}
            <div className="d-flex justify-content-between align-items-center">
              <h6>No. {i + 1}</h6>
            </div>
            <Row>
              <Col md={6}>
                <FormGroup className="required">
                  <Label className="control-label form-label">Name</Label>
                  <NexterInput size="sm" name="name" value={el.name} invalid={!!el.name_invalid} disabled={disabled} onChange={e => onChange(i, e)} className="nexter-input-sm" />
                  <FormFeedback>{el.name_invalid}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="required">
                  <Label className="control-label form-label">Email</Label>
                  <NexterInput size="sm" name="email" value={el.email} invalid={!!el.email_invalid} disabled={disabled} onChange={e => onChange(i, e)} className="nexter-input-sm" />
                  <FormFeedback>{el.email_invalid}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="required">
                  <Label className="control-label form-label">Telephone No.</Label>
                  <NexterInput
                    size="sm"
                    allow="tel"
                    name="phone"
                    value={el.phone}
                    onChange={e => onChange(i, e)}
                    pattern="[0-9]*"
                    invalid={el.hasOwnProperty('phone_invalid') && el.phone_invalid.length > 0}
                    disabled={disabled}
                    maxLength={10}
                    className="nexter-input-sm"
                  />
                  {el.hasOwnProperty('phone_invalid') &&
                    el.phone_invalid.length > 0 &&
                    el.phone_invalid.map((item, i) => {
                      return <FormFeedback key={i}>{item}</FormFeedback>;
                    })}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="required">
                  <Label className="control-label form-label">Contract Type</Label>
                  <NexterInput
                    type="select"
                    size="sm"
                    options={typeOptions}
                    value={el.type}
                    invalid={!!el.type_invalid}
                    disabled={disabled}
                    onChange={selected => onSelect(selected, i)}
                    className="nexter-select-sm"
                    placeholder="Choose Contract Type…"
                  />
                  <FormFeedback>{el.type_invalid}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                {items.length > 1 && (
                  <NexterButton className="my-0 mr-2" size="sm" color="dark" outline icon="fas fa-minus-circle" disabled={disabled} onClick={e => onRemove(i, e)}>
                    Remove
                  </NexterButton>
                )}
                {i === items.length - 1 && (
                  <NexterButton className="my-0" size="sm" icon="fa fa-plus" disabled={disabled} onClick={e => onAdd(i, e)}>
                    Add Contact
                  </NexterButton>
                )}
              </Col>
            </Row>
          </Fragment>
        ))}
      </>
    );
  }
}

/**
 * items object requires
 {
    name: '',
    email: '',
    phone: '',
    type: '',
    name_invalid: '',
    email_invalid: '',
    phone_invalid: '',
    type_invalid: '',
  }
 */
ContractPersonList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  typeOptions: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  onAdd: PropTypes.func,
};

export default ContractPersonList;
