import React, { Component } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { Col, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CheckBox } from '../../components/Input/CheckBox';
import Visibility from '../../components/Visibility/Visibility';
import NexterInput from '../../components/Input/NexterInput';
import NexterButton from '../../components/Button/NexterButton';
import CustomInputDatePicker from '../../components/Input/DatePicker';
import PropTypes from 'prop-types';
import PaymentModalFilter from './PaymentModalFilter';
import _ from 'lodash';

const inputStyles = classNames('nexter-input-sm');
const rowStyles = classNames('pb-3');
const colStyles = classNames('pr-0 pl-1');
const colDefaultProps = {
  xs: { size: 12 },
  lg: { size: 3 },
};
const rowModalStyles = classNames('mx-0 pb-3');
const colModalStyles = classNames('px-0');
const colModalDefaultProps = {
  xs: { size: 12 },
  lg: { size: 4 },
};
const initialState = {
  modalFilter: false,
  filters: {
    statuses: [
      { value: 'created', label: 'Created', checked: false },
      { value: 'pending', label: 'Pending', checked: false },
      { value: 'success', label: 'Success', checked: false },
      { value: 'failed', label: 'Failed', checked: false },
      { value: 'settled', label: 'Settled', checked: false },
      { value: 'voided', label: 'Voided', checked: false },
      { value: 'refunded', label: 'Refunded', checked: false },
      { value: 'cancel', label: 'Cancel', checked: false },
      { value: 'pending_settle', label: 'Pending Settle', checked: false },
      { value: 'mismatch_settled', label: 'Mismatch Settled', checked: false },
    ],
    tranferStatus: [
      { value: 'pre-transfer', label: 'Pre-Transfer', checked: false },
      { value: 'transferring', label: 'Transferring', checked: false },
      { value: 'pending', label: 'Pending', checked: false },
      { value: 'transferred', label: 'Transferred', checked: false },
      { value: '-', label: '-', checked: false },
    ],
    date_selected: [
      { value: 'payment_at', label: 'ชำระเงิน', checked: false },
      { value: 'updated_at', label: 'แก้ไขล่าสุด', checked: false },
    ],
    cards: [
      { value: 'credit_card', label: 'Credit card', checked: false },
      { value: 'bill_payment_mobile', label: 'Mobile banking', checked: false },
      { value: 'bill_payment', label: 'Counter Service', checked: false },
      { value: 'scg_wallet', label: 'SCG Wallet', checked: false },
      { value: 'installment', label: 'Installment', checked: false },
      { value: 'line', label: 'Line Pay', checked: false },
      { value: 'qr_box', label: 'Bill Payment (BBL)', checked: false },
      { value: 'chang_family_wallet', label: 'Chang Family Wallet', checked: false },
      { value: 'promptpay', label: 'Thai QR Payment', checked: false },
      { value: 'grabpay', label: 'GrabPay', checked: false },
      { value: 'cbdc', label: 'CBDC Payment', checked: false },
      { value: 'mobile_banking_opn', label: 'Mobile Banking (App Switch)', checked: false },
    ],
  },
  defaultFilters: {},
  hightLight: [],
  isSuperAdmin: false,
  isChannelAdmin: false,
};
class PaymentSearch extends Component {
  constructor(props) {
    super(props);

    // we need to use clone deep because it has array object.
    this.state = _.cloneDeep(initialState);

    this.toggleFilter = this.toggleFilter.bind(this);
    this.executeFilter = this.executeFilter.bind(this);
    this.onFilterChecked = this.onFilterChecked.bind(this);
    this.clearFilterState = this.clearFilterState.bind(this);
  }

  async componentDidMount() {
    let { loggedinUser } = this.props.user.getData();
    switch (loggedinUser.type) {
      case 'super_admin':
      case 'ae_marketing':
      case 'biz_system':
      case 'manager':
        await this.props.channel.fetchChannelAll();
        this.setState({ isSuperAdmin: true });
        break;
      case 'channel_admin':
      case 'channel_user':
      case 'as_channel_admin':
        this.props.payment.changeChannelUid(loggedinUser.channel?.uid || '');
        this.props.payment.selectPage(1);
        this.props.seller.fetchSellersByStatus('approved,created,pending,rejected');
        this.setState({ isChannelAdmin: true });
        break;
      default:
        break;
    }
  }
  executeFilter() {
    this.setState({ modalFilter: !this.state.modalFilter, defaultFilters: {} });
  }
  clearFilterState() {
    this.setState({ filters: _.cloneDeep(initialState.filters) });
  }

  onClear() {
    this.setState({ filters: _.cloneDeep(initialState.filters) });
    this.props.payment.changeRefId('');
    this.props.payment.changeStartDate('');
    this.props.payment.changeEndDate('');
    this.props.payment.changeChannel('');
    this.props.payment.changeStatus('');
    this.props.payment.changeTransferStatus('');
    this.props.payment.changedateSelected('');
    this.props.payment.changeCardType('');
    this.state.hightLight = [''];
    this.props.payment.condition.expireFilter = true;
    this.props.payment.selectPage(1);
  }
  onFind() {
    const { filters } = this.state;
    const statuses = filters.statuses.filter(s => s.checked);
    const tranferStatus = filters.tranferStatus.filter(s => s.checked);
    const date_selected = filters.date_selected.filter(s => s.checked)[0] ?? null; // object not array
    const cards = filters.cards.filter(s => s.checked);
    this.props.payment.changeStatus(statuses);
    this.props.payment.changeTransferStatus(tranferStatus);
    this.props.payment.changedateSelected(date_selected);
    this.props.payment.changeCardType(cards);
    this.props.payment.selectPage(1); // set page to 1 and fetch channel payment
    // this.props.payment.fetchChannelPayments();
  }
  exportFile() {
    this.props.payment.exportFile({ source: 'Payment' });
  }
  /** METHOD */

  /** COMPUTED */
  channelOptionList() {
    const { channels } = this.props.channel.getData();
    return channels.all.map(c => ({ value: c.uid, label: c.name }));
  }
  sellerOptionList() {
    const { channelSellers } = this.props.seller.getData();
    return channelSellers
      .filter(s => ['channel', 'partner'].find(str => str === s.seller.type))
      .map(s => {
        const id = s.seller.id;
        const name = channelSellers.filter(seller => seller.seller.name === s.seller.name).length > 1 ? `${s.seller.name} (${s.seller_code})` : s.seller.name;
        const type = s.seller.type;
        return { value: id, label: type === 'channel' ? '** ' + name : name };
      })
      .sort((f,s) => {
        if(f.label.startsWith('**')) {
          return -2;
        } else {
          if(f.label < s.label) return -1;
          else return 1;
        }
      });
  }
  /** COMPUTED */

  /** WATCH */
  toggleFilter() {
    const toggle = !this.state.modalFilter;
    // set filters back when closing (not save).
    const filters = _.cloneDeep(!toggle ? this.state.defaultFilters : this.state.filters);
    // set default filters when opening.
    const defaultFilters = _.cloneDeep(toggle ? this.state.filters : {});
    this.setState({ modalFilter: toggle, filters, defaultFilters });
  }
  onFilterChecked(index, type) {
    const filters = _.cloneDeep(this.state.filters);
    // select only one
    if (type === 'date_selected') {
      filters[type] = filters[type].map(f => ({ ...f, checked: false }));
    }
    filters[type][index].checked = !filters[type][index].checked;
    this.setState({ filters });
  }

  changeRefId(event) {
    let data = event.target.value;
    this.props.payment.changeRefId(data);
    this.state.hightLight = [data];
  }
  onChannelSelected(selectedOption, actionMeta) {
    const channelUid = selectedOption.value;
    this.props.payment.changeChannelUid(channelUid);
    // this.props.payment.selectPage(1);
    this.onClear();
    this.props.seller.fetchSellersByStatus('approved,created,pending,rejected', channelUid);
    this.props.payment.clearPaymentDetail();
    //this.props?.back();
  }
  changeChannel(optionSelected) {
    this.props.payment.changeChannel(optionSelected);
  }
  changeStartDate(date) {
    this.props.payment.changeStartDate(date);
  }
  changeEndDate(date) {
    this.props.payment.changeEndDate(date);
  }
  toggleChangeExpireFilter = () => {
    this.props.payment.toggleChangeExpireFilter();
  };
  /** WATCH */

  render() {
    const { modalFilter, filters, isSuperAdmin, isChannelAdmin } = this.state;
    const { showSearchPanel } = this.props;
    let { payments, condition, loading } = this.props.payment.getData();

    const channelOptions = this.channelOptionList();
    const selectedChannel = channelOptions.find(co => co.value === condition.channel_uid) ?? '';

    return (
      <>
        {/* uses mr-0 because cols use pr-0 */}
        <Row className={classNames(rowStyles, 'no-print')}>
          <Col {...colDefaultProps} className={classNames(colStyles, 'pl-0')}>
            <NexterInput
              type="select"
              className={'nexter-select-sm payment-channel-select'}
              options={channelOptions}
              onChange={this.onChannelSelected.bind(this)}
              value={selectedChannel}
              disabled={!isSuperAdmin || isChannelAdmin}
              placeholder="เลือก CHANNEL"
            />
          </Col>
          {showSearchPanel && (
            <>
              <Col {...colDefaultProps} className={colStyles}>
                <Visibility permission_code="payment:transaction_search">
                  <NexterInput
                    type="select"
                    className={'nexter-select-sm'}
                    isClearable
                    placeholder="เลือกผู้ขาย/ผู้รับเงิน..."
                    options={this.sellerOptionList()}
                    value={condition.seller_selected}
                    onChange={this.changeChannel.bind(this)}
                  />
                </Visibility>
              </Col>
              <Col
                {...colDefaultProps}
                // lg={{ size: 2, offset: 4 }}
                // xl={{ size: 1, offset: 5 }}
                lg="6"
                className={classNames(colStyles, 'd-flex justify-content-end')}
              >
                <Visibility permission_code="payment:transaction_excel">
                  <NexterButton size="sm" icon="fas fa-download" onClick={this.exportFile.bind(this)}>
                    excel
                  </NexterButton>
                </Visibility>
              </Col>
            </>
          )}
        </Row>
        {/* uses mr-0 because cols use pr-0 */}
        {showSearchPanel && (
          <Visibility permission_code="payment:transaction_search">
            <Row className={rowStyles}>
              <Col lg="3" className={classNames(colStyles, 'pl-0')}>
                <NexterInput size="sm" className={inputStyles} value={condition.ref_id} onChange={this.changeRefId.bind(this)} placeholder="ค้นหา REF. ID , ผู้ชำระเงิน" />
              </Col>
              <Col lg="3" className={colStyles}>
                <Row className="mx-0">
                  <Col xs={6} className="px-0">
                    <DatePicker
                      customInput={
                        <CustomInputDatePicker size="sm" iconCalendarStyle={{ position: 'absolute', right: 5 }}>
                          {condition.start_date ? (
                            <span className="datepicker-custom-text">{moment(condition.start_date).format('DD/MM/YYYY')}</span>
                          ) : (
                            <span className="datepicker-custom-text">วันที่เริ่ม</span>
                          )}
                        </CustomInputDatePicker>
                      }
                      selected={condition.start_date}
                      onChange={this.changeStartDate.bind(this)}
                      locale="th"
                      dateFormat="dd/MM/yyyy"
                      maxDate={condition.end_date}
                    />
                  </Col>
                  <Col xs={6} className="px-0">
                    <DatePicker
                      customInput={
                        <CustomInputDatePicker size="sm" iconCalendarStyle={{ position: 'absolute', right: 5 }}>
                          {condition.end_date ? (
                            <span className="datepicker-custom-text">{moment(condition.end_date).format('DD/MM/YYYY')}</span>
                          ) : (
                            <span className="datepicker-custom-text">วันที่สิ้นสุด</span>
                          )}
                        </CustomInputDatePicker>
                      }
                      selected={condition.end_date}
                      onChange={this.changeEndDate.bind(this)}
                      locale="th"
                      dateFormat="dd/MM/yyyy"
                      minDate={condition.start_date}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg="6" className={classNames(colStyles, 'd-flex justify-content-between')}>
                <div>
                  <CheckBox label="แสดงรายการหมดอายุ" checked={!condition.expireFilter} onChange={this.toggleChangeExpireFilter.bind(this)} defaultClassName="" />
                  <NexterButton size="sm" color="info" outline onClick={this.onClear.bind(this)}>
                    clear
                  </NexterButton>
                  <NexterButton size="sm" color="dark" className="mx-1" icon="fas fa-search" onClick={this.onFind.bind(this)}>
                    search
                  </NexterButton>
                </div>
                <div>
                  <NexterButton size="sm" color="dark" icon="fas fa-filter" onClick={this.toggleFilter}>
                    Filter
                  </NexterButton>
                  <Modal isOpen={modalFilter} toggle={this.toggleFilter} className="modal-payment-filter">
                    <ModalBody>
                      <h6 className="text-capitalize">filters</h6>
                      <hr />
                      <h6>สถานะชำระเงิน</h6>
                      <Row className={rowModalStyles}>
                        {filters.statuses.map((props, i) => (
                          <Col key={i} {...colModalDefaultProps} className={colModalStyles}>
                            <CheckBox {...props} onChange={() => this.onFilterChecked(i, 'statuses')} defaultClassName="mx-0" />
                          </Col>
                        ))}
                      </Row>
                      <hr />
                      <h6>สถานะโอนเงิน</h6>
                      <Row className={rowModalStyles}>
                        {filters.tranferStatus.map((props, i) => (
                          <Col key={i} {...colModalDefaultProps} className={colModalStyles}>
                            <CheckBox {...props} onChange={() => this.onFilterChecked(i, 'tranferStatus')} defaultClassName="mx-0" />
                          </Col>
                        ))}
                      </Row>
                      <hr />
                      <h6>ช่องทางชำระเงิน</h6>
                      <Row className={rowModalStyles}>
                        {filters.cards.map((props, i) => (
                          <Col key={i} {...colModalDefaultProps} className={colModalStyles}>
                            <CheckBox {...props} onChange={() => this.onFilterChecked(i, 'cards')} defaultClassName="mx-0" />
                          </Col>
                        ))}
                      </Row>
                      <hr />
                      <h6>เฉพาะวันที่</h6>
                      <Row className={rowModalStyles}>
                        {filters.date_selected.map((props, i) => (
                          <Col key={i} {...colModalDefaultProps} className={colModalStyles}>
                            <CheckBox {...props} onChange={() => this.onFilterChecked(i, 'date_selected')} defaultClassName="mx-0" />
                          </Col>
                        ))}
                      </Row>
                      <hr />
                      <div className="d-flex justify-content-end">
                        <NexterButton size="sm" className="mx-2" onClick={this.clearFilterState.bind(this)}>
                          Clear all filters
                        </NexterButton>
                        <NexterButton size="sm" onClick={this.executeFilter.bind(this)}>
                          Done
                        </NexterButton>
                      </div>
                    </ModalBody>
                  </Modal>
                </div>
              </Col>
            </Row>
          </Visibility>
        )}
      </>
    );
  }
}

PaymentSearch.propTypes = {
  showSearchPanel: PropTypes.bool,
  back: PropTypes.func,
};

PaymentSearch.defaultProps = {
  showSearchPanel: true,
};

export default inject('payment', 'channel', 'seller', 'user')(observer(PaymentSearch));
