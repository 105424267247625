import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import FilePicker from './FilePicker';
import NexterButton from '../Button/NexterButton';
import { Col } from 'reactstrap';

class ImageUpload extends Component {
  render() {
    const { previewSrc, onRemove, onChange, disabled, inputProps, dropZoneProps, titleButton, titlePre } = this.props;
    const renderPreview = () => {
      switch (typeof previewSrc) {
        case 'array':
        case 'object':
          return (
            <>
              {previewSrc && previewSrc.length
                ? previewSrc.map((ps, i) => (
                  <div className="box-file-image" key={i}>
                    <span className="text-name-image">{ps}</span>
                    <span className="ml-1"> {!disabled && <i className="far fa-times-circle remove-container" onClick={() => onRemove && onRemove(i)} />}</span>
                  </div>
                )) : (
                  <span className="text-placeholder">{titlePre}</span>
                )
              }
            </>
          )
        default:
          return (
            <>
              {previewSrc ? (
                <div className="box-file-image">
                  <span className="text-name-image">{previewSrc}</span>
                  <span className="ml-1"> {!disabled && <i className="far fa-times-circle remove-container" onClick={onRemove} />}</span>
                </div>
              ) : (
                  <span className="text-placeholder">{titlePre}</span>
                )}
            </>
          )
      }
    }
    return (
      <div className="upload-image">
        <div className="box-file-container">
          {renderPreview()}
        </div>
        <div className="ml-2">
          <FilePicker
            onDrop={onChange}
            inputProps={{
              multiple: false,
              disabled,
              ...inputProps,
            }}
            dropZoneProps={{
              disabled,
              ...dropZoneProps,
              ...(inputProps.accept && { accept: inputProps.accept }),
            }}
          >
            <div className="d-flex">
              <NexterButton color="dark" size="sm" icon="fas fa-folder" disabled={disabled}>
                <span>{titleButton}</span>
              </NexterButton>
            </div>
          </FilePicker>
        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  titleButton: PropTypes.string,
  titlePre: PropTypes.string,
};

export default ImageUpload;
