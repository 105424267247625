import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem, Collapse } from 'reactstrap';

class ListGroupCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  componentDidMount() {
    const path = window.location.pathname;
    const data = this.props.data;
    this.state.collapse = data.views.some(el => {
      return el.path === path;
    });
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  clickMenu(path) {
    let sliceStr = path.slice(0, path.lastIndexOf('/') + 1);
    this.toggle();
    setTimeout(() => {
      this.toggle();
    }, 400);

    if (sliceStr === '/admin/transfers/gateway/') {
      this.props.transfer.clearData();
    }
  }

  render() {
    const path = window.location.pathname;
    const data = this.props.data;

    const collpaseLinks = data.views.map((prop, key) => {
      return (
        <NavItem key={key} className={prop.path === path ? 'active' : ''} onClick={this.clickMenu.bind(this, prop.path)}>
          <NavLink to={prop.path} activeClassName="active">
            <span className="text-uppercase" >
              <i className={prop.icon} /> {prop.name}
            </span>
          </NavLink>
        </NavItem>
      );
    });

    return (
      <Fragment >
        <li onClick={this.toggle.bind(this)}>
          <NavLink className="nav-link" to="#" activeClassName="active">
            <i className={data.icon} />
            <p>{data.name} <b className="caret" /></p>
          </NavLink>
        </li>
        <Collapse isOpen={this.state.collapse}>
          <Nav>
            {collpaseLinks}
          </Nav>
        </Collapse>
      </Fragment>
    );
  }
}

export default inject('transfer')(observer(ListGroupCollapse));
