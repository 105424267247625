import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Col, Row } from 'reactstrap';

import NexterButton from '../../components/Button/NexterButton';
import { Page } from '../../components/Page';

import 'react-datepicker/dist/react-datepicker.css';
import SellerList from './SellerList';
import ApproveSellerPage2 from './ApproveSellerPage2';
import SellerPendingDelete from './SellerPendingDelete';
import SellerDetailPage from '../seller/SellerDetail/SellerDetailPage';
import Visibility from '../../components/Visibility/Visibility';
import { config } from '../../config';

class SellerPage extends Component {
  componentDidMount() {
    this.props.seller.changeSellerMgmtView('list');
    this.props.seller.changeActiveTabSellerPage('seller-list');
  }

  componentWillUnmount() {
    this.props.seller.clearSellers();
    this.props.seller.clearFilter();
    this.props.seller.clearPagination();
  }

  exportExcelSellerT2P() {
    this.props.seller.exportExcelSellerT2P({ source: 'Seller' });
  }

  exportExcelSellerCommittee() {
    this.props.seller.exportExcelSellerCommittee({ source: 'Seller' });
  }

  exportExcelSeller() {
    let { activeTabSellerPage: activeTab } = this.props.seller.getData();
    let tab = 'all';
    if (activeTab === 'pending-seller') {
      tab = 'pending';
    } else if (activeTab === 'pending-delete') {
      tab = 'pending_delete';
    }
    this.props.seller.exportSeller(tab);
  }

  changeActiveTab(e) {
    const tabId = e.target.id;
    this.props.seller.changeActiveTabSellerPage(tabId);
  }

  render() {
    let { loading, sellerMgmtView, activeTabSellerPage: activeTab } = this.props.seller.getData();
    const { loggedinUser } = this.props.user.getData();
    return (
      <Page loading={loading}>
        {sellerMgmtView == 'list' && (
          <>
            <Row className="mb-2">
              <Col sm={2}>
                <p className="label-header text-uppercase">Seller</p>
              </Col>
              <Col className="text-right">
                {['super_admin', 'biz_system', 'ae_marketing', 'manager'].includes(loggedinUser.type) && (
                  <>
                    <NexterButton className="ml-2" size="sm" icon="fas fa-download align-middle" onClick={this.exportExcelSellerCommittee.bind(this)}>
                      Excel Dealer Committee
                    </NexterButton>

                    <NexterButton className="ml-2" size="sm" icon="fas fa-download align-middle" onClick={this.exportExcelSellerT2P.bind(this)}>
                      Export Dealer (T2P)
                    </NexterButton>

                    <NexterButton className="ml-2" size="sm" icon="fas fa-download align-middle" onClick={this.exportExcelSeller.bind(this)}>
                      Export
                    </NexterButton>
                  </>
                )}
                {['channel_admin', 'channel_user'].includes(loggedinUser.type) && (
                  <>
                    <NexterButton className="ml-2" size="sm" icon="fas fa-plus align-middle" onClick={() => this.props.history.push(`${config.web.rootpath}/sellers/register/mass`)}>
                      Create/Update Seller (Mass)
                    </NexterButton>

                    <NexterButton className="ml-2" size="sm" icon="fas fa-plus align-middle" onClick={() => this.props.history.push(`${config.web.rootpath}/sellers/register`)}>
                      Create New Seller
                    </NexterButton>

                    <NexterButton className="ml-2" size="sm" icon="fas fa-download align-middle" onClick={this.exportExcelSeller.bind(this)}>
                      Export
                    </NexterButton>
                  </>
                )}
              </Col>
            </Row>
            {['biz_system'].includes(loggedinUser.type) && (
              <Nav tabs className="no-print">
                <NavItem>
                  <NavLink href="#" active={activeTab === 'seller-list'} onClick={this.changeActiveTab.bind(this)} id="seller-list">
                    Seller List
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#" active={activeTab === 'pending-seller'} onClick={this.changeActiveTab.bind(this)} id="pending-seller">
                    Pending Seller
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#" active={activeTab === 'pending-delete'} onClick={this.changeActiveTab.bind(this)} id="pending-delete">
                    Pending Delete
                  </NavLink>
                </NavItem>
              </Nav>
            )}
          </>
        )}

        <TabContent activeTab={activeTab}>
          <TabPane tabId="seller-list">
            <Visibility isVisibility={activeTab == 'seller-list'}>
              {sellerMgmtView == 'list' && (
                <Card className="card-seller-management">
                  <SellerList isChannelView={['channel_admin', 'channel_user'].includes(loggedinUser.type)} />
                </Card>
              )}
              {sellerMgmtView == 'detail' && <SellerDetailPage parentTab="seller" />}
            </Visibility>
          </TabPane>
          <TabPane tabId="pending-seller">
            <Visibility isVisibility={activeTab == 'pending-seller'}>
              {sellerMgmtView == 'list' && (
                <Card className="card-seller-management">
                  <ApproveSellerPage2 />
                </Card>
              )}
              {sellerMgmtView == 'detail' && <SellerDetailPage parentTab="pending" />}
            </Visibility>
          </TabPane>
          <TabPane tabId="pending-delete">
            <Visibility isVisibility={activeTab == 'pending-delete'}>
              {sellerMgmtView == 'list' && (
                <Card className="card-seller-management">
                  <SellerPendingDelete />
                </Card>
              )}
              {sellerMgmtView == 'detail' && <SellerDetailPage parentTab="seller" />}
            </Visibility>
          </TabPane>
        </TabContent>
      </Page>
    );
  }
}

export default inject('seller', 'channel', 'user')(observer(SellerPage));
