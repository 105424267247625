import React from 'react';
import { Row, Col, Label, FormGroup, CustomInput } from 'reactstrap';
import { AUTO_TRANSFER_SCHEDULE_DAYS as DAYS } from '../../../constants/transferConstants';

const DAY_OPTIONS = [
  [
    { label: DAYS['mon'], value: 'mon' },
    { label: DAYS['tue'], value: 'tue' },
    { label: DAYS['wed'], value: 'wed' },
    { label: DAYS['thu'], value: 'thu' },
    { label: DAYS['fri'], value: 'fri' },
  ],
  [
    { label: DAYS['sat'], value: 'sat' },
    { label: DAYS['sun'], value: 'sun' },
  ],
];

const ScheduleWeekDaySelector = ({ days = [], onChange }) => {
  function onSelect(day) {
    // support multiple values in the future
    let selectedDays = [day];
    onChange(selectedDays);
  }

  return (
    <div className="schedule-week-day-selector">
      <FormGroup className="required">
        <Label className="control-label form-label mb-3">โปรดเลือกวันในการโอน (สามารถเลือกได้แค่ 1 วัน)</Label>
        <Row>
          {DAY_OPTIONS.map((group, index) => (
            <Col xs={6} key={index}>
              {group.map(option => (
                <CustomInput
                  key={option.value}
                  type="radio"
                  id={`schedule_week_day_${option.value}`}
                  name="schedule_week_day"
                  label={option.label}
                  value={option.value}
                  checked={days.includes(option.value)}
                  onChange={e => onSelect(e.target.value)}
                  className="mb-3"
                />
              ))}
            </Col>
          ))}
        </Row>
      </FormGroup>
    </div>
  );
};

export default ScheduleWeekDaySelector;
