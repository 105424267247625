
class LocalStorage {
  constructor(customStorage = null) {
    this.localStorage = customStorage || window.localStorage;
  }

  getItem(key) {
    return this.localStorage.getItem(key);
  }

  setItem(key, value) {
    this.localStorage.setItem(key, value);
  }

  removeItem(key) {
    this.localStorage.removeItem(key);
  }
}

export default new LocalStorage();
