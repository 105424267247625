import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Card, CardHeader, CardBody, TabContent, TabPane, Row, Col, Nav, NavItem, NavLink, Modal, ModalBody, ModalFooter } from 'reactstrap';
import PaymentInvoice from '../payment/PaymentInvoice';
import { Page } from '../../components/Page';

import { PaymentReport } from './PaymentReport';

import { TransferItemsTable } from '../payment/TransferItemsTable';
import CreateTransfersPanel from '../payment/CreateTransfersPanel';
import VoidAction from './VoidAction';
import RefundAction from './RefundAction';
import R2Report from '../transfer/component/Report/R2';
import NexterInput from '../../components/Input/NexterInput';
import NexterButton from '../../components/Button/NexterButton';

const rowStyles = classNames('mr-0 pb-3');
const colStyles = classNames('pr-0');

const statusSellerAccountToShow = ['pending', 'created', 'rejected'];

class PaymentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'tab-report1',
      id: props.paymentId || props.match?.params?.id,
      uid: props.paymentUid || props.match?.params?.uid,
      headerModal: {
        isOpen: false,
        value: '',
        id: null,
      },
      itemModal: {
        isOpen: false,
        value: '',
        id: null,
      },
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.getPayment();
  }

  async getPayment() {
    if (this.state.uid) {
      const { payment } = this.props.payment.getData()
      if (!payment || payment.uid !== this.state.uid || payment.sub_payments)
        await this.props.payment.getPaymentWithSubPayment(this.state.uid);
    } else {
      await this.props.payment.getPaymentByUid(this.state.id);
    }
    const { payment } = this.props.payment.getData()
    this.state.paymentId = payment.id;
    if (payment && payment.channel?.uid) {
      this.props.seller.fetchSellersByStatus('approved', payment.channel?.uid, { is_active: true, payment_id: payment.id });
    }
    const tab = new URL(window.location.href).searchParams.get('tab')
    if (tab === 'r1') this.toggle('tab-report1')
    else if (tab === 'r2') this.toggle('tab-service')
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) this.setState({ activeTab: tab });
  }

  changeActiveTab(tab) {
    this.setState({ activeTab: tab });
  }

  printPDF() {
    window.print();
  }

  inquiry() {
    this.props.payment.inquiryPayment(this.state.uid, true);
  }

  updateInvoice() {
    this.props.payment.updateInvoicePayment(this.state.uid, false);
  }

  renderPartialPayment(activeTab, subPayment, loading, loggedinUser) {
    const { user_info } = this.props.user.getData();
    let sellerName = subPayment.seller?.name || '';
    let sellerAccountStatus = subPayment.seller?.sellers_account?.status || '';

    sellerAccountStatus = statusSellerAccountToShow.includes(sellerAccountStatus) ? `(${sellerAccountStatus})` : '';
    sellerName = sellerName + ' ' + sellerAccountStatus;

    const isAllowedStatus = ['created', 'pending', 'pending_settle', 'success', 'settled'].includes(subPayment.status);
    const isAllowedTransferStatus = ['-', 'pending'].includes(subPayment.transfer_status);
    const isAllowedRole = ['channel_admin', 'channel_user', 'super_admin'].includes(user_info?.role?.role_code)

    const editDescription = isAllowedStatus && isAllowedTransferStatus && isAllowedRole

    return (
      <Page loading={loading} key={subPayment.id}>
        <Nav tabs className="no-print">
          <NavItem>
            <NavLink className={classNames({ active: activeTab === 'tab-report1' })} onClick={() => this.toggle('tab-report1')}>
              รายงาน R1
            </NavLink>
          </NavItem>
          {subPayment.seller_id ?
            <NavItem>
              <NavLink className={classNames({ active: activeTab === 'tab-service' })} onClick={() => this.toggle('tab-service')}>
                รายงาน R2
              </NavLink>
            </NavItem>
            : ''}
        </Nav>
        <TabContent activeTab={activeTab} className="card card-body pb-0 page-break">
          <TabPane tabId="tab-report1">
            <PaymentInvoice 
              sellerName={sellerName} 
              payment={subPayment} 
              paymentProps={this.props.payment}
              editDescription={editDescription}
              open={this.openHeaderModal.bind(this)}
            />
            <PaymentReport 
              payment={subPayment} 
              editDescription={editDescription}
              open={this.openItemModal.bind(this)}
            />
          </TabPane>
          <TabPane tabId="tab-service">
            <PaymentInvoice sellerName={sellerName} payment={subPayment} paymentProps={this.props.payment} />
            <hr />
            <R2Report payment={subPayment} />
          </TabPane>
        </TabContent>
      </Page>
    )
  }

  renderPartialPaymentWithVoid(activeTab, payment, loading, loggedinUser) {
    return (
      <>
        {payment.sub_payments.map((subPayment) => this.renderPartialPayment(activeTab, subPayment, loading, loggedinUser))}
        <Page loading={loading}>
          <Row className={classNames('no-print w-50', rowStyles)}>
            <Col className={colStyles}>
              <Card className="p2">
                <VoidAction paymentInfo={payment} userType={loggedinUser.type} />
              </Card>
            </Col>
          </Row>
        </Page>
      </>
    )
  }

  renderFullPayment(activeTab, payment, loading, loggedinUser) {
    const { user_info } = this.props.user.getData();
    let sellerName = payment.seller?.name || '';
    let sellerAccountStatus = payment.seller?.sellers_account?.status || '';

    sellerAccountStatus = statusSellerAccountToShow.includes(sellerAccountStatus) ? `(${sellerAccountStatus})` : '';
    sellerName = sellerName + ' ' + sellerAccountStatus;

    const isAllowedStatus = ['created', 'pending', 'pending_settle', 'success', 'settled'].includes(payment.status);
    const isAllowedTransferStatus = ['-', 'pending'].includes(payment.transfer_status);
    const isAllowedRole = ['channel_admin', 'channel_user', 'super_admin'].includes(user_info?.role?.role_code)

    const editDescription = isAllowedStatus && isAllowedTransferStatus && isAllowedRole

    return (
      <Page loading={loading}>
        <Nav tabs className="no-print">
          <NavItem>
            <NavLink className={classNames({ active: activeTab === 'tab-report1' })} onClick={() => this.toggle('tab-report1')}>
              รายงาน R1
            </NavLink>
          </NavItem>
          {payment.seller_id ?
            <NavItem>
              <NavLink className={classNames({ active: activeTab === 'tab-service' })} onClick={() => this.toggle('tab-service')}>
                รายงาน R2
              </NavLink>
            </NavItem>
            : ''}
          <NavItem>
            <NavLink className={classNames({ active: activeTab === 'tab-transfer' })} onClick={() => this.toggle('tab-transfer')}>
              ผู้ขาย/ผู้รับเงิน
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="card card-body pb-0">
          <TabPane tabId="tab-report1">
            <PaymentInvoice 
              sellerName={sellerName} 
              payment={payment} 
              paymentProps={this.props.payment} 
              editDescription={editDescription}
              open={this.openHeaderModal.bind(this)}
            />
            <PaymentReport 
              editDescription={editDescription}
              open={this.openItemModal.bind(this)}
              payment={payment} 
            />
          </TabPane>
          <TabPane tabId="tab-service">
            <PaymentInvoice sellerName={sellerName} payment={payment} paymentProps={this.props.payment} />
            <hr />
            <R2Report payment={payment} />
          </TabPane>
          <TabPane tabId="tab-transfer">
            <PaymentInvoice payment={payment} paymentProps={this.props.payment} />
            <hr />
            <div className="d-flex justify-content-between my-2">
              <h6 className="payment-title text-uppercase mt-auto mb-0">รายละเอียดค่าบริการตามรายสินค้า</h6>
              <CreateTransfersPanel />
            </div>
            <TransferItemsTable payment={payment} seller={payment.seller} />
          </TabPane>
        </TabContent>
        {/* uses mr-0 because cols use pr-0 */}
        <Row className={classNames('no-print', rowStyles)}>
          <Col className={colStyles}>
            <Card className="p2">
              <VoidAction paymentInfo={payment} userType={loggedinUser.type} />
            </Card>
          </Col>
          <Col className={colStyles}>
            <Card className="p2">
              <RefundAction paymentInfo={payment} />
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }

  renderPayment(activeTab, payment, loading, loggedinUser) {
    if (payment.sub_payments) {
      return this.renderPartialPaymentWithVoid(activeTab, payment, loading, loggedinUser)
    } else {
      return this.renderFullPayment(activeTab, payment, loading, loggedinUser);
    }
  }

  async updatePaymentDescription() {
    const { headerModal: { id, value } } = this.state
    this.closeHeaderModal()
    await this.props.payment.updatePaymentDescription(id, value);
    this.getPayment()
  }

  async updatePaymentItemDescription() {
    const { itemModal: { id, value } } = this.state
    this.closeItemModal()
    await this.props.payment.updatePaymentItemDescription(id, value);
    this.getPayment()
  }

  openHeaderModal(id, value) {
    const {headerModal} = this.state
    this.setState({
      headerModal: { 
        ...headerModal,
        id,
        value,
        isOpen: true
      }
    })
  }

  closeHeaderModal() {
    const {headerModal} = this.state
    this.setState({
      headerModal: { 
        ...headerModal,
        isOpen: false,
        value: '',
        id: null
      }
    })
  }

  openItemModal(id, value) {
    const {itemModal} = this.state
    this.setState({
      itemModal: { 
        ...itemModal,
        id,
        value,
        isOpen: true
      }
    })
  }

  closeItemModal() {
    const {itemModal} = this.state
    this.setState({
      itemModal: { 
        ...itemModal,
        isOpen: false,
        value: '',
        id: null
      }
    })
  }

  render() {
    const { activeTab, headerModal, itemModal } = this.state;
    const { payment, loading } = this.props.payment.getData();
    const { loggedinUser } = this.props.user.getData();
    return (
      <>
        {this.renderPayment(activeTab, payment, loading, loggedinUser)}
        <Modal isOpen={headerModal.isOpen} centered>
          <ModalBody className="payment-description-modal-body">
            <h5 className="title">แก้ไขรายละเอียด</h5>
            <NexterInput value={this.state.headerModal.value} onChange={(input) => {
              this.setState({ headerModal: { ...headerModal, value: input.target.value }})
            }} />
          </ModalBody>
          <ModalFooter className="payment-description-modal-footer">
            <Row>
              <Col>
                <NexterButton onClick={this.closeHeaderModal.bind(this)} className="my-0 cancel" size="sm" outline>
                  ยกเลิก
                </NexterButton>
              </Col>
              <Col>
                <NexterButton disabled={headerModal.value.length === 0} onClick={this.updatePaymentDescription.bind(this)} className="btn-sm text-capitalize">
                  บันทึก
                </NexterButton>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>

        <Modal isOpen={itemModal.isOpen} centered>
          <ModalBody className="payment-description-modal-body">
            <h5 className="title">แก้ไขรายการ</h5>
            <NexterInput value={this.state.itemModal.value} onChange={(input) => {
              this.setState({ itemModal: { ...itemModal, value: input.target.value }})
            }} />
          </ModalBody>
          <ModalFooter className="payment-description-modal-footer">
            <Row>
              <Col>
                <NexterButton onClick={this.closeItemModal.bind(this)} className="my-0 cancel" size="sm" outline>
                  ยกเลิก
                </NexterButton>
              </Col>
              <Col>
                <NexterButton disabled={itemModal.value.length === 0} onClick={this.updatePaymentItemDescription.bind(this)} className="btn-sm text-capitalize">
                  บันทึก
                </NexterButton>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

export default inject('payment', 'user', 'seller')(observer(PaymentPage));
