import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import _ from 'lodash';
import { config } from '../../config';
import { http } from '../../utils/http';
import { Table, Row, Col } from 'reactstrap';
import { EmptyRow } from '../../components/Table/EmptyRow';
import { Pagination } from '../../components/Pagination/Pagination';
import { ensureJSONparse } from '../../utils/string';

const FIELD = {
  transfer: 'Payment transfer '
}

const t = (field) => {
  const translateField = _.get(FIELD, [field], field)
  return translateField
}

class RoleMenuLogTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      page: 1,
      size: 10,
      total: 1,
    }
  }
  async componentDidMount() {
    await this.fetchLogs();
  }

  async fetchLogs() {
    const { setLoading } = this.props

    let entity = [
      'rolepermission',
    ]
    try {
      setLoading(true)
      let url = `${config.npay.apiUrl}/historylogs`;
      let body = {
        page: this.state.page,
        size: this.state.size,
        parent: 'admin',
        parent_id: 0,
        entity: entity,
      };

      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        this.setState({
          logs: body.data || [],
          total: body.total || 1,
        });
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 100);
    }
  }

  selectPage(page) {
    this.setState({ page }, async () => {
      await this.fetchLogs();
    });
  }

  render() {
    const { logs } = this.state;
    let rows = logs.length ? logs.map((item, index) => {
      const oldValue = ensureJSONparse(item.old_value);
      const newValue = ensureJSONparse(item.new_value);
      const actionName = _.get(item, 'action_name', '');
      let list = newValue !== null && !_.isEmpty(newValue) ? newValue : oldValue;

      let result = Object.entries(list)
        .map(([key, value], i) => {
          let ov = _.get(oldValue, key);
          let nv = _.get(newValue, key);
          const [newV, newRP] = nv.split("lll")
          const [rp, role] = newRP.split("||")
          let [tab, permission] = rp.split(":")
          tab = tab.replace(/[_]/g, " ")
          permission = permission.split(/(?=[A-Z_.])/g).map(a => {
            a = a.replace(/[_.]/g, "")
            return a.charAt(0).toUpperCase() + a.substr(1)
          }).join(" ")
          const tempOldV = ov
          ov = tempOldV ? 'Active' : 'Inactive'
          nv = tempOldV ? 'Inactive' : 'Active'
          tab = t(tab)
          return (
            <tr key={`item-${item.id}-${i}`}>
              <td width="142">
                {moment(_.get(item, 'updated_at', '-')).format('DD-MM-YYYY HH:mm')}
              </td>
              <td width="152">{_.get(item, 'updated_by', '')}</td>
              <td width="80">
                <div className={`action-wrapper ${actionName}`}>{actionName}</div>
              </td>
              <td width="150">
                {tab === 'menu' ? 'Menu Setting' : 'Role Permission'}
              </td>
              <td width="150">
                {`${tab !== 'menu' ? `${tab.replace(/./, c => c.toUpperCase())} ` : ''}${permission}`}
              </td>
              <td width="150">
                {role}
              </td>
              <td width="150">
                {`${ov}`}
              </td>
              <td width="150">
                {`${nv}`}
              </td>
            </tr>
          )
        })
      return result;
    }) : <EmptyRow colSpan={6} />;

    return (
      <Fragment>
        <Table>
          <thead>
            <tr>
              <th className="border-0">วันที่แก้ไข</th>
              <th className="border-0">แก้ไขโดย</th>
              <th className="border-0">กิจกรรม</th>
              <th className="border-0">หน้าที่แก้ไข</th>
              <th className="border-0">ข้อมูลที่แก้ไข</th>
              <th className="border-0">สิทธิ์ที่แก้ไข</th>
              <th className="border-0">ข้อมูลเดิม</th>
              <th className="border-0">ข้อมูลที่แก้ไข</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        <hr />
        <Row>
          <Col />
          <Col>
            <Pagination
              className="pull-right"
              activePage={this.state.page}
              displayPageNumber={5}
              maximumPageNumber={Math.ceil(this.state.total / this.state.size)}
              onSelect={(page) => this.selectPage(page)}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}
export default inject('role')(observer(RoleMenuLogTable));