import React from 'react';
import PropTypes from 'prop-types';
import cc2p from '../../assets/img/paymentGateway/ccpp.png';
import one23 from '../../assets/img/paymentGateway/one23.png';
import t2p from '../../assets/img/paymentGateway/t2p.png';
import linepay from '../../assets/img/paymentGateway/linepay.png';
import opn from '../../assets/img/paymentGateway/opn.svg';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
const PaymentGatewaySelect = props => {
  const [select, setSelect] = useState(null);
  const [paymentGateway, setPaymentGateway] = useState([]);
  useEffect(() => {
    if (props.paymentGateway) {
      setPaymentGateway(props.paymentGateway.filter(({ gateway }) => gateway !== 'linepay'));
    }
    if (props.active) {
      setSelect(props.active);
    }
  }, [props]);
  const getIconPayment = code => {
    let icon = null;
    switch (code) {
      case '2c2p':
        icon = <img src={cc2p} style={{ marginTop: '30%' }}></img>;
        break;
      case '123':
        icon = <img src={one23} style={{ marginTop: '20%' }}></img>;
        break;
      case 't2p':
        icon = <img src={t2p}></img>;
        break;
      case 'linepay':
        icon = <img src={linepay} style={{ marginTop: '30%' }}></img>;
        break;
      case 'opn':
        icon = <img src={opn} style={{ marginTop: '30%' }}></img>;
        break;
      default:
        break;
    }
    return icon;
  };
  const onClickGateway = e => {
    const active = document.getElementById(e.target.id);
    const gateway = active.getAttribute('gateway');
    setSelect(gateway);
    if (props.onSelect) {
      props.onSelect(gateway);
    }
  };

  return (
    <div className="no-print">
      <span className="label-h5">เลือก Payment Gateway</span>
      <div className="box-select-payment">
        {paymentGateway.map((item, index) => {
          return (
            <div
              className={classNames('box-payment-gateway-item', { active: select === item.gateway })}
              onClick={onClickGateway}
              id={`payment-gateway-item-${item.gateway}`}
              gateway={item.gateway}
              key={`payment-gateway-item-${item.gateway}-${index}`}
            >
              {getIconPayment(item.gateway)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

PaymentGatewaySelect.propTypes = {
  onSelect: PropTypes.func,
  paymentGateway: PropTypes.array,
  active: PropTypes.string,
};

export default PaymentGatewaySelect;
