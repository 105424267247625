import React from 'react';
import { Table } from 'reactstrap';
import classNames from 'classnames';
import { Amount } from '../../components/Field/Amount';
import iNoData from '../../assets/img/icon-no-data.svg';

const tableHeaderColumns = [
  {
    title: 'รหัสสินค้า',
  },
  {
    title: 'หมวดหมู่',
  },
  {
    title: 'รายการ',
    // width: 344,
  },
  {
    title: 'ราคาก่อน VAT',
    className: 'text-right',
  },
  {
    title: 'VAT (7%)',
    className: 'text-right',
  },
  {
    title: 'ราคารวม VAT',
    className: 'text-right',
    width: 150,
  },
  {
    title: 'ส่วนลดคืน seller',
    className: 'text-right',
  },
  {
    title: 'ราคาหลังหักส่วนลด',
    className: 'text-right',
  },
];

const EmptyRow = () => (
  <tr>
    <td colSpan={8} className="text-center">
      <div>
        <img src={iNoData} />
      </div>
      <span>ไม่มีรายการคำสั่งซื้อ</span>
    </td>
  </tr>
);

export class PaymentReport extends React.Component {
  render() {
    let { payment } = this.props;
    // let totalVat = (payment.total * 100) / 107;
    let totalNet = 0;
    let totalBeforeVat = 0;
    let totalVat = 0;
    let total = 0;
    let totalDiscount = 0;
    let rows = payment.items.map(item => {
      let beforeVat = payment.seller && payment.seller.kyc_type === 'personal' ? item.subtotal : (item.subtotal * 100) / 107;
      let vat = payment.seller && payment.seller.kyc_type === 'personal' ? 0 : beforeVat * 0.07;
      let net = item.subtotal - item.discount;
      totalBeforeVat += beforeVat;
      totalVat += vat;
      total += item.subtotal;
      totalDiscount += item.discount;
      totalNet += net;
      return (
        <tr key={`item-${item.id}`}>
          <td>{item.product_code || '-'}</td>
          <td>{item.product_category || '-'}</td>
          <td style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            {item.description || '-'} 
            {this.props.editDescription && <i className="no-print fa fa-edit" style={{ color: '#e20a1a' }} onClick={() => this.props.open(item.id, item.description)}/>}
          </td>
          <td className="text-right"><Amount value={beforeVat} /></td>
          <td className="text-right"><Amount value={vat} /></td>
          <td className="text-right"><Amount value={item.subtotal} /></td>
          <td className="text-right">{item.discount ? (<Amount value={item.discount} />) : '-'}</td>
          <td className="text-right"><Amount value={net} /></td>
        </tr>
      );
    });

    return (
      <div className="card-report-r1">
        <hr />
        <div className="d-flex justify-content-between my-2">
          <h6 className="payment-title text-uppercase mt-auto mb-0">รายการคำสั่งซื้อ</h6>
        </div>
        <Table>
          <thead>
            <tr>
              {tableHeaderColumns.map(({ title, className = '', width = 100, props = {} }, i) => (
                <th key={i} className={classNames('', className)}
                  width={width}
                  scope="col"
                  {...props}
                >{title}</th>
              ))}
            </tr>
          </thead>
          {rows.length > 0
            ? (
              <tbody>
                {rows}
                <tr className="tr-total">
                  <td className="text-right" style={{ fontWeight: 700 }} colSpan="3">ยอดรวม</td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    <Amount value={totalBeforeVat} />
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>{totalVat ? (<Amount value={totalVat} />) : '-'}</td>
                  <td className="text-right" style={{ fontWeight: 700 }}>
                    <Amount value={total} />
                  </td>
                  <td className="text-right" style={{ fontWeight: 700 }}>{totalDiscount ? (<Amount value={totalDiscount} />) : '-'}</td>
                  <td className="text-right" style={{ fontWeight: 700 }}>{totalNet ? (<Amount value={totalNet} />) : '-'}</td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <EmptyRow />
              </tbody>
            )
          }
        </Table>
      </div >
    );
  }
}
