import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import React, { Component } from 'react';

import { CheckBox } from '../../components/Input/CheckBox';
import { CONST_FILTER_LIST } from '../../stores/ReconcileStore';
import MultiSelect from '../../components/Forms/MultiSelect';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';

const inputStyles = classNames('nexter-input-sm');
const rowStyles = classNames('pb-3 mx-0 my-0');
const colStyles = classNames('pr-3 pl-0');

const initialState = {
  modalFilter: false,
  filters: {
    statuses: [
      { value: 'settled', label: 'Settled' },
      // { value: 'pending_settle', label: 'Pending Settle' },
      { value: 'mismatch_settled', label: 'Mismatch Settled' },
      { value: 'refunded', label: 'Refunded' },
    ],
    cards: [
      { value: 'credit_card', label: 'Credit card', gateway: '2c2p' },
      { value: 'bill_payment_mobile', label: 'Mobile banking', gateway: '123' },
      { value: 'bill_payment', label: 'Counter Service', gateway: '123' },
      { value: 'scg_wallet', label: 'SCG Wallet', gateway: 't2p' },
      { value: 'installment', label: 'Installment', gateway: '2c2p' },
      { value: 'line', label: 'Line Pay', gateway: '2c2p' },
      { value: 'qr_box', label: 'Bill Payment (BBL)', gateway: 'bbl' },
      { value: 'chang_family_wallet', label: 'Chang Family Wallet', gateway: 't2p' },
      { value: 'promptpay', label: 'Thai QR Payment', gateway: '2c2p' },
      { value: 'grabpay', label: 'GrabPay', gateway: '2c2p' },
      { value: 'cbdc', label: 'CBDC Payment', gateway: '2c2p' },
      { value: 'mobile_banking_opn', label: 'Mobile Banking (App Switch)', gateway: 'opn' },
    ],
  },
  isSuperAdmin: false,
  isChannelAdmin: false,
};
class ReconcileTranSearch extends Component {
  constructor(props) {
    super(props);
    this.state = _.cloneDeep(initialState);
  }
  async componentDidMount() {
    let { loggedinUser } = this.props.user.getData();
    let { channel } = this.props.reconcile.getData();
    this.props.seller.fetchSellersByStatus('approved', channel?.uid ?? '');
    switch (loggedinUser.type) {
      case 'super_admin':
      case 'ae_marketing':
      case 'biz_system':
      case 'manager':
        this.setState({ isSuperAdmin: true });
        break;
      case 'channel_admin':
      case 'channel_user':
      case 'as_channel_admin':
        this.setState({ isChannelAdmin: true });
        break;
      default:
        break;
    }
  }
  sellerOptionList = () => {
    const { channelSellers } = this.props.seller.getData();
    return _.uniqBy(
      channelSellers
        .filter(s => ['channel', 'partner'].find(str => str === s.seller.type))
        .map(s => {
          const id = s.seller.id;
          const name = channelSellers.filter(seller => seller.seller.name === s.seller.name).length > 1 ? `${s.seller.name} (${s.seller_code})` : s.seller.name;
          const type = s.seller.type;
          return { value: id, label: type === 'channel' ? '** ' + name : name };
        }),
      'value'
    );
  };
  channelMethodList = () => {
    let { filter: { list } } = this.props.reconcile.getData();
    const { isSuperAdmin, filters } = this.state;
    const method = _.flatten(list[CONST_FILTER_LIST.PAYMENT_GATEWAY]
      .filter(gateway => (isSuperAdmin ? gateway : gateway !== 'bbl'))
      .map(gateway => filters.cards.filter(card => card.gateway === gateway)))
    return method
  }
  componentWillUnmount() {
    this.props.reconcile.channel = null;
    this.clear();
  }
  clear = () => {
    this.props.reconcile.tran.page = 1;
    this.props.reconcile.clearFilterTran();
  };
  render() {
    const { filters, isSuperAdmin } = this.state;
    const { reconcile } = this.props;
    const {
      filter: { tran },
    } = this.props.reconcile.getData();
    return (
      <>
        <Row className={classNames(rowStyles)}>
          <Col lg="6" xs="12" className={classNames(colStyles, 'pl-0')}>
            <NexterInput
              size="sm"
              className={inputStyles}
              value={tran.ref_id}
              onChange={e => {
                reconcile.filterTran('ref_id', e.target.value);
              }}
              placeholder="ค้นหา REF. ID , ผู้ชำระเงิน"
            />
          </Col>
          <Col lg="3" xs="6" className={colStyles}>
            <CheckBox
              label="แสดงเฉพาะ Different Transaction"
              checked={tran.diff}
              onChange={() => reconcile.filterTran('diff', !tran.diff)}
              defaultClassName=""
            />
          </Col>
          <Col lg="3" xs="6" className={colStyles}>
            <NexterButton className="mr-2 my-0" size="sm" icon="fas fa-download" onClick={() => this.props.reconcile.exportFileTransaction(isSuperAdmin, 'Reconcile report')}>
              EXCEL
            </NexterButton>
          </Col>
        </Row>
        <Row className={classNames(rowStyles)}>
          <Col lg="3" xs="12" className={colStyles}>
            <MultiSelect
              placeholder="เลือกผู้ขาย/ผู้รับเงิน..."
              options={this.sellerOptionList()}
              value={tran.seller_ids}
              onChange={e => reconcile.filterTran('seller_ids', e)}
            />
          </Col>
          <Col lg="3" xs="12" className={colStyles}>
            <MultiSelect placeholder="เลือกช่องทางการชำระเงิน" options={this.channelMethodList()} value={tran.card_type} onChange={e => reconcile.filterTran('card_type', e)} />
          </Col>
          <Col lg="3" xs="12" className={colStyles}>
            <MultiSelect placeholder="เลือกสถานะการชำระเงิน" options={filters.statuses} value={tran.status} onChange={e => reconcile.filterTran('status', e)} />
          </Col>
          <Col lg="3" xs="12" className={colStyles}>
            <NexterButton
              size="sm"
              color="info"
              outline
              onClick={() => {
                this.clear();
                this.props.reconcile.getTran(isSuperAdmin);
              }}
            >
              clear
            </NexterButton>
            <NexterButton
              size="sm"
              color="dark"
              className="mx-1"
              icon="fas fa-search"
              onClick={() => {
                this.props.reconcile.tran.page = 1;
                this.props.reconcile.getTran(isSuperAdmin);
              }}
            >
              search
            </NexterButton>
          </Col>
        </Row>
      </>
    );
  }
}

export default inject('channel', 'seller', 'user', 'reconcile')(observer(ReconcileTranSearch));
