import React, { Component, Fragment } from 'react';
import { Page } from '../../components/Page';
import HistoryLogTable from './HistoryLogTable'

class ChannelSettingHistoryLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  getTitle = () => {    
    let title = 'Channel Info'
    const tab = new URL(window.location.href).searchParams.get('tab')
    switch (tab) {
      case 'installment-campaign':
        title = 'Installment Campaign'
        break;
      case 'due-of-payment':
        title = 'Due of Payment'
        break;
      default:
        break;
    }
    return title
  }

  render() {
    const { loading } = this.state;

    
    return (
      <Fragment>
        <Page loading={loading}>
          <div className="historylog-wrapper">
            <h5 className="title-logs" style={{ marginTop: 4, marginBottom: 0 }}>{`ประวัติการแก้ไข ${this.getTitle()}`}</h5>
            <hr />
            <HistoryLogTable setLoading={(load) => this.setState({ loading: load })} parentUid={this.props.parentUid}/>
          </div>
        </Page>
      </Fragment>
    );
  }
}

export default ChannelSettingHistoryLog;
