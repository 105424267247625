import moment from 'moment';

import queryString from 'query-string';
import swal from 'sweetalert2';

import { config } from '../config';
import { http } from '../utils/http';
import BaseStore from './BaseStore';

export class TransferStore extends BaseStore {
  constructor() {
    super();
    this.observable(this.getInitialData());
  }

  getInitialData() {
    return {
      loading: false,
      transfers: [], /// transfer list ///
      transfer_condition: {
        status: 'approved',
        start_date: '',
        end_date: '',
        seller_selected: '',
        report_no: null,
      },
      transfer: {
        to_seller: {
          sellers_account: {
            bank_name: '',
            bank_code: '',
          },
          channel_sellers: [{ seller_code: '-' }],
        },
        partner_transfers: [],
        items: [],
        summary: {},
        installment: {},
      },
      table: {
        selection: [],
        selectAll: false,
        selectType: 'checkbox',
      },
      transferItems: [],
      preTransferItems: [],
      btnExcelAll: true,
      gateway: null,
      transfer_type: 'pre-transfer',
      channel_selected: null,
    };
  }

  clearData() {
    this.loading = false;
    this.transfers = [];
    this.transfer_condition = {
      status: this.transfer_condition.status,
      start_date: '',
      end_date: '',
      seller_selected: '',
      report_no: null,
    };
    this.transfer = {
      to_seller: {
        sellers_account: {
          bank_name: '',
          bank_code: '',
        },
        channel_sellers: [{ seller_code: '-' }],
      },
      items: [],
      partner_transfers: [],
      summary: {},
    };
  }
  setLoading(val) {
    this.loading = val;
  }
  clearTableSelection() {
    this.table.selection = [];
    this.table.selectAll = false;
  }
  changeTransferType(val) {
    if (val !== this.transfer_type) {
      this.transfer_type = val;
      this.clearData();
      this.getTransfer();
    }
  }

  checkExportExcelFile() {
    let newTransfers = this.transfers.filter(el => {
      return this.table.selection.indexOf(el.report_no) >= 0;
    });

    let someExported = newTransfers.some(el => el.file_loaded);
    this.btnExcelAll = someExported !== true;
  }

  changeChannel(selected) {
    this.channel_selected = selected;
  }
  changeSeller(selected) {
    this.transfer_condition.seller_selected = selected;
  }

  changeStartDate(val) {
    this.transfer_condition.start_date = val;
  }

  changeEndDate(val) {
    this.transfer_condition.end_date = val;
  }
  changeGateway(val) {
    this.gateway = val;
    this.changeActiveDefaultTransferStatus(val);
  }
  changeActiveDefaultTransferStatus(gateway) {
    const { transfer_condition } = this.getData();
    let statusDefault = transfer_condition.status;
    let statusT2P = ['created', 'transferred', 'failed'];
    let status2C2P = ['created', 'requested', 'transferred', 'failed'];
    let statusLinepay = status2C2P;
    let otherStatus = ['approved', 'exported', 'pending'];
    if (gateway === 't2p') {
      if (!statusT2P.includes(statusDefault)) {
        statusDefault = statusT2P[0];
      }
    } else if (['2c2p', 'opn'].includes(gateway)) {
      if (!status2C2P.includes(statusDefault)) {
        statusDefault = status2C2P[0];
      }
    } else if (gateway === 'linepay') {
      if (!statusLinepay.includes(statusDefault)) {
        statusDefault = statusLinepay[0];
      }
    } else {
      if (!otherStatus.includes(statusDefault)) {
        statusDefault = otherStatus[0];
      }
    }
    this.transfer_condition.status = statusDefault;
  }
  changeTransferStatus(val) {
    this.transfer_condition.status = val;
  }
  changeTransferReport(val) {
    this.transfer_condition.report_no = val;
  }
  async getTransfer(transfer_status = null) {
    const gateway = this.gateway;
    this.changeTransferReport(null);
    const {
      transfer_condition: { status, seller_selected },
      transfer_type,
    } = this.getData();
    transfer_status = transfer_status ? transfer_status : status;
    switch (transfer_status) {
      case 'approved':
        this.getTransfers(gateway, false, null, 'created');
        break;
      case 'pending':
        this.getTransfers(gateway, true, null, transfer_status);
        break;
      case 'exported':
        this.getTransfers(gateway, true);
        break;
      case 'requested':
        this.getTransfers(gateway, true, null, transfer_status);
        break;
      case 'transferred':
        this.getTransfers(gateway, true, null, transfer_status);
        break;
      case 'failed':
        this.getTransfers(gateway, false, null, transfer_status);
        break;
      case 'created':
        if (seller_selected && seller_selected.channelSeller && transfer_type === 'pre-transfer') {
          seller_selected.channelSeller.value = seller_selected.channelSeller.seller.uid;
          this.getTransferItemsBySeller(seller_selected.channelSeller, gateway);
        } else {
          this.getTransfers(gateway, false, null, transfer_status);
        }
        break;
      default:
        break;
    }
  }

  async getTransfers(gateway, fileExported, sellerStatus = null, status = '', transferredBy = '', with_payment = null) {
    try {
      sellerStatus = JSON.stringify(sellerStatus);
      let excludeTransferredBy = gateway ? 'kbank' : '';
      let { transfer_condition } = this.getData();
      let startdate = transfer_condition.start_date ? transfer_condition.start_date.toISOString() : '';
      let enddate = transfer_condition.end_date ? transfer_condition.end_date.toISOString() : '';
      let sellerid = transfer_condition.seller_selected ? transfer_condition.seller_selected.value : '';
      this.loading = true;
      let query = {
        gateway: gateway,
        file_exported: fileExported,
        seller_status: sellerStatus,
        status: status,
        transferred_by: transferredBy,
        exclude_transferred_by: excludeTransferredBy,
        seller_id: sellerid,
        start_date: startdate,
        end_date: enddate,
        with_payment: with_payment,
        channel_uid: this.channel_selected?.value,
      };
      const stringified = queryString.stringify(query);
      let url = `${config.npay.apiUrl}/transfers?${stringified}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        this.transfers = body.data;
        this.clearTableSelection();
        return body;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async getTransferReport(uid) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/transfers/${uid}?channel_uid=${this.channel_selected?.value}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        let transfer = body.data;
        this.transfer = {
          ...transfer,
          ...body.transfer_data,
        };
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
  setDataByTransferReport() {
    const transferState = this.getData();
    const { gateway, file_url, report_no } = transferState.transfer;
    this.gateway = gateway;
    if (file_url) {
      this.changeTransferStatus('exported');
    }
    if (report_no) {
      this.transfer_type = 'transfer';
      this.changeTransferReport(report_no);
    }
  }

  async getTransferItemsBySeller(channelSeller, gateway) {
    try {
      this.loading = true;
      let uid = channelSeller.value;
      let url = `${config.npay.apiUrl}/transfers/items/seller/${uid}?gateway=${gateway}&channel_uid=${this.channel_selected.value}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        let installment = body.installment;
        let seller = body.data.seller;
        let channelPaymentMethod = body.data.channel_payment_method;
        let transfer = body.transfer_data;

        if (['channel', 'partner'].includes(seller.type)) {
          this.transfer = {
            to_seller: seller,
            items: transfer.items,
            summary: transfer.summary,
            partner_transfers: transfer.partner_transfers,
            installment,
            channel_payment_method: channelPaymentMethod,
          };
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async getTransferItemById(id) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/transfers/items/paymentid/${id}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        let data = body.data;
        this.transferItems = data;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async createTransferReport(gateway) {
    try {
      this.loading = true;
      gateway = gateway ? gateway : this.gateway;
      let { transfer } = this.getData();
      let url = `${config.npay.apiUrl}/transfers?channel_uid=${this.channel_selected.value}`;
      let body = {
        seller_id: transfer.to_seller.id,
        transfer_net: transfer.summary.transfer_net,
        transfer_fee: transfer.summary.net_transfer_fee,
        interest_net: transfer.summary.interest_net,
        items: transfer.items.map(item => ({ id: item.id })),
        partner_transfers: transfer.partner_transfers.length ? transfer.partner_transfers.map(tr => ({ id: tr.id })) : [],
      };
      let response = await http.post(url, { body });

      if (response.status !== 200) {
        let body = await response.json();
        swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'Error!',
          text: body?.message,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async getTransferExcelFile(gateway, source) {
    const selection = ['created', 'approved'].includes(this.transfer_condition.status) ? this.table.selection : this.transfers.map(transfer => transfer.report_no);
    if (selection.length <= 0) {
      console.warn('No Selection');
    } else {
      try {
        this.loading = true;
        let body = { transfers: selection, source };
        // const route = ['123', '2c2p', 'linepay'].includes(this.gateway) && this.transfer_condition.status === 'approved' ? '/transfers/file' : '/transfers/fileonly';
        const route = this.gateway === '123' && this.transfer_condition.status === 'approved' ? '/transfers/file' : '/transfers/fileonly';
        let url = `${config.npay.apiUrl}${route}?channel_uid=${this.channel_selected.value}`;
        let response = await http.post(url, { body });

        if (response.status === 200) {
          let body = await response.json();
          window.open(body.data.url);
          if (!route.includes('fileonly')) this.getTransfer();
          //   window.location.href = `${config.web.rootpath}/payment-gateway/${gateway}`;
        } else {
          let body = await response.json();
          throw new Error(body.message);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    }
  }

  async getKbankTransferFile() {
    if (this.table.selection.length <= 0) {
      swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: 'Please select transfer',
        confirmButtonText: 'OK',
      });
      return;
    }
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/transfers/kbank/payment/file`;
      let options = { body: { transfers: this.table.selection } };
      let response = await http.post(url, options);
      let body = await response.json();
      if (response.status === 200) {
        swal
          .fire({
            customClass: 'nexter-alert',
            type: 'success',
            title: 'Success',
            // html: _.map(body.data.files, 'url').join('<br/>'),
            confirmButtonText: 'OK',
          })
          .then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 500);
          });
      } else {
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async importKbankTransferFile(content) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/transfers/kbank/payment/file/content`;
      let options = { body: { content } };
      let response = await http.post(url, options);
      let body = await response.json();
      if (response.status === 200) {
        console.log('data', body.data);
      } else {
        swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'Error',
          text: body.message,
          confirmButtonText: 'OK',
        });
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async transferScgWallet() {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/transfers/scgwallet`;
      // body: { content }   report_no: this.table.selection
      const body = { body: { report_no: this.table.selection } };
      let response = await http.post(url, body);

      return this.alertResponseTransfer(response);
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async transfer2c2p() {
    try {
      this.loading = true;
      const report_no = [...this.table.selection];
      const url = `${config.npay.apiUrl}/transfers/2c2p`;
      if (!report_no.length || report_no.length > 10) {
        return swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'Error',
          text: !report_no.length ? 'กรุณาเลือกรายงานการโอนเงิน' : 'ไม่สามารถเลือกรายการโอนได้มากกว่า 10 รายการ',
        });
      }
      const payload = { report_no };
      const response = await http.post(url, { body: payload });
      if (response.status >= 400) {
        const result = await response.json();
        return swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'Error!',
          text: result.message,
        });
      }

      return this.alertResponseTransfer(response);
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  async transferOpn() {
    try {
      this.loading = true;
      const report_no = [...this.table.selection];
      const url = `${config.npay.apiUrl}/transfers/opn`;
      if (!report_no.length || report_no.length > 10) {
        return swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'Error',
          text: !report_no.length ? 'กรุณาเลือกรายงานการโอนเงิน' : 'ไม่สามารถเลือกรายการโอนได้มากกว่า 10 รายการ',
        });
      }
      const payload = { report_no };
      const response = await http.post(url, { body: payload });
      if (response.status >= 400) {
        const result = await response.json();
        return swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'Error!',
          text: result.message,
        });
      }

      return this.alertResponseTransfer(response);
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  async alertResponseTransfer(response) {
    const result = await response.json();
    if (response.status === 200) {
      const {
        data: { success, failed, url },
      } = result;
      const summaryTransfer = `<br><a href="${url}">คลิกเพื่อดาวน์โหลดไฟล์สรุปการโอน</a>`;
      if (success.length > 0) {
        let successMsg = '';
        let errorMsg = '';
        successMsg += `โอนเงินสำเร็จ ${success.length} รายการ`;
        if (failed.length > 0) {
          successMsg += `<br/>`;
          errorMsg += `<div>มีรายการที่โอนไม่สำเร็จ ${failed.length} รายการ</div>`;
        }
        return swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          title: 'โอนเงินสำเร็จ!',
          html: `${successMsg}${errorMsg}${summaryTransfer}`,
          confirmButtonText: 'กลับไป',
        });
      } else {
        let errorMsg = `<div>มีรายการที่โอนไม่สำเร็จ ${failed.length} รายการ</div>`;
        return swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'โอนเงินไม่สำเร็จ!',
          html: `${errorMsg}${summaryTransfer}`,
          confirmButtonText: 'ลองอีกครั้ง',
        });
      }
    } else {
      return swal.fire({
        customClass: 'nexter-alert',
        type: 'error',
        title: 'เกิดข้อผิดพลาด!',
        html: 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง',
        confirmButtonText: 'ลองอีกครั้ง',
      });
    }
  }

  async exportFile(options = {}) {
    const { createTransferItemDetail } = options;
    try {
      this.isLoading = true;
      let { transfer } = this.getData();

      let url = `${config.npay.apiUrl}/report/channel`;
      if (transfer.to_seller.type === 'partner') {
        url = `${config.npay.apiUrl}/report/partner`;
      }
      let json = {
        transfer: transfer,
        create_transfer_item_detail: Boolean(createTransferItemDetail),
        source: options.source,
      };
      let response = await http.post(url, { body: json });
      if (response.status === 200) {
        let body = await response.json();
        window.open(body.data.url);
      }
    } catch (err) {
      console.error(err);
      swal.error('Error', 'Export ข้อมูลไม่สำเร็จ');
    } finally {
      this.isLoading = false;
    }
  }

  async undoTransfer(reportNo, gateway) {
    try {
      let result = await swal.fire({
        customClass: 'nexter-alert',
        text: 'ยืนยันที่จะย้อนกลับรายการนี้?',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'Undo',
        reverseButtons: true,
      });

      if (result.value) {
        this.loading = true;
        let body = { report_no: reportNo };
        let url = `${config.npay.apiUrl}/transfers/undo`;
        let response = await http.post(url, { body });
        if (response.status === 200) {
          this.getTransfer(null);
          // window.location.href = `${config.web.rootpath}/payment-gateway/${gateway}`;
        } else {
          let body = await response.json();
          swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'Error!',
            text: body.message,
            confirmButtonText: 'OK',
          });
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }

  async undoTransferExcel(reportNo, gateway) {
    try {
      let result = await swal.fire({
        customClass: 'nexter-alert',
        text: 'ยืนยันที่จะย้อนกลับรายการนี้?',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'Undo',
        reverseButtons: true,
      });

      if (result.value) {
        this.loading = true;
        let body = { report_no: reportNo };
        let url = `${config.npay.apiUrl}/transfers/undo/excel`;
        let response = await http.post(url, { body });
        if (response.status === 200) {
          window.location.href = `${config.web.rootpath}/payment-gateway/${gateway}`;
        } else {
          let body = await response.json();
          swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'Error!',
            text: body.message,
            confirmButtonText: 'OK',
          });
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }
}
