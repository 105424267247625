import { inject, observer } from 'mobx-react';
import { Row, Col, Table, UncontrolledTooltip } from 'reactstrap';
import React, { Component } from 'react';
import * as _ from 'lodash'
import numeral from 'numeral';

import { EmptyRow } from '../../components/Table/EmptyRow';
import NexterButton from '../../components/Button/NexterButton';
import { Amount } from '../../components/Field/Amount';
import { ReconcileTab } from '../../stores/ReconcileStore';

class ReconcileTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: {},
    };
  }
  componentDidMount() {
    const { loggedinUser } = this.props.user.getData();
    const isSuperAdmin = ['super_admin', 'biz_system', 'ae_marketing', 'manager'].includes(loggedinUser.type);
    this.props.reconcile.getList(isSuperAdmin);
  }

  toggleGateway(index, ref) {
    const isOpenState = this.state.isOpen;
    if (!Object.keys(isOpenState).includes(index.toString())) isOpenState[index] = true;
    else isOpenState[index] = !isOpenState[index];
    this.setState({ isOpen: isOpenState });
  }

  buildGateway = (gw) => {
    let { tab } = this.props.reconcile.getData();
    let groupGw = _.groupBy(gw, 'name')
    for (const g of Object.keys(groupGw)) {
      const summary = groupGw[g]
        .filter(g => tab === ReconcileTab.AVAILABLE_BALANCE ? ['balance', 'net'].includes(g.type) : true)
        .reduce((sum, p) => ({
          amount: sum?.amount + p?.summary?.amount,
          reconcile_amount: sum?.reconcile_amount + p?.summary?.reconcile_amount,
          diff: sum?.diff + p?.summary?.diff,
        }), { amount: 0, reconcile_amount: 0, diff: 0 });
      const diff = Math.abs(summary.amount - summary.reconcile_amount)
      summary.diff = diff
      const isCarray = Boolean(groupGw[g].find(row => row.type === 'carry'))
      groupGw[g].push({ name: `${g}`, summary, type: "sum", tab: `รวม ${g} ${isCarray ? '(A+B)' : ''}`.toUpperCase() })
    }
    return _.flatten(Object.values(groupGw))
  }

  getGatewayType = (gw) => {
    let { tab } = this.props.reconcile.getData();
    if ((gw && gw.tab) && (gw.type !== 'sum' || tab === ReconcileTab.AVAILABLE_BALANCE)) {
      return `${gw.tab.replace(/./, c => c.toUpperCase())} ${gw.type === 'refunded' ? '(Refund)' : ''}`
    } else {
      return (gw && gw.tab) ? gw.tab : ''
    }
  }

  render() {
    let {
      list: { items }, tab
    } = this.props.reconcile.getData();

    let rows = items.map(({ channel, channel_uid, gateway, summary: { amount, diff, reconcile_amount } }, index) => {
      const gateways = _.uniqBy(gateway, 'name').map(g => g.name).join(', ');
      gateway = this.buildGateway(gateway)
      return (
        <React.Fragment key={index}>
          <tr style={{ fontWeight: '500' }}>
            <td>
              <div className="card-usermanagement p-0">
                <NexterButton className="td-collapsible dropdown-descript" size="sm" onClick={this.toggleGateway.bind(this, index)}>
                  <i className={this.state.isOpen[index] == true ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
                </NexterButton>
              </div>
            </td>
            <td className="">{channel}</td>
            <td className="text-uppercase">{gateways}</td>
            <td></td>
            <td className="text-right">
              <Amount value={amount} />
            </td>
            <td className="text-right">
              <Amount value={reconcile_amount} />
            </td>
            <td className="text-right" style={{ color: diff < 0 ? '#E10C19' : 'black' }}>
              <span id={`${channel_uid}_${index}`}>
                <Amount value={diff} />
              </span>
              <UncontrolledTooltip placement="top-end" target={`${channel_uid}_${index}`} delay={{ show: 500, hide: 0 }}>
                {numeral(diff).format('0,0.0000')}
              </UncontrolledTooltip>
            </td>
            {tab !== ReconcileTab.AVAILABLE_BALANCE && <td className="text-center">
              <a
                style={{ color: '#007bff' }}
                onClick={() => {
                  this.props.reconcile.viewChannelTransaction({ uid: channel_uid, name: channel });
                }}
              >
                View
              </a>
            </td>}
          </tr>
          {this.state.isOpen[index] && (
            <>
              {gateway.map((g, i) => (
                <tr key={i} style={{
                  backgroundColor: '#FAFAFA',
                  fontWeight: (g.type === 'sum') ? 'bold' : 'normal'
                }}>
                  <td className="td-collapsible"></td>
                  <td className=""></td>
                  <td className="text-uppercase">{g.name}</td>
                  <td>{this.getGatewayType(g)}</td>
                  <td className="text-right">
                    <Amount value={g.summary.amount} />
                  </td>
                  <td className="text-right">
                    <Amount value={g.summary.reconcile_amount} />
                  </td>
                  <td className="text-right" style={{ color: g.summary.diff < 0 ? '#E10C19' : 'black' }}>
                    <span id={`${channel_uid}_${g.type}_${g.name}_${index}_${i}`}>
                      <Amount value={g.summary.diff} />
                    </span>
                    <UncontrolledTooltip placement="top" target={`${channel_uid}_${g.type}_${g.name}_${index}_${i}`} delay={{ show: 500, hide: 0 }}>
                      {numeral(g.summary.diff).format('0,0.0000')}
                    </UncontrolledTooltip>
                  </td>
                  <td className=""></td>
                </tr>
              ))}
            </>
          )}
        </React.Fragment>
      );
    });

    const amount = items.reduce((sum, p) => sum + p?.summary?.amount ?? 0, 0);
    const reconcile_amount = items.reduce((sum, p) => sum + p?.summary?.reconcile_amount ?? 0, 0);
    const diff = items.reduce((sum, p) => sum + p?.summary?.diff ?? 0, 0);

    return (
      <div>
        <hr className="mt-4 mb-3" />
        <Row>
          <Col>
            <div className="table-reponsive table-scroll">
              <Table className="table-user">
                <thead>
                  <tr>
                    <th className="border-0"></th>
                    <th className="text-capitalize border-0">Channel</th>
                    <th className="text-capitalize border-0">Payment Gateway</th>
                    <th className="text-capitalize border-0">Type</th>
                    <th className="text-capitalize text-right border-0">sFinPay net amount</th>
                    <th className="text-capitalize text-right border-0">Gateway net Amount</th>
                    <th className="text-capitalize text-right border-0">Different</th>
                    {tab !== ReconcileTab.AVAILABLE_BALANCE && <th className="text-capitalize text-center border-0">Transaction</th>}
                  </tr>
                </thead>
                {rows.length > 0 ? (
                  <>
                    <tbody className="tbody-user">{rows}</tbody>
                    <tfoot>
                      <tr className="tr-total" style={{ fontWeight: 700 }}>
                        <td colSpan={3} />
                        <td className="text-right">รวมทั้งหมด</td>
                        <td className="text-right">
                          <Amount value={amount} />
                        </td>
                        <td className="text-right">
                          <Amount value={reconcile_amount} />
                        </td>
                        <td className="text-right" style={{ color: diff < 0 ? '#E10C19' : 'black' }}>
                          <span id={`summary_diff`}>
                            <Amount value={diff} />
                          </span>
                          <UncontrolledTooltip placement="top" target={`summary_diff`}>
                            {numeral(diff).format('0,0.0000')}
                          </UncontrolledTooltip>
                        </td>
                        <td className="text-center"></td>
                      </tr>
                    </tfoot>
                  </>
                ) : (
                  <tbody>
                    <EmptyRow displayText="ไม่มีรายการ" colSpan={9} />
                  </tbody>
                )}
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default inject('reconcile', 'user')(observer(ReconcileTable));
