import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import localStorage from '../utils/localStorage';
import { Container, Card, CardBody, CardTitle, Row, Col, FormGroup, Label } from 'reactstrap';
import logo from '../assets/img/Logo-SFinPay.png';
import polygon from '../assets/img/polygon.jpg';
import NexterButton from '../components/Button/NexterButton';
import NexterInput from '../components/Input/NexterInput';
import Countdown from '../components/Countdown/Countdown';
import queryString from 'query-string';
import ADAuth from '../utils/adAuth';
import { Page } from '../components/Page';
import { config } from '../config';
const jwtDecode = require('jwt-decode');

const isEnabledNormalSignin = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

class LoginPage extends Component {
  async componentDidMount() {
    const { location } = this.props;
    const query = queryString.parse(location.search);
    if (query.token) {
      await this.props.user.loginAD(query.token);
      const redirect = localStorage.getItem('redirect');
      if (redirect) query.redirect = redirect;
    }
    localStorage.removeItem('redirect');
    let access_token = localStorage.getItem('token');
    // NL-1596
    if (query.logout) {
      if (access_token) this.props.user.logout();
      else this.props.history.push(`${config.web.rootpath}/login`);
      return;
    }
    if (access_token) {
      access_token = access_token || '';
      this.props.user.setLoading(true);
      let payload = jwtDecode(access_token);
      this.props.user.setCurrentUser(payload);
      this.props.user.getUserRole();
      const path = `${config.web.rootpath}/switch-channel`;
      if (query.redirect) {
        window.location.href = `${path}?redirect=${query.redirect}`;
      } else {
        window.location.href = path;
      }
    }
  }

  forgotPassword() {
    this.props.history.push(`${config.web.rootpath}/forgot-password`);
  }

  changeUsername(event) {
    let username = event.target.value;
    this.props.user.changeUsername(username);
  }

  changePassword(event) {
    let password = event.target.value;
    this.props.user.changePassword(password);
  }

  handleKeyPressLogin(event) {
    if (event.key === 'Enter') this.login();
  }

  login() {
    const { location } = this.props;
    const query = queryString.parse(location.search);
    if (query.redirect) {
      this.props.user.loginWithRedirect(query.redirect);
    } else {
      this.props.user.login();
    }
  }

  loginWithAD() {
    const searchParams = queryString.parse(location.search);
    if (searchParams.redirect) {
      localStorage.setItem('redirect', searchParams.redirect);
    }
    ADAuth.login();
  }

  render() {
    let data = this.props.user.getData();
    return (
      <Page loading={data.loading} className="d-flex min-vh-100 align-items-center">
        <Container className="py-3">
          <Row className="justify-content-center">
            <Col className="col-12 col-lg-9">
              <Card className="card-shadow0 m-0 p-4">
                <Row>
                  <Col md="6" className="d-flex align-items-center">
                    <figure>
                      <img src={polygon} className="w-100" />
                    </figure>
                  </Col>
                  <Col md="6" className="d-flex align-items-center">
                    <CardBody>
                      <CardTitle className="text-center" style={{ fontWeight: 700 }}>
                        <img src={logo} width="175px" />
                      </CardTitle>

                      {isEnabledNormalSignin && (
                        <>
                          <Fragment>
                            <FormGroup>
                              <Label>Username</Label>
                              <NexterInput value={data.user.username} invalid={data.isFailedUsername} onChange={this.changeUsername.bind(this)} />
                            </FormGroup>
                            <FormGroup>
                              <Label>Password</Label>
                              <NexterInput type="password" value={data.user.password} onChange={this.changePassword.bind(this)} onKeyPress={this.handleKeyPressLogin.bind(this)} />
                            </FormGroup>
                            <a className="as-link forgot-password " onClick={this.forgotPassword.bind(this)}>
                              Reset or Forgot password?
                            </a>
                            <br />
                          </Fragment>

                          <Countdown
                            endTime={data.lockedLoginExpiredAt}
                            render={data => (
                              <NexterButton className="w-100" size="m" onClick={this.login.bind(this)} block disabled={data.timeLeft > 0}>
                                Sign in {data.timeLeft > 0 && <span className="text-lowercase">({data.formatTime(data.timeLeft)})</span>}
                              </NexterButton>
                            )}
                          ></Countdown>
                        </>
                      )}

                      <NexterButton className="w-100 my-4" size="m" onClick={this.loginWithAD.bind(this)} block>
                        Sign in with AD
                      </NexterButton>
                      <p className="my-2">
                        By signing in, I agree to SCG-CBM’s <a className="as-link">Terms of Service</a> and
                        <a className="as-link"> Privacy Policy.</a>
                      </p>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </Page>
    );
  }
}

export default inject('user')(observer(LoginPage));
