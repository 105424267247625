/* eslint-disable no-return-assign */
import React, { Component, useRef } from 'react';
import { inject, observer } from 'mobx-react';

import 'chartjs-plugin-datalabels';

import Visibility from '../../components/Visibility/Visibility';
import NexterButton from '../../components/Button/NexterButton'
import CustomInputDatePicker from '../../components/Input/DatePicker';
import DatePicker, { registerLocale } from 'react-datepicker';
import { CheckBox } from '../../components/Input/CheckBox';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import TotalPaymentChart from './TotalPaymentChart';
import PaymentMethodChart from './PaymentMethodChart';
import CardBankChart from './CardBankChart';
import ChannelChart from './ChannelChart';
import TOPChannelChart from './TOPChannelChart';
import GatewayChart from './GatewayChart'
import AverageChart from './AverageChart';
import { Page } from '../../components/Page';

const ref = React.createRef();
const options = {
  orientation: 'portrait',
  unit: 'in',
  format: [4, 2]
};

const adminRoles = ['super_admin', 'ae_marketing', 'biz_system', 'manager'];
const channelRoles = ['channel_admin', 'channel_user'];

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false
    }
  }
componentWillUnmount(){
  this.props.dashboard.resetData()
}
componentWillMount(){
  let select_all=["credit_card", "qr_box", "scg_wallet", "line", "bill_payment_mobile", "bill_payment", "installment", "chang_family_wallet", "promptpay", "grabpay", "cbdc", "mobile_banking_opn"];
  let all =["all"]
  this.props.dashboard.method_filter = [...select_all]
  this.props.dashboard.method_filter_all = [...all]
  this.props.dashboard.method_filter_all = [...this.props.dashboard.method_filter_all];
  this.props.dashboard.method_filter = [...this.props.dashboard.method_filter];

}
  modalToggle = () => {
    this.setState({
      modal: !this.state.modal
    })
  }

  handleStartDate = (event) => {
    this.props.dashboard.handleStartDate(event)
  }
  handleEndDate = (event) => {
    this.props.dashboard.handleEndDate(event)
  }
  clearFilter=()=>{
    this.props.dashboard.clearFilter()
  }
  showResult = async () => {
    this.props.dashboard.setLoading(true)
    const {totalpayment_interval,totalByChannel_interval,totalGateway_interval} = this.props.dashboard.getData();
    await Promise.all([
      this.props.dashboard.fetchDashboardTotalPayment(totalpayment_interval),
      this.props.dashboard.fetchDashboardTopChannel(),
      this.props.dashboard.fetchDashboardCreditCardBankMethod(),
      this.props.dashboard.fetchDashboardPaymentByChannel(totalByChannel_interval),
      this.props.dashboard.fetchDashboardGatewayFee(totalGateway_interval),
      this.props.dashboard.fetchDashboardByPaymentMethod(),
    ])

    this.props.dashboard.setDataStatus(false)
    this.props.dashboard.setLoading(false)
  }

  toggleMethodTypeSelection(selected) {
    const index = this.props.dashboard.method_filter.indexOf(selected);
    if (index < 0) {
      this.props.dashboard.method_filter.push(selected);
    } else {
      this.props.dashboard.method_filter.splice(index, 1);
    }
    this.props.dashboard.method_filter = [...this.props.dashboard.method_filter];
  }
  toggleMethodSelectAll(selected) {
    const index = this.props.dashboard.method_filter_all.indexOf(selected);
    let select_all=["credit_card", "qr_box", "scg_wallet", "line", "bill_payment_mobile", "bill_payment", "installment", "chang_family_wallet", "promptpay", "grabpay", "cbdc"]
    if (index < 0) {
      this.props.dashboard.method_filter_all.push(selected);
      this.props.dashboard.method_filter = [...select_all]
    } else {
      this.props.dashboard.method_filter_all.splice(index, 1);
      this.props.dashboard.method_filter = []
    }
    this.props.dashboard.method_filter_all = [...this.props.dashboard.method_filter_all];
    this.props.dashboard.method_filter = [...this.props.dashboard.method_filter];
  }


  insertAfter(referenceNode, newNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  print(handlePrint) {
    const imgList = document.querySelectorAll('#dashboard-container .print-only');
    for (const img of imgList) { img.remove() }
    const canvasList = document.getElementsByTagName('canvas');
    for (const canvas of canvasList) {
      const imgElement = document.createElement('img');
      imgElement.setAttribute('src', canvas.toDataURL());
      const imgContainer = document.createElement('div');
      imgContainer.setAttribute('class', 'print-only');
      imgContainer.append(imgElement);
      this.insertAfter(canvas.parentElement, imgContainer);
    };
    handlePrint();
  }

  render() {
    const { loggedinUser } = this.props.user.getData();
    const data = this.props.dashboard.getData()
    this.props.dashboard.checkLabel()

    let dashboardName = null;
    if (adminRoles.includes(loggedinUser.type)) dashboardName = 'SFinPay';
    if (channelRoles.includes(loggedinUser.type)) dashboardName = 'CHANNEL';

    return (
      <Page>
        <Visibility permission_code="dashboard:view">
          <div
            className="content"
            id="dashboard-container"
            ref={el => (this.componentRef = el)}
          >
            <div className="dashboard-header no-print">
              <h5>{dashboardName} - Dashboard</h5>
              <ReactToPrint documentTitle="SFinPay - Dashboard" content={() => this.componentRef}>
                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <NexterButton
                      size="sm"
                      color="info"
                      icon="fa fa-upload"
                      outline
                      onClick={() => this.print(handlePrint)}
                    >
                      Export
                    </NexterButton>
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
            </div>

            <div className="print-block">
              <AverageChart />
            </div>

            <div className="dashboard-filter mb-3 no-print">
              <Row className="align-items-center">
                <Col md={{ size: 'auto' }}>
                  <p className="filter-title m-0">ช่วงเวลาของข้อมูล :</p>
                </Col>
                <Col md={{ size: 3 }}>
                  <FormGroup className="m-0">
                    <DatePicker
                      customInput={<CustomInputDatePicker />}
                      locale="th"
                      dateFormat="MM/yyyy"
                      onChange={this.handleStartDate}
                      selected={data.start_date}
                      maxDate={data.start_date.length!=0?data.max_start_date:new Date()}
                      minDate={data.min_start_date}

                      showMonthYearPicker
                      selectsStart

                    />
                  </FormGroup>
                </Col>
                <Col md={{ size: 3 }}>
                  <FormGroup className="m-0">
                    <DatePicker
                      customInput={<CustomInputDatePicker />}
                      locale="th"
                      dateFormat="MM/yyyy"
                      onChange={this.handleEndDate}
                      selected={data.end_date}
                      maxDate={data.start_date.length!=0?data.max_end_date:new Date()}
                      minDate={data.min_end_date}


                      showMonthYearPicker
                      selectsEnd

                    />
                  </FormGroup>
                </Col>
                <Col md={{ size: 'auto' }}>
                  <Row>
                    <NexterButton onClick={() => this.showResult()} className="mr-3" size="sm">
                      SHOW RESULT
                  </NexterButton>
                    <NexterButton onClick={() => this.modalToggle()} className="mr-3" size="sm" icon="fa fa-filter" color="dark">
                      Filter
                  </NexterButton>
                  </Row>
                </Col>
              </Row>
            </div>

            <div className="page-break">
              <TotalPaymentChart />
            </div>

            <div className="page-break">
              <div className="d-inline-block w-75 pr-3">
                <PaymentMethodChart />
              </div>
              <div className="d-inline-block w-25 pl-3">
                <CardBankChart />
              </div>
            </div>

            {['super_admin', 'biz_system', 'ae_marketing', 'manager'].includes(loggedinUser.type) && (
              <>
                <div className="page-break">
                  <ChannelChart />
                </div>

                <div className="page-break">
                  <TOPChannelChart />
                </div>
              </>
            )}

            <div className="page-break">
              <GatewayChart />
            </div>

            <Modal isOpen={this.state.modal} toggle={this.modalToggle}>
              <ModalBody>
                <p className="filter-title">Filters</p>
                <hr />
                <FormGroup>
                  <Label className="w-100 text-dark">
                    <strong>Payment Method</strong>
                  </Label>
                  <Row>
                    <Col md={{ size: 6 }}>
                      <CheckBox checked={data.method_filter.includes('credit_card')} label="Credit Card"
                        onChange={this.toggleMethodTypeSelection.bind(this, 'credit_card')} />
                    </Col>
                    <Col md={{ size: 6 }}>
                      <CheckBox checked={data.method_filter.includes('bill_payment_mobile')} label="Mobile Banking"
                        onChange={this.toggleMethodTypeSelection.bind(this, 'bill_payment_mobile')} />
                    </Col>
                    <Col md={{ size: 6 }}>
                      <CheckBox checked={data.method_filter.includes('qr_box')} label="QR / Bill Payment (BBL)"
                        onChange={this.toggleMethodTypeSelection.bind(this, 'qr_box')} />
                    </Col>
                    <Col md={{ size: 6 }}>
                      <CheckBox checked={data.method_filter.includes('bill_payment')} label="Counter Service"
                        onChange={this.toggleMethodTypeSelection.bind(this, 'bill_payment')} />
                    </Col>
                    <Col md={{ size: 6 }}>
                      <CheckBox checked={data.method_filter.includes('scg_wallet')} label="SCG Cash Card / SCG Wallet"
                        onChange={this.toggleMethodTypeSelection.bind(this, 'scg_wallet')} />
                    </Col>
                    <Col md={{ size: 6 }}>
                      <CheckBox checked={data.method_filter.includes('installment')} label="Installment (แบ่งชำระ)"
                        onChange={this.toggleMethodTypeSelection.bind(this, 'installment')} />
                    </Col>
                    <Col md={{ size: 6 }}>
                      <CheckBox checked={data.method_filter.includes('line')} label="Line Pay"
                        onChange={this.toggleMethodTypeSelection.bind(this, 'line')} />
                    </Col>
                    <Col md={{ size: 6 }}>
                      <CheckBox checked={data.method_filter.includes('chang_family_wallet')} label="Chang Family Wallet"
                        onChange={this.toggleMethodTypeSelection.bind(this, 'chang_family_wallet')} />
                    </Col>
                    <Col md={{ size: 6 }}>
                      <CheckBox checked={data.method_filter.includes('promptpay')} label="Thai QR Payment"
                        onChange={this.toggleMethodTypeSelection.bind(this, 'promptpay')} />
                    </Col>
                    <Col md={{ size: 6 }}>
                      <CheckBox checked={data.method_filter.includes('grabpay')} label="GrabPay"
                        onChange={this.toggleMethodTypeSelection.bind(this, 'grabpay')} />
                    </Col>
                    <Col md={{ size: 6 }}>
                      <CheckBox checked={data.method_filter.includes('cbdc')} label="CBDC Payment"
                        onChange={this.toggleMethodTypeSelection.bind(this, 'cbdc')} />
                    </Col>
                    <Col md={{ size: 6 }}>
                      <CheckBox checked={data.method_filter.includes('mobile_banking_opn')} label="Mobile Banking (App Switch)"
                        onChange={this.toggleMethodTypeSelection.bind(this, 'mobile_banking_opn')} />
                    </Col>
                    <Col md={{ size: 6 }}>
                    <CheckBox checked={data.method_filter_all.includes('all')} label="All"
                        onChange={this.toggleMethodSelectAll.bind(this, 'all')} />
                    </Col>
                  </Row>
                </FormGroup>
              </ModalBody>

              <ModalFooter>
                <Row>
                  <Col>
                    <NexterButton onClick={()=> this.clearFilter()} color="secondary" className="btn-sm">{' '} Clear Filters</NexterButton>
                  </Col>
                  <Col>
                    <NexterButton onClick={() => this.modalToggle()} className="btn-sm ">Done</NexterButton>
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>
          </div>
        </Visibility>

        <Visibility permission_code="dashboard:view" invisibility_msg>
          <div className="dashboard-header no-print">
            <h5>{dashboardName} - Dashboard</h5>
          </div>
          <h5>ท่านไม่มีสิทธิ์ดู Dashboard</h5>
        </Visibility>
      </Page>
    );
  }
}

export default inject('user', 'dashboard')(observer(Dashboard));
