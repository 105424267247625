import _ from 'lodash';
import React, { Component } from 'react';
import { CardBody, Table, Row, Col, UncontrolledTooltip } from 'reactstrap';
import 'moment/locale/th';
import { Amount } from '../../components/Field/Amount';
export class PartnerTable extends Component {
  onClick(data) {
    if (this.props.onClickRow) {
      this.props.onClickRow(data)
    }
  }
  render() {
    let { summary, items } = this.props;
    let rows = items.map(item => {
      if (item.service_charge_type === 'percent') {
        return (
          <tr key={item.id}>
            <td className="text-center">
              <span onClick={() => this.onClick(item)} className="as-link">{item.payment ? item.payment.ref_id : '-'}</span>
            </td>
            <td className="text-center">{item.invoice_no}</td>
            <td className="text-center">{_.get(item, 'payment.seller.name', '-')}</td>
            <td className="text-center">{item.type}</td>
            <td className="text-center">{item.customer_name}</td>
            <td className="text-right"><Amount value={item.subtotal} /></td>
            <td className="text-right"><Amount value={item.discount} /></td>
            <td className="text-right"><Amount value={item.paid} /></td>
            <td className="text-right">{item.service_charge}</td>
            <td className="text-right"><Amount value={item.charge} /></td>
            <td className="text-right"><Amount value={item.vat} /></td>
            <td className="text-right"><Amount value={item.wht} /></td>
            <td className="text-right"><Amount value={item.charge_net} /></td>
            <td className="text-right">{item.installment_period || '-'}</td>
            <td className="text-right"><Amount value={item.installment_rate || 0} /></td>
            <td className="text-right"><Amount value={item.interest} /></td>
            <td className="text-right"><Amount value={item.interest_vat} /></td>
            <td className="text-right"><Amount value={item.interest_net} /></td>
            <td className="text-right"><Amount value={item.totalBefDiscount} /></td>
            <td className="text-right"><Amount value={item.promotion_discount || 0} /></td>
            <td className="text-right"><Amount value={item.transfer_amount} /></td>
          </tr>
        );
      } else if (item.service_charge_type === 'baht') {
        return (
          <tr key={item.id}>
            <td className="text-center">
              <span onClick={() => this.onClick(item)} className="as-link">{item.payment ? item.payment.ref_id : '-'}</span>
            </td>
            <td className="text-center">{item.invoice_no}</td>
            <td className="text-center">{_.get(item, 'payment.seller.name', '-')}</td>
            <td className="text-center">{item.type}</td>
            <td className="text-center">{item.customer_name}</td>
            <td className="text-right"><Amount value={item.subtotal} /></td>
            <td className="text-right"><Amount value={item.discount} /></td>
            <td className="text-right"><Amount value={item.paid} /></td>
            <td className="text-right">-</td>
            <td className="text-right"><Amount value={item.charge} /></td>
            <td className="text-right"><Amount value={item.vat} /></td>
            <td className="text-right"><Amount value={item.wht} /></td>
            <td className="text-right"><Amount value={item.charge_net} /></td>
            <td className="text-right">{item.installment_period || '-'}</td>
            <td className="text-right"><Amount value={item.installment_rate || 0} /></td>
            <td className="text-right"><Amount value={item.interest} /></td>
            <td className="text-right"><Amount value={item.interest_vat} /></td>
            <td className="text-right"><Amount value={item.interest_net} /></td>
            <td className="text-right"><Amount value={item.totalBefDiscount} /></td>
            <td className="text-right"><Amount value={item.promotion_discount || 0} /></td>
            <td className="text-right"><Amount value={item.transfer_amount} /></td>
          </tr>
        );
      }
    });
    if (rows.length === 0) {
      rows = <EmptyRow colSpan={20} />
    }
    return (
      <div className="table-responsive table-scroll print-transfer-table seller">
        <Table>
          <thead>
            <tr className="no-print">
              <th className="text-center">หมายเลขการสั่งซื้อ</th>
              <th className="text-center">Invoice No.</th>
              <th className="text-center">Seller (ชื่อร้าน)</th>
              <th className="text-center">ประเภท</th>
              <th className="text-center" width="180">ชื่อลูกค้า</th>
              <th className="text-center">ราคารวม</th>
              <th className="text-center">ส่วนลด</th>
              <th className="text-center">ยอดเงินที่ลูกค้าชำระ</th>
              <th className="text-center">ค่าบริการ<br />Channel (%)</th>
              <th className="text-center">ค่าบริการ<br />Channel</th>
              <th className="text-center">VAT (7%)</th>
              <th className="text-center">WHT</th>
              <th className="text-center">ค่าบริการสุทธิ</th>
              <th className="text-center">ผ่อนชำระ (เดือน)</th>
              <th className="text-center">อัตราดอกเบี้ย (%)</th>
              <th className="text-center">ดอกเบี้ยหลังคำนวณ</th>
              <th className="text-center">VAT ดอกเบี้ย<br /> (7%)</th>
              <th className="text-center" width="100">ดอกเบี้ยสุทธิ</th>
              <th className="text-center">ยอดรวมก่อนส่วนลด</th>
              <th className="text-center">ส่วนลดค่าบริการ Channel</th>
              <th className="text-center" width="120">ยอดเตรียมโอน</th>
            </tr>
            <tr className="d-none print-only">
              <th className="text-center">หมายเลข<br/>การ<br/>สั่งซื้อ</th>
              <th className="text-center">Invoice<br/>No.</th>
              <th className="text-center">Seller<br/>(ชื่อร้าน)</th>
              <th className="text-center">ประเภท</th>
              <th className="text-center" width="180">ชื่อลูกค้า</th>
              <th className="text-center">ราคารวม</th>
              <th className="text-center">ส่วนลด</th>
              <th className="text-center">ยอดเงินที่ลูกค้าชำระ</th>
              <th className="text-center">ค่าบริการ<br />Chan<br />nel (%)</th>
              <th className="text-center">ค่าบริการ<br />Channel</th>
              <th className="text-center">VAT (7%)</th>
              <th className="text-center">WHT</th>
              <th className="text-center">ค่าบริการสุทธิ</th>
              <th className="text-center">ผ่อนชำระ (เดือน)</th>
              <th className="text-center">อัตราดอก<br/>เบี้ย (%)</th>
              <th className="text-center">ดอกเบี้ยหลังคำนวณ</th>
              <th className="text-center">VAT ดอกเบี้ย<br /> (7%)</th>
              <th className="text-center" width="100">ดอกเบี้ยสุทธิ</th>
              <th className="text-center">ยอดรวมก่อนส่วนลด</th>
              <th className="text-center">ส่วนลด<br/>ค่าบริการ<br/>Channel</th>
              <th className="text-center" width="120">ยอดเตรียมโอน</th>
            </tr>
          </thead>
          <tbody>
            {rows}
            <tr className="spacer" style={{ fontWeight: 700 }}>
              <td colSpan={5} className="text-right">
                <strong>รวม</strong>
              </td>
              <td className="text-right"><Amount value={summary.subtotal} /></td>
              <td className="text-right"><Amount value={summary.totaldiscount} /></td>
              <td className="text-right"><Amount value={summary.total_customer_paid} /></td>
              <td className="text-right">-</td>
              <td className="text-right"><Amount value={summary.charge} /></td>
              <td className="text-right"><Amount value={summary.vat} /></td>
              <td className="text-right"><Amount value={summary.wht} /></td>
              <td className="text-right"><Amount value={summary.charge_net} /></td>
              <td className="text-right">-</td>
              <td className="text-right">-</td>
              <td className="text-right"><Amount value={summary.interest} /></td>
              <td className="text-right"><Amount value={summary.interest_vat} /></td>
              <td className="text-right"><Amount value={summary.interest_net} /></td>
              <td className="text-right"><Amount value={summary.total_bef_discount} /></td>
              <td className="text-right"><Amount value={summary.total_promotion_discount} /></td>
              <td className="text-right"><Amount value={summary.transfer_amount} /></td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}
