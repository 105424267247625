import React from 'react';
import { Container } from 'reactstrap';

export const NotFound = () => {
  return (
    <Container>
      <h5 className="text-center">Not Found</h5>
    </Container>
  );
};
