import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, FormGroup, Label, CardHeader, CardFooter, Table, Input } from 'reactstrap';
import moment from 'moment';
import { Page } from '../../components/Page';
import Visibility from '../../components/Visibility/Visibility';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';
import { Pagination } from '../../components/Pagination/Pagination';
import iNoData from '../../assets/img/icon-no-data.svg';

const EmptyRow = () => (
  <tr>
    <td colSpan={9} className="text-center">
      <div>
        <img src={iNoData} />
      </div>
      <span>ไม่พบข้อมูลผู้ใช้งาน</span>
    </td>
  </tr>
);

class UserManageAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: {},
    };
    this.hasPermissionCode = this.hasPermissionCode.bind(this);
  }

  componentDidMount() {
    let { loggedinUser } = this.props.user.getData();
    if (loggedinUser.type.indexOf('channel') === -1) {
      this.props.user.searchUserByFilterAsAdmin();
    } else {
      this.props.user.getUsersByChannel(loggedinUser.channel.cid);
    }
  }

  clearAllFilters() {
    this.props.user.clearUsers();
    this.props.user.clearFilter();
    this.props.user.clearPagination();
    let { loggedinUser } = this.props.user.getData();
    if (loggedinUser.type.indexOf('channel') === -1) {
      this.props.user.searchUserByFilterAsAdmin();
    } else {
      this.props.user.getUsersByChannel(loggedinUser.channel.cid);
    }
    document.querySelectorAll('input[type=radio]').forEach(el => {
      el.checked = false;
    });
    this.state.isOpen = {};
  }

  searchUserByFilter() {
    this.props.user.pagination.page = 1;
    let { loggedinUser } = this.props.user.getData();
    if (loggedinUser.type.indexOf('channel') === -1) {
      this.props.user.searchUserByFilterAsAdmin();
    } else {
      this.props.user.getUsersByChannel(loggedinUser.channel.cid);
    }
  }

  changeFilterUserType(selected) {
    this.props.user.changeFilterUserType(selected);
  }

  changeFilterUserAccountStatus(input) {
    this.props.user.changeFilterUserAccountStatus(input.target.value);
  }

  changeFilterUserName(input) {
    this.props.user.changeFilterUserName(input.target.value);
  }

  changeFilterUserEmail(input) {
    this.props.user.changeFilterUserEmail(input.target.value);
  }

  changeFilterUserCode(input) {
    this.props.user.changeFilterUserCode(input.target.value);
  }

  selectPage(page) {
    this.props.user.changePagination(page);
    let { loggedinUser } = this.props.user.getData();
    if (loggedinUser.type.indexOf('channel') === -1) {
      this.props.user.searchUserByFilterAsAdmin();
    } else {
      this.props.user.getUsersByChannel(loggedinUser.channel.cid);
    }
  }

  handleChange(recordkey, statusOld, statusNew) {
    if (statusOld !== statusNew.value) this.props.user.ActiveUser(recordkey.id, statusNew.value);
  }

  hasPermissionCode(permission_code) {
    const { user_rp } = this.props.user.getData();
    return user_rp.includes(permission_code);
  }

  userAccountStatusOptions(currentStatus) {
    const statusList = ['Active', 'Inactive'];
    const options = statusList.map(status => {
      return {
        value: status.toLowerCase(),
        label: status,
        isDisabled: currentStatus == status.toLowerCase() || (status == 'Inactive' && !this.hasPermissionCode('user:inactivate')),
      };
    });
    return options;
  }

  toggleUserTimeStamp(index, ref) {
    const isOpenState = this.state.isOpen;
    if (!Object.keys(isOpenState).includes(index.toString())) isOpenState[index] = true;
    else isOpenState[index] = !isOpenState[index];
    this.setState({ isOpen: isOpenState });
  }

  detailUserPage(uid, ref) {
    this.props.user.setSelectedUser(uid);
    this.props.user.changeUserMgmtView('detail');
  }

  createUserPage() {
    this.props.user.changeUserMgmtView('create');
  }

  historyLogUserPage() {
    this.props.user.changeUserMgmtView('history');
  }

  generateChannelNames(userChannels = []) {
    const showNames = userChannels.slice(0, 2).map(item => item.channel?.name);
    const restCount = userChannels.length - showNames.length;
    return (
      <>
        {showNames.join(', ')}
        {restCount > 0 && (
          <>
            , <b> {restCount}+</b>
          </>
        )}
      </>
    );
  }

  render() {
    const userTypeOptions = [
      { value: 'super_admin', label: 'Super Admin' },
      { value: 'ae_marketing', label: 'Ae Marketing' },
      { value: 'biz_system', label: 'Biz System' },
      { value: 'manager', label: 'Manager' },
      { value: 'channel_admin', label: 'Channel Admin' },
      { value: 'channel_user', label: 'Channel User' },
    ];

    let { users, filter, pagination, loggedinUser } = this.props.user.getData();

    let rows = users.list.map((record, index) => {
      return (
        <React.Fragment key={index}>
          <tr>
            <td>
              <NexterButton className="td-collapsible dropdown-descript" size="sm" onClick={this.toggleUserTimeStamp.bind(this, index)}>
                <i className={this.state.isOpen[index] == true ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
              </NexterButton>
            </td>
            <td className="td-fname text-capitalize">
              <a style={{ color: '#007bff' }} onClick={this.detailUserPage.bind(this, record.uid)}>
                {record.name}
              </a>
            </td>
            <td className="td-lname text-capitalize">
              <a style={{ color: '#007bff' }} onClick={this.detailUserPage.bind(this, record.uid)}>
                {record.surname}
              </a>
            </td>
            <td className="td-type text-capitalize">{record.role.role_name.replace('_', ' ')}</td>
            <td className="td-mail">{record.email}</td>
            <td className="td-chname text-capitalize">{this.generateChannelNames(record.user_channels)}</td>
            <td className="td-status text-capitalize text-center">{record.status}</td>
            <td className="td-date text-center">{moment(record.updated_at).format('DD-MMM-YY HH:mm')}</td>
            <td className="td-action">
              <NexterInput
                className="dropdown-action"
                type="select"
                optionsDisabled={option => option.isdisabled}
                options={this.userAccountStatusOptions(record.status)}
                placeholder="เลือก"
                onChange={this.handleChange.bind(this, record, record.status)}
              />
            </td>
          </tr>
          {this.state.isOpen[index] && (
            <>
              <tr>
                <td className="td-collapsible border-0"></td>
                <td colSpan="2" className="td-updateby">
                  <span className="text-head-descript">Updated By:</span> {record.updated_by}
                </td>
                <td className="td-type text-head-descript">Created At: </td>
                <td className="td-mail">{moment(record.created_at).format('DD-MMM-YY HH:mm')}</td>
                <td className="td-chname text-head-descript">Last Login </td>
                <td className="td-status text-center">{moment(record.last_login).format('DD-MMM-YY HH:mm')}</td>
                <td className="td-date"></td>
                <td className="td-action"></td>
              </tr>
              <tr className="border-descript">
                <td className="td-collapsible"></td>
                <td colSpan="2" className="td-updateby">
                  <span className="text-head-descript"> Created By:</span> {record.created_by}
                </td>
                <td className="td-type text-head-descript">Updated At: </td>
                <td className="td-mail">{moment(record.updated_at).format('DD-MMM-YY HH:mm')}</td>
                <td className="td-chname text-head-descript">Telephone </td>
                <td className="td-status text-center">{record.tel}</td>
                <td className="td-date"></td>
                <td className="td-action"></td>
              </tr>
            </>
          )}
        </React.Fragment>
      );
    });

    return (
      <Page>
        <CardHeader>
          <Row>
            <Col>
              <h1 className="texthead-managementpage">USER MANAGEMENT</h1>
            </Col>
            <Visibility permission_code="user:view_list">
              <Col className="text-right p-0">
                <Col>
                  <a onClick={() => this.historyLogUserPage()}>
                    <NexterButton className="mr-3 my-0" size="sm" icon="fa fa-history" outline>
                      History Log
                    </NexterButton>
                  </a>
                  <Visibility permission_code="user:create">
                    <a onClick={() => this.createUserPage()}>
                      <NexterButton className="mr-0 my-0" size="sm" icon="fa fa-plus">
                        Create User
                      </NexterButton>
                    </a>
                  </Visibility>
                </Col>
              </Col>
            </Visibility>
          </Row>
        </CardHeader>

        <Visibility permission_code="user:view_list">
          <Visibility permission_code="user:search">
            <hr className="mt-4 mb-4" />

            {/* First Row */}
            <Row>
              <Col>
                <FormGroup>
                  <NexterInput size="sm" placeholder="ค้นหา Name" value={filter.user_name} onChange={this.changeFilterUserName.bind(this)} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <NexterInput size="sm" placeholder="ค้นหา Channel" value={filter.channel_name} onChange={this.changeFilterUserCode.bind(this)} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup check>
                  <Label className="filter-status-options">Status</Label>
                  <Label className="input-status" check>
                    <Input name="status" type="radio" value="active" onChange={this.changeFilterUserAccountStatus.bind(this)} />
                    <span className="label-status">Active </span>
                  </Label>
                  <Label className="input-status" check>
                    <Input name="status" type="radio" value="inactive" onChange={this.changeFilterUserAccountStatus.bind(this)} />
                    <span className="label-status">Inactive</span>
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <Col className="text-right p-0">
                  <NexterButton className="mr-2" size="sm" color="info" onClick={this.clearAllFilters.bind(this)} outline>
                    Clear
                  </NexterButton>
                  <NexterButton size="sm" color="default" icon="fa fa-search" onClick={this.searchUserByFilter.bind(this)}>
                    Search
                  </NexterButton>
                </Col>
              </Col>
            </Row>

            {/* Second Row */}
            <Row>
              <Col xs="6" sm="3">
                <FormGroup>
                  <NexterInput size="sm" placeholder="ค้นหา E-Mail" value={filter.user_email} onChange={this.changeFilterUserEmail.bind(this)} />
                </FormGroup>
              </Col>
              <Col xs="6" sm="3">
                <FormGroup>
                  <NexterInput type="select" size="sm" placeholder="เลือก Account Type…" value={filter.user_type} options={userTypeOptions} onChange={this.changeFilterUserType.bind(this)} />
                </FormGroup>
              </Col>
            </Row>
          </Visibility>

          <hr className="mt-4 mb-3" />
          <Row>
            <Col>
              <div className="table-reponsive table-scroll">
                <Table className="table-user">
                  <thead>
                    <tr>
                      <th className="td-collapsible border-0"></th>
                      <th className="td-fname text-capitalize border-0">First Name</th>
                      <th className="td-lname text-capitalize border-0">Last Name</th>
                      <th className="td-type text-capitalize border-0">Type</th>
                      <th className="td-mail border-0">
                        <span className="text-capitalize">E</span>-<span className="text-lowercase">mail</span>
                      </th>
                      <th className="td-chname text-capitalize border-0">
                        Channel <br /> Name
                      </th>
                      <th className="td-status text-capitalize text-center border-0">Status</th>
                      <th className="td-date text-capitalize text-center border-0">
                        วันที่ <br />
                        แก้ไขล่าสุด
                      </th>
                      <th className="td-action text-capitalize text-center border-0">Action</th>
                    </tr>
                  </thead>
                  {rows.length > 0 ? (
                    <tbody className="tbody-user">{rows}</tbody>
                  ) : (
                    <tbody>
                      <EmptyRow />
                    </tbody>
                  )}
                </Table>
              </div>
            </Col>
          </Row>
          <CardFooter>
            <Col className="p-0">
              <Pagination
                className="pull-right"
                activePage={pagination.page}
                displayPageNumber={pagination.size}
                onSelect={this.selectPage.bind(this)}
                maximumPageNumber={Math.ceil(pagination.total / pagination.size)}
              />
            </Col>
          </CardFooter>
        </Visibility>

        <Visibility permission_code="user:view_list" invisibility_msg>
          <h5 className="mt-4">ท่านไม่มีสิทธิ์ดูรายการ User</h5>
        </Visibility>
      </Page>
    );
  }
}

export default inject('user')(observer(UserManageAdmin));
