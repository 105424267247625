import moment from 'moment';
import BaseStore from './BaseStore';
import swal from 'sweetalert2';

import { config } from '../config';
import { http } from '../utils/http';
import { validation } from '../utils/validation';

export class PaymentStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      showAllPayment: true,
      loading: false,
      payment: {
        items: [],
        seller_id: '',
        status: 'unknown',
        transfer_items: [],
        void_condtion: {},
      },
      payments: {
        page: 1,
        size: 20,
        total: 1,
        items: [],
        summary: null,
      },
      condition: {
        iscondition: false,
        channel_uid: '',
        ref_id: '',
        status: [],
        trans_status: [],
        date_type: [],
        card_type: [],
        start_date: '',
        end_date: '',
        seller_selected: '',
        date_selected: '',
        expireFilter: true,
      },
      transfer_state: '', /// select_seller, create_transfer, clear_transfer, transferred ///
      paymentInfo: {
        ref_id: '',
        description: '',
        customer_name: '',
        phone: '',
        email: '',
        postback_url: '',
        items: [
          {
            subtotal: 0,
            discount: 0,
            description: null,
            product_category: null,
            product_code: null,
            channel_seller_id: null,
          },
        ],
        // expire_at: new Date().setDate(new Date().getDate() + 3),
        expire_at: new Date(),
        channel_seller_id: null,
      },
      default_expire_at: new Date(), // use for clearing global payment state
    });
  }
  changeShowAllPayment(val) {
    this.showAllPayment = val;
  }
  toggleChangeExpireFilter() {
    this.condition.expireFilter = !this.condition.expireFilter;
  }

  changeExpireFilter(val) {
    this.condition.expireFilter = val;
  }

  changeSeller(sellerId) {
    this.payment.seller_id = sellerId;
  }

  changeChannel(selected) {
    let obj = {
      value: selected ? selected.value : null,
      label: selected ? selected.label : null,
    };
    this.condition.seller_selected = selected ? obj : null;
  }

  changedateSelected(selected) {
    let obj = {
      value: selected ? selected.value : null,
      label: selected ? selected.label : null,
    };
    this.condition.date_selected = selected ? obj : null;
  }

  changeStartDate(val) {
    this.condition.start_date = val;
  }

  changeEndDate(val) {
    if (val.setHours) val.setHours(23, 58, 0); // 58 because of bug in MSSQL library shift a day.
    this.condition.end_date = val;
  }

  changeChannelUid(val) {
    this.condition.channel_uid = val;
  }
  changeRefId(val) {
    this.condition.ref_id = val;
  }

  changeStatus(selected) {
    this.condition.status = selected;
  }

  changeTransferStatus(selected) {
    this.condition.trans_status = selected;
  }

  changeDateType(selected) {
    this.condition.date_type = selected;
  }
  changeCardType(selected) {
    this.condition.card_type = selected;
  }

  changeChannelSellerID(selected) {
    let obj = selected ? { value: selected.value, label: selected.label } : null;
    this.paymentInfo.channel_seller_id = obj;
  }

  clearPayment() {
    this.showAllPayment = true;
    this.payment.seller_id = '';
    this.paymentInfo = {
      ref_id: '',
      description: '',
      customer_name: '',
      phone: '',
      email: '',
      postback_url: '',
      items: [
        {
          subtotal: 0,
          discount: 0,
          description: null,
          product_category: null,
          product_code: null,
        },
      ],
      // expire_at: new Date().setDate(new Date().getDate() + 3),
      expire_at: this.default_expire_at,
      channel_seller_id: null,
    };
  }

  clearPaymentDetail() {
    this.payment = {
      items: [],
      seller_id: '',
      status: 'unknown',
      transfer_items: [],
    };
    this.transfer_state = '';
  }

  showChangeSeller() {
    this.transfer_state = 'select_seller';
  }

  changeCustomerName(val) {
    this.paymentInfo.customer_name = val;
  }

  changeRefID(val) {
    this.paymentInfo.ref_id = val;
  }

  changeDescription(val) {
    this.paymentInfo.description = val;
  }

  changePhone(val) {
    this.paymentInfo.phone = val;
  }

  changeEmail(val) {
    this.paymentInfo.email = val;
  }

  changeProductName(i, val) {
    this.paymentInfo.items[i].description = val;
  }

  changeProductCategory(i, val) {
    this.paymentInfo.items[i].product_category = val;
  }

  changeProductCode(i, val) {
    this.paymentInfo.items[i].product_code = val;
  }

  changeProductPrice(i, val) {
    this.paymentInfo.items[i].subtotal = val;
  }

  changeProductDiscount(i, val) {
    this.paymentInfo.items[i].discount = val;
  }

  changeProductSeller(i, val) {
    let obj = val ? { value: val.value, label: val.label } : null;
    this.paymentInfo.items[i].channel_seller_id = obj;
  }

  changeProductTypeShipping(i) {
    this.paymentInfo.items[i].type_shipping = !this.paymentInfo.items[i].type_shipping;
  }

  selectPage(page) {
    this.payments.page = page;
    this.fetchChannelPayments();
  }

  changeExpireDate(val) {
    this.paymentInfo.expire_at = val;
  }

  validatePaymentData(data) {
    let keys = Object.keys(data);
    for (let key of keys) {
      if (key === 'items') {
        let testItems = this.validatePaymentItems(data[key]);
        if (!testItems) {
          return false;
        }
      } else if (key === 'ref_id') {
        if (!validation.paymentRefId(data[key])) {
          return false;
        }
      } else if (key === 'phone') {
        if (!data[key] || data[key][0] != '0' || data[key].length < 10) {
          return false;
        }
      } else if (key === 'email') {
        if (!validation.email(data[key])) {
          return false;
        }
      } else if (key === 'channel_seller_id') {
        // Skip validation for channel_seller_id if channel_seller_id is null
      } else {
        if (!data[key]) {
          return false;
        }
      }
    }

    return true;
  }

  validatePaymentItems(items) {
    for (let i = 0; i < items.length; i++) {
      const regexPositiveFloat = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;

      const el = items[i];
      if (!el.description) {
        el.description = 'สินค้า ' + (i + 1);
      }

      if (!el.subtotal) {
        return false;
      } else if (!regexPositiveFloat.test(el.subtotal)) {
        return false;
      }

      if (!el.discount) {
        el.discount = '0';
      } else if (!regexPositiveFloat.test(el.discount)) {
        return false;
      }
    }
    return true;
  }
  calculateSummary(paymentItems = []) {
    let summary = {
      total_discount: 0,
      total_subtotal: 0,
      total_service_charge: 0,
      total_vat: 0,
      total_service_charge_vat: 0,
      total_net_service_charge: 0,
      total_interest: 0,
      total_promotion_discount: 0,
      total_net: 0,
    };
    paymentItems = paymentItems.map(item => {
      let net = item.subtotal - item.discount;
      let vat = ((item.subtotal - item.discount) * 100) / 107;
      summary.total_subtotal += item.subtotal;
      summary.total_service_charge += item.service_charge;
      summary.total_net += net;
      summary.total_vat += vat;
      summary.total_discount += item.discount;
      summary.total_promotion_discount += item.promotion_discount || 0;
      item.bucharge = item.service_charge_type === 'baht' ? item.service_charge : item.subtotal * (item.service_charge / 100);
      item.vat = item.bucharge * (item.vat_rate || 7 / 100);
      item.wht_bath = item.bucharge * (item.wht / 100);
      item.bucharge_net = item.bucharge + item.vat + item.wht_bath;

      return item;
    });
    return { paymentItems, summary };
  }
  calculateTransferItem(transfer_items) {
    let summary = {
      subTotal: 0,
      totalDiscount: 0,
      totalServiceCharge: 0,
      totalVat: 0,
      totalNetService: 0,
      totalInterest: 0,
      totalNetInterest: 0,
      totalNet: 0,
    };
    let result = transfer_items.map(item => {
      let net = item.subtotal - item.discount;
      let vat = ((item.subtotal - item.discount) * 100) / 107;
      summary.totalNet += net;
      summary.totalVat += vat;
      summary.totalDiscount += item.discount;
      summary.totalServiceCharge += item.service_charge;
      return item;
    });
    return { transfer_items: result, summary_transfer: summary };
  }
  async getPaymentByUid(id) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/payments/report/${id}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        let payment = body.data;
        this.payment = payment;
        const { paymentItems, summary } = this.calculateSummary(payment.items);
        this.payment.summary = summary;
        this.payment.items = paymentItems;
        const { transfer_items, summary_transfer } = this.calculateTransferItem(payment.transfer_items);
        this.payment.transfer_items = transfer_items;
        this.payment.summary_transfer = summary_transfer;
        if (!payment.seller_id) {
          this.transfer_state = 'select_seller';
        } else {
          if (payment.transfer_items.length === 0) {
            this.transfer_state = 'create_transfer';
          } else {
            let transferred = payment.transfer_items.map(o => o.status);
            if (transferred.length > 0) {
              if (transferred.includes('created') || transferred.includes('pending')) {
                this.transfer_state = 'pending';
              } else if (transferred.includes('failed')) {
                this.transfer_state = 'failed';
              } else {
                this.transfer_state = payment.transfer_status;
              }
            } else {
              this.transfer_state = 'clear_transfer';
            }
          }
        }
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async getPaymentWithSubPayment(uid) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/payments/reports/${uid}`;
      let response = await http.get(url);
      if (response.status === 200) {
        let body = await response.json();
        let payment = body.data;
        if (payment.sub_payments) {
          payment.sub_payments.forEach(sub_payment => {
            const { paymentItems, summary } = this.calculateSummary(sub_payment.items);
            sub_payment.summary = summary;
            sub_payment.items = paymentItems;
          });
        }
        this.payment = payment;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async fetchChannelPayments() {
    try {
      this.loading = true;
      let page = this.payments.page;
      let size = this.payments.size;
      let { condition } = this.getData();

      let data = {
        channel_uid: condition.channel_uid,
        ref_id: condition.ref_id,
        card_type: condition.card_type,
        status: condition.status,
        transfer_status: condition.trans_status,
        seller_id: condition.seller_selected,
        start_date: moment(condition.start_date).valueOf(),
        end_date: moment(condition.end_date).valueOf(),
        date_selected: condition.date_selected ?? (condition.start_date || condition.end_date ? { value: 'updated_at' } : ''),
        expireFilter: condition.expireFilter,
      };

      let url = `${config.npay.apiUrl}/channels/payments?page=${page}&size=${size}`;
      let response = await http.post(url, { body: data });
      if (response.status === 200) {
        let body = await response.json();
        this.payments.total = body.total;
        this.payments.items = body.data;
        this.payments.summary = body.summary;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async savePaymentSeller(redirect = false) {
    try {
      this.loading = true;
      let data = this.getData();
      let updateData = {};
      updateData.paymentId = data.payment.id;
      updateData.sellerId = data.payment.seller_id;

      if (updateData.sellerId && updateData.paymentId) {
        let url = `${config.npay.apiUrl}/payments/seller`;
        let response = await http.put(url, { body: updateData });

        if (response.status === 200) {
          this.loading = false;
          const result = await swal.fire({
            customClass: 'nexter-alert',
            type: 'success',
            title: 'Success!',
            text: 'Update seller for this payment method',
            confirmButtonText: 'OK',
          });
          if (result.value) {
            if (redirect) {
              window.location.href = `${config.web.rootpath}/payments/detail/${data.payment.id}`;
            }
          }
          this.transfer_state = 'create_transfer';
        } else {
          let body = await response.json();
          throw new Error(body.message);
        }
      } else {
        swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'Error!',
          text: 'Please select the seller',
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async createTransferItems(id) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/transfers/items/payment/${id}`;
      let response = await http.post(url);
      if (response.status === 200) {
        const { payment } = await response.json();

        swal.fire({
          customClass: 'nexter-alert',
          title: 'Success!',
          text: 'Transfer created',
          type: 'success',
          confirmButtonText: 'OK',
        });
        this.getPaymentByUid(payment.id);
      } else {
        let body = await response.json();
        swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          html: body.message,
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async deleteTransferItems(id) {
    try {
      this.loading = true;
      let url = `${config.npay.apiUrl}/transfers/items/payment/${id}`;
      let response = await http.delete(url);
      if (response.status === 200) {
        swal.fire({
          customClass: 'nexter-alert',
          title: 'Success!',
          text: 'Transfer deleted',
          type: 'success',
          confirmButtonText: 'OK',
        });
        this.payment.transfer_items = [];
        this.transfer_state = 'create_transfer';
      } else {
        let body = await response.json();
        swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          text: body.message,
          confirmButtonText: 'OK',
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async createPayment(channel) {
    try {
      let { paymentInfo } = this.getData();
      // paymentInfo.ref_id = `order-${moment().format('YYMMDDHHmmssSSS')}`;
      paymentInfo.description = `[DIRECT] ${paymentInfo.description}`;
      paymentInfo.postback_url = `${config.npay.webUrl}/thankyou?shop=${channel.name}`;
      paymentInfo.expire_at = moment(paymentInfo.expire_at).set({ hour: 23, minute: 59, second: 59 }).valueOf();
      paymentInfo.channel_seller_id = paymentInfo.channel_seller_id ? paymentInfo.channel_seller_id.value : null;
      for (const item of paymentInfo.items) {
        item.channel_seller_id = item.channel_seller_id ? item.channel_seller_id.value : null;
      }

      let passed = this.validatePaymentData(paymentInfo);
      paymentInfo.ref_id = paymentInfo.ref_id.trim();

      if (passed) {
        this.loading = true;
        let body = paymentInfo;
        let url = `${config.npay.apiUrl}/payments/request`;
        let response = await http.post(url, { body });
        if (response.status === 200) {
          let body = await response.json();
          this.clearPayment();
          window.open(body.data.payment_url);
          return true;
        } else {
          let body = await response.json();
          swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            title: 'Error!',
            text: body.message,
            confirmButtonText: 'OK',
          });
          return false;
        }
      } else {
        swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: 'กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน',
          confirmButtonText: 'ลองอีกครั้ง',
        });
        return true;
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  async voidPayment(uid, isRedirect = true) {
    try {
      let result = await swal.fire({
        customClass: 'nexter-alert',
        text: 'ยืนยันที่จะ Void รายการนี้?',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'Void',
        reverseButtons: true,
      });

      if (result.value) {
        this.loading = true;
        let url = `${config.npay.apiUrl}/payments/${uid}/void`;
        let response = await http.put(url);

        if (response.status === 200) {
          const { data } = await response.json();

          swal
            .fire({
              customClass: 'nexter-alert',
              type: 'success',
              text: 'Void สำเร็จ',
              confirmButtonText: 'OK',
            })
            .then(() => {
              if (!isRedirect) {
                this.fetchChannelPayments();
              } else {
                window.location.href = `${config.web.rootpath}/payments/detail/${data.id}`;
              }
            });
        } else {
          let body = await response.json();
          swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            text: body.message,
            confirmButtonText: 'OK',
          });
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async refundPayment(id, isRedirect = true) {
    try {
      let result = await swal.fire({
        customClass: 'nexter-alert',
        text: 'ยืนยันที่จะ Refund รายการนี้?',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'Refund',
        reverseButtons: true,
      });

      if (result.value) {
        this.loading = true;
        let url = `${config.npay.apiUrl}/payments/${id}/refund`;
        let response = await http.put(url);

        if (response.status === 200) {
          const { data } = await response.json();

          swal
            .fire({
              customClass: 'nexter-alert',
              type: 'success',
              text: 'Refund สำเร็จ',
              confirmButtonText: 'OK',
            })
            .then(() => {
              if (!isRedirect) {
                this.fetchChannelPayments();
              } else {
                window.location.href = `${config.web.rootpath}/payments/detail/${data.id}`;
              }
            });
        } else {
          let body = await response.json();
          swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            text: body.message,
            confirmButtonText: 'OK',
          });
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async inquiryPayment(uid, isRedirect = true) {
    try {
      let result = await swal.fire({
        customClass: 'nexter-alert',
        text: 'ยืนยันที่จะปรับสถานะของรายการนี ให้เป็นไปตามข้อมูลจาก Payment Gateway',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ยืนยัน',
        reverseButtons: true,
      });

      if (result.value) {
        this.loading = true;
        let url = `${config.npay.apiUrl}/payments/${uid}/checkstatus`;
        let response = await http.put(url);
        if (response.status === 200) {
          let body = await response.json();
          if (body.data) {
            swal
              .fire({
                customClass: 'nexter-alert',
                type: 'warning',
                text: body.data,
                confirmButtonText: 'OK',
              })
              .then(() => {
                if (!isRedirect) {
                  this.fetchChannelPayments();
                } else {
                  window.location.href = `${config.web.rootpath}/payments/detail/${uid}`;
                }
              });
          } else {
            swal.fire({
              customClass: 'nexter-alert',
              type: 'success',
              text: 'รายการนี้ มีสถานะที่ตรงกันอยู่แล้ว, ไม่มีการปรับสถานะ',
              confirmButtonText: 'OK',
            });
          }
        } else {
          let body = await response.json();
          swal.fire({
            customClass: 'nexter-alert',
            type: 'error',
            text: 'ไม่พบหมายเลข Invoice, โปรดตรวจสอบ หมายเลข Invoice No, ' + body.message,
            confirmButtonText: 'OK',
          });
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async updateInvoicePayment(uid, isRedirect = true) {
    try {
      // let result = await swal.fire({
      //   text: 'ยืนยันที่จะ Void รายการนี้?',
      //   type: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#fbc658',
      //   confirmButtonText: 'Void',
      // });

      const swalAlert = await swal.fire({
        customClass: 'nexter-alert',
        title: 'ระบุหมายเลข Invoice No. ใหม่ให้กับรายการนี้',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ยืนยัน',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: inv => {
          this.loading = true;
          let url = `${config.npay.apiUrl}/payments/${uid}/invoice/${inv}`;
          return fetch(url, {
            method: 'PUT',
          })
            .then(response => {
              if (!response.ok) {
                throw new Error(response.statusText);
              }
              return response.json();
            })
            .catch(error => {
              swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
        allowOutsideClick: () => !swal.isLoading(),
      });
      const { value } = swalAlert;
      if (value) {
        await swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          text: 'แก้ไขหมายเลข Invoice สำเร็จ',
          confirmButtonText: 'OK',
        });
        if (!isRedirect) {
          this.fetchChannelPayments();
        } else {
          window.location.href = `${config.web.rootpath}/payments/detail/${value.payment.id}`;
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  async exportFile(options = {}) {
    try {
      this.loading = true;
      let { condition } = this.getData();

      let data = {
        channel_uid: condition.channel_uid,
        ref_id: condition.ref_id,
        card_type: condition.card_type,
        status: condition.status,
        transfer_status: condition.trans_status,
        seller_id: condition.seller_selected,
        start_date: moment(condition.start_date).valueOf(),
        end_date: moment(condition.end_date).valueOf(),
        date_selected: condition.date_selected ?? (condition.start_date || condition.end_date ? { value: 'updated_at' } : ''),
        expireFilter: condition.expireFilter,
        source: options.source,
      };
      let param = `${config.npay.apiUrl}/excel/payments`;
      let payments = await http.post(param, { body: data });
      if (payments.status === 200) {
        let body = await payments.json();
        window.open(body.data.url);
      }
    } catch (err) {
      console.error(err);
      swal.error('Error', 'Export ข้อมูลไม่สำเร็จ');
    } finally {
      this.loading = false;
    }
  }

  async showErrorDetail(e) {
    swal.fire({
      customClass: 'nexter-alert',
      icon: 'question',
      title: 'response Code ' + e.res_code,
      text: e.res_code === '32' ? 'ยกเลิกการชำระเงิน' : e.msg_to_show + ', ' + e.solution,
      footer: '<a href ="https://s.2c2p.com/manuals/ios/reference/responsecode.html">ตรวจสอบ Response Code อื่นๆ</a>',
    });
  }

  async updatePaymentDescription(id, value) {
    try {
      const url = `${config.npay.apiUrl}/payments/${id}/update-description`;
      const data = { description: value }
      const response = await http.put(url, { body: data });
      if (response.status === 200) {
        await swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          text: 'แก้ไขรายละเอียดเรียบร้อยแล้ว',
          confirmButtonText: 'ตกลง',
        });
      } else {
        let body = await response.json();
        await swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          text: body.message,
          confirmButtonText: 'ตกลง',
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  async updatePaymentItemDescription(id, value) {
    try {
      const url = `${config.npay.apiUrl}/payments/items/${id}/update-description`;
      const data = { description: value }
      const response = await http.put(url, { body: data });
      if (response.status === 200) {
        await swal.fire({
          customClass: 'nexter-alert',
          type: 'success',
          text: 'แก้ไขรายละเอียดเรียบร้อยแล้ว',
          confirmButtonText: 'ตกลง',
        });
      } else {
        let body = await response.json();
        await swal.fire({
          customClass: 'nexter-alert',
          type: 'error',
          text: body.message,
          confirmButtonText: 'ตกลง',
        });
      }
    } catch (err) {
      console.error(err);
    }
  }
}
