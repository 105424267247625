import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, CardBody, Label, FormGroup, Modal, ModalBody, ModalFooter } from 'reactstrap';
import 'react-table/react-table.css';
import { Page } from '../../components/Page';
import { CheckBox } from '../../components/Input/CheckBox';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';
import SellerPendingTable from './SellerPendingTable';

class ApproveSellerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      originalFilter: {},
    };
  }

  componentDidMount() {
    this.props.channel.fetchChannelAll();
    this.tempOriginalFilter();
  }

  componentWillUnmount() {
    this.props.seller.filterPending = this.state.originalFilter;
  }

  tempOriginalFilter() {
    const { filterPending: filter } = this.props.seller.getData();
    this.setState({ originalFilter: filter });
  }

  // Seller Code
  changeFilterPendingSellerCode(event) {
    this.props.seller.changeFilterPendingSellerCode(event.target.value);
  }

  // Channel UID
  changeFilterPendingChannelID(id) {
    this.props.seller.changeFilterPendingChannelID(id);
  }

  // Seller Company Name / Name
  changeFilterPendingSellerName(event) {
    this.props.seller.changeFilterPendingSellerName(event.target.value);
  }

  // Seller Email
  changeFilterPendingEmail(event) {
    this.props.seller.changeFilterPendingEmail(event.target.value);
  }

  // Channel (BU)
  changeChannel(optionSelected) {
    this.props.seller.changeChannel(optionSelected);
  }

  // สถานะบัญชี (multiple selection)
  toggleSellerAccountSelection(selected) {
    const index = this.props.seller.filterPending.seller_account_status_selection.indexOf(selected);
    if (index < 0) {
      this.props.seller.filterPending.seller_account_status_selection.push(selected);
    } else {
      this.props.seller.filterPending.seller_account_status_selection.splice(index, 1);
    }
    this.props.seller.filterPending.seller_account_status_selection = [...this.props.seller.filterPending.seller_account_status_selection];
  }

  // สถานะ KYC (multiple selection)
  toggleSellerKYCSelection(selected) {
    const index = this.props.seller.filterPending.seller_list_kyc_status_selection.indexOf(selected);
    if (index < 0) {
      this.props.seller.filterPending.seller_list_kyc_status_selection.push(selected);
    } else {
      this.props.seller.filterPending.seller_list_kyc_status_selection.splice(index, 1);
    }
    this.props.seller.filterPending.seller_list_kyc_status_selection = [...this.props.seller.filterPending.seller_list_kyc_status_selection];
  }

  // Seller Type (single selection)
  toggleSellerTypeSelection(selected) {
    const index = this.props.seller.filterPending.seller_type_selection.indexOf(selected);
    if (index < 0) {
      // if (this.props.seller.filterPending.seller_type_selection.length > 0) {
      //   this.props.seller.filterPending.seller_type_selection.pop();
      // }
      this.props.seller.filterPending.seller_type_selection.push(selected);
    } else {
      this.props.seller.filterPending.seller_type_selection.splice(index, 1);
    }
    this.props.seller.filterPending.seller_type_selection = [...this.props.seller.filterPending.seller_type_selection];
  }

  // Status (single selection)
  toggleSellerStatusSelection(selected) {
    const index = this.props.seller.filterPending.seller_status_selection.indexOf(selected);
    if (index < 0) {
      // if (this.props.seller.filterPending.seller_status_selection.length > 0) {
      //   this.props.seller.filterPending.seller_status_selection.pop();
      // }
      this.props.seller.filterPending.seller_status_selection.push(selected);
    } else {
      this.props.seller.filterPending.seller_status_selection.splice(index, 1);
    }
    this.props.seller.filterPending.seller_status_selection = [...this.props.seller.filterPending.seller_status_selection];
  }

  applySearchFilter() {
    this.props.seller.pagePending = 1;
    this.props.seller.fetchChannelSellers('pending');
    this.tempOriginalFilter();
  }

  clearFilter = () => {
    this.props.seller.pagePending = 1;
    this.props.seller.clearSellerListFilterPending();
  };

  onClear() {
    this.props.seller.pagePending = 1;
    this.props.seller.clearFilterPending();
    this.props.seller.fetchChannelSellers('pending');
    this.tempOriginalFilter();
  }

  modalToggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  /*
  displayAllSeller() {
    this.props.seller.fetchAllSeller();
    this.props.seller.table.selection = [];
    this.props.seller.table.selectAll = false;
  }

  displayPendingSeller() {
    this.props.seller.fetchPendingSellers();
    this.props.seller.table.selection = [];
    this.props.seller.table.selectAll = false;
  }

  displayPendingKYC() {
    this.props.seller.fetchPendingKYCs();
    this.props.seller.table.selection = [];
    this.props.seller.table.selectAll = false;
  }

  approveBtn() {
    this.props.seller.batchApproveSeller();
  }

  approveKYCBtn() {
    this.props.seller.batchApproveSellerKYC();
  }

  printDocument() {
    this.props.seller.previewSeller();
  }

  excelDocument() {
    this.props.seller.excelSeller();
  }

  toggleSelection = (key, shift, row) => {
    let selection = [...this.props.seller.table.selection];
    const keyIndex = selection.indexOf(key);

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
    } else {
      // it does not exist so add it
      selection.push(key);
    }
    // update the store
    this.props.seller.table.selection = selection;

    this.props.seller.checkNotPendingStatus();
    this.props.seller.checkNotPendingStatusKYC();
  };

  toggleAll = () => {
    const selectAll = !this.props.seller.table.selectAll;
    const selection = [];

    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.selectTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        if (item._original) {
          selection.push(item._original.uid);
        }
      });
    }
    this.props.seller.table.selectAll = selectAll;
    this.props.seller.table.selection = selection;

    this.props.seller.checkNotPendingStatus();
    this.props.seller.checkNotPendingStatusKYC();
  };

  isSelected = key => {
    return this.props.seller.table.selection.includes(key);
  };
  */

  render() {
    const { channels } = this.props.channel.getData();
    const channelOptions = channels.all.map(item => ({
      value: item.uid,
      label: item.name,
    }));

    const { filterPending } = this.props.seller.getData();

    return (
      <Page>
        <CardBody className="mt-3">
          <Row>
            <Col>
              <Label>
                <strong>พิมพ์ค้นหา</strong>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <FormGroup>
                <NexterInput className="nexter-input-sm" placeholder="Seller Code" value={filterPending.seller_code} onChange={this.changeFilterPendingSellerCode.bind(this)} />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <NexterInput
                  type="select"
                  className={'nexter-select-sm payment-channel-select'}
                  options={channels.all.map(c => ({ value: c.uid, label: c.name }))}
                  onChange={this.changeFilterPendingChannelID.bind(this)}
                  value={filterPending.channel_id}
                  placeholder="Channel Name"
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <NexterInput className="nexter-input-sm" placeholder="Company Name / Name" value={filterPending.seller_name} onChange={this.changeFilterPendingSellerName.bind(this)} />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <NexterInput className="nexter-input-sm" placeholder="Email" value={filterPending.email} onChange={this.changeFilterPendingEmail.bind(this)} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>
                <strong>Channel (BU)</strong>
              </Label>
            </Col>
          </Row>
          {/*
            <Row>
              <Col xs="6" sm="3" className="row2-col-pending-seller">
                <FormGroup>
                  <NexterInput type="select" size="md" placeholder="เลือก Channel (BU)" value={filterPending.channel_selected} options={channelOptions} onChange={this.changeChannel.bind(this)} />
                </FormGroup>
              </Col>
              <Col className="text-right row2-col-pending-seller">
                <NexterButton className="m-0 mr-3" size="sm" color="info" onClick={() => this.onClear()} outline>
                  Clear
                </NexterButton>
                <NexterButton className="m-0 mr-3" size="sm" color="dark" icon="fa fa-search" onClick={() => this.applySearchFilter()}>
                  SEARCH
                </NexterButton>
                <NexterButton className="m-0" size="sm" color="dark" icon="fa fa-filter" onClick={() => this.modalToggle()}>
                  Filter
                </NexterButton>
              </Col>
            </Row>
          */}
          <Row>
            <Col md={3}>
              <NexterInput type="select" placeholder="เลือก channel..." value={filterPending.channel_selected} options={channelOptions} onChange={this.changeChannel.bind(this)} />
            </Col>
            <Col className="text-right" md={{ size: 6, offset: 3 }}>
              <NexterButton outline color="info" className="m-0 mr-2" size="sm" onClick={this.onClear.bind(this)}>
                Clear
              </NexterButton>
              <NexterButton className="m-0 mr-2" size="sm" color="dark" icon="fa fa-search" onClick={this.applySearchFilter.bind(this)}>
                SEARCH
              </NexterButton>
              <NexterButton onClick={() => this.modalToggle()} color="dark" size="sm" className=" m-0" icon="fa fa-filter">
                Filter
              </NexterButton>
            </Col>
          </Row>
          <div style={{ height: 25 }} />
          <hr className="hr-seller" />
          <SellerPendingTable />
        </CardBody>

        <Modal isOpen={this.state.modal} toggle={this.modalToggle} onClosed={() => this.applySearchFilter()}>
          <ModalBody className="seller-modal-body">
            <p className="filter-title">Filters</p>
            <hr />
            <FormGroup>
              <Label className="w-100 text-dar">
                <strong>สถานะบัญชี</strong>
                {/*<!-- (multiple selection) -->*/}
              </Label>
              <FormGroup className="mt-2">
                <CheckBox label="Created" checked={filterPending.seller_account_status_selection.includes('created')} onChange={this.toggleSellerAccountSelection.bind(this, 'created')} />
                <CheckBox label="Approved" checked={filterPending.seller_account_status_selection.includes('approved')} onChange={this.toggleSellerAccountSelection.bind(this, 'approved')} />
                <CheckBox label="Rejected" checked={filterPending.seller_account_status_selection.includes('rejected')} onChange={this.toggleSellerAccountSelection.bind(this, 'rejected')} />
              </FormGroup>
              <FormGroup>
                <CheckBox label="Pending" checked={filterPending.seller_account_status_selection.includes('pending')} onChange={this.toggleSellerAccountSelection.bind(this, 'pending')} />
                <CheckBox label="Incomplete" checked={filterPending.seller_account_status_selection.includes('incomplete')} onChange={this.toggleSellerAccountSelection.bind(this, 'incomplete')} />
              </FormGroup>
            </FormGroup>
            <hr />
            <FormGroup>
              <Label className="w-100 text-dark">
                <strong>สถานะ KYC</strong>
                {/*<!-- (multiple selection) -->*/}
              </Label>
              <FormGroup className="mt-2">
                <CheckBox label="Created" checked={filterPending.seller_list_kyc_status_selection.includes('created')} onChange={this.toggleSellerKYCSelection.bind(this, 'created')} />
                <CheckBox label="Approved" checked={filterPending.seller_list_kyc_status_selection.includes('approved')} onChange={this.toggleSellerKYCSelection.bind(this, 'approved')} />
                <CheckBox label="Rejected" checked={filterPending.seller_list_kyc_status_selection.includes('rejected')} onChange={this.toggleSellerKYCSelection.bind(this, 'rejected')} />
              </FormGroup>
              <FormGroup>
                <CheckBox label="Pending" checked={filterPending.seller_list_kyc_status_selection.includes('pending')} onChange={this.toggleSellerKYCSelection.bind(this, 'pending')} />
                <CheckBox label="Incomplete" checked={filterPending.seller_list_kyc_status_selection.includes('incomplete')} onChange={this.toggleSellerKYCSelection.bind(this, 'incomplete')} />
              </FormGroup>
            </FormGroup>
            <hr />
            <FormGroup>
              <Label className="w-100 text-dark">
                <strong>Seller Type</strong>
                {/*<!-- (single selection) -->*/}
              </Label>
              <FormGroup className="mt-2">
                <CheckBox label="Seller" checked={filterPending.seller_type_selection.includes('partner')} onChange={this.toggleSellerTypeSelection.bind(this, 'partner')} />
                <CheckBox label="Channel" checked={filterPending.seller_type_selection.includes('channel')} onChange={this.toggleSellerTypeSelection.bind(this, 'channel')} />
              </FormGroup>
            </FormGroup>
            <hr />
            <FormGroup>
              <Label className="w-100 text-dark">
                <strong>Status</strong>
                {/*<!-- (single selection) -->*/}
              </Label>
              <FormGroup className="mt-2">
                <CheckBox label="Active" checked={filterPending.seller_status_selection.includes('active')} onChange={this.toggleSellerStatusSelection.bind(this, 'active')} />
                <CheckBox label="Inactive" checked={filterPending.seller_status_selection.includes('inactive')} onChange={this.toggleSellerStatusSelection.bind(this, 'inactive')} />
              </FormGroup>
            </FormGroup>
            <hr />
          </ModalBody>
          <ModalFooter className="seller-modal-footer">
            <Row>
              <Col>
                <NexterButton onClick={() => this.clearFilter()} color="secondary" className="btn-sm text-capitalize">
                  Clear all filters
                </NexterButton>
              </Col>
              <Col>
                <NexterButton onClick={() => this.modalToggle()} className="btn-sm text-capitalize">
                  Done
                </NexterButton>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </Page>
    );
  }
}

export default inject('seller', 'channel')(observer(ApproveSellerPage));
