import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { Card, CardBody, CardFooter, Col, Row, Table, UncontrolledTooltip } from 'reactstrap';

import NexterButton from '../../components/Button/NexterButton';
import { Amount } from '../../components/Field/Amount';
import { Pagination } from '../../components/Pagination/Pagination';
import { EmptyRow } from '../../components/Table/EmptyRow';
import Highlighter from 'react-highlight-words';
import moment from 'moment';

const rowStyles = classNames('pb-3');
const colStyles = classNames('px-0');

const tableHeaderColumns = [
  {
    title: '',
    width: 10,
  },
  {
    title: 'REF. ID',
    width: 160,
  },
  {
    title: 'รายละเอียด',
    width: 160,
  },
  {
    title: 'ผู้ชำระเงิน',
  },
  {
    title: 'ยอดชำระรวม',
    width: 110,
  },
  {
    title: 'ผู้ขาย/ผู้รับเงิน',
  },
  {
    title: (
      <>
        สถานะ
        <br />
        ชำระเงิน
      </>
    ),
    className: 'text-center',
    width: 105,
  },
  {
    title: (
      <>
        สถานะ
        <br />
        โอนเงิน
      </>
    ),
    className: 'text-center',
    width: 105,
  },
  {
    title: (
      <>
        วันที่
        <br />
        ชำระเงิน
      </>
    ),
    className: 'text-center',
    width: 107,
  },
  {
    title: (
      <>
        วันที่
        <br />
        แก้ไขล่าสุด
      </>
    ),
    className: 'text-center',
    width: 107,
  },
  {
    title: 'ช่องทางชำระเงิน',
    width: 140,
  },
  {
    title: 'ผู้ออกบัตร',
    width: 130,
  },
];

const statusSellerAccountToShow = ['pending', 'created', 'rejected'];

class PaymentTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hightLight: [],
      openSubPayment: [],
    };
  }

  getPaymentList() {
    const { payments } = this.props.payment.getData();
    return payments && payments.items && payments.items.length > 0
      ? payments.items.map(payment => {
          return this.mapPaymentStatus(payment);
        })
      : [];
  }

  mapPaymentStatus(payment) {
    let transferStatus = '-';
    if (payment.transfer_items.length > 0) {
      let transferred = payment.transfer_items.filter(item => item.status !== 'created' && item.type === 'product');
      if (transferred.length) {
        transferStatus = 'transferred';
      } else {
        transferStatus = 'pending';
      }
    }
    let statusString = payment.status;
    if (statusString === 'refunded') statusString = 'refund';
    else if (statusString === 'pending_settle') statusString = 'Pending Settle';
    else if (statusString === 'mismatch_settled') statusString = 'Mismatch Settled';
    const paymentMethodString = this.getPaymentMethodString(payment.method);
    return { payment, transferStatus, paymentMethodString, statusString };
  }

  getPaymentMethodString(method) {
    switch (method) {
      case 'credit_card':
        return 'Credit Card';
      case 'qr_box':
        return (
          <>
            Bill Payment (BBL)
            <br />
            &#8226; Mobile Banking
          </>
        );
      case 'bill_payment_mobile':
        return 'Mobile Banking';
      case 'bill_payment':
        return 'Counter Service';
      case 'line':
        return 'Line Pay';
      case 'installment':
        return 'Installment';
      case 'scg_wallet':
        return 'SCG Wallet';
      case 'chang_family_wallet':
        return 'Chang Family Wallet';
      case 'promptpay':
        return 'Thai QR Payment';
      case 'grabpay':
        return 'GrabPay';
      case 'cbdc':
        return 'CBDC Payment';
      case 'mobile_banking_opn':
        return 'Mobile Banking (App Switch)';
      default:
        return '-';
    }
  }

  goToDetail(id) {
    this.props.payment.getPaymentByUid(id);
  }

  goToDetailWithSubPayment(uid) {
    this.props.payment.getPaymentWithSubPayment(uid);
  }

  selectPage(page) {
    this.props.payment.selectPage(page);
  }

  getFirstItemDescription(payment) {
    if(payment.items && payment.items[0])
      return payment.items[0].description;
    else
      return payment.description
  }

  toggleSubPayment(i) {
    const openSubPayment = this.state.openSubPayment
    openSubPayment[i] = !openSubPayment[i];
    this.setState({ openSubPayment });
  }

  renderParentRow({ payment, transferStatus, paymentMethodString, statusString, i}) {
    return (
      <tr key={payment.id}>
        <td>
          <NexterButton size="sm" onClick={this.toggleSubPayment.bind(this, i)}>
            <i className={this.state.openSubPayment[i] ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
          </NexterButton>
        </td>
        <td className="ref-id">
          <Highlighter searchWords={this.state.hightLight} onClick={this.goToDetailWithSubPayment.bind(this, payment.uid)} autoEscape textToHighlight={payment.ref_id} />
        </td>
        <td id={payment.uid + '_d' + payment.id}>
          <Highlighter
            searchWords={this.state.hightLight}
            autoEscape
            textToHighlight={payment.description && payment.description.length > 26 ? payment.description.substring(0, 36) + '...' : !payment.description ? '-' : payment.description}
          />
          {payment.description && payment.description.length > 26 ? (
            <UncontrolledTooltip placement="top" target={payment.uid + '_d' + payment.id} delay={{ show: 500, hide: 0 }}>
              {payment.description}
            </UncontrolledTooltip>
          ) : (
            ''
          )}
        </td>
        <td>
          <Highlighter searchWords={this.state.hightLight} autoEscape textToHighlight={payment.customer_name} />
        </td>
        <td id={payment.uid + '_a' + payment.id} style={{ color: 'black' }}>
          <Amount value={payment.total} />
        </td>
        <td id={payment.uid + '_n' + payment.id}>
          {''}
        </td>
        <td className="text-center text-capitalize" id={payment.uid + '_' + payment.id}>
          <NexterButton size="sm" color="link" onClick={() => this.errorDetail(payment.gatewayResponse)}>
            <span className={`badge status status-${payment.status}`}>{statusString}</span>
          </NexterButton>
          {payment.status === 'failed' ? (
            <UncontrolledTooltip placement="top" target={payment.uid + '_' + payment.id} delay={{ show: 500, hide: 0 }}>
              {' '}
              {payment.gatewayResponse ? payment.gatewayResponse.msg_to_show : payment.failure_reason ? payment.failure_reason : 'ไม่พบรหัสความผิดผลาด'}{' '}
            </UncontrolledTooltip>
          ) : (
            ''
          )}
        </td>
        <td className="text-center text-capitalize">
          <span className={`badge transfer-status transfer-status-${payment.transfer_status}`}>{payment.transfer_status}</span>
        </td>
        <td className="text-center">
          {payment.payment_at ? moment(payment.payment_at).format('YYYY-MM-DD') : '-'}
          <br />
          {payment.payment_at ? moment(payment.payment_at).format('HH:mm') : ''}
        </td>
        <td className="text-center">
          {moment(payment.updated_at).format('YYYY-MM-DD')}
          <br />
          {moment(payment.updated_at).format('HH:mm')}
        </td>
        <td>{paymentMethodString}</td>
        <td id={payment.uid + '_ct' + payment.id}>{payment.card_type ? `${payment.card_type?.card_bank_code || ''}` : '-'}</td>
      </tr>
    )
  }

  renderPaymentRow({ payment, transferStatus, paymentMethodString, statusString, i }) {
    let sellerName = payment.seller ? payment.seller.name : '';
    let sellerAccountStatus = payment.seller?.sellers_account?.status || '';

    sellerName = !payment.seller?.deleted_at && sellerName.length > 26 ? sellerName.substring(0, 26) + '...' : sellerName
    sellerAccountStatus = statusSellerAccountToShow.includes(sellerAccountStatus) ? `(${sellerAccountStatus})` : '';
    sellerName = sellerName + ' ' + sellerAccountStatus;

    return (
      <tr key={payment.id}>
        <td>
        </td>
        <td className="ref-id">
          <Highlighter searchWords={this.state.hightLight} onClick={this.goToDetail.bind(this, payment.id)} autoEscape textToHighlight={payment.ref_id} />
        </td>
        <td id={payment.uid + '_d' + payment.id}>
          <Highlighter
            searchWords={this.state.hightLight}
            autoEscape
            textToHighlight={
              this.getFirstItemDescription(payment) && this.getFirstItemDescription(payment).length > 26 ? 
                this.getFirstItemDescription(payment).substring(0, 36) + '...' : 
                this.getFirstItemDescription(payment) || '-'}
          />
          {this.getFirstItemDescription(payment) && this.getFirstItemDescription(payment).length > 26 ? (
            <UncontrolledTooltip placement="top" target={payment.uid + '_d' + payment.id} delay={{ show: 500, hide: 0 }}>
              {this.getFirstItemDescription(payment)}
            </UncontrolledTooltip>
          ) : (
            ''
          )}
        </td>
        <td>
          <Highlighter searchWords={this.state.hightLight} autoEscape textToHighlight={payment.customer_name} />
        </td>
        <td id={payment.uid + '_a' + payment.id} style={{ color: 'black' }}>
          <Amount value={payment.total} />
        </td>
        <td className={classNames({ 'text-wrap': payment.seller?.deleted_at })} id={payment.uid + '_n' + payment.id}>
          {payment.seller ? (sellerName) : '-'}
          {payment.seller ? (
            sellerName.length > 26 ? (
              <UncontrolledTooltip placement="top" target={payment.uid + '_n' + payment.id} delay={{ show: 500, hide: 0 }}>
                {' '}
                {sellerName}
              </UncontrolledTooltip>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </td>
        <td className="text-center text-capitalize" id={payment.uid + '_' + payment.id}>
          <NexterButton size="sm" color="link" onClick={() => this.errorDetail(payment.gatewayResponse)}>
            <span className={`badge status status-${payment.status}`}>{statusString}</span>
          </NexterButton>
          {payment.status === 'failed' ? (
            <UncontrolledTooltip placement="top" target={payment.uid + '_' + payment.id} delay={{ show: 500, hide: 0 }}>
              {' '}
              {payment.gatewayResponse ? payment.gatewayResponse.msg_to_show : payment.failure_reason ? payment.failure_reason : 'ไม่พบรหัสความผิดผลาด'}{' '}
            </UncontrolledTooltip>
          ) : (
            ''
          )}
        </td>
        <td className="text-center text-capitalize">
          <span className={`badge transfer-status transfer-status-${payment.transfer_status}`}>{payment.transfer_status}</span>
        </td>
        <td className="text-center">
          {payment.payment_at ? moment(payment.payment_at).format('YYYY-MM-DD') : '-'}
          <br />
          {payment.payment_at ? moment(payment.payment_at).format('HH:mm') : ''}
        </td>
        <td className="text-center">
          {moment(payment.updated_at).format('YYYY-MM-DD')}
          <br />
          {moment(payment.updated_at).format('HH:mm')}
        </td>
        <td>{paymentMethodString}</td>
        <td id={payment.uid + '_ct' + payment.id}>{payment.card_type ? `${payment.card_type?.card_bank_code || ''}` : '-'}</td>
      </tr>
    )
  }

  renderMainRow({ payment, transferStatus, paymentMethodString, statusString, i }) {
    if(payment.sub_payment_id === null) {
      return this.renderPaymentRow({ payment, transferStatus, paymentMethodString, statusString, i });
    } else {
      return this.renderParentRow({ payment, transferStatus, paymentMethodString, statusString, i });
    }
  }

  renderPaymentRecord({ payment, transferStatus, paymentMethodString, statusString, i }) {
    return (
      <React.Fragment key={i}>
        {
          (this.renderMainRow({ payment, transferStatus, paymentMethodString, statusString, i }))
        }
        {
          this.state.openSubPayment[i] && payment.sub_payments &&
          payment.sub_payments.map((subPayment) => {
              const { payment, transferStatus, paymentMethodString, statusString } = this.mapPaymentStatus(subPayment);
              return (this.renderPaymentRow({ payment, transferStatus, paymentMethodString, statusString, i }));
            })
        }
      </React.Fragment>
    )
  }

  renderPaymentRecords() {
    const payments = this.getPaymentList();
    return payments && payments.length ? (
      payments.map(({ payment, transferStatus, paymentMethodString, statusString }, i) => (
        this.renderPaymentRecord({ payment, transferStatus, paymentMethodString, statusString, i })
        )
      )
    ) : (
      <EmptyRow colSpan={tableHeaderColumns.length} />
    );
  }

  render() {
    const { payments } = this.props.payment.getData();

    return (
      <>
        {/* uses mr-0 because cols use pr-0 */}
        <Row className={rowStyles}>
          <Col className={colStyles}>
            <Card className="card-payment-list">
              <CardBody>
                <div className="table-reponsive table-scroll">
                  <Table className="w-100">
                    <thead>
                      <tr>
                        {tableHeaderColumns.map(({ title, className = '', width = 100, props = {} }, i) => (
                          <th key={i} className={classNames('border-0', className)} style={{ minWidth: width, maxWidth: width }} scope="col" {...props}>
                            {title}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>{this.renderPaymentRecords()}</tbody>
                  </Table>
                </div>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col>
                    <Pagination
                      className="pull-right"
                      activePage={payments.page}
                      displayPageNumber={5}
                      maximumPageNumber={Math.ceil(payments.total / payments.size)}
                      onSelect={this.selectPage.bind(this)}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default inject('payment', 'channel', 'seller')(observer(PaymentTable));
