import React from 'react';
import moment from 'moment';

import { Table, Button } from 'reactstrap';
import { PAYMENT_GATEWAYS } from '../../../../constants/paymentConstants';
import { Pagination } from '../../../../components/Pagination/Pagination';

function ListMidChannels(props) {
  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>MID NAME</th>
            {props.gateway === PAYMENT_GATEWAYS.CCPP && <th>MID</th>}
            <th>CODE</th>
            <th>CREATED AT</th>
            <th>LAST UPDATED</th>
            <th>UPDATE BY</th>
            <th>STATUS</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {props.channels.map((channel, index) => {
            const rowNumber = ((props.pagination?.activePage || 0) - 1) * (props.pagination?.size || 0) + index + 1;
            return (
              <tr key={channel.id}>
                <th scope="row">{rowNumber}</th>
                <td>{channel.label}</td>
                {props.gateway === PAYMENT_GATEWAYS.CCPP && <td>{channel.merchant_id}</td>}
                <td>{channel.code}</td>
                <td>{moment(channel.created_at).format('ll')}</td>
                <td>{moment(channel.updated_at).format('ll')}</td>
                <td>{channel.updated_by}</td>
                <td>{channel.active ? 'ACTIVE' : 'INACTIVE'}</td>
                <td>
                  <Button size="sm" color="primary" outline className="nexter-btn text-uppercase" onClick={() => props.onEdit({ ...channel })}>
                    <i className="fas fa-pencil-alt mr-1" />
                    EDIT
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {!!props.pagination?.total && (
        <Pagination
          className="pull-right mt-4"
          activePage={props.pagination?.activePage}
          displayPageNumber={5}
          maximumPageNumber={Math.ceil(props.pagination?.total / props.pagination?.size)}
          onSelect={props.pagination?.onSelect}
        />
      )}
    </>
  );
}

export default ListMidChannels;

/**
 * active: (...)
code: (...)
company_name: (...)
created_at: (...)
id: (...)
label: (...)
merchant_id: (...)
secret_key: (...)
updated_at: (...)
updated_by: (...)
 */
