import React, { Fragment } from 'react';

import MenuItem from './MenuItem';

class MenuGroup extends React.Component {
  render() {
    const props = this.props;

    return (
      <Fragment>
        <li className="menu-group">{props.name}</li>
        {props.items.map((item, subIndex) => {
          return (
            <MenuItem
              key={`${props.index}${subIndex}`}
              path={item.path}
              icon={item.icon}
              name={item.name}
            />
          );
        })}
      </Fragment>
    );
  }
}

export default MenuGroup;
