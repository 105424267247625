import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import _ from 'lodash';
import { config } from '../../config';
import { http } from '../../utils/http';
import { Table, Row, Col } from 'reactstrap';
import { EmptyRow } from '../../components/Table/EmptyRow';
import { Pagination } from '../../components/Pagination/Pagination';
import { ensureJSONparse } from '../../utils/string';

const FIELD = {
  channels: {
    status: 'สถานะ Channel',
    payment_success_email	: 'Get payment successful email',
    file_ref_url: 'Application Form',
    logo_url: 'Channel Logo',
    company_tax_id: 'Company Tax ID',
    company_name: 'Company Name',
    ebill_account: 'Billing Type',
    name: 'Channel Name',
    ccpp_account: 'Payment Account',
    webhook_path: 'Payment & KYC Webhook Path',
    webhook_path_transfer: 'Transfer Webhook Path',
    callback_path: 'Callback Path',
    url_test: 'URL Test',
    url_prod: 'URL Production',
    is_send_webhook_success : 'Payment & KYC Webhook Success',
    is_send_webhook_failed : 'Payment & KYC Webhook Failed',
    is_send_webhook_pending : 'Payment & KYC Webhook Pending',
    is_send_webhook_voided : 'Payment & KYC Webhook Voided',
    is_send_webhook_refunded : 'Payment & KYC Webhook Refunded',
    is_send_webhook_transfer_transferred : 'Transfer Webhook Transferred',
    is_send_webhook_transfer_created : 'Transfer Webhook Created',
    is_send_webhook_transfer_requested : 'Transfer Webhook Requested',
    is_active: 'Status',
    reject_reason: 'เหตุผลที่ปฏิเสธ'
  },
  channels_contract: {
    name: 'Contact person - Name',
    email: 'Contact person - Email',
    phone: 'Contact person - Telephone No.',
    type: 'Contact person - Contract Type',
  },
  channel_installment_interest: {
    cost: 'Installment'
  },
  channel_payment_method: {
    fee_type: 'Full payment - Type',
    fixed_cost_fee: 'Full payment - Fee',
    status: 'PAYMENT METHOD',
    tax: 'Tax ID+Suffix (สำหรับ Barcode)'
  },
  channel_setting_due_date: {
    default_due_date: 'จำนวนวันเริ่มต้น',
    maximum_due_date: 'จำนวนวันสูงสุด'
  },
  installment_cards: {
    create: 'บัตรเครดิตที่ใช้ผ่อนชำระ',
    delete: 'บัตรเครดิตที่ใช้ผ่อนชำระ'
  },
  installments: {
    create: 'Installment Campaign',
    name: 'Campaign Name',
    start_date: 'Campaign - Start Date',
    end_date: 'Campaign - End Date',
    status: 'สถานะ Campaign'
  }
}

const t = (entity, field) => {
  const translateField = _.get(FIELD, [entity, field], field)
  return translateField
}

const BILLING_TYPE = {
  '1' : 'SCG SAP',
  '2' : 'SCG Non-SAP',
  '3' : 'Non SCG'
}

const CONTRACT_TYPE_OPTIONS = {
  BA: 'BUSINESS',
  IT: 'TECH'
}

class HistoryLogTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      page: 1,
      size: 10,
      total: 1,
    }
  }

  async componentDidMount() {
    await this.fetchHistoryLogs();
  }

  async fetchHistoryLogs() {
    const { setLoading, parentUid } = this.props
    const tab = new URL(window.location.href).searchParams.get('tab')
    let entity = [
      'channels',
      'channels_contract',
      'channel_payment_method',
      'channel_installment_interest',
    ]
    switch (tab) {
      case 'installment-campaign':
        entity = ['installments', 'installment_cards']
        break;
      case 'due-of-payment':
        entity = ['channel_setting_due_date']
        break;
      default:
        break;
    }
    try {
      setLoading(true)
      let url = `${config.npay.apiUrl}/historylogs`;
      let body = {
        page: this.state.page,
        size: this.state.size,
        parent: 'channel',
        entity: entity,
        action_name: [ 'update', 'create', 'delete' ]
      };
      if (parentUid) body['parent_uid'] = parentUid;

      let response = await http.post(url, { body });
      if (response.status === 200) {
        let body = await response.json();
        this.setState({
          logs: body.data || [],
          total: body.total || 1,
        });
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 100);
    }
  }

  selectPage(page) {
    this.setState({ page }, async () => {
      await this.fetchHistoryLogs();
    });
  }

  render() {
    const { logs } = this.state;
    let rows = logs.length ? logs.map((item, index) => {
      const oldValue = ensureJSONparse(item.old_value);
      const newValue = ensureJSONparse(item.new_value);
      const actionName = _.get(item, 'action_name', '');
      let list = newValue !== null && !_.isEmpty(newValue) ? newValue : oldValue;
      
      let result = Object.entries(list)
      .filter(([key, value], i) => {
        let ov = _.get(oldValue, key);
        let nv = _.get(newValue, key);
        if(['default_due_date', 'maximum_due_date'].includes(key)) return `${ov}` !== `${nv}`
        return typeof ov === typeof nv ? ov !== nv : Boolean(ov) !== Boolean(nv)
      })
      .sort((a, b) => b.includes('status') > a.includes('status') ? 1 : -1)
      .map(([key, value], i) => {
        const entity = _.get(item, 'entity')
        const field = t(entity, key)
        let ov = _.get(oldValue, key);
        let nv = _.get(newValue, key);
        const [updated_by] = _.get(item, 'updated_by', '').split('|||')
        if (key === 'payment_success_email' || 
          [
            'is_send_webhook_success',
            'is_send_webhook_failed', 
            'is_send_webhook_pending', 
            'is_send_webhook_voided', 
            'is_send_webhook_refunded',
            'is_send_webhook_transfer_transferred',
            'is_send_webhook_transfer_created',
            'is_send_webhook_transfer_requested',
          ]
          .includes(key)
        ) {
          ov = ov ? 'Yes' : 'No';
          nv = nv ? 'Yes' : 'No';
        } else if (key === 'ebill_account') {
          ov = BILLING_TYPE[`${ov}`]
          nv = BILLING_TYPE[`${nv}`]
        } else if (key === 'is_active') {
          ov = ov ? 'Active' : 'Inactive';
          nv = nv ? 'Active' : 'Inactive';
        } else if (entity === 'channels_contract' && key === 'type') {
          ov = CONTRACT_TYPE_OPTIONS[ov] || ov
          nv = CONTRACT_TYPE_OPTIONS[nv] || nv
        }
        return (
          <tr key={`item-${item.id}-${i}`}>
            <td width="142">
              {moment(_.get(item, 'updated_at', '-')).format('DD-MM-YYYY HH:mm')}
            </td>
            <td width="152">{updated_by}</td>
            <td width="150">{_.get(item.channel, 'name', '-')}</td>
            <td width="80">
              <div className={`action-wrapper ${actionName}`}>{actionName}</div>
            </td>
            <td width="150" className="keys">
              {!(key === 'create' && entity === 'channels') ? field : <span className="data-is-null">-</span>}
            </td>
            <td width="150" className={`${key === 'status' && entity === 'channels' ? 'text-uppercase' : ''}`}>
              {ov && !(key === 'create' && entity === 'channels') ? ov : <span className="data-is-null">-</span>}
            </td>
            <td width="150" className={`${key === 'status' && entity === 'channels' ? 'text-uppercase' : ''}`}>
              {nv && !(key === 'create' && entity === 'channels') ? nv : <span className="data-is-null">-</span>}
            </td>
          </tr>
        )
      })
      return result;
    }) : <EmptyRow colSpan={7} />;

    return (
        <Fragment>
            <Table>
                <thead>
                    <tr>
                        <th className="border-0">วันที่แก้ไข</th>
                        <th className="border-0">แก้ไขโดย</th>
                        <th className="border-0">Channel Name</th>
                        <th className="border-0">กิจกรรม</th>
                        <th className="border-0">ฟิลด์ที่แก้ไข</th>
                        <th className="border-0">ข้อมูลเดิม</th>
                        <th className="border-0">ข้อมูลที่แก้ไข</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
            <hr />
            <Row>
                <Col />
                <Col>
                <Pagination
                    className="pull-right"
                    activePage={this.state.page}
                    displayPageNumber={5}
                    maximumPageNumber={Math.ceil(this.state.total / this.state.size)}
                    onSelect={(page) => this.selectPage(page)}
                />
                </Col>
            </Row>
        </Fragment>
    );
  }
}

export default inject('channel')(observer(HistoryLogTable));
