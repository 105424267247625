import React, { useState } from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CustomInputDatePicker from '../../../components/Input/DatePicker';
import NexterButton from '../../../components/Button/NexterButton';
import NexterInput from '../../../components/Input/NexterInput';
import AutoTransferFilterModal from './AutoTransferFilterModal';
import 'react-datepicker/dist/react-datepicker.css';

const AutoTransferFilterTab = ({ channels = [], filter = {}, onChange, onClearAll, onClearSome, onSubmit }) => {
  const { channelUid, updatedBy, updatedStartDate, updatedEndDate } = filter;
  const [isOpenModal, setIsOpenModal] = useState(false);

  const channelOptions = channels.all.map(c => ({ value: c.uid, label: c.name }));

  function onOpenModal() {
    setIsOpenModal(true);
  }

  function onCloseModal() {
    setIsOpenModal(false);
    onSubmit();
  }

  return (
    <>
      <Row className="filter-auto-transfer align-items-center px-2">
        <Col xs={12} lg={6} className="px-2">
          <Label>
            <strong>ค้นหา</strong>
          </Label>
          <Row className="px-2">
            <Col xs={12} md={6} className="px-2">
              <FormGroup>
                <NexterInput
                  type="select"
                  className={'nexter-select-sm payment-channel-select z-index-6'}
                  options={channelOptions}
                  value={channelOptions.find(co => co.value === channelUid) ?? ''}
                  onChange={e => onChange('channelUid', e.value)}
                  placeholder="เลือก Channel"
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6} className="px-2">
              <FormGroup>
                <NexterInput placeholder="Update by" value={updatedBy} onChange={e => onChange('updatedBy', e.target.value)} />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col xs={12} lg={6} className="px-2">
          <Label>
            <strong>วันที่ Update</strong>
          </Label>
          <Row className="px-2">
            <Col xs={12} md={6} className="px-2">
              <FormGroup>
                <DatePicker
                  popperClassName="z-index-6"
                  customInput={
                    <CustomInputDatePicker size="sm" className="w-100" children={updatedStartDate ? null : <div className="flex-fill text-base text-capitalize text-secondary">Start Date</div>} />
                  }
                  locale="th"
                  dateFormat="dd/MM/yyyy"
                  selected={updatedStartDate}
                  onChange={value => onChange('updatedStartDate', value)}
                  selectsStart
                  startDate={updatedStartDate}
                  endDate={updatedEndDate}
                  maxDate={updatedEndDate}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6} className="px-2">
              <FormGroup>
                <DatePicker
                  popperClassName="z-index-6"
                  customInput={
                    <CustomInputDatePicker size="sm" className="w-100" children={updatedEndDate ? null : <div className="flex-fill text-base text-capitalize text-secondary">End Date</div>} />
                  }
                  locale="th"
                  dateFormat="dd/MM/yyyy"
                  selected={updatedEndDate}
                  onChange={value => onChange('updatedEndDate', value)}
                  selectsEnd
                  startDate={updatedStartDate}
                  endDate={updatedEndDate}
                  minDate={updatedStartDate}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col xs={12} className="px-2 d-flex flex-column flex-md-row justify-content-end" style={{ gap: 15 }}>
          <NexterButton size="sm" color="info" outline onClick={() => onClearAll()}>
            Clear
          </NexterButton>
          <NexterButton size="sm" color="dark" icon="fa fa-search" onClick={() => onSubmit()}>
            Search
          </NexterButton>
          <NexterButton size="sm" color="dark" icon="fa fa-filter" onClick={() => onOpenModal()}>
            Filter
          </NexterButton>
        </Col>
      </Row>

      <AutoTransferFilterModal isOpen={isOpenModal} filter={filter} onChange={onChange} onClearFilters={onClearSome} onClose={() => onCloseModal()} />
    </>
  );
};

export default AutoTransferFilterTab;
