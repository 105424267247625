import React, { Component } from 'react';
import qs from 'query-string';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { Container, Row, Col, Card, Alert } from 'reactstrap';
import { config } from '../../config';
import { Page } from '../../components/Page';
import ResetPasswordForm from './components/ResetPasswordForm';
import Visibility from '../../components/Visibility/Visibility';
import NexterButton from '../../components/Button/NexterButton';
import logo from '../../assets/img/Logo-SFinPay.png';

class ResetPasswordPage extends Component {
  async componentDidMount() {
    let { refid } = qs.parse(this.props.location.search);
    if (refid) {
      this.props.user.getUserByRefId(refid);
    } else {
      let access_token = localStorage.getItem('token');
      if (access_token) {
        let payload = jwtDecode(access_token);
        this.props.user.changeUsername(payload?.username);
        this.props.user.getUsersById(payload?.user_id);
      } else {
        this.props.user.logout();
      }
    }
  }

  changePassword(value) {
    this.props.user.changePassword(value);
  }

  changeNewPassword(value) {
    this.props.user.changeNewPassword(value);
  }

  changeConfirmNewPassword(value) {
    this.props.user.changeConfirmNewPassword(value);
  }

  gotoForgotPasswordPage() {
    this.props.history.push(`${config.web.rootpath}/forgot-password`);
  }

  onSubmit(e) {
    e.preventDefault();
    let { user } = this.props.user.getData();
    if (user.reset_password_ref_id) {
      this.props.user.updatePasswordByRefId();
    } else {
      this.props.user.updatePassword();
    }
  }

  onCancel() {
    this.props.user.logout();
  }

  render() {
    let { loading, user, hasExpiredResetPassword, isFailedCurrentPassword } = this.props.user.getData();
    let { password, new_password: newPassword, confirm_new_password: confirmNewPassword } = user;
    const hasExpiredPassword = moment(user.password_expires_at).valueOf() < new Date().valueOf();

    return (
      <Page loading={loading} className="d-flex min-vh-100 align-items-center">
        <Container className="py-3">
          <Row className="justify-content-center">
            <Visibility isVisibility={!!user.username && !hasExpiredResetPassword}>
              <Col className="col-12 col-md-8 col-lg-5">
                <Card className="card-shadow0 m-0 p-4">
                  <div className="text-center">
                    <img src={logo} width="175px" />
                    <h4 className="text-blue bold">{user.password_expires_at ? 'Reset' : 'Set'} Your Password</h4>
                    <Visibility isVisibility={!user.reset_password_ref_id && hasExpiredPassword}>
                      <Alert className="alert-danger-light p-2">Your password has expired, please change it.</Alert>
                    </Visibility>
                  </div>
                  <ResetPasswordForm
                    requiredCurrentPassword={!user.reset_password_ref_id}
                    currentPassword={password}
                    newPassword={newPassword}
                    confirmNewPassword={confirmNewPassword}
                    isFailedCurrentPassword={isFailedCurrentPassword}
                    onChangeCurrentPassword={this.changePassword.bind(this)}
                    onChangeNewPassword={this.changeNewPassword.bind(this)}
                    onChangeConfirmNewPassword={this.changeConfirmNewPassword.bind(this)}
                    onSubmit={this.onSubmit.bind(this)}
                    onCancel={this.onCancel.bind(this)}
                  />
                </Card>
              </Col>
            </Visibility>
          </Row>
        </Container>
      </Page>
    );
  }
}

export default inject('user')(observer(ResetPasswordPage));
