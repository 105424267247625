import React, { Component } from 'react';
import DropZone from 'react-dropzone';

import NexterButton from '../../components/Button/NexterButton';

export default class FilePicker extends Component {
  render() {
    let content = this.props.src ? (
      this.props.src.name ? (
        <NexterButton
          size="sm"
          color="success"
          style={{ marginTop: 0, marginBottom: 0 }}
          block
          outline
        >
          {this.props.src.name}
        </NexterButton>
      ) : (
        <NexterButton
          size="sm"
          color="success"
          style={{ marginTop: 0, marginBottom: 0 }}
          block
          outline
        >
          {this.props.src} File(s)
        </NexterButton>
      )
    ) : (
      <NexterButton
        size="sm"
        color="default"
        icon="fa fa-file-pdf"
        style={{ marginTop: 0, marginBottom: 0 }}
        block
        outline
      >
        เลือกไฟล์
      </NexterButton>
    );

    return (
      <DropZone onDrop={this.props.onDrop} style={this.props.style} {...this.props.dropZoneProps}>
        {({ getRootProps, getInputProps }) => {
          let rootProps = getRootProps();
          let inputProps = { ...getInputProps(), ...this.props.inputProps };
          return (
            <section className="text-center">
              <div {...rootProps}>
                <input {...inputProps} />
                {this.props.children ?? content}
              </div>
            </section>
          );
        }}
      </DropZone>
    );
  }
}
