import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as _ from 'lodash'
/** Props Data 
 * interface 
 *  tab: [
 *   {
 *    tabId: number | string
 *    tabName: string
 *    tabContent: jsx
 * }
 * 
 * ]
 * 
 */
const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState();

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  useEffect(() => {
    const active = _.head(props.tab)
    if (active) {
      setActiveTab(active.tabId)
    }
  }, [props])
  return (
    <div className='tabs-custom'>
      <Nav tabs>
        {props.tab.map(item => {
          return (
            <NavItem key={item.tabId}>
              <NavLink className={classnames({ active: activeTab === item.tabId })} onClick={() => { toggle(item.tabId); }}>{item.tabName}</NavLink>
            </NavItem>
          )
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        {props.tab.map(item => {
          return (
            <TabPane tabId={item.tabId} key={item.tabId}>
              {item.tabContent}
            </TabPane>
          )
        })}
      </TabContent>
    </div>
  );
}
Tabs.propTypes = {
  tab: PropTypes.array
}

export default Tabs;