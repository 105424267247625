import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import { CardFooter, FormGroup } from 'reactstrap';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';
import Visibility from '../../components/Visibility/Visibility';

class CreateTransfersPanel extends React.Component {

  componentDidMount() {
    let { payment } = this.props.payment.getData();
    if (payment && payment.seller_id) {
      this.props.payment.changeSeller(payment.seller_id);
    }
  }

  async selectSeller(channelSeller) {
    let { payment } = this.props.payment.getData();
    this.props.payment.changeSeller(channelSeller.value);
    await this.props.payment.savePaymentSeller();
    await this.props.payment.getPaymentByUid(payment.id);
    // NL-1671
    if (payment && payment.channel?.uid) {
      this.props.seller.fetchSellersByStatus('approved', payment.channel?.uid, { is_active: true, payment_id: payment.id });
    }
  }

  showChangeSeller() {
    this.props.payment.showChangeSeller();
  }

  async createTransferItems() {
    let { payment } = this.props.payment.getData();
    this.props.payment.createTransferItems(payment.id);
  }

  deleteTransferItems() {
    let { payment } = this.props.payment.getData();
    this.props.payment.deleteTransferItems(payment.id);
  }

  render() {
    let seller = this.props.seller.getData();
    const { channelSellers } = seller
    seller.channelSellers = seller.channelSellers.map(cSeller => ({...cSeller, label: channelSellers.filter(s => s.label === cSeller.label).length > 1 ? `${cSeller.label} (${cSeller.seller_code})` : cSeller.label }))
    let { payment, transfer_state: transferState } = this.props.payment.getData();

    let selectedOption = null;
    if (payment && payment.seller_id) {
      selectedOption = seller.channelSellers.find(option => {
        return option.value === payment.seller_id;
      });
    }
    let cannotEdit = ['failed', 'voided', 'refunded'].includes(payment.status);
    return (
      <div className="create-transfer-panel">
        <div className="d-flex align-items-center">
          <Visibility isVisibility={['created', 'pending', 'settled', 'refunded', 'success'].includes(payment.status)}>
            <div className="mx-2" style={{ width: 270 }}>
              <Visibility isVisibility={payment.gateway !== 'bbl' && (transferState === 'clear_transfer' || transferState === 'pending')}>
                <span className="float-right text-seller"><b>ผู้ขาย/ผู้รับเงิน</b> {selectedOption?.label ?? '-'}</span>
              </Visibility>
              <Visibility isVisibility={transferState === 'select_seller' || transferState === 'create_transfer'}>
                <NexterInput type="select" className={'nexter-select-sm no-print'}
                  value={selectedOption}
                  options={seller.channelSellers}
                  onChange={this.selectSeller.bind(this)}
                  isDisabled={cannotEdit || transferState !== 'select_seller'}
                  placeholder="เลือกผู้ขาย/ผู้รับเงิน..." />
              </Visibility>
            </div>
            <Visibility isVisibility={payment.gateway !== 'bbl' && (transferState === 'select_seller' || transferState === 'create_transfer')}>
              <NexterButton size="sm" className="no-print" icon="fas fa-pencil-alt"
              disabled={transferState === 'select_seller' || !['settled','refunded'].includes(payment.status)}
              onClick={this.createTransferItems.bind(this)}>สร้างการโอนเงิน</NexterButton>
            </Visibility>
            <Visibility isVisibility={payment.gateway !== 'bbl' && (transferState === 'clear_transfer' || transferState === 'pending')}>
              <NexterButton size="sm" className="no-print" icon="fas fa-minus-circle" onClick={this.deleteTransferItems.bind(this)}>ลบรายการโอนเงิน</NexterButton>
            </Visibility>
          </Visibility>

          <Visibility isVisibility={transferState === 'transferred' || payment.gateway === 'bbl' && payment.transfer_status === 'transferred'}>
            <span className="float-right text-seller"><b>ผู้ขาย/ผู้รับเงิน :</b> {selectedOption?.label ?? '-'}</span>
            <b className="float-right text-seller" style={{ marginLeft: '1rem' }}>โอนเงินให้ผู้ขายเรียบร้อยแล้ว</b>
          </Visibility>
        </div>
      </div>
    );
  }
}

export default inject('payment', 'seller')(observer(CreateTransfersPanel));
