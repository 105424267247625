/* global FormData */
import jwtDecode from 'jwt-decode';
import { config } from '../config';
import localStorage from './localStorage';
import { UserStore } from '../stores/UserStore';

class Http {
  constructor() {
    this.isLoggedOut = false;
    this.isRefreshing = false;
    this.failedQueue = [];
    initInterceptors(this);
  }

  getHeaders(params) {
    let defaultHeaders = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
    switch (true) {
      case params.body instanceof FormData:
        return { ...defaultHeaders };
      case typeof params.body === 'object':
        return { ...defaultHeaders, 'Content-Type': 'application/json' };
      default:
        return { ...defaultHeaders };
    }
  }

  parseBody(params) {
    if (params.body) {
      switch (true) {
        case params.body instanceof FormData:
          return params.body;
        case typeof params.body === 'object':
          return JSON.stringify(params.body);
        default:
          return params.body;
      }
    }
  }

  get(url, params = {}) {
    let options = {
      method: 'GET',
      headers: this.getHeaders(params),
    };
    return fetch(url, options);
  }

  post(url, params = {}) {
    let options = {
      method: 'POST',
      headers: this.getHeaders(params),
      body: this.parseBody(params),
    };
    return fetch(url, options);
  }

  put(url, params = {}) {
    let options = {
      method: 'PUT',
      headers: this.getHeaders(params),
      body: this.parseBody(params),
    };
    return fetch(url, options);
  }

  delete(url, params = {}) {
    let options = {
      method: 'DELETE',
      headers: this.getHeaders(params),
    };
    return fetch(url, options);
  }

  processQueue(error, token = null) {
    this.failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    this.failedQueue = [];
  }
}

const initInterceptors = self => {
  // NL-1565
  const { fetch: originalFetch } = window;

  window.fetch = async (...args) => {
    let [resource, requestConfig] = args;
    let response = await originalFetch(resource, requestConfig);

    if (response.status === 401 && !requestConfig._retry) {
      let body = await response.json();

      // password has expired
      if (body.code === 'B0017') {
        if (!self.isLoggedOut) {
          self.isLoggedOut = true;
          await logout();
        }
      }

      if (self.isRefreshing) {
        return new Promise(function (resolve, reject) {
          self.failedQueue.push({ resolve, reject });
        })
          .then(token => {
            requestConfig.headers['Authorization'] = 'Bearer ' + token;
            return originalFetch(resource, requestConfig);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      requestConfig._retry = true;
      self.isRefreshing = true;

      const refreshToken = localStorage.getItem('refresh_token');
      const accessToken = localStorage.getItem('token');
      return new Promise(async function (resolve, reject) {
        try {
          const url = `${config.npay.apiUrl}/auth/refresh-token`;
          let resRefreshToeken = await http.post(url, {
            body: { refresh_token: refreshToken, access_token: accessToken },
          });
          let body = await resRefreshToeken.json();
          if (resRefreshToeken.status === 200) {
            localStorage.setItem('refresh_token', body.refresh_token); // NL-1662
            localStorage.setItem('token', body.access_token);
            requestConfig.headers['Authorization'] = 'Bearer ' + body.access_token;
            self.processQueue(null, body.access_token);
            resolve(originalFetch(resource, requestConfig));
          } else {
            throw body;
          }
        } catch (error) {
          if (!self.isLoggedOut) {
            self.isLoggedOut = true;
            await logout();
          }
          self.processQueue(error, null);
          reject(error);
        }

        self.isRefreshing = false;
      });
    }
    return response;
  };
};

const logout = async () => {
  let store = new UserStore();
  try {
    const accessToken = localStorage.getItem('token');
    let payload = jwtDecode(accessToken);
    store.loggedinUser = store.formatPermissions(payload);
  } catch (error) {
    console.log(error);
  } finally {
    const currentPath = window.location.href.replace(config.npay.cmsUrl, '');
    await store.logout({
      redirectPath: currentPath,
    });
  }
};

export const http = new Http();
