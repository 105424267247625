import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, CardBody, Label, FormGroup } from 'reactstrap';
import 'react-table/react-table.css';
import { Page } from '../../components/Page';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';
import SellerAllTable from './SellerAllTable';

class SellerPendingDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      originalFilter: {},
    };
  }

  componentDidMount() {
    this.props.channel.fetchChannelAll();
    this.tempOriginalFilter();
  }

  componentWillUnmount() {
    this.props.seller.filterPendingDelete = this.state.originalFilter;
  }

  tempOriginalFilter() {
    const { filterPendingDelete: filter } = this.props.seller.getData();
    this.setState({ originalFilter: filter });
  }

  // Seller Code
  changeFilterSellerCode(event) {
    this.props.seller.changeFilterPendingDeleteSellerCode(event.target.value);
  }

  // Channel UID
  changeFilterChannelID(id) {
    this.props.seller.changeFilterPendingDeleteChannelID(id);
  }

  // Seller Company Name / Name
  changeFilterSellerName(event) {
    this.props.seller.changeFilterPendingDeleteSellerName(event.target.value);
  }

  // Seller Email
  changeFilterEmail(event) {
    this.props.seller.changeFilterPendingDeleteEmail(event.target.value);
  }

  applySearchFilter() {
    this.props.seller.pagePendingDeletelist = 1;
    this.props.seller.fetchChannelSellers('pending_delete');
    this.tempOriginalFilter();
  }

  clearFilter = () => {
    this.props.seller.pagePendingDeletelist = 1;
    this.props.seller.clearSellerListFilterPendingDelete();
  };

  onClear() {
    this.props.seller.pagePendingDeletelist = 1;
    this.props.seller.clearFilterPendingDelete();
    this.props.seller.fetchChannelSellers('pending_delete');
    this.tempOriginalFilter();
  }

  modalToggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const { channels } = this.props.channel.getData();
    const { filterPendingDelete: filter } = this.props.seller.getData();

    return (
      <Page>
        <CardBody className="mt-3">
          <Row>
            <Col>
              <Label>
                <strong>พิมพ์ค้นหา</strong>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <FormGroup>
                <NexterInput className="nexter-input-sm" placeholder="Seller Code" value={filter.seller_code} onChange={this.changeFilterSellerCode.bind(this)} />
              </FormGroup>
            </Col>
            <Col md="3" style={{ zIndex: 9 }}>
              <FormGroup>
                <NexterInput
                  type="select"
                  className={'nexter-select-sm payment-channel-select'}
                  options={channels.all.map(c => ({ value: c.uid, label: c.name }))}
                  onChange={this.changeFilterChannelID.bind(this)}
                  value={filter.channel_id}
                  placeholder="Channel Name"
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <NexterInput className="nexter-input-sm" placeholder="Company Name / Name" value={filter.seller_name} onChange={this.changeFilterSellerName.bind(this)} />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <NexterInput className="nexter-input-sm" placeholder="Email" value={filter.email} onChange={this.changeFilterEmail.bind(this)} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="text-right" md={{ size: 9, offset: 3 }}>
              <NexterButton outline color="info" className="m-0 mr-2" size="sm" onClick={this.onClear.bind(this)}>
                Clear
              </NexterButton>
              <NexterButton className="m-0 mr-0" size="sm" color="dark" icon="fa fa-search" onClick={this.applySearchFilter.bind(this)}>
                SEARCH
              </NexterButton>
            </Col>
          </Row>
          <div style={{ height: 25 }} />
          <hr className="hr-seller" />
          <SellerAllTable type="pending_delete" />
        </CardBody>
      </Page>
    );
  }
}

export default inject('seller', 'channel')(observer(SellerPendingDelete));
