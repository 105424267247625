import React, { Component } from 'react';
import DropZone from 'react-dropzone';

import { Button } from 'reactstrap';

// duplicate from npay-admin
export default class FilePicker extends Component {
  render() {
    let content = (this.props.src) ? (
      (this.props.src.name)
        ? <Button className="text-truncate" color="success" outline block style={{ marginTop: 0, marginBottom: 0 }}>
          {this.props.src.name}
        </Button>
        : <Button className="text-truncate" color="success" outline block style={{ marginTop: 0, marginBottom: 0 }}>
          {this.props.src} File(s)
        </Button>
    ) : <Button className="text-truncate" color="secondary" outline block style={{ marginTop: 0, marginBottom: 0 }}>
      <i className="far fa-file-pdf" /> เลือกไฟล์
    </Button>;

    return (
      <DropZone onDrop={this.props.onDrop} style={this.props.style}>
        {
          ({ getRootProps, getInputProps }) => {
            let rootProps = getRootProps();
            let inputProps = { ...getInputProps(), ...this.props.inputProps };
            return (
              <section className="text-center">
                <div {...rootProps}>
                  <input {...inputProps} />
                  {content}
                </div>
              </section>
            );
          }
        }
      </DropZone>
    );
  }
}
