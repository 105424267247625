// import '../../assets/css/seller.css';
import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import swal from 'sweetalert2';
import { Link } from 'react-router-dom';
// import Select from 'react-select';
import qs from 'query-string';
import { Card, CardBody, Row, Col, FormGroup, FormFeedback, Label } from 'reactstrap';
import { config } from '../../config';
import { http } from '../../utils/http';
import { unique } from '../../utils/unique';
import { validation } from '../../utils/validation';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';
import FileUpload from '../../components/File/FileUpload';
import SellerDetailPage from './SellerDetail/SellerDetailPage'
import { CheckBox } from '../../components/Input/CheckBox';

// import FilePicker from '../../components/Forms/FilePicker';
// import Number from '../../components/Forms/Number';
import SellerRegisterKYCCompany from './SellerRegisterKYCCompany';
import SellerRegisterKYCPersonal from './SellerRegisterKYCPersonal';
import { Page } from '../../components/Page';
// import { handleInputChange } from 'react-select/src/utils';

const INITIAL_SHAREHOLDER = {
  tax_id: '',
  name: '',
  lastname: '',
  type: '',
  kyc_shareholders_committee: [{ personal_name: '', personal_id: '' }],
};

const INITIAL_SHAREHOLDER_COMMITTEE = { personal_name: '', personal_id: '' };

const isEmptyDeep = obj => {
  if (_.isObject(obj)) {
    if (Object.keys(obj).length === 0) return true;
    return _.every(_.map(obj, v => isEmptyDeep(v)));
  }
  return _.isEmpty(obj);
};

const ERROR_MESSAGES = {
  taxId: 'กรุณาระบุเลขประจำตัวให้ถูกต้อง',
  sellerCode: 'กรุณาระบุรหัสผู้ขายให้ถูกต้อง',
  sellerType: 'กรุณาเลือกประเภทผู้ขายให้ถูกต้อง',
  sellerName: 'กรุณาระบุชื่อผู้ขายให้ถูกต้อง',
  sellerPhone: 'กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง',
  sellerEmail: 'กรุณาระบุอีเมลผู้ขายให้ถูกต้อง',
  bankType: 'กรุณาเลือกประเภทบัญชีให้ถูกต้อง',
  bankName: 'กรุณาระบุชื่อบัญชีให้ถูกต้อง',
  bankNo: 'กรุณาระบุเลขบัญชีให้ถูกต้อง',
  bankFile: 'กรุณาอัปโหลดสำเนาสมุดบัญชี',
  kycType: 'กรุณาเลือกประเภท KYC ให้ถูกต้อง',
};

class SellerRegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // flow state
      loading: false,
      mode: 'create',
      step: 1, // 1 - identification ID, 2 - info, 3 - kyc, 4 existing tax id
      isRegistered: null,
      isPending: false,
      isNoAuth: true,
      phoneLength: 10,

      // data state
      sellerUid: unique.generateSellerUid(),
      channel: {}, // id, uid, logo, public, url_test
      sellerKyc: {},
      channelSeller: {},

      // input state
      form: _.cloneDeep(this.getInitialFormState()),

      // validate status
      invalid_message: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleKYCChange = this.handleKYCChange.bind(this);
    this.handleKYCFile = this.handleKYCFile.bind(this);
    this.removeKYCFile = this.removeKYCFile.bind(this);
    this.onShareholderAction = this.onShareholderAction.bind(this);

    this.sellerDetailPage = React.createRef();
  }

  getInitialFormState() {
    return {
      taxId: '',
      sellerCode: '',
      sellerType: null,
      sellerTypeOption: [
        { value: 'channel', label: 'Channel' },
        { value: 'partner', label: 'Seller' },
      ],
      sellerName: '',
      service_charge_rate: '',
      service_charge_absorb: false,
      sellerPhone: '',
      sellerEmail: '',
      bankType: null,
      bankName: '',
      bankNo: '',
      bankFile: '',
      kycTypeOption: [
        { value: 'personal', label: 'บุคคลธรรมดา' },
        { value: 'company', label: 'นิติบุคคล' },
      ],
      kycType: null,
      kycPersonal: {
        name: '',
        dob_at: '',
        phone: '',
        email: '',
        kyc_personal: '', // file
        idcard: '', // file
        current_address: '',
        current_province: '',
        current_district: '',
        current_subdistrict: '',
        current_postcode: '',
        contact_same: false,
        id_address: '',
        id_province: '',
        id_district: '',
        id_subdistrict: '',
        id_zipcode: '',
        company_name: '',
        occupation: '',
        company_address: '',
        company_province: '',
        company_district: '',
        company_subdistrict: '',
        company_zipcode: '',
      },
      kycCompany: {
        identification: '', // file
        certificate: '', // file
        vat20: '', // file
        boj5: '', // file
        beneficial_owner_name: '',
        beneficial_owner_personal_id: '',
        beneficial_owner: '', // file
        beneficial_owner_authorization: '', // file
        other: '', // file
        kyc_shareholders: [],
      },
      sellerInfo: {},
    };
  }

  async componentDidMount() {
    let query = qs.parse(this.props.location.search);
    if (Object.keys(query).includes('channel')) {
      // this.props.sellerRegister.fetchChannelByPublicUid(query.channel);
      await this.fetchChannelByPublicUid(query.channel);
      this.setState({ isNoAuth: !!query.noauth });
    } else {
      const { loggedinUser } = this.props.user.getData();
      await this.fetchChannelByUid(loggedinUser.channel.uid);
      this.setState({ isNoAuth: false });
    }
  }

  componentWillUnmount() {
    this.props.seller.rawSeller = null;
    this.props.seller.editableSellerDetail = false;
  }

  changeTaxId(event) {
    this.props.sellerRegister.changeTaxId(event.target.value);
    this.props.sellerRegister.changeIsRegistered(null);
  }

  /**
   *
   * START NEW
   *
   */

  async fetchChannelByPublicUid(uid) {
    try {
      this.setLoading(true);
      let url = `${config.npay.apiUrl}/channels/detail`;
      let response = await http.post(url, { body: { uid } });
      if (response.status === 200) {
        let body = await response.json();
        this.setState({ channel: body.data });
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.setLoading(false);
    }
  }

  async fetchChannelByUid(uid) {
    try {
      this.setLoading(true);
      let url = `${config.npay.apiUrl}/channels/detail/${uid}`;
      let response = await http.post(url);
      if (response.status === 200) {
        let body = await response.json();
        this.setState({ channel: body.data });
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.setLoading(false);
    }
  }

  async showNoticeAlert(text) {
    const res = await swal.fire({
      customClass: 'nexter-alert',
      text,
      type: 'info',
      confirmButtonText: 'ตกลง',
    });
    return res;
  }

  async showErrorAlert(text = '') {
    await swal.fire({
      customClass: 'nexter-alert',
      type: 'error',
      title: 'เกิดข้อผิดพลาด!',
      text,
      confirmButtonText: 'ตกลง',
    });
  }

  setLoading(loading) {
    this.setState({ loading });
  }

  validateTaxId() {
    const {
      form: { taxId },
      invalid_message,
    } = this.state;
    let pass = true;
    if (!taxId || isNaN(parseInt(taxId))) {
      invalid_message['taxId'] = ERROR_MESSAGES['taxId'] || '';
      pass = false;
    }
    this.setState({ invalid_message });
    return pass;
  }

  validateRegister() {
    let pass = true;
    const validate_state = _.pick(this.state.form, [
      'sellerCode',
      'sellerType',
      'sellerPhone',
      // 'sellerEmail',
      'bankType',
      'bankName',
      'bankNo',
      'bankFile',
      'kycType',
      'kycCompany',
    ]);

    // check empty
    Object.entries(validate_state).forEach(([key, value]) => {
      let invalid = false;
      // if (['sellerCode'].some(s => s === key)) invalid = value.trim().indexOf(' ') !== -1;
      if (['sellerPhone'].some(s => s === key)) invalid = !validation.phone(value) || (9 <= value && value <= 10);
      // if (['sellerEmail'].some(s => s === key) && !_.isEmpty(value)) invalid = !validation.email(value);
      if (!invalid) invalid = _.isEmpty(value);

      if (invalid) {
        const { invalid_message } = this.state;
        invalid_message[key] = ERROR_MESSAGES[key] || '';
        this.setState({ invalid_message });
        pass = false;
      }
      // return invalid;
    });
    return pass;
  }

  formatForm(data) {
    const result = data || {};
    const dateISOregEx = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
    Object.entries(result).forEach(([key, value]) => {
      if (value === null) result[key] = '';
      if (value && typeof(value) === 'object')
      result[key] = this.formatForm(result[key]);
      if (dateISOregEx.test(value)) result[key] = new Date(value);
      
      if (key === 'current_postcode') result.current_postcode = value;
      if (key === 'file_kyc_personal') result.kyc_personal = value;
      if (key === 'file_idcard') result.idcard = value;
    });
    return result;
  }

  async checkTaxId() {
    if(this.sellerDetailPage?.current) {
      this.sellerDetailPage.current.wrappedInstance.clearValidateData()
    }
    if (this.validateTaxId()) {
      try {
        this.setLoading(true);
        let { form, channel } = this.state;
        this.props.seller.clearSeller()
        let url = `${config.npay.apiUrl}/channels/${channel.id}/seller/kyc/${form.taxId}`;
        let response = await http.get(url);
        if (response.status === 200) {
          let body = await response.json();
          let data = body.data;
          // console.log(data);

          // guest
          let sellerKyc = {};
          let channelSeller = {};
          let isRegistered = false;
          let isPending = false;
          let step = 2;
          let sellerInfo = {};
          if (!_.isEmpty(data.channel_seller)) {
            // member of this channel
            // NL-1645
            swal.fire({
              customClass: 'nexter-alert',
              type: 'warning',
              html: this.state.isNoAuth
                ? 'ไม่สามารถสร้าง Seller ด้วย Tax id/Personal id ที่ซ้ำกันได้ มากกว่า 1 ราย'
                : 'มีการสร้าง Seller ด้วย Tax id/ Personal นี้แล้ว<br/>ท่านสามารถดูข้อมูล Seller นี้ได้โดยการกด "ดูข้อมูล"',
              showCancelButton: !this.state.isNoAuth,
              reverseButtons: true,
              confirmButtonText: this.state.isNoAuth ? 'ตกลง' : 'ดูข้อมูล',
              cancelButtonText: 'ยกเลิก',
              preConfirm: () => {
                if (!this.state.isNoAuth) {
                  const sellerUid = data.channel_seller?.seller?.uid;
                  const channelUid = this.state.channel.uid;
                  this.props.history.push(`${config.web.rootpath}/sellers/detail/${channelUid}/${sellerUid}`);
                }
              },
            });
            sellerKyc = data.kyc;
            channelSeller = data.channel_seller;
            isPending = true;
            isRegistered = true;
            step = 1;
          } else if (!_.isEmpty(data.kyc)) {
            // non-member of this channel
            this.showNoticeAlert(`ท่านมีข้อมูลในระบบแล้ว กรุณากดปุ่มลงทะเบียนเพื่อลงทะเบียนกับ ${channel.name}`);
            sellerKyc = data.kyc;
            channelSeller = {};
            isRegistered = true;
            step = 4;
            let url2 = `${config.npay.apiUrl}/sellers/${data.kyc.seller_id}`;
            let response2 = await http.get(url2);
            if (response2.status === 200) {
              let body2 = await response2.json();
              sellerInfo = body2.data;
              if (!this.state.isNoAuth) {
                await this.props.seller.getSellerByUid(sellerInfo.uid);
                this.props.seller.changeServiceChargeRate('');
                this.props.seller.countSellers(sellerInfo.uid);
              } else {
                this.props.seller.changeSellerKYCType(sellerKyc.seller.kyc_type)
                this.props.seller.changeServiceChargeRate(3);
                this.props.seller.changeSellerType('partner');
                this.props.seller.setSellerId(data.kyc.seller_id)
              }
              this.props.seller.changeServiceChargeAbsorb(false);
            }
          } else {
            this.props.seller.changeSellerType('partner');
            this.props.seller.changeServiceChargeRate('');
            this.props.seller.changeServiceChargeAbsorb(false);
          }
          const { seller } = this.props.seller.getData();
          const dataKey = `kyc_${seller.kyc_type}`;
          const formKey = `kyc${_.capitalize(seller.kyc_type)}`;
          // console.log('STEP', step);
          const initForm = seller[dataKey] ? { [formKey]: this.formatForm(seller[dataKey]) } : {}
          if(this.state.isNoAuth) {
            initForm.sellerType = { value: 'partner', label: 'Seller' }
          }
          this.props.seller.editableSellerDetail = true
          this.props.seller.editableSellerAccountDetail = true
          this.setState(
            {
              sellerKyc,
              channelSeller,
              isRegistered,
              isPending,
              step,
              form: { ..._.cloneDeep(this.getInitialFormState()), ...initForm, taxId: this.state.form.taxId },
              sellerInfo,
              invalid_message: []
            },
            () => {
              this.onShareholderAction('kyc_shareholders', 'add', 0);
            }
          );
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.setLoading(false);
      }
    }
  }

  async register() {
    let { sellerUid, channel } = this.state;
    const { seller } = this.props.seller.getData()
    const detail = _.pick(seller, ['name', 'type', 'email', 'phone_number', 'kyc_type'])
    const channel_seller = _.pick(seller.channel_seller, ['service_charge_absorb', 'service_charge_rate'])
    if(detail.type === 'channel') channel_seller.service_charge_rate = 0
    const account = _.pick(seller.sellers_account, ['bank_code', 'bank_name', 'bank_account_name', 'bank_account_no', 'bank_account_image'])
    let sellerData = {
      channel_uid: channel.uid,
      uid: sellerUid,
      code: seller.channel_seller.seller_code || null,
      ...detail,
      ...account
    };
    const isValidData = this.sellerDetailPage.current.wrappedInstance.validateData();
    if (!Boolean(this.props.seller.validateSellerData(sellerData)) || !isValidData) {
      this.showErrorAlert('กรุณากรอกข้อมูลให้ถูกต้องครบถ้วน');
      return
    }

    swal
      .fire({
        customClass: 'nexter-alert',
        type: 'warning',
        title: 'ลงทะเบียนและส่งตรวจสอบเอกสาร',
        html: 'เมื่อกดยืนยัน ข้อมูลจะถูกส่งไปตรวจสอบ<br/>สถานะบางรายการจะเปลี่ยนเป็น “Pending” <br/>คุณต้องการยืนยันข้อมูลนี้หรือไม่?',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ยืนยัน',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        onOpen: () => swal.getConfirmButton().blur(),
        allowOutsideClick: () => !swal.isLoading(),
      })
      .then(async result => {
        if (result.value) {
          try {
            this.setLoading(true);
            await this.createSeller({ ...sellerData, ...channel_seller });
            swal
              .fire({
                customClass: 'nexter-alert',
                type: 'success',
                title: 'ลงทะเบียนสำเร็จแล้ว!',
                confirmButtonText: 'กลับไป',
                reverseButtons: true,
                onOpen: () => swal.getConfirmButton().blur(),
              })
              .then(() => {
                if (!this.state.isNoAuth) {
                  this.props.history.push(`${config.web.rootpath}/sellers`);
                } else {
                  window.location.reload();
                }
              });
          } catch (error) {
            swal.fire({
              customClass: 'nexter-alert',
              type: 'error',
              title: 'เกิดข้อผิดพลาด!',
              text: error?.message,
              confirmButtonText: 'ลองอีกครั้ง',
            });
          } finally {
            this.setLoading(false);
          }
        }
      });
  }

  async cancelRegister() {
    const sa2Confirm = await swal.fire({
      customClass: 'nexter-alert',
      // type: 'warning',
      title: 'ต้องการยกเลิกการลงทะเบียน',
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'ยืนยัน',
      reverseButtons: true,
      onOpen: () => swal.getConfirmButton().blur(),
    });

    if (sa2Confirm.value) {
      // back to seller list
      this.props.history.push(`${config.web.rootpath}/sellers`);
    }
  }

  async uploadKYCFile(files) {
    let temp = {}
    try {
      let { sellerUid, channel } = this.state;
      let formData = new FormData();
      formData.append('uid', sellerUid);
      formData.append('channel_uid', channel.uid);

      for (let prop in files) {
        if (typeof files[prop] === 'object') {
          formData.append(prop, files[prop]);
        }else {
          temp[`file_${prop}`] = files[prop]
        }
      }
      let url = `${config.npay.apiUrl}/sellers/kyc/file`;
      let response = await http.post(url, { body: formData });
      if (response.status === 200) {
        let body = await response.json();
        return {...body.data, ...temp};
      }
    } catch (err) {
      console.error(err);
    }
  }

  async uploadBankAccountImage(file) {
    try {
      let { sellerUid, form, channel } = this.state;
      file = file ? file : form.bankFile
      if(typeof file !== 'object') return file
      let formData = new FormData();
      formData.append('bookbank', file, file.name);
      formData.append('uid', sellerUid);
      formData.append('channel_uid', channel.uid);

      let url = `${config.npay.apiUrl}/sellers/bookbank/file`;
      let response = await http.post(url, { body: formData });
      if (response.status === 200) {
        let body = await response.json();
        return body.data.url;
      }
    } catch (err) {
      console.error(err);
    }
  }

  async createChannelSeller() {
    try {
      this.setLoading(true);
      let { channel, sellerKyc } = this.state;
      let url = `${config.npay.apiUrl}/sellers/channel`;
      let data = {
        channel_id: channel.id,
        seller_id: sellerKyc.seller_id,
      };
      let response = await http.post(url, { body: data });

      if (response.status === 200) {
        swal
          .fire({
            customClass: 'nexter-alert',
            type: 'success',
            title: 'ลงทะเบียนสำเร็จแล้ว!',
            confirmButtonText: 'กลับไป',
            onOpen: () => swal.getConfirmButton().blur(),
          })
          .then(() => {
            if (!this.state.isNoAuth) {
              this.props.history.push(`${config.web.rootpath}/sellers`);
            } else {
              window.location.reload();
            }
          });
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (err) {
      console.error(err);
      this.showErrorAlert(err.message);
    } finally {
      this.setLoading(false);
    }
  }

  async createSeller(seller_data) {
    try {
      let { isRegistered, channelSeller, sellerKyc, sellerUid, channel, form, step } = this.state;

      if (isRegistered === null) {
        throw new Error('กรุณากรอกเลขประจำตัวประชาชน/นิติบุคคลและกดค้นหา');
      } else if (!_.isEmpty(channelSeller)) {
        throw new Error('ไม่สามารถสร้าง Seller ด้วย Tax id/Personal id ที่ซ้ำกันได้ มากกว่า 1 ราย');
      } else if (!_.isEmpty(sellerKyc) && step !== 4) {
        this.createChannelSeller();
        return;
      }

      let sellerData = seller_data ? seller_data : {
        channel_uid: channel.uid,
        code: form.sellerCode || null,
        uid: sellerUid,
        name: form.sellerName,
        type: form.sellerType.value,
        email: form.sellerEmail,
        phone_number: form.sellerPhone,
        kyc_type: form.kycType.value,
        bank_code: form.bankType ? form.bankType.value : '',
        bank_name: form.bankType ? form.bankType.label : '',
        bank_account_name: form.bankName,
        bank_account_no: form.bankNo,
        bank_account_image: form.bankFile,
        service_charge_absorb: this.state.form.service_charge_absorb,
        service_charge_rate: !!this.state.form.service_charge_rate ? this.state.form.service_charge_rate : 3
      };

      let kycData = {};
      if (sellerData.kyc_type === 'personal') {
        const kycPersonalUpload = {
          kyc_personal: form.kycPersonal.kyc_personal,
          idcard: form.kycPersonal.idcard,
        };
        kycData = {
          ..._.pick(form.kycPersonal, [
            'name',
            'phone',
            'email',
            'current_address',
            'current_province',
            'current_district',
            'current_subdistrict',
            'current_postcode',
            'id_address',
            'id_province',
            'id_district',
            'id_subdistrict',
            'id_zipcode',
            'company_name',
            'occupation',
            'company_address',
            'company_province',
            'company_district',
            'company_subdistrict',
            'company_zipcode',
          ]),
          dob_at: form.kycPersonal.dob_at ? moment(form.kycPersonal.dob_at).valueOf() : '',
          personal_id: form.taxId,
          upload: kycPersonalUpload,
        };
        if (Object.entries(kycPersonalUpload).some(([key, value]) => !_.isEmpty(value))) {
          let kycUpload = await this.uploadKYCFile(kycPersonalUpload);
          if (_.isEmpty(kycUpload)) throw new Error('ไม่สามารถอัปโหลดไฟล์ KYC ได้ กรุณาลองใหม่อีกครั้ง');
          kycData.upload = kycUpload;
        }
      } else if (sellerData.kyc_type === 'company') {
        const kycCompanyUpload = _.pick(form.kycCompany, ['identification', 'certificate', 'vat20', 'boj5', 'beneficial_owner', 'beneficial_owner_authorization', 'other']);
        const kyc_shareholders = form.kycCompany.kyc_shareholders
          ? form.kycCompany.kyc_shareholders.filter(obj => {
              const objectEmpty = isEmptyDeep(_.pick(obj, ['tax_id', 'name', 'lastname', 'type']));
              const commiteeEmpty = obj.type === 'corporate' && _.every(_.map(obj.kyc_shareholders_committee, v => isEmptyDeep(v)));
              return !objectEmpty && !commiteeEmpty;
            }).map(obj => {
              const kyc_shareholders = _.pick(obj, ['tax_id', 'name', 'lastname', 'type'])
              if(kyc_shareholders.type === 'corporate') {
                const kyc_shareholders_committee = obj.kyc_shareholders_committee.map(committee => _.pick(committee, ['personal_id', 'personal_name']))
                kyc_shareholders.kyc_shareholders_committee = kyc_shareholders_committee
              }
              return kyc_shareholders
            })
          : [];
        kycData = {
          company_taxid: form.taxId,
          beneficial_owner_name: form.kycCompany.beneficial_owner_name,
          beneficial_owner_personal_id: form.kycCompany.beneficial_owner_personal_id,
          kyc_shareholders,
          upload: kycCompanyUpload,
        };
        if (Object.entries(kycCompanyUpload).some(([key, value]) => !_.isEmpty(value))) {
          let kycUpload = await this.uploadKYCFile(kycCompanyUpload);
          if (_.isEmpty(kycUpload)) throw new Error('ไม่สามารถอัปโหลดไฟล์ KYC ได้ กรุณาลองใหม่อีกครั้ง');
          kycData.upload = kycUpload;
        }
      }
      let data = {
        ...sellerData,
        kyc: kycData,
      };
      if (data.bank_account_image.name) {
        let imageUrl = await this.uploadBankAccountImage(data.bank_account_image);
        if (!imageUrl) throw new Error('ไม่สามารถอัปโหลดไฟล์สมุดบัญชีได้ กรุณาลองใหม่อีกครั้ง');
        data.bank_account_image = imageUrl;
      }

      let url = `${config.npay.apiUrl}/sellers`;
      let response = await http.post(url, { body: data });
      if (response.status === 200) {
        return true;
      } else {
        let body = await response.json();
        throw new Error(body.message);
      }
    } catch (error) {
      throw new Error(error?.message);
    }
  }

  onShareholderAction(name, action, index) {
    const isCommitee = name.includes('kyc_shareholders_committee');
    const value = _.cloneDeep(isCommitee ? INITIAL_SHAREHOLDER_COMMITTEE : INITIAL_SHAREHOLDER);
    const kyc = this.state.form.kycCompany;
    const arr = _.get(kyc, name);
    if (action === 'add') arr.push(value);
    if (action === 'remove' && arr.length > 0) arr.splice(index, 1);
    this.handleKYCChange('kycCompany', name, arr);
  }

  handleChange(e) {
    let { form, invalid_message, isRegistered } = this.state;
    _.set(form, e.target.name, e.target.value); // nest set
    _.set(invalid_message, e.target.name, ''); // reset invalid message
    if (e.target.name === 'taxId') isRegistered = null;
    this.setState({ form, invalid_message, isRegistered });
    this.validateInputs(e.target.name, e.target.value);
  }

  validateInputs(key = null, value = null) {
    let { form, phoneLength } = this.state;
    let pass = true;
    const fieldsToCheck = key ? [key] : ['sellerCode', 'sellerType', 'sellerName', 'sellerPhone', 'bankType', 'bankName', 'bankNo', 'bankFile', 'kycType', 'service_charge_rate'];
    const invalid_message = this.state.invalid_message;

    fieldsToCheck.forEach(field => (invalid_message[field] = null));

    fieldsToCheck.forEach(field => {
      const data = key ? value : form[field];
      const defaultValidator = [undefined, null, ''].includes(data);
      switch (field) {
        case 'sellerPhone': {
          if (defaultValidator) {
            invalid_message[field] = ERROR_MESSAGES[field];
            break;
          }
          if (data[0] != '0') {
            invalid_message[field] = 'หมายเลขโทรศัพท์ต้องขึ้นต้นด้วย 0';
            break;
          }
          if (data[0] == '0' && data[1] == '2') {
            phoneLength = 9;
            this.setState({ phoneLength });
            if (data.length < 9) {
              invalid_message[field] = 'หมายเลขโทรศัพท์ต้องมีความยาว 9 หลัก';
              break;
            }
            break;
          }
          if (data[0] == '0' && data[1] == '0') {
            invalid_message[field] = 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง';
            break;
          }
          if (data.length < 10) {
            phoneLength = 10;
            this.setState({ phoneLength });
            invalid_message[field] = 'หมายเลขโทรศัพท์ต้องมีความยาว 10 หลัก';
            break;
          }
          break;
        }
        case 'service_charge_rate': {
          if(!this.state.isNoAuth && form.sellerType && form.sellerType.value !== 'channel' && form.service_charge_rate < 3) {
            invalid_message['service_charge_rate'] = 'ค่าธรรมเนียมต้องมีค่ามากกว่าหรือเท่ากับ 3'
          }
          break;
        }
        default:
          if (defaultValidator) invalid_message[field] = ERROR_MESSAGES[field];
      }
    });

    this.setState({ invalid_message });
    return pass;
  }

  handleKYCChange(kycType, name, value) {
    const form = this.state.form;
    const kyc = form[kycType];
    _.set(kyc, name, value); // nest set

    if (name === 'contact_same' || kyc.contact_same) {
      if (kyc.contact_same === true) {
        kyc.current_address = kyc.id_address;
        kyc.current_province = kyc.id_province;
        kyc.current_district = kyc.id_district;
        kyc.current_subdistrict = kyc.id_subdistrict;
        kyc.current_postcode = kyc.id_zipcode;
      } else {
        kyc.current_address = '';
        kyc.current_province = '';
        kyc.current_district = '';
        kyc.current_subdistrict = '';
        kyc.current_postcode = '';
      }
    }
    form[kycType] = kyc;
    this.setState({ form });
  }

  handleKYCFile(kycType, name, acceptedFiles) {
    if (acceptedFiles && acceptedFiles.length) {
      const file = acceptedFiles[0];
      const form = this.state.form;
      form[kycType][name] = file;
      this.setState({ form });
    }
  }

  removeKYCFile(kycType, name) {
    this.handleKYCChange(kycType, name, '');
  }

  handleSelectChange(name, selected, meta) {
    const { form, invalid_message } = this.state;
    if (name === 'sellerType') {
      if (!(form.sellerType === null && selected.value === 'partner')) {
        form.sellerCode = selected.value === 'channel' ? '000' : '';
        form.service_charge_rate = ''
        form.service_charge_absorb = selected.value === 'channel'
        invalid_message['service_charge_rate'] = selected.value === 'channel' ? '' : invalid_message['service_charge_rate']
      } 
    }
    form[name] = selected;
    invalid_message[name] = '';
    let step = 2;
    if (name === 'kycType') step = 3;
    this.setState({ form, invalid_message, step });
  }

  handleBankFile(acceptedFiles) {
    if (acceptedFiles && acceptedFiles.length) {
      const file = acceptedFiles[0];
      const { form, invalid_message } = this.state;
      form['bankFile'] = file;
      invalid_message['bankFile'] = '';
      this.setState({ form });
    }
  }

  removeBankFile() {
    const form = this.state.form;
    form['bankFile'] = '';
    this.setState({ form });
  }

  changeServiceChargeAbsorb() {
    const { form } = this.state;
    form['service_charge_absorb'] = !form['service_charge_absorb'];
    this.setState({ form });
  }

  /**
   *
   * END NEW
   *
   */

  changeDealerCode(event) {
    this.props.sellerRegister.changeDealerCode(event.target.value);
  }

  // createSeller() {
  //   this.props.sellerRegister.createSeller();
  // }


  render() {
    // const { taxId, isRegistered, seller, loading, channel, channelSeller } = this.props.sellerRegister.getData();
    // const colStyle = { size: 9, offset: 1 };
    // const bankCode = _.get(seller, 'sellers_account.bank_code');
    // const currentBank = bankCode ? { value: seller.sellers_account.bank_code, label: seller.sellers_account.bank_name } : null;
    const { channel, form, invalid_message, step, phoneLength } = this.state;
    const { seller } = this.props.seller.getData()
    // if (_.isEmpty(channel)) {
    //   return (
    //     <div>
    //       <Container>
    //         <Card>
    //           <CardBody>
    //             <CardTitle className="text-center">{loading ? <p className="text-muted">กำลังโหลดข้อมูล..</p> : <h5>ไม่พบข้อมูลร้านค้า</h5>}</CardTitle>
    //           </CardBody>
    //         </Card>
    //       </Container>
    //     </div>
    //   );
    // }
    return (
      <Page loading={this.state.loading}>
        <div className={classNames('create-seller-wrapper pb-5', this.state.isNoAuth ? 'container' : null)}>
          {!this.state.isNoAuth ? (
            <>
              <p className="label-header text-uppercase">Seller</p>
              <div className="title-goback">
                <Link to={`${config.web.rootpath}/sellers`}>
                  <i className="back-icon fas fa-chevron-left my-auto mr-4" />
                </Link>
                <span className="texthead-goback">Create New Seller</span>
              </div>
            </>
          ) : (
            <div className="mt-5"></div>
          )}
          <Card>
            <CardBody className="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="text-uppercase my-auto title">
                  <b>ลงทะเบียนกับร้าน {this.state.channel.name || ''}</b>
                </h5>
                <div className="d-flex">
                  {!this.state.isNoAuth && (
                    <NexterButton className="mr-2 my-0" size="sm" color="info" outline onClick={this.cancelRegister.bind(this)}>
                      ยกเลิก
                    </NexterButton>
                  )}
                  <NexterButton size="sm" disabled={this.state.isRegistered === null || this.state.isPending} onClick={this.register.bind(this)} className="my-0">
                    ลงทะเบียน
                  </NexterButton>
                </div>
              </div>
              <hr />
              <Row>
                <Col md={6}>
                  <FormGroup className="required">
                    <Label className="control-label form-label">ระบุเลขประจำตัวประชาชน/นิติบุคคล</Label>
                    <div className="d-flex">
                      <NexterInput
                        allow="numeric"
                        size="sm"
                        id="taxId"
                        name="taxId"
                        value={form.taxId}
                        onChange={this.handleChange}
                        invalid={!!invalid_message['taxId']}
                        maxLength={13}
                        // disabled={disabledInfo}
                        className="nexter-input-sm"
                        style={{ width: '70%' }}
                      />
                      <div style={{ width: 5 }} />
                      <NexterButton style={{ width: '30%' }} size="sm" color="dark" onClick={this.checkTaxId.bind(this)} icon="fas fa-search">
                        SEARCH
                      </NexterButton>
                    </div>
                    <FormFeedback>{invalid_message['taxId']}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              {[2,3,4].includes(step) && <SellerDetailPage 
                createNewSeller={true} 
                taxId={form.taxId}
                form={seller.kyc_type === 'personal' ? { ...form.kycPersonal } : seller.kyc_type === 'company' ? { ...form.kycCompany } : {} }
                handleChange={this.handleKYCChange}
                handleKYCFile={this.handleKYCFile}
                removeKYCFile={this.removeKYCFile}
                onShareholderAction={this.onShareholderAction}
                ref={this.sellerDetailPage}
              />}
            </CardBody>
          </Card>
        </div>
      </Page>
    );
  }
}

export default inject('sellerRegister', 'channel', 'user', 'seller')(observer(SellerRegisterPage));
