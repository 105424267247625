import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class Countdown extends React.Component {
  constructor(props) {
    super(props);
    this.countDownTimerId = this.props.endTime;
    this.state = {
      timeLeft: 0,
    };
  }

  componentDidMount() {
    this.countDown();
  }

  countDown() {
    let timeLeft = this.calculateTimeLeft();
    this.setState({ timeLeft });
    clearInterval(this.countDownTimerId);
    if (timeLeft <= 0) {
      return;
    }
    this.countDownTimerId = setInterval(() => {
      let timeLeft = this.calculateTimeLeft();
      this.setState({ timeLeft });
      if (timeLeft <= 0) {
        clearInterval(this.countDownTimerId);
      }
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.endTime !== this.props.endTime) {
      this.countDown();
    }
  }

  componentWillUnmount() {
    clearInterval(this.countDownTimerId);
  }

  calculateTimeLeft() {
    let timeNow = new Date().getTime();
    let timeLeft = Math.floor((this.props.endTime - timeNow) / 1000);
    return timeLeft;
  }

  formatTime(secs = 0) {
    if (secs >= 60) {
      return `${moment.utc(secs * 1000).format('m:ss')}s`;
    } else {
      return `${secs}s`;
    }
  }

  render() {
    return this.props.render({
      timeLeft: this.state.timeLeft,
      formatTime: this.formatTime,
    });
  }
}

Countdown.propTypes = {
  endTime: PropTypes.number,
  onTimeUp: PropTypes.func,
};

Countdown.defaultProps = {
  endTime: 0,
  onTimeUp: () => {},
};

export default Countdown;
