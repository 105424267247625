import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
} from 'reactstrap';

import { Page } from '../../components/Page';
import NexterButton from '../../components/Button/NexterButton';
import NexterInput from '../../components/Input/NexterInput';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'read',
    };
  }

  componentDidMount() {
    this.props.user.prepareToUpdate();
  }

  changeToEditMode() {
    this.setState({ mode: 'edit' });
  }

  changeDisplayName(event) {
    this.props.user.changeDisplayName(event.target.value);
  }

  changeEmail(event) {
    this.props.user.changeEmail(event.target.value);
  }

  saveUserProfile() {
    this.setState({ mode: 'read' }, () => {
      this.props.user.saveUserProfile();
    });
  }

  render() {
    let { loading, user } = this.props.user.getData();
    return (
      <Page loading={loading}>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <Card>
              <CardHeader className="text-center">
                ข้อมูลผู้ใช้งาน
                <hr />
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>ชื่อผู้ใช้</Label>
                  <NexterInput readOnly value={user.username} />
                </FormGroup>

                <FormGroup>
                  <Label>ชื่อที่แสดง</Label>
                  <NexterInput
                    readOnly={this.state.mode === 'read'}
                    value={user.name}
                    onChange={this.changeDisplayName.bind(this)}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>อีเมล</Label>
                  <NexterInput
                    readOnly={this.state.mode === 'read'}
                    value={user.email}
                    onChange={this.changeEmail.bind(this)}
                  />
                </FormGroup>

                <hr />
                {this.state.mode === 'read' && (
                  <NexterButton
                    className="w-100"
                    size="sm"
                    onClick={this.changeToEditMode.bind(this)}
                    block
                  >
                    แก้ไข
                  </NexterButton>
                )}
                {this.state.mode === 'edit' && (
                  <NexterButton
                    className="w-100"
                    size="sm"
                    color="success"
                    onClick={this.saveUserProfile.bind(this)}
                    block
                  >
                    บันทึก
                  </NexterButton>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}

export default inject('user')(observer(UserProfile));
